import Stripe from 'stripe'

export class CompanySubscription {
    _id: string
    company_id: string | null
    name: string
    description: string | null
    currency: string
    status: string
    features: SubscriptionFeatures
    details: any
    stripe_subscription: Stripe.Subscription
    created_by_user: string | null
    deleted: boolean
    createdAt: Date
    updatedAt: Date



    constructor(details = {} as any) {
        this._id = details?._id
        this.company_id = details?.company_id || null
        this.name = details?.name || ''
        this.description = details?.description || null
        this.currency = details?.currency || 'AUD'
        this.status = details?.status || 'inactive'
        this.features = details?.features ? new SubscriptionFeatures(details?.features) : new SubscriptionFeatures()
        this.details = details?.details || {}
        this.stripe_subscription = details?.stripe_subscription || null
        this.created_by_user = details?.created_by_user || null
        this.deleted = details?.deleted || false
        this.createdAt = details?.createdAt
        this.updatedAt = details?.updatedAt
    }
}

export enum SUBSCRIPTION_FEATURES {
    DASHBOARD = "dashboard",
    DRIVER_APP = "driver_app",
    INVOICING = "invoicing",
    INVENTORY = "inventory",
    SMS = "sms",
    MULTIPLE_CLIENTS = "multiple_clients",
    SQUARE_PAYMENTS = "square_payments",
    AUTO_EMAILS = "auto_emails",
    DOCUMENTS = "documents",
    HOLDING = "holding",
    EMAIL_SCRAPING = "email_scraping",
    API_ACCESS = "api_access",
    WHITE_LABEL = "white_label",
    SERVICE_NETWORK = "service_network",
    CASE_MANAGEMENT = "case_management",
}

export type SubscriptionFeaturesType = {
    [key in SUBSCRIPTION_FEATURES]: boolean;
};

export class SubscriptionFeatures implements SubscriptionFeaturesType {
    [SUBSCRIPTION_FEATURES.DASHBOARD]: boolean = false;
    [SUBSCRIPTION_FEATURES.DRIVER_APP]: boolean = false;
    [SUBSCRIPTION_FEATURES.INVOICING]: boolean = false;
    [SUBSCRIPTION_FEATURES.INVENTORY]: boolean = false;
    [SUBSCRIPTION_FEATURES.SMS]: boolean = false;
    [SUBSCRIPTION_FEATURES.MULTIPLE_CLIENTS]: boolean = false;
    [SUBSCRIPTION_FEATURES.SQUARE_PAYMENTS]: boolean = false;
    [SUBSCRIPTION_FEATURES.AUTO_EMAILS]: boolean = false;
    [SUBSCRIPTION_FEATURES.DOCUMENTS]: boolean = false;
    [SUBSCRIPTION_FEATURES.HOLDING]: boolean = false;
    [SUBSCRIPTION_FEATURES.EMAIL_SCRAPING]: boolean = false;
    [SUBSCRIPTION_FEATURES.API_ACCESS]: boolean = false;
    [SUBSCRIPTION_FEATURES.WHITE_LABEL]: boolean = false;
    [SUBSCRIPTION_FEATURES.SERVICE_NETWORK]: boolean = false;
    [SUBSCRIPTION_FEATURES.CASE_MANAGEMENT]: boolean = false;

    constructor(details: Partial<SubscriptionFeaturesType> = {}) {
        Object.assign(this, details);
    }
}