export class VendorProfile {
    _id!: string;
    company_id!: string | null;
    linkedVendorAccountId!: string | null;
    name!: string;
    details!: any;
    operating_hours!: any;
    service_areas!: any;
    services_provided!: any;
    other!: any;
    settings!: VendorProfileSettings;
    deleted!: boolean;
    createdAt!: string;
    updatedAt!: string;

    constructor(details: any = {}) {
        this._id = details._id;
        this.company_id = details.company_id || null;
        this.linkedVendorAccountId = details.linkedVendorAccountId || null;
        this.name = details.name || '';
        this.details = details.details || {};
        this.operating_hours = details.operating_hours || {};
        this.service_areas = details.service_areas || {};
        this.services_provided = details.services_provided || {};
        this.other = details.other || {};
        this.settings = new VendorProfileSettings(details.settings || {});
        this.deleted = details.deleted || false;
        this.createdAt = details.createdAt || '';
        this.updatedAt = details.updatedAt || '';
    }
}

export class VendorProfileSettings {
    rates!: VendorProfileRate[];
    job_options!: any;
    create_job_mandatory_fields!: any;

    constructor(details: VendorProfileSettings) {
        this.rates = details.rates || [];
        this.job_options = details.job_options || {};
        this.create_job_mandatory_fields = details.create_job_mandatory_fields || {};
    }
}


export class VendorProfileRate {
    _id!: string;
    name!: string;
    cost!: number;

    constructor(details: VendorProfileRate) {
        this._id = details._id;
        this.name = details.name || '';
        this.cost = details.cost || 0;
    }
}