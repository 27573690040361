import React, { useState, useMemo, useRef, useEffect, useCallback } from 'react';
import {
    Image,
    AccessibilityRole,
    ImageProps,
    ImageStyle,
    StyleSheet,
    TouchableOpacity,
    View,
    Text as RNText,
    Animated,
    ScrollView,
    Dimensions,
    useWindowDimensions
    //@ts-ignore
} from "react-native";
import {
    ApplicationProvider,
    Button,
    Icon,
    IconRegistry,
    Input,
    Modal,
    Layout,
    Text,
    List,
    ListItem,
    Divider,
    Card,
    useTheme,
    //@ts-ignore
    CheckBox
} from "@ui-kitten/components";
import OSRM from 'osrm';
import { SafeAreaView } from 'react-native-safe-area-context';
import { EvaIconsPack } from "@ui-kitten/eva-icons";
import * as StorageController from "../../functions/storageController"
import * as JobController from "../../functions/job.controller"
import * as GoogleController from '../../functions/google.controller';
import * as RouteController from "../../functions/route.controller"
import * as MembershipController from "../../functions/membership.controller"
import { Job, JobAddress, LineItem, JobLocation, JobDirections, JOB_STATUS, JobClientRate, JobNote, JobCustomerDetails, Service } from "../../models/Job.model";
import { ReportDetailsCard } from '../job/details/reportDetailsCard.component';
import { CreateJobFromHolding } from '../job/modals/createJobFromHoldingModal.component';
import { InvoicedTotalCard } from '../job/details/jobInvoicedTotalDetailsCard.component';
import * as ClientController from '../../functions/client.controller'
// import { LocationDetailsCard } from '../job/newJobComponents/locationDetailsCard.component';
import { Route, RouteDetails, ROUTE_STATUS, RouteLeg, RouteLocation, IGoogleRoute, IGoogleRouteLeg, ROUTE_LOCATION_TYPE, IGoogleOptimizeRouteRequest, IGoogleOptimizeArrivalLocation, IGoogleOptimizeShipment } from '../../models/Route.model';
import { AddressDetailsCard } from '../job/details/addressDetailsCard.component';
import { InventoryItem } from '../../models/Company.model';
import { RoutePlanningMap } from './routePlanningMap.component';
import { RouteLegsCard } from './routeLegsCard.component';
import { RouteLocationDetailsCard } from './routeLocationDetailsCard.component';
import { RouteList } from './routeList.component';
import { Member } from '../../models/Member.model';
import { JobReportModal, FullJobReport } from '../dashboardComponents/jobReportModal.component';
import * as polyline from '@mapbox/polyline';
import { DriverSelect } from './DriverSelect';
import { AvailableJobs } from './AvailableJobs';
import { RouteActions } from './RouteActions';
import { ConfirmRemoveRouteLocationModal } from './ConfirmRemoveRouteLocationModal';
const { width, height } = Dimensions.get('window');
import { ClientDetailsCard } from '../job/details/ClientDetailsCard.component';
import { LocationDetailsCard } from '../job/details/locationDetailsCard.component';
import { InventoryDetailsCard } from '../job/details/inventoryDetailsCard.component';
import { JobNotesList } from '../job/details/jobNotes.component';
import { CustomerDetailsCard } from '../job/details/customerDetailsCard.component';
import { Client } from '../../models/Client.model';
import { ServicesContainer } from '../job/services/servicesContainer.component';


export const CreateJobForRoute = ({ onJobCreated }: { onJobCreated: (job: Job) => void }) => {

    const [jobState, setJob] = useState<Job>(new Job({}));
    const jobStateRef = useRef<Job>(jobState);
    const vendorProfile = null
    const { width, height } = useWindowDimensions()


    useEffect(() => {
        let j = new Job({})
        const company = StorageController.getCurrentCompany()
        j.company_id = company._id
        j.vendor_company_id = company._id
        j.details.options.transport_job_options = true

        onSetJob(j)
    }, [])

    const onSetJob = (job: Job) => {
        const j = new Job(job);
        setJob(j);
        jobStateRef.current = j;
    }

    const onSetClient = (client: Client) => {
        let _Job = new Job(jobStateRef.current)
        _Job.client_id = client._id
        onSetJob(_Job)
    }

    const onSetClientRate = (rate: JobClientRate) => {
        let _Job = new Job(jobStateRef.current)
        _Job.setClientRate(rate)
        onSetJob(_Job)
    }

    const onSetInventory = (inventory: LineItem[]) => {
        let _Job = new Job(jobStateRef.current)
        _Job.details.report.items = inventory
        onSetJob(_Job)
    }

    const onAddNote = (note: JobNote) => {
        let _Job = new Job(jobStateRef.current)
        _Job.details.addNote(note)
        onSetJob(_Job)
    }

    const onDeleteNote = (note: JobNote) => {
        let _Job = new Job(jobStateRef.current)
        _Job.details.deleteNote(note)
        onSetJob(_Job)
    }

    const onUpdateNote = (note: JobNote) => {
        let _Job = new Job(jobStateRef.current)
        _Job.details.updateNote(note)
        onSetJob(_Job)
    }

    const onSetLocation = (location: JobLocation) => {
        let _Job = new Job(jobStateRef.current)
        _Job.details.location = location
        onSetJob(_Job)
    }

    const onSetAddress = (address: JobAddress) => {
        let _Job = new Job(jobStateRef.current)
        _Job.details.address = address
        onSetJob(_Job)
    }


    const onSetCustomerDetails = (customer_details: JobCustomerDetails) => {
        let _Job = new Job(jobStateRef.current)
        _Job.details.customer_details = customer_details
        onSetJob(_Job)
    }

    const createJob = async () => {
        try{
            console.log('jobStateRef.current', jobStateRef.current)
            // return
            const job = await JobController.createJob(jobStateRef.current)
            if (!job) {
                throw new Error('Job not created')
                return
            }
            onJobCreated(job)
            console.log('job created', job)
        } catch (error) {
            console.log(error)
        }
    }


    const updateJobServices = (services: Service[]) => {
        let _Job = new Job(jobStateRef.current)
        _Job.details.selected_services = services
        onSetJob(_Job)
    }





    return (
        <Layout style={{ padding:10, width: width *0.6, height: height *0.8 }}>

            <ScrollView>
                <Layout style={{  }}>
                    <Text>Create Job for Route</Text>
                    <ClientDetailsCard
                        job={jobState}
                        clientReferenceId={jobState.client_reference_id}
                        fn_onSetClient={onSetClient}
                        fn_onSetClientRate={onSetClientRate}
                        fn_onSetClientRefrerence={(value) => {
                            const j = new Job(jobState);
                            j.client_reference_id = value;
                            onSetJob(j);
                        }}
                        style={{}}
                        isNewJob={true}
                        jobCreateSource={'vendor'}
                        allowEdit={true}
                        onInputFocus={() => { }}
                        jobCreatedBy={''}
                        setRefs={function (e: any): void {
                        }} />

                    <CustomerDetailsCard
                        style={{}}
                        customerDetails={jobState.details.customer_details}
                        onChangeCustomerDetails={onSetCustomerDetails}
                        handleKeyPress={() => { }}
                        setRefs={() => { }}
                        onInputFocus={() => { }}
                        allowEdit={true}
                    />
                    <ServicesContainer
                        fn_updateJobServices={updateJobServices}
                        var_selectedServices={jobState.details.selected_services}
                        allowEdit={true}
                        vendorCompanyId={null}
                        var_randUpdate={undefined}
                        style={{}}
                    />
                    <InventoryDetailsCard
                        selectedItems={jobState.details.report.items}
                        onChangeSelectedItems={onSetInventory}
                        style={{}}
                        allowEdit={true}
                        vendorProfile={vendorProfile}
                        jobSource={'vendor'}
                    />
                    <LocationDetailsCard
                        address={jobState.details.address}
                        location={jobState.details.location}
                        onSetLocation={onSetLocation}
                        onSetAddress={onSetAddress}
                        searchDisplayValue={jobState.details.address.formatted_address}
                        allowEdit={true}
                        cardTitle='Job Location'
                        style={{}}
                    />
                    <JobNotesList
                        notes={jobState.details.notes}
                        onAddNote={onAddNote}
                        onDeleteNote={onDeleteNote}
                        onUpdateNote={onUpdateNote}
                    />
                    <Button onPress={createJob}>Create Job</Button>
                </Layout>
            </ScrollView>
        </Layout>
    )

}
