import React, { useState, useEffect, useRef, useReducer, useCallback, Profiler, useMemo, memo, useContext } from 'react';
import {
    AccessibilityRole,
    ImageProps,
    ImageStyle,
    Image,
    StyleSheet,
    TouchableOpacity,
    View,
    Text as RNText,
    Platform,
    useWindowDimensions,
    ScrollView,
    Dimensions,
    Alert
    //@ts-ignore
} from "react-native";
import {
    ApplicationProvider,
    Button,
    Icon,
    IconRegistry,
    Layout,
    Text,
    List,
    ListItem,
    Card,
    Input,
    Select,
    SelectItem,
    Toggle,
    Divider
} from "@ui-kitten/components";
//@ts-ignore
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import ErrorBoundary from '../../ErrorBoundary.component';
import { Job } from '../../../models/Job.model';


interface OptionsDetailsCardProps {
    job: Job;
    style?: object;
    onSetDispatchable?: (e: boolean) => void;
    onSetIsScheduled?: (e: boolean) => void;
    onSet_start_time?: (e: number) => void;
    onSetVendorEta?: (e: any) => void;
    onSetPriority?: (e: { name: string, value: number }) => void;
    allowEdit?: boolean;
}

export const OptionsDetailsCard = ({
    job,
    style = { flex: 1 },
    onSetDispatchable = (e) => { },
    onSetIsScheduled = (e) => { },
    onSet_start_time = (e) => { },
    onSetVendorEta = (e) => { },
    onSetPriority = (e) => { },
    allowEdit = true,
}: OptionsDetailsCardProps) => {


    const RenderScheduledDateTimePicker = () => {
        let startTime = new Date(job.start_time || new Date().getTime())
        return (
            <Layout style={{ height: "100%", width: "100%" }}>
                <DatePicker
                    selected={startTime}
                    onChange={(date: Date) =>
                        // onSetDateTimeChanged(date)
                        onSet_start_time(date.getTime())
                    }
                    showTimeSelect
                    timeIntervals={15}
                    shouldCloseOnSelect={false}
                    minDate={new Date()}
                    inline
                    // fixedHeight
                    dateFormat="d MMMM, yyyy h:mm aa"
                />
            </Layout>
        )
    }

    return (

        <Card
            disabled
            header={() => <Text style={{ alignSelf: 'center' }}>Job Options</Text>}
            style={[style, { flex: 1 }]}
            status='primary'
        >
            <View style={{ flexDirection: 'column', justifyContent: 'space-between' }}>
                <View style={{ flexDirection: 'row', margin: 10 }}>

                    <Text adjustsFontSizeToFit={true} style={{ flex: 1 }}>
                        Dispatchable
                    </Text>
                    <Toggle
                        style={{ flex: 1, margin: 5 }}
                        checked={job.dispatchable}
                        disabled={!allowEdit}
                        onChange={(nextChecked: boolean) => {
                            onSetDispatchable(nextChecked)
                        }}>
                    </Toggle>
                </View>
                <Divider />
                <View style={{ flexDirection: 'row', margin: 10 }}>

                    <Text adjustsFontSizeToFit={true} style={{ flex: 1 }}>
                        Scheduled Job
                    </Text>
                    <Toggle
                        style={{ flex: 1, margin: 5 }}
                        checked={job.is_scheduled}
                        disabled={!allowEdit}
                        onChange={(nextChecked: boolean) => {
                            onSetIsScheduled(nextChecked)
                        }}>

                    </Toggle>
                </View>
                {job.is_scheduled ?
                    <Layout style={{}}>
                        <RenderScheduledDateTimePicker />
                    </Layout>
                    : null}
                <Divider />
                <View style={{ flexDirection: 'row', margin: 10 }}>

                    <Text
                        style={{ flex: 1 }}
                        category="label"
                    >
                        ETA (Minutes):
                    </Text>
                    <Input
                        style={{ flex: 1 }}
                        size="small"
                        placeholder='ETA'
                        disabled={!allowEdit}
                        onBlur={(e: any) => onSetVendorEta(e.nativeEvent.text)}
                        defaultValue={job.vendor_eta ? job.vendor_eta.toString() : "60"}
                    />
                </View>
                <View style={{ flexDirection: 'row', margin: 10 }}>
                    <Select
                        label={"Priority"}
                        size='small'
                        placeholder='Priority'
                        value={job.details.priority?.name || "Normal"}
                        onSelect={(indexPath: any) => {
                            let valueArr = ["Normal", "High", "Low"]
                            let index = indexPath.row
                            let value = valueArr[index]
                            const priority = {
                                name: value,
                                value: index
                            }
                            onSetPriority(priority)
                        }}
                    >
                        <SelectItem title='Normal' />
                        <SelectItem title='High' />
                        <SelectItem title='Low' />
                    </Select>
                </View>
            </View>
        </Card>

    )

}
