import React, { useState, useEffect, useRef, useReducer, useCallback, useMemo, createContext, useContext } from 'react';
//@ts-ignore
import { useWindowDimensions, StyleSheet, View, TouchableOpacity } from "react-native";
import { SafeAreaView } from 'react-native-safe-area-context';
//@ts-ignore
import { Divider, Icon, Layout, Text, Card, Button, Input, Modal, useTheme, Select, SelectItem, List, ListItem, CheckBox } from '@ui-kitten/components';
import ErrorBoundary from '../ErrorBoundary.component';
import * as JobsController from '../../functions/job.controller'
import * as MembershipsController from '../../functions/membership.controller'
import * as UpdateController from '../../functions/update.controller'
import * as ImageController from '../../functions/image.controller'
import * as ClientController from '../../functions/client.controller'
import * as StorageController from '../../functions/storageController'
import * as CompanyController from '../../functions/company.controller'
import * as VendorProfileController from '../../functions/vendorProfile.controller'
import * as ServiceController from '../../functions/service.controller'
import { ServiceAreaEdit } from '../settings/serviceAreaEdit.component'
import { NewVendor } from './newVendor.component';
import { Company } from '../../models/Company.model';
import { useAppStateChange, IAction, STATE_ACTIONS } from '../../hooks/appStateChange.hook';
import { VendorProfile } from '../../models/VendorProfile.model';
import { Service } from '../../models/Job.model';

interface VendorProfileContainerScreenProps {
    navigation: any;
}

export const VendorProfileContainerScreen: React.FC<VendorProfileContainerScreenProps> = ({ navigation }) => {

    const [vendorProfiles, setVendorProfiles] = useState<VendorProfile[]>([])
    const [selectedVendorProfile, setSelectedVendorProfile] = useState<VendorProfile | null>(null)
    const [showNewVendorProfileModal, setShowNewVendorProfileModal] = useState<boolean>(false)
    const [showServiceAreaModal, setShowServiceAreaModal] = useState<boolean>(false)
    const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false)

    const [listOfLinkedServices, setListOfLinkedServices] = useState<Service[]>([])
    const [listOfLinkedServiceTags, setListOfLinkedServiceTags] = useState<string[]>([])
    const [vendorLinkedCompany, setVendorLinkedCompany] = useState<Company | null>(null)
    const vendorProfileRef = useRef<VendorProfile | null>(null)

    const [selectedCompany, setSelectedCompany] = useState<Company | null>(null)

    useEffect(() => {
        const unsubscribe = navigation.addListener('focus', () => {
            setSelectedCompany(StorageController.getCurrentCompany())
            load()
        });
        return unsubscribe;
    }, [navigation])

    const load = async () => {
        try {
            const profiles = await VendorProfileController.getVendorProfilesByCompanyId(StorageController.getCurrentCompany()?._id)
            setVendorProfiles(profiles)
        } catch (e) {
            console.log(e)
        }
    }

    const handleCompanyChangeEvent = useCallback((action: UpdateController.IAction) => {
        setSelectedCompany(new Company(action.data))
        setVendorProfiles([])
        setSelectedVendorProfile(null)
        setUnsavedChanges(false)
        setListOfLinkedServiceTags([])
        setListOfLinkedServices([])
        setVendorLinkedCompany(null)
        vendorProfileRef.current = null
        setTimeout(async () => {
            load()
        }, 100);
    }, [selectedCompany]);

    const { dispatchEventStateChange } = useAppStateChange({
        onUpdatedSelectedCompany: handleCompanyChangeEvent,
    })

    const onSetSelectedVendorProfile = async (vendorProfile: VendorProfile | null, changes = true) => {
        vendorProfileRef.current = vendorProfile
        setSelectedVendorProfile(vendorProfile)
        setUnsavedChanges(changes)
    }

    const onSaveVendorProfile = () => {
        if (!vendorProfileRef.current) return
        VendorProfileController.updateVendorProfile(vendorProfileRef.current._id, vendorProfileRef.current)
        setUnsavedChanges(false)
        load()
    }

    const onCancel = () => {
        onSetSelectedVendorProfile(null, false)
    }

    const onSelectVendorProfile = async (vendorProfile: VendorProfile | null) => {
        onSetSelectedVendorProfile(vendorProfile, false)
        if (vendorProfile?.linkedVendorAccountId) {
            const services = await ServiceController.getServicesByCompanyId(vendorProfile.linkedVendorAccountId)
            const vendorCompany = await CompanyController.getCompanyById(vendorProfile.linkedVendorAccountId)
            vendorLinkIdTextRef.current = vendorProfile.linkedVendorAccountId
            let tags: string[] = []
            services.forEach((service: Service) => {
                tags = [...tags, ...service.tags]
                tags = [...new Set(tags)]
            });
            setListOfLinkedServiceTags(tags)
            setListOfLinkedServices(services)
            setVendorLinkedCompany(vendorCompany || null)
        } else {
            setListOfLinkedServices([])
            vendorLinkIdTextRef.current = ""
        }
    }

    const checkCanEditFields = (): boolean => {
        if (!selectedVendorProfile) return false;
        let canEdit = false

        if (StorageController.getAppState().selectedMembership?.is_admin) {
            canEdit = true

            if (!selectedVendorProfile.deleted) {
                canEdit = true
            } else {
                canEdit = false
            }
        };
        return canEdit
    }



    const serviceTags: string[] = [
        "Battery",
        "Battery EV",
        "Delivery",
        "Tow",
        "Tyre",
        "Lockout",
        "Fuel",
        "Winch",
        "Recovery",
        "Other",
    ]

    const RenderCreateNewVendorProfileModal: React.FC = () => {
        let newVendorProfile = new VendorProfile({})
        newVendorProfile.company_id = StorageController.getAppState().selectedCompany?._id || null

        return (
            <Modal
                visible={showNewVendorProfileModal}
                backdropStyle={styles.backdrop}
                onBackdropPress={() => setShowNewVendorProfileModal(false)}
            >
                <View style={{ flexDirection: 'column' }}>
                    <NewVendor
                        onAddVendor={(vendor: VendorProfile) => {
                            setShowNewVendorProfileModal(false)
                            load()
                        }}
                        onClose={() => { setShowNewVendorProfileModal(false); load() }}
                    />
                </View>
            </Modal>
        )
    }

    const onServiceAreaSave = async (serviceArea: any) => {
        onSetSelectedVendorProfile({ ...selectedVendorProfile, service_areas: [serviceArea] } as VendorProfile)
        setShowServiceAreaModal(false)
    }

    const RenderServiceAreaModal: React.FC = () => {
        return (
            <Modal
                visible={showServiceAreaModal}
                backdropStyle={styles.backdrop}
                style={{ width: '90%', height: '90%' }}
                onBackdropPress={() => setShowServiceAreaModal(false)}>
                <Card disabled={true}>
                    <ServiceAreaEdit
                        fn_onSave={(serviceArea) => onServiceAreaSave(serviceArea)}
                        var_serviceArea={selectedVendorProfile?.service_areas?.[0]}
                    />
                </Card>
                <Button onPress={() => setShowServiceAreaModal(false)}>Close</Button>
            </Modal>
        )
    }

    const linkExistingVendorAccount = async (linkedVendorAccountId: string) => {
        try {
            const vendorProfileLinkResult = await VendorProfileController.linkExistingVendorAccount(selectedVendorProfile!._id, linkedVendorAccountId)
            if (vendorProfileLinkResult) {
                onSetSelectedVendorProfile({ ...selectedVendorProfile, linkedVendorAccountId: linkedVendorAccountId } as VendorProfile)
                onSaveVendorProfile()
            }
        } catch (e) {
            console.log(e)
        }
    }

    const [companyFoundByLinkId, setCompanyFoundByLinkId] = useState<Company | null>(null)
    const [showCompanyLinkModal, setShowCompanyLinkModal] = useState<boolean>(false)
    const vendorLinkIdTextRef = useRef<string | null>(null)
    const validateVendorLinkId = async (vendorLinkId: string) => {
        try {
            const company = await CompanyController.getCompanyById(vendorLinkId)
            if (company) {
                setCompanyFoundByLinkId(company)
                setShowCompanyLinkModal(true)
            }
        } catch (e) {
            console.log(e)
        }
    }

    const RenderCompanyLinkModal: React.FC = () => {
        return (
            <Modal
                visible={showCompanyLinkModal}
                backdropStyle={styles.backdrop}
                onBackdropPress={() => setShowCompanyLinkModal(false)}
            >
                <View style={{ flexDirection: 'column' }}>
                    <Text category='h6'>Company found with this link id</Text>
                    <Text>Are you sure you want to link this vendor? {companyFoundByLinkId?.name || ""}</Text>
                    <View style={{ flexDirection: 'row' }}>
                        <Button onPress={() => {
                            setShowCompanyLinkModal(false)
                        }}>Cancel</Button>
                        <Button onPress={() => {
                            setShowCompanyLinkModal(false)
                            linkExistingVendorAccount(companyFoundByLinkId!._id)
                        }}>Link</Button>
                    </View>
                </View>
            </Modal>
        )
    }

    const [showNewRateModal, setShowNewRateModal] = useState<boolean>(false);
    const [showEditRateModal, setShowEditRateModal] = useState<boolean>(false);
    const [showConfirmDeleteRateModal, setShowConfirmDeleteRateModal] = useState<boolean>(false);
    const [rateToDelete, setRateToDelete] = useState<number | null>(null);
    const newRateNameRef = useRef<string>('');
    const newRateCostRef = useRef<number | string>('');
    const editRateIndexRef = useRef<number | null>(null);

    const handleAddRate = () => {
        if (!selectedVendorProfile) return
        let _vendorProfile = { ...selectedVendorProfile }
        if (!_vendorProfile.settings) _vendorProfile.settings = { job_options: [], create_job_mandatory_fields: [], rates: [] }
        if (!_vendorProfile.settings.rates) _vendorProfile.settings.rates = []
        let newRate = {
            name: newRateNameRef.current,
            cost: newRateCostRef.current,
        }
        const updatedVendorProfile = { ..._vendorProfile, settings: { ..._vendorProfile!.settings, rates: [..._vendorProfile!.settings.rates, newRate] } }
        onSetSelectedVendorProfile(updatedVendorProfile as VendorProfile, true)
        newRateNameRef.current = ''
        newRateCostRef.current = ''
    }

    const onDeleteRate = (index: number) => {
        setRateToDelete(index);
        setShowConfirmDeleteRateModal(true);
    };

    const handleDeleteRate = (index: number) => {
        const newRates = [...selectedVendorProfile!.settings.rates]
        newRates.splice(index, 1)
        const updatedVendorProfile = { ...selectedVendorProfile, settings: { ...selectedVendorProfile!.settings, rates: newRates } }
        onSetSelectedVendorProfile(updatedVendorProfile as VendorProfile, true)
    }

    const onEditRate = (index: number) => {
        editRateIndexRef.current = index
        newRateNameRef.current = selectedVendorProfile!.settings.rates[index].name
        newRateCostRef.current = selectedVendorProfile!.settings.rates[index].cost
        setShowEditRateModal(true)
    }

    const handleEditRate = () => {
        const newRates = [...selectedVendorProfile!.settings.rates]
        newRates[editRateIndexRef.current!] = {
            _id: new Date().getTime().toString(),
            name: newRateNameRef.current,
            cost: Number(newRateCostRef.current),
        }
        const updatedVendorProfile = { ...selectedVendorProfile, settings: { ...selectedVendorProfile!.settings, rates: newRates } }
        onSetSelectedVendorProfile(updatedVendorProfile as VendorProfile, true)
        newRateNameRef.current = ''
        newRateCostRef.current = ''
        setShowEditRateModal(false)
    }

    const EditRateModal: React.FC = () => {
        const [error, setError] = useState<string>("");
        return (
            <Modal
                visible={showEditRateModal}
                backdropStyle={styles.backdrop}
                onBackdropPress={() => setShowEditRateModal(false)}
            >
                <Card disabled={true}>
                    <Input
                        label='Name'
                        defaultValue={newRateNameRef.current}
                        onChangeText={(value: string) => newRateNameRef.current = value}
                    />
                    <Input
                        label='Cost'
                        defaultValue={newRateCostRef.current.toString()}
                        onChangeText={(value: string) => {
                            let val = Number(value)
                            if (isNaN(val)) {
                                setError('Cost must be a number')
                            } else {
                                setError('')
                                newRateCostRef.current = val
                            }
                        }}
                    />
                    <Text style={{ color: "red" }}>{error}</Text>
                    <Layout style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Button onPress={() => setShowEditRateModal(false)}>
                            Cancel
                        </Button>
                        <Button onPress={() => {
                            if (error.length > 0) return
                            setShowEditRateModal(false)
                            handleEditRate()
                        }}>
                            Save
                        </Button>
                    </Layout>
                </Card>
            </Modal>
        )
    };

    const NewRateModal: React.FC = () => {
        const [error, setError] = useState<string>("");
        return (
            <Modal
                visible={showNewRateModal}
                backdropStyle={styles.backdrop}
                onBackdropPress={() => setShowNewRateModal(false)}
            >
                <Card disabled={true}>
                    <Input
                        label='Name'
                        defaultValue={newRateNameRef.current}
                        onChangeText={(value: string) => newRateNameRef.current = value}
                    />
                    <Input
                        label='Cost'
                        defaultValue={newRateCostRef.current.toString()}
                        onChangeText={(value: string) => {
                            let val = Number(value)
                            if (isNaN(val)) {
                                setError('Cost must be a number')
                            } else {
                                setError('')
                                newRateCostRef.current = val
                            }
                        }}
                    />
                    <Text style={{ color: "red" }}>{error}</Text>
                    <Layout style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Button onPress={() => setShowNewRateModal(false)}>
                            Cancel
                        </Button>
                        <Button onPress={() => {
                            if (error.length > 0) return
                            setShowNewRateModal(false)
                            handleAddRate()
                        }}>
                            Add
                        </Button>
                    </Layout>
                </Card>
            </Modal>
        )
    };

    const DeleteRateModal: React.FC = () => {
        return (
            <Modal
                visible={showConfirmDeleteRateModal}
                backdropStyle={styles.backdrop}
                onBackdropPress={() => setShowConfirmDeleteRateModal(false)}
            >
                <Card disabled={true}>
                    <Text category='h6'>
                        Delete rate?
                    </Text>
                    <Text>
                        Are you sure you want to delete this rate?
                    </Text>
                    <Layout style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Button onPress={() => setShowConfirmDeleteRateModal(false)}>
                            Cancel
                        </Button>
                        <Button onPress={() => {
                            setShowConfirmDeleteRateModal(false)
                            handleDeleteRate(rateToDelete!)
                        }}>
                            Delete
                        </Button>
                    </Layout>
                </Card>
            </Modal>
        )
    };

    return (
        <Layout
            key={selectedCompany?._id}
            style={{ flex: 1, flexDirection: 'row' }}>
            <RenderCreateNewVendorProfileModal />
            <RenderServiceAreaModal />
            <RenderCompanyLinkModal />
            <EditRateModal />
            <DeleteRateModal />
            <NewRateModal />
            <Layout style={{ flex: 1, borderWidth: 1, borderColor: "#000" }}>
                <Button
                    onPress={() => setShowNewVendorProfileModal(true)}
                >New Vendor</Button>
                <Layout style={{ flex: 1, borderWidth: 1, borderColor: "#000" }}>
                    <List
                        data={vendorProfiles}
                        renderItem={({ item }: { item: VendorProfile }) => (
                            <ListItem
                                title={item.linkedVendorAccountId ? `${item.name} - Linked` : `${item.name} - Unlinked`}
                                description={item.details}
                                accessoryLeft={() => <CheckBox checked={item._id === selectedVendorProfile?._id} />}
                                onPress={() => onSelectVendorProfile(item)}
                            />
                        )}
                    />
                </Layout>
            </Layout>
            <Layout style={{ flex: 5 }}>
                <Layout style={{ flex: 1, borderWidth: 1, borderColor: "#000" }}>
                    {
                        selectedVendorProfile &&
                        <View style={{ flex: 1, flexDirection: 'column' }}>
                            <Layout style={{ flexDirection: 'row' }}>
                                <Layout style={{ flex: 3 }}>
                                    {
                                        unsavedChanges &&
                                        <Text
                                            style={{ color: 'red' }}
                                            category='h6'
                                        >Unsaved Changes</Text>
                                    }
                                </Layout>
                                <Layout style={{ flex: 1 }}>
                                    <Button
                                        onPress={() => { onSaveVendorProfile() }}
                                        accessoryLeft={(props: any) => <Icon {...props} name="save-outline" />}
                                        status="success"
                                    />
                                </Layout>
                                <Layout style={{ flex: 1 }}>
                                    <Button
                                        onPress={onCancel}
                                        accessoryLeft={(props: any) => <Icon {...props} name="close-outline" />}
                                        status="warning"
                                    />
                                </Layout>
                            </Layout>
                            <View style={{ flex: 1, flexDirection: 'row' }}>
                                <View style={{ flex: 1, flexDirection: 'column' }}>
                                    {vendorLinkedCompany &&
                                        <Card
                                            disabled={true}
                                            style={{ flex: 1 }}
                                            status='basic'
                                            header={() => <Text category='h6'>Vendors Contact details</Text>}
                                        >
                                            <Layout style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                                <Text category='label'>Name</Text>
                                                <Text>{vendorLinkedCompany.name}</Text>
                                            </Layout>
                                            <Layout style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                                <Text category='label'>Email</Text>
                                                <Text>{vendorLinkedCompany.settings?.contact_info?.email}</Text>
                                            </Layout>
                                            <Layout style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                                <Text category='label'>Phone</Text>
                                                <Text>{vendorLinkedCompany.settings?.contact_info?.phone}</Text>
                                            </Layout>
                                        </Card>
                                    }

                                    <Card
                                        disabled={true}
                                        style={{ flex: 1 }}
                                        status='basic'
                                        header={() => <Text category='h6'>Details</Text>}
                                    >
                                        <Input
                                            label='Name'
                                            value={selectedVendorProfile.name}
                                            onChangeText={(nextValue: string) => onSetSelectedVendorProfile({ ...selectedVendorProfile, name: nextValue })}
                                        />
                                        <Input
                                            label='Details'
                                            value={selectedVendorProfile.details}
                                            onChangeText={(nextValue: string) => onSetSelectedVendorProfile({ ...selectedVendorProfile, details: nextValue })}
                                        />
                                    </Card>
                                    <Card
                                        disabled={true}
                                        style={{ flex: 1 }}
                                        status='basic'
                                        header={() => <Text category='h6'>Service Area</Text>}
                                    >
                                        <Button onPress={() => setShowServiceAreaModal(true)}>Service Area</Button>
                                    </Card>
                                    <Card
                                        disabled={true}
                                        style={{ flex: 1 }}
                                        status='basic'
                                        header={() => <Text category='h6'>Vendor Link ID</Text>}
                                    >
                                        {selectedVendorProfile.linkedVendorAccountId ?
                                            <View style={{ flexDirection: "column" }}>
                                                <Text category='label'>This vendor is linked</Text>
                                                <View style={{ flexDirection: 'row' }}>
                                                    <Text category='s1'>Vendor ID: </Text>
                                                    <Text category='s1' selectable>{selectedVendorProfile.linkedVendorAccountId}</Text>
                                                </View>
                                            </View>
                                            :
                                            <>
                                                <Input
                                                    label='Vendor Link ID'
                                                    defaultValue={selectedVendorProfile.linkedVendorAccountId || ""}
                                                    onChangeText={(nextValue: string) => vendorLinkIdTextRef.current = nextValue}
                                                />
                                                <Button onPress={() => validateVendorLinkId(vendorLinkIdTextRef.current!)}>Validate</Button>
                                            </>
                                        }
                                    </Card>
                                </View>
                                <View style={{ flex: 1, flexDirection: 'column' }}>

                                    <Card
                                        disabled={true}
                                        style={{ flex: 1 }}
                                        status='basic'
                                        header={() => <Text category='h6'>Other</Text>}
                                    >
                                        <View style={{ flexDirection: "row" }}>
                                            <Input
                                                label='Schedule'
                                                defaultValue={selectedVendorProfile.other?.schedule || ""}
                                                multiline={true}
                                                textStyle={{ minHeight: 64 }}
                                                onChangeText={(nextValue: string) => onSetSelectedVendorProfile({ ...selectedVendorProfile, other: { ...selectedVendorProfile.other, schedule: nextValue } })}
                                            />
                                        </View>
                                    </Card>
                                    <Card
                                        disabled={true}
                                        style={{ flex: 1 }}
                                        status='basic'
                                        header={() => <Text category='h6'>Vendor Rates</Text>}
                                    >
                                        <Layout style={{ margin: -15 }}>
                                            <Button onPress={() => setShowNewRateModal(true)} disabled={!checkCanEditFields()} status="success">Add Rate</Button>
                                            {selectedVendorProfile.settings?.rates?.map((rate, index) => (
                                                <Card key={index} disabled={true}>
                                                    <Layout style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                                        <Text category='label'>{rate.name}</Text>
                                                        <Text>${rate.cost}</Text>
                                                        <Button onPress={() => onEditRate(index)} size='tiny' disabled={!checkCanEditFields()}>
                                                            <Icon name='edit-2-outline' width={15} height={15} status="info" />
                                                        </Button>
                                                        <Button onPress={() => onDeleteRate(index)} size='tiny' status="danger" disabled={!checkCanEditFields()}>
                                                            <Icon name='trash-2-outline' width={15} height={15} />
                                                        </Button>
                                                    </Layout>
                                                </Card>
                                            ))}
                                        </Layout>
                                    </Card>
                                </View>

                                {
                                    !selectedVendorProfile.linkedVendorAccountId &&
                                    <Card
                                        disabled={true}
                                        style={{ flex: 1 }}
                                        status='basic'
                                        header={() => <Text category='h6'>Services Provided</Text>}
                                    >
                                        <Text category='label'>These are the services this vendor provides.</Text>
                                        <Text >As an unlinked vendor, these will need to be selected to show in filtering</Text>
                                        {
                                            serviceTags.map((tag, index) => {
                                                return (
                                                    <CheckBox
                                                        key={index}
                                                        checked={selectedVendorProfile.services_provided.includes(tag)}
                                                        onChange={(nextChecked: boolean) => {
                                                            if (nextChecked) {
                                                                onSetSelectedVendorProfile({ ...selectedVendorProfile, services_provided: [...selectedVendorProfile.services_provided, tag] })
                                                            } else {
                                                                onSetSelectedVendorProfile({ ...selectedVendorProfile, services_provided: selectedVendorProfile.services_provided.filter((item: string) => item !== tag) })
                                                            }
                                                        }}
                                                    >
                                                        {tag}
                                                    </CheckBox>
                                                )
                                            })
                                        }
                                    </Card>
                                }
                                {
                                    selectedVendorProfile.linkedVendorAccountId &&
                                    <Card
                                        disabled={true}
                                        style={{ flex: 1 }}
                                        status='basic'
                                        header={() => <Text category='h6'>Linked Services</Text>}
                                    >
                                        <Text>The services this vendor provides. Select to add to this contract.</Text>
                                        <List
                                            data={listOfLinkedServiceTags}
                                            renderItem={({ item }: { item: string }) => (
                                                <ListItem
                                                    title={item}
                                                    onPress={() => {
                                                        if (selectedVendorProfile.services_provided.includes(item)) {
                                                            onSetSelectedVendorProfile({ ...selectedVendorProfile, services_provided: selectedVendorProfile.services_provided.filter((service: string) => service !== item) })
                                                        } else {
                                                            onSetSelectedVendorProfile({ ...selectedVendorProfile, services_provided: [...selectedVendorProfile.services_provided, item] })
                                                        }
                                                    }}
                                                    accessoryLeft={() =>
                                                        <CheckBox
                                                            checked={selectedVendorProfile.services_provided.includes(item)}
                                                            onChange={(nextChecked: boolean) => {
                                                                if (nextChecked) {
                                                                    onSetSelectedVendorProfile({ ...selectedVendorProfile, services_provided: [...selectedVendorProfile.services_provided, item] })
                                                                } else {
                                                                    onSetSelectedVendorProfile({ ...selectedVendorProfile, services_provided: selectedVendorProfile.services_provided.filter((service: string) => service !== item) })
                                                                }
                                                            }}
                                                        />
                                                    }
                                                />
                                            )}
                                        />
                                    </Card>
                                }
                                <Card
                                    disabled={true}
                                    style={{ flex: 1 }}
                                    status='basic'
                                    header={() => <Text category='h6'>Services Selected</Text>}
                                >
                                    <Text>The services selected for this contract.</Text>
                                    <List
                                        data={selectedVendorProfile.services_provided}
                                        renderItem={({ item }: { item: string }) => (
                                            <ListItem
                                                title={item}
                                            />
                                        )}
                                    />
                                </Card>
                            </View>
                        </View>
                    }
                </Layout>
            </Layout>
        </Layout>
    )
}

const styles = StyleSheet.create({
    backdrop: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
});