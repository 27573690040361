import React, { useState, useEffect, useReducer } from 'react';
//@ts-ignore
import { StyleSheet, Text as RNText, Platform, useWindowDimensions, View } from "react-native";
//@ts-ignore
import { Button, Icon, Layout, Card, Input, Modal, Text, CheckBox, Divider } from "@ui-kitten/components";

import * as ClientController from "../../functions/client.controller";
import * as CompanyController from "../../functions/company.controller";
import * as StorageController from "../../functions/storageController";
import { Client } from '../../models/Client.model';
import { Company } from '../../models/Company.model';

const useInputState = (initialValue = '') => {
    const [value, setValue] = React.useState(initialValue);
    return { value, onChangeText: setValue };
};


interface NewClientScreenProps {
    onClose: any,
    onAddClient: any
}

export const NewClientScreen = ({
    onClose,
    onAddClient
}: NewClientScreenProps) => {
    const [ignored, forceUpdate] = useReducer(x => x + 1, 0);
    const [modalVisible, setModalVisible] = useState(false);

    const inputClientName = useInputState();
    const inputClientEmail = useInputState();
    const inputClientPhone = useInputState();
    const inputClientDetails = useInputState();
    const inputClientAddress = useInputState();
    const inputClientAccountCode = useInputState();
    const inputClientUsername = useInputState();


    const clientAccountLinkingId = useInputState();
    const [showConfirmLinkClientAccount, setShowConfirmLinkClientAccount] = useState(false)
    const [validLinkedCompany, setValidLinkedCompany] = useState(null as Company | null)

    const [checkboxIsAdmin, setCheckboxIsAdmin] = useState(false);
    const [checkboxIsDriver, setCheckboxIsDriver] = useState(false);
    const [checkboxIsOperator, setCheckboxIsOperator] = useState(false);



    useEffect(
        () => {
            // console.log("New client Modal", props)
        },
        []
    )

    //function to verify inputs
    const verifyInputs = () => {
        if (inputClientName.value === '') {
            alert("Please enter a name")
            return false
        }
        if (inputClientEmail.value === '') {
            alert("Please enter an email")
            return false
        }
        if (inputClientDetails.value === '') {
            alert("Please enter details")
            return false
        }
        if (inputClientAccountCode.value === '') {
            alert("Please enter an account code eg 200")
            return false
        }
        // if (inputClientPhone.value === '') {
        //     alert("Please enter a phone number")
        //     return false
        // }
        // if (inputClientAddress.value === '') {
        //     alert("Please enter an account code eg 200")
        //     return false
        // }
        return true
    }



    const checkAccountLinkingCode = async () => {
        if (clientAccountLinkingId.value === '') {
            alert("Please enter a code")
            return false
        }
        const company = await CompanyController.getCompanyById(clientAccountLinkingId.value)
        // console.log("🚀 ~ file: newVendor.component.js:153 ~ checkAccountLinkingCode ~ company", company)
        if (company && company._id) {
            setValidLinkedCompany(company)
            setShowConfirmLinkClientAccount(true)
        }
        else {
            setValidLinkedCompany(null)
            alert("No company found")
        }
    }

    const linkClientAccount = async () => {
        if (validLinkedCompany) {
            setShowConfirmLinkClientAccount(false)
            inputClientName.onChangeText(validLinkedCompany.name)
            const result = await ClientController.createClientProfileByLinkingId(validLinkedCompany._id)
            // console.log("🚀 ~ file: newVendor.component.js:169 ~ linkVendorAccount ~ result", result)
            onClose()
            // inputVendorName.value = validLinkedCompany.name
        }
    }


    const verifyAndSubmit = () => {
        if (verifyInputs()) {
            let newClient = new Client({
                name: inputClientName.value,
                contact_info: {
                    email: inputClientEmail.value,
                    phone: inputClientPhone.value,
                    address: inputClientAddress.value
                },
                details: inputClientDetails.value,
                account_code: inputClientAccountCode.value
            })
            // console.log("New client", newClient)
            ClientController.createClient(newClient)
                .then(() => {
                    // alert("Client created")
                    onAddClient()
                    onClose()
                })
                .catch((err) => {
                    console.log(err)
                    alert("Error creating client")
                })
        }
        // console.log("in verify and submit")
    }

    const [showLinkingArea, setShowLinkingArea] = useState(false)

    return (
        <Layout style={{ flex: 1, flexDirection: 'column', padding: 20, border: '1px solid black' }}>
            <Modal
                visible={showConfirmLinkClientAccount}
                backdropStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
                onBackdropPress={() => setShowConfirmLinkClientAccount(false)}>
                <Card disabled={true}>
                    <Text>Are you sure you want to link this account?</Text>
                    <Text>Company Name: {validLinkedCompany ? validLinkedCompany.name : ""}</Text>
                    <Layout style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Button
                            appearance='outline'
                            status='danger'
                            onPress={() => { setShowConfirmLinkClientAccount(false) }}>
                            Cancel
                        </Button>
                        <Button

                            appearance='outline'
                            status='success'
                            onPress={() => { linkClientAccount() }}>
                            Link
                        </Button>
                    </Layout>
                </Card>
            </Modal>
            <Layout style={{ flex: 1 }}>
                <Text category='h6'>
                    Add Client:
                </Text>
            </Layout>
            <Layout style={{ flex: 1, flexDirection: 'column', justifyContent: 'space-between' }}>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Text category='s1'>
                        Link Client Account: If client has provided a linking code (Optional)
                    </Text>
                    {!showLinkingArea &&
                        <Button
                            appearance='outline'
                            status='info'
                            onPress={() => { setShowLinkingArea(true) }}>
                            Link
                        </Button>
                    }
                </View>
                {showLinkingArea &&
                    <Layout style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Input
                            style={styles.input}
                            status='primary'
                            placeholder='Account Linking Id'
                            label='Account Linking Id'
                            {...clientAccountLinkingId}
                        />
                        <Button
                            appearance='outline'
                            status='success'
                            onPress={() => { checkAccountLinkingCode() }}>
                            Check
                        </Button>
                    </Layout>
                }
            </Layout>
            <Divider />
            <Card
                style={{ flex: 1, margin: 2 }}
                status='primary'
                disabled={true}
                header={(headerProps: any) => <Text {...headerProps}>Client Details</Text>}>
                <Layout style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Input
                        style={styles.input}
                        status='primary'
                        placeholder='Name'
                        label='Client Name'
                        {...inputClientName}
                    />
                    <Input
                        style={styles.input}
                        status='primary'
                        placeholder='Email'
                        label='Client Email'
                        {...inputClientEmail}
                    />
                    <Input
                        style={styles.input}
                        status='primary'
                        placeholder='Phone'
                        label='Client Phone'
                        {...inputClientPhone}
                    />
                </Layout>
                <Layout style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Input
                        style={styles.input}
                        status='primary'
                        placeholder='Address'
                        multiline={true}
                        textStyle={{ minHeight: 64 }}
                        label='Client Address'
                        {...inputClientAddress}
                    />
                </Layout>
                <Layout style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Input
                        style={styles.input}
                        status='primary'
                        placeholder='Details'
                        label='Client Details'
                        multiline={true}
                        textStyle={{ minHeight: 64 }}
                        {...inputClientDetails}
                    />
                    <Input
                        style={styles.input}
                        status='primary'
                        placeholder='Account Code'
                        label='Account Code'
                        {...inputClientAccountCode}
                    />
                </Layout>
                <Layout style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between', gap: 10 }}>
                    <Button
                        appearance='outline'
                        status='danger'
                        onPress={() => { onClose() }}>
                        Cancel
                    </Button>
                    <Button
                        appearance='outline'
                        status='success'
                        onPress={() => { verifyAndSubmit() }}>
                        Create
                    </Button>
                </Layout>
            </Card>

        </Layout>
    )

}


const styles = StyleSheet.create({
    input: {
        flex: 1,
        margin: 2,
        padding: 6,
    },
    rowContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    controlContainer: {
        borderRadius: 4,
        margin: 2,
        padding: 6,
        backgroundColor: '#3366FF',
    },
});
