import React, { useEffect, useRef } from 'react';
import {
    Image,
    AccessibilityRole,
    ImageProps,
    ImageStyle,
    StyleSheet,
    TouchableOpacity,
    Dimensions,
    View,
    ScrollView,
    Text as RNText
    //@ts-ignore
} from "react-native";
import {
    ApplicationProvider,
    Button,
    Icon,
    IconRegistry,
    Layout,
    Text,
    List,
    ListItem,
    Card,
    Modal
} from "@ui-kitten/components";
import { SafeAreaView } from 'react-native-safe-area-context';
import { EvaIconsPack } from "@ui-kitten/eva-icons";
import { mapping, light, dark } from "@eva-design/eva";
import * as StorageController from '../../../functions/storageController';
import AssignJobList from '../assignJobList.component';
import { Job } from '../../../models/Job.model';
import { Member } from '../../../models/Member.model';

const { width, height } = Dimensions.get('window');

interface AssignJobModalProps {
    var_member_id: any;
    var_assignJobModalVisible: boolean;
    selectedJob: Job | null;
    fn_assignMember: (member: Member) => void;
    fn_dismiss: () => void;
}


const AssignJobModal = ({
    var_member_id,
    var_assignJobModalVisible,
    selectedJob,
    fn_assignMember,
    fn_dismiss
}: AssignJobModalProps) => {

    const [AssignJobvisible, SetAssignJobvisible] = React.useState(false)
    const [_selectedJob, SetSelectedJob] = React.useState(null as Job | null)

    useEffect(() => {

        console.log("Assign Job Modal Props")
        SetAssignJobvisible(var_assignJobModalVisible)
        SetSelectedJob(selectedJob)
    }, [var_assignJobModalVisible, selectedJob])

    const onAssignMember = (item: any) => {
        fn_assignMember(item)
    }


    const onDismiss = () => {
        SetAssignJobvisible(false)
        fn_dismiss()
    }


    return (
        <Modal
            visible={AssignJobvisible}
            backdropStyle={styles.backdrop}
            style={styles.assignModal}
            onBackdropPress={() => onDismiss()}>
            <Layout style={{ height: height * 0.6, width: width * 0.8, padding: 4, borderTopRightRadius: 5, borderTopLeftRadius: 5 }}>
                <View style={{ width: "100%", backgroundColor: 'aqua', height: 4, borderTopRightRadius: 5, borderTopLeftRadius: 5 }}></View>
                <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
                    <Text category='h6'>
                        Assign Driver {selectedJob && !selectedJob.dispatchable && <Text category='s1'> - (Not Dispatchable)</Text> || ""}
                    </Text>
                    <Text category='h6'>
                        {selectedJob?.friendly_id || ""}
                    </Text>
                    <Button
                        appearance='outline'
                        status='danger'
                        onPress={() => onDismiss()}
                    >
                        <Icon name='close-outline' fill='white' width={25} height={25} />
                    </Button>
                </View>
                <Layout style={{ height: "100%", borderRadius: 20 }}>
                    <View style={{ flex: 1 }}>
                        <Layout style={{ flex: 8, borderRadius: 10 }}>
                            <AssignJobList
                                fn_assignMember={onAssignMember} // has a member param
                                member_id={var_member_id}
                                selectedJob={selectedJob as Job}
                            />
                        </Layout>
                    </View>
                </Layout>
            </Layout>
            {/* </Card> */}
        </Modal>
    )
}

export default AssignJobModal;

const styles = StyleSheet.create({
    assignModal: {
        width: "80%",
        height: "80%",
        justifyContent: "center",
        alignItems: "center"
    },
    backdrop: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
});