import React, { useState, useEffect, useReducer, useCallback, useRef, useMemo } from 'react';
//@ts-ignore
import { StyleSheet, Text as RNText, Platform, useWindowDimensions, View, ScrollView } from "react-native";
import {
  Button,
  Icon,
  Layout,
  Card,
  Input,
  Modal,
  Text,
} from "@ui-kitten/components";
import * as StorageController from '../../../functions/storageController';
import * as UpdateController from '../../../functions/update.controller';
import CreateJobScreen from '../vendorJobs/newJobContainerScreen';
import { Company } from '../../../models/Company.model';



/**
 * Create Job Modal Component for vendors creating own jobs
 * Not actually a modal, since react native modal is buggy on resizing screens
 * 
 */

interface CreateJobModalProps {
  fn_onCreateJob: () => void;
  fn_onClose: () => void;
  case_id?: string | null;
  scheduledStartTime?: number | null;
}


const CreateJobModal = ({
  fn_onCreateJob,
  fn_onClose,
  case_id,
  scheduledStartTime
}: CreateJobModalProps) => {
  const [hasConfirmedCompany, setHasConfirmedCompany] = useState(false);
  const [newJobSelectedCompany, setNewJobSelectedCompany] = useState<Company | null>(null);
  const [isMinimized, setIsMinimized] = useState(false);

  useEffect(() => {
    if (case_id) {
      onSelectCompany(StorageController.getCurrentCompany());
    }

  }, []);

  const { height, width } = useWindowDimensions();

  const onSelectCompany = (company: Company) => {
    StorageController.appState.selectedCompany = company;
    StorageController.appState.selectedMembership = company.membership;
    StorageController.appState.clients = company.clients;
    StorageController.appState.services = company.services;
    StorageController.appState.members = company.members;
    UpdateController.dispatchEventStateChange({ type: 'UPDATED_SELECTED_COMPANY', data: company });
    UpdateController.dispatchEventStateChange({ type: 'UPDATED_SELECTED_MEMBERSHIP', data: company.membership });
    setNewJobSelectedCompany(company);
    setHasConfirmedCompany(true);
  };

  const onCreateJob = () => {
    setHasConfirmedCompany(false);
    fn_onCreateJob();
  };

  const onCloseModal = () => {
    setHasConfirmedCompany(false);
    fn_onClose();
  }

  const onMinimize = () => {
    setIsMinimized(true);
  }

  const onMaximize = () => {
    setIsMinimized(false);
  }

  const RenderMinimizedCard = () => {
    return (
      <View style={isMinimized ? minimizedCardVisibleStyle : minimizedCardHiddenStyle}>
        <Card
          style={{ flex: 1 }}
          onPress={() => onMaximize()}
          status='success'
        >
          <Button
            appearance='outline'
            status='success'
            style={{ marginRight: 5 }}
            onPress={() => onMaximize()}
            accessoryLeft={(props: any) => (
              <Icon {...props} name='plus' fill='green' style={{ width: 30, height: 30 }} />
            )}
          >
            <Text style={{ color: 'white' }}>Maximise Job</Text>
          </Button>
        </Card>
      </View>
    );
  }

  const minimizedCardHiddenStyle = {
    display: 'none',
  }

  const minimizedCardVisibleStyle = {
    position: 'absolute',
    top: 5,
    left: 0,
    zIndex: 999,
    width: 350,
    height: 80,
  }

  const createJobLayoutShownStyle = {
    width: width * 0.9,
    height: height * 0.9,
    borderRadius: 10,
    boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)',
    borderWidth: 1,
    borderColor: 'rgba(0, 0, 0, 0.5)',
    padding: 10,
  }

  const createJobLayoutHiddenStyle = {
    display: 'none',
  }

  const MinimizedStyle = {
    position: 'absolute',
    top: 5,
    left: 0,
    zIndex: 999,
  }

  const MaximizedStyle = {
    position: 'absolute',
    top: 5,
    left: 0,
    zIndex: 999,
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.5)',
  }

  return (
    <View
      style={isMinimized ? MinimizedStyle : MaximizedStyle}
    >
      {!hasConfirmedCompany && (
        <Layout
          style={{
            width: width * 0.3,
            height: height * 0.6,
            flexDirection: 'row',
            borderRadius: 10,
            boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)',
            borderWidth: 1,
            borderColor: 'rgba(0, 0, 0, 0.5)',
          }}
        >
          <Layout
            style={{
              position: 'absolute',
              top: -30,
              width: '100%',
              borderRadius: 100,
            }}
          >
            <Text category="s1" style={{ textAlign: 'center', textAlignVertical: 'center' }}>
              Select Company
            </Text>
          </Layout>
          <View style={{ flex: 1 }}></View>
          <ScrollView style={{ flex: 10 }}>
            <View style={{ flex: 1 }}></View>
            {StorageController.getAppState().companies?.map((company, index) => {
              return (
                <Card key={index} style={{ margin: 5 }} disabled={true}>
                  <Button
                    key={index}
                    appearance="outline"
                    onPress={() => {
                      onSelectCompany(company);
                    }}
                  >
                    {company.name}
                  </Button>
                </Card>
              );
            })}
            <View style={{ flex: 1 }}></View>
          </ScrollView>
          <View style={{ flex: 1 }}>
            <Button
              style={{ margin: 5 }}
              appearance="outline"
              status="danger"
              onPress={() => {
                onCreateJob();
              }}
            >
              X
            </Button>
          </View>
        </Layout>
      )}
      {hasConfirmedCompany && (
        <>
          <RenderMinimizedCard />
          <Layout style={isMinimized ? createJobLayoutHiddenStyle : createJobLayoutShownStyle}>
            <Layout
              style={{
                position: 'absolute',
                top: -20,
                width: '99%',
                borderTopLeftRadius: 50,
                borderTopRightRadius: 50,
                borderTopWidth: 5,
                borderTopColor: 'green',
                // backgroundColor: 'rgba(0,150,0,0.8)',
              }}
            >
              <View style={{ marginTop: -5, flexDirection: 'row', justifyContent: 'center' }}>
                <Text style={{ fontWeight: 'bold', fontSize: 24, textAlign: 'center', textAlignVertical: 'center' }}>
                  New Job:
                </Text>
                <Text style={{ fontSize: 24, textAlign: 'center', textAlignVertical: 'center' }}>
                  {" "}{newJobSelectedCompany?.name} {case_id && ` - Case ID: ${case_id}`}
                </Text>
              </View>
            </Layout>
            <View style={{ position: 'absolute', right: 0, top: -20, zIndex: 999 }}>
              <View style={{ flexDirection: 'row', gap: 10 }}>
                <Button status="warning" onPress={() => onMinimize()}>
                  -
                </Button>
                <Button status="danger" onPress={() => onCloseModal()}>
                  X
                </Button>
              </View>
            </View>
            <ScrollView>
              <CreateJobScreen case_id={case_id} fn_onCreateJob={() => onCreateJob()} scheduledStartTime={scheduledStartTime} />
            </ScrollView>
          </Layout>
        </>
      )
      }
    </View >
  );
};

export default CreateJobModal;