export class Payment {
    _id!: string;
    company_id!: string;
    job_id!: string;
    holding_id!: string;
    member_id!: string;
    case_id!: string;
    payment_type!: string;
    amount!: number;
    success!: boolean;
    details!: PaymentDetails;
    state: PaymentStates = PaymentStates.OPEN;
    error!: any;
    createdAt!: string;
    updatedAt!: string;

    constructor(payment: any) {
        this._id = payment._id;
        this.company_id = payment.company_id;
        this.job_id = payment.job_id;
        this.holding_id = payment.holding_id;
        this.member_id = payment.member_id;
        this.case_id = payment.case_id;
        this.payment_type = payment.payment_type;
        this.amount = payment.amount;
        this.success = payment.success;
        this.details = payment.details;
        this.state = payment.state || PaymentStates.OPEN;
        this.error = payment.error;
        this.createdAt = payment.createdAt;
        this.updatedAt = payment.updatedAt;
    }
}



//define states
export enum PaymentStates {
    OPEN = 'OPEN',
    PENDING = 'PENDING',
    PROCESSING = 'PROCESSING',
    COMPLETED = 'COMPLETED',
    FAILED = 'FAILED',
    REFUNDED = 'REFUNDED',
    CANCELED = 'CANCELED',
    DRAFT = 'DRAFT',
}

interface PaymentDetails {
    payment_link: SquarePaymentLink;
    squareOrder: SquareOrder;
    squarePayment?: SquarePayment;
    other?: any;
    errors?: any;
}




/*
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
//////// Square Payment Class ////////////////////////////////////
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
*/

// Interfaces to define the structure of the data
interface Money {
    amount: number;
    currency: string;
}

interface Card {
    cardBrand: string;
    last4: string;
    expMonth: number;
    expYear: number;
    fingerprint: string;
    cardType: string;
    prepaidType: string;
    bin: string;
}

interface CardDetails {
    status: string;
    card: Card;
    entryMethod: string;
}

interface Tender {
    id: string;
    locationId: string;
    transactionId: string;
    createdAt: string;
    amountMoney: Money;
    type: string;
    cardDetails: CardDetails;
    paymentId: string;
}

export interface SquareLineItem {
    uid: string;
    name: string;
    quantity: string;
    note: string;
    itemType: string;
    basePriceMoney: Money;
    variationTotalPriceMoney: Money;
    grossSalesMoney: Money;
    totalTaxMoney: Money;
    totalDiscountMoney: Money;
    totalMoney: Money;
    totalServiceChargeMoney: Money;
}

interface NetAmounts {
    totalMoney: Money;
    taxMoney: Money;
    discountMoney: Money;
    tipMoney: Money;
    serviceChargeMoney: Money;
}

export class SquareOrder {
    id!: string;
    locationId!: string;
    source!: { name: string };
    lineItems!: SquareLineItem[];
    netAmounts!: NetAmounts;
    tenders!: Tender[];
    createdAt!: string;
    updatedAt!: string;
    closedAt!: string;
    state!: string;
    version!: number;
    totalMoney!: Money;
    totalTaxMoney!: Money;
    totalDiscountMoney!: Money;
    totalTipMoney!: Money;
    totalServiceChargeMoney!: Money;
    netAmountDueMoney!: Money;
    serviceCharges: SquareServiceCharge[] = [];

    constructor(data: any) {
        this.id = data.id;
        this.locationId = data.locationId;
        this.source = data.source;
        this.lineItems = data.lineItems;
        this.netAmounts = data.netAmounts;
        this.tenders = data.tenders;
        this.createdAt = data.createdAt;
        this.updatedAt = data.updatedAt;
        this.closedAt = data.closedAt;
        this.state = data.state;
        this.version = data.version;
        this.totalMoney = data.totalMoney;
        this.totalTaxMoney = data.totalTaxMoney;
        this.totalDiscountMoney = data.totalDiscountMoney;
        this.totalTipMoney = data.totalTipMoney;
        this.totalServiceChargeMoney = data.totalServiceChargeMoney;
        this.netAmountDueMoney = data.netAmountDueMoney;
        this.serviceCharges = data.serviceCharges
    }
}

interface SquareServiceCharge {
    amountMoney: Money;
    appliedMoney: Money;
    calculationPhase: string;
    name: string;
    taxable: boolean;
    totalMoney: Money;
    totalTaxMoney: Money;
    treatmentType: string;
    type: string;
    uid: string;
}

interface SquareRelatedResources {
    orders: SquareOrder[];
}

export interface SquarePaymentLinkResponse {
    errors?: any
    paymentLink: SquarePaymentLink;
    relatedResources: SquareRelatedResources;
}

export class SquarePaymentLink {
    id: string;
    version: number;
    orderId: string;
    url: string;
    checkoutOptions: any;
    prePopulatedData: any;
    longUrl: string;
    paymentNote: string;
    created_at: string;

    constructor(data: any) {
        this.id = data.id;
        this.version = data.version;
        this.orderId = data.order_id;
        this.url = data.url;
        this.checkoutOptions = data.checkoutOptions;
        this.prePopulatedData = data.prePopulatedData;
        this.longUrl = data.longUrl;
        this.paymentNote = data.paymentNote;
        this.created_at = data.created_at;
    }
}


export class SquareOrderLineItem {
    name: string;
    quantity: string;
    basePriceMoney: Money;
    note: string;

    constructor(data: any) {
        this.name = data.name;
        this.quantity = data.quantity;
        this.basePriceMoney = data.basePriceMoney;
        this.note = data.note;
    }
}


class ExternalDetails {
    type: string;
    source: string;

    constructor(type: string, source: string) {
        this.type = type;
        this.source = source;
    }
}

class ApplicationDetails {
    square_product: string;
    application_id: string;

    constructor(square_product: string, application_id: string) {
        this.square_product = square_product;
        this.application_id = application_id;
    }
}


export class SquarePayment {
    id: string;
    createdAt: string;
    updatedAt: string;
    amountMoney: Money;
    status: string;
    sourceType: string;
    locationId: string;
    orderId: string;
    note: string;
    totalMoney: Money;
    capabilities: string[];
    externalDetails: ExternalDetails;
    receiptNumber: string;
    receiptUrl: string;
    applicationDetails: ApplicationDetails;
    versionToken: string;

    constructor(data: any) {
        this.id = data.id;
        this.createdAt = data.created_at;
        this.updatedAt = data.updated_at;
        this.amountMoney = data.amount_money;
        this.status = data.status;
        this.sourceType = data.source_type;
        this.locationId = data.location_id;
        this.orderId = data.order_id;
        this.note = data.note;
        this.totalMoney = data.total_money;
        this.capabilities = data.capabilities;
        this.externalDetails = new ExternalDetails(data.externalDetails.type, data.externalDetails.source);
        this.receiptNumber = data.receipt_number;
        this.receiptUrl = data.receipt_url;
        this.applicationDetails = new ApplicationDetails(data.applicationDetails.square_product, data.applicationDetails.applicationId);
        this.versionToken = data.version_token;
    }
}