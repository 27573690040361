import React, { useState, useRef, useCallback, useEffect, useReducer } from 'react';
import {
    Image,
    AccessibilityRole,
    ImageProps,
    ImageStyle,
    StyleSheet,
    TouchableOpacity,
    View,
    ScrollView,
    FlatList,
    Alert,
    Clipboard,
    Text as RNText
    //@ts-ignore
} from "react-native";
import {
    ApplicationProvider,
    Button,
    Icon,
    IconRegistry,
    Divider,
    Input,
    Select,
    SelectItem,
    useTheme,
    Layout,
    Text,
    List,
    ListItem,
    OverflowMenu,
    MenuItem,
    Card,
    Toggle,

} from "@ui-kitten/components";
import * as StorageController from '../../../functions/storageController';
import * as ClientController from '../../../functions/client.controller';
import * as VendorProfileController from '../../../functions/vendorProfile.controller';
import { VendorProfile, VendorProfileRate, VendorProfileSettings } from '../../../models/VendorProfile.model';
import { Client } from '../../../models/Client.model';
import { Member } from '../../../models/Member.model';


interface VendorDetailsCardProps {
    vendorProfile: VendorProfile | null | undefined;
    onSetClientRate: any;
    onSetVendorClientProfile: any;
    Job: any;
    onSetJob: any;
    formRefsArr: any;
    handleKeyPressArr: any;
    style: any;
    clientReferenceId: any;
    onChangeClientRefId: any;
    allowEdit: any;
    setRefs: any;
    onInputFocus: any;
}

export const VendorDetailsCard = ({
    vendorProfile,
    onSetClientRate,
    onSetVendorClientProfile,

    Job,
    onSetJob,
    formRefsArr,
    handleKeyPressArr,
    style,
    clientReferenceId,
    onChangeClientRefId,
    allowEdit,
    setRefs,
    onInputFocus
}: VendorDetailsCardProps) => {

    const clientReferenceIdRef = useRef(null as any);
    const [vendorRate, setVendorRate] = React.useState([] as any)
    const [chosenVendorRate, setChosenVendorRate] = React.useState(false)
    const [vendorRateOptions, setVendorRateOptions] = React.useState([] as any)
    const vendorRatesDisplayValue: any = vendorRateOptions ? `${vendorRateOptions[vendorRate.row]?.name} : $${vendorRateOptions[vendorRate.row]?.cost}` : null;
    const vendorClientProfileRef = React.useRef(null as VendorProfile | null)

    useEffect(() => {
        // Pass the refs back to the parent component
        if (setRefs) setRefs([clientReferenceId]);
        load()
    }, []);


    useEffect(() => {
        if (clientReferenceId) clientReferenceIdRef.current.value = clientReferenceId;
    }, [clientReferenceId]);

    const load = async () => {
        await getClientProfileFromVendor()
        loadVendorRates()
    }

    const getClientProfileFromVendor = async () => {
        if (!vendorProfile?.linkedVendorAccountId) return
        const clientCompanyId = StorageController.getCurrentCompany()._id
        const vendor_id = vendorProfile.linkedVendorAccountId
        const clientProfile = await ClientController.getClientByCompanyAndClientLinkingId(vendor_id, clientCompanyId)
        if (!clientProfile) return
        vendorClientProfileRef.current = clientProfile
        onSetVendorClientProfile(clientProfile)
        return clientProfile
    }



    const loadVendorRates = async () => {
        if (!vendorProfile?.settings || !vendorProfile.settings.rates) {
            return
        }
        let rates = [] as VendorProfileRate[]
        if (vendorClientProfileRef.current) {
            rates = vendorClientProfileRef.current?.settings?.rates || []
        }
        if (vendorProfile?.settings.rates) {
            rates = [...rates, ...vendorProfile.settings.rates]
        }
        setVendorRateOptions(rates)
    }

    const onVendorRateSelected = (index: any) => {
        setChosenVendorRate(true)
        setVendorRate(index)
        // set client rate
        let _Job = { ...Job }
        let selectedRate = vendorRateOptions[index.row]
        selectedRate.billing = "bill_all_back"
        selectedRate.billed_to = "bill_all_back"

        _Job.details.client_rate = selectedRate
        onSetClientRate(selectedRate)
        // onSetJob(_Job)
    }

    const renderVendorRatesOption = (item: VendorProfileRate) => (
        <SelectItem key={item.name} title={`${item.name} : $${item.cost}`} />
    );


    return (
        <Card
            status="info"
            header={() => <Text>Vendor Details</Text>}
            disabled={true}
            style={style}
        >
            <View style={{ flexDirection: 'row,', flexWrap: "wrap", justifyContent: 'flex-start' }}>
                {vendorProfile?.settings?.rates &&
                    <View style={{}}>
                        <Text category='h6'>Vendor Name: {vendorProfile.name}</Text>
                        <Select
                            size='small'
                            selectedIndex={vendorRate}
                            label='Vendor Rate'
                            value={chosenVendorRate ? vendorRatesDisplayValue : "---Select Rate---"}
                            placeholder='Select Vendor Rate'
                            onSelect={(index: any) => {
                                onVendorRateSelected(index)
                            }}
                        >
                            {vendorRateOptions ? vendorRateOptions.map(renderVendorRatesOption) : [].map(renderVendorRatesOption)}
                        </Select>
                    </View>
                }
                {/* Show custom rate if none exists */}
                {!vendorProfile?.settings?.rates &&
                    <View>

                        <Text category='h6'>Vendor Name: {vendorProfile?.name}</Text>
                        <View style={{ flexDirection: 'row' }}>
                            {/* name and cost */}
                            <Input
                                size='small'
                                placeholder='Description'
                                label='Rate Description'
                                value={Job.details?.client_rate?.name}
                                onChangeText={(value: string) => {
                                    let _Job = { ...Job }
                                    if (!_Job.details.client_rate) _Job.details.client_rate = {}
                                    _Job.details.client_rate.name = value
                                    onSetJob(_Job)
                                }}
                            />
                            <Input
                                size='small'
                                placeholder='Cost'
                                label='Rate Cost'
                                value={Job.details?.client_rate?.cost}
                                onChangeText={(value: string) => {
                                    let _Job = { ...Job }
                                    if (!_Job.details.client_rate) _Job.details.client_rate = {}
                                    _Job.details.client_rate.cost = value
                                    onSetJob(_Job)
                                }}
                            />
                        </View>
                    </View>
                }
            </View>
            <View style={{ flexDirection: 'column', gap: 5 }}>
                <Input
                    key={`clientRefId-${clientReferenceId}`} //changing key will force this component to update
                    ref={clientReferenceIdRef}
                    size='small'
                    placeholder='Client Reference ID'
                    label='Client Reference ID'
                    defaultValue={clientReferenceId}
                    onBlur={(e: any) => onChangeClientRefId(e.nativeEvent.text)}
                    onFocus={() => onInputFocus(clientReferenceIdRef)}
                />
            </View>
        </Card>
    );

}