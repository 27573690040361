
import React, { useState, useEffect, useRef, useReducer, useCallback, useMemo, createContext, useContext } from 'react';
//@ts-ignore
import { useWindowDimensions, StyleSheet, View, TouchableOpacity, ScrollView } from "react-native";
import { SafeAreaView } from 'react-native-safe-area-context';
import * as XLSX from 'xlsx';
import DataGrid from 'react-data-grid';
//@ts-ignore
import { Divider, Icon, Layout, Text, TopNavigation, TopNavigationAction, Card, Button, Input, ButtonGroup, CheckBox, Modal, RangeDatepicker, Datepicker, useTheme, IndexPath, Select, SelectItem, List, ListItem } from '@ui-kitten/components';
import ErrorBoundary from '../ErrorBoundary.component';
import * as JobsController from '../../functions/job.controller'
import * as MembershipsController from '../../functions/membership.controller'
import * as UpdateController from '../../functions/update.controller'
import * as ImageController from '../../functions/image.controller'
import * as ClientController from '../../functions/client.controller'
import * as StorageController from '../../functions/storageController'
import * as DocumentController from '../../functions/document.controller'
import { Document, DocumentCategory, SharePermissions, SharedWith } from '../../models/Document.model';
import { formatIsoDateTime, getDocumentTypeFromString } from '../../functions/utils';
import { Company } from '../../models/Company.model';


const RenderCreateNewCategoryModal = ({
    showCreateNewCategoryModal,
    setShowCreateNewCategoryModal,
    onCreateNewDocumentCategory
}: {
    showCreateNewCategoryModal: boolean,
    setShowCreateNewCategoryModal: (value: boolean) => void,
    onCreateNewDocumentCategory: (category: DocumentCategory) => void
}) => {
    const [title, setTitle] = useState("")
    const [description, setDescription] = useState("")



    const onCreateNewCategory = () => {
        onCreateNewDocumentCategory({
            title,
            description,
            company_id: StorageController.getCurrentCompany()._id,
            member_id: StorageController.getAppState().selectedMembership?._id,
            permissions: {},
            details: {},
        } as DocumentCategory)
    }

    return (
        <Modal
            visible={showCreateNewCategoryModal}
            backdropStyle={styles.backdrop}
            onBackdropPress={() => setShowCreateNewCategoryModal(false)}>
            <Card
                header={() => <Text>Create New Category</Text>}
                status='success'
                disabled={true}>
                <View style={{ flexDirection: 'column', gap: 20 }}>
                    <View style={{ flexDirection: 'column' }}>

                        <Input
                            placeholder="Title"
                            value={title}
                            onChangeText={setTitle}
                        />
                        <Input
                            placeholder="Description"
                            value={description}
                            onChangeText={setDescription}
                        />
                    </View>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Button status='success' onPress={onCreateNewCategory}>Create</Button>
                        <Button status='danger' appearance='ghost' onPress={() => setShowCreateNewCategoryModal(false)}>Cancel</Button>
                    </View>
                </View>
            </Card>
        </Modal>
    )
}

const styles = StyleSheet.create({
    backdrop: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
});

export default RenderCreateNewCategoryModal