import { DeviceDetails } from "./DeviceDetails.model";


export class User {
    _id!: string;
    name!: string;
    username!: string;
    password?: string;
    email!: string;
    phone!: string;
    details!: UserDetails;
    acl!: any;
    status!: any;
    mfa_enabled!: any;
    createdAt!: string;
    updatedAt!: string;
    subscriptions!: Array<any>;

    constructor(user: any = {}) {
        this._id = user._id;
        this.name = user.name;
        this.username = user.username;
        this.email = user.email;
        this.phone = user.phone;
        this.mfa_enabled = user.mfa_enabled;
        this.details = new UserDetails(user.details || {});
        if (user.password) this.password = user.password;
        if (user.acl) this.acl = user.acl;
        if (user.status) this.status = user.status;
        if (user.createdAt) this.createdAt = user.createdAt;
        if (user.updatedAt) this.updatedAt = user.updatedAt;
        if (user.subscriptions) this.subscriptions = user.subscriptions;


    }

    // get returns object of user
    public get(): User {
        return this
    }

    // set sets user object
    public set(user: any): User {
        this._id = user._id;
        this.name = user.name;
        this.username = user.username;
        this.password = user.password;
        this.email = user.email;
        this.phone = user.phone;
        this.details = user.details;
        this.acl = user.acl;
        this.status = user.status;
        this.createdAt = user.createdAt;
        this.updatedAt = user.updatedAt;
        this.subscriptions = user.subscriptions;
        return this;
    }

}


class UserDetails {
    device!: DeviceDetails;
    constructor(details: any = {}) {
        this.device = new DeviceDetails(details.device || {});
    }
}