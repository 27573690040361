import React, { useState, useMemo, useEffect, useRef, useCallback } from 'react';
import {
    Image,
    AccessibilityRole,
    ImageProps,
    ImageStyle,
    StyleSheet,
    TouchableOpacity,
    View,
    Text as RNText,
    LayoutChangeEvent,
    FlatList,
    Animated
    //@ts-ignore
} from "react-native";
import {
    ApplicationProvider,
    Button,
    Icon,
    IconRegistry,
    Layout,
    Text,
    useTheme,
    List,
    ListItem,
    Card,
} from "@ui-kitten/components";
import JobListItem from "./jobListItem.component"
import { Job, JOB_STATUS } from '../../models/Job.model';
import { Client } from '../../models/Client.model';
import { Member } from '../../models/Member.model';
import { CompactJobListItem } from "./CompactJobListItem";
import * as JobController from '../../functions/job.controller';
import * as StorageController from '../../functions/storageController';
import AssignJobModal from './modals/assignJobModal.component';
import { IAction, useAppStateChange } from '../../hooks/appStateChange.hook';
import { Company } from '../../models/Company.model';
import { debounce } from 'lodash';
import BulkActionConfirmationModal from './modals/BulkActionConfirmationModal.component';

interface JobsListProps {
    selectedJobId: string | null;
    jobs: Job[];
    showCompact?: boolean;
    fn_onEndReached: () => void;
    fn_zoomToMarker: (job: Job) => void;
    fn_selectJob: (job: Job) => void;
    var_viewedBy: string;
    var_vendorProfiles: any;
    drivers: Member[];
    clients: Client[];
    fn_setDirections: (driver: Member, job: Job) => void;
    fn_openAssign: (job: Job) => void;
    fn_vendorAcceptJob: (job_id: string, eta: number) => void;
}

const JobsList: React.FC<JobsListProps> = ({
    selectedJobId,
    jobs,
    showCompact = false,
    fn_onEndReached,
    fn_zoomToMarker,
    fn_selectJob,
    var_viewedBy,
    var_vendorProfiles,
    drivers,
    clients,
    fn_setDirections,
    fn_openAssign,
    fn_vendorAcceptJob
}) => {
    const [selectedJobs, setSelectedJobs] = useState<string[]>([]);
    const selectedJobIdsRef = useRef<string[]>(selectedJobs);
    const selectedJobsRef = useRef<Job[]>([]);
    const [bulkAssignJobModalVisible, setBulkAssignJobModalVisible] = useState(false);
    const [bulkActionsDisabled, setBulkActionsDisabled] = useState(true);

    const [containerWidth, setContainerWidth] = useState(1000);
    const [expandedItems, setExpandedItems] = useState<Set<string>>(new Set());

    const [confirmationModalVisible, setConfirmationModalVisible] = useState(false);
    const [confirmationAction, setConfirmationAction] = useState<() => void>(() => { });
    const [confirmationMessage, setConfirmationMessage] = useState('');


    const theme = useTheme();






    const lastContainerWidth = useRef(0);
    const SMALL_WIDTH = 900;

    const COLLAPSED_HEIGHT = 79;
    const EXPANDED_HEIGHT = 300; // Adjust this value based on your expanded item height

    const getItemLayout = useCallback((data, index) => {
        let offset = 0;
        for (let i = 0; i < index; i++) {
            offset += expandedItems.has(data[i]._id) ? EXPANDED_HEIGHT : COLLAPSED_HEIGHT;
        }
        const length = expandedItems.has(data[index]._id) ? EXPANDED_HEIGHT : COLLAPSED_HEIGHT;
        return { length, offset, index };
    }, [expandedItems]);


    const getDriverForJob = (job: Job) => {
        return drivers.find((driver: Member) => driver._id === job.member_id) || new Member()
    }

    const getClientForJob = (job: Job) => {
        return clients.find((client: Client) => client._id === job.client_id) || new Client({})
    }


    const onLayoutChange = (event: LayoutChangeEvent) => {
        const { width } = event.nativeEvent.layout;
        console.log("🚀============== ~ file: jobsList.component.tsx:82 ~ onLayoutChange ~ width🚀==============", width)
        if (width !== lastContainerWidth.current) {
            lastContainerWidth.current = width;
            onSetContainerWidth(width);
        }
    };

    const onSetContainerWidth = debounce(setContainerWidth, 300);

    const onSetSelectedJobs = (jobIds: string[]) => {
        selectedJobIdsRef.current = jobIds
        selectedJobsRef.current = jobs.filter(job => jobIds.includes(job._id))
        setSelectedJobs(jobIds)
    }

    const toggleJobSelection = (job: Job) => {
        const newSelectedJobs = selectedJobIdsRef.current.includes(job._id)
            ? selectedJobIdsRef.current.filter(id => id !== job._id)
            : [...selectedJobIdsRef.current, job._id]
        onSetSelectedJobs(newSelectedJobs)
        setBulkActionsDisabled(newSelectedJobs.length === 0)
    };

    const onClearSelection = () => {
        onSetSelectedJobs([])
    }

    useAppStateChange({
        onUpdatedSelectedCompany: (action: IAction) => {
            console.log("== onUpdatedSelectedCompany ==")
            onSetSelectedJobs([])
        },
        onUpdatedJobs: (action: IAction) => {
            console.log("== onUpdatedJobs ==")
        }
    })




    const onSelectedJob = (job: Job) => {
        // setSelectedJob(job)
        fn_selectJob(job)
    }

    const onEndReached = () => {
        if (fn_onEndReached) {
            fn_onEndReached()
        }
        // console.log("== on end reached ==")
    }

    // Use useState for memoized jobs to force re-renders
    const [delayedJobsState, setDelayedJobsState] = useState(jobs);
    const debounceSetDelayedJobsState = useCallback(debounce(setDelayedJobsState, 300), [])
    // Update memoizedJobs whenever jobs prop changes
    useEffect(() => {
        debounceSetDelayedJobsState([...jobs]);
        const unassignedCount = jobs.filter(job => job.status === JOB_STATUS.UNASSIGNED).length
        const assignedCount = jobs.filter(job => job.status === JOB_STATUS.ASSIGNED).length
        const pendingCount = jobs.filter(job => job.status === JOB_STATUS.PENDING).length
        const completedCount = jobs.filter(job => job.status === JOB_STATUS.COMPLETE).length
        const cancelledCount = jobs.filter(job => job.status === JOB_STATUS.CANCELLED).length
        console.log("== jobs ==")
        console.log(unassignedCount, assignedCount, pendingCount, completedCount, cancelledCount)
    }, [jobs]);

    // Modify renderItem to use memoizedJobs
    const renderItem = useCallback(({ item }: { item: Job }) => {
        const driver = drivers.find((driver: Member) => driver._id === item.member_id) || new Member()
        const client = clients.find((client: Client) => client._id === item.client_id) || new Client({})
        return (
            <View style={{ flex: 1 }}>

                <JobListItem
                    selectedJobId={selectedJobId as string}
                    job={item}
                    driver={driver}
                    client={client}
                    var_viewedBy={var_viewedBy || "vendor"}
                    // var_vendorProfiles={var_vendorProfiles}
                    fn_zoomToMarker={fn_zoomToMarker}
                    fn_selectJob={onSelectedJob}
                    // var_drivers={drivers}
                    // var_clients={clients}
                    fn_setDirections={fn_setDirections}
                    fn_openAssign={fn_openAssign}
                    fn_vendorAcceptJob={fn_vendorAcceptJob}
                />
            </View>
        )
    }, [selectedJobId, drivers, selectedJobs]);


    const isSelected = useCallback((job: Job) => {
        return selectedJobIdsRef.current.includes(job._id);
    }, [selectedJobIdsRef.current]);


    // Modify RenderMemoizedList to use memoizedJobs
    const RenderMemoizedList = useMemo(() => {
        return (
            <List
                data={delayedJobsState.map(j => new Job(j))}
                renderItem={({ item }) => {
                    return showCompact ?
                        <CompactJobListItem
                            job={item}
                            driver={getDriverForJob(item)}
                            client={getClientForJob(item)}
                            onAssign={fn_openAssign}
                            onViewDetails={onSelectedJob}
                            isSelected={isSelected(item)}
                            onCheckboxChange={() => toggleJobSelection(item)}
                            containerWidth={containerWidth}
                            selectedJobId={selectedJobId}
                        />
                        : renderItem({ item })
                }}
                initialNumToRender={10}
                maxToRenderPerBatch={10}
                keyExtractor={(item) => item._id}
                onEndReached={fn_onEndReached}
                onEndReachedThreshold={0.5}
                getItemLayout={getItemLayout}
                extraData={delayedJobsState} // Use memoizedJobs here
            />
        );
    }, [showCompact, containerWidth, delayedJobsState, selectedJobsRef.current, selectedJobId]);


    const showConfirmationModal = (action: () => void, actionName: string) => {
        const affectedJobs = selectedJobsRef.current.map(job => job.friendly_id).join('\n');
        setConfirmationMessage(`Are you sure you want to ${actionName} the following jobs?\n\n${affectedJobs}`);
        setConfirmationAction(() => action);
        setConfirmationModalVisible(true);
    };

    const onBulkAssign = async (driver: Member) => {
        showConfirmationModal(() => {
            const driver_id = driver._id;
            const company = StorageController.getCurrentCompany();
            if (!company) {
                console.error("== No company found ==");
                return;
            }
            JobController.assignMultipleJobsToMember(selectedJobIdsRef.current, driver_id, company._id);
            setBulkAssignJobModalVisible(false);
        }, 'assign');
    };

    const onBulkUnassign = async () => {
        showConfirmationModal(() => {
            JobController.setJobsUnassignedMultiple(selectedJobsRef.current);
            setBulkAssignJobModalVisible(false);
        }, 'unassign');
    };

    const onBulkSetPending = async () => {
        showConfirmationModal(() => {
            JobController.setJobsPendingMultiple(selectedJobsRef.current);
            setBulkAssignJobModalVisible(false);
        }, 'set as pending');
    };

    const onBulkComplete = async () => {
        showConfirmationModal(() => {
            JobController.setJobsCompleteMultiple(selectedJobsRef.current);
            setBulkAssignJobModalVisible(false);
        }, 'complete');
    };

    const onBulkCancel = async () => {
        showConfirmationModal(() => {
            JobController.setJobCancelledMultiple(selectedJobsRef.current);
            setBulkAssignJobModalVisible(false);
        }, 'cancel');
    };

    const RenderMemoizedBulkActionButtons = React.useCallback(() => {
        const jobsSelected = selectedJobsRef.current.length > 0;
        const canUnassign = jobsSelected && selectedJobsRef.current.every(job => job.status === JOB_STATUS.ASSIGNED);
        const canAssign = jobsSelected && selectedJobsRef.current.every(job => job.status === JOB_STATUS.UNASSIGNED);
        const canSetPending = jobsSelected && selectedJobsRef.current.every(job => job.status === JOB_STATUS.ASSIGNED);
        const canComplete = jobsSelected && selectedJobsRef.current.every(job => job.status === JOB_STATUS.PENDING);
        const canCancel = jobsSelected && selectedJobsRef.current.every(job => [JOB_STATUS.UNASSIGNED, JOB_STATUS.ASSIGNED, JOB_STATUS.PENDING].includes(job.status));
        const canClear = jobsSelected
        return (
            <View style={styles.bulkActionBar}>
                <Button
                    style={styles.bulkActionButton}
                    size="small"
                    status="unassigned"
                    accessoryLeft={(props) => <Icon {...props} name="person-remove-outline" />}
                    onPress={onBulkUnassign}
                    disabled={bulkActionsDisabled || !canUnassign}
                >
                    Unassign
                </Button>
                <Button
                    style={styles.bulkActionButton}
                    size="small"
                    status="assigned"
                    accessoryLeft={(props) => <Icon {...props} name="person-add-outline" />}
                    onPress={() => setBulkAssignJobModalVisible(true)}
                    disabled={bulkActionsDisabled || !canAssign}
                >
                    Assign
                </Button>
                <Button
                    style={styles.bulkActionButton}
                    size="small"
                    status="pending"
                    accessoryLeft={(props) => <Icon {...props} name="clock-outline" />}
                    onPress={onBulkSetPending}
                    disabled={bulkActionsDisabled || !canSetPending}
                >
                    Pending
                </Button>
                <Button
                    style={styles.bulkActionButton}
                    size="small"
                    status="complete"
                    accessoryLeft={(props) => <Icon {...props} name="checkmark-circle-2-outline" />}
                    onPress={onBulkComplete}
                    disabled={bulkActionsDisabled || !canComplete}
                >
                    Complete
                </Button>
                {/* <Button
                    style={styles.bulkActionButton}
                    size="small"
                    status="cancelled"
                    accessoryLeft={(props) => <Icon {...props} name="close-circle-outline" />}
                    onPress={onBulkCancel}
                    disabled={bulkActionsDisabled || !canCancel}
                >
                    Cancel
                </Button> */}
                <Button
                    style={styles.bulkActionButton}
                    size="small"
                    appearance='ghost'
                    status="primary"
                    // accessoryLeft={(props) => <Icon {...props} name="close-outline" />}
                    onPress={onClearSelection}
                    disabled={bulkActionsDisabled || !jobsSelected}
                >
                    <View style={{ flexDirection: 'row', alignItems: 'center', gap: 4 }}>
                        <View style={{ flexDirection: 'row', alignItems: 'center', gap: 4 }}>
                            <Icon name="close-outline" fill={theme['color-primary-500']} style={{ width: 16, height: 16 }} />
                            <Text style={{ fontSize: 12, color: jobsSelected ? theme['color-primary-500'] : theme['color-basic-500'] }} numberOfLines={1}>Clear</Text>
                            <Text style={{ fontSize: 12, color: jobsSelected ? theme['color-primary-500'] : theme['color-basic-500'] }} numberOfLines={1}>({selectedJobsRef.current.length})</Text>
                        </View>
                    </View>
                </Button>
            </View>
        );
    }, [bulkActionsDisabled, selectedJobs]);




    return (
        <View style={styles.container} onLayout={onLayoutChange}>
            <BulkActionConfirmationModal
                visible={confirmationModalVisible}
                message={confirmationMessage}
                onConfirm={() => {
                    confirmationAction();
                    setConfirmationModalVisible(false);
                }}
                onCancel={() => setConfirmationModalVisible(false)}
            />
            {showCompact && (
                <RenderMemoizedBulkActionButtons />
            )}
            {RenderMemoizedList}
            {/* Bulk Assign Job Modal */}
            <AssignJobModal
                var_member_id={null}
                var_assignJobModalVisible={bulkAssignJobModalVisible}
                selectedJob={null}
                fn_assignMember={onBulkAssign}
                fn_dismiss={() => setBulkAssignJobModalVisible(false)}
            />
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    bulkActionBar: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        padding: 8,
        flexWrap: 'wrap',
        gap: 4,
    },
    bulkActionButton: {
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: '15%',
        minWidth: 80,
        maxWidth: 120,
    },
});

export default JobsList;