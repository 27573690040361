var config = require('../config/config.js')
const axios = require('axios');
import * as StorageController from './storageController'
import * as UpdateController from './update.controller'
import * as UserController from './user.controller'
import { Company } from '../models/Company.model';
import { Member } from '../models/Member.model';
import { CompanySecret } from '../models/CompanySecret.model';
import * as MqttService from '../services/mqtt.service'
var api = config.api;
var testApi = config.test_api

function getApi() {
    if (StorageController.getAppState().use_test_api) {
        return testApi
    }
    return api
}
axios.interceptors.request.use(
    async (config: any) => {
        let token = await getToken();
        let member_id = null;
        if (StorageController.getAppState().selectedMembership != null) {
            member_id = StorageController.getAppState().selectedMembership?._id;
        }
        config.headers.authorization = `Bearer ${token}`;
        config.headers['member-id'] = member_id;
        return config;
    },
    (error: any) => {
        return Promise.reject(error);
    }
);

const getToken = async () => {
    return await StorageController.getAccessToken() || null;
}

/**
 * Create a company secret
 */
export async function createCompanySecret(companySecret: CompanySecret) {
    try {
        let response = await axios.post(`${getApi()}/company_secret`, companySecret);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
}

/**
 * Find a company secret by id
 */
export async function findCompanySecret(id: string) {
    try {
        let response = await axios.get(`${getApi()}/company_secret/${id}`);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
}

/**
 * Update a company secret
 */
export async function updateCompanySecret(companySecret: CompanySecret) {
    try {
        let response = await axios.put(`${getApi()}/company_secret/${companySecret._id}`, companySecret);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
}

/**
 * Find a company secret by company id
 */
export async function findByCompany(company_id: string) {
    try {
        let response = await axios.get(`${getApi()}/company_secret/company/${company_id}`);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
}