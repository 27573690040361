import React, { useState, useMemo, useRef, useEffect } from 'react';
import {
    Image,
    AccessibilityRole,
    ImageProps,
    ImageStyle,
    StyleSheet,
    TouchableOpacity,
    View,
    Text as RNText,
    Animated,
    ScrollView,
    Dimensions,
    useWindowDimensions,
    //@ts-ignore
} from "react-native";
import {
    ApplicationProvider,
    Button,
    Icon,
    IconRegistry,
    Input,
    Modal,
    Layout,
    Text,
    List,
    ListItem,
    Divider,
    Card
} from "@ui-kitten/components";
import { SafeAreaView } from 'react-native-safe-area-context';
import { EvaIconsPack } from "@ui-kitten/eva-icons";
import * as HoldingController from "../../../functions/holding.controller"
import * as StorageController from "../../../functions/storageController"
import * as JobController from "../../../functions/job.controller"
import * as ImageController from "../../../functions/image.controller"
import { Holding } from "../../../models/Holding.model";
import { Job, JobAddress, JobLocation, LineItem } from "../../../models/Job.model";
import { ReportDetailsCard } from '../details/reportDetailsCard.component';
import { CreateJobFromHolding } from '../modals/createJobFromHoldingModal.component';
import { InventoryDetailsCard } from '../details/inventoryDetailsCard.component';
import { ImageDetailsCard } from '../details/imageDetailsCard.component';
import { InvoicedTotalCard } from '../details/jobInvoicedTotalDetailsCard.component';
import { VehicleDetailsCard } from '../details/vehicleDetailsCard.component';
import { CustomerDetailsCard } from '../details/customerDetailsCard.component';
import { ServiceDetailsCard } from '../details/serviceDetailsCard.component';
import * as ClientController from '../../../functions/client.controller'
import { LocationDetailsCard } from '../details/locationDetailsCard.component';
import { Client } from '../../../models/Client.model';
import { JobNotesList } from '../details/jobNotes.component';
import { set } from 'lodash';
import { useNotification } from '../../../context/notifications.context';
import { HoldingDetailsContainer } from './holdingDetailsContainer.component';
// const { width, height } = Dimensions.get('window');


interface HoldingDetailsModalContainerProps {
    holdingItem: Holding,
    visible: boolean,
    fn_onClose: Function,
    fn_onReleaseAndCreateJobHolding: Function,
    fn_onReleaseOnlyHolding: Function,
    disabled: boolean
}

export const HoldingDetailsModalContainer = ({
    holdingItem,
    visible,
    fn_onClose,
    fn_onReleaseAndCreateJobHolding,
    fn_onReleaseOnlyHolding,
    disabled = false
}: HoldingDetailsModalContainerProps) => {


    return (
        <Modal
            visible={visible}
            backdropStyle={styles.backdrop}
            onBackdropPress={fn_onClose}
            style={styles.modal}
        >
            <HoldingDetailsContainer
                holdingItem={holdingItem}
                fn_onClose={fn_onClose}
                fn_onReleaseAndCreateJobHolding={fn_onReleaseAndCreateJobHolding}
                fn_onReleaseOnlyHolding={fn_onReleaseOnlyHolding}
                disabled={disabled}
            />
        </Modal >
    )
}

const styles = StyleSheet.create({
    modal: {
        // width: width * 0.9,
        // height: height * 0.9,
        padding: 0,
        margin: 0
    },
    backdrop: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    }
});