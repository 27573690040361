var config = require("../config/config.js");
const axios = require("axios");
import * as StorageController from "./storageController";
import { Payment, SquareOrder } from "../models/Payment.model";

var api = config.api;
var testApi = config.test_api

function getApi() {
    if (StorageController.getAppState().use_test_api) {
        return testApi
    }
    return api
}

axios.interceptors.request.use(
    //@ts-ignore
    (config) => {
        let token = getToken();
        config.headers.authorization = `Bearer ${token}`;
        return config;
    },
    //@ts-ignore
    (error) => {
        return Promise.reject(error);
    }
);

const getToken = async () => {
    return await StorageController.getAccessToken() || null;
}

// Create a new Payment
export const createPayment = async (payment: Payment): Promise<Payment | null> => {
    try {
        const response = await axios.post(`${getApi()}/payment`, payment);
        return new Payment(response.data);
    } catch (err) {
        console.log("🚀============== ~ file: payment.controller.ts:64 ~ createPayment ~ err================", err)
        return null
    }
};

// Update a Payment
export const updatePayment = async (id: string, payment: Payment): Promise<Payment | null> => {
    try {
        const response = await axios.put(`${getApi()}/payment/${id}`, payment);
        return new Payment(response.data);

    } catch (err) {
        console.log("🚀============== ~ file: payment.controller.ts:75 ~ updatePayment ~ err================", err)
        return null
    }
};

// Delete a Payment
export const deletePayment = async (id: string): Promise<Payment | null> => {
    try {
        const response = await axios.delete(`${getApi()}/payment/${id}`);
        return response.data;
    } catch (err) {
        console.log("🚀============== ~ file: payment.controller.ts:86 ~ deletePayment ~ err================", err)
        return null
    }
};

// Retrieve a single Payment by ID
export const getPaymentById = async (id: string): Promise<Payment | null> => {
    try {
        const response = await axios.get(`${getApi()}/payment/${id}`);
        return new Payment(response.data);
    } catch (err) {
        console.log("🚀============== ~ file: payment.controller.ts:97 ~ getPaymentById ~ err================", err)
        return null
    }
};

// Retrieve all Payments for a given Job ID
export const getPaymentsByJobId = async (jobId: string): Promise<Payment[] | null> => {
    try {
        // console.log("🚀============== ~ file: payment.controller.ts:80 ~ getPaymentsByJobId ~ jobId================", jobId)
        const response = await axios.get(`${getApi()}/payment/job/${jobId}`);
        const payments = response.data.map((payment: any) => new Payment(payment));
        return payments
    } catch (err) {
        console.log("🚀============== ~ file: payment.controller.ts:109 ~ getPaymentsByJob ~ err================", err)
        return null
    }
};

// Retrieve all Payments for a given Company ID and dates
export const getPaymentsByCompanyAndDates = async (companyId: string, startDate: string, endDate: string): Promise<Payment | null> => {
    try {
        const data = {
            company_id: companyId,
            start_date: startDate,
            end_date: endDate,
        };
        const response = await axios.post(`${getApi()}/payment/company/dates`, data);
        const payments = response.data.map((payment: any) => new Payment(payment));
        return payments
    } catch (err) {
        console.log("🚀============== ~ file: payment.controller.ts:126 ~ getPaymentsByCompanyAndDates ~ err================", err)
        return null
    }
};

// Retrieve all Payments for a given Company ID with pagination
export const getPaymentsByCompanyPaginate = async (companyId: string, offset: number, limit: number): Promise<Payment | null> => {
    try {
        const data = {
            company_id: companyId,
            offset: offset,
            limit: limit,
        };
        const response = await axios.post(`${getApi()}/payment/company/paginate`, data);
        const payments = response.data.map((payment: any) => new Payment(payment));
        return payments
    } catch (err) {
        console.log("🚀============== ~ file: payment.controller.ts:142 ~ getPaymentsByCompanyPaginate ~ err================", err)
        return null
    }
};

// Find by holding id
export const getPaymentByHolding = async (holdingId: string): Promise<Payment | null> => {
    try {
        const response = await axios.get(`${getApi()}/payment/holding/${holdingId}`);
        const payments = response.data.map((payment: any) => new Payment(payment));
        return payments
    } catch (err) {
        console.log("🚀============== ~ file: payment.controller.ts:153 ~ getPaymentByHolding ~ err================", err)
        return null
    }
};

// Find by case id
export const getPaymentByCase = async (caseId: string): Promise<Payment | null> => {
    try {
        const response = await axios.get(`${getApi()}/payment/case/${caseId}`);
        const payments = response.data.map((payment: any) => new Payment(payment));
        return payments
    } catch (err) {
        console.log("🚀============== ~ file: payment.controller.ts:164 ~ getPaymentByCase ~ err================", err)
        return null
    }
};

// Find by member id between dates
export const getPaymentsByMemberAndDates = async (memberId: string, startDate: string, endDate: string): Promise<Payment | null> => {
    try {
        const data = {
            member_id: memberId,
            start_date: startDate,
            end_date: endDate,
        };
        const response = await axios.post(`${getApi()}/payment/member/dates`, data);
        const payments = response.data.map((payment: any) => new Payment(payment));
        return payments
    } catch (err) {
        console.log("🚀============== ~ file: payment.controller.ts:180 ~ getPaymentsByMemberAndDates ~ err================", err)
        return null
    }
};

// Find by member with pagination
export const getPaymentsByMemberPaginate = async (memberId: string, offset: number, limit: number): Promise<Payment | null> => {
    try {
        const data = {
            member_id: memberId,
            offset: offset,
            limit: limit,
        };
        const response = await axios.post(`${getApi()}/payment/member/paginate`, data);
        const payments = response.data.map((payment: any) => new Payment(payment));
        return payments
    } catch (err: any) {
        console.log("🚀============== ~ file: payment.controller.ts:196 ~ getPaymentsByMemberPaginate ~ err================", err)
        return null
    }
};



/*
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
//////// Payment Links
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
*/

// Create a new Payment Link
export const createPaymentLink = async (job_id: string): Promise<any> => {
    try {
        const data = {

        }
        const response = await axios.post(`${getApi()}/payment/square/customer/create_payment_link/${job_id}`, data);
        return response.data;
    } catch (err) {
        console.log("🚀============== ~ file: payment.controller.ts:214 ~ createPaymentLink ~ err================", err)
        return null
    }
};


// check if payment link is active or is paid
export const checkPaymentLink = async (payment_id: string): Promise<Payment | null> => {
    try {
        const response = await axios.get(`${getApi()}/payment/square/is_paid/${payment_id}`);
        return new Payment(response.data);
    } catch (err) {
        console.log("🚀============== ~ file: payment.controller.ts:227 ~ checkPaymentLink ~ err================", err)
        return null
    }
};