var config = require('../config/config.js')
const axios = require('axios');
import { Document, DocumentCategory } from '../models/Document.model';
import * as StorageController from './storageController'

var api = config.api;
var testApi = config.test_api

function getApi() {
    if (StorageController.getAppState().use_test_api) {
        return testApi
    }
    return api
}

axios.interceptors.request.use(
    (config: any) => {
        let token = getToken();
        let member_id = null;
        if (StorageController.getAppState().selectedMembership != null) {
            member_id = StorageController.getAppState().selectedMembership?._id;
        }
        config.headers.authorization = `Bearer ${token}`;
        config.headers['member-id'] = member_id;
        return config;
    },
    (error: any) => {
        return Promise.reject(error);
    }
);
const getToken = async () => {
    return await StorageController.getAccessToken() || null;
}


/**
 * 
 *  // Create a new Document
router.post('/document', DocumentController.create);
// update an Document
router.put('/document/:id', DocumentController.update);
// Delete an Document
router.delete('/document/:id', DocumentController.deleteDocument);
// Retrieve a single Document by ID
router.get('/document/:id', DocumentController.findByPk);
// Retrieve all Documents for a given Job ID
router.get('/document/job/:job_id', DocumentController.findByJobId);
// Retrieve all Documents for a given Company ID
router.get('/document/company/:company_id', DocumentController.findByCompanyId);
 */

export async function createDocument(document: Document, file: { uri: string, type: string, name: string }) {
    try {
        const data = {
            document: document,
            file: file
        }
        const response = await axios.post(getApi() + "/document", data);
        return response.data
    } catch (error) {
        console.error(error);
    }
}

export async function updateDocument(document: Document) {
    try {
        const response = await axios.put(getApi() + "/document/" + document._id, document);
        return response.data
    } catch (error) {
        console.error(error);
    }
}

export async function deleteDocument(documentId: string) {
    try {
        const response = await axios.delete(getApi() + "/document/" + documentId);
        return response.data
    } catch (error) {
        console.error(error);
    }
}

export async function getDocumentById(documentId: string) {
    try {
        const response = await axios.get(getApi() + "/document/" + documentId);
        return response.data
    } catch (error) {
        console.error(error);
    }
}

export async function getDocumentsByJobId(jobId: string) {
    try {
        const response = await axios.get(getApi() + "/document/job/" + jobId);
        return response.data
    } catch (error) {
        console.error(error);
    }
}

export async function getDocumentsByCompanyId(companyId: string, limit: number = 50, offset: number = 0) {
    try {
        const data = {
            company_id: companyId,
            limit: limit,
            offset: offset
        }
        const response = await axios.post(getApi() + "/document/company/" + companyId, data);
        return response.data
    } catch (error) {
        console.error(error);
    }
}

/**
 * Retrieve all Documents for a given Category ID
 * @param categoryId 
 * @param limit 
 * @param offset 
 * @returns 
 */
export async function getDocumentsByCategoryId(company_id: string, categoryId: string, limit: number = 50, offset: number = 0) {
    try {
        const data = {
            category_id: categoryId,
            company_id: company_id,
            limit: limit,
            offset: offset
        }
        const response = await axios.post(getApi() + "/document/category_id/" + categoryId, data);
        // console.log("🚀============== ~ file: document.controller.ts:128 ~ getDocumentsByCategoryId ~ response🚀==============", response)
        return response.data
    } catch (error) {
        console.error(error);
    }
}


/**
 * Get signed Url
 * @param document_id
 * @returns url
 */
export async function getSignedUrl(document: Document, options: any) {
    try {
        const data = {
            // S3 bucket signed link options
            options: options ?? {}
        }
        const response = await axios.post(getApi() + "/document/signed_url/" + document._id, data);
        return response.data
    } catch (error) {
        console.error(error);
    }
}




/*
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
//////// DOCUMEMT CATEGORY ////////////////////////////////////////
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
*/

export async function createDocumentCategory(documentCategory: DocumentCategory) {
    try {
        // get if the current user is a client
        const isClient = StorageController.getAppState().selectedMembership?.is_client;
        // if the user is a client, then set the client_id to the current user's client_id
        if (isClient) {
            documentCategory.permissions.is_client_created = true;
            documentCategory.permissions.client_id = StorageController.getAppState().selectedMembership?.client_id || null;
        }
        const response = await axios.post(getApi() + "/document_category", documentCategory);
        return response.data
    } catch (error) {
        console.error(error);
    }
}

export async function updateDocumentCategory(documentCategory: DocumentCategory) {
    try {
        const response = await axios.put(getApi() + "/document_category/" + documentCategory._id, documentCategory);
        console.log("🚀============== ~ file: document.controller.ts:181 ~ updateDocumentCategory ~ response🚀==============", response)
        return response.data
    }
    catch (error) {
        console.log("🚀============== ~ file: document.controller.ts:184 ~ updateDocumentCategory ~ error🚀==============", error)
        console.error(error);
    }
}

export async function deleteDocumentCategory(documentCategoryId: string) {
    try {
        const response = await axios.delete(getApi() + "/document_category/" + documentCategoryId);
        return response.data
    } catch (error) {
        console.error(error);
    }
}

export async function getDocumentCategoryById(documentCategoryId: string) {
    try {
        const response = await axios.get(getApi() + "/document_category/" + documentCategoryId);
        return response.data
    } catch (error) {
        console.error(error);
    }
}


export async function getDocumentCategoriesByCompanyId(companyId: string) {
    try {
        const response = await axios.get(getApi() + "/document_category/company/" + companyId);
        const categories = response.data.map((category: any) => new DocumentCategory(category));
        return categories
    } catch (error) {
        console.error(error);
    }
}
