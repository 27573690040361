export function formatTimestamp(timestamp: number | string | Date) {
    try {
        if (!timestamp) return ""
        const date = new Date(timestamp);
        return Intl.DateTimeFormat('en-AU', {
            year: '2-digit',
            month: 'short',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
        }).format(date);
    } catch (error) {
        console.log(error)
        return ""
    }
}

export function formatTimestampWithSeconds(timestamp: number | string | Date) {
    try {
        const date = new Date(timestamp);
        return Intl.DateTimeFormat('en-AU', {
            year: '2-digit',
            month: 'short',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: true
        }).format(date);
    } catch (error) {
        console.log(error)
        return timestamp
    }
}

/**
 * Returns formatted date in the format DD/MM/YYYY
 * @param {*} date 
 * @returns 
 */
export function formatIsoDate(date: Date | string): string {
    try {
        const d = new Date(date);
        return Intl.DateTimeFormat('en-AU', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        }).format(d).toString();
    } catch (error) {
        console.log(error)
        return date.toLocaleString()
    }
}


/**
 * Returns formatted date in the format DD/MM/YYYY HH:mm
 */
export function formatIsoDateTime(date: Date | string) {
    try {
        const d = new Date(date);
        return Intl.DateTimeFormat('en-AU', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit'
        }).format(d);
    } catch (error) {
        console.log(error)
        return date
    }
}

/**
 * Build a description from a list of fields in an object.
 * @param {*} obj 
 * @param {*} fields 
 * @returns 
 */
export const buildDescription = (obj: any, fields: any) => {
    if (!obj) return "";
    return fields
        .map((field: any) => obj[field] || "")
        .filter((value: any) => value !== "")
        .join(" | ");
};


/**
 * Flatten an object to a string
 */
export const flattenJson = (data: any, ignoreList = []) => {
    const result = {};

    const isPlainObject = (obj: any) => {
        return Object.prototype.toString.call(obj) === '[object Object]';
    };

    const recurse = (cur: any, prop: any) => {
        if (Object(cur) !== cur) {
            //@ts-ignore
            result[prop] = cur;
        } else if (Array.isArray(cur)) {
            // Convert array of objects to JSON string if it cannot be flattened further
            if (cur.some(item => isPlainObject(item))) {
                //@ts-ignore
                result[prop] = JSON.stringify(cur);
            } else {
                for (let i = 0; i < cur.length; i++) {
                    recurse(cur[i], prop ? `${prop}[${i}]` : `${i}`);
                }
            }
        } else {
            let isEmpty = true;
            let tempObj = {};
            for (const p in cur) {
                //@ts-ignore
                if (!ignoreList.includes(p)) {
                    isEmpty = false;
                    recurse(cur[p], prop ? `${prop}.${p}` : p);
                    //@ts-ignore
                    tempObj[p] = cur[p];
                }
            }
            if (isEmpty) {
                //@ts-ignore
                result[prop] = {}; // Handle empty objects explicitly
            } else if (isPlainObject(tempObj)) {
                // Convert deep objects to string if they are still objects
                //@ts-ignore
                result[prop] = JSON.stringify(tempObj);
            }
        }
    };

    recurse(data, "");
    return result;
};

/**
 * Either an extension or mime type can be passed in.
 * @param {*} type 
 * @returns either 'image', 'pdf', 'video', 'audio', 'iframe', 'excel', 'csv', 'word', or 'default'
 */
export function getDocumentTypeFromString(type: string) {
    switch (type) {
        case 'image/jpeg':
        case 'image/png':
        case 'image/gif':
        case 'image/bmp':
        case 'image/webp':
        case '.jpg':
        case '.jpeg':
        case '.png':
        case '.gif':
        case '.bmp':
            return 'image';
        case 'application/pdf':
        case '.pdf':
            return 'pdf';
        case 'video/mp4':
        case 'video/webm':
        case 'video/ogg':
        case '.mp4':
        case '.webm':
            return 'video';
        case 'audio/mpeg':
        case 'audio/ogg':
        case 'audio/wav':
        case 'audio/webm':
        case '.mp3':
        case '.ogg':
        case '.wav':
            return 'audio';
        case 'text/plain':
        case 'text/html':
        case 'text/css':
        case 'text/javascript':
        case 'application/json':
        case 'application/xml':
        case '.txt':
        case '.html':
        case '.css':
        case '':
        case '.json':
            return 'iframe';
        case 'text/csv':
        case 'application/vnd.ms-excel':
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        case '.xls':
        case '.xlsx':
            return 'excel';
        case '.csv':
            return 'csv';
        case 'application/msword':
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        case 'application/vnd.oasis.opendocument.text':
        case '.doc':
        case '.docx':
        case '.odt':
            return 'word';
        default:
            return 'default';
    }
}