export class DeviceDetails {
    name = ""
    app_version = 0;
    system_version = "";
    device_id = "";


    battery_level: number = 0;
    location_permission: any;
    location_background_permission: any;
    location_enabled: boolean = false;
    battery_enabled: boolean = false;
    battery_low_power_mode: boolean = false;
    battery_optimization_enabled: boolean = false;
    camera_permission: any;
    media_library_permission: any;
    constructor(details: any = {}) {

        this.name = details?.name || "";
        this.app_version = details?.app_version || ""
        this.system_version = details?.system_version || ""
        this.device_id = details?.device_id || ""
        this.battery_level = details?.battery_level ?? 0;
        this.location_permission = details?.location_permission || {};
        this.location_background_permission = details?.location_background_permission || {};
        this.location_enabled = details?.location_enabled || false;
        this.battery_enabled = details?.battery_enabled || false;
        this.battery_low_power_mode = details?.battery_low_power_mode || false;
        this.battery_optimization_enabled = details?.battery_optimization_enabled || false;
        this.camera_permission = details?.camera_permission || {};
        this.media_library_permission = details?.media_library_permission || {};
    }

}