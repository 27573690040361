import { read, write } from 'xlsx';
import * as DocumentController from '../functions/document.controller';


export class Document {
    _id!: string;
    title!: string;
    description!: string;
    date!: string;
    category_id!: string;
    job_id!: string;
    case_id!: string;
    company_id!: string;
    member_id!: string;
    file!: string;
    uri!: string;
    content_type!: string;
    is_deleted!: boolean;
    details!: any;
    shared_with!: SharedWith[];
    createdAt!: string;
    updatedAt!: string;

    constructor(Document: any) {
        this._id = Document._id;
        this.title = Document.title;
        this.description = Document.description;
        this.date = Document.date;
        this.category_id = Document.category_id;
        this.job_id = Document.job_id;
        this.case_id = Document.case_id;
        this.company_id = Document.company_id;
        this.member_id = Document.member_id;
        this.file = Document.file; // file name
        this.uri = Document.uri; // file uri/url
        this.content_type = Document.content_type;
        this.is_deleted = Document.is_deleted;
        this.details = Document.details;
        this.shared_with = Document.shared_with || [];
        this.createdAt = Document.createdAt;
        this.updatedAt = Document.updatedAt;
    }

    async save() {
        try {
            const result = await DocumentController.updateDocument(this);
            return result;
        } catch (e) {
            console.log("🚀============== ~ file: Document.model.ts:45 ~ Document ~ save ~ e🚀==============", e)
        }
    }
}


export class DocumentCategory {
    _id!: string;
    title!: string;
    description!: string;
    company_id!: string;
    member_id!: string;
    permissions!: DocumentCategoryPermissions;
    details!: any;
    is_deleted!: boolean;
    shared_with!: SharedWith[];
    createdAt!: string;
    updatedAt!: string;
    /**
     * Creates a new instance of the DocumentCategory class.
     * @param DocumentCategory - The object containing the document category data.
     */
    constructor(DocumentCategory: any) {
        this._id = DocumentCategory._id;
        this.title = DocumentCategory.title;
        this.description = DocumentCategory.description;
        this.company_id = DocumentCategory.company_id;
        this.member_id = DocumentCategory.member_id;
        this.permissions = DocumentCategory.permissions ? new DocumentCategoryPermissions(DocumentCategory.permissions) : new DocumentCategoryPermissions({});
        this.details = DocumentCategory.details;
        this.is_deleted = DocumentCategory.is_deleted;
        this.shared_with = DocumentCategory.shared_with || [];
        this.createdAt = DocumentCategory.createdAt;
        this.updatedAt = DocumentCategory.updatedAt;
    }

    async save() {
        try {
            const result = await DocumentController.updateDocumentCategory(this);
            console.log("🚀============== ~ file: Document.model.ts:87 ~ DocumentCategory ~ save ~ result🚀==============", result)
            return result;
        } catch (e) {
            console.log("🚀============== ~ file: Document.model.ts:45 ~ Document ~ save ~ e🚀==============", e)
        }
    }
}

/*
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
//////// Sharing to other company permissions
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
*/
export class SharedWith {
    _id!: string;
    company_id!: string;
    permissions!: SharePermissions;
    details!: any;
    createdAt!: string;
    updatedAt!: string;

    constructor(sharedWith: any = {}) {
        this._id = sharedWith._id
        this.company_id = sharedWith.company_id
        this.permissions = sharedWith.permissions || {
            admin: { read: false, write: false },
            operator: { read: false, write: false },
            driver: { read: false, write: false },
            client: { read: false, write: false }
        };
        this.details = sharedWith.details || {}
        this.createdAt = sharedWith.createdAt
        this.updatedAt = sharedWith.updatedAt
    }
}

export interface SharePermissions {
    admin: { read: boolean, write: boolean };
    operator: { read: boolean, write: boolean };
    driver: { read: boolean, write: boolean };
    client: { read: boolean, write: boolean };
}

/*
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
//////// Document Category Permissions
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
*/
export class DocumentCategoryPermissions {
    admin!: DocumentCategoryPermission;
    operator!: DocumentCategoryPermission;
    driver!: DocumentCategoryPermission;
    client!: DocumentCategoryPermission;
    is_client_created!: boolean;
    client_id!: string | null;

    constructor(DocumentCategoryPermissions: any) {
        this.admin = new DocumentCategoryPermission(DocumentCategoryPermissions.admin ?? { read: true, write: true });
        this.operator = new DocumentCategoryPermission(DocumentCategoryPermissions.operator ?? {});
        this.driver = new DocumentCategoryPermission(DocumentCategoryPermissions.driver ?? {});
        this.client = new DocumentCategoryPermission(DocumentCategoryPermissions.client ?? {});
        this.is_client_created = DocumentCategoryPermissions.is_client_created ?? false;
        this.client_id = DocumentCategoryPermissions.client_id ?? null;
    }
}

export class DocumentCategoryPermission {
    read!: boolean;
    write!: boolean;


    constructor(DocumentCategoryPermissions: any) {
        this.read = DocumentCategoryPermissions.read ?? false;
        this.write = DocumentCategoryPermissions.write ?? false;
    }
}

export class DocumentCategoryDetails {
    color!: string;
    icon!: string;



    constructor(DocumentCategoryDetails: any) {
        this.color = DocumentCategoryDetails.color;
        this.icon = DocumentCategoryDetails.icon;
    }
}