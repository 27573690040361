import React, { useRef, useEffect } from 'react';
//@ts-ignore
import { View } from "react-native";
import {
    Card,
    Input,
    Text
} from "@ui-kitten/components";
import { JobVehicleDetails } from '../../../models/Job.model';


interface VehicleDetailsCardProps {
    style?: any;
    vehicleDetails: JobVehicleDetails;
    onChangeVehicleDetails: (details: JobVehicleDetails) => void;
    allowEdit?: boolean;
    setRefs?: (refs: React.RefObject<any>[]) => void;
    onInputFocus?: (ref: React.RefObject<any>) => void;
    handleKeyPress?: (e: any) => void;
}


export const VehicleDetailsCard = ({
    style,
    vehicleDetails,
    onChangeVehicleDetails,
    allowEdit = true,
    setRefs = () => { },
    onInputFocus = () => { },
    handleKeyPress = () => { }
}: VehicleDetailsCardProps) => {

    const regoRef = useRef(null as any);
    const makeRef = useRef(null as any);
    const modelRef = useRef(null as any);
    const yearRef = useRef(null as any);
    const colourRef = useRef(null as any);


    useEffect(() => {
        // Pass the refs back to the parent component
        if (setRefs) setRefs([
            regoRef,
            makeRef,
            modelRef,
            yearRef,
            colourRef
        ]);
    }, []);


    useEffect(() => {
        if (regoRef.current) regoRef.current.value = vehicleDetails.rego;
    }, [vehicleDetails.rego]);

    useEffect(() => {
        if (makeRef.current) makeRef.current.value = vehicleDetails.make;
    }, [vehicleDetails.make]);

    useEffect(() => {
        if (modelRef.current) modelRef.current.value = vehicleDetails.model;
    }, [vehicleDetails.model]);

    useEffect(() => {
        if (yearRef.current) yearRef.current.value = vehicleDetails.year;
    }, [vehicleDetails.year]);

    useEffect(() => {
        if (colourRef.current) colourRef.current.value = vehicleDetails.colour;
    }, [vehicleDetails.colour]);

    const handleInputChange = (field: keyof JobVehicleDetails, value: string) => {
        onChangeVehicleDetails({ ...vehicleDetails, [field]: value });
    };


    return (
        <Card
            status="info"
            header={() => <Text>Vehicle Details</Text>}
            disabled={true}
            style={style}
        >
            <View style={{ flex: 1, flexDirection: 'row', gap: 5, flexWrap: 'wrap', justifyContent: 'flex-start' }}>
                <Input
                    style={{ flex: 1, minWidth: 150 }}
                    key={`rego-${vehicleDetails.rego}`} // changing key will force this component to update
                    ref={regoRef}
                    disabled={!allowEdit}
                    size='small'
                    placeholder='Rego'
                    label='Rego'
                    defaultValue={vehicleDetails.rego}
                    onBlur={(e: any) => handleInputChange('rego', e.nativeEvent.text)}
                    onKeyPress={handleKeyPress}
                    onFocus={() => onInputFocus(regoRef)}
                />
                <Input
                    style={{ flex: 1, minWidth: 150 }}
                    key={`make-${vehicleDetails.make}`}
                    ref={makeRef}
                    disabled={!allowEdit}
                    size='small'
                    placeholder='Make'
                    label='Make'
                    defaultValue={vehicleDetails.make}
                    onBlur={(e: any) => handleInputChange('make', e.nativeEvent.text)}
                    onKeyPress={handleKeyPress}
                    onFocus={() => onInputFocus(makeRef)}
                />
                <Input
                    style={{ flex: 1, minWidth: 150 }}
                    key={`model-${vehicleDetails.model}`}
                    ref={modelRef}
                    disabled={!allowEdit}
                    size='small'
                    placeholder='Model'
                    label='Model'
                    defaultValue={vehicleDetails.model}
                    onBlur={(e: any) => handleInputChange('model', e.nativeEvent.text)}
                    onKeyPress={handleKeyPress}
                    onFocus={() => onInputFocus(modelRef)}
                />
                <Input
                    style={{ flex: 1, minWidth: 150 }}
                    key={`year-${vehicleDetails.year}`}
                    ref={yearRef}
                    disabled={!allowEdit}
                    size='small'
                    placeholder='Year'
                    label='Year'
                    defaultValue={vehicleDetails.year}
                    onBlur={(e: any) => handleInputChange('year', e.nativeEvent.text)}
                    onKeyPress={handleKeyPress}
                    onFocus={() => onInputFocus(yearRef)}
                />
                <Input
                    style={{ flex: 1, minWidth: 150 }}
                    key={`colour-${vehicleDetails.colour}`}
                    ref={colourRef}
                    disabled={!allowEdit}
                    size='small'
                    placeholder='Colour'
                    label='Colour'
                    defaultValue={vehicleDetails.colour}
                    onBlur={(e: any) => handleInputChange('colour', e.nativeEvent.text)}
                    onKeyPress={handleKeyPress}
                    onFocus={() => onInputFocus(colourRef)}
                />
            </View>
        </Card>
    );

}