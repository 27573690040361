import React, { useState, useMemo, useRef, useEffect, useCallback } from 'react';
import {
    Image,
    AccessibilityRole,
    ImageProps,
    ImageStyle,
    StyleSheet,
    TouchableOpacity,
    View,
    Text as RNText,
    Animated,
    ScrollView,
    Dimensions,
    useWindowDimensions,
    //@ts-ignore
} from "react-native";
import {
    ApplicationProvider,
    Button,
    Icon,
    IconRegistry,
    Input,
    Modal,
    Layout,
    //@ts-ignore
    Spinner,
    Text,
    List,
    ListItem,
    Divider,
    Card
} from "@ui-kitten/components";
import { SafeAreaView } from 'react-native-safe-area-context';
import { EvaIconsPack } from "@ui-kitten/eva-icons";
import { Case } from '../../models/Case.model';
import { Client } from '../../models/Client.model';
import { Job, JobCustomerDetails, JobNote, Service } from '../../models/Job.model';
import { Company } from '../../models/Company.model';
import { Member } from '../../models/Member.model';
import * as CaseController from '../../functions/case.controller';
import * as ImageController from '../../functions/image.controller';
import * as StorageController from '../../functions/storageController';



import { ServiceDetailsCard } from '../job/details/serviceDetailsCard.component';
import { JobNotesList } from '../job/details/jobNotes.component';
import { ImageDetailsCard } from '../job/details/imageDetailsCard.component';
import { CustomerDetailsCard } from '../job/details/customerDetailsCard.component';
import { useNotification } from '../../context/notifications.context';
import { FullJobReport } from '../dashboardComponents/jobReportModal.component';
import * as ImagePicker from 'expo-image-picker';
import { formatTimestamp } from '../invoicing/invoiceUtils';
import { Holding } from '../../models/Holding.model';
import { HoldingDetailsContainer } from '../job/holding/holdingDetailsContainer.component';
import { formatDateTime } from '../dashboardComponents/dashboardUtils';

interface CaseDetailsContainerProps {
    caseObj: Case | null;
    onClose: () => void;
}

export const CaseDetailsContainer = ({
    caseObj,
    onClose,
}: CaseDetailsContainerProps) => {
    const [caseObjState, setCaseObjState] = useState(new Case({}));
    const [client, setClient] = useState(new Client({}));
    const [company, setCompany] = useState(new Company());
    const [localImages, setLocalImages] = useState<Image[]>([]);
    const [images, setImages] = useState<Image[]>([]);
    const localImagesRef = useRef(localImages);
    const caseRef = useRef(caseObjState);
    const notification = useNotification();
    const [disableCreateButton, setDisableSaveButton] = useState(false);
    const [renderSpinner, setRenderSpinner] = useState(false);
    const [loadingText, setLoadingText] = useState("Creating Case...");
    const width = useWindowDimensions().width;
    const height = useWindowDimensions().height;

    useEffect(() => {
        load();
    }, [])

    useEffect(() => {
        if (caseObj) {
            setCaseObjState(caseObj);
        }
    }, [caseObj])

    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //////// INIT
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */
    const load = async () => {
        try {
            if (!caseObj) return;
            const company = StorageController.getCurrentCompany();
            setCompany(company);
            const fetchedCase = await CaseController.getCaseById(caseObj._id);
            if (!fetchedCase) {
                return
            }
            onSetCase(fetchedCase);
            fetchCaseImages(fetchedCase);
        } catch (error) {
            console.error(error);
        }
    }

    const fetchCaseImages = async (caseO: Case) => {
        try {
            let imageArr = [] as any
            let jobImages = [] as any
            const caseImages = await ImageController.fetchCaseImages(caseO._id as string);
            if (caseImages) {
                imageArr = [...imageArr, ...caseImages]
            }
            if (caseO.job_ids && caseO.job_ids.length > 0) {
                for (let i = 0; i < caseO.job_ids.length; i++) {
                    const jobImages = await ImageController.fetchJobImages(caseO.job_ids[i]._id);
                    if (jobImages) {
                        imageArr = [...imageArr, ...jobImages]
                    }
                }
            }
            if (caseO.holding_ids && caseO.holding_ids.length > 0) {
                for (let i = 0; i < caseO.holding_ids.length; i++) {
                    const holdingImages = await ImageController.fetchHoldingImages(caseO.holding_ids[i]._id as string);
                    if (holdingImages) {
                        imageArr = [...imageArr, ...holdingImages]
                    }
                }
            }
            const images = [...imageArr, ...jobImages]
            setImages(images)
        } catch (error) {
            console.error(error);
        }
    }


    const onSetCase = (caseObj: Case) => {
        caseRef.current = caseObj;
        setCaseObjState(caseObj);
    }

    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //////// NOTES
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */

    const onAddNote = (jobNote: JobNote) => {
        caseRef.current.details.addNote(jobNote);
        onSetCase(caseRef.current);
    }
    const onDeleteNote = (jobNote: JobNote) => {
        caseRef.current.details.deleteNote(jobNote);
        onSetCase(caseRef.current);
    }
    const onUpdateNote = (jobNote: JobNote) => {
        caseRef.current.details.updateNote(jobNote);
        onSetCase(caseRef.current);
    }

    const onSetServices = (services: Service[]) => {
        caseRef.current.details.selected_services = services;
        onSetCase(caseRef.current);
    }

    const onSetCustomerDetails = (customerDetails: any) => {
        caseRef.current.details.customer_details = customerDetails;
        onSetCase(caseRef.current);
    }

    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //////// IMAGES
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */

    const onSetLocalImages = (images: Image[]) => {
        setLocalImages(images);
        localImagesRef.current = images;
    }

    const uploadedImagesCount = useRef(0);

    const uploadImages = async (caseObj: Case) => {
        try {
            const uploadedImages = await ImageController.uploadImages(localImagesRef.current, ImageController.IMAGE_TYPE.CASE, caseObj._id);
            uploadedImagesCount.current = uploadedImages.length;
            return uploadedImages;
        } catch (error) {
            console.error(error);
        }
    }

    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //////// Save Case
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */

    const saveCase = async () => {
        try {
            setDisableSaveButton(true);
            setRenderSpinner(true);
            const company = StorageController.getCurrentCompany();
            const updatedCase = await CaseController.updateCase(caseRef.current);
            if (updatedCase) {
                if (localImagesRef.current.length > 0) {
                    setLoadingText("Uploading Images...");
                    const uploadedImages = await uploadImages(updatedCase);
                }
            }
            setRenderSpinner(false);
            setDisableSaveButton(false);
            onClose();
        } catch (error) {
            console.error(error);
            setRenderSpinner(false);
            setDisableSaveButton(false);
        }
    }

    if (!caseObj) return <></>

    const [selectedJob, setSelectedJob] = useState<Job | null>(null)
    const [selectedHolding, setSelectedHolding] = useState<Holding | null>(null)

    const [showJobPanel, setShowJobPanel] = useState(false)
    const [showHoldingPanel, setShowHoldingPanel] = useState(false)

    const RenderJobsList = ({ jobs }: any) => {
        if (!jobs || jobs.length === 0) return <></>
        return (
            <View>
                <Text category="h6">Jobs</Text>
                <List
                    data={jobs}
                    ItemSeparatorComponent={Divider}
                    renderItem={({ item, index }: { item: Job, index: number }) => {
                        let description = item.start_time ? formatDateTime(item.start_time) : ''
                        description += ` | ${item.status} | `
                        description += `${item.getServicesNames()}`
                        return (
                            <ListItem
                                onPress={() => {
                                    setSelectedJob(item)
                                    setShowJobPanel(true)
                                }}
                                title={item.getServicesNames()}
                                description={description}
                                accessoryLeft={(props: any) =>
                                    <Button
                                        appearance='ghost'
                                        status='info'
                                        onPress={() => {
                                            setSelectedJob(item)
                                            setShowJobPanel(true)
                                        }}
                                        accessoryLeft={(props: any) =>
                                            <Icon {...props} name='briefcase-outline' />
                                        }
                                    >
                                        Open
                                    </Button>

                                }
                            />
                        )
                    }}
                />
            </View>
        )
    }

    const RenderHoldingsList = ({ holdings }: any) => {
        if (!holdings || holdings.length === 0) return <></>
        return (
            <View>
                <Text category="h6">Holdings</Text>
                <List
                    data={holdings}
                    renderItem={({ item, index }: { item: Holding, index: number }) => {
                        let description = item.createdAt ? formatDateTime(item.holding_time) : ''
                        description += ` | ${item.status} | `
                        description += `Held For - ${item.getTimeElapsed().days} days ${item.getTimeElapsed().hours} hours`
                        return (
                            <ListItem
                                onPress={() => {
                                    setSelectedHolding(item)
                                    setShowHoldingPanel(true)
                                }}
                                title={item.getServicesNames()}
                                description={description}
                                accessoryLeft={(props: any) =>
                                    <Button
                                        appearance='ghost'
                                        status='info'
                                        onPress={() => {
                                            setSelectedHolding(item)
                                            setShowHoldingPanel(true)
                                        }}
                                        accessoryLeft={(props: any) =>
                                            <Icon {...props} name='briefcase-outline' />
                                        }
                                    >
                                        Open
                                    </Button>
                                }
                            />
                        )
                    }}
                />
            </View>
        )
    }

    return (
        <Layout style={{ flex: 1 }}>
            <RenderSpinnerOverlay renderSpinner={renderSpinner} loadingText={loadingText} />
            <Button
                onPress={() => onClose()}
                status='danger'
                style={{ position: 'absolute', top: 0, right: 10, zIndex: 100 }}
            >
                <Icon name='close-outline' fill='white' width={20} height={20} />
            </Button>
            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                <Text category="h6">Case Details {caseObj.name}</Text>
                <View style={{ flex: 1 }}></View>
                <View style={{ flex: 1 }}></View>
            </View>
            <ScrollView style={styles.scrollContainer}>
                <View style={{ flexDirection: 'row', flexWrap: 'wrap', flex: 1 }}>
                    <View style={styles.imageAreaStyle}>
                        <ImageDetailsCard
                            propStyle={{ flex: 1, marginBottom: 20 }}
                            images={images}
                            onSetLocalImages={onSetLocalImages}
                            onSetImages={() => { }}
                        />
                    </View>
                    <View style={styles.mainAreaStyle}>
                        <ScrollView style={{}}>
                            <View style={{ flexDirection: 'row', gap: 5, flexWrap: 'wrap' }}>
                                <CustomerDetailsCard
                                    style={[styles.groupContainer, { flexBasis: "33%" }]}
                                    customerDetails={caseObj.details.customer_details || new JobCustomerDetails({})}
                                    onChangeCustomerDetails={onSetCustomerDetails}
                                    allowEdit={true}
                                />
                            </View>
                            <Divider />
                            <View style={{ flexDirection: 'row' }}>
                                <View style={{ flex: 1 }}>

                                    <JobNotesList
                                        notes={caseObj?.details?.notes}
                                        onAddNote={onAddNote}
                                        onDeleteNote={onDeleteNote}
                                        onUpdateNote={onUpdateNote}
                                    />
                                </View>
                                <View style={{ flex: 1 }}>
                                    <RenderJobsList
                                        jobs={caseObj.job_ids}
                                    />
                                    <RenderHoldingsList
                                        holdings={caseObj.holding_ids}
                                    />
                                </View>
                            </View>
                        </ScrollView>
                    </View>
                    <View style={styles.serviceAreaStyle}>
                        <ScrollView style={{}}>
                            <ServiceDetailsCard
                                style={[styles.groupContainerLarge]}
                                allowEdit={true}
                                selectedServices={caseObj.details.selected_services}
                                updateJobServices={onSetServices}
                                canSetTimes={true}
                            />
                        </ScrollView>
                    </View>
                    {selectedJob &&
                        <Modal
                            visible={showJobPanel}
                            style={{
                                width: width * 0.9,
                                height: height * 0.9,
                                padding: 0,
                                margin: 0
                            }}
                            backdropStyle={styles.backdrop}
                        >
                            <FullJobReport
                                job={selectedJob}
                                onClose={() => { setSelectedJob(null) }}
                            />
                        </Modal>
                    }
                    {selectedHolding &&
                        <Modal
                            visible={showHoldingPanel}
                            style={{
                                width: width * 0.9,
                                height: height * 0.9,
                                padding: 0,
                                margin: 0
                            }}
                            backdropStyle={styles.backdrop}
                        >
                            <HoldingDetailsContainer
                                holdingItem={selectedHolding}
                                fn_onClose={() => { setShowHoldingPanel(false) }}
                                fn_onReleaseAndCreateJobHolding={() => { }}
                                fn_onReleaseOnlyHolding={() => { }}
                                disabled={false}
                            />
                        </Modal>
                    }
                </View>
            </ScrollView>
            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                <Card style={{ flex: 1, margin: 5 }}>
                    <Button disabled={disableCreateButton} status="success" onPress={() => saveCase()} style={{ flex: 1, margin: 5 }}>Save</Button>
                </Card>
            </View>
        </Layout>
    )

}

const RenderSpinnerOverlay = ({ renderSpinner, loadingText }: any) => {
    if (!renderSpinner) return <></>
    return (
        <Layout style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, zIndex: 1000, justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(0,0,0,0.5)' }}>
            <Spinner size='giant' />
            <Text>{loadingText}</Text>
        </Layout>
    )
}

const styles = StyleSheet.create({
    groupContainer: {
        margin: 0,
        flex: 1,
        minWidth: 250,
    },
    groupContainerLarge: {
        margin: 0,
        minWidth: 250,
    },
    imageAreaStyle: {
        flexBasis: "20%",
        minWidth: 250,
        // height: '100%',
        flexGrow: 1,
        flexShrink: 1,
    },
    mainAreaStyle: {
        flexBasis: "60%",
        minWidth: 450,
        // height: '100%',
        flexGrow: 1,
        flexShrink: 1,
    },
    serviceAreaStyle: {
        flexBasis: "20%",
        minWidth: 250,
        // height: '100%',
        flexGrow: 1,
        flexShrink: 1,
    },
    scrollContainer: {
        // flex: 1,
        // overflow: 'hidden',
        height: '100%',
        width: '100%',
    },
    backdrop: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    }
});