import React, { useState, useEffect, useRef, useReducer, useCallback, useMemo, createContext, useContext } from 'react';
//@ts-ignore
import { useWindowDimensions, StyleSheet, View, TouchableOpacity } from "react-native";
import { SafeAreaView } from 'react-native-safe-area-context';
//@ts-ignore
import { Divider, Icon, Layout, Text, TopNavigation, TopNavigationAction, Card, Button, Input, ButtonGroup, CheckBox, Modal, RangeDatepicker, Datepicker, useTheme, IndexPath, Select, SelectItem, List, ListItem } from '@ui-kitten/components';
import ErrorBoundary from '../ErrorBoundary.component';
import * as JobsController from '../../functions/job.controller'
import * as MembershipsController from '../../functions/membership.controller'
import * as UpdateController from '../../functions/update.controller'
import * as ImageController from '../../functions/image.controller'
import * as ClientController from '../../functions/client.controller'
import * as CompanyController from '../../functions/company.controller'
import * as StorageController from '../../functions/storageController'
import * as VendorProfileController from '../../functions/vendorProfile.controller'
import { ServiceAreaEdit } from '../settings/serviceAreaEdit.component'

import { VendorProfile } from '../../models/VendorProfile.model'
import { Company, CompanyContract } from '../../models/Company.model'
import { Client } from '../../models/Client.model';
import { useAppStateChange, IAction } from '../../hooks/appStateChange.hook';

export const ContractsContainerScreen = ({ navigation }: any) => {

    const [contracts, setContracts] = useState([] as CompanyContract[])
    const [company, setCompany] = useState({} as Company)
    const [selectedContract, setSelectedContract] = useState(null as CompanyContract | null)
    const [showNewContractModal, setShowNewContractModal] = useState(false)
    const [originalCompanyObj, setOriginalCompanyObj] = useState({})
    const [unsavedChanges, setUnsavedChanges] = useState(false)
    const [vendorProfiles, setVendorProfiles] = useState([] as VendorProfile[])

    useEffect(() => {
        const unsubscribe = navigation.addListener('focus', () => {
            // The screen is focused
            load()
        });
        return unsubscribe;
        //get vendor profile by company id
    }, [])




    const load = async () => {
        try {
            let id = StorageController.getCurrentCompany()._id
            let company = await CompanyController.getCompanyById(id) as Company
            setCompany(company)
            setContracts(company.settings?.contracts || [])

            // load vendor profiles
            let profiles = await VendorProfileController.getVendorProfilesByCompanyId(id)
            setVendorProfiles(profiles)
        } catch (e) {
            console.log(e)
        }
    }

    const handleCompanyChangeEvent = useCallback((action: IAction) => {
            setCompany(new Company({}))
            setContracts([])
            setVendorProfiles([])
            setUnsavedChanges(false)
            setSelectedContract(null)
            setTimeout(async () => {
                load()
            }, 100);
    }, [company]);

    const { dispatchEventStateChange } = useAppStateChange({
        onUpdatedSelectedCompany: handleCompanyChangeEvent
    })


    const onSetSelectedContract = (contract: CompanyContract | null, changes = true) => {
        setSelectedContract(contract)
        setUnsavedChanges(changes)
    }


    const serviceTags = [
        "Battery",
        "Battery EV",
        "Delivery",
        "Tow",
        "Tyre",
        "Lockout",
        "Fuel",
        "Winch",
        "Recovery",
        "Other",
    ]


    const RenderCreateNewContractModal = () => {
        const [newContract, setNewContract] = useState(new CompanyContract())
        return (
            <Modal
                visible={showNewContractModal}
                backdropStyle={styles.backdrop}
                onBackdropPress={() => setShowNewContractModal(false)}
            >
                <View style={{ flexDirection: 'column' }}>
                    <Input
                        label='Name'
                        onChangeText={(nextValue: string) =>
                            setNewContract({ ...newContract, name: nextValue })
                        }
                    />
                    <Input
                        label='Details'
                        onChangeText={(nextValue: string) =>
                            setNewContract({ ...newContract, details: nextValue })
                        }
                    />
                    <Button onPress={() => {
                        setShowNewContractModal(false)
                        let newContracts = [...contracts, newContract]
                        setContracts(newContracts)
                        let newCompany = new Company(company)
                        newCompany.settings.contracts = newContracts
                        setCompany(newCompany)
                        //update company
                        CompanyController.updateCompany(newCompany)
                    }}>Create</Button>
                </View>
            </Modal>
        )
    }



    const onSaveContract = () => {
        if (!selectedContract) return
        let existingContracts = [...contracts]
        let index = existingContracts.findIndex((item) => item._id === selectedContract._id)
        existingContracts[index] = selectedContract
        setContracts(existingContracts)
        let newCompany = new Company(company)
        newCompany.settings.contracts = existingContracts
        setCompany(newCompany)
        //update company
        CompanyController.updateCompany(newCompany)
        setUnsavedChanges(false)
        load()
    }

    const onCancel = () => {
        onSetSelectedContract(null, false)
    }

    const onSelectContract = (contract: CompanyContract) => {
        onSetSelectedContract(contract, false)
    }



    return (
        <Layout
            key={company?._id}
            style={{ flex: 1, flexDirection: 'row' }}>
            <RenderCreateNewContractModal />
            {/* <RenderServiceAreaModal /> */}
            <Layout style={{ flex: 1, borderWidth: 1, borderColor: "#000" }}>
                {/* New Vendor Form */}
                <Button
                    onPress={() => setShowNewContractModal(true)}
                >New Contract</Button>
                {/* Vendor List */}
                <Layout style={{ flex: 1, borderWidth: 1, borderColor: "#000" }}>
                    <List
                        data={contracts}
                        renderItem={({ item }: { item: CompanyContract }) => (
                            <ListItem
                                title={item.name}
                                description={item.details}
                                accessoryLeft={() => <CheckBox checked={item._id === selectedContract?._id} />}
                                onPress={() => onSelectContract(item)}
                            />
                        )}
                    />

                </Layout>
            </Layout>
            <Layout style={{ flex: 5 }}>
                {/* Vendor profile settings */}
                <Layout style={{ flex: 1, borderWidth: 1, borderColor: "#000" }}>
                    {
                        selectedContract &&
                        <View style={{ flex: 1, flexDirection: 'column' }}>
                            <Layout style={{ flexDirection: 'row' }}>
                                <Layout style={{ flex: 3 }}>
                                    {
                                        unsavedChanges &&
                                        <Text
                                            style={{ color: 'red' }}
                                            category='h6'
                                        >Unsaved Changes</Text>
                                    }
                                </Layout>
                                <Layout style={{ flex: 1 }}>
                                    <Button
                                        onPress={() => { onSaveContract() }}
                                        accessoryLeft={(props: any) => <Icon {...props} name="save-outline" />}
                                        status="success"
                                    />
                                </Layout>
                                <Layout style={{ flex: 1 }}>
                                    <Button
                                        onPress={onCancel}
                                        accessoryLeft={(props: any) => <Icon {...props} name="close-outline" />}
                                        status="warning"
                                    />
                                </Layout>
                            </Layout>
                            <View style={{ flex: 1, flexDirection: 'row' }}>

                                <Card
                                    disabled={true}
                                    style={{ flex: 1 }}
                                    status='basic'
                                    header={() => <Text category='h6'>Details</Text>}
                                >
                                    <Input
                                        label='Name'
                                        value={selectedContract.name}
                                        onChangeText={(nextValue: any) => onSetSelectedContract({ ...selectedContract, name: nextValue })}
                                    />
                                    <Input
                                        label='Details'
                                        value={selectedContract.details}
                                        onChangeText={(nextValue: any) => onSetSelectedContract({ ...selectedContract, details: nextValue })}
                                    />
                                </Card>
                                {/* <Card
                                    disabled={true}
                                    style={{ flex: 1 }}
                                    status='basic'
                                    header={() => <Text category='h6'>Service Area</Text>}
                                >
                                    <Button onPress={() => setShowServiceAreaModal(true)}>Service Area</Button>
                                </Card> */}
                                <Card
                                    disabled={true}
                                    style={{ flex: 1 }}
                                    status='basic'
                                    header={() => <Text category='h6'>Services Provided</Text>}
                                >
                                    {/* Create a list of checkboxes? for service tags to be mapped to vendor services */}
                                    {/* Map over the serviceTags to make checkboxes. then check if they are in the services_provided array */}
                                    {
                                        serviceTags.map((tag, index) => {
                                            return (
                                                <CheckBox
                                                    key={index}
                                                    checked={selectedContract.service_tags.includes(tag)}
                                                    onChange={(nextChecked: any) => {
                                                        if (nextChecked) {
                                                            onSetSelectedContract({ ...selectedContract, service_tags: [...selectedContract.service_tags, tag] })
                                                        } else {
                                                            onSetSelectedContract({ ...selectedContract, service_tags: selectedContract.service_tags.filter((item) => item !== tag) })
                                                        }
                                                    }}
                                                >
                                                    {tag}
                                                </CheckBox>
                                            )
                                        })
                                    }
                                </Card>
                                <Card
                                    disabled={true}
                                    style={{ flex: 1 }}
                                    status='basic'
                                    header={() => <Text category='h6'>Vendors Servicing</Text>}
                                >
                                    <List
                                        data={vendorProfiles}
                                        renderItem={({ item }: { item: VendorProfile }) => (
                                            <ListItem
                                                title={item.name}
                                                description={item.details}
                                                accessoryLeft={() => <CheckBox checked={selectedContract.vendor_profiles.includes(item._id)} />}
                                                onPress={() => {
                                                    if (selectedContract.vendor_profiles.includes(item._id)) {
                                                        onSetSelectedContract({ ...selectedContract, vendor_profiles: selectedContract.vendor_profiles.filter((id) => id !== item._id) })
                                                    } else {
                                                        onSetSelectedContract({ ...selectedContract, vendor_profiles: [...selectedContract.vendor_profiles, item._id] })
                                                    }
                                                }}
                                            />
                                        )}
                                    />
                                </Card>

                            </View>
                        </View>
                    }
                </Layout>

            </Layout>
        </Layout>
    )
}


const styles = StyleSheet.create({
    backdrop: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
});