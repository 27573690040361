import React, { useState, useEffect, useRef, useReducer, useMemo, useCallback } from 'react';
//@ts-ignore
import { StyleSheet, View, ScrollView } from 'react-native';
// @ts-ignore
import {
    ApplicationProvider,
    Button,
    Icon,
    IconRegistry,
    Divider,
    Input,
    Select,
    SelectItem,
    useTheme,
    Layout,
    Text,
    List,
    ListItem,
    OverflowMenu,
    MenuItem,
    Card,
    Toggle,
    Radio,
    RadioGroup,
    Modal,
    //@ts-ignore
    RangeDatepicker
} from '@ui-kitten/components';
import { Job, JobTowingDetails, TowingLeg } from '../../models/Job.model';
import { Member } from '../../models/Member.model';
import { Company } from '../../models/Company.model';
import * as StorageController from '../../functions/storageController';

export const RenderSearchJobsByDateRange = ({
    searchJobsByDateRange = () => { },
    validateAndSearchJobsByDateRange = (r: any) => { },
    dateRange = {},
    validateDateRange = (v: any) => { },
    handleNextWeek = () => { },
    handlePreviousWeek = () => { },
}) => {

    const CalendarIcon = (props: any) => (
        <Icon {...props} name='calendar' />
    );
    const RenderNextWeekButton = () => (
        <Button
            appearance="outline"
            status="basic"
            onPress={() => {
                // start date is last sunday aned date is this saturday
                handleNextWeek();
            }}
        >
            <Icon name="arrow-ios-forward-outline" width={30} height={30} />
        </Button>
    );

    const RenderLastWeekButton = () => (
        <Button
            appearance="outline"
            status="basic"
            onPress={() => {
                // start date is last sunday aned date is this saturday
                handlePreviousWeek();
            }}
        >
            <Icon name="arrow-ios-back-outline" width={30} height={30} />
        </Button>
    );
    return (
        <View style={{ flexDirection: 'column', gap: 2 }}>
            <Layout style={{ flexDirection: "row", gap: 2 }}>
                <Layout style={{ flex: 1 }}>
                    <RenderLastWeekButton />
                </Layout>
                <Layout style={{ flex: 1 }}>
                    <RenderNextWeekButton />
                </Layout>
            </Layout>
            {/* <Button
                // appearance='outline'
                status='info'
                style={{}}
                size="small"
                onPress={() => { searchJobsByDateRange() }}
            >
                Search
            </Button> */}
            <RangeDatepicker
                style={{}}
                autoDismiss={true}
                range={dateRange}
                min={new Date(1900, 0, 0)}
                max={new Date(2099, 0, 0)}
                accessoryRight={CalendarIcon}
                onSelect={(nextRange: any) => {
                    validateAndSearchJobsByDateRange(nextRange);
                }}
            />
        </View>
    )
}