import { JobAddress, JobLocation, JobDirections, LineItem, Job } from './Job.model';
import * as RouteController from '../functions/route.controller';

export class Route {
    _id!: string;
    name!: string;
    company_id!: string;
    member_id!: string;
    job_ids!: string[];
    details!: RouteDetails;
    status!: string;
    planned_start_time!: number | null;
    actual_start_time!: number | null;
    completed_time!: number | null;
    inventory_items!: LineItem[];
    createdAt!: string;
    updatedAt!: string;

    constructor(route: any) {
        this._id = route._id;
        this.name = route.name || `New Route ${new Date().toLocaleTimeString('en-AU', { month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true })}`;
        this.company_id = route.company_id;
        this.member_id = route.member_id;
        this.job_ids = route.job_ids;
        this.details = route.details ? new RouteDetails(route.details) : new RouteDetails({});
        this.status = route.status;
        this.planned_start_time = route.planned_start_time ? new Date(route.planned_start_time).getTime() : new Date().getTime();
        this.actual_start_time = route.actual_start_time ? route.actual_start_time : null;
        this.completed_time = route.completed_time ? route.completed_time : null;
        this.inventory_items = route.inventory_items || [];
        this.createdAt = route.createdAt;
        this.updatedAt = route.updatedAt;
    }

    create() {
        return RouteController.createRoute(this)
    }

    save() {
        return RouteController.updateRoute(this)
    }

    async assignDriver(member_id: string) {
        if (!member_id) {
            throw new Error("Member ID is required")
        }
        if (!this._id) {
            throw new Error("Route ID is required - Route has not been created yet")
        }
        this.member_id = member_id
        await this.save()
        await RouteController.assignDriverToAllJobsInRoute(this._id, member_id)
    }
}

// status constants
export enum ROUTE_STATUS {
    PLANNED = 'planned',
    IN_PROGRESS = 'in_progress',
    PENDING = 'pending',
    COMPLETED = 'completed',
    CANCELLED = 'cancelled',
};

export enum ROUTE_START_END_OPTION {
  DEFAULT = 'default',
  ROUND_TRIP = 'round_trip',
  START_FIRST_END_LAST = 'start_first_end_last',
  CUSTOM = 'custom',
}

export class RouteDetails {
    locations: RouteLocation[];
    legs: RouteLeg[];
    distance_kms: number | null;
    duration_seconds: number | null;
    cost_per_km: number | null;
    covered_distance_kms: number | null;
    total_cost: number | null;
    overview_polyline: string | {lat: number, lng: number}[];
    all_steps_polyline: string[] | {lat: number, lng: number}[];
    startEndOption: ROUTE_START_END_OPTION;
    startLocationIndex: number | null;
    endLocationIndex: number | null;

    constructor(details: any = {}) {
        this.locations = details?.locations ? details.locations.map((loc: any) => new RouteLocation(loc)) : [];
        this.legs = details?.legs ? details.legs.map((leg: any) => new RouteLeg(leg)) : [];
        this.distance_kms = details?.distance_kms ?? null;
        this.duration_seconds = details?.duration_seconds ?? null;
        this.cost_per_km = details?.cost_per_km ?? null;
        this.covered_distance_kms = details?.covered_distance_kms ?? null;
        this.total_cost = details?.total_cost ?? null;
        this.overview_polyline = details?.overview_polyline ?? null;
        this.all_steps_polyline = details?.all_steps_polyline ?? null;
        this.startEndOption = details?.startEndOption ?? ROUTE_START_END_OPTION.DEFAULT;
        this.startLocationIndex = details?.startLocationIndex ?? null;
        this.endLocationIndex = details?.endLocationIndex ?? null;
    }

    addNewLocationAtIndex(index: number, location: RouteLocation = new RouteLocation({})) {
        this.locations.splice(index, 0, location)
    }

    addNewLegAtIndex(index: number, leg: RouteLeg = new RouteLeg({})) {
        this.legs.splice(index, 0, leg)
    }

    reindexLegs() {
        this.legs.forEach((leg, index) => {
            leg.index = index;
        })
        return this.legs;
    }

}


export class RouteLeg {
    index: number;
    origin_route_location_index: number;
    destination_route_location_index: number;
    origin: JobLocation | null;
    destination: JobLocation | null;
    directions: JobDirections;
    cost_per_km: number;
    covered_distance_kms: number;
    total_cost: number;
    actual_distance_kms: number;
    actual_duration_seconds: number;
    polyline: any;
    constructor(details: any = {}) {
        this.index = details?.index ?? 0;
        this.origin_route_location_index = details?.origin_route_location_index ?? 0;
        this.destination_route_location_index = details?.destination_route_location_index ?? 0;
        this.origin = details?.origin ? new JobLocation(details?.origin) : null;
        this.destination = details?.destination ? new JobLocation(details?.destination) : null;
        this.directions = details?.directions ? new JobDirections(details?.directions) : new JobDirections({});
        this.cost_per_km = details?.cost_per_km ?? 0;
        this.covered_distance_kms = details?.covered_distance_kms ?? 0;
        this.total_cost = details?.total_cost ?? 0;
        this.actual_distance_kms = details?.actual_distance_kms ?? 0;
        this.actual_duration_seconds = details?.actual_duration_seconds ?? 0;
        this.polyline = details?.polyline ?? "";
    }
}

export enum ROUTE_LOCATION_TYPE {
    PICKUP = 'pickup',
    DELIVERY = 'delivery',
    INTERMEDIATE = 'intermediate',
    RETURN = 'return',
}

export class RouteLocation {
    index: number;
    job_id: string;
    location_type: string;
    address_type: string;
    address: JobAddress;
    location: JobLocation;
    name: string;
    note: string;
    inventory_items: LineItem[];
    route_location_type: ROUTE_LOCATION_TYPE;

    // Job is only used in the UI, it will be null when sending to the server
    job: Job | null;

    constructor(details: any = {}) {
        this.index = details?.index ?? 0;
        this.job_id = details?.job_id ?? "";
        this.location_type = details?.location_type ?? "Address";
        this.address_type = details?.address_type ?? "Home";
        this.address = details?.address ? new JobAddress(details?.address) : new JobAddress({});
        this.location = details?.location ? new JobLocation(details?.location) : new JobLocation({});
        this.name = details?.name ?? "Location";
        this.note = details?.note ?? "";
        this.inventory_items = details?.inventory_items ?? [];
        this.job = details?.job ? new Job(details.job) : null;
        this.route_location_type = details?.route_location_type ?? ROUTE_LOCATION_TYPE.DELIVERY;
    }
}



export interface IGoogleRoute {
    distanceMeters: number
    duration: number
    legs: IGoogleRouteLeg[]
    localizedValues: any
    polyline: {
        encodedPolyline: string
    }
    routeLabels: any
    routeToken: string
    staticDuration: number
    travelAdvisory: any
    viewport: {
        high: {
            latitude: number
            longitude: number
        }
        low: {
            latitude: number
            longitude: number
        }
    }
    warnings: any
}

export interface IGoogleRouteLeg {
    distanceMeters: number
    duration: string // "12345s"
    endLocation: {
        latLng: {
            latitude: number
            longitude: number
        }
    }
    startLocation: {
        latLng: {
            latitude: number
            longitude: number
        }
    }
    polyline: {
        encodedPolyline: string
    }
    localizedValues: any

}

/*
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
//////// GOOGLE OPTIMIZE ROUTE
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
*/
export interface IGoogleOptimizeLocation {
    latitude: number;
    longitude: number;
}

export interface IGoogleOptimizeArrivalLocation {
    arrivalLocation: IGoogleOptimizeLocation;
}

export interface IGoogleOptimizeShipment {
    pickups: IGoogleOptimizeArrivalLocation[];
    deliveries: IGoogleOptimizeArrivalLocation[];
}

export interface IGoogleOptimizeVehicle {
    startLocation: IGoogleOptimizeLocation;
    endLocation: IGoogleOptimizeLocation;
    costPerKilometer?: number;
}

export interface IGoogleOptimizeRouteModel {
    shipments: IGoogleOptimizeShipment[];
    vehicles: IGoogleOptimizeVehicle[];
    globalStartTime: string;
    globalEndTime: string;
}

export interface IGoogleOptimizeRouteRequest {
    model: IGoogleOptimizeRouteModel;
    considerRoadTraffic: boolean;
    populateTransitionPolylines: boolean;
    searchMode: 'RETURN_BEST' | 'RETURN_FAST';
}


const test: IGoogleOptimizeRouteRequest = {
    model: {
        shipments: [
            {
                pickups: [
                    {
                        arrivalLocation: {
                            latitude: 37.73881799999999,
                            longitude: -122.4161
                        }
                    }
                ],
                deliveries: [
                    {
                        arrivalLocation: {
                            latitude: 37.79581,
                            longitude: -122.4218856
                        }
                    }
                ]
            }
        ],
        vehicles: [
            {
                startLocation: {
                    latitude: 37.73881799999999,
                    longitude: -122.4161
                },
                endLocation: {
                    latitude: 37.73881799999999,
                    longitude: -122.4161
                },
                costPerKilometer: 1.0
            }
        ],
        globalStartTime: "2024-02-13T00:00:00.000Z",
        globalEndTime: "2024-02-14T06:00:00.000Z"
    },
    considerRoadTraffic: true,
    populateTransitionPolylines: true,
    searchMode: 'RETURN_BEST'
}