import React, { useState, useMemo, useRef, useEffect } from 'react';
import {
    Image,
    AccessibilityRole,
    ImageProps,
    ImageStyle,
    StyleSheet,
    TouchableOpacity,
    View,
    Text as RNText,
    Animated,
    ScrollView,
    Dimensions
    //@ts-ignore
} from "react-native";
import {
    ApplicationProvider,
    Button,
    Icon,
    IconRegistry,
    Modal,
    Layout,
    Text,
    List,
    ListItem,
    Divider,
    Card
} from "@ui-kitten/components";
import { SafeAreaView } from 'react-native-safe-area-context';
import { EvaIconsPack } from "@ui-kitten/eva-icons";
import { mapping, light, dark } from "@eva-design/eva";
import * as HoldingController from "../../functions/holding.controller"
import * as StorageController from "../../functions/storageController"
import * as JobController from "../../functions/job.controller"
import JobListItemComponent from './jobListItem.component';
import JobsList from './jobsList.component';
import { HoldingList, HoldingListItem } from './holdingList.component';
import * as CaseController from "../../functions/case.controller"
import { ReportDetailsCard } from './details/reportDetailsCard.component';

import { Job } from '../../models/Job.model';
import { Holding } from '../../models/Holding.model';
import { Case, CASE_STATUS } from '../../models/Case.model';
import { VendorProfile } from '../../models/VendorProfile.model';
import { Client } from '../../models/Client.model';
import { Company } from '../../models/Company.model';
import { Member } from '../../models/Member.model';
import { formatDateTime } from '../dashboardComponents/dashboardUtils';
import { FullJobReport, JobReportModal } from '../dashboardComponents/jobReportModal.component';
import { CompactJobListItem } from './CompactJobListItem';



interface CaseListProps {
    selectedCompany: Company,
    caseFilter: string | CASE_STATUS,
    var_viewedBy: string,
    var_vendorProfiles: any,
    fn_zoomToMarker: any,
    fn_selectJob: any,
    var_drivers: Member[],
    var_clients: Client[],
    fn_setDirections: any,
    fn_openAssign: any,
    fn_openJobReport: any,
    fn_vendorAcceptJob: any,
    fn_onCreateNewCaseJob: any,
    fn_onCreateNewHolding: any,
    fn_openCaseDetails: any
}


export const CaseList = ({
    // cases,
    selectedCompany,
    caseFilter,
    var_viewedBy,
    var_vendorProfiles,
    fn_zoomToMarker,
    fn_selectJob,
    var_drivers,
    var_clients,
    fn_setDirections,
    fn_openAssign,
    fn_openJobReport,
    fn_vendorAcceptJob,
    fn_onCreateNewCaseJob,
    fn_onCreateNewHolding,
    fn_openCaseDetails
}: CaseListProps) => {

    const [selectedCase, setSelectedCase] = useState(null as Case | null)
    const selectedCaseRef = useRef(null)
    const [cases, setCases] = useState([] as Case[])
    const [showJobDetails, setShowJobDetails] = useState(false)
    const [jobLoadedForReport, setJobLoadedForReport] = useState(null as Job | null)

    const [layoutWidth, setLayoutWidth] = useState(0)

    const { width, height } = Dimensions.get('window');
    useEffect(() => {
        // on selected company
        if (selectedCompany) {
            setCases([])
            loadCasesOnFilter(caseFilter as CASE_STATUS)
        }
    }, [selectedCompany])

    useEffect(() => {
        console.log("🚀 ~ useEffect ~ statusFilter:", caseFilter)
        loadCasesOnFilter(caseFilter as CASE_STATUS)
    }, [caseFilter])



    const loadCasesOnFilter = (filter: CASE_STATUS) => {
        switch (filter) {
            case "complete":
                loadCompleteCases()
                break;
            case "ongoing":
                loadOngoingCompanyCases()
                break;
            case "pending":
                loadPendingCompanyCases()
                break;
            case "cancelled":
                loadCancelledCases()
                break;
            default:
                loadOngoingCompanyCases()
                break;
        }
    }




    /////////////////////////////////////////////
    ////////////////////////////////////////////
    // COMPLETE & CANCELLED CASES
    ////////////////////////////////////////////
    ////////////////////////////////////////////
    const [completeCases, setCompleteCases] = useState([] as Case[])
    const [cancelledCases, setCancelledCases] = useState([] as Case[])

    const loadCompleteCases = async () => {
        const cases = await CaseController.getCasesByCompanyIdAndStatus(selectedCompany._id, "complete", 10, 0)
        setCompleteCases(cases)
    }


    const pagenateCompleteCases = async () => {
        const cases = await CaseController.getCasesByCompanyIdAndStatus(selectedCompany._id, "complete", 10, completeCases.length)
        setCompleteCases([...completeCases, ...cases])
    }

    const loadCancelledCases = async () => {
        const cases = await CaseController.getCasesByCompanyIdAndStatus(selectedCompany._id, "cancelled", 10, 0)
        setCancelledCases(cases)
    }

    const pagenateCancelledCases = async () => {
        const cases = await CaseController.getCasesByCompanyIdAndStatus(selectedCompany._id, "cancelled", 10, cancelledCases.length)
        setCancelledCases([...cancelledCases, ...cases])
    }

    /////////////////////////////////////////////
    ////////////////////////////////////////////
    // ONGOING & PENDING CASES
    ////////////////////////////////////////////
    ////////////////////////////////////////////



    const loadOngoingCompanyCases = async () => {
        const cases = await CaseController.getCasesByCompanyIdAndStatus(selectedCompany._id, "ongoing", 100, 0) as Case[]
        // // console.log("🚀 ~ loadCompanyCases ~ cases:", cases)
        setCases(cases)
    }

    const loadPendingCompanyCases = async () => {
        const cases = await CaseController.getCasesByCompanyIdAndStatus(selectedCompany._id, "pending", 100, 0) as Case[]
        // // console.log("🚀 ~ loadCompanyCases ~ cases:", cases)
        setCases(cases)
    }


    /////////////////////////////////////////////
    ////////////////////////////////////////////
    // SET CASE STATUS
    ////////////////////////////////////////////
    ////////////////////////////////////////////

    const setCaseOngoing = async (caseId: string) => {
        const updatedCase = await CaseController.setCaseStatus(caseId, "ongoing")
        // console.log("🚀============== ~ file: casesList.component.js:132 ~ setCaseOngoing ~ updatedCase🚀==============", updatedCase)
        loadOngoingCompanyCases()
    }

    const setCasePending = async (caseId: string) => {
        const updatedCase = await CaseController.setCaseStatus(caseId, "pending")
        // console.log("🚀============== ~ file: casesList.component.js:138 ~ setCasePending ~ updatedCase🚀==============", updatedCase)
        loadOngoingCompanyCases()
    }

    const setCaseComplete = async (caseId: string) => {
        const updatedCase = await CaseController.setCaseStatus(caseId, "complete")
        // console.log("🚀============== ~ file: casesList.component.js:144 ~ setCaseComplete ~ updatedCase🚀==============", updatedCase)
        loadOngoingCompanyCases()
    }

    const setCaseCancelled = async (caseId: string) => {
        const updatedCase = await CaseController.setCaseStatus(caseId, "cancelled")
        // console.log("🚀============== ~ file: casesList.component.js:150 ~ setCaseCancelled ~ updatedCase🚀==============", updatedCase)
        loadOngoingCompanyCases()
    }

    const RenderJobDetailModal = () => {
        return (
            <Modal
                visible={showJobDetails}
                backdropStyle={styles.backdrop}
                onBackdropPress={() => setShowJobDetails(false)}
            >
                <View style={{ height: height * 0.95 }}>
                    <ScrollView style={{ flex: 1 }}>
                        {jobLoadedForReport &&
                            <FullJobReport
                                job={jobLoadedForReport}
                                onClose={() => setShowJobDetails(false)} />
                        }
                    </ScrollView>
                </View>
            </Modal>
        )
    }

    const [showRemoveFromHoldingOptionsModal, setShowRemoveFromHoldingOptionsModal] = useState(false)
    //Shows options to either create a job from the holding or just remove it from the holding with no job


    const onOpenJobReport = async (job_id: string) => {
        const job = await JobController.getJobById(job_id) as Job
        setJobLoadedForReport(job)
        setShowJobDetails(true)
    }

    const mergeAndSortCases = (caseJobs: Job[], caseHoldings: Holding[]) => {
        // Assuming both caseJobs and caseHoldings have a 'createdAt' field
        const combinedList = [...caseJobs, ...caseHoldings];
        combinedList.sort((a: any, b: any) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
        return combinedList;
    };


    const RenderCaseListItem = ({ caseItem, index }: { caseItem: Case, index: number }) => {
        const caseJobs = caseItem.job_ids || []
        const caseHoldings = caseItem.holding_ids || []
        const combinedCases = mergeAndSortCases(caseJobs, caseHoldings);

        // find those jobs in storage controller
        const STATUS_COLOUR = {
            "ongoing": "orange",
            "pending": "blue",
            "complete": "green",
            "cancelled": "purple"
        }

        const STATUS_CARD_STATE = {
            "ongoing": "warning",
            "pending": "info",
            "complete": "success",
            "cancelled": "basic"
        }

        const RenderCaseStatusBadge = () => (
            <View style={{
                backgroundColor: STATUS_COLOUR[caseItem.status],
                borderRadius: 5,
                padding: 5,
                justifyContent: 'center',
            }}>
                {/* capitalise item.status */}
                <Text category='s1' style={{ color: 'white', textAlign: 'center' }}>{caseItem.status.charAt(0).toUpperCase() + caseItem.status.slice(1)}</Text>
            </View>
        )

        const onOpenNewJobModal = (caseId: string) => {
            fn_onCreateNewCaseJob(caseId)
        }

        const RenderSmallJobDetailsCard = ({ job }: { job: Job }) => {
            return (
                <Card
                    style={{ margin: 5 }}
                    // status={STATUS_CARD_STATE[job.status]}
                    header={() =>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                            <Text category='s1'>Job Details</Text>
                            <Button
                                size="tiny"
                                status='info'
                                onPress={() => onOpenJobReport(job._id)}
                            >
                                Report
                            </Button>

                        </View>
                    }
                >
                    <View style={{ flexDirection: 'row' }}>
                        <View style={{ flexDirection: 'column' }}>
                            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                <Text category='s1'>Job ID:</Text>
                                <Text category='s2'>{formatDateTime(job.start_time)}</Text>
                            </View>
                            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                <Text category='s1'>Service:</Text>
                                <Text category='s2'>{job.getServicesNames()}</Text>
                            </View>
                            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                <Text category='s1'>Status:</Text>
                                <Text category='s2'>{job.status}</Text>
                            </View>
                        </View>
                        <View style={{ flexDirection: 'column' }}></View>
                    </View>
                </Card>
            )
        }

        const onSetLayoutWidth = (event: any) => {
            if (event?.nativeEvent?.layout?.width > 0) {
                if (event?.nativeEvent?.layout?.width != layoutWidth) {
                    setLayoutWidth(event?.nativeEvent?.layout?.width)
                }
            }
        }


        return (
            <Card
                onPress={() => fn_openCaseDetails(caseItem)}
                disabled={false}
                style={{ margin: 5 }}
                status={STATUS_CARD_STATE[caseItem.status]}
                header={() =>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <View style={{ flexDirection: 'row', gap: 3 }}>
                            <Text category='s1'>Case ID:</Text>
                            <Text category='p1'>{caseItem.name}</Text>
                        </View>
                        <Button
                            size="tiny"
                            status='success'
                            // appearance='ghost'
                            onPress={() => fn_openCaseDetails(caseItem)}
                        >
                            Details
                        </Button>
                    </View>
                }
                footer={() =>
                    <View style={{ flexDirection: 'row', gap: 3, justifyContent: 'space-between' }}>
                        <RenderCaseStatusBadge />
                        <Button status="info" appearance="outline" size='tiny' onPress={() => onOpenNewJobModal(caseItem._id)}>Add Job To Case</Button>
                        <Button status="info" appearance="outline" size='tiny' onPress={() => fn_onCreateNewHolding(caseItem._id)}>Add Holding To Case</Button>
                        <View style={{ flexDirection: 'row', gap: 3 }}>
                            <Button status="warning" appearance={caseItem.status == "ongoing" ? 'filled' : "outline"} size='tiny' onPress={() => setCaseOngoing(caseItem._id)}>Ongoing</Button>
                            <Button status="info" appearance={caseItem.status == "pending" ? 'filled' : "outline"} size='tiny' onPress={() => setCasePending(caseItem._id)}>Pending</Button>
                            <Button status="success" appearance={caseItem.status == "complete" ? 'filled' : "outline"} size='tiny' onPress={() => setCaseComplete(caseItem._id)}>Complete</Button>
                            <Button status="purple" appearance={caseItem.status == "cancelled" ? 'filled' : "outline"} size='tiny' onPress={() => setCaseCancelled(caseItem._id)}>Cancel</Button>
                        </View>
                    </View>
                }
            >
                <View onLayout={(event) => onSetLayoutWidth(event?.nativeEvent?.layout?.width)} style={{ flexDirection: 'column', margin: -15, marginBottom: 0, paddingBottom: 5, justifyContent: 'flex-start' }}>
                    <JobReportModal
                        job={jobLoadedForReport}
                        visible={showJobDetails}
                        setVisible={setShowJobDetails}
                    />
                    <View style={[styles.cardColumn, styles.debug]}>
                        {/* Case details card */}
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: 5 }}>
                            <View style={{ flexDirection: 'column' }}>
                                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                    <Text category='s1'>Name:</Text>
                                    <Text category='s2'>{caseItem.details.customer_details?.name}</Text>
                                </View>
                                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                    <Text category='s1'>Phone:</Text>
                                    <Text category='s2'>{caseItem.details.customer_details?.phone}</Text>
                                </View>
                            </View>
                            <View style={{ flexDirection: 'column' }}>
                                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                    <Text category='s1'>Services:</Text>
                                    <Text category='s2'>{caseItem.getCaseServiceNames()}</Text>
                                </View>
                                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                    <Text category='s1'>Email:</Text>
                                    <Text category='s2'>{caseItem.details.customer_details?.email}</Text>
                                </View>
                            </View>
                            <View style={{ flexDirection: 'column' }}></View>

                        </View>
                        {combinedCases.length > 0 && combinedCases.map((item, index) => {
                            if (item && item.hasOwnProperty("dispatchable")) {
                                //@ts-ignore
                                const driver = var_drivers.find((driver: Member) => driver._id === item.member_id) || new Member()
                                const client = var_clients.find((client: Client) => client._id === item.client_id) || new Client({})
                                return (
                                    // <RenderSmallJobDetailsCard
                                    //     key={index}
                                    //     job={item as Job} // Assuming 'item' should be passed here instead of 'job'
                                    // />
                                    <View style={{ backgroundColor: 'rgba(0,0,0,0.3)', padding: 5, borderRadius: 5 }}>
                                        <CompactJobListItem
                                            key={index}
                                            job={item as Job} // Assuming 'item' should be passed here instead of 'job'
                                            client={client}
                                            driver={driver}
                                            onAssign={() => { }}
                                            onViewDetails={() => { }}
                                            isSelected={false}
                                            // onSelect={() => {}}
                                            selectedJobId={null}
                                            containerWidth={layoutWidth}
                                            onCheckboxChange={() => { }} />
                                        {/* <JobListItemComponent
                                        key={index}
                                        job={item as Job} // Assuming 'item' should be passed here instead of 'job'
                                        var_viewedBy={var_viewedBy || "vendor"}
                                        // var_vendorProfiles={var_vendorProfiles}
                                        fn_zoomToMarker={fn_zoomToMarker}
                                        fn_selectJob={fn_selectJob}
                                        driver={driver}
                                        client={client}
                                        fn_setDirections={fn_setDirections}
                                        fn_openAssign={fn_openAssign}
                                        // fn_openJobReport={onOpenJobReport}
                                        fn_vendorAcceptJob={fn_vendorAcceptJob}
                                        selectedJobId={''}
                                    /> */}
                                    </View>
                                )
                            }
                            if (item && item.hasOwnProperty("holding_time")) {
                                return (
                                    <HoldingList
                                        key={index}
                                        preloadedHoldingItems={[item]}
                                        selectedCompany={selectedCompany}
                                        //@ts-ignore
                                        showReleasedList={undefined}
                                    />
                                )
                            }
                        })}
                    </View>
                </View>
            </Card>
        )
    }
    const styles = StyleSheet.create({
        backdrop: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
        modal: {
            width: width * 0.9,
            height: height * 0.9,
            // backgroundColor: 'white',
        },
        cardColumn: {
            flexDirection: 'column',
            // justifyContent: 'space-between',
            // flex: 1,
        },
        debug: {
            // borderWidth: 1,
            // borderColor: 'red',
            // borderStyle: 'solid',
        },
        orangeDebug: {
            // borderWidth: 1,
            // borderColor: 'orange',
            // borderStyle: 'solid',
        }
    });

    interface CaseListItemProps {
        item: Case,
        index: number
    }


    return (
        <View style={{ flex: 1 }}>
            <RenderJobDetailModal />
            {caseFilter == "ongoing" &&
                <List
                    data={cases}
                    ItemSeparatorComponent={Divider}
                    renderItem={({ item, index }: CaseListItemProps) => (
                        <RenderCaseListItem caseItem={item} index={index} />
                    )}
                />
            }
            {caseFilter == "pending" &&
                <List
                    data={cases}
                    ItemSeparatorComponent={Divider}
                    renderItem={({ item, index }: CaseListItemProps) => (
                        <RenderCaseListItem caseItem={item} index={index} />
                    )}
                />
            }
            {caseFilter == "complete" &&
                <List
                    data={completeCases}
                    ItemSeparatorComponent={Divider}
                    renderItem={({ item, index }: CaseListItemProps) => (
                        <RenderCaseListItem caseItem={item} index={index} />
                    )}
                    onEndReached={pagenateCompleteCases}
                />
            }
            {caseFilter == "cancelled" &&
                <List
                    data={cancelledCases}
                    ItemSeparatorComponent={Divider}
                    renderItem={({ item, index }: CaseListItemProps) => (
                        <RenderCaseListItem caseItem={item} index={index} />
                    )}
                    onEndReached={pagenateCancelledCases}
                />
            }
        </View>
    );
}


