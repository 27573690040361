import React from 'react';
import { BaseClientDetailsCard } from './BaseClientDetailsCard';
import { ClientCreatedJobClientDetailsCardProps } from './ClientDetailsCardTypes';

export function ClientCreatedJobClientDetailsCard({
  linkedClientCompany,
  ...props
}: ClientCreatedJobClientDetailsCardProps) {
  return (
    <BaseClientDetailsCard
      {...props}
      linkedClientCompany={linkedClientCompany}
      cardStatus="success"
      showClientSelect={false}
      showRateSelect={true}
    />
  );
}