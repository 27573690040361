import React from 'react';
//@ts-ignore
import { Image, AccessibilityRole, ImageProps, ImageStyle, StyleSheet, TouchableOpacity, View, Text as RNText, } from "react-native";
import { ApplicationProvider, Button, Icon, IconRegistry, Layout, Text, List, ListItem, Card } from "@ui-kitten/components";
import * as MemberController from "../../functions/membership.controller";
import * as StorageController from "../../functions/storageController";
import { Member } from '../../models/Member.model';


interface MembersListProps {
    var_members: Member[];
    var_selectedMemberToEdit: Member;
    fn_onSelectMember: (member: any) => void;
}


export const MembersList = ({
    var_members,
    var_selectedMemberToEdit,
    fn_onSelectMember,
}: MembersListProps) => {

    const [membersList, setMembersList] = React.useState([] as Member[])
    const [selectedMember, setSelectedMember] = React.useState(null as Member | null)

    React.useEffect(() => {
        // console.log("MembersList useEffect")
        // console.log(props)
        if (var_members) {
            setMembersList(var_members)
        }
        if (var_selectedMemberToEdit) {
            setSelectedMember(var_selectedMemberToEdit)
        }
    }, [var_members, var_selectedMemberToEdit])

    // online Icon with green color
    const OnlineIcon = (style: any) => (
        <Icon {...style} name='radio-button-on' fill='green' width={25} height={25} />
    );

    // offline Icon with red color
    const OfflineIcon = (style: any) => (
        <Icon {...style} name='radio-button-on' fill='red' width={25} height={25} />
    );

    const TickIcon = (style: any) => (
        <Icon {...style} name='checkmark-circle-2-outline' width={15} height={15} fill={'green'} />
    );


    const setMember = (item: Member) => {
        fn_onSelectMember(item)
    }

    const memberDescription = (item: Member) => {
        return (
            <View style={{ flexDirection: "row", flex: 1 }}>
                {/* <View style={{ flex: 1 }}>
                    <Text category='s1' style={{}}>{item.name}</Text>
                    <Text category='s1' style={{}}>{item.status}</Text>
                </View> */}
                <View style={{ flex: 1, flexDirection: "row" }}>
                    {item.is_admin && (
                        <View style={{ flexDirection: 'row' }}>
                            <Text category='s1' style={{}}>Admin: </Text>
                            <TickIcon />
                            <Text category='s1' style={{}}>  </Text>
                        </View>
                    )}
                    {item.is_operator && (
                        <View style={{ flexDirection: 'row' }}>
                            <Text category='s1' style={{}}>Operator: </Text>
                            <TickIcon />
                            <Text category='s1' style={{}}>  </Text>
                        </View>
                    )}
                    {item.is_driver && (
                        <View style={{ flexDirection: 'row' }}>
                            <Text category='s1' style={{}}>Driver: </Text>
                            <TickIcon />
                            <Text category='s1' style={{}}>  </Text>
                        </View>
                    )}
                    {item.is_client && (
                        <View style={{ flexDirection: 'row' }}>
                            <Text category='s1' style={{}}>Client: </Text>
                            <TickIcon />
                            <Text category='s1' style={{}}>  </Text>
                        </View>
                    )}


                </View>
            </View>
        )
    }

    const sortOnlineMembers = (members: Member[]) => {
        let onlineMembers = [] as Member[]
        let offlineMembers = [] as Member[]
        if (!members) return []
        if (members.length === 0) return []
        members.forEach((member) => {
            if (member.locked || member.deleted) return
            if (member.online) {
                onlineMembers.push(member)
            }
            else {
                offlineMembers.push(member)
            }
        })
        return onlineMembers.concat(offlineMembers)
    }


    const renderItem = ({ item, index }: { item: Member, index: number }) => {
        // console.log(item)
        return (
            <ListItem
                title={`${item.name}`}
                // if selectedMember is not null, then check if the item is the selectedMember, based on the id, background color will be set to green
                style={{
                    backgroundColor: selectedMember && selectedMember._id === item._id ? "green" : "",
                    border: "1px solid black"
                }}
                onPress={() => { setMember(item) }}
                accessoryLeft={() => {
                    if (item.deleted || item.locked) {
                        return <OfflineIcon />
                    } else {
                        if (item.online) {
                            return <OnlineIcon />
                        } else {
                            return <OfflineIcon />
                        }
                    }
                }}
                description={memberDescription(item)}
            />
        );
    }

    return (
        <Layout style={{ flex: 1, flexDirection: 'column' }}>
            <Text style={{ textAlign: 'center', backgroundColor: "green" }}>Active Members</Text>
            {/* Active members */}
            <Layout style={{ flex: 3 }}>
                <List
                    style={styles.container}
                    contentContainerStyle={styles.contentContainer}
                    data={sortOnlineMembers(var_members)}
                    renderItem={renderItem}
                />
            </Layout>
            {/* Locked members */}
            {
                // if user is not a client, then show the locked members
                !StorageController.getAppState().selectedMembership?.is_client &&
                StorageController.getAppState().selectedMembership?.is_admin &&
                <>
                    <Text style={{ textAlign: 'center', backgroundColor: 'blue' }}>Locked Members</Text>
                    <Layout style={{ flex: 1 }}>
                        <List
                            style={styles.container}
                            contentContainerStyle={styles.contentContainer}
                            data={
                                var_members.length > 0 ? var_members.filter((member) => {
                                    let m = { ...member }
                                    m.online = false
                                    return (member.locked && !member.deleted)
                                }) :
                                    null
                            }
                            renderItem={renderItem}
                        />
                    </Layout>
                </>
            }
            {/* Deleted members */}
            {/* {
                // only show to admin
                StorageController.getAppState().selectedMembership.is_admin &&
                <>
                    <Text style={{ textAlign: 'center', backgroundColor: 'red' }}>Deleted Members</Text>
                    <Layout style={{ flex: 1 }}>
                        <List
                            style={styles.container}
                            contentContainerStyle={styles.contentContainer}
                            data={
                                var_members.length > 0 ? var_members.filter((member) => {
                                    return member.deleted
                                }) :

                                    null
                            }
                            renderItem={renderItem}
                        />
                    </Layout>
                </>
            } */}
        </Layout>
    )
}


const styles = StyleSheet.create({
    container: {
        maxHeight: "100%",
        height: "100%",
        overflow: "hidden"
    },
    contentContainer: {
        paddingHorizontal: 0,
        paddingVertical: 0,
        overflow: "auto"
    },
    listContainer: {
        flex: 1
        // flexDirection: "row",
        // height: "100%",
        // width: "100%",
        // overflow: "hidden"
    },
    tinyLogo: {
        width: "100%",
        height: 100,
    },
    item: {
        marginVertical: 4,
    },
});