import React from 'react'
//@ts-ignore
import { View } from 'react-native'
import { Button, Card, Text } from '@ui-kitten/components'
import { Job } from '../../models/Job.model'
import * as GoogleController from '../../functions/google.controller'


export const RoadsideJobInfo = ({
    job,
    setShowJobInfoWindow = () => { },
    setShowTowingPolyline = () => { },
    setTowingPolylines = () => { },
    fn_selectJob = () => { }
}: {
    job: Job,
    setShowJobInfoWindow: any,
    setShowTowingPolyline: any,
    setTowingPolylines: any,
    fn_selectJob: any

}) => {
    let serviceNames = ""
    if (job?.details?.selected_services) {
        serviceNames = job?.getServicesNames()
    }
    // const serviceNames = job.details.selected_services.map(service => service.name).join(", ")
    const roadsideDetails = {
        customerName: job?.details?.customer_details?.name || "N/A",
        customerPhone: job?.details?.customer_details?.phone || "N/A",
        customerEmail: job?.details?.customer_details?.email || "N/A",
        address: GoogleController.addressBuilder(job?.details?.address) || "N/A",
        make: job?.details?.vehicle_details?.make || "N/A",
        model: job?.details?.vehicle_details?.model || "N/A",
        color: job?.details?.vehicle_details?.colour || "N/A",
        year: job?.details?.vehicle_details?.year || "N/A",
        rego: job?.details?.vehicle_details?.rego || "N/A",
        comments: job?.details?.comments || "N/A",
    }

    return (
        <Card
            disabled={true}
            status='primary'
            header={() => (
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <View style={{ flexDirection: "row", justifyContent: 'space-between' }}>
                        <Text category="s1">{serviceNames}</Text>
                    </View>
                </View>
            )}
            footer={() => (
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Button
                        status='primary'
                        appearance='outline'
                        onPress={() => {
                            if (job) {
                                console.log("on click towing info")
                                setShowJobInfoWindow(false)
                                setShowTowingPolyline(false)
                                setTowingPolylines(null)
                                fn_selectJob(job)
                            }
                        }}
                    >
                        Open Job
                    </Button>
                    <Button
                        status='danger'
                        appearance='outline'
                        onPress={() => {
                            console.log("on click roadside info")
                            setShowJobInfoWindow(false)
                            setShowTowingPolyline(false)
                            setTowingPolylines(null)
                        }}
                    >
                        Close
                    </Button>
                </View>
            )}
        >

            <View style={{ margin: -10 }}>
                <View style={{ flexDirection: "row", justifyContent: 'space-between', borderBottomWidth: 1 }}>
                    <Text style={{ flex: 1 }} category="s1">Customer Name:</Text>
                    <Text style={{ flex: 3 }}>{roadsideDetails.customerName}</Text>
                </View>
                <View style={{ flexDirection: "row", justifyContent: 'space-between', borderBottomWidth: 1 }}>
                    <Text style={{ flex: 1 }} category="s1">Customer Phone:</Text>
                    <Text style={{ flex: 3 }}>{roadsideDetails.customerPhone}</Text>
                </View>
                <View style={{ flexDirection: "row", justifyContent: 'space-between', borderBottomWidth: 1 }}>
                    <Text style={{ flex: 1 }} category="s1">Customer Email:</Text>
                    <Text style={{ flex: 3 }}>{roadsideDetails.customerEmail}</Text>
                </View>
                <View style={{ flexDirection: "row", justifyContent: 'space-between', borderBottomWidth: 1 }}>
                    <Text style={{ flex: 1 }} numberOfLines={1} category="s1">Address:</Text>
                    <Text style={{ flex: 3 }}>{roadsideDetails.address}</Text>
                </View>
                <View style={{ flexDirection: "row", justifyContent: 'space-between', borderBottomWidth: 1 }}>
                    <Text style={{ flex: 1 }} category="s1">Make:</Text>
                    <Text style={{ flex: 3 }}>{roadsideDetails.make}</Text>
                </View>
                <View style={{ flexDirection: "row", justifyContent: 'space-between', borderBottomWidth: 1 }}>
                    <Text style={{ flex: 1 }} category="s1">Model:</Text>
                    <Text style={{ flex: 3 }}>{roadsideDetails.model}</Text>
                </View>
                <View style={{ flexDirection: "row", justifyContent: 'space-between', borderBottomWidth: 1 }}>
                    <Text style={{ flex: 1 }} category="s1">Color:</Text>
                    <Text style={{ flex: 3 }}>{roadsideDetails.color}</Text>
                </View>
                <View style={{ flexDirection: "row", justifyContent: 'space-between', borderBottomWidth: 1 }}>
                    <Text style={{ flex: 1 }} category="s1">Year:</Text>
                    <Text style={{ flex: 3 }}>{roadsideDetails.year}</Text>
                </View>
                <View style={{ flexDirection: "row", justifyContent: 'space-between', borderBottomWidth: 1 }}>
                    <Text style={{ flex: 1 }} category="s1">Rego:</Text>
                    <Text style={{ flex: 3 }}>{roadsideDetails.rego}</Text>
                </View>
                <View style={{ flexDirection: "row", justifyContent: 'space-between', borderBottomWidth: 1 }}>
                    <Text style={{ flex: 1 }} category="s1">Comments:</Text>
                    <Text style={{ flex: 3 }}>{roadsideDetails.comments}</Text>
                </View>
            </View>
        </Card>
    )
}