import React, { useState, useMemo, useRef, useEffect, useCallback } from 'react';
import {
    Image,
    AccessibilityRole,
    ImageProps,
    ImageStyle,
    StyleSheet,
    TouchableOpacity,
    View,
    Text as RNText,
    Animated,
    ScrollView,
    Dimensions
    //@ts-ignore
} from "react-native";
import {
    ApplicationProvider,
    Button,
    Icon,
    IconRegistry,
    Input,
    Modal,
    Layout,
    Text,
    List,
    ListItem,
    Divider,
    Card,
    useTheme,
    //@ts-ignore
    CheckBox,
    Select,
    SelectItem,
    //@ts-ignore
    IndexPath
} from "@ui-kitten/components";
import OSRM from 'osrm';
import { SafeAreaView } from 'react-native-safe-area-context';
import { EvaIconsPack } from "@ui-kitten/eva-icons";
import * as StorageController from "../../functions/storageController"
import * as JobController from "../../functions/job.controller"
import * as GoogleController from '../../functions/google.controller';
import * as RouteController from "../../functions/route.controller"
import * as MembershipController from "../../functions/membership.controller"
import { Job, JobAddress, LineItem, JobLocation, JobDirections, JOB_STATUS } from "../../models/Job.model";
import { ReportDetailsCard } from '../job/details/reportDetailsCard.component';
import { CreateJobFromHolding } from '../job/modals/createJobFromHoldingModal.component';
import { InvoicedTotalCard } from '../job/details/jobInvoicedTotalDetailsCard.component';
import * as ClientController from '../../functions/client.controller'
// import { LocationDetailsCard } from '../job/newJobComponents/locationDetailsCard.component';
import { Route, RouteDetails, ROUTE_STATUS, RouteLeg, RouteLocation, IGoogleRoute, IGoogleRouteLeg, ROUTE_LOCATION_TYPE, IGoogleOptimizeRouteRequest, IGoogleOptimizeArrivalLocation, IGoogleOptimizeShipment, ROUTE_START_END_OPTION } from '../../models/Route.model';
import { AddressDetailsCard } from '../job/details/addressDetailsCard.component';
import { InventoryItem } from '../../models/Company.model';
import { RoutePlanningMap } from './routePlanningMap.component';
import { RouteLegsCard } from './routeLegsCard.component';
import { RouteLocationDetailsCard } from './routeLocationDetailsCard.component';
import { RouteList } from './routeList.component';
import { Member } from '../../models/Member.model';
import { JobReportModal, FullJobReport } from '../dashboardComponents/jobReportModal.component';
import * as polyline from '@mapbox/polyline';
import { DriverSelect } from './DriverSelect';
import { AvailableJobs } from './AvailableJobs';
import { RouteActions } from './RouteActions';
import { ConfirmRemoveRouteLocationModal } from './ConfirmRemoveRouteLocationModal';
import { CreateJobForRoute } from './createJobForRoute.component';
import { RouteSummary } from './RouteSummary';
import { RouteStartEndSelector } from './RouteStartEndSelector';
import Toast from 'react-native-toast-message';
import * as OsrmController from '../../functions/osrm.controller';
const { width, height } = Dimensions.get('window');


enum ROUTE_ACTION_TYPES {
    GET_CURRENT_ROUTE = 'GET_CURRENT_ROUTE',
    GET_OPTIMIZED_ROUTE = 'GET_OPTIMIZED_ROUTE',
}

const VEHICLE_CAPACITY = 10000;
const VEHICLE_PROFILE = 'car';
const PICKUP_AMOUNT = 1;
const DELIVERY_AMOUNT = 1;
const DEFAULT_PRIORITY = 0;


const polylineStrokeColourSet = (index: number) => {
    const colours = [
        "#0000FF", "#4B0082", "#9400D3", "#FF7F00", "#FF0000",
        "#00FF00", "#00FFFF", "#FF00FF", "#FFD700", "#32CD32",
        "#8A2BE2", "#FF1493", "#1E90FF", "#FF4500", "#00CED1",
    ];
    return colours[index % colours.length];
};

export const CreateRouteContainer = ({ onClose }: any) => {

    const [routeState, setRouteState] = useState<Route>(new Route({}));
    const [routeCanBeCreated, setRouteCanBeCreated] = useState(false);
    const [selectedRouteLocation, setSelectedRouteLocation] = useState<RouteLocation | null>(null);
    const [selectedRouteLocationIndex, setSelectedRouteLocationIndex] = useState<number>(0);
    const [comapnyInventoryItems, setCompanyInventoryItems] = useState<InventoryItem[]>([]);
    const [availableJobs, setAvailableJobs] = useState<Job[]>([]);
    const [showRouteLocationDetailsPanel, setShowRouteLocationDetailsPanel] = useState(false);
    const [showRouteLegsPanel, setShowRouteLegsPanel] = useState(false);
    const [showJobReportModal, setShowJobReportModal] = useState(false);
    const routeStateRef = useRef(routeState);
    const [selectedLegIndex, setSelectedLegIndex] = useState<number | null>(null);
    const [routeAction, setRouteAction] = useState<ROUTE_ACTION_TYPES>(ROUTE_ACTION_TYPES.GET_OPTIMIZED_ROUTE);
    //@ts-ignore
    const theme = useTheme() as any;
    const [showConfirmRemoveRouteLocationModal, setShowConfirmRemoveRouteLocationModal] = useState(false);
    const [showCreateJobForRouteModal, setShowCreateJobForRouteModal] = useState(false);
    const [selectedMarkerIndex, setSelectedMarkerIndex] = useState<number | null>(null);
    const [isRouteCreated, setIsRouteCreated] = useState(false);

    useEffect(() => {
        load()
    }, []);

    useEffect(() => {
        const canBeCreated = checkIfRouteCanBeCreated();
        if (canBeCreated != routeCanBeCreated) {
            setRouteCanBeCreated(canBeCreated);
        }
    }, [routeState]);

    const load = async () => {
        const company = StorageController.getCurrentCompany();
        const r = new Route({ ...routeState, company_id: company._id, details: new RouteDetails() });
        setRouteState(r);
        const cInventory = company.inventory
        if (cInventory) {
            setCompanyInventoryItems(cInventory);
        }
        const fetchedJobs = await JobController.getIncompleteJobsByCompanyId(company._id);
        const incompleteJobs = fetchedJobs.map((job: Job) => new Job(job));
        const incompleteJobsWithoutRoute = incompleteJobs.filter((job: Job) => !job.route_id);
        setAvailableJobs(incompleteJobsWithoutRoute);
    }

    const onSetRouteState = (newState: Route, caller: string = 'unknown') => {
        newState = new Route(newState);
        // updated the job ids from the locations
        const jobIds = newState.details.locations.map(location => location.job_id);
        newState.job_ids = jobIds;
        routeStateRef.current = newState;
        console.log("🚀============== ~ file: createRouteContainer.component.tsx:92 ~ onSetRouteState ~ newState🚀==============", newState, caller)
        setRouteState(newState);
    }


    const generateRoute = async (action: ROUTE_ACTION_TYPES = routeAction) => {
        if (action === ROUTE_ACTION_TYPES.GET_CURRENT_ROUTE) {
            getRouteDirections();
        } else if (action === ROUTE_ACTION_TYPES.GET_OPTIMIZED_ROUTE) {
            getOptimizeRoute();
        }
    }



    const checkIfRouteCanBeCreated = () => {
        const locations = routeStateRef.current.details.locations.length > 1;
        const legs = routeStateRef.current.details.legs;
        // const hasJobs = locations.some(location => location.job_id);
        // const hasAddresses = locations.some(location => location.address);
        const hasBeenCalculated = legs.length > 0;
        return  locations && hasBeenCalculated;
    }



    const onSelectLocationFromMap = (location: RouteLocation) => {
        onSelectLocation(location.index);
    }


    /**
     * Remove location from locations
     * @param index
     * @returns
     */
    const onRemoveLocation = (index: number) => {
        const updatedLocations = [...routeState.details.locations];
        updatedLocations.splice(index, 1);
        updateRouteState({ details: { locations: updatedLocations } as RouteDetails }, 'onRemoveLocation');
        setSelectedRouteLocation(null);
        setSelectedRouteLocationIndex(0);
    }

    const onConfirmRemoveLocation = (index: number) => {
        setSelectedRouteLocationIndex(index);
        setShowConfirmRemoveRouteLocationModal(true);
    }




    const onSetSelectedRouteLocation = (location: RouteLocation) => {
        setSelectedRouteLocation(location);
        const updatedLocations = [...routeState.details.locations];
        updatedLocations[selectedRouteLocationIndex] = location;
        updateRouteState({ details: { locations: updatedLocations } as RouteDetails }, 'onSetSelectedRouteLocation');
    }

    const onSelectLeg = (leg: RouteLeg) => {
        // highlight the leg on the map
        if (selectedLegIndex === leg.index) {
            setSelectedLegIndex(null);
        } else {
            setSelectedLegIndex(leg.index);
        }
    }

    const onClearSelectedLeg = () => {
        setSelectedLegIndex(null);
    }


    const isJobSelected = (job: Job) => {
        return routeState.details.locations.some(location => location.job_id === job._id);
    }

    const getJobOrderBadge = (job: Job) => {
        const index = routeState.details.locations.findIndex(location => location.job_id === job._id);
        return index !== -1 ? index + 1 : null;
    }

    const onSelectJob = (job: Job) => {
        if (isJobSelected(job)) {
            // Remove the job from locations if it's already selected
            const updatedLocations = routeState.details.locations.filter(location => location.job_id !== job._id);
            updateRouteState({ details: { locations: updatedLocations } as RouteDetails }, 'onSelectJob');
        } else {
            // Add the job as a new location
            const newLocation = new RouteLocation({
                index: routeStateRef.current.details.locations.length,
                job_id: job._id,
                job: job,
                name: job.details.customer_details.name || `Job ${job._id}`,
                address: job.details.address,
                location: job.details.location,
                inventory_items: job.details.report.items,
            });
            const updatedLocations = [...routeStateRef.current.details.locations, newLocation];
            updateRouteState({ details: { locations: updatedLocations } as RouteDetails }, 'onSelectJob');
        }
    }


    const memoizedLocations = useMemo(() => routeState.details.locations, [routeState]);

    const onSelectLocation = (index: number) => {
        setSelectedRouteLocationIndex(index);
        setSelectedRouteLocation(routeStateRef.current.details.locations[index]);
        setShowRouteLocationDetailsPanel(true);
    };

    const onReorderLocations = (fromIndex: number, toIndex: number) => {
        if (toIndex < 0 || toIndex >= routeStateRef.current.details.locations.length) return;

        const updatedLocations = [...routeStateRef.current.details.locations];
        const [reorderedItem] = updatedLocations.splice(fromIndex, 1);
        updatedLocations.splice(toIndex, 0, reorderedItem);

        // Update indices
        updatedLocations.forEach((location, index) => {
            location.index = index;
        });

        // Update the route state with the new order
        updateRouteState({ details: { locations: updatedLocations } as RouteDetails }, 'onReorderLocations');

        // Set the route action to get the current route (not optimized)
        setRouteAction(ROUTE_ACTION_TYPES.GET_CURRENT_ROUTE);

        // Generate the route with the new order, but don't optimize
        setTimeout(() => {
            generateRoute(ROUTE_ACTION_TYPES.GET_CURRENT_ROUTE);
        }, 200);
    };




    const onSetRouteLocationType = (index: number, routeLocationType: ROUTE_LOCATION_TYPE) => {
        const updatedLocations = [...routeState.details.locations];
        updatedLocations[index].route_location_type = routeLocationType;
        updateRouteState({ details: { locations: updatedLocations } as RouteDetails }, 'onSetRouteLocationType');
    }

    const RenderMemoizedLocations = useMemo(() => {
        return (
            <RouteList
                locations={memoizedLocations}
                selectedRouteLocationIndex={selectedRouteLocationIndex}
                onSelectLocation={onSelectLocation}
                onRemoveLocation={onConfirmRemoveLocation}
                onReorderLocations={onReorderLocations}
                onSetRouteLocationType={onSetRouteLocationType}
            />
        )
    }, [routeState, selectedRouteLocationIndex, onSetRouteLocationType])

    const openJobReportModal = (jobId: string) => {
        setShowJobReportModal(true);
    }


    const onUpdateLegs = (legs: RouteLeg[], overviewPolyline: any = null, allStepsPolyline: any = null) => {
        try {
            let currentLocations = [...routeStateRef.current.details.locations];
            let updatedLocations: RouteLocation[] = [];

            // Helper function to find the closest location to a given point
            const findClosestLocation = (point: JobLocation) => {
                return currentLocations.reduce((closest, location) => {
                    if (!location.location) return closest;
                    const currentDist = Math.pow(location.location.lat - point.lat, 2) + Math.pow(location.location.lng - point.lng, 2);
                    const closestDist = closest ? Math.pow(closest.location.lat - point.lat, 2) + Math.pow(closest.location.lng - point.lng, 2) : Infinity;
                    return currentDist < closestDist ? location : closest;
                }, null as RouteLocation | null);
            };

            const validLegs = legs.filter(leg => leg.origin && leg.origin.lat && leg.origin.lng);


            // Reorder locations based on legs
            validLegs.forEach((leg, index) => {
                const originLocation = findClosestLocation(leg.origin as JobLocation);
                if (originLocation && !updatedLocations.includes(originLocation)) {
                    updatedLocations.push(originLocation);
                }

                // For the last leg, also add the destination
                if (index === validLegs.length - 1 && leg.destination) {
                    const destinationLocation = findClosestLocation(leg.destination as JobLocation);
                    if (destinationLocation && !updatedLocations.includes(destinationLocation)) {
                        updatedLocations.push(destinationLocation);
                    }
                }
            });

            // Add any remaining locations that weren't matched to the end
            currentLocations.forEach(location => {
                if (!updatedLocations.includes(location)) {
                    updatedLocations.push(location);
                }
            });

            // Update indices
            updatedLocations.forEach((location, index) => {
                location.index = index;
            });


            updateRouteState({
                details: {
                    locations: updatedLocations,
                    legs: legs,
                    overview_polyline: overviewPolyline ?? routeState.details.overview_polyline,
                    all_steps_polyline: allStepsPolyline ?? routeState.details.all_steps_polyline,
                    distance_kms: routeState.details.distance_kms,
                    duration_seconds: routeState.details.duration_seconds,
                    cost_per_km: routeState.details.cost_per_km,
                    covered_distance_kms: routeState.details.covered_distance_kms,
                    total_cost: routeState.details.total_cost
                } as RouteDetails
            }, 'onUpdateLegs');
        } catch (e) {
            console.error("Error in onUpdateLegs:", e);
        }
    }

    const [routePolyline, setRoutePolyline] = useState(null as any);
    const [hoveredMarkerIndex, setHoveredMarkerIndex] = useState<number | null>(null);

    const onMapClick = async (event: any) => {
        try {
            const location = event.latLng.toJSON();
            const addressObj = await GoogleController.getAddressFromLatLng(location.lat, location.lng);
            const address = new JobAddress(addressObj?.address);

        } catch (e) {
            console.error("Error in onMapClick:", e);
        }
    };

    const onDragMarker = async (location: any, index: number) => {
        try {
            const locations = await GoogleController.getAddressFromLatLngAllResults(location.lat, location.lng);
            if (locations && locations.length > 0) {
                const address = new JobAddress(locations[0].formatted_address);
                const updatedLocations = [...routeState.details.locations];
                updatedLocations[index].location = location;
                updatedLocations[index].address = address;
                updateRouteState({ details: { locations: updatedLocations } as RouteDetails }, 'onDragMarker');

                generateRoute()
            }
        } catch (e) {
            console.error("Error in onDragMarker:", e);
        }
    };



    // Gets route directions from Google Maps API - Keeps the route in order
    const getRouteDirections = async () => {
        const result = await OsrmController.getRouteDirections(routeStateRef.current);
        if (result.legs.length > 0) {
            setRoutePolyline(result.routePolyline);
            updateRouteState({
                details: {
                    ...routeStateRef.current.details,
                    legs: result.legs,
                    overview_polyline: result.routePolyline
                } as RouteDetails
            }, 'getRouteDirections');
        }
    };



    const getOptimizeRoute = async () => {
        const result = await OsrmController.getOptimizeRoute(routeStateRef.current);
        if (result.legs.length > 0) {
            setRoutePolyline(result.overviewPolyline);
            onUpdateLegs(result.legs, result.overviewPolyline, result.allStepsPolyline);
        }
    };




    const onJobCreated = (job: Job) => {
        setShowCreateJobForRouteModal(false);
        // add the job to the available jobs
        setAvailableJobs([job, ...availableJobs]);
        // select the job
        onSelectJob(job);
    }

    const updateRouteState = (updates: Partial<Route>, caller: string = 'unknown') => {
        const newState = {
            ...routeState,
            details: new RouteDetails({
                ...routeState.details,
                ...updates.details
            })
        };
        onSetRouteState(newState as Route, caller);
    };

    const onCreateRoute = async () => {
        try {
            // get all job ids from the route locations
        
            console.log("🚀============== ~ file: createRouteContainer.component.tsx:673 ~ onCreateRoute ~ createdRoute🚀==============", routeStateRef.current)
            const createdRoute = await RouteController.createRoute(routeStateRef.current);
            if (!createdRoute) {
                throw new Error('Route not created')
                return
            }
            // assign all jobs to the route
            const jobIds = createdRoute.job_ids
            await JobController.setRouteIdForJobs(jobIds, createdRoute._id);
            if (createdRoute) {
                onSetRouteState(createdRoute);
                setIsRouteCreated(true);
                Toast.show({
                    type: 'success',
                    text1: 'Success',
                    text2: 'Route created successfully',
                });
            }
        } catch (error) {
            console.error("Error creating route:", error);
            Toast.show({
                type: 'error',
                text1: 'Error',
                text2: 'Failed to create route',
            });
        }
    };

    const onAssignDriverToRoute = async (driverId: string) => {
        const company = StorageController.getAppState().selectedCompany;
        const memberId = driverId;
        const jobIds = routeState.details.locations.map((loc: any) => loc.job_id).filter((id: string) => id);
        if (!company) {
            console.error("Company not found");
            return;
        }
        const companyId = company._id;          
        const routeResult = await RouteController.assignDriverToRoute(routeState._id, memberId);
        const jobResult = await JobController.assignMultipleJobsToMember(jobIds, memberId, companyId);
        return { route: routeResult, jobs: jobResult };
    }


    const onSelectStartEndOption = (option: ROUTE_START_END_OPTION) => {
        const updatedDetails = { ...routeState.details, startEndOption: option };

        if (option === ROUTE_START_END_OPTION.DEFAULT) {
            updatedDetails.startLocationIndex = null;
            updatedDetails.endLocationIndex = null;
        } else if (option === ROUTE_START_END_OPTION.ROUND_TRIP) {
            updatedDetails.startLocationIndex = 0;
            updatedDetails.endLocationIndex = 0;
        } else if (option === ROUTE_START_END_OPTION.START_FIRST_END_LAST) {
            updatedDetails.startLocationIndex = 0;
            updatedDetails.endLocationIndex = routeState.details.locations.length - 1;
        }

        updateRouteState({ details: updatedDetails as RouteDetails }, 'onSelectStartEndOption');
    };

    const onSelectStartLocation = (index: number) => {
        updateRouteState({ details: { ...routeState.details, startLocationIndex: index } as RouteDetails }, 'onSelectStartLocation');
    };

    const onSelectEndLocation = (index: number) => {
        updateRouteState({ details: { ...routeState.details, endLocationIndex: index } as RouteDetails }, 'onSelectEndLocation');
    };

    const handleUpdatePlannedStartTime = (time: Date) => {
        updateRouteState({ planned_start_time: time.getTime() }, 'handleUpdatePlannedStartTime');
    };

    const MemoizedRouteMap = useMemo(() => {
        return (
            <RoutePlanningMap
                route={routeState}
                selectedMarkerIndex={selectedMarkerIndex}
                setSelectedMarkerIndex={setSelectedMarkerIndex}
                highlightedLegIndex={selectedLegIndex}
                setHighlightedLegIndex={setSelectedLegIndex}
                hoveredMarkerIndex={hoveredMarkerIndex}
                setHoveredMarkerIndex={setHoveredMarkerIndex}
                onMapClick={onMapClick}
                onDragMarker={onDragMarker}
                onClickMarker={onSelectLocationFromMap}
            />
        )
    }, [routeState, selectedLegIndex, hoveredMarkerIndex, routePolyline, selectedMarkerIndex])

    const MemoizedRouteSummary = useMemo(() => {
        return (
            <RouteSummary
                route={routeState}
                routeReadyForCreation={routeCanBeCreated}
                onCreateRoute={onCreateRoute}
                onAssignDriver={onAssignDriverToRoute}
                isRouteCreated={isRouteCreated}
                onUpdatePlannedStartTime={(date: Date) => {
                    handleUpdatePlannedStartTime(date)
                }}
                onSelectRoute={()=>{}}
                onSelectJobId={()=>{}}
            />
        )
    }, [routeState, isRouteCreated, routeCanBeCreated]);

    return (
        <Layout style={{ flex: 1 }}>
            <CreateJobForRouteModal
                showCreateJobForRouteModal={showCreateJobForRouteModal}
                setShowCreateJobForRouteModal={setShowCreateJobForRouteModal}
                onJobCreated={onJobCreated}
            />
            <JobReportModal
                showReportDetailsModal={showJobReportModal}
                setShowReportDetailsModal={setShowJobReportModal}
                job={selectedRouteLocation?.job}
                showImages={true}
                showLogs={true}
            />
            <ConfirmRemoveRouteLocationModal
                visible={showConfirmRemoveRouteLocationModal}
                onClose={() => setShowConfirmRemoveRouteLocationModal(false)}
                onRemove={() => onRemoveLocation(selectedRouteLocationIndex)}
            />
            <View style={{ position: 'absolute', top: 0, right: 0, zIndex: 1001 }}>
                {!showRouteLocationDetailsPanel && (
                    <Button onPress={() => onClose()} status="danger" style={{}}>
                        <Icon name="close-outline" fill="white" width={15} height={15} />
                    </Button>
                )}
            </View>
            <Layout style={{ flex: 1, flexDirection: 'row', borderWidth: 1, borderColor: theme['color-basic-500'], borderRadius: 8, margin: 1 }}>
                <View style={{ flex: 2, borderWidth: 1, borderColor: theme['color-basic-500'] }}>
                    <Button onPress={() => setShowCreateJobForRouteModal(true)}>
                        <Text>Create Job</Text>
                    </Button>
                    <ScrollView contentContainerStyle={{ borderWidth: 1, borderColor: theme['color-basic-500'], borderRadius: 8, margin: 1 }}>
                        <AvailableJobs
                            availableJobs={availableJobs}
                            onSelectJob={onSelectJob}
                            isJobSelected={isJobSelected}
                            getJobOrderBadge={getJobOrderBadge}
                        />
                    </ScrollView>
                </View>
                <View style={{ flex: 2, borderWidth: 1, borderColor: theme['color-basic-500'], borderRadius: 8, margin: 1 }}>
                    <Text category='h6'>Route Locations</Text>
                    <RouteStartEndSelector
                        startEndOption={routeState.details.startEndOption}
                        onSelectOption={onSelectStartEndOption}
                        onSelectStartLocation={onSelectStartLocation}
                        onSelectEndLocation={onSelectEndLocation}
                        locations={routeState.details.locations.map(loc => ({ index: loc.index, name: loc.name }))}
                        startLocationIndex={routeState.details.startLocationIndex}
                        endLocationIndex={routeState.details.endLocationIndex}
                    />
                    {memoizedLocations.length > 0 ? (
                        RenderMemoizedLocations
                    ) : (
                        <Text>No locations added yet.</Text>
                    )}
                </View>

                <View style={{ flex: 7, borderWidth: 1, borderColor: theme['color-basic-500'], borderRadius: 8, margin: 1 }}>
                    {showRouteLegsPanel && (
                        <Layout style={{ position: 'absolute', top: 0, left: 0, width: '20%', height: '50%', zIndex: 1000, borderWidth: 1, borderRadius: 8, borderColor: theme['color-basic-500'] }}>
                            {/* close button */}
                            <Button
                                status="danger"
                                style={{ position: 'absolute', top: 0, right: 0, zIndex: 1000 }}
                                onPress={() => {
                                    setShowRouteLegsPanel(false)
                                    onClearSelectedLeg()
                                }}>
                                <Icon name="close-outline" fill="white" width={15} height={15} />
                            </Button>
                            <ScrollView contentContainerStyle={{ borderWidth: 1, borderColor: theme['color-basic-500'], borderRadius: 8, margin: 1 }}>
                                <RouteLegsCard
                                    route={routeState}
                                    onLegClick={onSelectLeg}
                                    onClearSelectedLeg={onClearSelectedLeg}
                                    selectedLegIndex={selectedLegIndex}
                                />
                            </ScrollView>
                        </Layout>
                    )}
                    {selectedRouteLocation && showRouteLocationDetailsPanel && (
                        <Layout style={{ position: 'absolute', top: 0, right: 0, width: '35%', height: '100%', zIndex: 1001, borderRadius: 8, borderWidth: 1, borderColor: theme['color-basic-500'] }}>
                            <ScrollView style={{ flex: 1 }}>
                                <RouteLocationDetailsCard
                                    location={selectedRouteLocation}
                                    onChangeRouteLocation={onSetSelectedRouteLocation}
                                    comapnyInventoryItems={comapnyInventoryItems}
                                    onCloseDetailsPanel={() => setShowRouteLocationDetailsPanel(false)}
                                    openJobReportModal={openJobReportModal}
                                />
                            </ScrollView>
                        </Layout>
                    )}
                    <View style={{ flex: 1, height: '100%' }}>
                        {MemoizedRouteMap}
                    </View>
                    <View style={{ position: 'absolute', bottom: 0, left: 0, zIndex: 999, flexDirection: 'column', width: '100%', borderWidth: 1, borderColor: theme['color-basic-500'], borderRadius: 8, margin: 1 }}>
                            {MemoizedRouteSummary}
                    </View>
                    <View style={{ position: 'absolute', top: 0, left: 0, zIndex: 1000, flexDirection: 'column', borderWidth: 1, borderColor: theme['color-basic-500'], borderRadius: 8, margin: 1 }}>
                        <RouteActions
                            routeAction={routeAction}
                            setRouteAction={setRouteAction}
                            onCalculateRoute={generateRoute}
                            setShowRouteLegsPanel={setShowRouteLegsPanel}
                            />
                    </View>
                </View>
            </Layout>
        </Layout>
    )
}



const CreateJobForRouteModal = ({
    showCreateJobForRouteModal,
    setShowCreateJobForRouteModal,
    onJobCreated,
}: {
    showCreateJobForRouteModal: boolean;
    setShowCreateJobForRouteModal: (show: boolean) => void;
    onJobCreated: (job: Job) => void;
}) => {
    return (
        <Modal
            visible={showCreateJobForRouteModal}
            onBackdropPress={() => setShowCreateJobForRouteModal(false)}
            backdropStyle={{ backgroundColor: 'rgba(0,0,0,0.5)' }}
        >
            <CreateJobForRoute
                onJobCreated={onJobCreated}
            />
        </Modal>
    )
}

