import React, { useState, useEffect, useRef, useReducer, useMemo } from 'react';
//@ts-ignore
import { StyleSheet, View, ScrollView, Image, Animated, TouchableOpacity } from 'react-native';
import { Layout, Text, Button, Divider, List, Modal, Card, Input, Icon, ListItem } from '@ui-kitten/components';
import * as UpdateController from '../../../functions/update.controller';
import * as UserController from '../../../functions/user.controller';
import * as StorageController from "../../../functions/storageController"
import * as MessageController from "../../../functions/message.controller"
import * as MembershipController from '../../../functions/membership.controller';
import { MembersMessageList } from './membersMessageList.component';
import { Member } from '../../../models/Member.model';
import { Message } from '../../../models/Message.model';
import { useAppStateChange, IAction } from '../../../hooks/appStateChange.hook';

interface MembersListSmallContainerProps {
    fn_onSelectMember: (member: Member) => void;
    var_members: Member[];
    style?: any;
    var_selectedMember?: Member | null;
}


export const MembersListSmallContainer = ({
    fn_onSelectMember,
    var_members,
    style,
    var_selectedMember,
}: MembersListSmallContainerProps) => {
    const [RootNavigation, setRootNavigation] = useState();
    const [members, setMembers] = useState([]);

    // set registered
    const [registeredEvents, setRegisteredEvents] = useState(false)

    const lazyLoad = async () => {
        //@ts-ignore
        const r = await import('../../../routes')
        setRootNavigation(r as any)
    }

    useEffect(() => {
        lazyLoad();
        if (!registeredEvents) {
            setRegisteredEvents(true)
        }
        load();
        return () => {
        }
    }, []);






    const [unreadIds, setUnreadIds] = useState(StorageController.appState.unreadMessageSenderIds || [])
    const unreadIdsRef = useRef(StorageController.appState.unreadMessageSenderIds || []);
    const onSetUnreadIds = (ids: string[]) => {
        unreadIdsRef.current = ids;
        setUnreadIds(ids)
    }
    const memoizedUnreadIds = useMemo(() => unreadIdsRef.current, [unreadIdsRef.current])

    const load = async () => {
        const _members = await loadMembers();
        // setUnreadIds(StorageController.appState.unreadMessageSenderIds || [])
    }



    const loadMembers = async () => {
        try {
            const company = StorageController.getCurrentCompany();
            let _members = await MembershipController.getMembersByCompanyId(company._id)
            // remove member by id
            _members = _members.filter((member: Member) => member._id !== StorageController.getAppState().selectedMembership?._id);

            // if member is client, only show admin
            if (StorageController.getAppState().selectedMembership?.is_client) {
                _members = _members.filter((member: Member) => member.is_admin);
            }

            // console.log("members", _members);
            setMembers(_members);
            return _members
        }
        catch (e) {
            console.log("error loading members", e)
            return []
        }
    }




    const handleCompanySwitchedEvent = async (action: IAction) => {
        if (action.type === UpdateController.STATE_ACTIONS.UPDATED_SELECTED_COMPANY) {
            // console.log("COMPANY_SWITCHED", action);
            load();
        }
    };

    const handleUnreadMessagesUpdateEvent = async (action: IAction) => {
        if (action.type === UpdateController.STATE_ACTIONS.UPDATED_UNREAD_MESSAGES) {
            // console.log("UPDATED_UNREAD_MESSAGES", action);
            let unreadMessages = action.data.unreadMessages;
            if (!unreadMessages) return;
            let unreadIds = [] as string[];
            unreadMessages.forEach((message: Message) => {
                if (!unreadIds.includes(message.sender)) {
                    unreadIds.push(message.sender)
                }
            });
            onSetUnreadIds(unreadIds)
            // load();
            // onForceUpdate();
        }
    };



    const handleMessageUpdatedEvent = async (action: IAction) => {
        if (action.type === UpdateController.STATE_ACTIONS.MESSAGE_UPDATE) {
            if (action.data.data) // this is the message object
            {
                const message = action.data.data;
                if (!message._id) return; // if no id, then its not a message
                if (!message.read) {
                    if (StorageController.appState.selectedChatRecipient?._id != message.sender) {
                        const _unreadIds = StorageController.appState.unreadMessageSenderIds || []
                        if (!_unreadIds.includes(message.sender)) {
                            onSetUnreadIds([..._unreadIds, message.sender])
                        }
                    }
                }
            }
        }
    };


    const { dispatchEventStateChange } = useAppStateChange({
        onUpdatedSelectedCompany: handleCompanySwitchedEvent,
        onUpdatedUnreadMessages: handleUnreadMessagesUpdateEvent,
        onMessageUpdate: handleMessageUpdatedEvent,
    })



    const onSelectMember = async (member: Member) => {
        if (!member) return;
        if (member._id != StorageController.appState.selectedChatRecipient?._id) {
        }
        // remove unread id
        const _unreadIds = StorageController.appState.unreadMessageSenderIds || []
        StorageController.appState.selectedChatRecipient = member
        const index = _unreadIds.indexOf(member._id);
        if (index > -1) {
            _unreadIds.splice(index, 1);
        }
        onSetUnreadIds(_unreadIds)
        const membership = StorageController.getAppState().selectedMembership;
        if (!membership) return;
        MessageController.getMessagesBetweenMembers(membership._id, member._id).then(_messages => {
            StorageController.setSelectedMessages(_messages)
            UpdateController.dispatchEventStateChange({ type: UpdateController.STATE_ACTIONS.SET_SELECTED_CHAT, data: { member: member, messages: _messages } })
            StorageController.saveStateToDisk()
        })
        // setSelectedMember(member);
        fn_onSelectMember(member);

    }


    const checkViewDrivers = () => {
        // check if user has permission to view drivers
        let valid = true
        if (StorageController.getAppState().selectedMembership?.is_client) {
            valid = false
        }
        if (StorageController.getAppState().selectedMembership?.is_operator) {
            valid = true
        }
        if (StorageController.getAppState().selectedMembership?.is_admin) {
            valid = true
        }
        if (StorageController.getAppState().selectedMembership?.is_driver) {
            valid = true
        }
        return valid
    }

    const checkViewOperators = () => {
        // check if user has permission to view operators
        let valid = true
        if (StorageController.getAppState().selectedMembership?.is_client) {
            valid = true
        }
        if (StorageController.getAppState().selectedMembership?.is_operator) {
            valid = true
        }
        if (StorageController.getAppState().selectedMembership?.is_admin) {
            valid = true
        }
        if (StorageController.getAppState().selectedMembership?.is_driver) {
            valid = true
        }
        return valid
    }

    const checkViewClients = () => {
        // check if user has permission to view clients
        let valid = true
        if (StorageController.getAppState().selectedMembership?.is_admin) {
            return true
        }
        if (StorageController.getAppState().selectedMembership?.is_client) {
            return false
        }
        if (StorageController.getAppState().selectedMembership?.is_operator) {
            return true
        }
        if (StorageController.getAppState().selectedMembership?.is_driver) {
            return false
        }
        return valid
    }

    const checkViewAdmins = () => {
        // check if user has permission to view admin
        let valid = true
        if (StorageController.getAppState().selectedMembership?.is_client) {
            valid = true
        }
        if (StorageController.getAppState().selectedMembership?.is_operator) {
            valid = true
        }
        if (StorageController.getAppState().selectedMembership?.is_admin) {
            valid = true
        }
        if (StorageController.getAppState().selectedMembership?.is_driver) {
            valid = true
        }

        return valid
    }

    const [adminsExpanded, setAdminsExpanded] = useState(true);
    const [operatorsExpanded, setOperatorsExpanded] = useState(true);
    const [driversExpanded, setDriversExpanded] = useState(true);
    const [clientsExpanded, setClientsExpanded] = useState(false);
    const [othersExpanded, setOthersExpanded] = useState(false);


    const membersListRef = React.useRef({
        admins: [] as Member[],
        operators: [] as Member[],
        drivers: [] as Member[],
        clients: [] as Member[],
        others: [] as Member[],
    } as any)

    const membersList = React.useCallback(() => {
        membersListRef.current.admins = []
        membersListRef.current.operators = []
        membersListRef.current.drivers = []
        membersListRef.current.clients = []

        var_members.forEach(member => {
            if (member.is_admin) {
                membersListRef.current.admins.push(member)
            }
            else if (member.is_operator) {
                membersListRef.current.operators.push(member)
            }
            else if (member.is_driver) {
                membersListRef.current.drivers.push(member)
            }
            else if (member.is_client) {
                membersListRef.current.clients.push(member)
            }
            else {
                membersListRef.current.others.push(member)
            }
        });
        return membersListRef.current
    }, [var_members]);


    return (
        <View style={[style]}>
            {/* Render driver list */}
            {checkViewDrivers() &&
                <MembersMessageList
                    unreadIds={unreadIds as string[]}
                    members={membersList().drivers}
                    heading="Drivers"
                    expanded={driversExpanded}
                    onSetExpanded={setDriversExpanded}
                    onSelectMember={onSelectMember}
                />
            }
            {/* Render operator list */}
            {checkViewOperators() &&
                <MembersMessageList
                    unreadIds={unreadIds}
                    members={membersList().operators}
                    heading="Call Centre"
                    expanded={operatorsExpanded}
                    onSetExpanded={setOperatorsExpanded}
                    onSelectMember={onSelectMember} />
            }
            {/* Render admin list */}
            {checkViewAdmins() &&
                <MembersMessageList
                    unreadIds={unreadIds}
                    members={membersList().admins}
                    heading="Management"
                    expanded={adminsExpanded}
                    onSetExpanded={setAdminsExpanded}
                    onSelectMember={onSelectMember}
                />
            }
            {/* Clients */}
            {checkViewClients() &&
                <MembersMessageList
                    unreadIds={unreadIds}
                    members={membersList().clients}
                    heading="Clients"
                    expanded={clientsExpanded}
                    onSetExpanded={setClientsExpanded}
                    onSelectMember={onSelectMember}
                />
            }
        </View>
    )

}



const styles = StyleSheet.create({
    container: {
        flexDirection: "row",
        alignItems: "center",
        marginVertical: 8,
    },
    onlineContainer: {
        width: 70,
        left: -10,
        height: 48,
        borderRadius: 24,
        backgroundColor: "#004920",
        borderColor: "green",
        borderWidth: 7,
        justifyContent: "center",
        alignItems: "center",
    },
    offlineContainer: {
        width: 70,
        left: -10,
        height: 48,
        borderRadius: 24,
        backgroundColor: "#B20000",
        borderColor: "red",
        borderWidth: 7,
        justifyContent: "center",
        alignItems: "center",
    },
    selectedAvatarContainer: {
        backgroundColor: "#007aff",
        borderColor: "#007aff",
    },
    avatar: {
        color: "white",
        fontWeight: "bold",
        textShadowColor: "black",
        textShadowOffset: { width: -1, height: 1 }
    },
    innerRing: {
        position: "absolute",
        justifyContent: "center",
        alignItems: "center",
        // backgroundColor: "transparent",
    },
    yellowContainer: {
        backgroundColor: "rgba(255, 255, 0, 0.08)",
        borderColor: "rgb(255, 255, 0)",
        borderRadius: 4,
        borderWidth: 1,
        margin: 10,
    },
    // green Container
    greenContainer: {
        backgroundColor: "rgba(0, 224, 150, 0.08)",
        borderColor: "rgb(0, 224, 150)",
        borderRadius: 4,
        borderWidth: 1,
        margin: 10,
    },
    // red Container
    redContainer: {

        backgroundColor: "rgba(255, 0, 0, 0.08)",
        borderColor: "rgb(255, 0, 0)",
        borderRadius: 4,
        borderWidth: 1,
        margin: 10,
    },
    // blue Container
    blueContainer: {
        backgroundColor: "rgba(0, 0, 255, 0.08)",
        borderColor: "rgb(0, 0, 255)",
        borderRadius: 4,
        borderWidth: 1,
        margin: 10,
    },
    // purple Container
    purpleContainer: {
        backgroundColor: "rgba(128, 0, 128, 0.08)",
        borderColor: "rgb(128, 0, 128)",
        borderRadius: 4,
        borderWidth: 1,
        margin: 0,
    },
    // grey Container
    greyContainer: {
        backgroundColor: "rgba(128, 128, 128, 0.08)",
        borderColor: "rgb(128, 128, 128)",
        borderRadius: 4,
        borderWidth: 1,
        margin: 1,
    },
});