import React, { useState, useEffect, useRef, useReducer, useCallback, useMemo, createContext, useContext } from 'react';
//@ts-ignore
import { useWindowDimensions, StyleSheet, View, TouchableOpacity, ScrollView } from "react-native";
import { SafeAreaView } from 'react-native-safe-area-context';
import * as XLSX from 'xlsx';
import DataGrid from 'react-data-grid';
//@ts-ignore
import { Divider, Icon, Layout, Text, TopNavigation, TopNavigationAction, Card, Button, Input, ButtonGroup, CheckBox, Modal, RangeDatepicker, Datepicker, useTheme, IndexPath, Select, SelectItem, List, ListItem } from '@ui-kitten/components';
import ErrorBoundary from '../ErrorBoundary.component';
import * as JobsController from '../../functions/job.controller'
import * as MembershipsController from '../../functions/membership.controller'
import * as UpdateController from '../../functions/update.controller'
import * as ImageController from '../../functions/image.controller'
import * as ClientController from '../../functions/client.controller'
import * as StorageController from '../../functions/storageController'
import * as DocumentController from '../../functions/document.controller'
import { Document, DocumentCategory, SharePermissions, SharedWith } from '../../models/Document.model';
import { formatIsoDateTime, getDocumentTypeFromString } from '../../functions/utils';
import { Company } from '../../models/Company.model';




const RenderDocumentViewer = ({ canEditCategory, document, handleOpenDocument, handleDeleteDocument }: { canEditCategory: any, document: Document, handleOpenDocument: any, handleDeleteDocument: any }) => {

    const [uri, setUri] = useState('');
    const [contentType, setContentType] = useState('');
    const [viewerType, setViewerType] = useState('');
    const [iframeUrl, setIframeUrl] = useState(null as string | null);
    const [loading, setLoading] = useState(true);
    const [viewerHeight, setViewerHeight] = useState(700);

    useEffect(() => {
        loadUri()
    }, [document])

    const loadUri = async () => {
        setLoading(true);
        const uri = await DocumentController.getSignedUrl(document, {
            Expires: 300,
        });
        const content_type = document.content_type || '.' + document.file.split('.').pop() || '';
        const viewerType = getDocumentTypeFromString(content_type);
        setContentType(content_type);
        setViewerType(viewerType);
        setUri(uri);
        setIframeUrl(null);
        setLoading(false);
    };


    const onSetIframeUrl = (url: string | null) => {
        console.log("🚀============== ~ file: documentsContainerScreen.tsx:573 ~ onSetIframeUrl ~ url🚀==============", url)
        setIframeUrl(url);
    }


    const renderContent = () => {
        switch (viewerType) {
            case 'image':
                return <ImageViewer uri={uri} height={viewerHeight} />;
            case 'pdf':
                return <PDFViewer uri={uri} iframeUrl={iframeUrl} height={viewerHeight} />;
            case 'video':
                return <VideoViewer uri={uri} height={viewerHeight} />;
            case 'audio':
                return <AudioViewer uri={uri} height={viewerHeight} />;
            case 'iframe':
                return <IframeViewer uri={uri} height={viewerHeight} />;
            case 'excel':
                return <ExcelViewer fileUrl={uri} iframeUrl={iframeUrl} height={viewerHeight} type={viewerType} document={document} />;
            case 'csv':
                return <ExcelViewer fileUrl={uri} iframeUrl={iframeUrl} height={viewerHeight} type={viewerType} document={document} />;
            case 'word':
                return <WordViewer uri={uri} iframeUrl={iframeUrl} height={viewerHeight} />;
            default:
                return <Text>No viewer available for this file type.</Text>;
        }
    };



    const renderViewerTypeButtons = () => {
        const buttons = [];
        if (viewerType === 'pdf') {
            buttons.push(
                <Button
                    key="embed"
                    onPress={() => onSetIframeUrl(null)}
                    appearance={!iframeUrl ? 'filled' : 'outline'}
                    status='basic'
                >
                    Embed
                </Button>,
                <Button
                    key="googleDrive"
                    status='purple'
                    onPress={() => onSetIframeUrl(`https://drive.google.com/viewerng/viewer?embedded=true&url=${encodeURIComponent(uri)}`)}
                    appearance={iframeUrl && iframeUrl.includes('https://drive.google.com') ? 'filled' : 'outline'}
                >
                    Google Drive
                </Button>
            );
        } else if (viewerType === 'excel') {
            buttons.push(
                <Button
                    key="datagrid"
                    status='basic'
                    onPress={() => onSetIframeUrl(null)}
                    appearance={!iframeUrl ? 'filled' : 'outline'}
                >
                    Data Grid
                </Button>,
                <Button
                    key="googleDrive"
                    status='purple'
                    onPress={() => onSetIframeUrl(`https://drive.google.com/viewerng/viewer?embedded=true&url=${encodeURIComponent(uri)}`)}
                    appearance={iframeUrl && iframeUrl.includes('https://drive.google.com') ? 'filled' : 'outline'}
                >
                    Google Drive
                </Button>,
                <Button
                    key="office"
                    status='info'
                    onPress={() => onSetIframeUrl(`https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(uri)}`)}
                    appearance={iframeUrl && iframeUrl.includes('https://view.officeapps.live.com') ? 'filled' : 'outline'}
                >
                    Office
                </Button>
            );
        } else if (viewerType === 'csv') {
            buttons.push(
                <Button
                    key="datagrid"
                    onPress={() => onSetIframeUrl(null)}
                    appearance={!iframeUrl ? 'filled' : 'outline'}
                >
                    Data Grid
                </Button>,
                <Button
                    key="googleDrive"
                    status='purple'
                    onPress={() => onSetIframeUrl(`https://drive.google.com/viewerng/viewer?embedded=true&url=${encodeURIComponent(uri)}`)}
                    appearance={iframeUrl && iframeUrl.includes('https://drive.google.com') ? 'filled' : 'outline'}
                >
                    Google Drive
                </Button>,
            );
        } else if (viewerType === 'word') {
            buttons.push(
                <Button
                    key="googleDrive"
                    status='purple'
                    onPress={() => onSetIframeUrl(`https://drive.google.com/viewerng/viewer?embedded=true&url=${encodeURIComponent(uri)}`)}
                    appearance={iframeUrl && iframeUrl.includes('https://drive.google.com') ? 'filled' : 'outline'}
                >
                    Google Drive
                </Button>,
                <Button
                    key="office"
                    status='info'
                    onPress={() => onSetIframeUrl(`https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(uri)}`)}
                    appearance={iframeUrl && iframeUrl.includes('https://view.officeapps.live.com') ? 'filled' : 'outline'}
                >
                    Office
                </Button>
            );
        }

        return buttons;
    };


    return (
        <View style={{ flex: 1 }}>
            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                <View style={{ flexDirection: 'row', gap: 10 }}>
                    {renderViewerTypeButtons()}
                </View>
                <Button
                    onPress={() => handleOpenDocument(document)}
                    status='primary'
                    appearance='outline'
                    size='small'
                >
                    Open/Download
                </Button>
                {canEditCategory &&
                    <Button
                        onPress={() => handleDeleteDocument(document)}
                        status='danger'
                        size='small'
                        appearance='outline'
                    >
                        <Icon name='trash-2-outline' fill='white' width={20} height={20} />
                        Delete
                    </Button>
                }
            </View>
            {uri !== '' &&
                <View style={{ flex: 1 }}>
                    {loading &&
                        <View style={{ position: 'absolute', top: '50%', left: '50%' }}>
                            <Text>Loading....</Text>
                        </View>
                    }
                    {!loading &&
                        <View style={{ flex: 1, zIndex: 9999 }}>
                            {renderContent()}
                        </View>
                    }
                </View>
            }
        </View>
    );
};


const WordViewer = ({ iframeUrl, uri, height }: any) => {
    return (
        <iframe
            src={iframeUrl || `https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(uri)}`}
            width="100%"
            height={height || '600px'}
            frameBorder="0"
        />
    );
};

const IframeViewer = ({ uri, height }: any) => {
    return <iframe src={uri} style={{
        width: '100%', height: height || '600px'
    }}></iframe>;
};

const AudioViewer = ({ uri }: any) => {
    return (
        <audio controls>
            <source src={uri} type="audio/mpeg" />
            Your browser does not support the audio element.
        </audio>
    );
};

const VideoViewer = ({ uri, height }: any) => {
    return (
        <video width="100%" height={height || 600} controls>
            <source src={uri} type="video/mp4" />
            Your browser does not support the video tag.
        </video>
    );
};

const ImageViewer = ({ uri, height }: any) => {
    return <img src={uri} alt="Document" style={{ width: '100%', height: height || 'auto' }} />;
};

const ExcelViewer = ({ fileUrl, iframeUrl, height, type, document }: any) => {
    return iframeUrl ? (
        <iframe
            src={iframeUrl}
            width="100%"
            height={height || 600}
            frameBorder="0"
        />
    ) : (
        <View style={{ height: height || 600 }}>
            {/* Your existing Excel viewer logic here */}
            <DataGridViewer fileUrl={fileUrl} height={height} type={type} document={document} />
        </View>
    );
};

const PDFViewer = ({ uri, iframeUrl, height }: any) => {
    return iframeUrl ? (
        <iframe
            src={iframeUrl || `https://drive.google.com/viewerng/viewer?embedded=true&url=${encodeURIComponent(uri)}`}
            width="100%"
            height={height || '600px'}
            frameBorder="0"
        />
    ) : (
        <embed src={uri} type="application/pdf" width="100%" height={height || '600px'} />
    );
};



const DataGridViewer = ({ fileUrl, height, type, document }: any) => {
    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);

    useEffect(() => {
        if (type !== 'csv' && type !== 'excel') return;

        fetch(fileUrl)
            .then(response => response.arrayBuffer())
            .then(buffer => {
                const workbook = XLSX.read(buffer, { type: 'array' });
                const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
                const data = XLSX.utils.sheet_to_json(firstSheet, { header: 1 });

                if (!data || data.length === 0) {
                    throw new Error("No data available in the Excel sheet");
                }

                let headers = data[0] as any;
                let rows = data.slice(1) as any;

                if (!headers.every((header: any) => typeof header === 'string')) {
                    console.warn("First row does not contain valid headers. Generating default headers.");
                    headers = headers.map((_: any, index: any) => `Column ${index + 1}`);
                    rows = data;
                }

                let columns = headers.map((header: any, index: number) => ({
                    key: index.toString(),
                    name: header,
                    width: calculateColumnWidth(data.map((row: any) => row[index]), header)
                }));

                // Filter out any invalid columns
                columns = columns.filter((col: any) => col && col.key && col.name);

                setColumns(columns);
                setRows(rows.map((row: any) => row.reduce((acc: any, cell: any, index: any) => ({ ...acc, [index]: cell }), {})));
            })
            .catch(error => console.error('Error fetching Excel file:', error));
    }, [fileUrl]);

    const calculateColumnWidth = (cells: any, header: any) => {
        const maxWidth = 400; // Maximum column width
        const padding = 20; // Padding for the column
        const maxContentWidth = Math.max(
            ...cells.map((cell: any) => cell ? cell.toString().length : 0),
            header.length
        );
        return Math.min(maxWidth, maxContentWidth * 10 + padding); // Assuming average character width of 10px
    };

    try {
        return (
            <ErrorBoundary>
                <View style={{ flex: 1, height: '100%', width: '100%' }}>
                    <DataGrid
                        columns={columns}
                        rows={rows}
                        style={{ height: '100%', width: '100%' }}
                    />
                </View>
            </ErrorBoundary>
        );
    } catch (err) {
        console.error("Error rendering DataGrid:", err);
        return <Text>There was an error loading the data</Text>;
    }
};

const styles = StyleSheet.create({
    backdrop: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
});

export default RenderDocumentViewer;