import React, { useEffect } from 'react';
import {
    AccessibilityRole,
    ImageProps,
    ImageStyle,
    StyleSheet,
    TouchableOpacity,
    View,
    Text as RNText,
    Platform
    //@ts-ignore
} from "react-native";
import { SafeAreaView } from 'react-native-safe-area-context';
import { ApplicationProvider, Modal, Button, Icon, IconRegistry, Layout, Text, List, ListItem, Card, Input, Divider } from "@ui-kitten/components";
import { EvaIconsPack } from "@ui-kitten/eva-icons";
import { MembersList } from './membersList.component';
import { MemberEditScreen } from './memberEdit.component';
// import { NewMemberScreen } from './newMember.component';
import { NewMemberModal } from './newMemberModal.component';
import * as MembershipController from '../../functions/membership.controller';
import * as StorageController from '../../functions/storageController';
import * as UpdateController from '../../functions/update.controller';
import { useNotification } from '../../context/notifications.context'
import { Member } from '../../models/Member.model';
import { useAppStateChange, IAction } from '../../hooks/appStateChange.hook';



const PlusIcon = (props: any) => (
    <Icon {...props} fill='#FFF' name="plus-outline" />
);

enum MEMBER_ROLE_TABS {
    ALL = "All",
    ADMINS = "Admins",
    OPERATORS = "Operators",
    DRIVERS = "Drivers",
    CLIENTS = "Clients"
}


export const MembersScreen = ({ navigation }: any) => {
    const [members, setMembers] = React.useState([] as Member[]);
    const [filteredMembers, setFilteredMembers] = React.useState([] as Member[]);
    const [ignored, forceUpdate] = React.useReducer(x => x + 1, 0);
    const [value, setValue] = React.useState('');
    const [selectedMemberToEdit, setSelectedMemberToEdit] = React.useState(null as Member | null)
    const [showNewMemberModal, setShowNewMemberModal] = React.useState(false)
    const [eventsRegistered, setEventsRegistered] = React.useState(false)
    const [selectedTab, setSelectedTab] = React.useState(MEMBER_ROLE_TABS.ALL)

    const { showNotification } = useNotification(); // Get the showNotification function

    //subscribe to navigate
    React.useEffect(() => {
        const unsubscribe = navigation.addListener('focus', () => {
            //update member list
            if (!eventsRegistered) {

                setEventsRegistered(true)
            }
            loadMembers();
        });
        // Return the function to unsubscribe from the event so it gets removed on unmount
        return () => {

            unsubscribe();
        }
    }, [navigation]);

    const checkCanEditFields = () => {
        let canEdit = false;
        if (StorageController.getAppState().selectedMembership?.is_admin) {
            canEdit = true;
        }
        return canEdit;
    }


    //reload members list
    const loadMembers = () => {
        const company = StorageController.getCurrentCompany();
        MembershipController.getMembersByCompanyId(company._id).then((_members: Member[]) => {
            // if member is_client, only show members that are not clients
            if (StorageController.getAppState().selectedMembership?.is_client) {
                _members = _members.filter(member => !member.is_client);

                // also only show members who are operators or admins
                _members = _members.filter(member => member.is_operator || member.is_admin);
            }
            if (!StorageController.getAppState().selectedMembership?.is_admin) {
                _members = _members.filter(member => !member.deleted);
            }
            if (!StorageController.getAppState().selectedMembership?.is_admin) {
                _members = _members.filter(member => !member.locked);
            }

            // if member is_client remove
            setMembers(_members)
            // console.log("🚀============== ~ file: members.component.js:88 ~ MembershipController.getMembersByCompanyId ~ _members🚀==============", _members)
            forceUpdate();
        })
    }




    const handleCompanySwitchedEvent = async (action: UpdateController.IAction) => {
        setMembers([]);
        setSelectedMemberToEdit(null)
        setTimeout(() => {
            loadMembers();
        }, 100)
    };

    useAppStateChange({
        onUpdatedSelectedCompany: handleCompanySwitchedEvent
    })


    // on member edit function
    const onMemberEdit = (member: Member) => {
        setSelectedMemberToEdit(member)
        MembershipController.updateMembership(member).then(res => {
            loadMembers();
        })
        showNotification('Notice', 'Member Updated', () => { });
    }


    const onSelectMember = (member: Member) => {
        // forceUpdate();
        setSelectedMemberToEdit(member)
    }

    const onAddMember = () => {
        setShowNewMemberModal(false)
        loadMembers();
    }

    const onDeleteMember = () => {
        setSelectedMemberToEdit(null)
        loadMembers();
    }

    const onLockedMemeber = () => {
        setSelectedMemberToEdit(null)
        loadMembers();
    }

    const onUnlockedMemeber = () => {
        setSelectedMemberToEdit(null)
        loadMembers();
    }


    const onCloseNewMemberModal = () => {
        setShowNewMemberModal(false)
    }

    const NewMemberModalComponent = () => {
        return (
            <Modal
                visible={showNewMemberModal}
                backdropStyle={styles.backdrop}
                style={styles.newMemberModal}
                onBackdropPress={() => setShowNewMemberModal(false)}>
                <NewMemberModal
                    fn_closeModal={onCloseNewMemberModal}
                    fn_onAddMember={onAddMember}
                />
            </Modal>
        )
    }

    useEffect(() => {
        if (selectedTab === MEMBER_ROLE_TABS.ALL) {
            setFilteredMembers(members)
        }
        else if (selectedTab === MEMBER_ROLE_TABS.CLIENTS) {
            setFilteredMembers(members.filter(member => member.is_client))
        }
        else if (selectedTab === MEMBER_ROLE_TABS.ADMINS) {
            setFilteredMembers(members.filter(member => member.is_admin))
        }
        else if (selectedTab === MEMBER_ROLE_TABS.OPERATORS) {
            setFilteredMembers(members.filter(member => member.is_operator))
        }
        else if (selectedTab === MEMBER_ROLE_TABS.DRIVERS) {
            setFilteredMembers(members.filter(member => member.is_driver))
        }
    }, [selectedTab, members])





    const RenderTab = ({ tab, selectedTab, setSelectedTab }: any) => {
        // count members in each tab
        const count = members.filter(member => {
            if (tab === MEMBER_ROLE_TABS.ALL) {
                return true
            }
            else if (tab === MEMBER_ROLE_TABS.CLIENTS) {
                return member.is_client
            }
            else if (tab === MEMBER_ROLE_TABS.ADMINS) {
                return member.is_admin
            }
            else if (tab === MEMBER_ROLE_TABS.OPERATORS) {
                return member.is_operator
            }
            else if (tab === MEMBER_ROLE_TABS.DRIVERS) {
                return member.is_driver
            }
        }).length
        return (
            <View style={{ flex: 1 }}>
                <View style={{
                    position: 'absolute',
                    top: 0,
                    left: 10,
                    zIndex: 100,
                    backgroundColor: 'green',
                    borderRadius: 10,
                    width: 20,
                    height: 20,
                }}>
                    <Text style={{ alignSelf: 'center' }}>{count}</Text>
                </View>
                <Button
                    onPress={() => setSelectedTab(tab)}
                    status={selectedTab === tab ? 'primary' : 'basic'}
                    appearance={selectedTab === tab ? 'filled' : 'outline'}
                >
                    <Text>{tab}</Text>
                </Button>
            </View>
        )
    }

    const RenderTabs = ({ selectedTab, setSelectedTab }: any) => {
        return (
            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                {Object.values(MEMBER_ROLE_TABS).map(tab => (
                    <RenderTab key={tab} tab={tab} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
                ))}
            </View>
        )
    }



    return (
        <Layout style={{ flex: 1, flexDirection: 'column' }}>
            <NewMemberModalComponent />
            <Layout style={{ flex: 10, flexDirection: 'row' }}>
                <View style={{ flex: 1, flexDirection: 'column' }}>
                    <Layout style={{ justifyContent: "center", alignItems: "start", margin: 5 }}>
                        <View style={{ flexDirection: 'row' }}>
                            <Button
                                appearance='outline'
                                status='success'
                                disabled={!checkCanEditFields()}
                                onPress={() => { setShowNewMemberModal(true) }}
                                style={{
                                    marginLeft: 5
                                }}
                                accessoryLeft={PlusIcon}
                            >
                                <Text style={{}}>Create Member</Text>
                            </Button>
                        </View>
                        <Divider />
                    </Layout>
                    <Layout style={{ flex: 1 }}>
                        <RenderTabs selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
                        <MembersList
                            var_members={filteredMembers}
                            fn_onSelectMember={onSelectMember}
                            var_selectedMemberToEdit={selectedMemberToEdit as Member}
                        />
                    </Layout>
                </View>
                <Layout style={{ flex: 3, height: '100%' }}>
                    {selectedMemberToEdit &&
                        <MemberEditScreen
                            var_selectedMemberToEdit={selectedMemberToEdit as Member}
                            fn_onMemberEdit={onMemberEdit}
                            fn_onDeleteMember={onDeleteMember}
                            fn_onLockedMemeber={onLockedMemeber}
                            fn_onUnlockedMemeber={onUnlockedMemeber}
                        />
                    }
                </Layout>
            </Layout>
        </Layout>
    );

};
const styles = StyleSheet.create(
    {
        container: {
            maxHeight: "100%",
        },
        contentContainer: {
            paddingHorizontal: 0,
            paddingVertical: 0,
        },
        listcontainer: {
            flexDirection: "column",
            height: "100%",
            width: "100%",
            padding: 0,
            margin: 0
        },
        item: {
            marginVertical: 4,
        },
        side_section: {
            flex: 1,
            justifyContent: 'center',
            display: 'flex'
        },
        backdrop: {
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
        },
        newMemberModal: {
            // width: '80%',
            // height: '80%',
            // borderRadius: 10,
            // padding: 10
        }
    }
);