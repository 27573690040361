import React, { useState, useEffect, useRef, useReducer, useCallback, useMemo, createContext, useContext } from 'react';
//@ts-ignore
import { useWindowDimensions, StyleSheet, View, TouchableOpacity } from "react-native";
import { SafeAreaView } from 'react-native-safe-area-context';
//@ts-ignore
import { Divider, Icon, Layout, Text, TopNavigation, TopNavigationAction, Card, Button, Input, ButtonGroup, CheckBox, Modal, RangeDatepicker, Datepicker, useTheme, IndexPath, Select, SelectItem, Spinner, Toggle } from '@ui-kitten/components';
import 'react-data-grid/lib/styles.css';
import moment from 'moment';
//@ts-ignore
import DataGrid, { Row, RowRendererProps, SelectColumn, textEditor, useFocusRef, Too, Column, DataGridProps } from 'react-data-grid';
import * as StorageController from '../../functions/storageController'
import * as JobController from '../../functions/job.controller'
import * as InvoiceController from '../../functions/invoice.controller'
import * as ClientController from '../../functions/client.controller'
import * as UpdateController from '../../functions/update.controller'
import * as ServiceController from '../../functions/service.controller'
import * as GoogleController from '../../functions/google.controller'
import ErrorBoundary from '../ErrorBoundary.component';
import { ScrollView } from 'react-native-gesture-handler';
//@ts-ignore
import { CSVLink } from "react-csv";
import { ReportDetailsCard } from '../job/details/reportDetailsCard.component';
import { LogDetailsCard } from '../job/details/logDetailsCard.component';
import { Job, JobAddress, JobCustomerDetails, JobDetails, JobLocation, JobReport, JobTowingDetails, JobVehicleDetails, LineItem, Service } from '../../models/Job.model'
import { Case } from '../../models/Case.model';
import { Holding } from '../../models/Holding.model';
import { Invoice, InvoiceItem } from '../../models/Invoice.model';
import * as Utils from './invoiceUtils';
import * as Formatters from './invoiceFormatters';
import Tooltip from '../modals/Tooltip';
import { Member } from '../../models/Member.model';
import { Client } from '../../models/Client.model';
import { Company } from '../../models/Company.model';
import { FullJobReport } from '../dashboardComponents/jobReportModal.component';
import { JobInvoiceRow, HoldingInvoiceRow } from './invoicingTypes';
import { ExportInvoiceModal } from './ExportModal';
import { Filters } from './Filters';
import { JobsColumnsRenderer, createTowingColumns, createVehicleColumns, itemSubrowColumns, HoldingColumnsRenderer } from './InvoiceColumnsConfigJobs';
import { HoldingDetailsContainer } from '../job/holding/holdingDetailsContainer.component';
import { useAppStateChange, IAction } from '../../hooks/appStateChange.hook';

// Context is needed to read filter values otherwise columns are
// re-created when filters are changed and filter loses focus
const FilterContext = createContext(undefined);


export const InvoiceScreen = ({ navigation }: any) => {
    const [fetchedJobs, setFetchedJobs] = useState([] as Job[]);
    const [fetchedHoldings, setFetchedHoldings] = useState([] as Holding[]);
    const [x, forceUpdate] = useReducer(x => x + 1, 0);
    const pivot = useRef(null);

    const [windowHeight, setWindowHeight] = React.useState(useWindowDimensions().height * 0.9)
    const [windowWidth, setWindowWidth] = React.useState(useWindowDimensions().width * 0.9);

    const [holdingsOrJobs, setHoldingsOrJobs] = useState("jobs");

    const [selectedRows, setSelectedRows] = useState(() => new Set());
    const [dataRows, setRows] = useState([] as JobInvoiceRow[] | HoldingInvoiceRow[]);
    const memoizedDataRows = useMemo(() => {
        return (dataRows)
    }, [dataRows]);
    const [holdingsRows, setHoldingsRows] = useState([] as HoldingInvoiceRow[]);
    const memoizedHoldingsRows = useMemo(() => {
        return (holdingsRows)
    }, [holdingsRows]);
    // const [fetchedJobs, setFetchedJobs] = useState([]);

    //@ts-ignore
    const theme = useTheme();

    const [sortColumn, setSortColumn] = useState('id');
    const [sortDirection, setSortDirection] = useState('ASC');

    const clientsRef = useRef([] as Client[]);
    // memebers list
    const driversRef = useRef([] as Member[]);

    // services
    const servicesRef = useRef([] as Service[]);


    const [csvExportModalVisible, setExportModalVisible] = useState(false);
    const invoiceDueDateRef = useRef(new Date());
    const invoiceTaxTypeRef = useRef("GST on Income");
    const [dueDate, setDueDate] = useState(new Date());
    const [taxType, setTaxType] = useState("GST on Income");
    const memoizedInvoiceDueDate = useMemo(() => dueDate, [dueDate]);
    const memoizedInvoiceTaxType = useMemo(() => taxType, [taxType]);
    const [exportServices, setExportServices] = useState(false);
    const [exportLineItems, setExportLineItems] = useState(true);
    const [exportBillBackItems, setExportBillBackItems] = useState(true);
    const [exportCustomerCostItems, setExportCustomerCostItems] = useState(true);
    const exportServicesRef = useRef(false);
    const exportLineItemsRef = useRef(true);
    const exportBillBackItemsRef = useRef(true);
    const exportCustomerCostItemsRef = useRef(true);



    const [showJobDetailsPanel, setShowJobDetailsPanel] = useState(false);
    const [selectedJob, setSelectedJob] = useState(null as Job | null);
    const [jobLogs, setJobLogs] = useState([]);
    const [columns, setColumns] = useState([] as Column<JobInvoiceRow>[]); // What will actually be rendered in the DataGrid
    const memoizedColumns = useMemo(() => columns, [columns]);

    const [selectedCompany, setSelectedCompany] = useState<Company | null>(null)


    const [showReleaseHoldingOptions, setShowReleaseHoldingOptions] = useState(false);
    const [holdingDetailsOpen, setHoldingDetailsOpen] = useState(false);
    const [selectedHolding, setSelectedHolding] = useState(null as Holding | null);


    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [dateRange, setDateRange] = React.useState({ startDate: new Date(), endDate: new Date() });
    const startDateRef = useRef(startDate);
    const endDateRef = useRef(endDate);
    const dateRangeRef = useRef(dateRange);

    const onSetStartDate = (date: Date) => {
        setStartDate(date);
        startDateRef.current = date;
    }

    const onSetEndDate = (date: Date) => {
        setEndDate(date);
        endDateRef.current = date;
    }

    const onSetDateRange = (range: any) => {
        setDateRange(range);
        dateRangeRef.current = range;
    }



    const onCloseHoldingDetails = () => {
        setHoldingDetailsOpen(false);
        setSelectedHolding(null);
    }

    const getDueDate = () => {
        return memoizedInvoiceDueDate;
    }

    const getTaxType = () => {
        return memoizedInvoiceTaxType;
    }


    useEffect(() => {
        const unsubscribe = navigation.addListener('focus', () => {
            setSelectedCompany(new Company(StorageController.getCurrentCompany()))
            load();
        });
        // Return the function to unsubscribe from the event so it gets removed on unmount
        return unsubscribe;
    }, [navigation]);


    const handleCompanyChangeEvent = useCallback((action: UpdateController.IAction) => {
        if (action.type === UpdateController.STATE_ACTIONS.UPDATED_SELECTED_COMPANY) {
            setSelectedCompany(new Company(action.data))
            setFetchedJobs([]);
            setSelectedRows(new Set());
            setSelectedJob(null);
            // setJobLogs([]);
            setShowJobDetailsPanel(false);
            setColumns([]);
            setRows([]);
            clientsRef.current = [];
            driversRef.current = [];
            servicesRef.current = [];

            setTimeout(async () => {
                load()
            }, 100);
        }
    }, [selectedCompany]);

    const { dispatchEventStateChange } = useAppStateChange({
        onUpdatedSelectedCompany: handleCompanyChangeEvent
    })

    useEffect(() => {
        searchJobsByDateRange(dateRange.startDate, dateRange.endDate);
        // console.log("🚀============== ~ file: invoices.component.tsx:192 ~ useEffect ~ startDateRef.current🚀==============", dateRange)
    }, [holdingsOrJobs]);



    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //////// SEARCH BY DATE RANGE
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */
    const searchJobsByDateRange = useCallback(async (start = startDate, end = endDate) => {
        setShowLoading(true);
        start.setHours(0, 0, 0, 0);
        if (!end) { // for a single date
            end = new Date(start);
        }
        end.setHours(23, 59, 59, 999);
        let dat = [] as Holding[] | Job[] | any
        if (holdingsOrJobs === "holdings") {
            dat = await InvoiceController.getHoldingsByDateRange(start, end) as Holding[];
        } else {
            dat = await InvoiceController.getJobsByDateRange(start, end) as Job[];
        }
        if (!dat) {
            dat = []
        }
        // console.log("🚀============== ~ file: invoices.component.tsx:191 ~ searchJobsByDateRange ~ dat🚀==============", dat, start, end)
        clearFilters();
        // console.log(_jobs)
        let updatedJobs = []
        if (holdingsOrJobs === "jobs") {
            updatedJobs = addAllFieldsToJobRows(dat as Job[]);
            setFetchedJobs(dat as Job[]);
        } else {
            dat = dat?.filter((row: Holding) => row.status == "released")
            updatedJobs = addAllFieldsToHoldingRows(dat as Holding[]);
            setFetchedHoldings(dat as Holding[]);
        }
        setRows(updatedJobs);
        console.log("🚀============== ~ file: invoices.component.tsx:235 ~ searchJobsByDateRange ~ updatedJobs🚀==============", updatedJobs)
        itemsExpanded ? toggleItems(true) : null;
        forceUpdate();
        setShowLoading(false);
        return dat;
    }, [startDate, endDate, holdingsOrJobs, dateRange]);




    const load = async () => {
        await loadClients();
        initDateRange();
        handlePreviousWeek();
        loadDrivers();
        loadServices();
        setShowJobDetailsPanel(false);
        setSelectedJob(null);
        // setJobLogs([]);
        await toggleItems(true)

    }

    const initDateRange = async () => {
        const week = new Date();
        week.setDate(week.getDate() - 7);
        const _startDate = new Date(week);
        const _endDate = new Date(week);
        _startDate.setDate(_startDate.getDate() - week.getDay() + 1);
        _endDate.setDate(_endDate.getDate() - week.getDay() + 7);
        onSetStartDate(_startDate);
        onSetEndDate(_endDate);
    }



    // get services
    const loadServices = async () => {
        const s = StorageController.getAppState().services || StorageController.getAppState().selectedCompany?.services || [];
        servicesRef.current = s;
    }

    // get members
    const loadDrivers = async () => {
        const result = StorageController.getCurrentCompany().members.filter((member: Member) => member.is_driver === true);
        driversRef.current = result;
    }

    // get clients
    const loadClients = async () => {
        const result = await ClientController.getClientsByCompanyId(StorageController.getCurrentCompany()._id)
        if (StorageController.getAppState().selectedMembership?.is_client) {
            const client = result.find((c: Client) => c._id === StorageController.getAppState().selectedMembership?.client_id);
            clientsRef.current = [client];
        } else {
            clientsRef.current = result;
        }
    }

    function handleFill({ columnKey, sourceRow, targetRow }: {
        columnKey: string;
        sourceRow: any;
        targetRow: any;
    }) {
        return { ...targetRow, [columnKey]: sourceRow[columnKey] };
    }

    function handlePaste({
        sourceColumnKey,
        sourceRow,
        targetColumnKey,
        targetRow
    }: {
        sourceColumnKey: string;
        sourceRow: any;
        targetColumnKey: string;
        targetRow: any;
    }) {
        const incompatibleColumns = ['email', 'zipCode', 'date'];
        if (
            sourceColumnKey === 'avatar' ||
            ['id', 'avatar'].includes(targetColumnKey) ||
            ((incompatibleColumns.includes(targetColumnKey) ||
                incompatibleColumns.includes(sourceColumnKey)) &&
                sourceColumnKey !== targetColumnKey)
        ) {
            return targetRow;
        }

        return { ...targetRow, [targetColumnKey]: sourceRow[sourceColumnKey] };
    }

    function handleCopy({ sourceRow, sourceColumnKey }: {
        sourceRow: any;
        sourceColumnKey: string;
    }) {
        if (window.isSecureContext) {
            navigator.clipboard.writeText(sourceRow[sourceColumnKey]);
        }
    }
    function rowKeyGetter(row: JobInvoiceRow) {
        try {
            return row.id;
        }
        catch (err) {
            console.log(err);
        }
    }

    const getClientName = (clientId: string) => {
        const client = clientsRef.current.find(client => client._id === clientId);
        if (client) {
            return client.name;
        }
        return clientId;
    }

    // get client account_code
    const getClientAccountCode = (clientId: string) => {
        const client = clientsRef.current.find(client => client._id === clientId);
        if (client) {
            return client.account_code;
        }
        return clientId;
    }

    const getMemberName = (memberId: string) => {

        const member = driversRef.current.find(member => member._id === memberId);
        if (member) {
            return member.name;
        }
        return memberId;
    }


    const onOpenJobDetailsPanel = async (job: JobInvoiceRow) => {
        const selectedJob = new Job(job);
        setSelectedJob(selectedJob);
        setShowJobDetailsPanel(true);
        // const logs = await JobController.getJobLogsByJobId(job._id)
        // setJobLogs(logs);
    }

    const onOpenHoldingDetailsPanel = async (holding: HoldingInvoiceRow) => {
        const selectedHolding = new Holding(holding);
        setSelectedHolding(selectedHolding);
        setHoldingDetailsOpen(true);
    }

    const handleGridSort = (sortColumn: any, sortDirection: any) => {
        setSortColumn(sortColumn);
        setSortDirection(sortDirection);
    };

    const memoJobsColumnsRenderer = useMemo(() => {
        return (
            JobsColumnsRenderer({
                onItemsExpanded: onItemsExpanded,
                itemsExpanded: itemsExpanded,
                handleGridSort: handleGridSort,
                onOpenJobDetailsPanel: onOpenJobDetailsPanel,
                getMemberName: getMemberName,
                getClientName: getClientName,
                getClientAccountCode: getClientAccountCode,
            })
        )

    }, [fetchedJobs]);

    const memoHoldingsColumnsRenderer = useMemo(() => {
        return (
            HoldingColumnsRenderer({
                onItemsExpanded: onItemsExpanded,
                itemsExpanded: itemsExpanded,
                handleGridSort: handleGridSort,
                onOpenHoldingDetailsPanel: onOpenHoldingDetailsPanel,
                getMemberName: getMemberName,
                getClientName: getClientName,
                getClientAccountCode: getClientAccountCode,
            })
        )

    }, [fetchedHoldings]);



    // Returns jobRows with services
    const addAllFieldsToJobRows = (rows: Job[]): JobInvoiceRow[] => {
        setColumns([]);
        let allServices = new Set();
        let serviceColumns = [] as Column<JobInvoiceRow>[]; // service columns
        let columnKeys = new Set();
        const addedServices = new Set();
        let updatedRows: JobInvoiceRow[] = [];

        rows?.forEach((job, index) => {
            //////////////////////////////////////////////
            // Add job specific options
            //////////////////////////////////////////////
            let hasRoadsideOptions = false
            let hasTowingOptions = false
            let hasTransport = false
            if (job.details?.options) {
                const options = job.details?.options
                hasRoadsideOptions = options?.roadside_job_options
                hasTowingOptions = options?.towing_job_options
                hasTransport = options?.transport_job_options
            } else {
                hasRoadsideOptions = true
            }


            if (hasRoadsideOptions) {
                const vehicleColumns = createVehicleColumns(job);
                vehicleColumns.forEach((column) => {
                    if (!columnKeys.has(column.key)) {
                        columnKeys.add(column.key);
                        serviceColumns.push(column);
                    }
                });
            }

            if (hasTowingOptions) {
                const towingColumns = createTowingColumns(job);
                towingColumns.forEach((column) => {
                    if (!columnKeys.has(column.key)) {
                        columnKeys.add(column.key);
                        serviceColumns.push(column);
                    }
                });
            }

            if (servicesCheckedRef.current?.length === 0) {
                try {

                    job.details.selected_services?.forEach((service) => {
                        allServices.add(service.name);
                    });
                } catch (err) {
                    console.log(err);
                }
            } else {
                try {
                    job.details.selected_services?.forEach((service) => {
                        if (servicesCheckedRef.current.some((s: Service) => s._id === service._id) && !addedServices.has(service.name)) {
                            allServices.add(service.name);
                            addedServices.add(service.name);
                        }
                    });
                } catch (err) {
                    console.log(err);
                }
            }
            allServices.forEach((serviceName) => {
                const serviceItem = job.details?.selected_services?.find((item) => item.name === serviceName) as Service;
                if (serviceItem) {
                    Object.entries(serviceItem.fields).forEach(([fieldKey, fieldName]: any) => {
                        //@ts-ignore
                        const isInvoiced = serviceItem.invoiced[fieldKey] === true;
                        //@ts-ignore
                        const fieldValue = serviceItem.values[fieldKey];
                        if (isInvoiced) {
                            const columnKey = `details.selected_services.${serviceName}.${fieldKey}`;
                            if (!columnKeys.has(columnKey)) {
                                columnKeys.add(columnKey);
                                //@ts-ignore
                                const isCost = serviceItem.types[fieldKey] === 'cost';
                                serviceColumns.push({
                                    key: columnKey,
                                    name: `${serviceName} | ${fieldName}`,
                                    formatter: ({ row }) => {
                                        if (!row.details?.selected_services) return (<Text></Text>)
                                        const service = row.details.selected_services.find((s) => s.name === serviceName);
                                        if (isCost) {
                                            return <Text style={{ backgroundColor: "rgba(0,0,255,0.2)" }}>{service ? Formatters.paymentFormatter(fieldValue) : ''}</Text>;
                                        } else {
                                            return <Text>{service ? fieldValue?.toString() : ''}</Text>;
                                        }
                                    },
                                });
                            }
                        }

                    });
                }
            });
        })

        // Add service name to column
        allServices.forEach((serviceName: any) => {
            serviceColumns.push({
                key: `details.selected_services.${serviceName}`,
                name: serviceName,
                formatter: ({ row }: { row: JobInvoiceRow }) => {
                    if (!row.details?.selected_services) return (<Text></Text>)
                    const service = row.details?.selected_services.find(
                        (s) => s.name === serviceName
                    );
                    return <Text>{service ? service.name : ''}</Text>;
                },
            });
        });

        updatedRows = rows?.map((job, index) => {
            const updatedJob = new JobInvoiceRow(job)
            serviceColumns.forEach((column) => {
                //@ts-ignore
                updatedJob[column.key] = column.formatter({ row: job });
            });
            updatedJob.id = index;
            return updatedJob;
        });
        // ensure no duplicate columns
        const defaultColumns = memoJobsColumnsRenderer
        setColumns([...defaultColumns as any, ...serviceColumns]);
        return updatedRows;
    };



    // Returns jobRows with services
    const addAllFieldsToHoldingRows = (rows: Holding[]): HoldingInvoiceRow[] => {
        setColumns([]);
        let allServices = new Set();
        let serviceColumns = [] as Column<HoldingInvoiceRow>[]; // service columns
        let columnKeys = new Set();
        const addedServices = new Set();
        let updatedRows: HoldingInvoiceRow[] = [];


        rows?.forEach((holding, index) => {
            if (servicesCheckedRef.current?.length === 0) {
                try {

                    holding.details.selected_services?.forEach((service) => {
                        allServices.add(service.name);
                    });
                } catch (err) {
                    console.log(err);
                }
            } else {
                try {
                    holding.details.selected_services?.forEach((service) => {
                        if (servicesCheckedRef.current.some((s: Service) => s._id === service._id) && !addedServices.has(service.name)) {
                            allServices.add(service.name);
                            addedServices.add(service.name);
                        }
                    });
                } catch (err) {
                    console.log(err);
                }
            }
            allServices.forEach((serviceName) => {
                const serviceItem = holding.details?.selected_services?.find((item) => item.name === serviceName) as Service;
                if (serviceItem) {
                    Object.entries(serviceItem.fields).forEach(([fieldKey, fieldName]: any) => {
                        //@ts-ignore
                        const isInvoiced = serviceItem.invoiced[fieldKey] === true;
                        //@ts-ignore
                        const fieldValue = serviceItem.values[fieldKey];
                        if (isInvoiced) {
                            const columnKey = `details.selected_services.${serviceName}.${fieldKey}`;
                            if (!columnKeys.has(columnKey)) {
                                columnKeys.add(columnKey);
                                //@ts-ignore
                                const isCost = serviceItem.types[fieldKey] === 'cost';
                                serviceColumns.push({
                                    key: columnKey,
                                    name: `${serviceName} | ${fieldName}`,
                                    formatter: ({ row }) => {
                                        if (!row.details?.selected_services) return (<Text></Text>)
                                        const service = row.details.selected_services.find((s) => s.name === serviceName);
                                        if (isCost) {
                                            return <Text style={{ backgroundColor: "rgba(0,0,255,0.2)" }}>{service ? Formatters.paymentFormatter(fieldValue) : ''}</Text>;
                                        } else {
                                            return <Text>{service ? fieldValue?.toString() : ''}</Text>;
                                        }
                                    },
                                });
                            }
                        }

                    });
                }
            });
        })

        // Add service name to column
        allServices.forEach((serviceName: any) => {
            serviceColumns.push({
                key: `details.selected_services.${serviceName}`,
                name: serviceName,
                formatter: ({ row }: { row: HoldingInvoiceRow }) => {
                    if (!row.details?.selected_services) return (<Text></Text>)
                    const service = row.details?.selected_services.find(
                        (s) => s.name === serviceName
                    );
                    return <Text>{service ? service.name : ''}</Text>;
                },
            });
        });

        updatedRows = rows?.map((holding, index) => {
            const updatedJob = new HoldingInvoiceRow(holding)
            serviceColumns.forEach((column) => {
                //@ts-ignore
                updatedJob[column.key] = column.formatter({ row: holding });
            });
            updatedJob.id = index;
            return updatedJob;
        });
        // ensure no duplicate columns
        const defaultColumns = memoHoldingsColumnsRenderer
        setColumns([...defaultColumns as any, ...serviceColumns]);
        return updatedRows;
    };








    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    // ITEM ROWS
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////

    const [itemsExpanded, setItemsExpanded] = useState(true);

    const onItemsExpanded = () => {
        setItemsExpanded(!itemsExpanded);
        toggleItems(!itemsExpanded)
    };

    const toggleItems = useCallback(async (expanded: boolean) => {
        setColumns(prevColumns => {
            const updatedColumns = [...prevColumns];
            const itemsColumnIndex = updatedColumns.findIndex(c => c.key === 'items') + 1;

            if (expanded) {
                updatedColumns.splice(itemsColumnIndex, 0, ...itemSubrowColumns as any);
            } else {
                updatedColumns.splice(itemsColumnIndex, itemSubrowColumns.length);
            }

            return updatedColumns;
        });
        if (holdingsOrJobs === "jobs") {
            setRows(prevRows => {
                let newRows: JobInvoiceRow[] = [...prevRows as JobInvoiceRow[]];

                if (expanded) {
                    newRows = newRows.reduce((expandedRows: JobInvoiceRow[], row) => {
                        const jobLineItems = row.details?.report.items;
                        if (!jobLineItems || jobLineItems?.length === 0) {
                            expandedRows.push(row);
                            return expandedRows;
                        }

                        //@ts-ignore
                        const subRows: JobInvoiceRow[] = jobLineItems.map((child, index) => ({
                            ...child,
                            _id: row._id,
                            createdAt: row.createdAt,
                            invoice_date: row.createdAt,
                            job_count: row.job_count,
                            pending_time: row.pending_time,
                            isSubRow: true,
                            isItemRow: true,
                            parentId: row.id,
                            id: `${row.id}-${index + 1}`,
                            total: (child.cost * child.quantity).toFixed(2),
                        }));

                        const total = subRows.reduce((acc, cur) => acc + parseFloat(cur.total), 0).toFixed(2);
                        subRows.push({
                            _id: row._id,
                            createdAt: row.createdAt,
                            job_count: row.job_count,
                            invoice_date: row.createdAt,
                            pending_time: row.pending_time,
                            isTotalRow: true,
                            isSubRow: true,
                            total: `$${total}`,
                            id: `${row.id}-total`,
                        } as unknown as JobInvoiceRow);

                        expandedRows.push(row, ...subRows);
                        return expandedRows;
                    }, []);
                } else {
                    newRows = newRows.filter(row => !row.isSubRow);
                }

                return newRows;
            });
        } else {
            setRows(prevRows => {
                let newRows: HoldingInvoiceRow[] = [...prevRows as HoldingInvoiceRow[]];

                if (expanded) {
                    newRows = newRows.reduce((expandedRows: HoldingInvoiceRow[], row) => {
                        const holdingLineItems = row.line_items;
                        if (!holdingLineItems || holdingLineItems?.length === 0) {
                            expandedRows.push(row);
                            return expandedRows;
                        }

                        //@ts-ignore
                        const subRows: HoldingInvoiceRow[] = holdingLineItems.map((child, index) => ({
                            ...child,
                            _id: row._id,
                            createdAt: row.createdAt,
                            invoice_date: row.createdAt,
                            isSubRow: true,
                            isItemRow: true,
                            parentId: row.id,
                            id: `${row.id}-${index + 1}`,
                            total: (child.cost * child.quantity).toFixed(2),
                        }));

                        const total = subRows.reduce((acc, cur) => acc + parseFloat(cur.total), 0).toFixed(2);
                        subRows.push({
                            _id: row._id,
                            createdAt: row.createdAt,
                            invoice_date: row.createdAt,
                            isTotalRow: true,
                            isSubRow: true,
                            total: `$${total}`,
                            id: `${row.id}-total`,
                        } as unknown as HoldingInvoiceRow);

                        expandedRows.push(row, ...subRows);
                        return expandedRows;
                    }, []);
                } else {
                    newRows = newRows.filter(row => !row.isSubRow);
                }

                return newRows;
            });
        }
    }, [holdingsOrJobs]);




    const sortedData = useMemo(() => {
        const result = memoizedDataRows?.sort((a: any, b: any) => {
            const sortValueA: any = a[sortColumn];
            const sortValueB: any = b[sortColumn];
            let result = 0;

            if (typeof sortValueA === 'number' && typeof sortValueB === 'number') {

                return sortDirection === 'ASC' ? sortValueA - sortValueB : sortValueB - sortValueA;
            } else if (typeof sortValueA === 'string' && typeof sortValueB === 'string') {

                return sortDirection === 'ASC' ? sortValueA.localeCompare(sortValueB) : sortValueB.localeCompare(sortValueA);
            } else if (sortValueA instanceof Date && sortValueB instanceof Date) {

                //@ts-ignore
                return sortDirection === 'ASC' ? sortValueA - sortValueB : sortValueB - sortValueA;
            } else {
                return 0;
            }
        });
        return result;
    }, [memoizedDataRows, sortColumn, sortDirection]);




    const topSummaryRowJobs = useMemo(() => {
        return [{
            id: 'summary',
            totalCount: sortedData?.filter(row => !row.isSubRow)?.length,
            clientRateTotal: sortedData?.reduce((acc, cur) => acc + parseFloat(Utils.getTotalClientRate(cur).toString()), 0).toFixed(2),
            itemBillAllBackTotal: sortedData?.reduce((acc, cur) => acc + parseFloat(Utils.getTotalItemBillAllBack(cur).toString()), 0).toFixed(2),
            itemCustomerCostTotal: sortedData?.reduce((acc, cur) => acc + parseFloat(Utils.getTotalItemCustomerCost(cur).toString()), 0).toFixed(2),
            itemTotal: sortedData?.reduce((acc, cur) => acc + parseFloat(Utils.getTotalCostItems(cur).toString()), 0).toFixed(2),
            cardAmount: sortedData?.reduce((acc, cur) => acc + parseFloat(Utils.getCustomerPaidCardAmount(cur as JobInvoiceRow).toString()), 0).toFixed(2),
            cashAmount: sortedData?.reduce((acc, cur) => acc + parseFloat(Utils.getCustomerPaidCashAmount(cur as JobInvoiceRow).toString()), 0).toFixed(2),
            totalPaid: sortedData?.reduce((acc, cur) => acc + parseFloat(Utils.getCustomerPaidCardAmount(cur as JobInvoiceRow).toString()) + parseFloat(Utils.getCustomerPaidCashAmount(cur as JobInvoiceRow).toString()), 0).toFixed(2),
            total: 0,
        }];
    }, [sortedData]);


    const topSummaryRowHoldings = useMemo(() => {
        return [{
            id: 'summary',
            totalCount: sortedData?.length,
            total: 0,
            // cost: 0,
            // quantity: 0,
            // billAllBack: 0,
            // customerCost: 0,
            // invoiceNumber: 0,
            // invoiceDate: 0,
            // invoiceDueDate: 0,
            // invoiceTaxType: 0,
            // invoiceTax: 0,
            // invoiceTotal: 0,
            // invoiceStatus: 0,
            // description: 0,
            // isTotalRow: true,
        }];
    }, [sortedData]);





    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    // COLUMN FORMATTING
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////





    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    // RENDER DATA GRID
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    const gridElement = useRef(null);


    const handleCellContextMenu = (args: any) => {
        // console.log("handleCellContextMenu", args)
        // if (args.row && !args.row.isSubRow) {
        //     setSelectedJob(args.row)
        //     setShowJobDetailsPanel(true);
        // }
    }

    const RenderGrid = (
        <DataGrid
            ref={gridElement}
            style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, height: "100%" }}
            columns={memoizedColumns as any}
            rows={sortedData}
            rowKeyGetter={rowKeyGetter as any}
            topSummaryRows={holdingsOrJobs == "jobs" ? topSummaryRowJobs : topSummaryRowHoldings}
            onRowsChange={setRows}
            onFill={handleFill}
            onCopy={handleCopy}
            onPaste={handlePaste}
            rowHeight={30}
            //@ts-ignore
            sortDirection={sortDirection as any}
            //@ts-ignore
            onSort={handleGridSort}
            selectedRows={selectedRows as any}
            onCellContextMenu={handleCellContextMenu}
            onSelectedRowsChange={setSelectedRows}
            // className="fill-grid"
            className="rdg-dark"
            direction={"ltr"}
            onCellClick={(args, event) => {
                // console.log("=====Cell click=====", args, event)
            }}
        />
    )



    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    // FILTERS
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////

    const clientsCheckedRef = useRef([] as Client[]);
    const driversCheckedRef = useRef([] as Member[]);
    const servicesCheckedRef = useRef([] as Service[]);
    const statusesCheckedRef = useRef([] as any[]);
    const [statuses, setStatuses] = useState([]); // statuses checked in filter
    const [statusesChecked, setStatusesChecked] = useState([] as any[]); // statuses checked in filter
    const [clientsChecked, setClientsChecked] = useState([] as any[]); // clients checked in filter
    const [driversChecked, setDriversChecked] = useState([] as any[]); // drivers checked in filter
    const [servicesChecked, setServicesChecked] = useState([] as any[]); // services checked in filter


    // clear filters
    const clearFilters = () => {
        clientsCheckedRef.current = [];
        driversCheckedRef.current = [];
        servicesCheckedRef.current = [];
        statusesCheckedRef.current = [];
        setStatusesChecked([]);
        setClientsChecked([]);
        setDriversChecked([]);
        setServicesChecked([]);
        setStatuses([]);
        forceUpdate()
    }

    const RenderFilterCards = () => {
        return (

            <Filters
                filterType={holdingsOrJobs as any}
                clients={clientsRef}
                drivers={driversRef}
                services={servicesRef}
                statuses={statuses}
                statusesChecked={statusesChecked}
                clientsChecked={clientsChecked}
                driversChecked={driversChecked}
                servicesChecked={servicesChecked}
                setClientsChecked={setClientsChecked}
                setDriversChecked={setDriversChecked}
                setServicesChecked={setServicesChecked}
                setStatusesChecked={setStatusesChecked}
                clientsCheckedRef={clientsCheckedRef}
                driversCheckedRef={driversCheckedRef}
                servicesCheckedRef={servicesCheckedRef}
                statusesCheckedRef={statusesCheckedRef}
                jobs={fetchedJobs}
                filteredJobs={sortedData as Job[]}
                holdings={fetchedHoldings}
                filteredHoldings={sortedData as Holding[]}
                forceUpdate={forceUpdate}
                filterJobsByCriteria={filterJobsByCriteria}
                filterHoldingsByCriteria={filterHoldingsByCriteria}
            />
        )
    }


    //////////////////////////////////////////////////////
    /////////////////////////////////////////////////////
    ///// FILTER JOBS BY CRITERIA
    ////////////////////////////////////////////////////
    /////////////////////////////////////////////////// 
    const filterJobsByCriteria = () => {
        try {

            //convert to the JobInvoiceRow type
            const nextJobs = fetchedJobs
            const nextJobsFiltered = nextJobs.filter((job) => {
                if (
                    clientsCheckedRef.current?.length > 0 &&
                    !clientsCheckedRef.current.find((c) => c._id === job.client_id)
                ) {
                    return false;
                }
                if (
                    driversCheckedRef.current?.length > 0 &&
                    !driversCheckedRef.current.find((d) => d._id === job.member_id)
                ) {
                    return false;
                }
                if (servicesCheckedRef.current?.length > 0) {

                    const hasSelectedService = job.details.selected_services.some(
                        (service) =>
                            servicesCheckedRef.current.some(
                                (selectedService) => selectedService._id === service._id
                            )
                    );

                    if (!hasSelectedService) {
                        return false;
                    }
                }
                if (
                    statusesCheckedRef.current.length > 0 &&
                    !statusesCheckedRef.current.includes(job.status)
                ) {
                    return false;
                }
                return true;
            });
            const updatedJobs = addAllFieldsToJobRows(nextJobsFiltered);
            setRows(updatedJobs);
            itemsExpanded ? toggleItems(true) : null;
            // setRows(nextJobsFiltered);
            forceUpdate();
        } catch (e) {
            console.log('Error filterJobsByCriteria: ', e);
        }
    };

    const filterHoldingsByCriteria = () => {
        try {
            //convert to the HoldingInvoiceRow type
            const nextHoldings = fetchedHoldings
            const nextHoldingsFiltered = nextHoldings.filter((holding) => {
                if (
                    clientsCheckedRef.current?.length > 0 &&
                    !clientsCheckedRef.current.find((c) => c._id === holding.client_id)
                ) {
                    return false;
                }
                if (servicesCheckedRef.current?.length > 0) {
                    const hasSelectedService = holding.details.selected_services.some(
                        (service) =>
                            servicesCheckedRef.current.some(
                                (selectedService) => selectedService._id === service._id
                            )
                    );
                    if (!hasSelectedService) {
                        return false;
                    }
                }
                return true;
            });
            const updatedHoldings = addAllFieldsToHoldingRows(nextHoldingsFiltered as any);
            setRows(updatedHoldings);
            itemsExpanded ? toggleItems(true) : null;
            // setRows(nextJobsFiltered);
            forceUpdate();
        } catch (e) {
            console.log('Error filterJobsByCriteria: ', e);
        }
    }








    //////////////////////////////////////////////////////
    /////////////////////////////////////////////////////
    ///// FILTER JOBS BY DATE
    ////////////////////////////////////////////////////
    /////////////////////////////////////////////////// 



    interface DateRange {
        startDate: Date;
        endDate: Date;
    }

    const onDateRangeChange = (nextRange: DateRange) => {
        if (validateDateRange(nextRange)) {
            onSetDateRange(nextRange);
            searchJobsByDateRange(nextRange.startDate, nextRange.endDate);
        }
    };

    const validateStartDate = (date: Date) => {
        date = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 5, 5, 5);
        // convert isodate to timestamp
        var timestamp = date.getTime()
        // console.log(timestamp)
        onSetStartDate(date)
    }
    const validateEndDate = (date: Date) => {
        // console.log(date)
        onSetEndDate(date)
    }
    const validateRange = (range: DateRange) => {
        // console.log(range)
        validateStartDate(range.startDate)
        validateEndDate(range.endDate)
        onSetDateRange(range)
    }

    const [showLoading, setShowLoading] = useState(false);
    // render full page loading spinner
    const RenderLoading = () => (
        <Layout style={{
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            justifyContent: "center",
            alignItems: "center",
            zIndex: 100,
            backgroundColor: "rgba(0,0,0,0.5)"
        }}>
            <Spinner size="giant" />
        </Layout>
    )









    // validate start date is before end date
    const validateDateRange = (nextRange: any) => {
        const { startDate, endDate } = nextRange; // are Date objects
        return startDate <= endDate;
    };

    const CalendarIcon = (props: any) => (
        <Icon {...props} name="calendar" />
    );
    const [currentWeek, setCurrentWeek] = useState(new Date()); // Initial week
    // render this week button
    const RenderNextWeekButton = () => (
        <Button
            style={{}}
            appearance="outline"
            status="basic"
            onPress={() => {
                // start date is last sunday aned date is this saturday
                handleNextWeek();
            }}
        >
            <Icon name="arrow-ios-forward-outline" width={30} height={30} />
        </Button>
    );

    const RenderLastWeekButton = () => (
        <Button
            style={{}}
            appearance="outline"
            status="basic"
            onPress={() => {
                // start date is last sunday aned date is this saturday
                handlePreviousWeek();
            }}
        >
            <Icon name="arrow-ios-back-outline" width={30} height={30} />
        </Button>
    );


    const handlePreviousWeek = () => {
        const previousWeek = new Date(currentWeek);
        previousWeek.setDate(previousWeek.getDate() - 7);
        setCurrentWeek(previousWeek);
        updateDateRange(previousWeek);
    };

    const handleNextWeek = () => {
        const nextWeek = new Date(currentWeek);
        nextWeek.setDate(nextWeek.getDate() + 7);
        setCurrentWeek(nextWeek);
        updateDateRange(nextWeek);
    };

    const updateDateRange = (week: Date) => {
        const startDate = new Date(week);
        const endDate = new Date(week);
        startDate.setDate(startDate.getDate() - week.getDay() + 1);
        endDate.setDate(endDate.getDate() - week.getDay() + 7);
        const nextRange = { startDate, endDate };
        onDateRangeChange(nextRange);
    };


    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    // TRANSFORM DATA
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////

    const buildDescription = (details: any, fields: any) => {
        if (!details) return "";
        return fields
            .map((field: any) => details[field] || "")
            .filter((value: any) => value !== "")
            .join(" | ");
    };

    const transformHoldingDataToCSV = useMemo(() => {
        let job = [] as JobInvoiceRow[];
        let lineItems = [] as LineItem[];
        let serviceItems = [] as Service[];

        let csvData = [];
        const sD = sortedData as HoldingInvoiceRow[];
        sD?.forEach((item: HoldingInvoiceRow) => {
            // const invoiceDate = new Date(item["pending_time"]).toLocaleDateString();
            // format date to dd/mm/yyyy
            const invoiceDate = new Date(item["released_time"]).toLocaleDateString("en-AU", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
            });
            // const dueDate = new Date(item["pending_time"] + 2592000000).toLocaleDateString();
            const mDueDate = getDueDate()
            let dueDate = new Date(mDueDate).toLocaleDateString("en-AU", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
            });
            const clientName = getClientName(item.client_id as string);
            const clientAccountCode = getClientAccountCode(item.client_id as string);
            const invoiceData = {} as any;
            const taxType = getTaxType();
            const invoiceNumber = item.name;

            if (item.isSubRow) return

            // get client_rate name and cost from item.details.client_rate if theyt exist
            let client_rate_name = "";
            let client_rate_cost = 0;
            let hasClientRate = false

            if (item.details?.hasOwnProperty("client_rate")) {
                client_rate_name = item.details.client_rate.name;
                client_rate_cost = item.details.client_rate.cost;
                hasClientRate = true
            }

            // Add invoice level data
            invoiceData["*ContactName"] = clientName
            invoiceData["*AccountCode"] = clientAccountCode
            // invoiceData["*TaxType"] = "GST on Income";
            invoiceData["*TaxType"] = taxType;
            invoiceData["*InvoiceNumber"] = invoiceNumber;
            invoiceData["*InvoiceDate"] = invoiceDate;
            // Due date is invoice date + 30 days
            invoiceData["*DueDate"] = dueDate;
            invoiceData["*Quantity"] = 1;
            invoiceData["*UnitAmount"] = client_rate_cost;
            invoiceData["Reference"] = item.details.client_reference_id || ""
            // description : deiver name | service names | vehicle details | customer details | comments
            let description = "";
            // const driverName = getMemberName(item["member_id"]) || "";
            let vehicleDetails = "";
            let towingDetails = "";
            let customerDetails = "";
            let comments = "";
            let serviceNames = "";
            let client_reference_id = item.details.client_reference_id || "";

            let vehicle_details = {} as JobVehicleDetails
            let towing_details = {} as JobTowingDetails
            let transport_details = {} as any
            let customer_details = {} as JobCustomerDetails
            let addressDetails = item.details?.address?.addressToString() || ""

            vehicleDetails = buildDescription(item.details.vehicle_details, ["make", "model", "year", "colour", "rego"]);
            towing_details = item.details.towing_details //assumed exists
            if (towing_details?.towing_type?.toLowerCase() === "equipment") {
                towingDetails = buildDescription(towing_details?.equipment_details, ["equipment", "serial", "size", "type", "weight"]);
            }

            if (towing_details?.towing_type?.toLowerCase() === "vehicle") {
                towingDetails = buildDescription(towing_details?.vehicle_details, ["make", "model", "rego", "type", "year", "colour"]);
            }
            // for each towing location get the address
            if (towing_details?.towing_locations?.length > 0) {
                try {
                    towingDetails += ` | Pickup: ${towing_details.pickup_address.addressToString()} | Dropoff: ${towing_details.dropoff_address.addressToString()}`;
                } catch (err) {
                }
            }

            customer_details = item.details.customer_details
            customerDetails = buildDescription(customer_details, ["name", "phone", "email"]);



            try {
                comments = `${item.details.notes.map((note => note.note)) || ""}`
                serviceNames = item.details?.selected_services?.map((service: Service) => service.name || "").concat().join(" | ") || "";
            }
            catch (e) {
                // console.log(e, item)
            }
            description = ""
            if (item.status == "cancelled") {
                description += `CANCELLED -- |`
            } else if (item.status == "transferred_out") {
                description += `TRANSFERRED OUT -- |`
            }
            description += `RefID: ${client_reference_id} |`
            description += `Service: ${serviceNames} |`
            description += `Address: ${addressDetails} |`

            description += `Vehicle: ${vehicleDetails} |`
            description += `Towing: ${towingDetails} |`

            description += `Customer: ${customerDetails} |`
            description += `Comments: ${comments.replace(",", " ")}`
            if (hasClientRate) {
                description += `Client Rate: ${client_rate_name} at ${client_rate_cost} |`
            }
            invoiceData["Description"] = description;

            if (!clientName || !clientAccountCode || clientName == "") {
                return
            }
            job.push(invoiceData);

            // Add invoice line item data for each item
            const getBilling = (item: LineItem) => {
                if (item.billAllBack)
                    return 'Bill All Back'
                else if (item.customerCost)
                    return 'Customer Cost'
                else
                    return ''
            }
            if (exportLineItemsRef.current) {
                let items = [] as LineItem[]
                if (exportBillBackItemsRef.current) {
                    const bbItems = item.getTotalBillBackItems()
                    items = items.concat(bbItems)
                }
                if (exportCustomerCostItemsRef.current) {
                    const ccItems = item.getTotalCustomerCostItems()
                    items = items.concat(ccItems)
                }
                items?.forEach((lineItem) => {
                    const lineItemData = {} as any;
                    lineItemData["*ContactName"] = clientName
                    lineItemData["*AccountCode"] = clientAccountCode
                    lineItemData["*TaxType"] = "GST on Income";
                    lineItemData["*InvoiceNumber"] = invoiceNumber;
                    lineItemData["*InvoiceDate"] = invoiceDate;
                    // Due date is invoice date + 30 days
                    lineItemData["*DueDate"] = dueDate;
                    lineItemData["*Quantity"] = lineItem.quantity;
                    lineItemData["*UnitAmount"] = lineItem.cost;
                    lineItemData["Description"] = `${getBilling(lineItem)} - ${lineItem.description || lineItem.name || ""}`;
                    lineItems.push(lineItemData);
                });
            }
            // csvData.push(...lineItems);
            if (exportServicesRef.current && item.details?.selected_services) {
                // Add selected service line item data
                item.details?.selected_services?.forEach((service: Service) => {
                    const serviceData = {} as any;
                    //@ts-ignore
                    if (!service?.fields || !service?.fields?.length == 0) return;
                    // if a field is invoiced, add it to the serviceData object, mapped by the field name
                    Object.keys(service?.fields)?.forEach((field: any) => {
                        //@ts-ignore
                        if (service.invoiced[field]) {
                            // if the field is invoiced, add it to the serviceData object
                            // has cost and quantity
                            let added = false
                            //@ts-ignore
                            if (service.types[field] == "cost") {
                                //@ts-ignore
                                serviceData["*UnitAmount"] = service.values[field];
                                added = true
                            }
                            //@ts-ignore
                            if (service.types[field] == "quantity") {
                                //@ts-ignore
                                serviceData["*Quantity"] = service.values[field];
                                added = true
                            }
                            if (!added) {
                                //@ts-ignore
                                serviceData[`${service.name}-${field}`] = service.values[field];
                            }
                        }
                    });
                    serviceData["*ContactName"] = clientName
                    serviceData["*AccountCode"] = clientAccountCode
                    serviceData["*TaxType"] = taxType;
                    serviceData["*InvoiceNumber"] = invoiceNumber;
                    serviceData["*InvoiceDate"] = invoiceDate;
                    serviceData["*DueDate"] = dueDate;

                    serviceItems.push(serviceData);
                });
                // csvData.push(...serviceItems);
            }
        });
        // Add all arrays to csvData
        csvData.push(...job, ...lineItems, ...serviceItems);
        return csvData;
    }, [sortedData, dueDate, taxType, exportServices, exportLineItems, exportBillBackItems, exportCustomerCostItems]);


    const transformJobDataToCSV = useMemo(() => {
        let job = [] as JobInvoiceRow[];
        let lineItems = [] as LineItem[];
        let serviceItems = [] as Service[];

        let csvData = [];

        sortedData?.forEach((item: JobInvoiceRow | any) => {
            // const invoiceDate = new Date(item["pending_time"]).toLocaleDateString();
            // format date to dd/mm/yyyy
            const invoiceDate = new Date(item["pending_time"]).toLocaleDateString("en-AU", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
            });
            // const dueDate = new Date(item["pending_time"] + 2592000000).toLocaleDateString();
            const mDueDate = getDueDate()
            let dueDate = new Date(mDueDate).toLocaleDateString("en-AU", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
            });
            const clientName = getClientName(item["client_id"]);
            const clientAccountCode = getClientAccountCode(item["client_id"]);
            const invoiceData = {} as any;
            const taxType = getTaxType();
            const invoiceNumber = Utils.generateInvoiceNumber(item["_id"], item["createdAt"], item["job_count"]);

            if (item.isSubRow) return

            // get client_rate name and cost from item.details.client_rate if theyt exist
            let client_rate_name = "";
            let client_rate_cost = 0;
            let hasClientRate = false

            if (item.details?.hasOwnProperty("client_rate")) {
                client_rate_name = item.details.client_rate.name;
                client_rate_cost = item.details.client_rate.cost;
                hasClientRate = true
            }

            // Add invoice level data
            invoiceData["*ContactName"] = clientName
            invoiceData["*AccountCode"] = clientAccountCode
            // invoiceData["*TaxType"] = "GST on Income";
            invoiceData["*TaxType"] = taxType;
            invoiceData["*InvoiceNumber"] = invoiceNumber;
            invoiceData["*InvoiceDate"] = invoiceDate;
            // Due date is invoice date + 30 days
            invoiceData["*DueDate"] = dueDate;
            invoiceData["*Quantity"] = 1;
            invoiceData["*UnitAmount"] = client_rate_cost;
            invoiceData["Reference"] = item["client_reference_id"] || ""
            // description : deiver name | service names | vehicle details | customer details | comments
            let description = "";
            const driverName = getMemberName(item["member_id"]) || "";
            let vehicleDetails = "";
            let towingDetails = "";
            let customerDetails = "";
            let comments = "";
            let serviceNames = "";
            let client_reference_id = item["client_reference_id"] || "";

            // get options, if no options property exists, assume roadside job
            let hasRoadsideOptions = false
            let hasTowingOptions = false
            let hasTransport = false
            if (item.details?.options) {
                const options = item.details?.options
                hasRoadsideOptions = options?.roadside_job_options
                hasTowingOptions = options?.towing_job_options
                hasTransport = options?.transport_job_options
            } else {
                hasRoadsideOptions = true
            }

            let vehicle_details = {} as JobVehicleDetails
            let towing_details = {} as JobTowingDetails
            let transport_details = {} as any
            let customer_details = {} as JobCustomerDetails

            if (hasRoadsideOptions) {
                vehicleDetails = buildDescription(item.details.vehicle_details, ["make", "model", "year", "colour", "rego"]);
            }
            if (hasTowingOptions) {
                towing_details = item.details.towing_details //assumed exists
                if (towing_details?.towing_type?.toLowerCase() === "equipment") {
                    towingDetails = buildDescription(towing_details?.equipment_details, ["equipment", "serial", "size", "type", "weight"]);
                }

                if (towing_details?.towing_type?.toLowerCase() === "vehicle") {
                    towingDetails = buildDescription(towing_details?.vehicle_details, ["make", "model", "rego", "type", "year", "colour"]);
                }
                // for each towing location get the address
                if (towing_details?.towing_locations?.length > 0) {
                    try {
                        towingDetails += ` | Pickup: ${towing_details.pickup_address.addressToString()} | Dropoff: ${towing_details.dropoff_address.addressToString()}`;
                    } catch (err) {
                    }
                }
            }
            if (hasTransport) {
                // transport_details = item.details.transport_details || {} //assumed exists
            }

            if (item.details?.hasOwnProperty("customer_details")) {
                customer_details = item.details.customer_details
            }
            customerDetails = buildDescription(customer_details, ["name", "phone", "email"]);



            try {
                // vehicleDetails = `${item.details.make || ""} ${item.details.model || ""} ${item.details.year || ""} ${item.details.color || ""}`;
                // customerDetails = `${item.details.name || ""} ${item.details.phone || ""} ${item.details.email || ""}`;
                comments = `${item.details.comments || ""} ${item.details?.report?.comments || ""}`;
                serviceNames = item.details?.selected_services?.map((service: Service) => service.name || "").concat().join(" | ") || "";
            }
            catch (e) {
                // console.log(e, item)
            }
            description = ""
            if (item.status == "cancelled") {
                description += `CANCELLED -- |`
            } else if (item.status == "transferred_out") {
                description += `TRANSFERRED OUT -- |`
            }
            description += `RefID: ${client_reference_id} |`
            description += `Driver: ${driverName} |`
            description += `Service: ${serviceNames} |`

            if (hasRoadsideOptions) {
                description += `Vehicle: ${vehicleDetails} |`
            }
            if (hasTowingOptions) {
                description += `Towing: ${towingDetails} |`
            }

            description += `Customer: ${customerDetails} |`
            description += `Comments: ${comments.replace(",", " ")}`

            if (hasClientRate) {
                description += `Client Rate: ${client_rate_name} at ${client_rate_cost} |`
            }
            invoiceData["Description"] = description;

            if (!clientName || !clientAccountCode || clientName == "") {
                return
            }
            job.push(invoiceData);

            // Add invoice line item data for each item
            const getBilling = (item: LineItem) => {
                if (item.billAllBack)
                    return 'Bill All Back'
                else if (item.customerCost)
                    return 'Customer Cost'
                else
                    return ''
            }
            if (exportLineItemsRef.current) {
                let items = [] as LineItem[]
                const report = new JobReport(item.details?.report)
                if (exportBillBackItemsRef.current) {
                    const bbItems = report.getTotalBillBackItems()
                    items = items.concat(bbItems)
                }
                if (exportCustomerCostItemsRef.current) {
                    const ccItems = report.getTotalCustomerCostItems()
                    items = items.concat(ccItems)
                }
                items?.forEach((lineItem) => {
                    const lineItemData = {} as any;
                    lineItemData["*ContactName"] = clientName
                    lineItemData["*AccountCode"] = clientAccountCode
                    lineItemData["*TaxType"] = "GST on Income";
                    lineItemData["*InvoiceNumber"] = invoiceNumber;
                    lineItemData["*InvoiceDate"] = invoiceDate;
                    // Due date is invoice date + 30 days
                    lineItemData["*DueDate"] = dueDate;
                    lineItemData["*Quantity"] = lineItem.quantity;
                    lineItemData["*UnitAmount"] = lineItem.cost;
                    lineItemData["Description"] = `${getBilling(lineItem)} - ${lineItem.description || lineItem.name || ""}`;
                    lineItems.push(lineItemData);
                });
            }
            // csvData.push(...lineItems);
            if (exportServicesRef.current && item.details?.selected_services) {
                // Add selected service line item data
                item.details?.selected_services?.forEach((service: Service) => {
                    const serviceData = {} as any;
                    //@ts-ignore
                    if (!service?.fields || !service?.fields?.length == 0) return;
                    // if a field is invoiced, add it to the serviceData object, mapped by the field name
                    Object.keys(service?.fields)?.forEach((field: any) => {
                        //@ts-ignore
                        if (service.invoiced[field]) {
                            // if the field is invoiced, add it to the serviceData object
                            // has cost and quantity
                            let added = false
                            //@ts-ignore
                            if (service.types[field] == "cost") {
                                //@ts-ignore
                                serviceData["*UnitAmount"] = service.values[field];
                                added = true
                            }
                            //@ts-ignore
                            if (service.types[field] == "quantity") {
                                //@ts-ignore
                                serviceData["*Quantity"] = service.values[field];
                                added = true
                            }
                            if (!added) {
                                //@ts-ignore
                                serviceData[`${service.name}-${field}`] = service.values[field];
                            }
                        }
                    });
                    serviceData["*ContactName"] = clientName
                    serviceData["*AccountCode"] = clientAccountCode
                    serviceData["*TaxType"] = taxType;
                    serviceData["*InvoiceNumber"] = invoiceNumber;
                    serviceData["*InvoiceDate"] = invoiceDate;
                    serviceData["*DueDate"] = dueDate;

                    serviceItems.push(serviceData);
                });
                // csvData.push(...serviceItems);
            }
        });
        // Add all arrays to csvData
        csvData.push(...job, ...lineItems, ...serviceItems);
        return csvData;
    }, [sortedData, dueDate, taxType, exportServices, exportLineItems, exportBillBackItems, exportCustomerCostItems]);




    // checkbox to confirm set invoices as submitted
    const [confirmSetInvoiceSubmit, setConfirmSetInvoiceSubmit] = useState(false);
    const confirmSetInvoiceSubmitRef = useRef(false);
    const handleConfirmSubmitCheckbox = (value: any) => {
        setConfirmSetInvoiceSubmit(value);
        confirmSetInvoiceSubmitRef.current = value;
    }


    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //////// EXPORT TOOLS
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */



    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    // RENDER
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    const RenderMemoizedHoldingDetails = useMemo(() => {
        if (!selectedHolding) return null
        return (
            <HoldingDetailsContainer
                fn_onClose={() => { onCloseHoldingDetails(); }}
                fn_onReleaseAndCreateJobHolding={() => { setShowReleaseHoldingOptions(true); setHoldingDetailsOpen(false) }}
                holdingItem={selectedHolding as Holding}
                fn_onReleaseOnlyHolding={() => { onCloseHoldingDetails(); }}
                disabled={false}
            />
        )
    }, [selectedHolding, holdingDetailsOpen, selectedCompany])

    return (
        <ErrorBoundary>
            {showLoading && <RenderLoading />}
            <ExportInvoiceModal
                csvExportModalVisible={csvExportModalVisible}
                setExportModalVisible={setExportModalVisible}
                handleConfirmSubmitCheckbox={handleConfirmSubmitCheckbox}
                setDueDate={setDueDate}
                setTaxType={setTaxType}
                setExportServices={setExportServices}
                setExportLineItems={setExportLineItems}
                setExportBillBackItems={setExportBillBackItems}
                setExportCustomerCostItems={setExportCustomerCostItems}
                generateCsvData={Utils.generateCsvData}
                transformData={holdingsOrJobs == "jobs" ? transformJobDataToCSV : transformHoldingDataToCSV}
                jobs={fetchedJobs}
                sortedData={sortedData}
                memoizedInvoiceDueDate={memoizedInvoiceDueDate}
                memoizedInvoiceTaxType={memoizedInvoiceTaxType}
                exportLineItems={exportLineItems}
                exportBillBackItems={exportBillBackItems}
                exportCustomerCostItems={exportCustomerCostItems}
                exportServices={exportServices}
                confirmSetInvoiceSubmit={confirmSetInvoiceSubmit}
                invoiceTaxTypeRef={invoiceTaxTypeRef}
                invoiceDueDateRef={invoiceDueDateRef}
                exportServicesRef={exportServicesRef}
                exportLineItemsRef={exportLineItemsRef}
                exportBillBackItemsRef={exportBillBackItemsRef}
                exportCustomerCostItemsRef={exportCustomerCostItemsRef}
                confirmSetInvoiceSubmitRef={confirmSetInvoiceSubmitRef}
            />

            <View style={{ flex: 1, flexDirection: 'row' }}>
                <View style={{ flex: 2, flexDirection: 'row' }}>
                    {/*
                    //////////////////////////////////////////////////////////////////
                    //////////////////////////////////////////////////////////////////
                    //////// FILTERS AND SEARCH
                    //////////////////////////////////////////////////////////////////
                    //////////////////////////////////////////////////////////////////
                    */}
                    <Layout style={{ flex: 15 }}>
                        <ScrollView>
                            {selectedCompany?.settings.job_options.showTowingJobOptions &&
                                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                    <Button
                                        style={{ flex: 1 }}
                                        appearance={holdingsOrJobs === "jobs" ? 'filled' : 'outline'}
                                        status='info'
                                        onPress={() => {
                                            setHoldingsOrJobs("jobs")
                                        }}>
                                        Jobs
                                    </Button>
                                    <Button
                                        style={{ flex: 1 }}
                                        appearance={holdingsOrJobs === "holdings" ? 'filled' : 'outline'}
                                        status='info'
                                        onPress={() => {
                                            setHoldingsOrJobs("holdings")
                                        }}>
                                        Holdings
                                    </Button>
                                </View>
                            }
                            <View>
                                <Button appearance='outline' onPress={() => { setExportModalVisible(true) }}>
                                    Export To Csv
                                </Button>
                            </View>
                            <View style={{ height: 20 }}></View>
                            <Layout style={{ flexDirection: "row" }}>
                                <Layout style={{ flex: 1 }}>
                                    <RenderLastWeekButton />
                                </Layout>
                                <Layout style={{ flex: 1 }}>
                                    <RenderNextWeekButton />
                                </Layout>
                            </Layout>
                            {/* <RenderDateRangePicker /> */}
                            <Layout style={{}}>
                                <RangeDatepicker
                                    range={dateRange}
                                    min={new Date(1900, 0, 0)}
                                    max={new Date(2099, 0, 0)}
                                    accessoryRight={CalendarIcon}
                                    //@ts-ignore
                                    onSelect={(nextRange: DateRange) => validateRange(nextRange)}
                                />
                            </Layout>
                            <Button style={{ flex: 1 }} size="small" onPress={() => { searchJobsByDateRange() }}>
                                Search
                            </Button>
                            <View style={{ flexDirection: 'column' }}>
                                <RenderFilterCards />

                            </View>
                        </ScrollView>
                    </Layout>
                    {/*
                    //////////////////////////////////////////////////////////////////
                    //////////////////////////////////////////////////////////////////
                    //////// DATA GRID
                    //////////////////////////////////////////////////////////////////
                    //////////////////////////////////////////////////////////////////
                    */}
                    <View style={{ flex: 85, fontFamily: 'Helvetica' }}>
                        <View style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, backgroundColor: "#171717" }}>
                            {RenderGrid}
                        </View>
                    </View>
                </View>
                {/*
                //////////////////////////////////////////////////////////////////
                //////////////////////////////////////////////////////////////////
                //////// JOB DETAILS PANEL
                //////////////////////////////////////////////////////////////////
                //////////////////////////////////////////////////////////////////
                */}
                {((showJobDetailsPanel || holdingDetailsOpen) && (selectedJob || selectedHolding)) &&
                    <Layout style={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        bottom: 0,
                        width: "50%"
                    }}>
                        <Button
                            style={{ position: 'absolute', top: 0, right: 0, zIndex: 10 }}
                            // appearance='outline'
                            status='danger'
                            onPress={() => {
                                setShowJobDetailsPanel(false)
                                setHoldingDetailsOpen(false)
                            }}>
                            <Icon name="close-outline" width={30} height={30} />
                        </Button>
                        <ScrollView style={{ flex: 1 }}>
                            {holdingsOrJobs == "holdings" ?
                                RenderMemoizedHoldingDetails
                                :
                                <FullJobReport job={selectedJob as Job} onClose={() => setShowJobDetailsPanel(false)} showImages={false} showLogs={false} />
                            }
                        </ScrollView>
                    </Layout>
                }
            </View>
        </ErrorBoundary >
    );

};



// export default invoiceScreen

const styles = StyleSheet.create({
    backdrop: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },

});
