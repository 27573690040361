import React from 'react';
import { BaseClientDetailsCard } from './BaseClientDetailsCard';
import { LocalClientJobClientDetailsCardProps } from './ClientDetailsCardTypes';

export function LocalClientJobClientDetailsCard(props: LocalClientJobClientDetailsCardProps) {
  return (
    <BaseClientDetailsCard
      {...props}
      cardStatus="primary"
      showClientSelect={false}
      showRateSelect={true}
    />
  );
}