import React, { useState, useEffect, useRef, useReducer, useCallback, useMemo, createContext, useContext } from 'react';
//@ts-ignore
import { useWindowDimensions, StyleSheet, View, TouchableOpacity, FlatList, Alert, ScrollView } from "react-native";
import { SafeAreaView } from 'react-native-safe-area-context';
//@ts-ignore
import { Divider, List, Icon, Layout, Text, TopNavigation, TopNavigationAction, Card, Button, Input, ButtonGroup, CheckBox, Modal, RangeDatepicker, Datepicker, useTheme, IndexPath, Select, SelectItem, Toggle, OverflowMenu, MenuItem, ListItem } from '@ui-kitten/components';
import ErrorBoundary from '../ErrorBoundary.component';
import * as JobsController from '../../functions/job.controller'
import * as MembershipsController from '../../functions/membership.controller'
import * as UpdateController from '../../functions/update.controller'
import * as ImageController from '../../functions/image.controller'
import * as ClientController from '../../functions/client.controller'
import * as StorageController from '../../functions/storageController'
import * as CompanyController from '../../functions/company.controller'
import * as GoogleController from '../../functions/google.controller'
import { Job } from '../../models/Job.model'
import { ServiceAreaEdit } from './serviceAreaEdit.component'
//@ts-ignore
import { SketchPicker, CirclePicker, SwatchesPicker, SliderPicker, ChromePicker } from 'react-color'
//@ts-ignore
import debounce from 'lodash.debounce';
import { useNotification } from '../../context/notifications.context';
import { Company, CompanySavedAddress, CompanySettings, JobOptions, ScheduleSettings, SMSSettings, EmailSettings, Surcharge, ServiceArea, CompanyWebhookSettings, CompanyTheme, IServiceAreaCoord, CompanyAlerts } from '../../models/Company.model';
import { SavedAddresses } from './address/savedAddresses.component';
import { StripeSubscriptionSettings } from './stripeSubscriptionSettings.component';
import { PaymentSettings } from './paymentSettings.component';
import { CompanySubscription, SUBSCRIPTION_FEATURES } from '../../models/CompanySubscription.model';
import * as CompanySubscriptionController from '../../functions/CompanySubscription.controller'
import Tooltip from '../modals/Tooltip';
import { CompanySecret } from '../../models/CompanySecret.model';
import * as CompanySecretController from '../../functions/companySecret.controller'
import { useAppStateChange, IAction, STATE_ACTIONS } from '../../hooks/appStateChange.hook';


export const CompanySettingsContainer = ({ navigation }: any) => {

    const [company, setCompany] = useState({} as Company)
    const companyRef = useRef({} as Company)
    const [originalCompanyObj, setOriginalCompanyObj] = useState({} as Company)

    const [addressSearch, setAddressSearch] = React.useState({ term: '', fetchPredictions: false })
    const [predictions, setPredictions] = React.useState([] as google.maps.places.AutocompleteResponse["predictions"])
    const [showPredictions, setShowPredictions] = React.useState(false)
    const [showServiceAreaModal, setShowServiceAreaModal] = React.useState(false)
    const { showNotification } = useNotification();
    const [unsavedChanges, setUnsavedChanges] = useState(false)

    const [serviceAreaData, setServiceAreaData] = useState([] as IServiceAreaCoord[]);

    const [isError, setIsError] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')



    const handleCompanyChangeEvent = useCallback((action: IAction) => {
        onSetCompany(new Company({}), false)
        setUnsavedChanges(false)
        setServiceAreaData([])
        setTimeout(async () => {
            load()
        }, 100);
    }, [company]);

    const { dispatchEventStateChange } = useAppStateChange({
        onUpdatedSelectedCompany: handleCompanyChangeEvent,
    })




    // example of company.inventory
    // const inventory array = [
    //   { name: "BTY-0455", cost: 0.1 },
    //   { name: "Zoloft", cost: 3.5 },
    //   { name: "Car", cost: 6.5 },
    //   { name: "Cable02", cost: 8.5 },
    // ]

    const MENU_TABS = {
        NOTIFICATIONS: 'NOTIFICATIONS',
        JOB_OPTIONS: 'JOB_OPTIONS',
        COMPANY_INFO: 'COMPANY_INFO',
        PAYMENT_OPTIONS: 'PAYMENT_OPTIONS',
        OTHER: 'OTHER',
        SUBSCRIPTION: 'SUBSCRIPTION'
    }

    const [selectedMenuTab, setSelectedMenuTab] = useState(MENU_TABS.JOB_OPTIONS)

    const RenderMenuTabs = () => {
        return (
            <View style={{ flexDirection: 'column' }}>
                <Button
                    onPress={() => setSelectedMenuTab(MENU_TABS.JOB_OPTIONS)}
                    appearance={selectedMenuTab == MENU_TABS.JOB_OPTIONS ? 'filled' : 'outline'}
                    status={selectedMenuTab == MENU_TABS.JOB_OPTIONS ? 'primary' : 'basic'}
                >
                    Job Options
                </Button>
                <Button
                    onPress={() => setSelectedMenuTab(MENU_TABS.NOTIFICATIONS)}
                    appearance={selectedMenuTab == MENU_TABS.NOTIFICATIONS ? 'filled' : 'outline'}
                    status={selectedMenuTab == MENU_TABS.NOTIFICATIONS ? 'primary' : 'basic'}
                >
                    Notifications
                </Button>
                <Button
                    onPress={() => setSelectedMenuTab(MENU_TABS.COMPANY_INFO)}
                    appearance={selectedMenuTab == MENU_TABS.COMPANY_INFO ? 'filled' : 'outline'}
                    status={selectedMenuTab == MENU_TABS.COMPANY_INFO ? 'primary' : 'basic'}
                >
                    Company Info
                </Button>
                <Button
                    onPress={() => setSelectedMenuTab(MENU_TABS.PAYMENT_OPTIONS)}
                    appearance={selectedMenuTab == MENU_TABS.PAYMENT_OPTIONS ? 'filled' : 'outline'}
                    status={selectedMenuTab == MENU_TABS.PAYMENT_OPTIONS ? 'primary' : 'basic'}
                >
                    Payment Options
                </Button>
                <Button
                    onPress={() => setSelectedMenuTab(MENU_TABS.OTHER)}
                    appearance={selectedMenuTab == MENU_TABS.OTHER ? 'filled' : 'outline'}
                    status={selectedMenuTab == MENU_TABS.OTHER ? 'primary' : 'basic'}
                >
                    Other
                </Button>
                <Button
                    onPress={() => setSelectedMenuTab(MENU_TABS.SUBSCRIPTION)}
                    appearance={selectedMenuTab == MENU_TABS.SUBSCRIPTION ? 'filled' : 'outline'}
                    status={selectedMenuTab == MENU_TABS.SUBSCRIPTION ? 'primary' : 'basic'}
                >
                    Subscription
                </Button>
            </View>
        )
    }






    useEffect(() => {
        const unsubscribe = navigation.addListener('focus', () => {
            load();
        });

        // Return the function to unsubscribe from the event so it gets removed on unmount
        return unsubscribe;
    }, [navigation]);



    const load = async () => {
        let id = StorageController.getCurrentCompany()._id
        let company = await CompanyController.getCompanyById(id) as Company
        let companySubscription = await CompanySubscriptionController.getActiveSubscriptionByCompanyId(id)
        if (!company) return
        company.company_subscription = new CompanySubscription(companySubscription)
        company = new Company(company)
        onSetCompany(company, false)
        setOriginalCompanyObj(company)


        // load the service_area[0] to data
        if (company.settings.service_areas && company.settings.service_areas.length > 0) {
            setServiceAreaData(company.settings.service_areas[0].coords)
        }

    }


    const onSetCompany = async (company: Company, changes = true) => {
        company = new Company(company)
        companyRef.current = company
        setCompany(company)
        if (changes) {
            setUnsavedChanges(true)
        }
    }


    const companySecretRef = useRef({} as CompanySecret)
    const unsavedCompanySecretChangesRef = useRef(false)
    const onSetCompanySecret = async (nextValue: CompanySecret) => {
        setUnsavedChanges(true)
        unsavedCompanySecretChangesRef.current = true
        companySecretRef.current = nextValue
    }



    const onSetAlertEnabled = async (nextChecked: boolean) => {
        company.updateScheduleSettings({ alertEnabled: nextChecked })
        onSetCompany(company)
        // onSetCompany({...company, settings: {...company.settings, scheduleAlertEnabled: nextChecked } })
    }

    const onSetAlertTime = async (nextValue: any) => {
        company.updateScheduleSettings({ alertTime: nextValue })
        onSetCompany(company)
        // onSetCompany({...company, settings: {...company.settings, scheduleAlertTime: nextValue } })
    }

    // Allow field to be empty or numbers
    const onSetJobsPerDriver = async (nextValue: string) => {
        try {
            let num = parseInt(nextValue)
            if (isNaN(num)) {
                num = 1
            }
            company.updateDriverSettings({ concurrent_jobs_per_driver: num })
            onSetCompany(company)
        } catch (err) {
        }
    }

    const onSetEnableConcurrentJobsPerDriverLimit = (nextChecked: boolean) => {
        company.updateDriverSettings({ enable_concurrent_jobs_per_driver_limit: nextChecked })
        onSetCompany(company)
    }


    //setting that is a location of company as an address
    const onSetAddress = async (result: any) => {
        //console.log("==== Address", result)
        setShowPredictions(false)
        const coords = await GoogleController.getPredictionsPlace(result.place_id)
        //console.log("===coords", coords)
        let location = {
            address: result.description,
            coords: {
                lat: coords.result.geometry.location.lat,
                lng: coords.result.geometry.location.lng
            }
        }
        //console.log("===location", location)
        onSetCompany({ ...company, settings: { ...company.settings, location: location } } as Company)
    }

    const onSave = async () => {
        // set the company that matches the id in storage controller
        let result = await CompanyController.updateCompany(company)
        //console.log("result", result)
        if (result.status == 200) {
            showNotification('Notice', 'Company Settings Saved');
            setUnsavedChanges(false)
            setOriginalCompanyObj(company)
        }
        else {
            showNotification('Error', 'Company Settings Failed to Save, try again later or refresh..');
        }
        if (unsavedCompanySecretChangesRef.current) {
            const secretSaveResult = await CompanySecretController.updateCompanySecret(companySecretRef.current)
            console.log("🚀============== ~ file: companySettings.component.tsx:258 ~ onSave ~ secretSaveResult🚀==============", secretSaveResult)
            unsavedCompanySecretChangesRef.current = false
        }
    }

    const onCancel = async () => {
        onSetCompany(originalCompanyObj, false)
        setUnsavedChanges(false)
    }


    ///////////////////////////////////////
    // ADDRESS
    ///////////////////////////////////////




    const onChangeText = async (term: string) => {
        const res = await GoogleController.getpredictionsAutocomplete(term)
        if (!res) return
        setPredictions(res.predictions)
        setShowPredictions(true)
    }
    const debouncedSave = useDebounce((nextValue: any) => onChangeText(nextValue), 1000);

    function useDebounce(callback: any, delay: number) {
        const debouncedFn = useCallback(
            debounce((...args: any) => callback(...args), delay),
            [delay] // will recreate if delay changes
        );
        return debouncedFn;
    }

    const AddressSearch = () => {
        return (
            <Input
                placeholder='Search Address'
                style={{ padding: 10 }
                }
                label='Search Address'
                value={addressSearch.term}
                onChangeText={(text: string) => {
                    setAddressSearch({ term: text, fetchPredictions: true })
                    debouncedSave(addressSearch.term)
                }}
            />
        )
    }




    ///////////////////////////////////////
    // Service Area
    ///////////////////////////////////////
    const serviceAreaDataRef = useRef(serviceAreaData)
    const [selectedServiceAreaIndex, setSelectedServiceAreaIndex] = useState(0)
    const [showConfirmServiceAreaDeleteModal, setShowConfirmServiceAreaDeleteModal] = useState(false)

    const RenderServiceAreaDeleteModal = () => {
        return (
            <Modal
                visible={showConfirmServiceAreaDeleteModal}
                backdropStyle={styles.backdrop}
                onBackdropPress={() => setShowConfirmServiceAreaDeleteModal(false)}>
                <Card disabled={true}>
                    <Text>Are you sure you want to delete this service area ? </Text>
                    <Button onPress={() => onDeleteServiceArea(selectedServiceAreaIndex)} status="danger" > Delete </Button>
                    <Button onPress={() => setShowConfirmServiceAreaDeleteModal(false)} status="basic" > Cancel </Button>
                </Card>
            </Modal>
        )
    }

    const onServiceAreaSave = async (serviceArea: any) => {
        let newServiceAreas = company.settings?.service_areas
        //@ts-ignore
        newServiceAreas[selectedServiceAreaIndex] = serviceArea
        onSetCompany({ ...company, settings: { ...company.settings, service_areas: newServiceAreas } } as Company)
        setShowServiceAreaModal(false)
    }

    const confirmDeleteServiceArea = async (index: number) => {
        setSelectedServiceAreaIndex(index)
        setShowConfirmServiceAreaDeleteModal(true)
    }

    const onDeleteServiceArea = async (index: number) => {
        let newServiceAreas = company.settings.service_areas
        newServiceAreas.splice(index, 1)
        onSetCompany({ ...company, settings: { ...company.settings, service_areas: newServiceAreas } } as Company)
    }


    const onEditServiceArea = async (index: number) => {
        setSelectedServiceAreaIndex(index)
        setShowServiceAreaModal(true)
    }

    const onAddNewServiceArea = async () => {
        let serviceArea = new ServiceArea()
        let newServiceAreas = company.settings.service_areas as ServiceArea[]
        newServiceAreas.push(serviceArea)
        onSetCompany({ ...company, settings: { ...company.settings, service_areas: newServiceAreas } } as Company)
    }

    const RenderServiceAreaModal = () => {
        return (
            <Modal
                visible={showServiceAreaModal}
                backdropStyle={styles.backdrop}
                style={{ width: '90%', height: '90%' }
                }
                onBackdropPress={() => setShowServiceAreaModal(false)}>
                <ServiceAreaEdit
                    fn_onSave={(serviceArea: ServiceArea) => onServiceAreaSave(serviceArea)}
                    fn_onCancel={() => setShowServiceAreaModal(false)}
                    var_serviceArea={company.settings?.service_areas?.[selectedServiceAreaIndex]}
                />
                {/* <Button onPress={() => setShowServiceAreaModal(false)}>Close</Button> */}
            </Modal>
        )
    }


    ///////////////////////////////////////
    // SETTINGS CHECKBOXES
    ///////////////////////////////////////
    const SettingsCheckBox = ({ checked, onChange, label }: any) => (
        <CheckBox
            checked={checked || false}
            onChange={onChange}
        >
            {label}
        </CheckBox>
    );
    const CardWithCheckboxes = ({ headerText, settings = {}, settingKey, setCompany, checkBoxes }: any) => {
        // Ensure the settingKey exists, otherwise initialize as empty object
        const settingValues = settings[settingKey] || {};
        return (
            <Card disabled header={() => <Text category='h6' > {headerText} </Text>} status="basic">
                <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
                    {
                        checkBoxes.map(({ label, key }: any) => (
                            <View key={key} style={{ margin: 5, width: "25%" }} >
                                <SettingsCheckBox
                                    label={label}
                                    checked={settingValues[key] || false}
                                    onChange={(nextChecked: boolean) => setCompany({
                                        ...company, settings: {
                                            ...company.settings, [settingKey]: {
                                                ...settingValues,
                                                [key]: nextChecked
                                            }
                                        }
                                    })}
                                />
                            </View>
                        ))}
                </View>
            </Card >
        );
    };


    return (
        <View
            key={company?._id}
            style={{ flex: 1, flexDirection: 'row' }}>
            <RenderServiceAreaModal />
            <RenderServiceAreaDeleteModal />
            <View style={{ flex: 1, borderRightWidth: 1, borderColor: 'black' }}>
                <RenderMenuTabs />
            </View>
            <View style={{ flex: 5 }}>
                <View style={{ flexDirection: 'row', backgroundColor: 'rgba(0,0,0,0.3)' }}>
                    <View style={{ flex: 1 }}>
                        {
                            unsavedChanges ? <Text style={{ color: 'red' }
                            } > Unsaved Changes </Text> : <Text></Text >
                        }
                    </View>
                    <View style={{ flex: 3 }}>
                    </View>
                    <View style={{ flex: 1 }}>
                        <Button
                            onPress={onSave}
                            accessoryLeft={(props: any) => <Icon {...props} name="save-outline" />}
                            status="success"
                        >
                            Save
                        </Button>
                    </View>
                    <View style={{ flex: 1 }}>
                        <Button
                            onPress={onCancel}
                            accessoryLeft={(props: any) => <Icon {...props} name="close-outline" />}
                            status="warning"
                        >
                            Cancel
                        </Button>
                    </View>
                </View>
                <ScrollView style={{ flex: 1 }}>
                    <Layout style={{ flex: 1, flexDirection: 'row' }}>


                        {selectedMenuTab == MENU_TABS.JOB_OPTIONS &&
                            <View style={{ flex: 1, gap: 10 }}>
                                <View style={{ flexDirection: 'row', gap: 5 }}>
                                    <Card
                                        disabled
                                        header={() => <Text category='h6' > Scheduling </Text>}
                                        status="basic"
                                    >
                                        <Toggle
                                            checked={company.settings?.scheduleSettings?.alertEnabled}
                                            onChange={(nextChecked: boolean) => onSetAlertEnabled(nextChecked)}
                                        >
                                            Schedule Alert Enabled
                                        </Toggle>
                                        <Input
                                            label="Schedule Alert Time"
                                            placeholder="Schedule Alert Time"
                                            defaultValue={company.settings?.scheduleSettings?.alertTime}
                                            // value={company.settings?.scheduleAlertTime}
                                            onChangeText={(nextValue: boolean) => onSetAlertTime(nextValue)}
                                        />
                                    </Card>

                                    <Card
                                        disabled
                                        header={() => <Text category='h6' > Job Options </Text>}
                                        status="basic"
                                    >
                                        <View style={{ flexDirection: 'column' }}>
                                            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                                {/* SMS checkboxes*/}
                                                <Text category='s1' > Show Roadside Job Options </Text>
                                                <Toggle
                                                    checked={company.settings?.job_options?.showRoadsideJobOptions || false}
                                                    onChange={(nextChecked: boolean) =>
                                                        onSetCompany({
                                                            ...company, settings: {
                                                                ...company.settings, job_options: {
                                                                    ...(company.settings?.job_options || {}),
                                                                    showRoadsideJobOptions: nextChecked
                                                                }
                                                            }
                                                        } as Company)
                                                    }
                                                >

                                                </Toggle>
                                            </View>
                                            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                                <Text category='s1' > Show Transport Job Options </Text>
                                                <Toggle
                                                    checked={company.settings?.job_options?.showTransportJobOptions || false}
                                                    onChange={(nextChecked: boolean) =>
                                                        onSetCompany({
                                                            ...company, settings: {
                                                                ...company.settings, job_options: {
                                                                    ...(company.settings?.job_options || {}),
                                                                    showTransportJobOptions: nextChecked
                                                                }
                                                            }
                                                        } as Company)
                                                    }
                                                >
                                                </Toggle>
                                            </View>
                                            {/* Towing options */}
                                            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                                <Text category='s1' > Show Towing Job Options </Text>
                                                <Toggle
                                                    checked={company.settings?.job_options?.showTowingJobOptions || false}
                                                    onChange={(nextChecked: boolean) =>
                                                        onSetCompany({
                                                            ...company, settings: {
                                                                ...company.settings, job_options: {
                                                                    ...(company.settings?.job_options || {}),
                                                                    showTowingJobOptions: nextChecked
                                                                }
                                                            }
                                                        } as Company)
                                                    }
                                                >
                                                </Toggle>
                                            </View>
                                        </View>
                                    </Card>
                                </View>
                                <View style={{ flexDirection: 'row', gap: 5 }}>

                                    <Card
                                        disabled
                                        header={() => <Text category='h6' > Driver App Options </Text>}
                                        status="basic"
                                    >
                                        <Layout style={{ flex: 1, flexDirection: 'column' }}>
                                            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                                <Toggle
                                                    checked={company.settings?.driver_settings?.allow_driver_create_job || false}
                                                    onChange={(nextChecked: boolean) => {
                                                        company.updateDriverSettings({ allow_driver_create_job: nextChecked })
                                                        onSetCompany(company)
                                                    }}
                                                >
                                                    Allow Driver To Create Jobs In App
                                                </Toggle>
                                            </View>
                                            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                                <Toggle
                                                    checked={company.settings?.app_options?.show_signatures || false}
                                                    onChange={(nextChecked: boolean) => {
                                                        company.settings.app_options.show_signatures = nextChecked
                                                        company.settings.app_options.show_post_inspection_signatures = nextChecked
                                                        company.settings.app_options.show_pre_inspection_signatures = nextChecked
                                                        onSetCompany(company)
                                                    }}
                                                >
                                                    Show Signatures
                                                </Toggle>
                                            </View>
                                            {company.settings?.app_options?.show_signatures &&
                                                <Card>
                                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                                        <Toggle
                                                            checked={company.settings?.app_options?.show_pre_inspection_signatures || false}
                                                            onChange={(nextChecked: boolean) => {
                                                                company.settings.app_options.show_pre_inspection_signatures = nextChecked
                                                                onSetCompany(company)
                                                            }}
                                                        >
                                                            Show Pre-Inspection Signatures
                                                        </Toggle>
                                                    </View>
                                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                                        <Toggle
                                                            checked={company.settings?.app_options?.show_post_inspection_signatures || false}
                                                            onChange={(nextChecked: boolean) => {
                                                                company.settings.app_options.show_post_inspection_signatures = nextChecked
                                                                onSetCompany(company)
                                                            }}
                                                        >
                                                            Show Post-Inspection Signatures
                                                        </Toggle>
                                                    </View>
                                                </Card>
                                            }
                                            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                                <Toggle
                                                    checked={company.settings?.app_options?.show_vehicle_indemnity || false}
                                                    onChange={(nextChecked: boolean) => {
                                                        company.settings.app_options.show_vehicle_indemnity = nextChecked
                                                        onSetCompany(company)
                                                    }}
                                                >
                                                    Show Vehicle Indemnity
                                                </Toggle>
                                            </View>
                                            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                                <Toggle
                                                    checked={company.settings?.app_options?.show_services || false}

                                                    onChange={(nextChecked: boolean) => {
                                                        company.settings.app_options.show_services = nextChecked
                                                        onSetCompany(company)
                                                    }}
                                                >
                                                    Show Services
                                                </Toggle>
                                            </View>
                                            {company.settings?.app_options?.show_services &&
                                                <Card>
                                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                                        <Toggle
                                                            checked={company.settings?.app_options?.allow_edit_services || false}
                                                            onChange={(nextChecked: boolean) => {
                                                                company.settings.app_options.allow_edit_services = nextChecked
                                                                onSetCompany(company)
                                                            }}
                                                        >
                                                            Allow Edit Services
                                                        </Toggle>
                                                    </View>
                                                </Card>
                                            }
                                            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                                <Toggle
                                                    checked={company.settings?.app_options?.show_inventory || false}
                                                    onChange={(nextChecked: boolean) => {
                                                        company.settings.app_options.show_inventory = nextChecked
                                                        onSetCompany(company)
                                                    }}
                                                >
                                                    Show Inventory
                                                </Toggle>
                                            </View>
                                            {company.settings?.app_options?.show_inventory &&
                                                <Card>
                                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                                        <Toggle
                                                            checked={company.settings?.app_options?.allow_edit_inventory || false}
                                                            onChange={(nextChecked: boolean) => {
                                                                company.settings.app_options.allow_edit_inventory = nextChecked
                                                                onSetCompany(company)
                                                            }}
                                                        >
                                                            Allow Edit Inventory
                                                        </Toggle>
                                                    </View>
                                                </Card>
                                            }
                                            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                                <Toggle
                                                    checked={company.settings?.app_options?.show_payment || false}
                                                    onChange={(nextChecked: boolean) => {
                                                        company.settings.app_options.show_payment = nextChecked

                                                        onSetCompany(company)
                                                    }}
                                                >
                                                    Show Payment
                                                </Toggle>
                                            </View>
                                            {company.settings?.app_options?.show_payment &&
                                                <Card>
                                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                                        <Toggle
                                                            checked={company.settings?.app_options?.allow_edit_payment || false}
                                                            onChange={(nextChecked: boolean) => {
                                                                company.settings.app_options.allow_edit_payment = nextChecked
                                                                onSetCompany(company)
                                                            }}
                                                        >
                                                            Allow Edit Payment
                                                        </Toggle>
                                                    </View>
                                                </Card>
                                            }
                                            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                                <Toggle
                                                    checked={company.settings?.app_options?.require_driver_comments || false}
                                                    onChange={(nextChecked: boolean) => {
                                                        company.settings.app_options.require_driver_comments = nextChecked
                                                        onSetCompany(company)
                                                    }}
                                                >
                                                    Require Driver Comments
                                                </Toggle>
                                            </View>
                                            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                                <Toggle
                                                    checked={company.settings?.app_options?.show_images || false}
                                                    onChange={(nextChecked: boolean) => {
                                                        company.settings.app_options.show_images = nextChecked
                                                        onSetCompany(company)
                                                    }}
                                                >
                                                    Show Images
                                                </Toggle>
                                            </View>
                                            {company.settings?.app_options?.show_images &&
                                                <Card>
                                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                                        <Toggle
                                                            checked={company.settings?.app_options?.require_pre_inspection_images || false}
                                                            onChange={(nextChecked: boolean) => {
                                                                company.settings.app_options.require_pre_inspection_images = nextChecked
                                                                onSetCompany(company)
                                                            }}
                                                        >
                                                            Require Pre Inspection Images
                                                        </Toggle>
                                                    </View>
                                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                                        <Toggle
                                                            checked={company.settings?.app_options?.require_post_inspection_images || false}
                                                            onChange={(nextChecked: boolean) => {
                                                                company.settings.app_options.require_post_inspection_images = nextChecked
                                                                onSetCompany(company)
                                                            }}
                                                        >
                                                            Require Post Inspection Images
                                                        </Toggle>
                                                    </View>
                                                </Card>
                                            }
                                            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                                <Toggle
                                                    checked={company.settings?.app_options?.allow_edit_customer_details || false}
                                                    onChange={(nextChecked: boolean) => {
                                                        company.settings.app_options.allow_edit_customer_details = nextChecked
                                                        onSetCompany(company)
                                                    }}
                                                >
                                                    Allow Edit Customer Details
                                                </Toggle>
                                            </View>
                                            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                                <Toggle
                                                    checked={company.settings?.app_options?.allow_edit_vehicle_details || false}
                                                    onChange={(nextChecked: boolean) => {
                                                        company.settings.app_options.allow_edit_vehicle_details = nextChecked
                                                        onSetCompany(company)
                                                    }}
                                                >
                                                    Allow Edit Vehicle Details
                                                </Toggle>
                                            </View>



                                            {/* company.settings.driver_settings.allow_driver_create_job */}
                                        </Layout>
                                    </Card>

                                    <Card
                                        disabled
                                        header={() => <Text category='h6' > Service Settings </Text>}
                                        status="basic"
                                    >
                                        <Layout style={{ flex: 1, flexDirection: 'column' }}>
                                            <Toggle
                                                checked={company.settings?.driver_settings?.enable_concurrent_jobs_per_driver_limit || false}
                                                onChange={(nextChecked: boolean) => {
                                                    onSetEnableConcurrentJobsPerDriverLimit(nextChecked)
                                                }
                                                }
                                            >
                                                Enable Concurrent Jobs Per Driver Limit
                                            </Toggle>
                                            {
                                                company.settings?.driver_settings?.enable_concurrent_jobs_per_driver_limit &&
                                                <Layout style={{ flexDirection: 'row' }}>
                                                    <Input
                                                        label="Jobs Per Driver"
                                                        placeholder="Jobs Per Driver"
                                                        inputType="number"
                                                        defaultValue={company.settings?.driver_settings.concurrent_jobs_per_driver.toString() || 1}
                                                        onChangeText={(nextValue: string) => {
                                                            onSetJobsPerDriver(nextValue)
                                                        }}
                                                    />
                                                </Layout>
                                            }
                                        </Layout>
                                    </Card>
                                </View>
                                <View style={{ flexDirection: 'row' }}>
                                    <View style={{ flex: 1 }}>

                                        <CardWithCheckboxes
                                            headerText="Create Job Mandatory Fields"
                                            settings={company.settings}
                                            settingKey="create_job_mandatory_fields"
                                            setCompany={onSetCompany}
                                            checkBoxes={
                                                [
                                                    { label: 'Customer Name', key: 'customer_name' },
                                                    { label: 'Customer Phone', key: 'customer_phone' },
                                                    { label: 'Customer Email', key: 'customer_email' },
                                                    // vehicle
                                                    { label: 'Rego', key: 'vehicle_rego' },
                                                    { label: 'Make', key: 'vehicle_make' },
                                                    { label: 'Model', key: 'vehicle_model' },
                                                    { label: 'Colour', key: 'vehicle_colour' },
                                                    { label: 'Type', key: 'vehicle_type' },
                                                    { label: 'Year', key: 'vehicle_year' },
                                                    { label: 'VIN', key: 'vehicle_vin' },
                                                    // client
                                                    { label: 'Client Reference #', key: 'client_reference_id' },
                                                ]}
                                        />
                                    </View>
                                    <View style={{ flex: 1 }}> </View>
                                </View>
                            </View>
                        }
                        {
                            selectedMenuTab == MENU_TABS.NOTIFICATIONS &&
                            <View style={{ flex: 1, flexDirection: 'column', gap: 10 }}>
                                <View style={{ flexDirection: 'row', gap: 5 }}>
                                    <View style={{ flex: 1 }}>
                                        <CardWithCheckboxes
                                            headerText="Customer Emails"
                                            settings={company.settings}
                                            settingKey="email"
                                            setCompany={onSetCompany}
                                            checkBoxes={
                                                [
                                                    { label: 'Send Created Email', key: 'customer_sendCreatedEmail' },
                                                    // { label: 'Send Assigned Email', key: 'customer_sendDriverAssignedEmail' },
                                                    // { label: 'Send En Route Email', key: 'customer_sendDriverEnRouteEmail' },
                                                    // { label: 'Send Arrived Email', key: 'customer_sendDriverArrivedEmail' },
                                                    // { label: 'Send Driver Complete Email', key: 'customer_sendDriverCompleteEmail' },
                                                    { label: 'Send Complete Email', key: 'customer_sendCompleteEmail' },
                                                    // { label: 'Send Cancelled Email', key: 'customer_sendCancelledEmail' },
                                                ]}
                                        />
                                    </View>
                                    <View style={{ flex: 1 }}>
                                        <CardWithCheckboxes
                                            headerText="Client Emails"
                                            settings={company.settings}
                                            settingKey="email"
                                            setCompany={onSetCompany}
                                            checkBoxes={
                                                [
                                                    { label: 'Send Created Email', key: 'client_sendCreatedEmail' },
                                                    // { label: 'Send Assigned Email', key: 'client_sendDriverAssignedEmail' },
                                                    // { label: 'Send En Route Email', key: 'client_sendDriverEnRouteEmail' },
                                                    // { label: 'Send Arrived Email', key: 'client_sendDriverArrivedEmail' },
                                                    // { label: 'Send Driver Complete Email', key: 'client_sendDriverCompleteEmail' },
                                                    { label: 'Send Completed Email', key: 'client_sendCompletedEmail' },
                                                    // { label: 'Send Cancelled Email', key: 'client_sendCancelledEmail' },
                                                    { label: "Include Job status link in Email", key: "include_job_status" }
                                                ]}
                                        />
                                    </View>
                                </View>

                                <View style={{ flexDirection: 'row' }}>
                                    <View style={{ flex: 1 }}>

                                        <CardWithCheckboxes
                                            headerText="SMS options"
                                            settings={company.settings}
                                            settingKey="sms"
                                            setCompany={onSetCompany}
                                            checkBoxes={
                                                [
                                                    // { label: 'Send Created SMS', key: 'sendCreatedSMS' },
                                                    // { label: 'Send Assigned SMS', key: 'sendAssignedSMS' },
                                                    { label: 'Send En Route SMS', key: 'sendEnRouteSMS' },
                                                    // { label: 'Send Completed SMS', key: 'sendCompletedSMS' },
                                                    // { label: 'Send Cancelled SMS', key: 'sendCancelledSMS' },
                                                    { label: "Include Job status link in SMS", key: "include_job_status" }
                                                ]}
                                        />
                                    </View>
                                    <View style={{ flex: 1 }}> </View>
                                </View>
                                <View style={{ flexDirection: 'row', gap: 5 }}>
                                    <Card
                                        disabled
                                        header={() => <Text category='h6' > Admin Emails </Text>}
                                        status="basic"
                                        style={{ flex: 1 }}
                                    >
                                        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>

                                            <Toggle checked={company.settings?.email?.enable_admin_notify_job_request || false}
                                                onChange={(nextChecked: boolean) => {
                                                    onSetCompany({
                                                        ...company, settings: {
                                                            ...company.settings, email: {
                                                                ...company.settings?.email, enable_admin_notify_job_request: nextChecked
                                                            }
                                                        }
                                                    } as Company)
                                                }}
                                            >
                                                Enable Admin Notify Job Request
                                            </Toggle>
                                            <View style={{ flex: 1 }}></View>
                                        </View>
                                        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                            <View style={{ flex: 1 }}>
                                                {
                                                    company.settings?.email?.enable_admin_notify_job_request &&
                                                    <Input
                                                        label="Admin Email Address"
                                                        placeholder="Admin Email Address"
                                                        defaultValue={company.settings?.email?.admin_notify_job_request_email}
                                                        onChangeText={(nextValue: string) => {
                                                            onSetCompany({
                                                                ...company, settings: {
                                                                    ...company.settings, email: {
                                                                        ...company.settings?.email, admin_notify_job_request_email: nextValue
                                                                    }
                                                                }
                                                            } as Company)
                                                        }}
                                                    />
                                                }
                                            </View>
                                            <View style={{ flex: 1 }}>

                                            </View>
                                        </View>
                                    </Card>
                                    <View style={{ flex: 1 }}>
                                        <CompanyAlertOptions
                                            companyAlerts={company.settings?.alerts || new CompanyAlerts({})}
                                            onSetCompanyAlertOptions={(nextValue: CompanyAlerts) => {
                                                onSetCompany({ ...company, settings: { ...company.settings, alerts: nextValue } } as Company)
                                            }}
                                        />
                                    </View>
                                </View>
                            </View>
                        }
                        {
                            selectedMenuTab == MENU_TABS.COMPANY_INFO &&
                            <View style={{ flex: 1, flexDirection: 'row', flexWrap: 'wrap' }}>
                                <Card
                                    disabled
                                    header={() => <Text category='h6' > Saved Addresses | Address Book</Text>}
                                    status="basic"
                                >
                                    <View style={{ flexDirection: 'column' }}>
                                        <SavedAddresses
                                            company={company}
                                            onSave={(updatedAddressBook: CompanySavedAddress[]) => {
                                                onSetCompany({
                                                    ...company, settings: {
                                                        ...company.settings, address_book: updatedAddressBook
                                                    }
                                                } as Company)
                                            }
                                            }
                                        />
                                    </View>
                                </Card>
                                <Card
                                    disabled
                                    header={() => <Text category='h6' > Contact Info </Text>}
                                    status="basic"
                                >
                                    <View style={{ flexDirection: 'column' }}>
                                        {/* Contact info, phone, email */}
                                        <Input
                                            label="Contact Phone"
                                            placeholder="Contact Phone"
                                            defaultValue={company.settings?.contact_info.phone}
                                            onChangeText={
                                                (nextValue: string) =>
                                                    // company.settings.contact_info.contact_phone = nextValue
                                                    onSetCompany({
                                                        ...company, settings: {
                                                            ...company.settings, contact_info: {
                                                                ...(company.settings?.contact_info || {}),
                                                                phone: nextValue
                                                            }
                                                        }
                                                    } as Company)
                                            }
                                        />
                                        <Input
                                            label="Contact Email"
                                            placeholder="Contact Email"
                                            defaultValue={company.settings?.contact_info?.email}
                                            onChangeText={
                                                (nextValue: string) =>
                                                    onSetCompany({
                                                        ...company, settings: {
                                                            ...company.settings, contact_info: {
                                                                ...(company.settings?.contact_info || {}),
                                                                email: nextValue
                                                            }
                                                        }
                                                    } as Company)
                                            }
                                        />
                                    </View>
                                </Card>
                                <Card
                                    disabled={true}
                                    // style={{ flex: 1 }}
                                    status='basic'
                                    header={() => <Text category='h6' > Service Area Settings </Text>}
                                >
                                    <Layout style={{ flexDirection: "row" }}>
                                        <Layout style={{ flex: 1 }}>
                                            <Layout style={{}}>
                                                <Button onPress={() => onAddNewServiceArea()} status="success" > Add New Area </Button>
                                            </Layout>
                                            <Layout style={{}}>
                                                {
                                                    company.settings?.service_areas?.map((serviceArea: ServiceArea, index) => (
                                                        <Card key={index} disabled={true} >
                                                            <Layout style={{ flexDirection: 'row', justifyContent: 'space-between' }} >
                                                                <Text category='label' style={{ flex: 3 }} > {serviceArea?.name} </Text>
                                                                <View style={{ flexDirection: 'row', flex: 1 }}>
                                                                    <Button onPress={() => onEditServiceArea(index)} size='tiny' >
                                                                        <Icon name='edit-2-outline' width={15} height={15} status="info" />
                                                                    </Button>
                                                                    <Button onPress={() => confirmDeleteServiceArea(index)} size='tiny' status="danger" >
                                                                        <Icon name='trash-2-outline' width={15} height={15} />
                                                                    </Button>
                                                                </View>
                                                            </Layout>
                                                        </Card>
                                                    ))}
                                            </Layout>
                                        </Layout>
                                    </Layout>
                                </Card>
                                <Card
                                    disabled
                                    header={() => <Text category='h6' > Set Company Location </Text>}
                                    status="basic"
                                >
                                    <Layout style={{ flex: 1, flexDirection: 'column' }}>
                                        {/* set address input */}
                                        <OverflowMenu
                                            anchor={AddressSearch}
                                            visible={showPredictions}
                                            onBackdropPress={() => setShowPredictions(false)}
                                            style={{ width: '100%' }}
                                            fullWidth={true}
                                        >
                                            {
                                                showPredictions ? predictions.map((prediction: any, index) => (
                                                    <MenuItem
                                                        key={prediction?.place_id}
                                                        title={prediction?.description}
                                                        onPress={() => {
                                                            onSetAddress(prediction)
                                                        }}
                                                    />
                                                )) : ""}
                                        </OverflowMenu>
                                        <Input
                                            label="Address"
                                            placeholder="Address"
                                            disabled={true}
                                            defaultValue={company.settings?.location?.address}
                                        />
                                        <Layout style={{ flex: 1, flexDirection: 'row' }}>
                                            <Input
                                                label="Latitude"
                                                placeholder="Latitude"
                                                disabled={true}
                                                defaultValue={company.settings?.location?.coords?.lat}
                                            />
                                            <Input
                                                label="Longitude"
                                                placeholder="Longitude"
                                                disabled={true}
                                                defaultValue={company.settings?.location?.coords?.lng}
                                            />
                                        </Layout>
                                    </Layout>
                                </Card>
                                <Card
                                    disabled
                                    header={() => <Text category='h6' > Company Role </Text>}
                                    status="basic"
                                >
                                    <View style={{ flexDirection: 'column' }}>
                                        {company.company_subscription?.features[SUBSCRIPTION_FEATURES.SERVICE_NETWORK] &&
                                            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                                <Text category='h6' > Show Contracts </Text>
                                                <Toggle
                                                    checked={company.settings?.company_roles?.showContracts || false}
                                                    onChange={(nextChecked: boolean) =>
                                                        onSetCompany({
                                                            ...company, settings: {
                                                                ...company.settings, company_roles: {
                                                                    ...(company.settings?.company_roles || {}),
                                                                    showContracts: nextChecked
                                                                }
                                                            }
                                                        } as Company)
                                                    }
                                                >
                                                </Toggle>
                                            </View>
                                        }
                                        {/* Show Cases */}
                                        {company.company_subscription?.features[SUBSCRIPTION_FEATURES.CASE_MANAGEMENT] &&
                                            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                                <Text category='h6' > Show Cases </Text>
                                                <Toggle
                                                    checked={company.settings?.company_roles?.showCases || false}
                                                    onChange={(nextChecked: boolean) =>
                                                        onSetCompany({
                                                            ...company, settings: {
                                                                ...company.settings, company_roles: {
                                                                    ...(company.settings?.company_roles || {}),
                                                                    showCases: nextChecked
                                                                }
                                                            }
                                                        } as Company)
                                                    }
                                                >
                                                </Toggle>
                                            </View>
                                        }
                                    </View>
                                </Card>
                            </View>
                        }
                        {selectedMenuTab == MENU_TABS.PAYMENT_OPTIONS &&
                            <View style={{ flex: 1, gap: 10 }}>
                                <View style={{ flexDirection: 'row', gap: 5 }}>
                                    {/* Square payment options */}
                                    <PaymentSettings
                                        company={company}
                                        onSetCompany={onSetCompany}
                                        onSetCompanySecret={onSetCompanySecret}
                                    />
                                </View>
                            </View>

                        }
                        {
                            selectedMenuTab == MENU_TABS.OTHER &&
                            <View style={{ flex: 1, flexDirection: 'column', gap: 10 }}>
                                <View style={{ flexDirection: 'column', gap: 5 }}>
                                    {
                                    // company.company_subscription?.features[SUBSCRIPTION_FEATURES.API_ACCESS] || 
                                    true && //TODO: REMOVE THE TRUE

                                        <Card
                                            disabled
                                            header={() => <Text category='h6' >Webhook Settings </Text>}
                                            status="basic"
                                        >
                                            <View style={{ flexDirection: 'column' }}>
                                                <Toggle
                                                    checked={company.settings?.webhook_settings?.webhook_enabled || false}
                                                    onChange={(nextChecked: boolean) =>
                                                        onSetCompany({
                                                            ...company, settings: {
                                                                ...company.settings, webhook_settings: {
                                                                    ...(company.settings?.webhook_settings || {}),
                                                                    webhook_enabled: nextChecked
                                                                }
                                                            }
                                                        } as Company)
                                                    }
                                                >
                                                    Enable Webhook
                                                </Toggle>
                                                {
                                                    company.settings?.webhook_settings?.webhook_enabled &&
                                                    <>
                                                        <Text>
                                                            Include the webhook url to recieve notifications to your job updates.
                                                        </Text>
                                                        <Input
                                                            label="Webhook URL"
                                                            placeholder="Webhook URL"
                                                            defaultValue={company.settings?.webhook_settings?.webhook_url}
                                                            onChangeText={(nextValue: string) =>
                                                                onSetCompany({
                                                                    ...company, settings: {
                                                                        ...company.settings, webhook_settings: {
                                                                            ...(company.settings?.webhook_settings || {}),
                                                                            webhook_url: nextValue
                                                                        }
                                                                    }
                                                                } as Company)
                                                            }
                                                        />
                                                        <Input
                                                            label="Webhook Bearer Token"
                                                            placeholder="Bearer [Token]"
                                                            defaultValue={company.settings?.webhook_settings?.webhook_auth_bearer_token}
                                                            onChangeText={(nextValue: string) =>
                                                                onSetCompany({
                                                                    ...company, settings: {
                                                                        ...company.settings, webhook_settings: {
                                                                            ...(company.settings?.webhook_settings || {}),
                                                                            webhook_auth_bearer_token: nextValue
                                                                        }
                                                                    }
                                                                } as Company)
                                                            }
                                                        />
                                                        <Text style={{ marginTop: 8 }}>
                                                            If included, the bearer token will be included in the Authorization header as:
                                                            {"\n"}
                                                            <Text style={{ fontWeight: 'bold' }}>
                                                                Authorization: Bearer [your_token_here]
                                                            </Text>
                                                        </Text>
                                                        <Text>
                                                            Otherwise, Leave empty.
                                                        </Text>
                                                    </>
                                                }
                                            </View>
                                        </Card>
                                    }
                                    {/* Theme settings */}
                                    {
                                    // company.company_subscription?.features[SUBSCRIPTION_FEATURES.WHITE_LABEL] ||
                                     true && //TODO: REMOVE THE TRUE
                                        <RenderCompanyThemePicker company={company} onSetCompany={onSetCompany} />
                                    }
                                </View>
                            </View>
                        }
                        {
                            selectedMenuTab == MENU_TABS.SUBSCRIPTION &&
                            <StripeSubscriptionSettings company={company} onSetCompany={onSetCompany} />
                        }
                    </Layout>
                </ScrollView>
            </View>
        </View>
    )
}


// const CreateSurchargeModal = ({ visible, setVisible, onSave }: any) => {
//     const [surcharge, setSurcharge] = useState(new Surcharge({ name: '', amount: '', type: 'flat' }))

//     const onSetSurcharge = (surcharge: Surcharge) => {
//         setSurcharge(surcharge)
//     }


//     const save = async (surcharge: Surcharge) => {
//         // check if the surcharge has a name and amount
//         if (surcharge.name && surcharge.amount) {
//             // Check if amount is a number
//             if (isNaN(surcharge.amount)) {
//                 alert('Surcharge amount must be a number')
//                 return
//             }
//             onSave(surcharge)
//         }
//         else {
//             alert('Surcharge must have a name and amount')
//         }
//     }



//     return (
//         <Modal
//             visible={visible}
//             backdropStyle={styles.backdrop}
//             onBackdropPress={() => setVisible(false)}>
//             <Card disabled={true}>
//                 <Text>Surcharge Name</Text>
//                 <Input
//                     placeholder='Surcharge Name'
//                     value={surcharge.name}
//                     onChangeText={(nextValue: any) => onSetSurcharge({ ...surcharge, name: nextValue })}
//                 />
//                 <Text>Surcharge Amount</Text>
//                 <Input
//                     placeholder='Surcharge Amount'
//                     value={surcharge.amount}
//                     onChangeText={(nextValue: any) => onSetSurcharge({ ...surcharge, amount: nextValue })}
//                 />
//                 <Text>Surcharge Type</Text>
//                 {/* Toggle between flat or percentage */}
//                 <Toggle
//                     checked={surcharge.type == 'flat'}
//                     onChange={(nextChecked: boolean) => {
//                         let type = nextChecked ? 'flat' : 'percentage'
//                         onSetSurcharge({ ...surcharge, type: type })
//                     }}
//                 >
//                     {surcharge.type == 'flat' ? 'Flat' : 'Percentage'}
//                 </Toggle>
//                 <Button onPress={() => save(surcharge)} status="success" > Save </Button>
//                 <Button onPress={() => setVisible(false)} status="basic" > Cancel </Button>
//             </Card>
//         </Modal>
//     )
// }


interface CompanyThemePickerProps {
    company: Company
    onSetCompany: (company: Company) => void
}

const RenderCompanyThemePicker = ({ company, onSetCompany }: CompanyThemePickerProps) => {
    const [selectedTheme, setSelectedTheme] = useState(company.settings?.theme as CompanyTheme)
    const [currentTheme, setCurrentTheme] = useState(company.settings?.theme)
    const [urlError, setUrlError] = useState('')
    const brandUriInputState = useRef(null as any)

    useEffect(() => {
        setSelectedTheme(company.settings?.theme as CompanyTheme)
        setCurrentTheme(company.settings?.theme)
        if (brandUriInputState.current) {
            brandUriInputState.current.value = company.settings?.theme.brand_logo
        }
    }, [company])



    const onSetTheme = (theme: CompanyTheme) => {
        setSelectedTheme(theme)
        testUpdatedTheme(theme)
        onSaveCompanyTheme(theme)
    }

    const onSaveCompanyTheme = (theme: CompanyTheme) => {
        onSetCompany({
            ...company, settings: {
                ...company.settings, theme: theme
            }
        } as Company)
    }

    const onResetTheme = () => {
        const resetTheme = new CompanyTheme({})
        onSetTheme(resetTheme)
    }


    const testUpdatedTheme = (newTheme: CompanyTheme) => {
        UpdateController.dispatchEventStateChange({
            type: UpdateController.STATE_ACTIONS.UPDATED_COMPANY_THEME,
            data: newTheme
        })
    }

    const setIsLightTheme = (lightTheme: boolean) => {
        const theme = lightTheme ? 'light' : 'dark'
        onSetTheme(new CompanyTheme({ ...selectedTheme, light_dark: theme }))
    }

    const setBrandLogo = (logoUrl: string) => {
        // ensure the logo is a valid url
        if (!validateImageUrl(logoUrl)) {
            setUrlError('Invalid Image URL')
            return
        }
        setUrlError('')
        onSetTheme(new CompanyTheme({ ...selectedTheme, brand_logo: logoUrl }))
    }

    const validateImageUrl = (url: string) => {
        // just has to be a url... 
        return url.includes('http')
    }

    const [colourPickerType, setColourPickerType] = useState('swatches')

    const RenderColourPickerType = (themeProperty: string, themeValue: string) => {
        if (colourPickerType == 'swatches') {
            return <SwatchesPicker
                color={themeValue}
                onChange={(color: any) => onSetTheme(new CompanyTheme({ ...selectedTheme, [themeProperty]: color.hex }))}
            />
        }
        else {
            return <ChromePicker
                color={themeValue}
                onChange={(color: any) => onSetTheme(new CompanyTheme({ ...selectedTheme, [themeProperty]: color.hex }))}
            />
        }
    }



    return (
        <Card
            key={company._id}
            disabled
            header={() => <Text category='h6' > Theme </Text>}
            status="basic"
        >
            <Layout style={{ flexDirection: 'column', justifyContent: 'space-between' }}>
                {/* Toggle colour picker type */}

                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Card style={{ flex: 1 }} disabled>
                        <Text category='s1' > Dark/Light Theme </Text>
                        <Toggle
                            checked={selectedTheme.light_dark == 'light'}
                            onChange={(nextChecked: boolean) => setIsLightTheme(nextChecked)}
                        >
                        </Toggle>
                    </Card>
                    <Card style={{ flex: 3 }} disabled>
                        <Text category='s1' > Brand Logo </Text>
                        <Input
                            ref={brandUriInputState}
                            label="Brand Logo"
                            placeholder="Brand Logo"
                            defaultValue={selectedTheme.brand_logo}
                            onChangeText={(nextValue: string) => setBrandLogo(nextValue)}

                        />
                        <Text style={{ color: 'red' }}>{urlError}</Text>
                    </Card>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Text category='s1' > Colour Picker Type </Text>
                        <Toggle
                            checked={colourPickerType == 'swatches'}
                            onChange={(nextChecked: boolean) => setColourPickerType(nextChecked ? 'swatches' : 'chrome')}
                        >
                        </Toggle>
                    </View>
                </View>

                <Card
                    disabled
                    header={() => <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Text category='h6' > Colours </Text>
                        <Button onPress={() => onResetTheme()} status="danger" > Reset </Button>
                    </View>}
                >

                    {/* colour swatches for primary, secondary and accent colours */}
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <View style={{ flex: 1 }}>
                            <Text category='s1' > Primary </Text>
                            {RenderColourPickerType('primary', selectedTheme.primary)}
                        </View>
                        <View style={{ flex: 1 }}>

                            <Text category='s1' > Success </Text>
                            {RenderColourPickerType('success', selectedTheme.success)}
                        </View>
                    </View>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <View style={{ flex: 1 }}>
                            <Text category='s1' > Warning </Text>
                            {RenderColourPickerType('warning', selectedTheme.warning)}
                        </View>
                        <View style={{ flex: 1 }}>
                            <Text category='s1' > Danger </Text>
                            {RenderColourPickerType('danger', selectedTheme.danger)}
                        </View>
                    </View>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <View style={{ flex: 1 }}>
                            <Text category='s1' > Basic </Text>
                            {RenderColourPickerType('basic', selectedTheme.basic)}
                        </View>
                    </View>
                </Card>

            </Layout>

        </Card>
    )
}

interface CompanyAlertOptionsProps {
    companyAlerts: CompanyAlerts
    onSetCompanyAlertOptions: (companyAlerts: CompanyAlerts) => void
}

const CompanyAlertOptions = ({ companyAlerts, onSetCompanyAlertOptions }: CompanyAlertOptionsProps) => {

    const textCategory = 's2'
    return (
        <View style={{ flex: 1 }}>
            <Card
                disabled
                header={() => <Text category='h6' > Alerts </Text>}
                status="basic"
            >
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Toggle
                        checked={companyAlerts?.enable_over_eta_alerts ?? false}
                        onChange={(nextChecked: boolean) => {
                            onSetCompanyAlertOptions({
                                ...companyAlerts, enable_over_eta_alerts: nextChecked
                            })
                        }}
                    >
                        <Tooltip text="Alert popup when a job is over the ETA" >
                            <Text category={textCategory}>
                                Enable Over ETA Alerts
                            </Text>
                        </Tooltip>
                    </Toggle>
                </View>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Toggle
                        checked={companyAlerts?.enable_close_to_eta_alerts ?? false}
                        onChange={(nextChecked: boolean) => {
                            onSetCompanyAlertOptions({
                                ...companyAlerts, enable_close_to_eta_alerts: nextChecked
                            })
                        }}
                    >
                        <Tooltip text="Alert popup when a job is close to the ETA" >
                            <Text category={textCategory}>
                                Enable Close To ETA Alerts
                            </Text>
                        </Tooltip>
                    </Toggle>
                </View>
                {
                    companyAlerts?.enable_close_to_eta_alerts &&
                    <Card>

                        <Input
                            label="Close To ETA Threshold (Minutes)"
                            placeholder="Close To ETA Threshold (Minutes)"
                            defaultValue={companyAlerts?.close_to_eta_alert_time_theshold.toString()}
                            onChangeText={(nextValue: string) => {
                                onSetCompanyAlertOptions({
                                    ...companyAlerts, close_to_eta_alert_time_theshold: parseInt(nextValue)
                                })
                            }}
                        />
                    </Card>
                }
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>

                    <Toggle
                        checked={companyAlerts?.enable_unassigned_job_time_alerts ?? false}
                        onChange={(nextChecked: boolean) => {
                            onSetCompanyAlertOptions({
                                ...companyAlerts, enable_unassigned_job_time_alerts: nextChecked
                            })
                        }}
                    >
                        <Tooltip text="Alert popup when a job is unassigned for a certain time" >
                            <Text category={textCategory}>
                                Enable Unassigned Job Time Alerts
                            </Text>
                        </Tooltip>
                    </Toggle>
                </View>
                {companyAlerts?.enable_unassigned_job_time_alerts &&
                    <Card>

                        <Input
                            label="Unassigned Job Time Threshold (Minutes)"
                            placeholder="Unassigned Job Time Threshold (Minutes)"
                            defaultValue={companyAlerts?.unassigned_job_time_threshold.toString()}
                            onChangeText={(nextValue: string) => {
                                onSetCompanyAlertOptions({
                                    ...companyAlerts, unassigned_job_time_threshold: parseInt(nextValue)
                                })
                            }}
                        />
                    </Card>
                }
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>

                    <Toggle
                        checked={companyAlerts?.enable_assigned_driver_not_accepted_job_time_alerts ?? false}
                        onChange={(nextChecked: boolean) => {
                            onSetCompanyAlertOptions({
                                ...companyAlerts, enable_assigned_driver_not_accepted_job_time_alerts: nextChecked
                            })
                        }}
                    >
                        <Tooltip text="Alert popup when a job is assigned to a driver but not accepted for a certain time" >
                            <Text category={textCategory}>
                                Enable Assigned Driver Not Accepted Job Time Alerts
                            </Text>
                        </Tooltip>
                    </Toggle>
                </View>
                {companyAlerts?.enable_assigned_driver_not_accepted_job_time_alerts &&
                    <Card>

                        <Input
                            label="Assigned Driver Not Accepted Job Time Threshold (Minutes)"
                            placeholder="Assigned Driver Not Accepted Job Time Threshold (Minutes)"
                            defaultValue={companyAlerts?.assigned_driver_not_accepted_job_time_threshold.toString()}
                            onChangeText={(nextValue: string) => {
                                onSetCompanyAlertOptions({
                                    ...companyAlerts, assigned_driver_not_accepted_job_time_threshold: parseInt(nextValue)
                                })
                            }}
                        />
                    </Card>
                }
            </Card>
        </View>
    )
}



const styles = StyleSheet.create({
    backdrop: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
});