import React from 'react';
//@ts-ignore
import { View } from 'react-native';
import { Text, List, ListItem, useTheme } from "@ui-kitten/components";
import { Job, JOB_STATUS } from '../../models/Job.model';
import {Badge} from '../job/components/Badge.component';

interface AvailableJobsProps {
    availableJobs: Job[];
    onSelectJob: (job: Job) => void;
    isJobSelected: (job: Job) => boolean;
    getJobOrderBadge: (job: Job) => number | null;
}

export const AvailableJobs = ({ availableJobs, onSelectJob, isJobSelected, getJobOrderBadge }: AvailableJobsProps) => {



    //@ts-ignore
    const theme = useTheme() as any



    return (
        <View>
            <Text category='h6'>Available Jobs</Text>
            <List
                data={availableJobs}
                renderItem={({ item }: { item: Job }) => (
                    <ListItem
                        title={item.details.customer_details.name || `Job ${item._id}`}
                        description={`${item.details.address.addressToString()}`}
                        onPress={() => onSelectJob(item)}
                        style={{
                            backgroundColor: isJobSelected(item) ? theme['color-primary-100'] : 'transparent',
                        }}
                        accessoryLeft={() => {
                            const badgeNumber = getJobOrderBadge(item);
                            return (<Text>{badgeNumber ? badgeNumber : ''}</Text>)
                        }}
                        accessoryRight={() => (
                            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                <Badge status={item.status} />
                            </View>
                        )}
                    />
                )}
            />
        </View>
    );
};