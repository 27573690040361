import { Job } from "../../models/Job.model";
import { Holding } from "../../models/Holding.model";
import { Case } from "../../models/Case.model";


export class JobInvoiceRow extends Job {
    super = Job;
    id: number;
    itemsExpanded?: boolean;
    isSubRow?: boolean;
    isItemRow: boolean;
    itemCustomerCostTotal: number;
    itemBillAllBackTotal: number;
    itemTotal: number;
    total: string;
    cost: string;
    quantity: string;
    billAllBack: string;
    customerCost: string;
    invoiceNumber: string;
    invoiceDate: string;
    invoiceDueDate: string;
    invoiceTaxType: string;
    invoiceTax: string;
    invoiceTotal: string;
    invoiceStatus: string;
    description: string;
    isTotalRow: boolean;
    totalCount: number;
    clientRateTotal: number;
    totalPaid: number;
    cashAmount: number;
    cardAmount: number;

    constructor(job: Job) {
        super(job);
        this.id = 0;
        this.isSubRow = false;
        this.isItemRow = false;
        this.itemCustomerCostTotal = 0;
        this.itemBillAllBackTotal = 0;
        this.itemTotal = 0;
        this.total = '';
        this.cost = '';
        this.quantity = '';
        this.billAllBack = '';
        this.customerCost = '';
        this.invoiceNumber = '';
        this.invoiceDate = '';
        this.invoiceDueDate = '';
        this.invoiceTaxType = '';
        this.invoiceTax = '';
        this.invoiceTotal = '';
        this.invoiceStatus = '';
        this.description = '';
        this.isTotalRow = false;
        this.totalCount = 0;
        this.clientRateTotal = 0;
        this.totalPaid = 0;
        this.cashAmount = 0;
        this.cardAmount = 0;
    }
}

export class HoldingInvoiceRow extends Holding {
    super = Holding;
    id: number;
    itemsExpanded?: boolean;
    isSubRow?: boolean;
    isItemRow: boolean;
    itemCustomerCostTotal: number;
    itemBillAllBackTotal: number;
    itemTotal: number;
    total: string;
    cost: string;
    quantity: string;
    billAllBack: string;
    customerCost: string;
    invoiceNumber: string;
    invoiceDate: string;
    invoiceDueDate: string;
    invoiceTaxType: string;
    invoiceTax: string;
    invoiceTotal: string;
    invoiceStatus: string;
    description: string;
    isTotalRow: boolean;
    totalCount: number;
    clientRateTotal: number;
    totalPaid: number;
    cashAmount: number;
    cardAmount: number;

    constructor(holding: Holding) {
        super(holding);
        this.id = 0;
        this.isSubRow = false;
        this.isItemRow = false;
        this.itemCustomerCostTotal = 0;
        this.itemBillAllBackTotal = 0;
        this.itemTotal = 0;
        this.total = '';
        this.cost = '';
        this.quantity = '';
        this.billAllBack = '';
        this.customerCost = '';
        this.invoiceNumber = '';
        this.invoiceDate = '';
        this.invoiceDueDate = '';
        this.invoiceTaxType = '';
        this.invoiceTax = '';
        this.invoiceTotal = '';
        this.invoiceStatus = '';
        this.description = '';
        this.isTotalRow = false;
        this.totalCount = 0;
        this.clientRateTotal = 0;
        this.totalPaid = 0;
        this.cashAmount = 0;
        this.cardAmount = 0;
    }
}