import React, { useState, useEffect, useRef, useReducer, useMemo, useCallback } from 'react';
//@ts-ignore
import { useWindowDimensions, StyleSheet, View, TouchableOpacity, ScrollView } from "react-native";
import { SafeAreaView } from 'react-native-safe-area-context';
//@ts-ignore
import { Divider, Icon, Layout, Text, TopNavigation, TopNavigationAction, Card, Button, Input, ButtonGroup, CheckBox, Modal, RangeDatepicker, Datepicker } from '@ui-kitten/components';
import moment from 'moment';
import Slider, { SliderProps } from '@react-native-community/slider';
import * as StorageController from '../../functions/storageController'
import * as JobController from '../../functions/job.controller'
import * as InvoiceController from '../../functions/invoice.controller'
import * as UpdateController from '../../functions/update.controller'
import * as ServiceController from '../../functions/service.controller'
import ErrorBoundary from '../ErrorBoundary.component';
import _, { debounce } from 'lodash';


interface TimeFilterProps {
    startTime: number;
    endTime: number;
    onStartTimeChange: (time: string) => void;
    onEndTimeChange: (time: string) => void;
    filterJobsByTime: () => void;
}

export const TimeFilter = ({ startTime, endTime, onStartTimeChange, onEndTimeChange, filterJobsByTime }: TimeFilterProps) => {

    const [errorMsg, setErrorMsg] = useState<string>('');
    const [isError, setIsError] = useState<boolean>(false);

    const [start, setStart] = useState<number>(startTime);
    const [end, setEnd] = useState<number>(endTime);

    const [startTimeString, setStartTimeString] = useState<string>('00:00');
    const [endTimeString, setEndTimeString] = useState<string>('24:00');

    const debouncedFilterJobsByTime = useCallback(_.debounce(filterJobsByTime, 500), [filterJobsByTime]);


    const STEP = 10; // 10 minutes

    const formatTime = (minutes: number) => {
        const hours = Math.floor(minutes / 60);
        const mins = minutes % 60;
        return `${String(hours).padStart(2, '0')}:${String(mins).padStart(2, '0')}`;
    };
    const onSetStartTime = (time: number) => {
        const formattedTime = formatTime(time);
        setStartTimeString(formattedTime);
        if (time > end) {
            time = end - STEP;
        }
        onStartTimeChange(formattedTime);
        setStart(time);
    };

    const onSetEndTime = (time: number) => {
        const formattedTime = formatTime(time);
        setEndTimeString(formattedTime);
        if (time < start) {
            time = start + STEP;
        }
        onEndTimeChange(formattedTime);
        setEnd(time);
    };

    useEffect(() => {
        let error = '';
        if (start >= end) {
            error = 'End time must be after start time';
            setIsError(true);
            setErrorMsg(error);
        } else {
            setIsError(false);
            setErrorMsg('');
            debouncedFilterJobsByTime();
        }
    }, [start, end, debouncedFilterJobsByTime]);

    return (
        <Card
            disabled
            status='info'
            header={() => <Text category='s1'>Time Filter (24hr)</Text>}
        >
            <View style={{ margin: -15 }}>
                {isError && <Text status='danger'>{errorMsg}</Text>}
                <View style={{ marginVertical: 10 }}>
                    <Text>Start Time: {startTimeString}</Text>
                    {/* @ts-ignore */}
                    <Slider
                        minimumValue={0}
                        maximumValue={1440 - STEP}
                        step={STEP}
                        value={start}
                        upperLimit={end - STEP}
                        onValueChange={(value: number) => { setStartTimeString(formatTime(value)) }}
                        onSlidingComplete={onSetStartTime}
                        maximumTrackTintColor={isError ? 'red' : 'blue'}
                    />
                </View>
                <View style={{ marginVertical: 10 }}>
                    <Text>End Time: {endTimeString}</Text>
                    {/* @ts-ignore */}
                    <Slider
                        minimumValue={0 + STEP}
                        maximumValue={1440}
                        step={STEP}
                        value={end}
                        lowerLimit={start + STEP}
                        onValueChange={(value: number) => { setEndTimeString(formatTime(value)) }}
                        onSlidingComplete={onSetEndTime}
                        minimumTrackTintColor={isError ? 'red' : 'blue'}
                    />
                </View>
            </View>
        </Card>
    );
}