import React, { useState, useMemo, useRef, useEffect } from 'react';
import {
    Image,
    AccessibilityRole,
    ImageProps,
    ImageStyle,
    StyleSheet,
    TouchableOpacity,
    View,
    Text as RNText,
    Animated,
    ScrollView,
    Dimensions
    //@ts-ignore
} from "react-native";
import {
    ApplicationProvider,
    Button,
    Icon,
    IconRegistry,
    Modal,
    Layout,
    Text,
    List,
    ListItem,
    Divider,
    Card,
    useTheme
} from "@ui-kitten/components";
import { SafeAreaView } from 'react-native-safe-area-context';
import { EvaIconsPack } from "@ui-kitten/eva-icons";
import { mapping, light, dark } from "@eva-design/eva";
import * as HoldingController from "../../../functions/holding.controller"
import * as StorageController from "../../../functions/storageController"
import * as JobController from "../../../functions/job.controller"
import { ReportDetailsCard } from '../details/reportDetailsCard.component';
import { CreateJobFromHolding } from '../modals/createJobFromHoldingModal.component';
import { HoldingDetailsContainer } from '../holding/holdingDetailsContainer.component';
import Toast from 'react-native-toast-message';
import { Job, JobTowingDetails } from '../../../models/Job.model';
import { Holding } from '../../../models/Holding.model';
const { width, height } = Dimensions.get('window');



interface ICreateJobFromHoldingModalProps {
    holdingItem: Holding,
    holdingItemId: string,
    holdingJobId: string,
    fn_onClose: Function,
    fn_onCreateJob: (j: Job) => void
}

export const CreateJobFromHoldingModal = ({
    holdingItem,
    holdingItemId,
    holdingJobId,
    fn_onClose,
    fn_onCreateJob = (j: Job) => { }
}: ICreateJobFromHoldingModalProps) => {

    const [showCreateJobFromHoldingModal, setShowCreateJobFromHoldingModal] = useState(false)
    const [showRemoveFromHoldingOptionsModal, setShowRemoveFromHoldingOptionsModal] = useState(true)
    const [selectedHoldingItem, setSelectedHoldingItem] = useState(holdingItem)
    const selectedHoldingItemRef = useRef(holdingItem)
    const holdingItemIdRef = useRef(holdingItemId)
    const holdingJobIdRef = useRef(holdingJobId)

    useEffect(() => {
        console.log("🚀============== ~ file: createJobFromHoldingModal.tsx:61 ~ holdingItem🚀==============", holdingItem)
        selectedHoldingItemRef.current = selectedHoldingItem
    }, [selectedHoldingItem])

    useEffect(() => {
        holdingItemIdRef.current = holdingItemId
    }, [holdingItemId])

    useEffect(() => {
        holdingJobIdRef.current = holdingJobId
    }, [holdingJobId])

    //@ts-ignore
    const theme = useTheme() as any
    const RenderOptionsModal = () => {
        return (

            <Modal
                visible={showRemoveFromHoldingOptionsModal}
                backdropStyle={styles.backdrop}
                onBackdropPress={() => fn_onClose()}
            >
                <Card
                    disabled={true}
                    style={{ margin: 5, minHeight: 200 }}
                    status="warning"
                    header={() => <Text category='s1'>Remove From Holding</Text>}
                    footer={() =>
                        <View style={{ flexDirection: 'row', gap: 3, justifyContent: 'flex-end' }}>
                            <Button size="small" status="danger" appearance="outline" onPress={() => { setShowRemoveFromHoldingOptionsModal(false) }}>Cancel</Button>
                        </View>
                    }
                >
                    <View style={{ margin: -15, flexDirection: "row", gap: 15, flex: 1 }}>
                        <Card
                            disabled={true}
                            style={{ height: "100%", margin: 5 }}
                            status="success"
                            header={() => <Text category='s1'>Remove and Create Job</Text>}
                            footer={() =>
                                <View style={{ justifyContent: 'flex-end' }}>
                                    <Button
                                        size='small'
                                        appearance='outline'
                                        status='success'
                                        onPress={() => {
                                            setShowRemoveFromHoldingOptionsModal(false)
                                            setShowCreateJobFromHoldingModal(true)
                                        }}>Create Job</Button>
                                </View>
                            }
                        >
                            <View style={{ height: "100%", flexDirection: 'column', gap: 5, justifyContent: 'space-evenly' }}>
                                <Text category='p1'>This will remove the item from the holding and create a job for it.</Text>

                            </View>
                        </Card>
                        <Card
                            disabled={true}
                            style={{ margin: 5, flex: 1 }}
                            status="info"
                            header={() => <Text category='s1'>Remove Only</Text>}
                            footer={() =>
                                <View style={{ justifyContent: 'flex-end' }}>

                                    <Button
                                        size='small'
                                        appearance='outline'
                                        status='purple'
                                        onPress={() => {
                                            selectedHoldingItemRef.current?.setReleased()
                                            Toast.show({
                                                type: 'success',
                                                position: 'top',
                                                text1: 'Holding Released',
                                                text2: 'Item has been released from holding',
                                                visibilityTime: 3000,
                                                autoHide: true,
                                                topOffset: 30,
                                                bottomOffset: 40,
                                            });
                                            fn_onClose()
                                        }}>Remove From Holding Only</Button>
                                </View>
                            }
                        >
                            <View style={{ flex: 1, flexDirection: 'column', gap: 5, justifyContent: 'space-evenly' }}>
                                <Text category='p1'>This will remove the item from the holding without creating a job.</Text>
                            </View>
                        </Card>
                    </View>
                </Card>
            </Modal>
        )
    }


    if (showRemoveFromHoldingOptionsModal) {
        return (
            <RenderOptionsModal />
        )
    }
    else {
        return (
            <Modal
                visible={showCreateJobFromHoldingModal}
                backdropStyle={styles.backdrop}
                style={styles.modal}
                onBackdropPress={() => setShowCreateJobFromHoldingModal(false)}
            >
                <CreateJobFromHolding
                    visible={showCreateJobFromHoldingModal}
                    holdingItemId={holdingItemIdRef.current as string}
                    holdingJobId={holdingJobIdRef.current as string}
                    onCloseModal={() => setShowCreateJobFromHoldingModal(false)}
                    onCreateJob={fn_onCreateJob}
                />
            </Modal>
        )

    }

}

const styles = StyleSheet.create({
    backdrop: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    modal: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    }
});