import React, { useState, useEffect, useRef } from 'react';
import {
    View,
    ScrollView
    //@ts-ignore
} from "react-native";
import {
    Button,
    Icon,
    Input,
    Card,
    Text,
    List,
    ListItem,
    Divider,
    Layout
} from "@ui-kitten/components";
import { AddressDetailsCard } from '../job/details/addressDetailsCard.component';
import { RouteLocation } from "../../models/Route.model";
import { InventoryItem, ExtendedInventoryCategory } from "../../models/Inventory.model";
import * as InventoryController from "../../functions/Inventory.controller"
import {Job, JobAddress, JobLocation, LineItem } from "../../models/Job.model";
import * as JobController from "../../functions/job.controller";
import Toast from 'react-native-toast-message';
interface RouteLocationDetailsCardProps {
    location: RouteLocation;
    onChangeRouteLocation: Function;
    comapnyInventoryItems: ExtendedInventoryCategory[];
    onCloseDetailsPanel: Function;
    openJobReportModal: Function;
    onSaveLocation: (location: RouteLocation) => void;
}

export const RouteLocationDetailsCard = ({ location, onChangeRouteLocation, comapnyInventoryItems, onCloseDetailsPanel, openJobReportModal, onSaveLocation }: RouteLocationDetailsCardProps) => {

    const [locationState, setLocationState] = useState<RouteLocation>(location);
    const [showInventoryItems, setShowInventoryItems] = useState(false);
    const [showCompanyInventoryItems, setShowCompanyInventoryItems] = useState(false);
    const [selectedInventoryItem, setSelectedInventoryItem] = useState<InventoryItem | null>(null);
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

    const locationStateRef = useRef<RouteLocation>(location);
    const [job, setJob] = useState<Job | null>(null);

    // load job from job_id
    useEffect(() => {
        const loadJob = async () => {
            const job = await JobController.getJobById(location.job_id);
            if (job) {
                setJob(job);
            }
        };
        loadJob();
    }, [location.job_id]);

    useEffect(() => {
        locationStateRef.current = location;
        setLocationState(location);
    }, [location]);

    useEffect(() => {
        if (job) {
            setLocationState({ ...location, inventory_items: job.details.report.items });
        }
    }, [job]);

    const onSetRouteLocation = (newLocation: RouteLocation) => {
        setLocationState(newLocation);
        locationStateRef.current = newLocation;
        // onChangeRouteLocation(newLocation);
        setHasUnsavedChanges(true);
    }

    const onSetAddress = (address: JobAddress) => {
        onSetRouteLocation({ ...locationStateRef.current, address });
    }

    const onSetLocation = (newLocation: JobLocation) => {
        onSetRouteLocation({ ...locationStateRef.current, location: newLocation });
    }

    const onSetInventoryItems = (lineItems: LineItem[]) => {
        onSetRouteLocation({ ...locationStateRef.current, inventory_items: lineItems });
    }

    const itemDescriptionBuilder = (item: LineItem) => {
        return `${item.description || item.name} X ${item.quantity}`
    }

    const saveChanges = async () => {
        try {
            let job = await JobController.getJobById(locationStateRef.current.job_id);
            if (!job) {
                throw new Error('Job not found');
            }
            // Update line items
            job.details.report.items = locationStateRef.current.inventory_items;

            // Update location and address
            job.details.address = locationStateRef.current.address;
            job.details.location = locationStateRef.current.location;

            // Update towing location if applicable
            if (job.details.options.towing_job_options && job.details.towing_details) {
                job.details.towing_details.updateLocationAtIndex(locationStateRef.current.index, {
                    ...job.details.towing_details.towing_locations[locationStateRef.current.index],
                    address: locationStateRef.current.address,
                    location: locationStateRef.current.location,
                });
            }

            await job.save();
            onSaveLocation(locationStateRef.current);
            setHasUnsavedChanges(false);
            Toast.show({
                type: 'success',
                text1: 'Changes saved successfully',
            });
        } catch (error) {
            console.error("Failed to save changes:", error);
            Toast.show({
                type: 'error',
                text1: 'Failed to save changes',
                text2: 'Please try again',
            });
        }
    };

    return (
        //@ts-ignore
        <Layout style={{ flex: 1, padding: 3, borderWidth: 1 }} level="1">
            {hasUnsavedChanges && (
                <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: 10, backgroundColor: '#FFF3CD' }}>
                    <Text category="s1" style={{ color: '#856404' }}>You have unsaved changes</Text>
                    <Button size="small" onPress={saveChanges}>Save Changes</Button>
                </View>
            )}

            <View style={{ flexDirection: 'row', height: 3, backgroundColor: '#3366ff' }}></View>
            <View style={{ flexDirection: 'row', justifyContent: 'space-between', zIndex: 1001 }}>
                <Text>{location.index + 1} : {location.name}</Text>
                <Button
                    size='tiny'
                    style={{ zIndex: 1002 }}
                    // appearance='ghost'
                    status='warning'
                    onPress={() => openJobReportModal(location.job_id)}>
                    <>
                        <Icon name='file-text-outline' width={20} height={20} fill='white' />
                        <Text>Open Job Report</Text>
                    </>
                </Button>
                <Button
                    size='tiny'
                    style={{ zIndex: 1002 }}
                    // appearance='ghost'
                    status='danger'
                    onPress={() => onCloseDetailsPanel()}>
                    <Icon name='close-outline' width={20} height={20} fill='white' />
                </Button>

            </View>
            <Input
                label='Name'
                value={location.name}
                onChangeText={(name: string) => onSetRouteLocation({ ...location, name })}
            />
            <Input
                label='Note'
                multiline={true}
                textStyle={{ minHeight: 64 }}
                value={locationStateRef.current.note}
                onChangeText={(note: string) => onSetRouteLocation({ ...locationStateRef.current, note })}
            />
            <Text>{locationStateRef.current.job_id}</Text>
            <Text>{locationStateRef.current.address?.formatted_address}</Text>
                <Text>{locationStateRef.current.location_type}</Text>
            <AddressDetailsCard
                address={locationStateRef.current.address}
                addressDisplayValue={locationStateRef.current.address?.formatted_address}
                onSetAddress={onSetAddress}
                onSetLocation={onSetLocation}
                onToggleCollapse={() => { }}
            />
            <Button onPress={() => setShowCompanyInventoryItems(!showCompanyInventoryItems)}>Company Inventory Items</Button>
            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                <Card
                    disabled={true}
                    style={{ flex: 1 }}
                    header={() => <Text>Inventory Items</Text>}
                    status='success'
                >
                    <List
                        data={locationStateRef.current.inventory_items}
                        ItemSeparatorComponent={Divider}
                        renderItem={({ item, index }: { item: LineItem, index: number }) => (
                            <ListItem
                                title={item.name}
                                description={itemDescriptionBuilder(item)}
                                accessoryRight={() => (
                                    <Button
                                        appearance='ghost'
                                        onPress={() => {
                                            const newItems = locationStateRef.current.inventory_items.filter((_, i) => i !== index);
                                            onSetInventoryItems(newItems);
                                        }}
                                        accessoryLeft={() => (
                                            <Input
                                                style={{ width: 50 }}
                                                value={item.quantity?.toString() || '1'}
                                                onChangeText={(quantity: string) => {
                                                    const newItems = locationStateRef.current.inventory_items.map((i, iIndex) => {
                                                        if (iIndex === index) {
                                                            return { ...i, quantity: parseInt(quantity) }
                                                        }
                                                        return i;
                                                    }
                                                    );
                                                    onSetInventoryItems(newItems);
                                                }
                                                }
                                            />
                                        )}
                                    >
                                        <Icon name='trash-2-outline' width={20} height={20} fill='red' />
                                    </Button>
                                )}
                            />
                        )}
                    />
                </Card>

                {showCompanyInventoryItems ?
                    <Card
                        disabled={true}
                        style={{ flex: 1 }}
                        header={() => <Text>Company Inventory Items</Text>}
                        status='warning'
                    >
                        <List
                            ItemSeparatorComponent={Divider}
                            data={comapnyInventoryItems.map(c => c.inventoryItems).flat()}
                            renderItem={({ item }: { item: InventoryItem }) => (
                                <ListItem
                                    title={item.name}
                                    description={item.description || ''}
                                    onPress={() => {
                                        const lineItem = item.toLineItem();
                                        onSetInventoryItems([...locationStateRef.current.inventory_items, lineItem]);
                                    }}
                                />
                            )}
                        />
                    </Card>
                    : null
                }
            </View>


        </Layout>
    )
}