var config = require('../config/config.js')
const axios = require('axios');
import * as ImagePicker from 'expo-image-picker';
import { Image } from '../models/Image.model';
import * as StorageController from './storageController'

var api = config.api;
var testApi = config.test_api

export enum IMAGE_TYPE {
    JOB = "job",
    HOLDING = "holding",
    CASE = "case",
    COMPANY = "company",
    MEMBER = "member"
}


function getApi() {
    if (StorageController.getAppState().use_test_api) {
        return testApi
    }
    return api
}

axios.interceptors.request.use(
    (config: any) => {
        let token = getToken();
        let member_id = null;
        if (StorageController.getAppState().selectedMembership != null) {
            member_id = StorageController.getAppState().selectedMembership?._id;
        }
        config.headers.authorization = `Bearer ${token}`;
        config.headers['member-id'] = member_id;
        return config;
    },
    (error: any) => {
        return Promise.reject(error);
    }
);

const getToken = async () => {
    return await StorageController.getAccessToken() || null;
}

//get images from job
export async function getImagesById(imageId: string) {
    try {
        const response = await axios.get(getApi() + "/image/" + imageId);
        // console.log(response);
        return response.data
    } catch (error) {
        console.error(error);
        // throw new Error(`HTTP error! status: ${response.status}`);
    }
}

// delete image by id
export async function deleteImageById(imageId: string) {
    try {
        const response = await axios.delete(getApi() + "/image/" + imageId);
        // console.log(response);
        return response.data
    } catch (error) {
        console.error(error);
        // throw new Error(`HTTP error! status: ${response.status}`);
    }
}


export async function fetchHoldingImages(holdingId: string) {
    try {
        const response = await axios.get(getApi() + "/image/holding/" + holdingId);
        return response.data
    } catch (error) {
        console.error(error);
    }
}

export async function fetchJobImages(jobId: string) {
    try {
        const response = await axios.get(getApi() + "/image/job/" + jobId);
        return response.data
    } catch (error) {
        console.error(error);
    }
}

export async function fetchCaseImages(caseId: string) {
    try {
        const response = await axios.get(getApi() + "/image/case/" + caseId);
        return response.data
    } catch (error) {
        console.error(error);
    }
}


export async function uploadImage(image: ImagePicker.ImageInfo, type: IMAGE_TYPE, id: string = "") {
    try {
        const company = StorageController.getCurrentCompany()
        const membership = StorageController.getAppState().selectedMembership
        let imageType = image.uri.split('data:')[1].split(';')[0].split('/')[1];
        let requestObj = new Image({
            company_id: company._id,
            member_id: membership?._id,
            details: "",
            status: "",
            name: "",
            desc: "",
            data: image.uri,
            contentType: `data:image/${imageType}`,
            fileType: imageType
        })
        if (type === IMAGE_TYPE.JOB) {
            requestObj.job_id = id
        }
        if (type === IMAGE_TYPE.HOLDING) {
            requestObj.holding_id = id
        }
        if (type === IMAGE_TYPE.CASE) {
            requestObj.case_id = id
        }
        const response = await axios.post(getApi() + "/image", requestObj);
        return response.data
    } catch (error) {
        console.error(error);
    }
}




export async function uploadImages(images: ImagePicker.ImageInfo[], type: IMAGE_TYPE, id: string = "") {
    try {
        const company = StorageController.getCurrentCompany()
        const membership = StorageController.getAppState().selectedMembership
        let imageArr = []
        for (let i = 0; i < images.length; i++) {
            let image = images[i]
            let imageType = image.uri.split('data:')[1].split(';')[0].split('/')[1];
            let requestObj = new Image({
                company_id: company._id,
                member_id: membership?._id,
                details: "",
                status: "",
                name: "",
                desc: "",
                data: image.uri,
                contentType: `data:image/${imageType}`,
                fileType: imageType
            })
            if (type === IMAGE_TYPE.JOB) {
                requestObj.job_id = id
            }
            if (type === IMAGE_TYPE.HOLDING) {
                requestObj.holding_id = id
            }
            if (type === IMAGE_TYPE.CASE) {
                requestObj.case_id = id
            }
            console.log("🚀============== ~ file: image.controller.ts:98 ~ uploadImages ~ requestObj🚀==============", requestObj)
            const response = await axios.post(getApi() + "/image", requestObj);
            console.log("🚀============== ~ file: image.controller.ts:97 ~ uploadImages ~ response🚀==============", response)
            imageArr.push(response.data._id)
        }
        console.log("🚀============== ~ file: image.controller.ts:104 ~ uploadImages ~ imageArr🚀==============", imageArr)
        return imageArr
    } catch (error) {
        console.log("🚀============== ~ file: image.controller.ts:91 ~ uploadImages ~ error🚀==============", error)
        return []
    }
}