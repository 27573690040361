import React, { useState, useRef, useCallback, useEffect, useReducer } from 'react';
import {
    Image,
    AccessibilityRole,
    ImageProps,
    ImageStyle,
    StyleSheet,
    TouchableOpacity,
    View,
    ScrollView,
    FlatList,
    Alert,
    Clipboard,
    Text as RNText
    //@ts-ignore
} from "react-native";
import {
    ApplicationProvider,
    Button,
    Icon,
    IconRegistry,
    Input,
    Select,
    SelectItem,
    useTheme,
    Layout,
    Text,
    List,
    ListItem,
    OverflowMenu,
    MenuItem,
    Card,
    Toggle,
    Radio,
    RadioGroup,
    Modal
} from "@ui-kitten/components";
//@ts-ignore
import debounce from 'lodash.debounce';
import { GoogleMap, useJsApiLoader, Marker, InfoWindow, TransitLayer, DirectionsService, DirectionsRenderer, TrafficLayer, MarkerClusterer, Polygon } from '@react-google-maps/api';
import * as StorageController from '../../functions/storageController';
import * as GoogleController from '../../functions/google.controller';
import * as ClientController from '../../functions/client.controller';
import * as UpdateController from '../../functions/update.controller';
import { or } from 'react-native-reanimated';


/**
     * Calculate distance between two points in metres using google maps api
*/

function useDebounce(callback: any, delay: number) {
    const debouncedFn = useCallback(
        debounce((...args: any) => callback(...args), delay),
        [delay] // will recreate if delay changes
    );
    return debouncedFn;
}

const mapLibs = ["places", "visualization"]


const DistanceQuoteTool = () => {
    const [distance, setDistance] = useState(null as string | null);
    const [duration, setDuration] = useState(null as string | null);
    const [originOptions, setOriginOptions] = useState([] as google.maps.places.AutocompleteResponse["predictions"]);
    const [destinationOptions, setDestinationOptions] = useState([] as google.maps.places.AutocompleteResponse["predictions"]);
    const [originMenuVisible, setOriginMenuVisible] = useState(false);
    const [destinationMenuVisible, setDestinationMenuVisible] = useState(false);
    const [originSearch, setOriginSearch] = useState({ term: '', fetchPredictions: false } as any);
    const [destinationSearch, setDestinationSearch] = useState({ term: '', fetchPredictions: false } as any);

    const [originLocation, setOriginLocation] = useState(null as any);
    const [destinationLocation, setDestinationLocation] = useState(null as any);

    const [coveredDistance, setCoveredDistance] = useState(0);
    const [costPerKm, setCostPerKm] = useState(0);

    const [costResult, setCostResult] = useState("0" as string | null);
    const mapRef = React.useRef(null as any);
    const [center, setCenter] = useState({ lat: -27.4705, lng: 153.0260 });
    const centerRef = React.useRef(center);
    const [map, setMap] = React.useState(null)
    const [showDirections, setShowDirections] = useState(false);
    const [mapDirections, setMapDirections] = useState(null as google.maps.DirectionsResult | null);

    const originDebounce = useDebounce((text: string) => {
        getPredictions(text).then((res) => {
            if (!res) return
            setOriginOptions(res);
            // console.log("🚀 ~ file: newJob.component.js:850 ~ getPredictions ~ res:", res)
            setOriginMenuVisible(true);
        });
    }, 500);

    const destinationDebounce = useDebounce((text: string) => {
        getPredictions(text).then(res => {
            setDestinationOptions(res);
            setDestinationMenuVisible(true);
        });
    }, 500);

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyDySIh3VZu-dtIFF9lzZC2RLJa_bLKtsvM",
        //@ts-ignore
        libraries: mapLibs  // Required for heatmap
    })


    const getPredictions = async (search: string) => {
        const res = await GoogleController.getpredictionsAutocomplete(search) as google.maps.places.AutocompleteResponse;
        return res.predictions;
    }

    const clearAll = () => {
        setOriginSearch({ term: '', fetchPredictions: false })
        setDestinationSearch({ term: '', fetchPredictions: false })
        setOriginOptions([])
        setDestinationOptions([])
        setOriginMenuVisible(false)
        setDestinationMenuVisible(false)
        setOriginLocation(null)
        setDestinationLocation(null)
        setDistance(null)
        setDuration(null)
        setCostResult(null)
        setShowDirections(false)
        setMapDirections(null)
    }



    const originInput = () => {
        return (
            <Input
                placeholder='Search Address'
                size='small'
                label='Search Address'
                value={originSearch.term}
                onChangeText={(text: string) => {
                    // console.log("🚀 ~ file: newJob.component.js:877 ~ originInput ~ text:", text)

                    setOriginSearch({ term: text, fetchPredictions: true })
                    originDebounce(text);
                }}
            />
        )
    }

    const destinationInput = () => {
        return (
            <Input
                placeholder='Search Address'
                size='small'
                label='Search Address'
                value={destinationSearch.term}
                onChangeText={(text: string) => {
                    setDestinationSearch({ term: text, fetchPredictions: true })
                    destinationDebounce(text);
                }}
            />
        )
    }

    const clickedPrediction = (event: google.maps.places.AutocompletePrediction, type: any) => {
        // console.log("clicked")
        // console.log(event)
        if (type === 'origin') {
            setOriginSearch({ term: event.description })
            setOriginMenuVisible(false);
            GoogleController.getPredictionsPlace(event.place_id).then(res => {
                console.log(res)
                res.result.address_components
                setOriginLocation({ lat: res.result.geometry.location.lat, lon: res.result.geometry.location.lng })
                // GoogleController.sortAddress(res.result.address_components).then(res => {
                //     console.log(res)
                // })
            })
        } else {
            setDestinationSearch({ term: event.description })
            setDestinationMenuVisible(false);
            GoogleController.getPredictionsPlace(event.place_id).then(res => {
                // console.log(res)
                res.result.address_components
                setDestinationLocation({ lat: res.result.geometry.location.lat, lon: res.result.geometry.location.lng })
                GoogleController.sortAddress(res.result.address_components).then((res: any) => {
                    // console.log(res)
                })
            })
        }
    }

    const getDirections = async () => {
        // console.log("get directions", originLocation, destinationLocation)
        if (!originLocation || !destinationLocation) {
            return
        }
        let origin = new google.maps.LatLng(originLocation.lat, originLocation.lon)
        let destination = new google.maps.LatLng(destinationLocation.lat, destinationLocation.lon)
        let directionService = new google.maps.DirectionsService()
        let directionsRequest = {
            origin: origin,
            destination: destination,
            travelMode: google.maps.TravelMode.DRIVING,
            unitSystem: google.maps.UnitSystem.METRIC
        }
        // make that async
        const directions = await directionService.route(directionsRequest, (result, status) => {
            // console.log(result)
            // console.log(status)
            if (result && status == google.maps.DirectionsStatus.OK) {
                let distVal = result.routes[0]?.legs[0]?.distance?.value
                let distTxt = result.routes[0]?.legs[0]?.distance?.text
                const distStr = `${distTxt} (${distVal} m)`
                // console.log(result.routes[0].legs[0].distance.text)
                // console.log(result.routes[0].legs[0].duration.text)
                setShowDirections(true)
                setDistance(distStr)
                setDuration(result.routes[0]?.legs[0]?.duration?.text || "")
                setMapDirections(result)
                return result
            } else {
                console.log("error")
            }
        }
        )
        return directions
    }

    const calculateResult = async () => {
        // get directions
        try {

            const directions = await getDirections() as google.maps.DirectionsResult
            if (!directions) return
            // console.log(directions)
            let dist = directions.routes[0].legs[0].distance?.value || 1 // in metres
            dist = dist / 1000 // convert to km
            // console.log("🚀 ~ file: jobDistanceQuoteTool.component.js:206 ~ calculateResult ~ distance:", dist)
            // offset distance by covered distance
            const offsetDistance = dist - Number(coveredDistance)
            // console.log("🚀 ~ file: jobDistanceQuoteTool.component.js:209 ~ calculateResult ~ offsetDistance:", offsetDistance)

            // calculate cost
            const cost = offsetDistance * Number(costPerKm)
            let costStr = cost.toFixed(2)
            // set cost result
            setCostResult(`$${costStr}`)
        } catch (e) {
            console.log(e)
        }
    }


    const RenderCardHeader = ({ value }: any) => {
        return (
            <Text
                style={{
                    fontSize: 14,
                    fontWeight: 'bold',
                }}
            >{value}
            </Text>
        )
    }





    const containerStyle = {
        width: '100%',
        height: '300px',
    };

    const handleCenterChanged = () => {
        if (map !== null) {
            const newCenter = mapRef.current?.state?.map?.center
            centerRef.current = { lat: newCenter.lat, lng: newCenter.lng };
        }
    };

    const onUnmount = React.useCallback(function callback(map: any) {
        setMap(null)
    }, [])

    const onLoad = React.useCallback(function callback(map: any) {
        // console.log("🚀 ~ file: serviceAreaEdit.component.js:19 ~ onLoad ~ map:", map)
        setMap(map)
    }, [])

    const onMapClick = (e: google.maps.MapMouseEvent) => {
        if (!e) return
        // console.log("🚀 ~ file: jobDistanceQuoteTool.component.js:264 ~ onMapClick ~ e:", e)
        if (!originLocation) {
            setOriginLocation({ lat: e.latLng?.lat(), lon: e.latLng?.lng() })
            setOriginSearch({ term: `${e.latLng?.lat()}, ${e.latLng?.lng()}` })
        }
        else if (!destinationLocation) {
            setDestinationLocation({ lat: e.latLng?.lat(), lon: e.latLng?.lng() })
            setDestinationSearch({ term: `${e.latLng?.lat()}, ${e.latLng?.lng()}` })
        }
        else if (originLocation && destinationLocation) {
            setOriginLocation(null)
            setDestinationLocation(null)
        }
    }


    return (
        <Layout style={{ flexDirection: 'row' }}>
            <Card
                style={{
                    margin: 10,
                    flex: 1,
                    // backgroundColor: '#252e56',
                }}
                status='info'
                header={() => <RenderCardHeader value='Distance Quote Tool' />}
            >
                <View style={{ flexDirection: 'row' }}>


                    {!isLoaded && <Text>Loading...</Text>}
                    <View style={{ flex: 1, flexDirection: 'column', margin: 5 }}>
                        <OverflowMenu
                            visible={originMenuVisible}
                            anchor={originInput}
                            onBackdropPress={() => setOriginMenuVisible(false)}
                        >
                            {originOptions && originOptions.map((option, index) =>
                                <MenuItem
                                    key={index}
                                    title={option.description}
                                    onPress={() => {
                                        clickedPrediction(option, 'origin');
                                    }}
                                />
                            )}
                        </OverflowMenu>

                        <OverflowMenu
                            visible={destinationMenuVisible}
                            anchor={destinationInput}
                            onBackdropPress={() => setDestinationMenuVisible(false)}
                        >
                            {destinationOptions && destinationOptions.map((option, index) =>
                                <MenuItem
                                    key={index}
                                    title={option.description}
                                    onPress={() => {
                                        clickedPrediction(option, 'destination');
                                    }}
                                />
                            )}
                        </OverflowMenu>
                    </View>
                    <View style={{ flex: 1, flexDirection: 'column', margin: 5 }}>
                        <Input
                            placeholder='Covered Distance (Km)'
                            size='small'
                            label='Covered Distance (Km)'
                            value={coveredDistance}
                            onChangeText={(text: string) => {
                                if (!isNaN(Number(text)) || text.includes('.')) {
                                    if (text.startsWith('.')) {
                                        text = '0' + text;
                                    }
                                    setCoveredDistance(Number(text))
                                }
                            }}
                        />
                        <Input
                            placeholder='Cost Per Km'
                            size='small'
                            label='Extra Cost Per Km Over($)'
                            value={costPerKm}
                            onChangeText={(text: string) => {
                                if (!isNaN(Number(text)) || text.includes('.')) {
                                    if (text.startsWith('.')) {
                                        text = '0' + text;
                                    }
                                    setCostPerKm(Number(text))
                                }
                            }}
                        />
                    </View>

                    <View style={{ flex: 1, flexDirection: 'column', margin: 5 }}>
                        <Text>Origin: {originSearch.term}</Text>
                        <Text>Destination: {destinationSearch.term}</Text>
                        <Button
                            appearance='outline'
                            status='success'
                            onPress={() => calculateResult()}
                        >
                            Calculate
                            <Icon name='cube-outline' width={24} height={24} fill="white" />
                        </Button>
                    </View>
                    <Card
                        header={() => <RenderCardHeader value='Result' />}
                        style={{
                            flex: 1,
                            flexDirection: 'column',
                            // backgroundColor: '#252e56',
                            margin: 5
                        }}>
                        {distance ? <Text>Distance: {distance}</Text> : null}
                        {duration ? <Text>Duration: {duration}</Text> : null}
                        {costResult ? <Text>Cost: {costResult}</Text> : null}
                    </Card>
                </View>
                {isLoaded &&
                    <GoogleMap
                        ref={mapRef}
                        mapContainerStyle={containerStyle}
                        onLoad={onLoad}
                        onUnmount={onUnmount}
                        onCenterChanged={handleCenterChanged}
                        zoom={8}
                        onClick={onMapClick}
                        center={center}
                    >
                        {/* Add markers for origin and destination */}
                        {originLocation && <Marker
                            position={{ lat: originLocation.lat, lng: originLocation.lon }}
                            icon={{
                                url: "https://maps.google.com/mapfiles/ms/icons/red-dot.png",
                                scaledSize: new window.google.maps.Size(30, 30),
                                origin: new window.google.maps.Point(0, 0),
                                anchor: new window.google.maps.Point(15, 15),
                            }}
                        />}
                        {destinationLocation && <Marker
                            position={{ lat: destinationLocation.lat, lng: destinationLocation.lon }}
                            icon={{
                                url: "https://maps.google.com/mapfiles/ms/icons/blue-dot.png",
                                scaledSize: new window.google.maps.Size(30, 30),
                                origin: new window.google.maps.Point(0, 0),
                                anchor: new window.google.maps.Point(15, 15),
                            }}
                        />}
                        {/* Add directions */}
                        {
                            showDirections &&
                            <DirectionsRenderer
                                options={{
                                    directions: mapDirections,
                                    // supressMarkers: true,
                                    polylineOptions: {
                                        strokeColor: "#252e56",
                                        strokeOpacity: 0.8,
                                        strokeWeight: 4,
                                    }
                                }
                                }
                            />

                        }
                    </GoogleMap>
                }
                <Button
                    appearance='outline'
                    status='danger'
                    onPress={() => clearAll()}
                >
                    Clear
                    <Icon name='trash-2-outline' width={24} height={24} fill="white" />
                </Button>

            </Card>
        </Layout>
    );
};


export default DistanceQuoteTool;