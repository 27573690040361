var config = require('../config/config.js')
const axios = require('axios');
import * as StorageController from './storageController'
import * as UpdateController from './update.controller'
import * as UserController from './user.controller'
import { Company } from '../models/Company.model';
import { Member } from '../models/Member.model';
import { CompanySubscription } from '../models/CompanySubscription.model';
import * as MqttService from '../services/mqtt.service'
var api = config.api;
var testApi = config.test_api

function getApi() {
    if (StorageController.getAppState().use_test_api) {
        return testApi
    }
    return api
}

axios.interceptors.request.use(
    async (config: any) => {
        let token = await getToken();
        let member_id = null;
        if (StorageController.getAppState().selectedMembership != null) {
            member_id = StorageController.getAppState().selectedMembership?._id;
        }
        config.headers.authorization = `Bearer ${token}`;
        config.headers['member-id'] = member_id;
        return config;
    },
    (error: any) => {
        return Promise.reject(error);
    }
);

const getToken = async () => {
    return await StorageController.getAccessToken() || null;
}









// router.post('/company_subscription', CompanySubscriptionController.create);
// router.get('/company_subscription/:id', CompanySubscriptionController.findOne);
// router.put('/company_subscription/:id', CompanySubscriptionController.update);
// router.delete('/company_subscription/:id', CompanySubscriptionController.deleteCompanySubscription);

// router.get('/company_subscription/company/:company_id', CompanySubscriptionController.findAllByCompany);
// router.get('/company_subscription/company/:company_id/:status', CompanySubscriptionController.findByCompanyAndStatus);
// router.post('/company_subscription/stripe/create_portal_session', CompanySubscriptionController.createStripePortalSession);

export async function createCompanySubscription(companySubscription: CompanySubscription) {
    try {
        let response = await axios.post(`${getApi()}/company_subscription`, companySubscription);
        return response.data;
    } catch (error) {
        console.log("🚀============== ~ file: CompanySubscription.controller.ts:62 ~ createCompanySubscription ~ error🚀==============", error)

        return null
    }
}

export async function updateCompanySubscription(companySubscription: CompanySubscription) {
    try {
        let response = await axios.put(`${getApi()}/company_subscription/${companySubscription._id}`, companySubscription);
        return response.data;
    } catch (error) {
        console.log("🚀============== ~ file: CompanySubscription.controller.ts:72 ~ updateCompanySubscription ~ error🚀==============", error)

        return null
    }
}

export async function deleteCompanySubscription(companySubscription: CompanySubscription) {
    try {
        let response = await axios.delete(`${getApi()}/company_subscription/${companySubscription._id}`);
        return response.data;
    } catch (error) {
        console.log("🚀============== ~ file: CompanySubscription.controller.ts:82 ~ deleteCompanySubscription ~ error🚀==============", error)

        return null
    }
}

export async function getCompanySubscription(id: string) {
    try {
        let response = await axios.get(`${getApi()}/company_subscription/${id}`);
        return response.data;
    } catch (error) {
        console.log("🚀============== ~ file: CompanySubscription.controller.ts:92 ~ getCompanySubscription ~ error🚀==============", error)

        return null
    }
}

export async function getCompanySubscriptionsByCompany(company_id: string) {
    try {
        let response = await axios.get(`${getApi()}/company_subscription/company/${company_id}`);
        return response.data;
    } catch (error) {
        console.log("🚀============== ~ file: CompanySubscription.controller.ts:102 ~ getCompanySubscriptionsByCompany ~ error🚀==============", error)

        return []
    }
}

export async function getCompanySubscriptionsByCompanyAndStatus(company_id: string, status: string) {
    try {
        let response = await axios.get(`${getApi()}/company_subscription/company/${company_id}/${status}`);
        return response.data;
    } catch (error) {
        console.log("🚀============== ~ file: CompanySubscription.controller.ts:112 ~ getCompanySubscriptionsByCompanyAndStatus ~ error🚀==============", error)

        return null
    }
}


export async function createStripePortalSession(companySubscription: CompanySubscription) {
    try {
        const data = {
            company_id: companySubscription.company_id
        }
        let response = await axios.post(`${getApi()}/company_subscription/stripe/create_portal_session`, data);
        return response.data;
    } catch (error) {
        console.log("🚀============== ~ file: CompanySubscription.controller.ts:126 ~ createStripePortalSession ~ error🚀==============", error)
        return null
    }
}

export async function getActiveSubscriptionByCompanyId(company_id: string) {
    try {
        let response = await axios.get(`${getApi()}/company_subscription/company_active/${company_id}`);
        return response.data;
    } catch (error) {
        console.log("🚀============== ~ file: CompanySubscription.controller.ts:138 ~ getActiveSubscriptionByCompanyId ~ error🚀==============", error)

        return null
    }
}
