
import React, { useState, useEffect, useRef, useReducer, useCallback, useMemo, createContext, useContext } from 'react';
//@ts-ignore
import { useWindowDimensions, StyleSheet, View, TouchableOpacity, ScrollView } from "react-native";
import { SafeAreaView } from 'react-native-safe-area-context';
import * as XLSX from 'xlsx';
import DataGrid from 'react-data-grid';
//@ts-ignore
import { Divider, Icon, Layout, Text, TopNavigation, TopNavigationAction, Card, Button, Input, ButtonGroup, CheckBox, Modal, RangeDatepicker, Datepicker, useTheme, IndexPath, Select, SelectItem, List, ListItem } from '@ui-kitten/components';
import ErrorBoundary from '../ErrorBoundary.component';
import * as JobsController from '../../functions/job.controller'
import * as MembershipsController from '../../functions/membership.controller'
import * as UpdateController from '../../functions/update.controller'
import * as ImageController from '../../functions/image.controller'
import * as ClientController from '../../functions/client.controller'
import * as StorageController from '../../functions/storageController'
import * as DocumentController from '../../functions/document.controller'
import { Document, DocumentCategory, SharePermissions, SharedWith } from '../../models/Document.model';
import { formatIsoDateTime, getDocumentTypeFromString } from '../../functions/utils';
import { Company } from '../../models/Company.model';


const RenderCreateNewDocumentModal = ({
    showCreateNewDocumentModal,
    setShowCreateNewDocumentModal,
    onCreateNewDocument,
    selectedCategory
}: {
    showCreateNewDocumentModal: boolean,
    setShowCreateNewDocumentModal: (value: boolean) => void,
    onCreateNewDocument: (document: Document) => void,
    selectedCategory: DocumentCategory | null
}) => {
    const [title, setTitle] = useState("")
    const titleRef = useRef(title)
    const [description, setDescription] = useState("")
    const descriptionRef = useRef(description)
    const [file, setFile] = useState(null as any | null);
    const newDocumentRef = useRef(null as Document | null)
    const [isError, setIsError] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")



    useEffect(() => {
        newDocumentRef.current = new Document({
            title: titleRef.current,
            description: descriptionRef.current,
            company_id: StorageController.getCurrentCompany()._id,
            member_id: StorageController.getAppState().selectedMembership?._id,
            category_id: selectedCategory?._id,
            job_id: null,
            case_id: null,
            file: file?.name,
            content_type: file?.type,
            // uri: file,
            is_deleted: false,
            details: {},
        })
        let isErr = false
        let errorMsg = ""
        if (!title || title === "") {
            isErr = true
            errorMsg = "-Title is required"
        }
        if (!file) {
            isErr = true
            errorMsg += "\n-File is required"
        }
        setIsError(isErr)
        setErrorMessage(errorMsg)

    }, [title, description, selectedCategory, file])

    const onSetTitle = (value: string) => {
        setTitle(value)
        titleRef.current = value
    }




    const onSetDescription = (value: string) => {
        setDescription(value)
        descriptionRef.current = value
    }

    const handleCreateNewDocument = async () => {

        const newDoc = { ...newDocumentRef.current }
        const documentResult = await DocumentController.createDocument(newDoc as Document, file as any);
        onCreateNewDocument(documentResult)
        setTitle("")
        setDescription("")
        setFile(null)
    }


    const onFileChange = async (event: any) => {
        try {
            const newFile = event.target.files[0];
            // console.log("🚀============== ~ file: documentsContainerScreen.tsx:254 ~ onFileChange ~ newFile🚀==============", newFile)
            const base64File = await toBase64(newFile);
            setFile({
                uri: base64File,
                name: newFile.name,
                type: newFile.type
            });
        } catch (err: any) {
            alert("Error selecting file" + err?.target?.error || "")
            console.log("🚀============== ~ file: documentsContainerScreen.tsx:333 ~ onFileChange ~ err🚀==============", err)
        }
    };

    const onClose = () => {
        setTitle("")
        setDescription("")
        setFile(null)
        setShowCreateNewDocumentModal(false)
    }

    const toBase64 = (file: File) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    };

    return (
        <Modal
            visible={showCreateNewDocumentModal}
            backdropStyle={styles.backdrop}
            onBackdropPress={() => onClose()}>
            <Card
                status='success'
                header={() =>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Text>Create New Document</Text>
                        <Button onPress={() => onClose()} status='danger'>
                            <Icon name='close-outline' fill='white' width={15} height={15} />
                        </Button>
                    </View>
                }
                disabled={true}>
                <View style={{ flexDirection: 'column', gap: 10 }}>

                    <View style={{ flexDirection: 'column', gap: 3 }}>

                        <Input
                            placeholder="Title"
                            value={title}
                            onChangeText={onSetTitle}
                        />
                        <Input
                            placeholder="Description"
                            value={description}
                            onChangeText={onSetDescription}
                        />
                    </View>
                    {/* upload file area */}
                    <View style={{ flexDirection: 'row' }}>

                        <input type="file" onChange={onFileChange} />
                        {file &&
                            <Button status='warning' size='tiny' onPress={() => setFile(null)}>Remove</Button>
                        }

                    </View>
                    <View style={{ flexDirection: 'column', gap: 3 }}>

                        <Text>Name: {file?.name}</Text>
                        <Text>Type: {file?.type}</Text>
                    </View>
                    <Text style={{ color: 'red' }}>{errorMessage}</Text>
                    {/* <Text>{file?.uri}</Text> */}
                    {/* <Text>Size: {file?.size}</Text> */}
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', padding: 10 }}>
                        <Button
                            status='success'
                            disabled={isError}
                            onPress={handleCreateNewDocument}>
                            Create
                        </Button>
                        <Button
                            status='danger'
                            appearance='ghost'
                            onPress={onClose}>
                            Cancel
                        </Button>
                    </View>
                </View>
            </Card>
        </Modal>
    )
}

const styles = StyleSheet.create({
    backdrop: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
});

export default RenderCreateNewDocumentModal;