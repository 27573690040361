//@ts-ignore
window.isBatchingLegacy = false;
import React, { StrictMode, useEffect, useReducer } from "react";
import { useJsApiLoader } from '@react-google-maps/api';
import * as Sentry from 'sentry-expo';
import { ApplicationProvider, IconRegistry, Icon, Toggle, Button } from "@ui-kitten/components";
//@ts-ignore
import { StyleSheet, Text, View, Platform, ScrollView, Dimensions, useWindowDimensions, LogBox } from 'react-native';
import { EvaIconsPack } from "@ui-kitten/eva-icons";
import { SafeAreaProvider, SafeAreaView } from 'react-native-safe-area-context';
import { mapping, light, dark } from "@eva-design/eva";
import Toast from 'react-native-toast-message';
import { AppNavigator } from './routes';
import * as StorageController from './functions/storageController';
import { MessageOverlay } from "./components/messaging/messageOverlay.component";
import { CompanySwitchComponent } from "./components/login/companySwitch.component";
import { default as newmapping } from './mapping.json';
import config from "./config/config";
import * as UpdateController from './functions/update.controller';
import ErrorBoundary from "./components/ErrorBoundary.component";
import { NotificationsComponent } from "./components/notifications/notifications.component";
import { NotificationProvider } from "./context/notifications.context";
import NotificationModal from "./components/modals/notification.modal";
import { ThemeProvider, useTheme } from './context/theme.context';
import { Company, CompanyTheme } from "./models/Company.model";
import { PortalHost, PortalProvider } from '@gorhom/portal';
import * as MqttService from './services/mqtt.service';
import { useAppStateChange, IAction } from './hooks/appStateChange.hook';
import { GestureHandlerRootView } from 'react-native-gesture-handler';

const asciiArt = `

▓█████▄ ██▓ ██████ ██▓███  ▄▄▄    ▄▄▄█████▓▄████▄  ██░ ██ ███▄ ▄███▓█████
▒██▀ ██▓██▒██    ▒▓██░  ██▒████▄  ▓  ██▒ ▓▒██▀ ▀█ ▓██░ ██▓██▒▀█▀ ██▓█   ▀
░██   █▒██░ ▓██▄  ▓██░ ██▓▒██  ▀█▄▒ ▓██░ ▒▒▓█    ▄▒██▀▀██▓██    ▓██▒███
░▓█▄   ░██░ ▒   ██▒██▄█▓▒ ░██▄▄▄▄█░ ▓██▓ ░▒▓▓▄ ▄██░▓█ ░██▒██    ▒██▒▓█  ▄
░▒████▓░██▒██████▒▒██▒ ░  ░▓█   ▓██▒▒██▒ ░▒ ▓███▀ ░▓█▒░██▒██▒   ░██░▒████▒
 ▒▒▓  ▒░▓ ▒ ▒▓▒ ▒ ▒▓▒░ ░  ░▒▒   ▓▒█░▒ ░░  ░ ░▒ ▒  ░▒ ░░▒░░ ▒░   ░  ░░ ▒░ ░
 ░ ▒  ▒ ▒ ░ ░▒  ░ ░▒ ░      ▒   ▒▒ ░  ░     ░  ▒   ▒ ░▒░ ░  ░      ░░ ░  ░
 ░ ░  ░ ▒ ░  ░  ░ ░░        ░   ▒   ░     ░        ░  ░░ ░      ░     ░
   ░    ░       ░               ░  ░      ░ ░      ░  ░  ░      ░     ░  ░
 ░                                        ░

`;
console.log(asciiArt);
// import DisableDevtool from 'disable-devtool';
// DisableDevtool({
//     ondevtoolopen: function () {
//         // alert('Devtool is disabled');
//         // Log a cool ascii art showing the workd honeypot
//         console.log(asciiArt);

//     },
//     clearLog: false,
//     // rewriteHTML: "<h1>Devtool is disabled</h1>"
//     // interval: number, // timer interval default 200ms
//     disableMenu: false, // Whether to disable the right-click menu Default is true
//     // stopIntervalTime: number, // Waiting time to cancel monitoring on mobile
//     clearIntervalWhenDevOpenTrigger: true, // Whether to stop monitoring after triggering the default is false, this parameter is invalid when using ondevtoolclose
//     // detectors: Array<DetectorType>, // Enabled detectors See 3.5 for details of detectors. The default is all, it is recommended to use all
//     // clearLog: boolean, // Whether to clear the log every time
//     // disableSelect: boolean, // Whether to disable selection text Default is false
//     // disableCopy: boolean, // Whether to disable copying, default is false
//     // disableCut: boolean, // Whether to disable cutting, default is false
//     // disablePaste: boolean, // Whether to disable paste, default is false
//     // ignore: (string | RegExp)[] | null | (() => boolean), // Some cases ignore the disablement
//     // disableIframeParents: boolean, // Whether all parent windows are disabled in the iframe
//     // timeOutUrl: string, // Turn off URLs that page timeouts forward towards
//     // rewriteHTML: string, // Detecting the rewriting page after opening
// });

/*
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
//////// CONSOLE ERROR SUPPRESSION           INITIALIZATION //////
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
*/
const getTimestamp = () => {
    const now = new Date();
    return now.toISOString();
};
const ignorePrefixes = [
    'Unexpected text node:',
    'Warning: Failed prop type:',
    "Warning: Can't perform a React state",
    "Unchecked runtime",
    "Google Maps JavaScript API has been loaded",
    "As of February 21st, 2024",
    "Animated: `useNativeDriver`"
];
const shouldIgnore = (message: any) => {
    return ignorePrefixes.some(prefix => message.startsWith(prefix));
};

const originalWarn = console.warn;
const originalError = console.error;
const originalLog = console.log;

console.error = (...args) => {
    if (typeof args[0] === 'string' && shouldIgnore(args[0])) {
        return; // Suppress the error
    }
    originalError(...args); // Log other errors
};

console.warn = (...args) => {
    if (typeof args[0] === 'string' && shouldIgnore(args[0])) {
        return; // Suppress the warning
    }
    originalWarn(...args); // Log other warnings
};

console.log = (...args) => {
    if (process.env.NODE_ENV === 'production') {
        return; // Suppress logs in production
    } else {
        // Add a timestamp in development
        originalLog(`[${getTimestamp()}]`, ...args);
    }
};


/*
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
//////// SENTRY ERROR REPORTING           INITIALIZATION    ////// 
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
*/

let isSentryInitialized = false;

export function initializeSentry() {
    if (isSentryInitialized) return;

    Sentry.init({
        dsn: "https://6e3c657c79e2ac482092348d1fafe591@o4505395136757760.ingest.sentry.io/4505797978488832",
        integrations: [
            new Sentry.Browser.BrowserTracing(),
            new Sentry.Browser.Replay()
        ],
        debug: true,
        enabled: true,
        enableInExpoDevelopment: true,
        release: "1.0.0",
        // enableOutOfMemoryTracking: true,
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        tracesSampleRate: 0.1,

        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

        // Capture Replay for 10% of all sessions,
        // plus for 100% of sessions with an error
        // replaysSessionSampleRate: 0.1,
        // replaysOnErrorSampleRate: 1.0,
    });

    isSentryInitialized = true;
}

// initializeSentry();

enum DEVICE_MODE {
    MOBILE = 'mobile',
    DESKTOP = 'desktop'
}

const AppContent = () => {

    const [loading, setLoading] = React.useState(false);
    const [loggedIn, setLoggedIn] = React.useState(false);
    const [selectedCompany, setSelectedCompany] = React.useState(null as Company | null);
    const [showChat, setShowChat] = React.useState(true);
    const [showCompanySwitch, setShowCompanySwitch] = React.useState(false);
    const [eventsRegistered, setEventsRegistered] = React.useState(false)
    const [alldataUpdate, setAllDataUpdate] = React.useState(null as any)

    const [customCompanyThemeMapping, setCustomCompanyThemeMapping] = React.useState(newmapping);
    const [appliedCompanyTheme, setAppliedCompanyTheme] = React.useState({} as CompanyTheme);
    const [viewMode, setViewMode] = React.useState(DEVICE_MODE.DESKTOP as DEVICE_MODE)

    // const { windowWidth, windowHeight } = Dimensions.get('window');
    const windowWidth = useWindowDimensions().width;

    useEffect(() => {
        // if is a mobile device set the view mode to list
        if (windowWidth < 800) {
            setViewMode(DEVICE_MODE.MOBILE)
        }
    }, [windowWidth])

    //force update
    const [x, forceUpdate] = useReducer(x => x + 1, 0);
    React.useEffect(() => {
        load()
    }, [])

    const load = async () => {

        setLoading(true);
        try {

            const state = await StorageController.loadStateFromDisk()
            setLoading(false);
            if (state?.loggedIn && state?.selectedMembership != null) {
                setLoggedIn(true)
                //update company memberships
                // UpdateController.updateMembershipsAndCompanies()
                MqttService.connect()
                setTimeout(() => {
                    window.document.title = "DispatchMe - Jobs"
                }, 1000);
            } else {
                //MQTT connection on company select
                setTimeout(() => {
                    window.document.title = "DispatchMe - Login"
                }, 1000);
            }
        } catch (e) {
            console.log("🚀============== ~ file: App.tsx:181 ~ load ~ e", e)
            setLoading(false);
        }


        if (!eventsRegistered) {
            registerMqttStatusEvent()
            setEventsRegistered(true)
            // throw new Error('I crashed!');
        }
        setLoading(false);

    }



    const { theme, toggleTheme } = useTheme() as any;
    useEffect(() => {
        if (selectedCompany) {
            if (selectedCompany?.settings?.theme) {
                const newTheme = selectedCompany.settings.theme
                const lightOrDark = selectedCompany.settings.theme.light_dark
                const evaJson = newTheme?.createEvaJson()
                toggleTheme(evaJson, lightOrDark)
            }
        }
    }, [selectedCompany])


    //Used to test new themes or something
    useEffect(() => {
        if (appliedCompanyTheme) {
            // console.log("🚀============== ~ file: App.tsx:144 ~ useEffect ~ appliedCompanyTheme🚀==============", appliedCompanyTheme)
            // if theres it is an empty object, then use the default theme
            if (Object.keys(appliedCompanyTheme).length === 0) {
                const lightOrDark = "dark"
                toggleTheme({}, lightOrDark)
            } else {
                const newTheme = new CompanyTheme(appliedCompanyTheme)
                const lightOrDark = newTheme.light_dark
                const evaJson = newTheme?.createEvaJson()
                toggleTheme(evaJson, lightOrDark)
            }
        }
    }, [appliedCompanyTheme])

    useAppStateChange({
        onNavigated: (action: IAction) => {
            onNavigated(action)
        },
        onLoggedIn: (action: IAction) => {
            onLoggedIn(action)
        },
        onLoggedOut: (action: IAction) => {
            onLoggedOut(action)
        },
        onUpdatedSelectedCompany: (action: IAction) => {
            onUpdatedSelectedCompany(action)
        },
        onUpdatedCompanyTheme: (action: IAction) => {
            onUpdatedCompanyTheme(action)
        },
        onUpdatedAllData: (action: IAction) => {
            onUpdatedAllData(action)
        }
    })

    const onNavigated = (action: IAction) => {
        setTimeout(() => {
            window.document.title = "DispatchMe - " + (action.data || "")
        }, 100);
        if (action.data == "Messages" || action.data == "CompanySelect" || action.data == "Login" || action.data == "Invoices" || action.data == "Loading") {
            setShowChat(false)
            // console.log("------HIDING CHAT------")
            forceUpdate()
        } else {
            setShowChat(true)
        }
        if (action.data == "CompanySelect" || action.data == "Login" || action.data == "Loading") {
            setShowCompanySwitch(false)
            forceUpdate()
        }
        else {
            setShowCompanySwitch(true)
        }
    }



    const onLoggedIn = (action: IAction) => {
        setLoggedIn(true)
        forceUpdate()
    }

    const onLoggedOut = (action: IAction) => {
        setLoggedIn(false)
        forceUpdate()
    }

    const onUpdatedSelectedCompany = (action: IAction) => {
        setSelectedCompany(new Company(StorageController.getAppState().selectedCompany))
        forceUpdate()
    }

    const onUpdatedCompanyTheme = (action: IAction) => {
        setAppliedCompanyTheme(action.data)
        forceUpdate()
    }

    const onUpdatedAllData = (action: IAction) => {
        setAllDataUpdate(action.data)
    }


    const [mqttStatus, setMqttStatus] = React.useState('disconnected');

    const registerMqttStatusEvent = () => {
        UpdateController.eventMqttEvent.register((action: any) => {
            if (action.type == UpdateController.MQTT_ACTIONS.CONNECTED) {
                // console.log('mqtt connected event fired', action.data);
                setMqttStatus('Connected')
            }
            if (action.type == UpdateController.MQTT_ACTIONS.DISCONNECTED) {
                // console.log('mqtt disconnected event fired', action.data);
                setMqttStatus('Disconnected')
            }
            if (action.type == UpdateController.MQTT_ACTIONS.RECONNECTING) {
                console.log('mqtt reconnecting event fired', action.data);
                setMqttStatus('Reconnecting')
            }
            if (action.type == UpdateController.MQTT_ACTIONS.ERROR) {
                console.log('mqtt error event fired', action.data);
                setMqttStatus('Error')
            }
        })
    }

    const MemoizedCompanySwitchComponent = React.useMemo(() => {
        return <CompanySwitchComponent />
    }, [loggedIn, selectedCompany, alldataUpdate])

    const MemoizedNotificationsComponent = React.useMemo(() => {
        return <NotificationsComponent />
    }, [loggedIn, selectedCompany, alldataUpdate])

    return (
        <>

            <IconRegistry icons={EvaIconsPack} />
            <ApplicationProvider mapping={mapping} customMapping={newmapping} theme={theme}>
                <PortalProvider>


                    <NotificationProvider>
                        <View style={styles.container}>
                            {/* <RenderDevToggle /> */}
                            {/* <NotificationsComponent /> */}
                            <NotificationModal
                                onOk={() => { }}
                            />
                            <AppNavigator />
                            <Toast />
                            {loggedIn && showChat ?
                                <ErrorBoundary>
                                    <MessageOverlay />
                                </ErrorBoundary> :
                                null
                            }
                            {!loading &&
                                <>
                                    <>
                                        <View style={{ position: 'absolute', top: 0, left: 0 }}>
                                            <View style={{ flexDirection: 'row', justifyContent: "center", alignContent: "center" }}>
                                                {(showCompanySwitch && loggedIn) &&
                                                    <>
                                                        <Icon
                                                            name='radio-button-on'
                                                            fill={mqttStatus == 'Connected' ? 'green' : mqttStatus == 'Disconnected' ? 'red' : mqttStatus == 'Reconnecting' ? 'yellow' : 'red'}
                                                            width={15}
                                                            height={15}
                                                        />
                                                        <Text
                                                            style={{
                                                                color: mqttStatus == 'Connected' ? 'green' : mqttStatus == 'Disconnected' ? 'red' : mqttStatus == 'Reconnecting' ? 'yellow' : 'red',
                                                                fontSize: 12,
                                                            }}>{mqttStatus}</Text>
                                                        {/* <Button
                                                    onPress={() => {
                                                        console.table(MqttService.getSubscriptions())
                                                        }}
                                                        >
                                                        Subscriptions
                                                        </Button> */}
                                                    </>
                                                }
                                            </View>
                                        </View>
                                        <View style={viewMode == DEVICE_MODE.DESKTOP ? styles.topBar : styles.topBarMobile}>
                                            <View style={{ flexDirection: 'row', justifyContent: 'flex-end', gap: 5 }}>
                                                <View style={viewMode == DEVICE_MODE.DESKTOP ? styles.companySwitch : styles.companySwitchMobile}>
                                                    {/* <CompanySwitchComponent /> */}
                                                    {MemoizedCompanySwitchComponent}
                                                </View>
                                                <View style={viewMode == DEVICE_MODE.DESKTOP ? styles.notifications : styles.notificationsMobile}>
                                                    {MemoizedNotificationsComponent}
                                                </View>
                                            </View>
                                        </View>
                                    </>
                                </>
                            }
                        </View>
                    </NotificationProvider>
                </PortalProvider>
            </ApplicationProvider>
        </>
    );
};

const App = () => (
    <ThemeProvider>
        {/* @ts-ignore */}
        <GestureHandlerRootView style={{ flex: 1 }}>
            <IconRegistry icons={EvaIconsPack} />
            <AppContent />
        </GestureHandlerRootView>
    </ThemeProvider>
);

export default App;


const styles = StyleSheet.create({
    container: {
        flex: 1,
        overflow: 'hidden',
    },
    topBar: {
        position: 'absolute',
        top: 0,
        width: '80%',
        right: 0,
    },
    topBarMobile: {
        position: 'absolute',
        top: 0,
        width: '80%',
        right: 0,
    },
    companySwitch: {
        minWidth: "20%",
        maxWidth: "80%",
        right: "0%",
    },
    companySwitchMobile: {
        minWidth: "20%",
        // maxWidth: "80%",
        left: 0,
    },
    notifications: {
        minWidth: "10%",
        maxWidth: "20%"
    },
    notificationsMobile: {
        minWidth: "10%",
        maxWidth: "20%"
    }
});

// add pwa registration
// import { registerRootComponent } from 'expo';
// import { name as appName } from './app.json';
// registerRootComponent(App);

// add web registration
// import { AppRegistry } from 'react-native';
// AppRegistry.registerComponent("App", () => App);
