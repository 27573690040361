import React from 'react';
//@ts-ignore
import { View } from 'react-native';
import { Button, Text, Layout, useTheme } from "@ui-kitten/components";

interface RouteActionsProps {
  routeAction: ROUTE_ACTION_TYPES;
  setRouteAction: (action: ROUTE_ACTION_TYPES) => void;
  onCalculateRoute: () => void;
  setShowRouteLegsPanel: (show: boolean) => void;
}

enum ROUTE_ACTION_TYPES {
    GET_CURRENT_ROUTE = 'GET_CURRENT_ROUTE',
    GET_OPTIMIZED_ROUTE = 'GET_OPTIMIZED_ROUTE',
}

export const RouteActions = ({ routeAction, setRouteAction, onCalculateRoute, setShowRouteLegsPanel }: RouteActionsProps) => {
  const theme = useTheme();
  return (
    <Layout style={{ flexDirection: 'row',gap:10, justifyContent: 'space-between', alignItems: 'center', borderWidth: 1, borderColor: theme['color-basic-500'], borderRadius: 8, margin: 1, padding:5 }}>
      <View style={{ flexDirection: 'column', gap: 3 }}>
        <Button
          appearance={routeAction === ROUTE_ACTION_TYPES.GET_CURRENT_ROUTE ? 'filled' : 'outline'}
          onPress={() => setRouteAction(ROUTE_ACTION_TYPES.GET_CURRENT_ROUTE)}
        >
          <Text>Keep Location in Order</Text>
        </Button>
        <Button
          appearance={routeAction === ROUTE_ACTION_TYPES.GET_OPTIMIZED_ROUTE ? 'filled' : 'outline'}
          onPress={() => setRouteAction(ROUTE_ACTION_TYPES.GET_OPTIMIZED_ROUTE)}
        >
          <Text>Optimize Route</Text>
        </Button>
      </View>
      <View style={{ flexDirection: 'column', gap: 3 }}>
        <Button
          onPress={() => onCalculateRoute()}
          status='success'>
          <Text>Calculate</Text>
        </Button>
      </View>
      <View style={{}}>
        {/* <Button onPress={() => setShowRouteLegsPanel(true)}>
          <Text>Route Legs</Text>
        </Button> */}
      </View>
    </Layout>
  );
};