import React from 'react';
//@ts-ignore
import { View, TouchableOpacity } from 'react-native';
import { ListItem, Icon, useTheme, Text, Card, Button } from '@ui-kitten/components';
import { RouteLocation, ROUTE_LOCATION_TYPE } from '../../models/Route.model';
import { JOB_STATUS } from '../../models/Job.model';

interface RouteListItemProps {
  routeLocation: RouteLocation;
  index: number;
  isSelected: boolean;
  onPress: () => void;
  onRemove: () => void;
  onMoveUp: () => void;
  onMoveDown: () => void;
  isFirst: boolean;
  isLast: boolean;
  onSetRouteLocationType: (index: number, routeLocationType: ROUTE_LOCATION_TYPE) => void;
}

export const RouteListItem: React.FC<RouteListItemProps> = ({
  routeLocation,
  index,
  isSelected,
  onPress,
  onRemove,
  onMoveUp,
  onMoveDown,
  isFirst,
  isLast,
  onSetRouteLocationType
}) => {

  //@ts-ignore
  const theme = useTheme() as any;

  const buildLocationDescription = (location: RouteLocation) => {
    const lineItems = location.inventory_items.map(item => `${item.name} ${item.quantity}`);
    return `${location.address?.formatted_address} ${location.location_type} ${lineItems.length} Items`;
  };

  const RenderCardStatusAccent = ({ status }: { status: JOB_STATUS }) => {
    const STATUS_CARD_ACCENT: { [key: string]: string } = {
      "unassigned": "red",
      "assigned": 'yellow',
      "ongoing": "yellow",
      "pending": "blue",
      "complete": "green",
      "cancelled": "purple",
      "held": "yellow",
      "released": "green",
      "quote": "gray",
      "request": "pink",
    };

    const style = {
      height: 5,
      borderTopLeftRadius: 5,
      borderTopRightRadius: 5,
      zIndex: 10,
      backgroundColor: STATUS_CARD_ACCENT[status]
    };

    return <View style={style} />;
  };



  return (
    <Card
      accent={<RenderCardStatusAccent status={routeLocation?.job?.status ?? JOB_STATUS.UNASSIGNED} />}
      onPress={onPress}
      style={{
        backgroundColor: isSelected ? theme['color-success-900'] : theme['color-basic-800'],
      }}
      header={() => (
        <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <TouchableOpacity
                onPress={onMoveUp}
                disabled={isFirst}
                style={{ marginRight: 5, opacity: isFirst ? 0.5 : 1 }}
              >
                <Icon name='arrow-upward-outline' width={20} height={20} fill={theme['color-primary-500']} />
              </TouchableOpacity>
              <TouchableOpacity
                onPress={onMoveDown}
                disabled={isLast}
                style={{ marginRight: 10, opacity: isLast ? 0.5 : 1 }}
              >
                <Icon name='arrow-downward-outline' width={20} height={20} fill={theme['color-primary-500']} />
              </TouchableOpacity>
            </View>
            <Text>{index + 1}. {routeLocation.name}</Text>
          </View>
          <Button
            onPress={onRemove}
            appearance='ghost'
            status='danger'
            size='small'
          >
            <Icon name='trash-2-outline' width={15} height={15} fill='red' />
          </Button>

        </View>
      )}
      footer={() => (
        <View style={{ flex: 1 }}>
          <View style={{ flexDirection: 'row', flex: 1, justifyContent: 'flex-end' }}>
            <Button
              onPress={() => {
                try {
                  onSetRouteLocationType(index, ROUTE_LOCATION_TYPE.PICKUP);
                } catch (error) {
                  console.log(error);
                }

              }}
              appearance={routeLocation.route_location_type === ROUTE_LOCATION_TYPE.PICKUP ? 'filled' : 'outline'}
              status='danger'
              size='small'
            >
              Pickup
            </Button>
            <Button
              onPress={() => {
                try {
                  onSetRouteLocationType(index, ROUTE_LOCATION_TYPE.DELIVERY);
                } catch (error) {
                  console.log(error);
                }
              }}
              appearance={routeLocation.route_location_type === ROUTE_LOCATION_TYPE.DELIVERY ? 'filled' : 'outline'}
              status='danger'
              size='small'
            >
              Delivery
            </Button>
          </View>
        </View>
      )}
    >
      <View style={{ flexDirection: 'row', margin: -15 }}>
          <Text style={{ }}>{buildLocationDescription(routeLocation)}</Text>

        </View>
    </Card >
  );

return (
  <ListItem
    title={`${index + 1}. ${routeLocation.name}`}
    description={buildLocationDescription(routeLocation)}
    accessoryLeft={() => (
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <TouchableOpacity
          onPress={onMoveUp}
          disabled={isFirst}
          style={{ marginRight: 5, opacity: isFirst ? 0.5 : 1 }}
        >
          <Icon name='arrow-upward-outline' width={20} height={20} fill={theme['color-primary-500']} />
        </TouchableOpacity>
        <TouchableOpacity
          onPress={onMoveDown}
          disabled={isLast}
          style={{ marginRight: 10, opacity: isLast ? 0.5 : 1 }}
        >
          <Icon name='arrow-downward-outline' width={20} height={20} fill={theme['color-primary-500']} />
        </TouchableOpacity>
      </View>
    )}
    accessoryRight={() => (
      <TouchableOpacity
        onPress={onRemove}
        style={{ padding: 5 }}
      >
        <Icon name='trash-2-outline' width={20} height={20} fill={theme['color-danger-500']} />
      </TouchableOpacity>
    )}
    onPress={onPress}
    style={{
      backgroundColor: isSelected ? theme['color-success-900'] : theme['color-basic-800'],
    }}
  />
);
};


