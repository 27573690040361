import { JobClientRate, LINE_ITEM_BILLED_TO } from "./Job.model"
import { JobAddress, JobLocation } from "./Job.model";

export class Client {
    _id!: string | null;
    company_id!: string | null;
    linkedClientAccountId!: string | null;
    details!: string;
    name!: string;
    settings!: ClientSettings;
    account_code!: string;
    contact_info!: ClientContactInfo;
    deleted!: boolean;
    other!: ClientOther;
    createdAt!: string;
    updatedAt!: string;

    constructor(client: any) {
        this._id = client?._id;
        this.company_id = client?.company_id;
        this.linkedClientAccountId = client?.linkedClientAccountId;
        this.details = client?.details;
        this.name = client?.name;
        this.settings = new ClientSettings(client?.settings);
        this.account_code = client?.account_code;
        this.contact_info = new ClientContactInfo(client?.contact_info);
        this.deleted = client?.deleted;
        this.other = new ClientOther(client?.other);
        this.createdAt = client?.createdAt;
        this.updatedAt = client?.updatedAt;
    }
}

interface IClientSettings {      
    rates?: JobClientRate[]
    default_billed_to?: LINE_ITEM_BILLED_TO
}

export class ClientSettings implements IClientSettings {
    rates: JobClientRate[] = []
    default_billed_to: LINE_ITEM_BILLED_TO = LINE_ITEM_BILLED_TO.BILL_ALL_BACK

    constructor(settings: IClientSettings | null = null) {
        this.rates = settings?.rates || [] as JobClientRate[]
        this.default_billed_to = settings?.default_billed_to || LINE_ITEM_BILLED_TO.BILL_ALL_BACK
    }
}

interface IClientContactInfo {  
    email: string
    phone: string
    address: JobAddress
    location: JobLocation   
}

export class ClientContactInfo implements IClientContactInfo {
    email: string = ""
    phone: string = ""
    address: JobAddress = new JobAddress({})
    location: JobLocation = new JobLocation({})

    constructor(contact_info: IClientContactInfo) {
        this.email = contact_info?.email
        this.phone = contact_info?.phone

        if (typeof contact_info?.address == "string") {
            this.address = new JobAddress({formatted_address: contact_info?.address})
        } else {
            this.address = new JobAddress(contact_info?.address)
        }
        this.location = new JobLocation(contact_info?.location)
    }
}


interface IClientOther {
    details: string
}

export class ClientOther implements IClientOther {
    details: string = ""

    constructor(other: IClientOther) {
        this.details = other?.details
    }
}