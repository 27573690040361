import React, { useState, useEffect, useReducer, useCallback, useRef, useMemo } from 'react';
//@ts-ignore
import { StyleSheet, Text as RNText, Platform, useWindowDimensions, View, AppState, TouchableOpacity, ScrollView } from "react-native";
//@ts-ignore
import { Button, Icon, Layout, Card, Input, Modal, Text, Spinner, RangeDatepicker, CheckBox, List } from "@ui-kitten/components";

export const CounterBadge = ({
    status,
    children
}: { status: string, children: any }) => {
    return (
        <Layout style={{
            margin: 2,
            padding: 2,
            paddingBottom: 0,
            borderRadius: 5,
            backgroundColor: status == "unassigned" ? "red" :
                status == "warning" ? "orange" :
                    status == "request" ? "#ff34fb" :
                        status == "quote" ? "grey" :
                            status == "complete" ? "green" :
                                status == "purple" ? "purple" :
                                    status == "pending" ? "blue" :
                                        "grey"
        }}>
            <Text style={{ color: "white", textShadowColor: "black", textShadowOffset: { width: 1, height: 1 }, textShadowRadius: 2 }}>
                {children}
            </Text>
        </Layout>
    )
}