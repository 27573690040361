import React, { useState, useEffect, useRef, useReducer, useMemo } from 'react';
//@ts-ignore
import { StyleSheet, View, ScrollView, Image, Animated, TouchableOpacity } from 'react-native';
import { Layout, Text, Button, Divider, List, Modal, Card, Input, Icon, ListItem } from '@ui-kitten/components';
import * as UpdateController from '../../../functions/update.controller';
import * as UserController from '../../../functions/user.controller';
import * as StorageController from "../../../functions/storageController"
import * as MessageController from "../../../functions/message.controller"
import * as MembershipController from '../../../functions/membership.controller';
import { Member } from '../../../models/Member.model';


/**
 * Shows 1 group of members (admin, managers, drivers, etc)
 * @param {*} param0 
 * @returns 
 */
export const MembersMessageList = ({
    members = [] as Member[],
    onSelectMember = (m: Member) => { },
    expanded = true,
    onSetExpanded = (b: boolean) => { },
    unreadIds = [] as string[],
    heading = "",
}) => {

    const [selectedMember, setSelectedMember] = useState(null);
    const [onlineMembers, setOnlineMembers] = useState(0);

    const toggleExpanded = () => {
        onSetExpanded(!expanded);
    }

    const expandedStyle = {
        flex: 10,
        backgroundColor: "rgba(128, 128, 128, 0.5)",
        marginBottom: 3,
        width: "100%",
    }

    const collapsedStyle = {
        width: "100%",
        height: 40,
        marginBottom: 3,
    }


    useEffect(() => {
        let count = 0
        // if (!members) return count
        try {
            if (members.length == 0) return
            members.forEach((member: Member) => {
                if (member?.online) {
                    count++
                }
            });
            setOnlineMembers(count)
        } catch (e) {
            console.log("error counting online members", e)
            // return
        }
    }, [members])

    useEffect(() => {
        if (unreadIds.length > 0) {
            // console.log("unreadIds", unreadIds)
        }
    }, [unreadIds])



    const UnreadIcon = (props: any) => (
        <Icon {...props} name='message-circle-outline' />
    );


    const pulseValue = useRef(new Animated.Value(1)).current;
    useEffect(() => {
        // Start the pulse animation when the component mounts
        Animated.loop(
            Animated.sequence([
                Animated.timing(pulseValue, {
                    toValue: 1.2,
                    duration: 500,
                    useNativeDriver: true,
                }),
                Animated.timing(pulseValue, {
                    toValue: 1,
                    duration: 500,
                    useNativeDriver: true,
                }),
            ])
        ).start();
    });

    const RenderItemIcon = ({ member, unread }: { member: Member, unread: boolean }) => {
        const names = member.name.split(' ');
        let firstName = '';
        let initials = '';

        if (names.length === 1) {
            firstName = names[0];
        } else {
            firstName = names[0];
            initials = names.slice(1).map(name => name[0]).join(' ').toUpperCase();
        }

        const memberInitials = initials ? `${firstName} ${initials[0]}` : firstName;
        // const memberInitials = member.name.split(' ').map((name) => name[0]).join('').toUpperCase();
        const containerWidth = 45; // Width of the container
        const borderWidth = 10
        const innerBorderWidth = 10; // Width of the inner border ring
        const maxFontSize = 28; // Maximum font size
        const fontSize = Math.min(maxFontSize, containerWidth / (memberInitials.length / 2));
        let isSelected = false
        return (
            <View style={[styles.container]}>
                {unread &&
                    <View style={{ height: 20, width: 20, borderRadius: 50, position: 'absolute', top: -4, right: 0, textAlign: "center", zIndex: 1 }}>
                        <Animated.View style={{ transform: [{ scale: pulseValue }], backgroundColor: member.online ? "green" : "red", borderRadius: 50 }}>
                            <UnreadIcon style={{ width: 25, height: 25 }} />
                        </Animated.View>
                    </View>
                }
                <View style={[
                    member.online ? styles.onlineContainer : styles.offlineContainer,
                    // { backgroundColor: getColorFromId(member.user._id) }
                ]}>
                    <View
                        style={[
                            styles.innerRing,
                            {
                                width: containerWidth - borderWidth * 2 - innerBorderWidth * 2,
                                height: containerWidth - borderWidth * 2 - innerBorderWidth * 2,
                                borderRadius: 50,
                                borderWidth: innerBorderWidth,
                                backgroundColor: member.online ? "#004920" : "#B20000",
                                borderColor: member.online ? "#004920" : "#B20000",
                            },
                        ]}
                    >
                        <Text adjustsFontSizeToFit={true} style={[styles.avatar, { textAlign: 'center' }]}>{memberInitials}</Text>
                    </View>
                    {/* Unread */}
                </View>
            </View>
        )
    }


    const RenderMemberListItem = ({ item, index }: { item: Member, index: number }) => {
        const member = item
        if (member.deleted) {
            return null
        }
        var unread = unreadIds.includes(member._id)
        return (
            <Layout style={{ flex: 1, flexDirection: 'column', backgroundColor: "Transparent", paddingRight: 10 }}>
                <ListItem
                    // contentContainerStyle={{ paddingLeft: 0 }}
                    accessoryLeft={<RenderItemIcon member={member} unread={unread} />}
                    onPress={() => onSelectMember(member)}
                    style={{ backgroundColor: "Transparent", padding: 0 }}
                >
                </ListItem>
            </Layout>
        )
    }

    return (
        <View
            style={expanded ? expandedStyle : collapsedStyle}
        >
            <TouchableOpacity onPress={toggleExpanded}>
                <View style={{ flexDirection: 'row', height: 20, backgroundColor: "rgba(128, 128, 128, 1)", borderTopLeftRadius: 15, borderTopRightRadius: 15, justifyContent: 'flex-end' }}>
                    <Text style={{ color: "white", textAlign: "center", fontSize: 12 }}>{heading}:</Text>
                    <Text style={{ backgroundColor: 'green', borderRadius: 30, color: "white", textAlign: "center", fontSize: 12, padding: 1 }}>{onlineMembers} </Text>
                    {expanded ?
                        <Icon name={'arrow-ios-downward-outline'} width={20} height={20} fill='#fff' />
                        :
                        <Icon name={'arrow-ios-upward-outline'} width={20} height={20} fill='#fff' />
                    }
                </View>
            </TouchableOpacity>
            {expanded &&
                <List
                    style={{ backgroundColor: 'transparent' }}
                    data={members.sort((a, b) => {
                        const isAOnline = a.online;
                        const isBOnline = b.online;
                        const isAUnread = unreadIds.includes(a._id);
                        const isBUnread = unreadIds.includes(b._id);

                        // Sort by online status
                        if (isAOnline && !isBOnline) {
                            return -1; // a comes before b
                        } else if (!isAOnline && isBOnline) {
                            return 1; // a comes after b
                        }

                        // Sort by unread messages within online members
                        if (isAUnread && !isBUnread) {
                            return -1; // a comes before b
                        } else if (!isAUnread && isBUnread) {
                            return 1; // a comes after b
                        }

                        // If both have the same online status and unread status, maintain the existing order
                        return 0;
                    })}

                    renderItem={RenderMemberListItem}
                />
            }
        </View>
    )
}




const styles = StyleSheet.create({
    container: {
        flexDirection: "row",
        alignItems: "center",
        marginVertical: -10,
    },
    onlineContainer: {
        width: 70,
        left: 0,
        height: 48,
        borderRadius: 24,
        backgroundColor: "#004920",
        borderColor: "green",
        borderWidth: 7,
        justifyContent: "center",
        alignItems: "center",
    },
    offlineContainer: {
        width: 70,
        left: 0,
        height: 48,
        borderRadius: 24,
        backgroundColor: "#B20000",
        borderColor: "red",
        borderWidth: 7,
        justifyContent: "center",
        alignItems: "center",
    },
    selectedAvatarContainer: {
        backgroundColor: "#007aff",
        borderColor: "#007aff",
    },
    avatar: {
        color: "white",
        fontWeight: "bold",
        textShadowColor: "black",
        textShadowOffset: { width: -1, height: 1 }
    },
    innerRing: {
        position: "absolute",
        justifyContent: "center",
        alignItems: "center",
        // backgroundColor: "transparent",
    }
});