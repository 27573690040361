import React from 'react';
import { BaseClientDetailsCard } from './BaseClientDetailsCard';
import { NewJobClientDetailsCardProps } from './ClientDetailsCardTypes';

export function NewJobClientDetailsCard(props: NewJobClientDetailsCardProps) {
  return (
    <BaseClientDetailsCard
      {...props}
      cardStatus="info"
      showClientSelect={true}
      showRateSelect={true}
    />
  );
}