// indexedDB.js
import { set, get, del } from 'idb-keyval';
//@ts-ignore
import CryptoJS from 'crypto-js';

// Function to set a value
export const setItem = async (key: string, value: any, encrypt: boolean) => {
    try {
        if (!encrypt) {
            await set(key, value);
            return;
        }
        const decryptKey = await get('access_token');
        const encryptedData = CryptoJS.AES.encrypt(value, decryptKey || "").toString();
        await set(key, encryptedData);
    } catch (error) {
        console.error('Error setting item in IndexedDB', error);
    }
};

// Function to get a value
export const getItem = async (key: string, decrypt: boolean) => {
    try {
        const value = await get(key);
        if (!value) return null;
        if (!decrypt) return value;
        const decryptKey = await get('access_token');
        const bytes = CryptoJS.AES.decrypt(value, decryptKey || "");
        const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
        return decryptedData;
    } catch (error) {
        console.error('Error getting item from IndexedDB', error);
    }
};

// Function to remove a value
export const removeItem = async (key: string) => {
    try {
        await del(key);
    } catch (error) {
        console.error('Error removing item from IndexedDB', error);
    }
};
