import React, { useState, useEffect, useReducer, useCallback, useRef, useMemo } from 'react';
//@ts-ignore
import { StyleSheet, Text as RNText, Platform, useWindowDimensions, View, AppState, TouchableOpacity, ScrollView } from "react-native";
//@ts-ignore
import { Button, Icon, Layout, Card, Input, Modal, Text, Spinner, RangeDatepicker, CheckBox, List } from "@ui-kitten/components";
import { SafeAreaView } from 'react-native-safe-area-context';
//@ts-ignore
import debounce from 'lodash.debounce';
import JobsList from '../jobsList.component';
import { JobDetailContainer } from '../details/jobDetailContainer.component'
import { HoldingList } from '../holdingList.component';
import { CaseList } from '../casesList.component';
import DriversList from '../../Drivers/DriversList.component';
import AssignJobModal from '../modals/assignJobModal.component';
import Map from '../../map/map.component';
import * as JobsController from '../../../functions/job.controller'
import * as MembershipsController from '../../../functions/membership.controller'
import * as UpdateController from '../../../functions/update.controller'
import * as ClientController from '../../../functions/client.controller'
import * as StorageController from '../../../functions/storageController'
import * as ServiceController from '../../../functions/service.controller'
import * as HoldingController from '../../../functions/holding.controller'
import * as MqttService from '../../../services/mqtt.service'
import * as CaseController from '../../../functions/case.controller'
import * as RouteController from '../../../functions/route.controller'
import * as CompanyController from '../../../functions/company.controller'
import ErrorBoundary from '../../ErrorBoundary.component';
import _log from '../../../utils/log'
import CreateJobModal from '../modals/createJobModal.component';
import { CreateRouteContainer } from '../../routes/createRouteContainer.component';
import { CreateHoldingContainer } from '../holding/createHolding.component';
import { HoldingDetailsModalContainer } from '../holding/holdingDetailModal';
import { HoldingDetailsContainer } from '../holding/holdingDetailsContainer.component';
import { Job, Service, JOB_STATUS } from '../../../models/Job.model';
import { Company } from '../../../models/Company.model';
import { Member } from '../../../models/Member.model';
import { Case } from '../../../models/Case.model';
import { HOLDING_STATUS, Holding } from '../../../models/Holding.model';
import { Client } from '../../../models/Client.model';
import { Route } from '../../../models/Route.model';
import { JobReportModal, FullJobReport } from '../../dashboardComponents/jobReportModal.component';
import { CreateJobFromHoldingModal } from '../holding/createJobFromHoldingModal';
import { CalendarContainer } from '../../schedule/calendarContainer.components';
import { CreateCaseContainer } from '../../cases/createCaseContainer.component';
import { CaseDetailsContainer } from '../../cases/caseDetailsContainer.component';
import Toast from 'react-native-toast-message';
import { useAppStateChange, IAction, STATE_ACTIONS } from '../../../hooks/appStateChange.hook';
import { RoutesList } from '../../routes/routesList.component';
import { CounterBadge } from './CounterBadge';

enum JOB_STATUS_FILTER {
    // ALL = "all",
    INCOMPLETE = "incomplete", //all
    REQUEST = "request",
    QUOTE = "quote",
    UNASSIGNED = "unassigned",
    ASSIGNED = "assigned",
    PENDING = "pending",
    COMPLETE = "complete",
    CANCELLED = "cancelled"
}


interface JobStatusFilterButtonsProps {
    onSetFilterApplied: (filter: JOB_STATUS_FILTER) => void;
    filterApplied: JOB_STATUS_FILTER;
    setShowFilters: (show: boolean) => void;
    fetchedJobsRef: React.MutableRefObject<any[]>;
    style: any;
}

export const JobStatusFilterButtons = ({
    onSetFilterApplied,
    filterApplied,
    setShowFilters,
    fetchedJobsRef,
    style
}: JobStatusFilterButtonsProps) => {
    return (
        <View style={style}>
            <Layout style={styles.filter_button_container}>
                <Button onPress={() => setShowFilters(true)} size="small" status="warning" style={{ width: "5%", margin: 2, height: 20 }}>
                    <Icon name="funnel-outline" width={20} height={20} fill="white" />
                </Button>
                <Button
                    style={styles.filter_button_style}
                    onPress={() => onSetFilterApplied(JOB_STATUS_FILTER.INCOMPLETE)}
                    status='primary'
                    size="small"
                    appearance={filterApplied == JOB_STATUS_FILTER.INCOMPLETE ? 'filled' : 'outline'}
                >
                    <Text>
                        All
                    </Text>
                </Button>
                <Button
                    style={styles.filter_button_style}
                    onPress={() => onSetFilterApplied(JOB_STATUS_FILTER.QUOTE)}
                    status='quote'
                    size="small"
                    appearance={filterApplied == JOB_STATUS_FILTER.QUOTE ? 'filled' : 'outline'}
                >
                    <Text>
                        Quote
                        <View style={{ position: "absolute", top: -15, right: 0 }}>
                            <RenderStatusCountBadge fetchedJobsRef={fetchedJobsRef} status={"quote"} />
                        </View>
                    </Text>
                </Button>
                <Button
                    style={styles.filter_button_style}
                    onPress={() => onSetFilterApplied(JOB_STATUS_FILTER.REQUEST)}
                    status='request'
                    size="small"
                    appearance={filterApplied == JOB_STATUS_FILTER.REQUEST ? 'filled' : 'outline'}
                >
                    <Text>
                        Request
                        <View style={{ position: "absolute", top: -15, right: 0 }}>
                        <RenderStatusCountBadge fetchedJobsRef={fetchedJobsRef} status={"request"} />
                    </View>
                    </Text>
                </Button>
                <Button
                    style={styles.filter_button_style}
                    onPress={() => onSetFilterApplied(JOB_STATUS_FILTER.UNASSIGNED)}
                    status='unassigned'
                    size="small"
                    appearance={filterApplied == JOB_STATUS_FILTER.UNASSIGNED ? 'filled' : 'outline'}
                >
                    <Text>
                    Unassigned
                    <View style={{ position: "absolute", top: -15, right: 0 }}>
                            <RenderStatusCountBadge fetchedJobsRef={fetchedJobsRef} status={"unassigned"} />
                        </View>
                    </Text>
                </Button>
                <Button
                    style={styles.filter_button_style}
                    onPress={() => onSetFilterApplied(JOB_STATUS_FILTER.ASSIGNED)}
                    status='assigned'
                    size="small"
                    appearance={filterApplied == JOB_STATUS_FILTER.ASSIGNED ? 'filled' : 'outline'}
                >
                    <Text>
                        Assigned
                        <View style={{ position: "absolute", top: -15, right: 0 }}>
                        <RenderStatusCountBadge fetchedJobsRef={fetchedJobsRef} status={"assigned"} />
                    </View>
                    </Text>
                </Button>
                <Button
                    style={styles.filter_button_style}
                    onPress={() => onSetFilterApplied(JOB_STATUS_FILTER.PENDING)}
                    status='pending'
                    size="small"
                    appearance={filterApplied == JOB_STATUS_FILTER.PENDING ? 'filled' : 'outline'}
                >
                    <Text>
                        Pending
                        <View style={{ position: "absolute", top: -15, right: 0 }}>
                        <RenderStatusCountBadge fetchedJobsRef={fetchedJobsRef} status={"pending"} />
                    </View>
                    </Text>
                </Button>
                <Button
                    style={styles.filter_button_style}
                    onPress={() => onSetFilterApplied(JOB_STATUS_FILTER.COMPLETE)}
                    status='complete'
                    size="small"
                    appearance={filterApplied == JOB_STATUS_FILTER.COMPLETE ? 'filled' : 'outline'}
                >

                    Complete
                </Button>
                <Button
                    style={styles.filter_button_style}
                    onPress={() => onSetFilterApplied(JOB_STATUS_FILTER.CANCELLED)}
                    status='cancelled'
                    size="small"
                    appearance={filterApplied == JOB_STATUS_FILTER.CANCELLED ? 'filled' : 'outline'}
                >
                    Cancelled
                </Button>
            </Layout>
        </View>
    )
};




const RenderStatusCountBadge = ({ status, fetchedJobsRef }: any) => {
    if (!fetchedJobsRef?.current) return (<></>)
    try {
        const count = fetchedJobsRef.current.filter((job: Job) => job.status == status).length
        if (count == 0) return null
        return (
            <ErrorBoundary>
                <CounterBadge
                    status={
                        status == "unassigned" ? "unassigned" :
                            status == "request" ? "request" :
                                status == "quote" ? "quote" :
                                    status == "pending" ? "pending" :
                                        status == "complete" ? "complete" :
                                            status == "cancelled" ? "purple" :
                                                status == "assigned" ? "warning" :
                                                    "basic"}
                >
                    {fetchedJobsRef.current.filter((job: Job) => job.status == status).length}
                </CounterBadge>
            </ErrorBoundary>

        )
    } catch (e) {
        _log("ERROR", e)
        return <></>
    }
}


const styles = StyleSheet.create(
    {
        filter_row: {
            flexDirection: 'row',
            flex: 1,
            justifyContent: 'space-between',
            padding: 7,
            gap: 2
        },
        filter_row_wrap: {
            flexDirection: 'row',
            // flex: 1,
            justifyContent: 'space-between',
            padding: 7,
            gap: 2,
            flexWrap: 'wrap'
        },
        filter_button_container: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            padding: 5,
            gap: 2,
            height: "60%",
            flexWrap: 'wrap'
        },
        filter_button_style: {
            flex: 1,
            minWidth: 70,
            // maxWidth: 80,
            // flexBasis: 80,
        },
        view_button_style: {
            flex: 1,
            minWidth: 50,
            // maxWidth: 80,
            // flexBasis: 80,
        },
        content: {
            flex: 18,
            flexDirection: "column",
        },
        main_area: {
            flexDirection: "row",
            height: "100%",
            width: "100%",
            padding: 0,
            margin: 0
        },
        list_section: {
            flex: 2,
            justifyContent: 'center',
            height: "100%",
            boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.5)",
            zIndex: 2
        },
        map_container: {
            flex: 3,
            justifyContent: 'center',
            display: 'flex',
            height: "100%"
        },
        list_detail_container: {
            flex: 4,
            justifyContent: 'center',
            display: 'flex',
            height: "100%"
        },
        job_details_modal_backdrop: {
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 100
        },
        job_details_modal_container: {
            position: 'absolute',
            top: '0%',
            right: '5%',
            left: '5%',
            bottom: '0%',
            zIndex: 100000
        }
    }
);