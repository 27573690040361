import React, { useState, useEffect, useReducer } from 'react';
import { StyleSheet, Text as RNText, Platform, useWindowDimensions } from "react-native";
import { Button, Icon, Layout, Card, Input, Modal, Text, CheckBox } from "@ui-kitten/components";

import * as VendorProfileController from "../../functions/vendorProfile.controller";
import * as StorageController from "../../functions/storageController";
import * as CompanyController from "../../functions/company.controller";


const useInputState = (initialValue = '') => {
    const [value, setValue] = React.useState(initialValue);
    return { value, onChangeText: setValue };
};


export const NewVendor = ({
    onAddVendor,
    onClose,
}) => {
    const [ignored, forceUpdate] = useReducer(x => x + 1, 0);
    const [modalVisible, setModalVisible] = useState(false);

    const inputVendorName = useInputState();
    const inputVendorEmail = useInputState();
    const inputVendorPhone = useInputState();
    const inputVendorDetails = useInputState();
    const inputVendorAddress = useInputState();
    const inputVendorAccountCode = useInputState();

    const vendorAccountLinkingId = useInputState();
    const [showConfirmLinkVendorAccount, setShowConfirmLinkVendorAccount] = useState(false)
    const [validLinkedCompany, setValidLinkedCompany] = useState(null)

    const [checkboxIsAdmin, setCheckboxIsAdmin] = useState(false);
    const [checkboxIsDriver, setCheckboxIsDriver] = useState(false);
    const [checkboxIsOperator, setCheckboxIsOperator] = useState(false);



    useEffect(
        () => {

        },
        []
    )

    //function to verify inputs
    const verifyInputs = () => {
        if (inputVendorName.value === '') {
            alert("Please enter a name")
            return false
        }
        if (inputVendorEmail.value === '') {
            alert("Please enter an email")
            return false
        }
        if (inputVendorDetails.value === '') {
            alert("Please enter details")
            return false
        }
        // if (inputVendorAccountCode.value === '') {
        //     alert("Please enter an account code eg 200")
        //     return false
        // }
        // if (inputVendorPhone.value === '') {
        //     alert("Please enter a phone number")
        //     return false
        // }
        // if (inputVendorAddress.value === '') {
        //     alert("Please enter an account code eg 200")
        //     return false
        // }
        return true
    }

    const verifyAndSubmit = () => {
        if (verifyInputs()) {
            let newVendorProfile = {
                name: inputVendorName.value,
                company_id: StorageController.getCurrentCompany()._id,
                contact_info: {
                    email: inputVendorEmail.value,
                    phone: inputVendorPhone.value,
                    address: inputVendorAddress.value
                },
                details: inputVendorDetails.value,
                account_code: inputVendorAccountCode.value
            }
            console.log("🚀 ~ file: newVendor.component.js:89 ~ verifyAndSubmit ~ newVendorProfile:", newVendorProfile)
            VendorProfileController.createVendorProfile(newVendorProfile).then(res => {
                console.log("🚀 ~ file: newVendor.component.js:137 ~ VendorProfileController.createVendorProfile ~ res", res)
                onAddVendor(res)
                onClose()
            }).catch(err => {
                console.log("🚀 ~ file: newVendor.component.js:139 ~ VendorProfileController.createVendorProfile ~ err", err)
            })
        }
        console.log("in verify and submit")
    }

    
    const checkAccountLinkingCode = async () => {
        if (vendorAccountLinkingId.value === '') {
            alert("Please enter a code")
            return false
        }
        const company = await CompanyController.getCompanyById(vendorAccountLinkingId.value)
        console.log("🚀 ~ file: newVendor.component.js:153 ~ checkAccountLinkingCode ~ company", company)
        if(company._id){
            setValidLinkedCompany(company)
            setShowConfirmLinkVendorAccount(true)
        }
        else{
            setValidLinkedCompany(null)
            alert("No company found")
        }
    }

    const linkVendorAccount = async () => {
        if(validLinkedCompany){
            setShowConfirmLinkVendorAccount(false)
            inputVendorName.onChangeText(validLinkedCompany.name)
            const result = await VendorProfileController.createVendorProfileByLinkingId(validLinkedCompany._id)
            console.log("🚀 ~ file: newVendor.component.js:169 ~ linkVendorAccount ~ result", result)
            onClose()
            // inputVendorName.value = validLinkedCompany.name
        }
    }





    return (
        <Layout style={{ flex: 1, flexDirection: 'column', padding: 20, border: '1px solid black' }}>
            <Modal
                visible={showConfirmLinkVendorAccount}
                backdropStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
                onBackdropPress={() => setShowConfirmLinkVendorAccount(false)}>
                <Card disabled={true}>
                    <Text>Are you sure you want to link this account?</Text>
                    <Text>Company Name: {validLinkedCompany ? validLinkedCompany.name : ""}</Text>
                    <Layout style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Button
                            appearance='outline'
                            status='danger'
                            onPress={() => { setShowConfirmLinkVendorAccount(false) }}>
                            Cancel
                        </Button>
                        <Button

                            appearance='outline'
                            status='success'
                            onPress={() => { linkVendorAccount() }}>
                            Link
                        </Button>
                    </Layout>
                </Card>
            </Modal>
            <Layout style={{ flex: 1 }}>
                <Text category='h6'>
                    Add Vendor:
                </Text>
            </Layout>
            <Layout style={{ flex: 1, flexDirection: 'column', justifyContent: 'space-between' }}>
                <Layout style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Input
                        style={styles.input}
                        status='primary'
                        placeholder='Account Linking Id'
                        label='Account Linking Id'
                        {...vendorAccountLinkingId}
                    />
                    <Button
                        appearance='outline'
                        status='success'
                        onPress={() => { checkAccountLinkingCode() }}>
                        Check
                    </Button>
                </Layout>
            </Layout>
            <Layout style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between' }}>
                <Input
                    style={styles.input}
                    status='primary'
                    placeholder='Name'
                    label='Vendor Name'
                    {...inputVendorName}
                />
                <Input
                    style={styles.input}
                    status='primary'
                    placeholder='Email'
                    label='Vendor Email'
                    {...inputVendorEmail}
                />
                <Input
                    style={styles.input}
                    status='primary'
                    placeholder='Phone'
                    label='Vendor Phone'
                    {...inputVendorPhone}
                />
            </Layout>
            <Layout style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between' }}>
                <Input
                    style={styles.input}
                    status='primary'
                    placeholder='Address'
                    multiline={true}
                    textStyle={{ minHeight: 64 }}
                    label='Vendor Address'
                    {...inputVendorAddress}
                />
            </Layout>
            <Layout style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between' }}>
                <Input
                    style={styles.input}
                    status='primary'
                    placeholder='Details'
                    label='Vendor Details'
                    multiline={true}
                    textStyle={{ minHeight: 64 }}
                    {...inputVendorDetails}
                />
                <Input
                    style={styles.input}
                    status='primary'
                    placeholder='Account Code'
                    label='Account Code'
                    {...inputVendorAccountCode}
                />
            </Layout>
            <Layout style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between' }}>
                <Button
                    appearance='outline'
                    status='danger'
                    onPress={() => { onClose() }}>
                    Cancel
                </Button>
                <Button
                    appearance='outline'
                    status='success'
                    onPress={() => { verifyAndSubmit() }}>
                    Create
                </Button>
            </Layout>

        </Layout>
    )

}


const styles = StyleSheet.create({
    input: {
        flex: 1,
        margin: 2,
        padding: 6,
    },
    rowContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    controlContainer: {
        borderRadius: 4,
        margin: 2,
        padding: 6,
        backgroundColor: '#3366FF',
    },
});
