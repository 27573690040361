import React, { useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { Modal, Card, Text, Button, Input } from '@ui-kitten/components';
import { Job } from '../../models/Job.model';
import * as JobsController from '../../functions/job.controller';

interface VendorAcceptModalProps {
    showModal: boolean;
    job: Job;
    onAccept: (jobId: string, eta: number) => void;
    onClose: () => void;
}

export const VendorAcceptModal: React.FC<VendorAcceptModalProps> = ({
    showModal,
    job,
    onAccept,
    onClose,
}) => {
    const [eta, setEta] = useState(60);
    const [showConfirmDecline, setShowConfirmDecline] = useState(false);

    const onChangeEta = (nextValue: string) => {
        const numValue = parseInt(nextValue, 10);
        if (!isNaN(numValue)) {
            setEta(numValue);
        }
    };

    const handleAccept = () => {
        onAccept(job._id, eta);
        JobsController.vendorAcceptJob(job._id, eta);
        onClose();
    };

    const handleDecline = () => {
        JobsController.vendorDeclineJob(job._id);
        setShowConfirmDecline(false);
        onClose();
    };

    return (
        <Modal
            visible={showModal}
            backdropStyle={styles.backdrop}
            onBackdropPress={onClose}
        >
            <Card disabled={true}>
                {showConfirmDecline ? (
                    <View>
                        <Text>Are you sure you want to decline this job?</Text>
                        <Button
                            style={styles.button}
                            onPress={handleDecline}>
                            Yes
                        </Button>
                        <Button
                            style={styles.button}
                            onPress={() => setShowConfirmDecline(false)}>
                            No
                        </Button>
                    </View>
                ) : (
                    <>
                        <Button
                            style={styles.closeButton}
                            appearance='outline'
                            status='danger'
                            onPress={onClose}
                        >
                            X
                        </Button>
                        <Text>Vendor Accept</Text>
                        <Text>ETA (Minutes)</Text>
                        <Input
                            placeholder='ETA'
                            value={eta.toString()}
                            onChangeText={onChangeEta}
                            keyboardType="numeric"
                        />
                        <Button
                            appearance='outline'
                            status='success'
                            style={styles.button}
                            onPress={handleAccept}>
                            Accept
                        </Button>
                        <Button
                            appearance='outline'
                            status='danger'
                            style={styles.button}
                            onPress={() => setShowConfirmDecline(true)}>
                            Decline
                        </Button>
                    </>
                )}
            </Card>
        </Modal>
    );
};

const styles = StyleSheet.create({
    backdrop: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    button: {
        margin: 10,
    },
    closeButton: {
        position: "absolute",
        right: 0,
        top: 0,
    },
});