
import React, { useState, useEffect, useRef, useReducer, useCallback, useMemo, createContext, useContext } from 'react';
//@ts-ignore
import { useWindowDimensions, StyleSheet, View, TouchableOpacity, ScrollView } from "react-native";
import { SafeAreaView } from 'react-native-safe-area-context';
import * as XLSX from 'xlsx';
import DataGrid from 'react-data-grid';
//@ts-ignore
import { Divider, Icon, Layout, Text, TopNavigation, TopNavigationAction, Card, Button, Input, ButtonGroup, CheckBox, Modal, RangeDatepicker, Datepicker, useTheme, IndexPath, Select, SelectItem, List, ListItem } from '@ui-kitten/components';
import ErrorBoundary from '../ErrorBoundary.component';
import * as JobsController from '../../functions/job.controller'
import * as MembershipsController from '../../functions/membership.controller'
import * as UpdateController from '../../functions/update.controller'
import * as ImageController from '../../functions/image.controller'
import * as ClientController from '../../functions/client.controller'
import * as StorageController from '../../functions/storageController'
import * as DocumentController from '../../functions/document.controller'
import { Document, DocumentCategory, SharePermissions, SharedWith, DocumentCategoryPermissions, DocumentCategoryDetails } from '../../models/Document.model';
import { formatIsoDateTime, getDocumentTypeFromString } from '../../functions/utils';
import { Company } from '../../models/Company.model';
import EditSharedWithModal from './SharedWithModal.component';



interface EditCategoryModalProps {
    showModal: boolean;
    setShowModal: (visible: boolean) => void;
    selectedCategory: DocumentCategory | null;
    onSave: (category: DocumentCategory) => void;
}

const EditCategoryModal: React.FC<EditCategoryModalProps> = ({ showModal, setShowModal, selectedCategory, onSave }) => {
    const [title, setTitle] = useState(selectedCategory?.title || '');
    const [description, setDescription] = useState(selectedCategory?.description || '');
    const [sharedWithList, setSharedWithList] = useState<SharedWith[]>(selectedCategory?.shared_with || []);
    const [selectedSharedWith, setSelectedSharedWith] = useState<SharedWith | null>(null);
    const [showEditSharedWithModal, setShowEditSharedWithModal] = useState(false);
    const [localPermissions, setLocalPermissions] = useState<DocumentCategoryPermissions>(selectedCategory?.permissions || new DocumentCategoryPermissions({}));
    const companies = StorageController.getAppState().companies || [];
    const [infoText, setInfoText] = useState('');

    useEffect(() => {
        if (selectedCategory) {
            setTitle(selectedCategory.title);
            setDescription(selectedCategory.description);
            setSharedWithList(selectedCategory.shared_with || []);
            setLocalPermissions(selectedCategory.permissions);
        }
        return () => {
            setTitle('');
            setDescription('');
            setSharedWithList([]);
            setLocalPermissions(new DocumentCategoryPermissions({}));
        }
        // console.log("🚀============== ~ file: EditCategoryModal.component.tsx:47 ~ selectedCategory🚀==============", selectedCategory)
    }, [selectedCategory]);


    const infoTextMemo = useMemo(() => {
        let desc = ""
        const company = StorageController.getCurrentCompany()
        const client = company.clients?.find(c => c._id === selectedCategory?.permissions.client_id)
        if (client) {
            console.log("🚀============== ~ file: EditCategoryModal.component.tsx:139 ~ getClientCreatedBy ~ client🚀==============", client.name)
            desc += ` By: ${client.name} \n`
        }
        return desc
    }, [selectedCategory])


    const handleSaveCategory = async () => {
        if (selectedCategory) {
            const updatedCategory = new DocumentCategory({
                ...selectedCategory,
                title,
                description,
                shared_with: sharedWithList,
                permissions: localPermissions
            });
            try {
                // console.log("🚀============== ~ file: documentsContainerScreen.tsx:411 ~ handleSaveCategory ~ updatedCategory🚀==============", updatedCategory)
                // const result = await updatedCategory.save();
                const result = await DocumentController.updateDocumentCategory(updatedCategory);
                console.log('Saved category:', result);
                onSave(result);
                // setShowModal(false);
            } catch (error) {
                console.error('Error saving category:', error);
            }
        }
    };

    const handleAddNewShare = () => {
        setSelectedSharedWith(new SharedWith({}));
        setShowEditSharedWithModal(true);
    };

    const handleUpdateSharedWith = (updatedSharedWith: SharedWith) => {
        const updatedList = sharedWithList.map(sharedWith =>
            sharedWith._id === updatedSharedWith._id ? updatedSharedWith : sharedWith
        );
        if (!updatedList.find(s => s._id === updatedSharedWith._id)) {
            updatedList.push(updatedSharedWith);
        }
        setSharedWithList(updatedList);
        setShowEditSharedWithModal(false);
    };

    const handleDeleteSharedWith = (sharedWith: SharedWith) => {
        setSharedWithList(sharedWithList.filter(s => s._id !== sharedWith._id));
    };

    const renderPermissionIcon = (hasPermission: boolean) => (
        <Icon
            name={hasPermission ? 'checkmark-circle-2' : 'close-circle'}
            fill={hasPermission ? 'green' : 'red'}
            width={16}
            height={16}
        />
    );

    const onSetLocalPermissions = (role: string, permission: string, value: boolean) => {
        setLocalPermissions({
            ...localPermissions,
            //@ts-ignore
            [role]: { ...localPermissions[role], [permission]: value }
        });
    }

    if (!selectedCategory) return null;


    const canEditSharing = () => {
        let allowSharing = false;
        const company = StorageController.getCurrentCompany();
        const membership = StorageController.getAppState().selectedMembership
        const memberIsClient = membership?.is_client;
        const memberClientId = membership?.client_id;
        const isCompanyOwner = StorageController.getCurrentCompany()._id == selectedCategory.company_id
        if (!membership) return false;

        if (selectedCategory.permissions.is_client_created) {
            if (memberIsClient && selectedCategory.permissions.client_id == membership.client_id) {
                return true;
            } else {
                return false;
            }
        }
        return true;
    }








    return (
        <Modal
            visible={showModal}
            backdropStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
            onBackdropPress={() => setShowModal(false)}
        >
            <Card
                header={() => <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Text>Edit Category</Text>
                    <Button size="tiny" onPress={() => setShowModal(false)} status='danger'>
                        <Icon name='close-outline' fill='white' width={15} height={15} />
                    </Button>
                </View>}
                disabled
                status="info"
            >
                <View style={{ flexDirection: 'column', gap: 20 }}>
                    <Text>{infoTextMemo || ''}</Text>
                    <View style={{ flexDirection: 'column' }}>
                        <Input
                            label="Title"
                            placeholder="Enter title"
                            value={title}
                            onChangeText={setTitle}
                        />
                        <Input
                            label="Description"
                            placeholder="Enter description"
                            value={description}
                            onChangeText={setDescription}
                        />
                    </View>
                    {/* Add local company permissions using DocumentCategoryPermissions */}
                    <Card
                        header={() => <Text>Local Company Permissions</Text>}
                        status="warning"
                    >
                        <View style={{ margin: -15 }}>
                            <View style={{ flexDirection: 'column' }}>
                                <Text category="p2">Operator</Text>
                                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                    <CheckBox
                                        checked={localPermissions?.operator.read}
                                        onChange={(checked: boolean) => {
                                            onSetLocalPermissions('operator', 'read', checked);
                                        }}>
                                        Read
                                    </CheckBox>
                                    <CheckBox
                                        checked={localPermissions?.operator.write}
                                        onChange={(checked: boolean) => {
                                            onSetLocalPermissions('operator', 'write', checked);
                                        }}>
                                        Write
                                    </CheckBox>
                                </View>
                            </View>
                            <View style={{ flexDirection: 'column' }}>
                                <Text category="p2">Driver</Text>
                                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>

                                    <CheckBox
                                        checked={localPermissions?.driver.read}
                                        onChange={(checked: boolean) => {
                                            onSetLocalPermissions('driver', 'read', checked);
                                        }}>
                                        Read
                                    </CheckBox>
                                    <CheckBox
                                        checked={localPermissions?.driver.write}
                                        onChange={(checked: boolean) => {
                                            onSetLocalPermissions('driver', 'write', checked);
                                        }}>
                                        Write
                                    </CheckBox>
                                </View>
                            </View>
                            {!localPermissions.is_client_created &&
                                <View style={{ flexDirection: 'column' }}>
                                    <Text category="p2">Clients</Text>
                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>

                                        <CheckBox
                                            checked={localPermissions?.client.read}
                                            onChange={(checked: boolean) => {
                                                onSetLocalPermissions('client', 'read', checked);
                                            }}>
                                            Read
                                        </CheckBox>
                                        <CheckBox
                                            checked={localPermissions?.client.write}
                                            onChange={(checked: boolean) => {
                                                onSetLocalPermissions('client', 'write', checked);
                                            }}>
                                            Write
                                        </CheckBox>
                                    </View>
                                </View>
                            }
                        </View>
                    </Card>
                    {canEditSharing() &&
                        <View style={{ flexDirection: 'column', gap: 10 }}>
                            <Button
                                appearance='outline'
                                status='success'
                                onPress={handleAddNewShare}>
                                Add Share
                            </Button>
                            <View style={{ flexDirection: 'column' }}>

                                <Text>Shared With:</Text>
                                <List
                                    data={sharedWithList}
                                    ItemSeparatorComponent={Divider}
                                    renderItem={({ item }: { item: SharedWith }) => (
                                        <ListItem
                                            title={companies.find(c => c._id === item.company_id)?.name || 'Unknown'}
                                            // description={() => <View>{buildDescription(item.permissions)}</View>}
                                            accessoryRight={() => (
                                                <>
                                                    <Button
                                                        appearance='outline'
                                                        status='info'
                                                        onPress={() => {
                                                            setSelectedSharedWith(item);
                                                            setShowEditSharedWithModal(true);
                                                        }}>
                                                        <Icon name='edit-2-outline' fill='white' width={15} height={15} />
                                                    </Button>
                                                    <Button status='danger' appearance='ghost' onPress={() => handleDeleteSharedWith(item)}>
                                                        <Icon name='trash-2-outline' fill='red' width={15} height={15} />
                                                    </Button>
                                                </>
                                            )}
                                        />
                                    )}
                                />
                            </View>
                        </View>
                    }
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Button status='success' onPress={handleSaveCategory}>Save</Button>
                        <Button appearance='ghost' status='danger' onPress={() => setShowModal(false)}>Cancel</Button>
                    </View>
                </View>
            </Card>

            {showEditSharedWithModal && (
                <EditSharedWithModal
                    visible={showEditSharedWithModal}
                    onClose={() => setShowEditSharedWithModal(false)}
                    companies={companies}
                    sharedWith={selectedSharedWith}
                    onSave={handleUpdateSharedWith}
                />
            )}
        </Modal>
    );
};

export default EditCategoryModal;