import React, { useState, useEffect, useRef, useReducer, useCallback, useMemo, createContext, useContext } from 'react';
//@ts-ignore
import { useWindowDimensions, StyleSheet, View, TouchableOpacity } from "react-native";
import { SafeAreaView } from 'react-native-safe-area-context';
//@ts-ignore
import { Divider, Icon, Layout, Text, TopNavigation, TopNavigationAction, Card, Button, Input, ButtonGroup, CheckBox, Modal, RangeDatepicker, Datepicker, useTheme, IndexPath, Select, SelectItem, Spinner, Toggle } from '@ui-kitten/components';
import { JobInvoiceRow } from './invoicingTypes';


export const paymentFormatter = (value: any) => {
    if (value === 'undefined' || isNaN(value) || value === null || value === '') {
        return (
            <Text></Text>
        )
    } else {
        // add the $ sign and format the number
        // if value is a string, convert to a number
        value = typeof value === 'string' ? parseFloat(value) : value;
        return (
            <Text>${parseFloat(value).toFixed(2)}</Text>
        )
    }
}

export const totalPaymentFormatter = (v1: any, v2: any) => {
    // if value is a string, convert to a number
    v1 = typeof v1 === 'string' ? parseFloat(v1) : v1;
    v2 = typeof v2 === 'string' ? parseFloat(v2) : v2;
    if (isNaN(v1)) v1 = 0;
    if (isNaN(v2)) v2 = 0;
    const total = v1 + v2;
    return (
        <Text>${total.toFixed(2)}</Text>
    )
}


export const addressFormatter = (value: any) => {

    if (value) {
        // if any of the values are undefined, return an empty string for that value, otherwise return the value
        if (typeof value === 'string') return (<Text>{value}</Text>)

        let addrString = '';
        for (const key in value) {
            if (value[key] === undefined) value[key] = '';
            addrString += `${value[key]} `;
        }
        return (
            <Text>{addrString}</Text>
        )
    } else {
        return (
            <Text></Text>
        )
    }
}


export const itemRowFormatter = (row: JobInvoiceRow, value: string, colour = "rgba(255,255,0,0.3)") => {
    return (
        row.isSubRow &&
        <View style={{ flex: 1, backgroundColor: colour }}>
            <Text>{value}</Text>
        </View>
    )
}