import React, { useEffect, useRef } from 'react';
import {
    Image,
    AccessibilityRole,
    ImageProps,
    ImageStyle,
    StyleSheet,
    TouchableOpacity,
    View,
    Text as RNText,
    Animated
    //@ts-ignore
} from "react-native";
import {
    ApplicationProvider,
    Button,
    Icon,
    IconRegistry,
    Layout,
    Text,
    List,
    ListItem,
    Card
} from "@ui-kitten/components";
import { SafeAreaView } from 'react-native-safe-area-context';
import { EvaIconsPack } from "@ui-kitten/eva-icons";
import { mapping, light, dark } from "@eva-design/eva";
import JobListItem from '../job/jobListItem.component';
import { CompactJobListItem } from '../job/CompactJobListItem';
import JobsList from '../job/jobsList.component';
import { Job } from '../../models/Job.model';
import { Member } from '../../models/Member.model';

const ForwardIcon = () => (
    <Icon fill="#FFFFFF" name='arrow-ios-forward' />
);
const TruckIcon = () => (
    <Icon style={{ width: "10px" }} fill="#FFFFFF" name='car-outline' />
);

interface DriversJobListProps {
    var_driversJobs: Job[];
    fn_zoomToMarker: (job: Job) => void;
    fn_selectJob: (job: Job) => void;
    var_drivers: any;
    var_clients: any;
    fn_setDirections: (driver: Member, job: Job) => void;
    var_viewedBy: string;
}

const DriversJobList = ({
    var_driversJobs,
    fn_zoomToMarker,
    fn_selectJob,
    var_drivers,
    var_clients,
    fn_setDirections,
    var_viewedBy
}: DriversJobListProps) => {

    // console.log(props)

    const getDriver = (job: Job) => {
        return var_drivers.find((driver: Member) => driver._id === job.member_id)
    }

    const getClient = (job: Job) => {
        return var_clients.find((client: any) => client._id === job.client_id)
    }

    

    return (
        <Layout style={{ flex: 1}}>
            <JobsList
                showCompact={true}
                selectedJobId={null}
                jobs={var_driversJobs}
                fn_zoomToMarker={fn_zoomToMarker}
                fn_selectJob={fn_selectJob}
                var_viewedBy={var_viewedBy}
                var_vendorProfiles={[]}
                drivers={var_drivers}
                clients={var_clients}
                fn_setDirections={fn_setDirections}
                fn_openAssign={() => { } }
                fn_vendorAcceptJob={() => { } } 
                fn_onEndReached={() => { } }
            />
        </Layout>
    );



}

const styles = StyleSheet.create({

});


export default DriversJobList;
