// context/NotificationContext.js
import React, { createContext, useState } from 'react';


interface NotificationContextProps {
  notificationVisible: boolean;
  notificationTitle: string;
  notificationMessage: string;
  showNotification: (title: string, message: string, onOk?: () => void) => void;
  hideNotification: () => void;
  onOk: (() => void) | null;
}

const NotificationContext = createContext<NotificationContextProps | undefined>(undefined);

export const NotificationProvider = ({ children }: any) => {
  const [notificationVisible, setNotificationVisible] = useState(false);
  const [notificationTitle, setNotificationTitle] = useState('');
  const [notificationMessage, setNotificationMessage] = useState('');
  const [onOk, setOnOk] = useState<(() => void) | null>(null);

  const showNotification = (title: string, message: string, onOk: (() => void) | null = null) => {
    setNotificationTitle(title);
    setNotificationMessage(message);
    setOnOk(onOk);
    setNotificationVisible(true);
  };

  const hideNotification = () => {
    setOnOk(null);
    setNotificationVisible(false);
  };

  return (
    <NotificationContext.Provider
      //@ts-ignore
      value={{
        notificationVisible,
        notificationTitle,
        notificationMessage,
        showNotification,
        hideNotification,
        onOk
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};


/**
 * Hook to use the notification context
 * params: none
 * @returns {NotificationContext}
 */
export const useNotification = () => {
  const context = React.useContext(NotificationContext);
  if (!context) {
    throw new Error('useNotification must be used within a NotificationProvider');
  }
  return context;
}

export default NotificationContext;