var config = require('../config/config.js')
const axios = require('axios');
import { Client } from '../models/Client.model'
import * as StorageController from './storageController'
import { Company } from '../models/Company.model';

var api = config.api;
var testApi = config.test_api

function getApi() {
    if (StorageController.getAppState().use_test_api) {
        return testApi
    }
    return api
}


axios.interceptors.request.use(
    (config: any) => {
        let token = getToken();
        let member_id = null;
        if (StorageController.getAppState().selectedMembership != null) {
            member_id = StorageController.getAppState().selectedMembership?._id;
        }
        config.headers.authorization = `Bearer ${token}`;
        config.headers['member-id'] = member_id;
        return config;
    },
    (error: any) => {
        return Promise.reject(error);
    }
);

const getToken = async () => {
    return await StorageController.getAccessToken() || null;
}

//set selected driver in storage storagecontroller
export async function setSelectedClientToEdit(clientObj: Client) {
    StorageController.appState.selectedClientToEdit = clientObj
    StorageController.saveStateToDisk()
}

//get selected client
export async function getSelectedClientToEdit() {
    return StorageController.appState.selectedClientToEdit
}

// get clients by company id from StorageController
export async function getClientsLocalBySelectedCompany() {
    let companies = await StorageController.getAppState().companies || [] as Company[]
    let selectedCompany = await StorageController.getAppState().selectedCompany
    if (!selectedCompany) {
        return []
    }
    let clients = null;
    if (selectedCompany && companies) {
        clients = companies.find((company: Company) => company._id == selectedCompany?._id)?.clients;
    }
    return clients;
}



//getClientsByCompanyId
export async function getClientsByCompanyId(companyId: string) {
    try {
        const response = await axios.get(getApi() + "/client/company/" + companyId);
        //// console.log(response);
        //for each client get the user
        var clients = response.data
        //save clients to storage
        StorageController.appState.clients = clients
        return clients
    } catch (error) {
        console.error(error);
        //throw new Error(`HTTP error! status: ${response.status}`);
    }
}

/**
 * 
 * @param {*} clientCompanyId 
 */
export const createClientProfileByLinkingId = async (clientCompanyId: string) => {
    const vendorCompanyId = StorageController.getAppState().selectedCompany?._id
    try {
        let data = {
            clientCompanyId: clientCompanyId,
            vendorCompanyId: vendorCompanyId
        }
        const response = await axios.post(getApi() + "/clientvendor/linkclient", data)
        // console.log("🚀 ~ file: vendorProfile.controller:63 ~ getVendorProfileByCompanyId ~ response:", response)
        return response.data
    } catch (e) {
        // console.log("🚀 ~ file: vendorProfile.controller:66 ~ getVendorProfileByCompanyId ~ e:", e)
        return []
    }
}

/**
 * Link existing client with linkingid
 */

export const linkExistingClientAccount = async (clientCompanyId: string, client_id: string) => {
    const vendorCompanyId = StorageController.getAppState().selectedCompany?._id
    try {
        let data = {
            clientCompanyId: clientCompanyId,
            vendorCompanyId: vendorCompanyId,
            client_id: client_id
        }
        const response = await axios.post(getApi() + "/clientvendor/linkexistingclient", data)
        // console.log("🚀 ~ file: vendorProfile.controller:63 ~ getVendorProfileByCompanyId ~ response:", response)
        return response.data
    } catch (e) {
        // console.log("🚀 ~ file: vendorProfile.controller:66 ~ getVendorProfileByCompanyId ~ e:", e)
        return []
    }
}

/**
 * Gets client by vendor company id and linkedClientAccountId
 * @param {*} companyId 
 * @param {*} linkedClientAccountId 
 * @returns 
 */
export async function getClientByCompanyAndClientLinkingId(companyId: string, linkedClientAccountId: string) {
    try {
        const response = await axios.get(getApi() + "/client/company/" + companyId + "/linkedClientAccountId/" + linkedClientAccountId);
        //console.log(response);
        //for each client get the user
        var client = response.data
        //save clients to storage
        StorageController.appState.clients = client
        return client
    } catch (error) {
        console.error(error);
        //throw new Error(`HTTP error! status: ${response.status}`);
    }
}

//create client
export async function createClient(clientObj: Client) {

    try {
        const company = StorageController.getAppState().selectedCompany
        if (!company) {
            throw new Error("No company selected")
        }
        //@ts-ignore
        clientObj.company_id = StorageController.getAppState().selectedCompany?._id;
        const response = await axios.post(getApi() + "/client", clientObj);
        //console.log(response);
        return response.data
    } catch (error) {
        console.error(error);
        //throw new Error(`HTTP error! status: ${response.status}`);
    }
}

//update clientObj
export async function updateClient(clientObj: Client) {
    try {
        const response = await axios.put(getApi() + "/client/" + clientObj._id, clientObj);
        //console.log(response);
        return response.data
    } catch (error) {
        console.error(error);
        //throw new Error(`HTTP error! status: ${response.status}`);
    }
}


// get client by id
export async function getClientById(clientId: string) {
    try {
        const response = await axios.get(getApi() + "/client/" + clientId);
        //console.log(response);
        return response.data
    } catch (error) {
        console.error(error);
        //throw new Error(`HTTP error! status: ${response.status}`);
    }
}

//get client by id from StorageController
export async function getClientLocalById(clientId: string) {
    let clients = await StorageController.getAppState().clients
    let client = clients.find(client => client._id == clientId)
    return client
}

export async function tagClientDeleted(clientId: string) {
    try {
        const response = await axios.put(getApi() + "/client/tag/delete/" + clientId);
        //console.log(response);
        return response.data
    } catch (error) {
        console.error(error);
        //throw new Error(`HTTP error! status: ${response.status}`);
    }
}

export async function getAllClientsByAllCompanies() {
    const companies = await StorageController.getAppState().companies || []
    let clients = [] as Client[]
    try {
        for (var i = 0; i < companies.length; i++) {
            const company = companies[i]
            const companyClients = await getClientsByCompanyId(company._id)
            clients = clients.concat(companyClients)
            company.clients = companyClients
        }
        return clients
    }
    catch (error) {
        console.error(error);
        console.log(`error! status: ${error}`);
    }
}


// 622a12af6925f7e172ebfff5