import React, { useState, useEffect, useRef, useReducer, useCallback, useMemo, createContext, useContext } from 'react';
//@ts-ignore
import { useWindowDimensions, StyleSheet, View, TouchableOpacity, FlatList } from "react-native";
import { SafeAreaView } from 'react-native-safe-area-context';
//@ts-ignore
import { Divider, List, Icon, Layout, Text, TopNavigation, TopNavigationAction, Card, Button, Input, ButtonGroup, CheckBox, Modal, RangeDatepicker, Datepicker, useTheme, IndexPath, Select, SelectItem, Toggle, OverflowMenu, MenuItem, ListItem } from '@ui-kitten/components';
import ErrorBoundary from '../ErrorBoundary.component';
import * as GoogleController from '../../functions/google.controller'
//@ts-ignore
import debounce from 'lodash.debounce';


interface AddressSearchProps {
    fn_onSelect: (location: any) => void;
    style: any;
}

export const AddressSearch = ({
    fn_onSelect,
    style
}: AddressSearchProps) => {

    const [addressSearch, setAddressSearch] = React.useState({ term: '', fetchPredictions: false })
    const [predictions, setPredictions] = React.useState([] as google.maps.places.AutocompleteResponse["predictions"])
    const [showPredictions, setShowPredictions] = React.useState(false)

    //setting that is a location of company as an address
    const onSetAddress = async (result: google.maps.places.AutocompletePrediction) => {
        console.log("==== Address", result)
        setShowPredictions(false)
        const coords = await GoogleController.getPredictionsPlace(result.place_id)
        console.log("===coords", coords)
        let location = {
            address: result,
            coords: {
                lat: coords.result.geometry.location.lat,
                lng: coords.result.geometry.location.lng
            }
        }
        console.log("===location", location)
        fn_onSelect(location)
    }

    const onChangeText = async (term: string) => {
        const res = await GoogleController.getpredictionsAutocomplete(term)
        if (res) {
            setPredictions(res.predictions)
            setShowPredictions(true)
        }
    }
    const debouncedSave = useDebounce((nextValue: string) => onChangeText(nextValue), 1000);

    function useDebounce(callback: any, delay: number) {
        const debouncedFn = useCallback(
            debounce((...args: any) => callback(...args), delay),
            [delay] // will recreate if delay changes
        );
        return debouncedFn;
    }

    const AddressSearch = () => {
        return (
            <Input
                placeholder='Search Address'
                style={{ padding: 10 }}
                label='Search Address'
                value={addressSearch.term}
                onChangeText={(text: string) => {
                    setAddressSearch({ term: text, fetchPredictions: true })
                    debouncedSave(addressSearch.term)
                }}
            />
        )
    }


    return (
        <Layout style={style}>
            <OverflowMenu
                anchor={AddressSearch}
                visible={showPredictions}
                onBackdropPress={() => setShowPredictions(false)}
                style={{ width: '100%' }}
                fullWidth={true}
            >
                {showPredictions ? predictions.map((prediction, index) => (
                    <MenuItem
                        key={prediction.place_id}
                        title={prediction.description}
                        onPress={() => {
                            onSetAddress(prediction)
                        }}
                    />
                )) : ""}
            </OverflowMenu>
        </Layout>
    )


}