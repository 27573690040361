import React, { useState, useEffect, useRef, useReducer, useCallback, useMemo, createContext, useContext } from 'react';
//@ts-ignore
import { useWindowDimensions, StyleSheet, View, TouchableOpacity } from "react-native";
import { SafeAreaView } from 'react-native-safe-area-context';
//@ts-ignore
import { Divider, Icon, Layout, Text, TopNavigation, TopNavigationAction, Card, Button, Input, ButtonGroup, CheckBox, Modal, RangeDatepicker, Datepicker, useTheme, IndexPath, Select, SelectItem } from '@ui-kitten/components';
import ErrorBoundary from '../ErrorBoundary.component';
import * as JobsController from '../../functions/job.controller'
import * as MembershipsController from '../../functions/membership.controller'
import * as UpdateController from '../../functions/update.controller'
import * as ImageController from '../../functions/image.controller'
import * as ClientController from '../../functions/client.controller'
import * as StorageController from '../../functions/storageController'

import { CompanySettingsContainer } from './companySettings.component';
import { UserSettings } from './userSettings.component';


export const SettingsContainerScreen = ({ navigation }: any) => {

    return (
        <Layout style={{ flex: 1, flexDirection: 'row' }}>
            {/* <Layout style={{ flex: 1, borderWidth: 1, borderColor: "#000" }}>
                <Text>Settings</Text>
            </Layout> */}
            <Layout style={{ flex: 5 }}>
                <CompanySettingsContainer style={{ flex: 1, borderWidth: 1, borderColor: "#000" }} navigation={navigation} />
                {/* <UserSettings style={{ flex: 1, borderWidth: 1, borderColor: "#000" }} navigation={navigation} /> */}
            </Layout>
        </Layout>
    )
}