import React, { useEffect, useState } from 'react';
import { StyleSheet, View, ScrollView } from 'react-native';
import { Layout, Text, Button, Icon } from "@ui-kitten/components";
import { RouteStartEndSelector } from './RouteStartEndSelector';
import { RouteActions } from './RouteActions';
import { ROUTE_START_END_OPTION, RouteLocation } from '../../models/Route.model';
import { Company, CompanySavedAddress } from '../../models/Company.model';
import { ROUTE_ACTION_TYPES } from './createRouteContainer.component';
import { RouteGroupConfig } from './RouteGroupConfig';
import { RouteGroup } from '../../types/route.types';
import * as StorageController from '../../functions/storageController';

interface RouteConfigPanelProps {
    routeGroups: RouteGroup[];
    onUpdateRouteGroups: (groups: RouteGroup[]) => void;
    onSelectAddress: (groupIndex: number, addressType: 'start' | 'end' | 'roundTrip') => void;
    routeAction: ROUTE_ACTION_TYPES;
    setRouteAction: (action: ROUTE_ACTION_TYPES) => void;
    onCalculateRoute: () => void;
    setShowRouteLegsPanel: (show: boolean) => void;
    disableKeepInOrder: boolean;
}

export const RouteConfigPanel: React.FC<RouteConfigPanelProps> = ({
    routeGroups,
    onUpdateRouteGroups,
    onSelectAddress,
    routeAction,
    setRouteAction,
    onCalculateRoute,
    setShowRouteLegsPanel,
    disableKeepInOrder,
}) => {
    const [companySavedAddresses, setCompanySavedAddresses] = useState<CompanySavedAddress[]>([]);

    useEffect(() => {
        const company = StorageController.getCurrentCompany() as Company;
        setCompanySavedAddresses(company.settings.address_book);
    }, []);


    return (
        <View style={{ flexDirection: 'row', alignItems: 'flex-start'}}>

            <ScrollView style={[styles.container, { maxHeight: 600 }]}>
                <Layout style={styles.section}>
                    <Text category="s1">Route Groups</Text>
                    <RouteGroupConfig
                        routeGroups={routeGroups}
                        onUpdateRouteGroups={onUpdateRouteGroups}
                        companySavedAddresses={companySavedAddresses}
                    />
                </Layout>
            </ScrollView>
            <View style={styles.section}>
                <RouteActions
                    routeAction={routeAction}
                    setRouteAction={setRouteAction}
                    onCalculateRoute={onCalculateRoute}
                    setShowRouteLegsPanel={setShowRouteLegsPanel}
                    disableKeepInOrder={disableKeepInOrder}
                />
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        // flex: 1,
    },
    section: {
        padding: 10,
        marginBottom: 10,
        borderRadius: 8,
    },
});
