import React, { useState, useEffect, useRef, useReducer, useCallback, useMemo, createContext, useContext } from 'react';
//@ts-ignore
import { useWindowDimensions, StyleSheet, View, TouchableOpacity, FlatList, Alert, ScrollView } from "react-native";
import { SafeAreaView } from 'react-native-safe-area-context';
//@ts-ignore
import { Divider, List, Icon, Layout, Text, TopNavigation, TopNavigationAction, Card, Button, Input, ButtonGroup, CheckBox, Modal, RangeDatepicker, Datepicker, useTheme, IndexPath, Select, SelectItem, Toggle, OverflowMenu, MenuItem, ListItem } from '@ui-kitten/components';
import * as JobsController from '../../../functions/job.controller'
import * as MembershipsController from '../../../functions/membership.controller'
import * as UpdateController from '../../../functions/update.controller'
import * as ImageController from '../../../functions/image.controller'
import * as ClientController from '../../../functions/client.controller'
import * as StorageController from '../../../functions/storageController'
import * as CompanyController from '../../../functions/company.controller'
import * as GoogleController from '../../../functions/google.controller'
import { Job, JobAddress } from '../../../models/Job.model'
//@ts-ignore
import debounce from 'lodash.debounce';
import NotificationContext from '../../../context/notifications.context';
import { Company, CompanySavedAddress, CompanySettings, JobOptions, ScheduleSettings, SMSSettings, EmailSettings, Surcharge, ServiceArea } from '../../../models/Company.model';
import AddressSearch from '../../job/details/addressSearch.component';
import { LocationDetailsCard } from '../../job/details/locationDetailsCard.component';
export const SavedAddresses = ({
    company,
    onSave,
}: any) => {

    const [companyAddressBook, setCompanyAddressBook] = useState<CompanySavedAddress[]>(company?.settings?.address_book || [])
    const [selectedAddress, setSelectedAddress] = useState<CompanySavedAddress | null>(null)
    const [showAddNewAddressModal, setShowAddNewAddressModal] = useState(false)
    const [showEditAddressModal, setShowEditAddressModal] = useState(false)
    const editingAddressRef = useRef<CompanySavedAddress | null>(null)
    useEffect(() => {
        setCompanyAddressBook(company?.settings.address_book || [])
    }, [company])

    const handleSelectAddress = (address: CompanySavedAddress) => {
        setSelectedAddress(address)
    }




    const handleDelete = (address: CompanySavedAddress) => {
        const newAddresses = companyAddressBook.filter(a => a._id !== address._id)
        setCompanyAddressBook(newAddresses)
        onSave(newAddresses)
    }

    const handleAddNewAddress = () => {
        editingAddressRef.current = new CompanySavedAddress({})
        // setSelectedAddress(editingAddressRef.current)
        setShowAddNewAddressModal(true)
    }


    const handleSaveNew = (address: CompanySavedAddress) => {
        let companyAddresses = companyAddressBook
        companyAddresses = [...companyAddresses, address]
        setCompanyAddressBook(companyAddresses)
        onSave(companyAddresses)
        setShowAddNewAddressModal(false)
    }

    const handleEdit = (address: CompanySavedAddress) => {
        console.log("🚀============== ~ file: savedAddresses.component.tsx:61 ~ handleEdit ~ address🚀==============", address)
        editingAddressRef.current = address
        setSelectedAddress(address)
        setShowEditAddressModal(true)
    }

    const handleSaveEdit = (address: CompanySavedAddress) => {
        let companyAddresses = [...companyAddressBook]
        // save the edited address by the _id of the address
        const index = companyAddresses.findIndex(a => a._id === address._id)
        companyAddresses[index] = address
        setCompanyAddressBook(companyAddresses)
        onSave(companyAddresses)
        setShowEditAddressModal(false)
    }





    return (
        <View style={{ flexDirection: 'column' }}>
            <RenderAddNewAddressModal
                showAddNewAddressModal={showAddNewAddressModal}
                setShowAddNewAddressModal={setShowAddNewAddressModal}
                onSave={handleSaveNew}
            />
            <RenderEditAddressModal
                showEditAddressModal={showEditAddressModal}
                setShowEditAddressModal={setShowEditAddressModal}
                selectedAddress={selectedAddress}
                setSelectedAddress={setSelectedAddress}
                onSave={handleSaveEdit}
            />
            <View style={{ flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' }}>
                <Text category="h6">Saved Addresses</Text>
                <Button size="tiny" onPress={handleAddNewAddress}>Add New</Button>
                <List
                    data={companyAddressBook}
                    renderItem={({ item }: { item: CompanySavedAddress }) => (
                        <ListItem
                            title={item.name}
                            description={item.address?.addressToString()}
                            accessoryLeft={(props: any) => <Icon {...props} name='pin-outline' />}
                            accessoryRight={(props: any) => (
                                <View style={{ flexDirection: 'row' }}>
                                    <Button status="primary" onPress={() => handleEdit(item)}>
                                        <Icon fill="white" width={15} height={15} {...props} name='edit-outline' />
                                    </Button>
                                    <Button status="danger" onPress={() => handleDelete(item)}>
                                        <Icon fill="white" width={15} height={15} {...props} name='trash-2-outline' />
                                    </Button>
                                </View>
                            )}
                        />
                    )}
                />
            </View>

        </View>
    )
}


const RenderAddNewAddressModal = ({
    showAddNewAddressModal,
    setShowAddNewAddressModal,
    onSave
}: {
    showAddNewAddressModal: boolean,
    setShowAddNewAddressModal: any,
    onSave: any
}) => {
    const editingAddressRef = useRef<CompanySavedAddress>(new CompanySavedAddress({}))
    const [address, setAddress] = useState<any>(new JobAddress({}))
    const [location, setLocation] = useState<any>({})
    const [isError, setIsError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    useEffect(() => {
        editingAddressRef.current = new CompanySavedAddress({})
        setAddress(new JobAddress({}))
        setLocation({})
        setIsError(false)
        setErrorMessage('')
    }, [showAddNewAddressModal])


    const handleSave = () => {
        console.log("🚀============== ~ file: savedAddresses.component.tsx:140 ~ handleSave ~ editingAddressRef.current🚀==============", editingAddressRef.current)
        if (!editingAddressRef.current.name || editingAddressRef.current.name.length < 1) {
            setIsError(true)
            setErrorMessage("Name is required")
            return
        }
        if (!editingAddressRef.current.address) {
            setIsError(true)
            setErrorMessage("Address is required")
            return
        }
        onSave(editingAddressRef.current)
    }


    return (
        <Modal
            visible={showAddNewAddressModal}
            backdropStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
            onBackdropPress={() => setShowAddNewAddressModal(false)}>
            <Card disabled={true} status='success'>
                <View style={{ flexDirection: 'column', gap: 10 }}>
                    {isError && <Text status="danger">{errorMessage}</Text>}
                    <Text category="h6">Add New Address</Text>
                    {/* Name */}
                    <Input
                        label="Name"
                        placeholder="Home"
                        defaultValue={editingAddressRef.current?.name}
                        onChangeText={(nextValue: string) => {
                            editingAddressRef.current.name = nextValue
                        }}
                    />
                    <LocationDetailsCard
                        address={address}
                        location={location}
                        onSetAddress={(address: any) => {
                            setAddress(new JobAddress(address))
                            editingAddressRef.current.address = new JobAddress(address)
                        }}
                        onSetLocation={(location: any) => {
                            editingAddressRef.current.location = location
                            setLocation(location)
                        }}
                        searchDisplayValue={GoogleController.addressBuilder(address)}
                        allowEdit={true}
                    />
                    {/* Save */}
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Button status='success' onPress={() => handleSave()}>Save</Button>
                        <Button appearance='ghost' status='danger' onPress={() => setShowAddNewAddressModal(false)}>Cancel</Button>
                    </View>
                </View>

            </Card>
        </Modal>
    )
}

const RenderEditAddressModal = ({
    showEditAddressModal,
    setShowEditAddressModal,
    selectedAddress,
    onSave
}: {
    showEditAddressModal: boolean,
    setShowEditAddressModal: any,
    selectedAddress: CompanySavedAddress | null,
    setSelectedAddress: any,
    onSave: any
}) => {


    if (!selectedAddress) return null


    const editingAddressRef = useRef<CompanySavedAddress>(selectedAddress)
    const [address, setAddress] = useState<any>(selectedAddress?.address)
    const [location, setLocation] = useState<any>(selectedAddress?.location)
    const [isError, setIsError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    useEffect(() => {

        editingAddressRef.current = new CompanySavedAddress(selectedAddress)
        setAddress(selectedAddress?.address)
        setLocation(selectedAddress?.location)
        setIsError(false)
        setErrorMessage('')

        console.log("🚀============== ~ file: savedAddresses.component.tsx:200 ~ useEffect ~ editingAddressRef.current🚀==============", editingAddressRef.current)
    }, [selectedAddress, setShowEditAddressModal])


    const handleSave = () => {
        console.log("🚀============== ~ file: savedAddresses.component.tsx:197 ~ handleSave ~ editingAddressRef.current🚀==============", editingAddressRef.current)
        if (!editingAddressRef.current.name || editingAddressRef.current.name.length < 1) {
            setIsError(true)
            setErrorMessage("Name is required")
            return
        }
        if (!editingAddressRef.current.address) {
            setIsError(true)
            setErrorMessage("Address is required")
            return
        }
        onSave(editingAddressRef.current)
    }

    return (
        <Modal
            visible={showEditAddressModal}
            backdropStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
            onBackdropPress={() => setShowEditAddressModal(false)}>
            <Card disabled={true} status='warning'>
                {isError && <Text status="danger">{errorMessage}</Text>}
                <Text category="h6">Edit Address</Text>
                {/* Name */}
                <Input
                    label="Name"
                    placeholder="Home"
                    defaultValue={selectedAddress?.name}
                    onChangeText={(nextValue: string) => {
                        editingAddressRef.current.name = nextValue
                    }}
                />
                <LocationDetailsCard
                    address={address}
                    location={location}
                    onSetAddress={(address: any) => {
                        setAddress(new JobAddress(address))
                        editingAddressRef.current.address = new JobAddress(address)
                    }}
                    onSetLocation={(location: any) => {
                        editingAddressRef.current.location = location
                        setLocation(location)
                    }}
                    searchDisplayValue={GoogleController.addressBuilder(address)}
                    allowEdit={true}
                />
                {/* Save */}
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Button status='success' onPress={() => handleSave()}>Save</Button>
                    <Button appearance='ghost' status='danger' onPress={() => setShowEditAddressModal(false)}>Cancel</Button>
                </View>
            </Card>
        </Modal>
    )
}