import React, { useState, useEffect, useReducer, useCallback, useRef } from 'react';
//@ts-ignore
import { StyleSheet, Text as RNText, Platform, useWindowDimensions, View, Alert, ScrollView } from "react-native";
import {
    Button,
    Icon,
    Layout,
    Card,
    Input,
    Modal,
    Text,
    //@ts-ignore
    Spinner,
    List,
    ListItem,
    //@ts-ignore
    IndexPath,
    Select,
    SelectItem,
    Toggle,
    //@ts-ignore
    CheckBox,
    Radio,
    RadioGroup
} from "@ui-kitten/components";
import * as UpdateController from '../../functions/update.controller'
import * as StorageController from '../../functions/storageController'
import * as ServiceController from '../../functions/service.controller'
import * as MembershipController from '../../functions/membership.controller'
import ErrorBoundary from '../ErrorBoundary.component';
import { Job, Service, ServiceTagsArr, SERVICE_TAGS, SERVICE_FIELD_TYPES, ServiceFields } from '../../models/Job.model'
import { useNotification } from '../../context/notifications.context';
import { Company } from '../../models/Company.model';
import { useAppStateChange, IAction, STATE_ACTIONS } from '../../hooks/appStateChange.hook';
// import { FILE } from 'dns';


// screen to manage company services
export const ServicesScreen = ({ navigation }: any) => {

    const [ignored, forceUpdate] = useReducer(x => x + 1, 0);
    const [services, setServices] = useState<Service[]>([]);
    const [loading, setLoading] = useState(false);

    const [selectedService, setSelectedService] = useState<Service | null>(null);

    const [registeredEvents, setRegisteredEvents] = useState(false);
    const [showNewServiceModal, setShowNewServiceModal] = useState(false);

    const [canEditField, setCanEditField] = useState(false);
    const [enableEditField, setEnableEditField] = useState(false);

    const [canCreateService, setCanCreateService] = useState(false);
    const [enableCreateService, setEnableCreateService] = useState(false);

    const unsavedChangesRef = useRef(false);
    const [showConfirmUnsavedServiceChangeModal, setShowConfirmUnsavedServiceChangeModal] = useState(false);

    const { windowWidth, windowHeight } = useWindowDimensions();
    const { showNotification } = useNotification();



    const [selectedCompany, setSelectedCompany] = useState<Company | null>(null)


    const onSetSelectedService = (service: Service | null) => {
        if (unsavedChangesRef.current) {
            setShowConfirmUnsavedServiceChangeModal(true);
        }
        else {
            setSelectedService(service);
        }
    }






    useEffect(() => {
        const unsubscribe = navigation.addListener('focus', () => {
            load();
            // get member permissions for this screen

        });
        return unsubscribe;
    }, [navigation])


    const handleCompanyChangeEvent = useCallback((action: IAction) => {
        setSelectedCompany(new Company(action.data))
        setServices([])
        setSelectedService(null)
        setTimeout(async () => {
            load()
        }, 100);
    }, [selectedCompany]);


    const { dispatchEventStateChange } = useAppStateChange({
        onUpdatedSelectedCompany: handleCompanyChangeEvent,
    })


    const load = async () => {
        setLoading(true);
        const company = StorageController.getCurrentCompany()
        ServiceController.getServicesByCompanyId(company._id).then((_services: Service[]) => {
            //console.log("Services: ", _services)
            setServices(_services);
            StorageController.appState.services = _services;
            StorageController.saveStateToDisk();
            setLoading(false);
            getMemberPermissions();
            // forceUpdate();
        })
    }


    const getMemberPermissions = () => {
        MembershipController.getMemberPermissions().then((permissions) => {
            //console.log("Permissions: ", permissions);
            const selectedMembership = StorageController.getAppState().selectedMembership;
            if (!selectedMembership) return;
            setCanCreateService(selectedMembership?.is_admin);
            setCanEditField(selectedMembership?.is_admin);
        })
    }




    const handleServiceSelect = (service: Service) => {
        //console.log("Selected service: ", service);
        if (!service.other) {
            service.other = {};
        }
        onSetSelectedService(service);
    }

    const handleServiceDelete = (service: Service) => {
        //console.log("Deleting service: ", service);
        ServiceController.deleteService(service).then(() => {
            //console.log("Deleted service: ", service);
            onSetSelectedService(null);
            forceUpdate();
            showNotification("Service deleted", "success");
        })
    }

    const handleServiceSave = async (service: Service) => {
        //console.log("Saving service: ", service);
        await ServiceController.updateService(service)
        unsavedChangesRef.current = false;
        handleServiceSelect(service);
        showNotification(`${service.name} Saved`, "success");
        // forceUpdate();
    }

    const handleServiceCreate = async (name: string) => {
        // Check that a name was provided
        if (name.length == 0) {
            return;
        }
        // Create a new service definition
        try {

            const company = StorageController.getCurrentCompany();
            let s = capitalizeFirstLetter(name);
            let company_id = company._id;
            let _service = await ServiceController.tempGenerateServiceDefinition(s, company_id);
            let _sObj = await ServiceController.createService(_service) as Service;
            StorageController.appState.services.push(_sObj);
            StorageController.saveStateToDisk();
            onSetSelectedService(_sObj);
            showNotification(`${_sObj.name} Created`, "success");
        } catch (e) {
            console.log("Error creating service: ", e);
        }
    }

    const handleServiceCancel = () => {
        unsavedChangesRef.current = false;
        onSetSelectedService(null);
    }

    function capitalizeFirstLetter(str: string) {
        var words = str.split(" ");
        var capitalizedWords = words.map(function (word) {
            return word[0].toUpperCase() + word.slice(1);
        });
        return capitalizedWords.join(" ");
    }




    // confirm delete service modal
    const [showServiceDeleteModal, setShowServiceDeleteModal] = useState(false);
    const ServiceDeleteModal = () => {
        return (
            <Modal
                visible={showServiceDeleteModal}
                backdropStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
                onBackdropPress={() => setShowServiceDeleteModal(false)}
            >
                <Card disabled={true}>
                    <Text>Are you sure you want to delete this service?</Text>
                    <Button
                        status="danger"
                        accessoryLeft={(props: any) => <Icon {...props} name="trash-2-outline" />}
                        onPress={() => {
                            setShowServiceDeleteModal(false);
                            handleServiceDelete(selectedService as Service);
                        }}
                    >
                        Delete
                    </Button>
                    <Button
                        onPress={() => {
                            setShowServiceDeleteModal(false);
                        }}
                    >
                        Cancel
                    </Button>
                </Card>
            </Modal>
        );
    }



    const RenderConfirmUnsavedServiceChangeModal = () => {
        return (
            <Modal
                visible={showConfirmUnsavedServiceChangeModal}
                backdropStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
                onBackdropPress={() => setShowConfirmUnsavedServiceChangeModal(false)}
            >
                <Card disabled={true}>
                    <Text category="h6">Unsaved Changes</Text>
                    <Text>There are unsaved changes. Are you sure you want to continue?</Text>
                    <Layout style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Button onPress={() => setShowConfirmUnsavedServiceChangeModal(false)}>Cancel</Button>
                        <Button onPress={() => {
                            setShowConfirmUnsavedServiceChangeModal(false);
                            unsavedChangesRef.current = false;
                            setSelectedService(null);
                        }}>Continue</Button>
                    </Layout>
                </Card>
            </Modal>
        )
    }

    const onSetUnsavedChangesRef = (value: boolean) => {
        unsavedChangesRef.current = value;
    }


    return (
        <Layout style={{ flex: 1, borderColor: 'yellow', borderWidth: 1 }}>
            <NewServiceModal
                visible={showNewServiceModal}
                handleServiceCreate={handleServiceCreate}
                setShowNewServiceModal={setShowNewServiceModal}
            />
            <RenderConfirmUnsavedServiceChangeModal />
            <ServiceDeleteModal />
            <Layout style={{ flex: 1, flexDirection: 'row' }}>
                <Layout style={{ flex: 1, flexDirection: 'column' }}>
                    <Layout style={{ flex: 1, flexDirection: 'row' }}>
                        <Button
                            style={{ flex: 1 }}
                            status="success"
                            disabled={!canCreateService}
                            onPress={() => { setShowNewServiceModal(true) }}
                            accessoryLeft={(props: any) => <Icon {...props} name="plus-outline" />}
                        />
                        <Button
                            style={{ flex: 1 }}
                            status="danger"
                            disabled={!canCreateService}
                            onPress={() => { setShowServiceDeleteModal(true) }}
                            accessoryLeft={(props: any) => <Icon {...props} name="minus-outline" />}
                        />
                    </Layout>
                    <Layout style={{ flex: 15, border: '1px solid' }}>
                        {loading && <Spinner />}
                        <List
                            data={services}
                            renderItem={({ item }: { item: Service }) => (
                                <ListItem
                                    key={item._id}
                                    title={item.name}
                                    style={{
                                        backgroundColor: selectedService && selectedService._id === item._id ? 'green' : '',
                                        border: '1px solid'
                                    }}
                                    onPress={() => handleServiceSelect(item)}
                                />
                            )}
                        />
                    </Layout>
                </Layout>
                <Layout style={{ flex: 4 }}>
                    {selectedService &&
                        <ServiceEditor
                            service={selectedService}
                            // _unsavedChanges={unsavedChangesRef.current}
                            canEditField={canEditField}
                            selectedService={selectedService}
                            unsavedChangesRef={unsavedChangesRef}
                            FIELD_TYPES={SERVICE_FIELD_TYPES}
                            handleServiceCancel={handleServiceCancel}
                            handleServiceSave={handleServiceSave}
                            onSetUnsavedChangesRef={onSetUnsavedChangesRef}
                            capitalizeFirstLetter={capitalizeFirstLetter}
                        />
                    }
                </Layout>
            </Layout>
        </Layout>
    )
}


interface ServiceEditorProps {
    service: Service,
    canEditField: boolean,
    selectedService: Service,
    unsavedChangesRef: any,
    FIELD_TYPES: any,
    handleServiceCancel: Function,
    handleServiceSave: Function,
    onSetUnsavedChangesRef: Function,
    capitalizeFirstLetter: Function
}

const ServiceEditor = ({
    service,
    // _unsavedChanges = false,
    canEditField,
    selectedService,
    unsavedChangesRef,
    FIELD_TYPES,
    handleServiceCancel,
    handleServiceSave,
    onSetUnsavedChangesRef,
    capitalizeFirstLetter
}: ServiceEditorProps) => {

    const [serviceState, setServiceState] = useState(service);
    const [selectedField, setSelectedField] = useState(null as null | typeof service.fields[keyof typeof service.fields]);
    const [serviceOptions, setServiceOptions] = useState(service.options);
    const [selectedOption, setSelectedOption] = useState(null);
    const [x, forceUpdate] = useReducer(x => x + 1, 0);
    const [showNewFieldModal, setShowNewFieldModal] = useState(false);


    const [optionEditInputField, setOptionEditInputField] = useState("");
    const [selectedOptionText, setSelectedOptionText] = useState("");

    const [selectedDestination, setSelectedDestination] = useState(null);
    const [selectedDestinationIndex, setSelectedDestinationIndex] = useState(0);
    const [selectedDestinationName, setSelectedDestinationName] = useState("");
    const [selectedDestinationTime, setSelectedDestinationTime] = useState("");
    const [selectedDestinationTypes, setSelectedDestinationTypes] = useState([]); //radio options
    const [selectedDestinationTypeText, setSelectedDestinationTypeText] = useState("");
    const [selectedDestinationTypeIndex, setSelectedDestinationTypeIndex] = useState(0);
    const [selectedDestinationPhotos, setSelectedDestinationPhotos] = useState([]);
    const [selectedDestinationAddress, setSelectedDestinationAddress] = useState("");

    const [unsavedChanges, setUnsavedChanges] = useState(false);

    const { windowWidth, height } = useWindowDimensions();

    const onSetServiceState = (service: Service, changes = true) => {
        const s = new Service(service)
        setUnsavedChanges(changes);
        // unsavedChangesRef.current = changes;
        onSetUnsavedChangesRef(changes);
        setServiceState(s);
    }

    useEffect(() => {
        setServiceState(service);
        setSelectedField(null);
    }, [service])

    // useEffect(() => {
    //     setUnsavedChanges(_unsavedChanges);
    // }, [_unsavedChanges])













    // fields
    const addField = (field: string) => {
        let _service = serviceState;
        _service.addField(field);




        // _service.fields[fieldKey] = val;
        // _service.values[fieldKey] = "";
        onSetServiceState(_service);
        forceUpdate();
    }

    const removeField = (field: string) => {
        let _service = serviceState;
        try {
            _service.removeField(field);
            // delete _service.fields?.[field];
            // delete _service.types?.[field];
            // delete _service.values?.[field];
            // delete _service.options?.[field];
            // delete _service.invoiced?.[field];
            // delete _service.required?.[field];
            // delete _service.driver_editable?.[field];
            // delete _service.other?.[field];
            onSetServiceState(_service);
        } catch (e) {
            console.log("Error removing field: ", e);
        }
    }

    const updateField = (field: string, value: any) => {
        let _service = serviceState;
        _service.fields[field] = value;
        onSetServiceState(_service);
    }

    // types
    const addType = (field: string, type: any) => {
        let _service = serviceState;
        _service.types[field] = type;
        onSetServiceState(_service);
    }

    const removeType = (field: string) => {
        let _service = serviceState;
        delete _service.types[field];
        onSetServiceState(_service);
    }

    const updateType = (field: string, type: any) => {
        let _service = serviceState;
        _service.types[field] = type;
        if (type === SERVICE_FIELD_TYPES.options || type === SERVICE_FIELD_TYPES.radio) {
            _service.options[field] = [];
        }

        onSetServiceState(_service);
        forceUpdate();
    }

    // values
    const addValue = (field: string, value: any) => {
        let _service = serviceState;
        _service.values[field] = value;
        onSetServiceState(_service);
    }

    const removeValue = (field: string) => {
        let _service = serviceState;
        delete _service.values[field];
        onSetServiceState(_service);
    }

    const updateValue = (field: string, value: any) => {
        let _service = serviceState;
        _service.values[field] = value;
        onSetServiceState(_service);
        forceUpdate();
    }

    // options
    const addOption = (field: string, option: string) => {
        let _service = serviceState;
        if (!_service.options[field]) {
            _service.options[field] = [];
        }
        option = `${option} ${_service.options[field].length}`;
        _service.options[field].push(option);
        //console.log("pushed:", _service.options[field]);
        onSetServiceState(_service);
        forceUpdate();
    }

    const removeOption = (field: string, option: string) => {
        let _service = serviceState;
        _service.options[field] = _service.options[field].filter((o) => o !== option);
        onSetServiceState(_service);
        forceUpdate();
    }

    const updateOption = (field: string, option: string, value: any) => {
        let _service = serviceState;
        //console.log('Updating option', field, option, value);
        // find index of option
        let index = _service.options[field].indexOf(option);
        _service.options[field][index] = value;
        //console.log('update option', _service.options[field][index], _service);
        setSelectedOption(value);
        onSetServiceState(_service);
        // forceUpdate();
    }






    const updateInvoiced = (field: string, value: boolean) => {
        let _service = new Service({ ...serviceState })
        if (!_service.hasOwnProperty("invoiced")) {
            _service.invoiced = {};
            _service.invoiced[field] = value;
        } else {
            _service.invoiced[field] = value;
        }
        onSetServiceState(_service);
        forceUpdate();
    }

    const updateRequired = (field: string, value: boolean) => {
        let _service = new Service({ ...serviceState })
        if (!_service.hasOwnProperty("required")) {
            _service.required = {};
            _service.required[field] = value;
        } else {
            _service.required[field] = value;
        }
        onSetServiceState(_service);
        forceUpdate();
    }

    // driver_editable
    const updateDriverEditable = (field: string, value: boolean) => {
        let _service = new Service({ ...serviceState });
        if (!_service.hasOwnProperty("driver_editable")) {
            _service.driver_editable = {};
            _service.driver_editable[field] = value;
        } else {
            _service.driver_editable[field] = value;
        }
        onSetServiceState(_service);
        forceUpdate();
    }

    const updateServiceTags = (tags: string[]) => {
        let _service = new Service({ ...serviceState })
        _service.tags = tags || [];
        onSetServiceState(_service);
        forceUpdate();
    }


    const handleSave = () => {
        handleServiceSave(serviceState);
        setUnsavedChanges(false);
    }

    const handleCancel = () => {
        handleServiceCancel();
    }

    // const handleDelete = () => {
    //     handleServiceDelete(serviceState);
    // }


    // if selectedService.other[field] does not exist, create it

    const handleFieldSelect = (field: string) => {
        // check has fields
        let _service = new Service({ ...serviceState })
        if (!_service.driver_editable) {
            _service.driver_editable = {};
        }
        if (!_service.invoiced) {
            _service.invoiced = {};
        }
        if (!_service.required) {
            _service.required = {};
        }
        if (!_service.driver_editable[field]) {
            _service.driver_editable[field] = false;
        }
        if (!_service.invoiced[field]) {
            _service.invoiced[field] = false;
        }
        if (!_service.required[field]) {
            _service.required[field] = false;
        }
        const c = unsavedChanges;
        onSetServiceState(_service, c);
        setSelectedField(field);
    }





    return (
        <Layout style={{ flex: 1, flexDirection: 'column', height: height * 0.9 }}>
            <NewFieldModal
                onClose={() => setShowNewFieldModal(false)}
                addField={addField}
                showNewFieldModal={showNewFieldModal} />
            <Layout style={{ flex: 1, flexDirection: 'row' }}>
                <View style={{ flex: 1, flexDirection: 'row' }}></View>
                <View style={{ flex: 2, justifyContent: 'center', alignContent: 'center' }}>
                    {
                        unsavedChanges ? <Text category='h6' style={{ color: 'red' }}>Unsaved Changes</Text> : <Text></Text>
                    }
                </View>
                <Layout style={{ flex: 1 }}>
                    <Button
                        disabled={!canEditField}
                        onPress={handleSave}
                        accessoryLeft={(props: any) => <Icon {...props} name="save-outline" />}
                        status="success"
                    >
                        Save
                    </Button>
                </Layout>
                <Layout style={{ flex: 1 }}>
                    <Button
                        disabled={!canEditField}
                        onPress={handleCancel}
                        accessoryLeft={(props: any) => <Icon {...props} name="close-outline" />}
                        status="warning"
                    >
                        Cancel
                    </Button>
                </Layout>
            </Layout>
            <Layout style={{ flex: 15, flexDirection: 'column' }}>
                <Layout style={{ flex: 2, flexDirection: 'row', border: '1px solid', gap: 10 }}>
                    <Layout style={{ flex: 1, flexDirection: 'column' }}>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', gap: 10 }}>
                            <Text category="h5">Service : {serviceState.name}</Text>
                            <Text category="p1">Service ID: {serviceState._id}</Text>
                        </View>
                        <Input
                            disabled={!canEditField}
                            label="Name"
                            value={serviceState.name}
                            onChangeText={(value: string) => {
                                //console.log('on change name', value);
                                let _service = serviceState;
                                _service.name = value;
                                onSetServiceState(_service);
                                forceUpdate();
                            }}
                        />
                        <Layout style={{ flex: 2 }}>
                            <Input
                                disabled={!canEditField}
                                label="Description"
                                value={serviceState.description}
                                multiline={true}
                                textStyle={{ minHeight: 64 }}
                                onChangeText={(value: string) => {
                                    let _service = serviceState;
                                    _service.description = value;
                                    onSetServiceState(_service);
                                    forceUpdate();
                                }}
                            />
                        </Layout>
                    </Layout>
                    <Layout style={{ flex: 1 }}>
                        <Text category='h6'>Tags</Text>
                        <Text>Select the categories this service is applicable to. This assists in showing the clients what services you have at a glance.</Text>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                            {ServiceTagsArr.map((tag, index) => (
                                <Button
                                    size='small'
                                    key={index}
                                    disabled={!canEditField}
                                    onPress={() => {
                                        let tags = serviceState.tags || [];
                                        if (tags.includes(tag)) {
                                            tags = tags.filter((t) => t !== tag);
                                        } else {
                                            tags.push(tag);
                                        }
                                        updateServiceTags(tags);
                                    }}
                                    appearance={serviceState.tags?.includes(tag) ? 'filled' : 'outline'}
                                >
                                    {tag}
                                </Button>

                            ))}
                        </View>
                    </Layout>
                </Layout>
                <Layout style={{ flex: 5, flexDirection: 'row' }}>
                    <Card
                        style={{ flex: 1 }}
                        header={() => <Text category='s1'>Fields</Text>}
                        disabled
                        status="warning"
                    >
                        <Layout style={{ flex: 1, flexDirection: 'column', margin: -15, marginLeft: -20, marginRight: -20, gap: 5 }}>
                            <View style={{ flexDirection: 'row', gap: 5, justifyContent: 'space-between' }}>
                                <Button
                                    style={{ flex: 1 }}
                                    disabled={!canEditField}
                                    onPress={() => {
                                        // addField('newField');
                                        setShowNewFieldModal(true);
                                    }}
                                    accessoryLeft={(props: any) => <Icon {...props} name="plus-outline" />}
                                    status="success"
                                />
                                <Button
                                    style={{ flex: 1 }}
                                    disabled={!canEditField}
                                    onPress={() => {
                                        removeField(selectedField as string);
                                        setSelectedField(null);
                                    }}
                                    accessoryLeft={(props: any) => <Icon {...props} name="minus-outline" />}
                                    status="danger"
                                />
                            </View>
                            <List
                                data={Object.keys(serviceState.fields)}
                                renderItem={({ item }: { item: string }) => (
                                    <ListItem
                                        title={serviceState.fields[item]}
                                        style={{
                                            backgroundColor: selectedField === item ? 'green' : '',
                                            border: '1px solid black'
                                        }}
                                        onPress={() => {
                                            handleFieldSelect(item);
                                            console.log('selected field', height);
                                        }}
                                    />
                                )}
                            />
                        </Layout>
                    </Card>
                    <Layout style={{ flex: 3 }}>
                        <Card
                            style={{ flex: 1 }}
                            disabled
                            header={() => <Text category='s1'>Field Details</Text>}
                            status="primary"
                        >
                            {selectedField &&
                                <ScrollView style={{ height: height * 0.6 }}>
                                    <Layout style={{ flexDirection: 'row', gap: 10 }}>
                                        <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between' }}>
                                            <View style={{ flex: 1 }}>
                                                <Text category='label'>Field Name</Text>
                                                <Text>{serviceState.fields[selectedField]}</Text>
                                            </View>
                                            <View style={{ flex: 1 }}>
                                                <Select
                                                    disabled={!canEditField}
                                                    label="Field Type"
                                                    value={serviceState.types[selectedField]}
                                                    onSelect={(index: any) => {
                                                        let value = Object.keys(FIELD_TYPES)[index.row];
                                                        updateType(selectedField, value);
                                                    }}
                                                >
                                                    {Object.keys(FIELD_TYPES).map((type, index) => (
                                                        <SelectItem key={index} title={type} />
                                                    ))}
                                                </Select>
                                            </View>
                                        </View>
                                        <View style={{ flex: 1, flexDirection: 'row' }}>

                                            <Layout style={{}}>
                                                <Toggle
                                                    disabled={!canEditField}
                                                    checked={serviceState.invoiced?.[selectedField] || false}
                                                    onChange={(checked: boolean) => {
                                                        updateInvoiced(selectedField, checked);
                                                    }}
                                                >
                                                    Invoiced
                                                </Toggle>
                                                <Toggle
                                                    disabled={!canEditField}
                                                    checked={serviceState.required?.[selectedField] || false}
                                                    onChange={(checked: boolean) => {
                                                        updateRequired(selectedField, checked);
                                                    }}
                                                >
                                                    Required
                                                </Toggle>
                                            </Layout>
                                            <Layout style={{}}>
                                                <Toggle
                                                    disabled={!canEditField}
                                                    checked={serviceState.driver_editable?.[selectedField] || false}
                                                    onChange={(checked: boolean) => {
                                                        updateDriverEditable(selectedField, checked);
                                                    }}
                                                >
                                                    Driver Editable
                                                </Toggle>
                                            </Layout>
                                        </View>
                                    </Layout>
                                    <Layout style={{ flex: 3, flexDirection: 'row' }}>
                                        <Layout style={{ flex: 2, flexDirection: 'row' }}>
                                            {/* Value */}
                                            {(serviceState.types[selectedField] === FIELD_TYPES.input ||
                                                serviceState.types[selectedField] === FIELD_TYPES.date ||
                                                serviceState.types[selectedField] === FIELD_TYPES.time) &&
                                                <Layout style={{ flex: 1 }}>
                                                    <RenderInputTextField
                                                        field={selectedField}
                                                        value={serviceState.values[selectedField] as string}
                                                        onChange={updateValue}
                                                        label={"Default Value"}
                                                        disabled={!canEditField}
                                                    />
                                                </Layout>
                                            }
                                            {/* is cost/rebate/commission */}
                                            {(serviceState.types[selectedField] === FIELD_TYPES.cost ||
                                                serviceState.types[selectedField] === FIELD_TYPES.driver_rebate ||
                                                serviceState.types[selectedField] === FIELD_TYPES.driver_commission) &&
                                                <Layout style={{ flex: 1 }}>
                                                    <RenderInputNumberField
                                                        field={selectedField}
                                                        value={serviceState.values[selectedField] as string}
                                                        onChange={updateValue}
                                                        label={"Default $ value"}
                                                        disabled={!canEditField}
                                                    />
                                                </Layout>
                                            }
                                            {serviceState.types[selectedField] === FIELD_TYPES.quantity &&
                                                <Layout style={{ flex: 1 }}>
                                                    <RenderInputNumberField
                                                        field={selectedField}
                                                        value={serviceState.values[selectedField] as string}
                                                        onChange={updateValue}
                                                        label={"Default quantity"}
                                                        disabled={!canEditField}
                                                    />
                                                </Layout>
                                            }
                                            {/* Options */}
                                            {(serviceState.types[selectedField] === FIELD_TYPES.options ||
                                                serviceState.types[selectedField] === FIELD_TYPES.radio) &&
                                                <RenderOptionsField
                                                    field={selectedField}
                                                    options={serviceState.options[selectedField]}
                                                    label={"Options"}
                                                    disabled={!canEditField}
                                                    onAddOption={addOption}
                                                    onRemoveOption={removeOption}
                                                    onUpdateOption={updateOption}
                                                />
                                            }
                                        </Layout>
                                    </Layout>
                                </ScrollView>
                            }
                        </Card>
                    </Layout>
                    {selectedService &&
                        <Layout style={{ flex: 2, border: '1px solid' }}>
                            <Layout style={{ flex: 1 }}>
                                <Text category='h6'>Preview</Text>
                            </Layout>
                            <Layout style={{ flex: 15 }}>
                                <Card>
                                    <Text category='h5'>{selectedService?.name}</Text>
                                    {Object.keys(selectedService?.fields).map((field, index) => (
                                        <Layout key={index} style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center", margin: 5, height: selectedService?.fields.length || 1 * 60 }}>
                                            <Text
                                                style={{ flex: 1 }}
                                                category="s1">{selectedService?.fields[field]}</Text>
                                            {(selectedService?.types?.[field] === "input" ||
                                                selectedService?.types?.[field] === "cost" ||
                                                selectedService?.types?.[field] === "address" ||
                                                selectedService?.types?.[field] === "driver_rebate" ||
                                                selectedService?.types?.[field] === "driver_commission" ||
                                                selectedService?.types?.[field] === "quantity") &&
                                                <Input
                                                    value={selectedService?.values[field]}
                                                />}
                                            {selectedService?.types?.[field] === "options" &&
                                                selectedService?.options[field] &&
                                                <Select
                                                    value={selectedService?.values?.[field]}
                                                    selectedIndex={new IndexPath(selectedService?.values?.[field])}
                                                >
                                                    {selectedService?.options[field].map((option, index) => (
                                                        <SelectItem
                                                            key={index}
                                                            title={option}
                                                            value={option}
                                                        />
                                                    ))}
                                                </Select>}
                                            {selectedService?.types?.[field] === "toggle" && <Toggle
                                                checked={selectedService?.values[field]}
                                            />}
                                            {selectedService?.types?.[field] === "radio" &&
                                                <RadioGroup
                                                    selectedIndex={new IndexPath(selectedService?.values?.[field])}
                                                >
                                                    {selectedService?.options &&
                                                        <>
                                                            {selectedService?.options?.[field]?.map((option, index) => {
                                                                return (<Radio key={index}>{option}</Radio>)
                                                            })}
                                                        </>
                                                    }
                                                </RadioGroup>
                                            }
                                        </Layout>
                                    ))}
                                </Card>
                            </Layout>
                        </Layout>
                    }
                </Layout>
            </Layout>
        </Layout>
    );
}


interface RenderInputTextFieldProps {
    field: string,
    value: string,
    onChange: Function,
    label: string,
    disabled: boolean
}


const RenderInputTextField = ({
    field,
    value,
    onChange,
    label,
    disabled
}: RenderInputTextFieldProps) => {

    return (
        <Input
            label={label}
            value={value}
            disabled={disabled}
            onChangeText={(val: string) => onChange(field, val)}
        />
    )
}

interface RenderInputNumberFieldProps {
    field: string,
    value: string,
    onChange: Function,
    label: string,
    disabled: boolean
}

const RenderInputNumberField = ({
    field,
    value,
    onChange,
    label,
    disabled
}: RenderInputNumberFieldProps) => {

    const validateNumber = (val: any) => {
        if (isNaN(val)) {
            return;
        }
        onChange(field, val);
    }

    return (
        <Input
            label={label}
            value={value}
            disabled={disabled}
            onChangeText={(val: any) => validateNumber(val)}
        />
    )
}

interface RenderOptionsFieldProps {
    field: string,
    options: string[],
    label: string,
    disabled: boolean,
    onAddOption: Function,
    onRemoveOption: Function,
    onUpdateOption: Function
}


const RenderOptionsField = ({
    field,
    options,
    label = "Options",
    disabled,
    onAddOption,
    onRemoveOption,
    onUpdateOption
}: RenderOptionsFieldProps) => {

    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedOptionText, setSelectedOptionText] = useState("");
    const [showEditOptionModal, setShowEditOptionModal] = useState(false);

    const RenderNewOptionModal = () => {
        const optionTextRef = useRef("");
        return (
            <Modal
                visible={showEditOptionModal}
                backdropStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
                onBackdropPress={() => setShowEditOptionModal(false)}
            >
                <Card disabled={true}>
                    <Input
                        label="Edit Option"
                        defaultValue={selectedOptionText}
                        onChangeText={(val: string) => optionTextRef.current = val}
                    />
                    <Button
                        onPress={() => {
                            onUpdateOption(field, selectedOption, optionTextRef.current);
                            setShowEditOptionModal(false);
                        }}
                    >
                        Save
                    </Button>
                </Card>
            </Modal>
        )
    }


    const RenderButtons = () => {
        return (
            <Layout style={{ flexDirection: 'row', gap: 10, justifyContent: 'space-between' }}>
                <Button
                    appearance='outline'
                    disabled={disabled}
                    onPress={() => {
                        onAddOption(field, 'New Option');
                    }}
                    accessoryLeft={(props: any) => <Icon {...props} name="plus-outline" />}
                    status="success"
                >
                    Add Option
                </Button>
            </Layout>
        )
    }

    const RenderOptionsList = () => {
        return (
            <View style={{ flex: 5 }}>
                <List
                    data={options}
                    renderItem={({ item }: any) => (
                        <ListItem
                            title={item}
                            style={{
                                // backgroundColor: selectedOption && selectedOption === item ? 'green' : '',
                                border: '1px solid black'
                            }}
                            accessoryRight={() =>
                                <View style={{ flexDirection: 'row' }}>
                                    <Button
                                        status='success'
                                        appearance='outline'
                                        onPress={() => {
                                            setSelectedOptionText(item);
                                            setSelectedOption(item);
                                            setShowEditOptionModal(true);
                                        }}
                                    >
                                        <Icon name='edit-outline' fill="white" width={15} height={15} />
                                    </Button>
                                    <Button
                                        status="danger"
                                        appearance='outline'
                                        onPress={() => {
                                            onRemoveOption(field, item);
                                        }}
                                    >
                                        <Icon name='trash-2-outline' fill="white" width={15} height={15} />
                                    </Button>
                                </View>
                            }
                            onPress={() => {
                                setSelectedOptionText(item);
                                setSelectedOption(item);
                            }}
                        />
                    )}
                />
            </View>
        )
    }

    return (
        <Card
            style={{ flex: 1 }}
            header={() => <Text category='s1'>{label}</Text>}
            disabled
            status="primary"
        >
            <RenderNewOptionModal />
            <View style={{ flex: 1, gap: 10 }}>
                <RenderButtons />
                <RenderOptionsList />
            </View>
        </Card>
    )
}


interface RenderMultiDestinationFieldProps {
    field: string,
    destinations: any[],
    label: string,
    disabled: boolean,
    onAddDestination: Function,
    onRemoveDestination: Function,
    onUpdateDestination: Function
}

const RenderMultiDestinationField = ({
    field,
    destinations,
    label,
    disabled,
    onAddDestination,
    onRemoveDestination,
    onUpdateDestination
}: RenderMultiDestinationFieldProps) => {

    const [selectedDestination, setSelectedDestination] = useState(null);
    const [selectedDestinationIndex, setSelectedDestinationIndex] = useState(null);
    const [selectedDestinationText, setSelectedDestinationText] = useState("");
    const [showEditDestinationModal, setShowEditDestinationModal] = useState(false);

    const [selectedDestinationTypeIndex, setSelectedDestinationTypeIndex] = useState(null);
    const [selectedDestinationTypeText, setSelectedDestinationTypeText] = useState("");
    const [showEditDestinationTypeModal, setShowEditDestinationTypeModal] = useState(false);

    const RenderNewDestinationModal = () => {
        const destinationTextRef = useRef("");
        const destinationTypeTextRef = useRef("");
        return (
            <Modal
                visible={showEditDestinationModal}
                backdropStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
                onBackdropPress={() => setShowEditDestinationModal(false)}
            >
                <Card disabled={true}>
                    <Input
                        label="Edit Destination"
                        defaultValue={selectedDestinationText}
                        onChangeText={(val: any) => destinationTextRef.current = val}
                    />
                    <Button
                        onPress={() => {
                            //@ts-ignore
                            let _selectedDestination = { ...selectedDestination }
                            _selectedDestination.name = destinationTextRef.current;
                            onUpdateDestination(field, _selectedDestination, selectedDestinationIndex);
                            setShowEditDestinationModal(false);
                        }}
                    >
                        Save
                    </Button>
                </Card>
            </Modal>
        )
    }

    const multiDestinationDefaultTypes = [
        "Home",
        "Carpark",
        "Roadside",
        "Mechanic",
        "Dealership",
        "Tow to safety",
        "Holding Yard",
        "Other",
    ]

    const newDestination = {
        name: "New Destination",
        address: "",
        destinationType: "",
        destinationTypes: [...multiDestinationDefaultTypes]
    }


    const RenderButtons = () => {
        return (
            <Layout style={{ flexDirection: 'row', gap: 10, justifyContent: 'space-between' }}>
                <Button
                    appearance='outline'
                    disabled={disabled}
                    onPress={() => {
                        onAddDestination(field, { ...newDestination });
                    }}
                    accessoryLeft={(props: any) => <Icon {...props} name="plus-outline" />}
                    status="success"
                >
                    Add Destination
                </Button>
            </Layout>
        )
    }

    const RenderDestinationsList = () => {
        return (
            <View style={{}}>
                <List
                    data={destinations}
                    renderItem={({ item, index }: any) => (
                        <ListItem
                            title={item?.name || "New Destination"}
                            style={{
                                backgroundColor: selectedDestination && selectedDestination === item ? 'green' : '',
                                border: '1px solid black'
                            }}
                            accessoryRight={() =>
                                <View style={{ flexDirection: 'row' }}>
                                    <Button
                                        status='success'
                                        appearance='outline'
                                        onPress={() => {
                                            setSelectedDestinationText(item.name);
                                            setSelectedDestination(item);
                                            setSelectedDestinationIndex(index);
                                            setShowEditDestinationModal(true);
                                        }}
                                    >
                                        <Icon name='edit-outline' fill="white" width={15} height={15} />
                                    </Button>
                                    <Button
                                        status="danger"
                                        appearance='outline'
                                        onPress={() => {
                                            onRemoveDestination(field, item);
                                        }}
                                    >
                                        <Icon name='trash-2-outline' fill="white" width={15} height={15} />
                                    </Button>
                                </View>
                            }
                            onPress={() => {
                                setSelectedDestinationText(item);
                                setSelectedDestination(item);
                                console.log('selected destination', item);
                                setSelectedDestinationTypeIndex(null);
                            }}
                        />
                    )}
                />
            </View>
        )
    }



    const RenderEditDestinationTypeModal = () => {
        const destinationTypeTextRef = useRef("");
        return (
            <Modal
                visible={showEditDestinationTypeModal}
                backdropStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
                onBackdropPress={() => setShowEditDestinationTypeModal(false)}
            >
                <Card disabled={true}>
                    <Input
                        label="Edit Destination Type"
                        defaultValue={selectedDestinationTypeText}
                        onChangeText={(val: string) => destinationTypeTextRef.current = val}
                    />
                    <Button
                        onPress={() => {
                            //@ts-ignore
                            let _selectedDestination = { ...selectedDestination };
                            _selectedDestination.destinationTypes[selectedDestinationTypeIndex] = destinationTypeTextRef.current;
                            onUpdateDestination(field, _selectedDestination, selectedDestinationIndex);
                            setShowEditDestinationTypeModal(false);
                        }}
                    >
                        Save
                    </Button>
                </Card>
            </Modal>
        )
    }

    /**
     * Render the multi destination types list for selected destination
     */
    const RenderDestinationTypesList = () => {
        //@ts-ignore
        if (!selectedDestination || !selectedDestination?.destinationTypes) return null;
        return (
            <Card
                style={{}}
                header={() => <Text category='s1'>Destination Types</Text>}
                disabled
                status="primary"
            >
                <List
                    style={{}}
                    // @ts-ignore
                    data={selectedDestination.destinationTypes}
                    renderItem={({ item, index }: any) => (
                        <ListItem
                            title={item}
                            style={{
                                backgroundColor: selectedDestinationTypeIndex === index ? 'green' : '',
                                border: '1px solid black'
                            }}
                            accessoryRight={() =>
                                <View style={{ flexDirection: 'row' }}>
                                    <Button
                                        status='success'
                                        appearance='outline'
                                        onPress={() => {
                                            setSelectedDestinationTypeIndex(index);
                                            setSelectedDestinationTypeText(item);
                                            setShowEditDestinationTypeModal(true);
                                        }}
                                    >
                                        <Icon name='edit-outline' fill="white" width={15} height={15} />
                                    </Button>
                                    <Button
                                        status="danger"
                                        appearance='outline'
                                        onPress={() => {
                                            //@ts-ignore
                                            let _selectedDestination = { ...selectedDestination };
                                            //@ts-ignore
                                            _selectedDestination.destinationTypes = _selectedDestination.destinationTypes.filter((t) => t !== selectedDestination.destinationTypes[selectedDestinationTypeIndex]);
                                            onUpdateDestination(field, _selectedDestination, selectedDestinationIndex);
                                        }}
                                    >
                                        <Icon name='trash-2-outline' fill="white" width={15} height={15} />
                                    </Button>
                                </View>
                            }
                            onPress={() => {
                                setSelectedDestinationTypeIndex(index);
                                setSelectedDestinationTypeText(item);
                            }}
                        />
                    )}
                />
            </Card>
        )
    }

    return (
        <Card
            style={{}}
            header={() => <Text category='s1'>{label}</Text>}
            disabled
            status="primary"
        >
            <View style={{ flex: 1, }}>
                <ScrollView style={{}}>
                    <ErrorBoundary>
                        <RenderEditDestinationTypeModal />
                        <RenderNewDestinationModal />
                        <View style={{ flexDirection: 'row', gap: 5, flex: 1 }}>
                            <View style={{ flex: 1, gap: 10 }}>
                                <RenderButtons />
                                <RenderDestinationsList />
                            </View>
                            <View style={{ flex: 1, gap: 10 }}>
                                <RenderDestinationTypesList />
                            </View>
                        </View>
                    </ErrorBoundary>
                </ScrollView>
            </View>
        </Card>
    )
}


const NewServiceModal = ({
    visible,
    handleServiceCreate,
    setShowNewServiceModal,
}: any) => {
    const [name, setName] = useState("");

    const handleSave = () => {
        if (name.length == 0) {
            return;
        }
        handleServiceCreate(name);
        setShowNewServiceModal(false);
    }
    const handleCancel = () => {
        setShowNewServiceModal(false);
    }

    return (
        <Modal
            animationType="slide"
            transparent={true}
            visible={visible}
            onRequestClose={() => {
                handleCancel();
            }}
        >
            <Card
                disabled
                status="primary"
                header={() => <Text>Create New Service</Text>}
                style={{ width: '80%', height: '30%', justifyContent: 'center', alignItems: 'center' }}>
                <Input
                    placeholder="Service Name"
                    onChangeText={(text: string) => setName(text)}
                    value={name}
                />
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Button status="success" onPress={() => handleSave()}>Save</Button>
                    <Button status="warning" appearance="ghost" onPress={() => handleCancel()}>Cancel</Button>
                </View>
            </Card>
        </Modal>

    )
}



const NewFieldModal = ({
    onClose,
    showNewFieldModal,
    addField
}: {
    onClose: any,
    showNewFieldModal: boolean,
    addField: Function
}) => {
    const [newField, setNewField] = useState("");
    const [newFieldType, setNewFieldType] = useState(SERVICE_FIELD_TYPES.input);

    const handleNewFieldSave = () => {
        addField(newField);
        // addType(newField, newFieldType);
        onClose();
    }

    return (
        <Modal
            animationType="slide"
            transparent={true}
            visible={showNewFieldModal}
            onRequestClose={() => {
                onClose();
            }}
        >
            <Layout
                style={{
                    flex: 1,
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "rgba(0,0,0,0.5)"
                }}
            >
                <Layout
                    style={{
                        width: "80%",
                        backgroundColor: "white",
                        padding: 20,
                        borderRadius: 10
                    }}
                >
                    <Text category="h6">New Field</Text>
                    <Input
                        placeholder="Field Name"
                        value={newField}
                        onChangeText={setNewField}
                    />
                    <Layout
                        style={{
                            flexDirection: "row",
                            justifyContent: "flex-end"
                        }}
                    >
                        <Button
                            onPress={() => {
                                onClose();
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            onPress={() => {
                                handleNewFieldSave();
                            }}
                        >
                            Save
                        </Button>
                    </Layout>
                </Layout>
            </Layout>
        </Modal>
    )
}