import React from 'react';
import { View, ScrollView } from 'react-native';
import { Layout, Text, Button, Card, CheckBox, Icon } from "@ui-kitten/components";
import { Client } from '../../models/Client.model';
import { Member } from '../../models/Member.model';
import * as StorageController from '../../functions/storageController'

interface FilterPanelProps {
    clients: Client[],
    filteredClients: string[],
    drivers: Member[],
    filteredDrivers: string[],
    setFilteredClients: (clients: string[]) => void,
    setFilteredDrivers: (drivers: string[]) => void,
    setShowFilters: (show: boolean) => void,
    showFilters: boolean
}

export const FilterPanel = ({
    clients,
    filteredClients,
    setFilteredClients,
    setShowFilters,
    showFilters,
    drivers,
    filteredDrivers,
    setFilteredDrivers
}: FilterPanelProps) => {
    const isClient = StorageController.getAppState().selectedMembership?.is_client
    return (
        <Layout
            style={{ flex: 1, borderTopWidth: 3, borderTopLeftRadius: 10, borderTopRightRadius: 10, borderColor: 'orange' }}
        >
            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                <Text category='s1'>Filters</Text>
                {showFilters ?
                    <Button
                        status="danger"
                        onPress={() => setShowFilters(false)}
                        style={{}}
                        size="tiny"
                    >
                        <Icon name='close-outline' width={15} height={15} fill="white" />
                    </Button>
                    :
                    <></>
                }
            </View>
            <View style={{
                flexDirection: 'column',
                flex: 1,
            }}>
                <ScrollView style={{}}>
                    {!isClient ?
                        <RenderClientList
                            clients={clients}
                            filteredClients={filteredClients}
                            setFilteredClients={setFilteredClients}
                        />
                        : <></>}
                    {!isClient ?
                        <RenderDriversList
                            drivers={drivers}
                            filteredDrivers={filteredDrivers}
                            setFilteredDrivers={setFilteredDrivers}
                        />
                        : <></>}
                </ScrollView>

            </View>
        </Layout>
    )
}




const RenderClientList = ({ clients, filteredClients, setFilteredClients }: any) => {
    return (
        <Card
            style={{ padding: 5 }}
            status='success'
            disabled={true}
            header={() =>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Text category='s1'>Clients</Text>
                </View>
            }
        >
            <View style={{ flexDirection: 'column', margin: -15 }}>
                {clients?.map((client: Client, index: number) => {
                    return (
                        <CheckBox
                            key={index}
                            checked={filteredClients.includes(client._id)}
                            onChange={(nextChecked: boolean) => {
                                if (nextChecked) {
                                    setFilteredClients([...filteredClients, client._id])
                                } else {
                                    setFilteredClients(filteredClients.filter((c: string) => c !== client._id))
                                }
                            }}>
                            {client.name}
                        </CheckBox>
                    )
                })}
            </View>
        </Card>
    )
}

const RenderDriversList = ({ drivers, filteredDrivers, setFilteredDrivers }: any) => {
    return (
        <Card
            style={{ padding: 5 }}
            status='success'
            disabled={true}
            header={() =>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Text category='s1'>Drivers</Text>
                </View>
            }
        >
            <View style={{ flexDirection: 'column', margin: -15 }}>
                {drivers?.map((driver: Member, index: number) => {
                    return (
                        <CheckBox
                            key={index}
                            checked={filteredDrivers.includes(driver._id)}
                            onChange={(nextChecked: boolean) => {
                                if (nextChecked) {
                                    setFilteredDrivers([...filteredDrivers, driver._id])
                                } else {
                                    setFilteredDrivers(filteredDrivers.filter((d: string) => d !== driver._id))
                                }
                            }}>
                            {driver.name}
                        </CheckBox>
                    )
                })}
            </View>
        </Card>
    )
}