import React, { useState, useEffect, useReducer, useRef, useCallback, useMemo } from 'react';
import {
    Image,
    AccessibilityRole,
    ImageProps,
    ImageStyle,
    StyleSheet,
    TouchableOpacity,
    View,
    Text as RNText,
    Animated,
    useWindowDimensions,
    AppState
    //@ts-ignore
} from "react-native";
import {
    ApplicationProvider,
    Button,
    Icon,
    IconRegistry,
    Layout,
    Text,
    List,
    ListItem,
    Card,
    Toggle,
    Modal
} from "@ui-kitten/components";
import moment from 'moment';
import Toast, { ToastType } from 'react-native-toast-message';
import * as MembershipsController from '../../functions/membership.controller'
import * as UpdateController from '../../functions/update.controller'
import * as ImageController from '../../functions/image.controller'
import * as ClientController from '../../functions/client.controller'
import * as StorageController from '../../functions/storageController'
import * as CompanyController from '../../functions/company.controller'
import * as RootNavigation from '../../routes';
import * as UserController from '../../functions/user.controller';
import * as JobController from '../../functions/job.controller';
import { JOB_STATUS, Job } from '../../models/Job.model';
import { Client } from '../../models/Client.model';
import { Company } from '../../models/Company.model';
import { Portal } from '@gorhom/portal';
//import audio
import { Audio } from 'expo-av';
import { ScrollView } from 'react-native-gesture-handler';
import { JobDetailContainer } from '../job/details/jobDetailContainer.component';
import { set } from 'lodash';
import { useAppStateChange, IAction, STATE_ACTIONS } from '../../hooks/appStateChange.hook';
// create audio object for new job notification
// create audio object for new message notification
const messageAudio = new Audio.Sound();

const generalJobAudio = new Audio.Sound();

const newRequestAudio = new Audio.Sound();
const arrivedAudio = new Audio.Sound();
const completeAudio = new Audio.Sound();
const cancelAudio = new Audio.Sound();
const pendingAudio = new Audio.Sound();
const alertAudio = new Audio.Sound();

let var_playAudio = true
// load audio files
const loadAudio = async () => {
    try {
        await generalJobAudio.loadAsync(require('../../assets/audio/newjob.mp3'));
        await messageAudio.loadAsync(require('../../assets/audio/message.mp3'));
        await newRequestAudio.loadAsync(require('../../assets/audio/newrequest.mp3'));
        await arrivedAudio.loadAsync(require('../../assets/audio/arrived.mp3'));
        await pendingAudio.loadAsync(require('../../assets/audio/pending.mp3'));
        await completeAudio.loadAsync(require('../../assets/audio/complete.mp3'));
        await cancelAudio.loadAsync(require('../../assets/audio/cancel.mp3'));
        await alertAudio.loadAsync(require('../../assets/audio/alert.mp3'));

    } catch (error) {
        console.log(error);
    }
}

loadAudio();

// let hasUserInteracted = false;
// function handleUserInteraction() {
//     hasUserInteracted = true;
//     document.removeEventListener('click', handleUserInteraction);
//     document.removeEventListener('keydown', handleUserInteraction);
// }
// // Add event listeners for user interactions
// document.addEventListener('click', handleUserInteraction);
// document.addEventListener('keydown', handleUserInteraction);

const DEVICE_MODE = {
    DESKTOP: 'DESKTOP',
    MOBILE: 'MOBILE'
}


export class DashboardNotification {
    id = Math.floor(Math.random() * 1000000000)
    title: string
    message: string
    icon: any
    color: any
    time: string
    data: any
    type: string
    constructor(title: string, message: string, icon: any, color: any, time: string, data: any, type: string) {
        this.title = title
        this.message = message
        this.icon = icon
        this.color = color
        this.time = time
        this.data = data
        this.type = type
    }
}

enum ALERT_TYPE {
    ETA = 'ETA',
    CLOSE_TO_ETA = 'CLOSE_TO_ETA',
    UNASSIGNED_JOB_TIME = 'UNASSIGNED_JOB_TIME',
    ASSIGNED_BUT_NOT_ACCEPTED = 'ASSIGNED_BUT_NOT_ACCEPTED'
}

const alertTypeToTitle = (type: ALERT_TYPE) => {
    switch (type) {
        case ALERT_TYPE.ETA:
            return 'Over ETA'
        case ALERT_TYPE.CLOSE_TO_ETA:
            return 'Close To ETA'
        case ALERT_TYPE.UNASSIGNED_JOB_TIME:
            return 'Unassigned Job Time Exceeded'
        case ALERT_TYPE.ASSIGNED_BUT_NOT_ACCEPTED:
            return 'Driver Accept Time Exceeded'
        default:
            return 'Alert'
    }
}

export class ETANotification {
    job: Job
    company: Company
    client: Client | null
    eta: number
    message: string
    dismissed = false
    type: ALERT_TYPE

    constructor(details: any = {}) {
        this.job = new Job(details.job ?? {})
        this.company = new Company(details.company ?? {})
        this.client = new Client(details.client ?? {})
        this.eta = details.eta ?? 60
        this.message = details.message ?? ""
        this.dismissed = details.dismissed ?? false
        this.type = details.type ?? ALERT_TYPE.ETA
    }
}




function getAllCompanyAlerts() {
    let companies = StorageController.getAppState().companies
    if (!companies) return []
    let alerts = [] as ETANotification[]
    for (let company of companies) {
        let companyAlerts = processCompanyEtaNotification(company)
        alerts = [...alerts, ...companyAlerts]
    }
    return alerts
}

function processCompanyEtaNotification(company: Company) {
    const etaAlertEnabled = company.settings?.alerts?.enable_over_eta_alerts
    const closeToEtaEnabled = company.settings?.alerts?.enable_close_to_eta_alerts
    const unassignedTimeAlertsEnabled = company.settings?.alerts?.enable_unassigned_job_time_alerts
    const assignedButNotAcceptedAlertsEnabled = company.settings?.alerts?.enable_assigned_driver_not_accepted_job_time_alerts
    if (!etaAlertEnabled && !closeToEtaEnabled && !unassignedTimeAlertsEnabled && !assignedButNotAcceptedAlertsEnabled) return []
    let jobs = company.jobs
    let etas = [] as ETANotification[]
    let closeToEtaAlerts = [] as ETANotification[]
    let unassignedTimeAlerts = [] as ETANotification[]
    let assignedButNotAcceptedAlerts = [] as ETANotification[]
    for (let job of jobs) {
        job = new Job(job)
        const isCurrentJob = job.status == JOB_STATUS.UNASSIGNED || job.status == JOB_STATUS.ASSIGNED
        if (!isCurrentJob) continue
        if (!job.dispatchable) continue
        const client = company.clients.find((client) => client._id == job.client_id)
        const eta = job.vendor_eta || 60
        const etaMillis = eta * 60000
        const startTime = job.start_time // as unix millis
        const startTimeWithEta = startTime + etaMillis
        const currentTime = new Date().getTime()

        const currentTimeRemaining = startTimeWithEta - currentTime
        // console.log("🚀============== ~ file: notifications.component.tsx:173 ~ processCompanyEtaNotification ~ currentTimeRemaining🚀==============", currentTimeRemaining)
        let currentTimeRemainingMinutes = 0
        if (currentTimeRemaining != 0) {
            currentTimeRemainingMinutes = Math.floor(currentTimeRemaining / 60000)
        }
        // console.log("🚀============== ~ file: notifications.component.tsx:176 ~ processCompanyEtaNotification ~ currentTimeRemainingMinutes🚀==============", currentTimeRemainingMinutes)
        if (etaAlertEnabled) {
            if (currentTimeRemainingMinutes < 0) {
                let client = company.clients.find((client) => client._id == job.client_id)
                let etaNotification = new ETANotification({
                    job: job,
                    company: company,
                    client: client || null,
                    eta: eta,
                    message: `${client?.name || ""}:  ${job.getServicesNames()} - Over ETA by ${Math.abs(currentTimeRemainingMinutes)} Minutes`,
                    type: ALERT_TYPE.ETA
                })
                etas.push(etaNotification)
            }
        }
        if (closeToEtaEnabled) {
            const threshold = company.settings.alerts.close_to_eta_alert_time_theshold
            if (currentTimeRemainingMinutes <= threshold && currentTimeRemainingMinutes > 0) {
                let etaNotification = new ETANotification({
                    job: job,
                    company: company,
                    client: client || null,
                    eta: eta,
                    message: `${client?.name || ""}: ${job.getServicesNames()} - Close To ETA ${threshold} Minutes Remaining`,
                    type: ALERT_TYPE.CLOSE_TO_ETA
                })
                closeToEtaAlerts.push(etaNotification)
            }
        }
        if (unassignedTimeAlertsEnabled && job.status == JOB_STATUS.UNASSIGNED) {
            const threshold = company.settings.alerts.unassigned_job_time_threshold
            if (currentTimeRemainingMinutes < threshold) {
                let client = company.clients.find((client) => client._id == job.client_id)
                let etaNotification = new ETANotification({
                    job: job,
                    company: company,
                    client: client || null,
                    eta: eta,
                    message: `${client?.name || ""}: ${job.getServicesNames()} - Unassigned Job Time Exceeded ${threshold} Minutes`,
                    type: ALERT_TYPE.UNASSIGNED_JOB_TIME
                })
                unassignedTimeAlerts.push(etaNotification)
            }
        }
        if (assignedButNotAcceptedAlertsEnabled && job.status == JOB_STATUS.ASSIGNED) {
            const threshold = company.settings.alerts.assigned_driver_not_accepted_job_time_threshold
            const driver_accepted = job.driver_accepted
            if (currentTimeRemainingMinutes < threshold && !driver_accepted) {
                let etaNotification = new ETANotification({
                    job: job,
                    company: company,
                    client: client || null,
                    eta: eta,
                    message: `${client?.name || ""}: ${job.getServicesNames()} - Driver Assigned But Not Accepted Time Exceeded ${threshold} Minutes`,
                    type: ALERT_TYPE.ASSIGNED_BUT_NOT_ACCEPTED
                })
                assignedButNotAcceptedAlerts.push(etaNotification)
            }
        }
    }
    return [...etas, ...closeToEtaAlerts, ...unassignedTimeAlerts, ...assignedButNotAcceptedAlerts]
}




export const NotificationsComponent = () => {


    const [eventsRegistered, setEventsRegistered] = useState(false)
    const [notificationsList, setNotificationsList] = useState<DashboardNotification[]>([])
    const [notificationsCount, setNotificationsCount] = useState(0)
    const [windowHeight, setWindowHeight] = React.useState(useWindowDimensions().height)
    const [x, forceUpdate] = useReducer(x => x + 1, 0);
    const [playAudio, setPlayAudio] = useState(true)


    const [hasUserInteractedState, setHasUserInteractedState] = useState(true)
    const hasUserInteractedStateRef = useRef(true)


    const windowWidth = useWindowDimensions().width;
    // const windowHeight = useWindowDimensions().height;
    const [viewMode, setViewMode] = useState(DEVICE_MODE.DESKTOP)
    const [showEtaNotifications, setShowEtaNotifications] = useState(false)


    const [etaNotificationAlerts, setEtaNotifications] = useState([] as ETANotification[])


    const etaNotificationAlertsRef = useRef([] as ETANotification[]);

    const notificationsListRef = useRef([] as DashboardNotification[]);

    useEffect(() => {
        // if is a mobile device set the view mode to list
        if (windowWidth < 800) {
            setViewMode(DEVICE_MODE.MOBILE)
        }
    }, [windowWidth])

    useEffect(() => {
        if (!eventsRegistered) {
            setTimeout(() => {
                checkJobsForAlertTriggers()
            }, 10000)
        }
    }, [])

    useEffect(() => {
        document.addEventListener('click', handleUserInteraction);
        document.addEventListener('keydown', handleUserInteraction);
        return () => {
            document.removeEventListener('click', handleUserInteraction);
            document.removeEventListener('keydown', handleUserInteraction);
        }
    }, [])

    const onSetEtaNotifications = (etaNotifications: ETANotification[]) => {
        setEtaNotifications(etaNotifications)
        etaNotificationAlertsRef.current = etaNotifications
    }

    const handleUserInteraction = () => {
        if (hasUserInteractedStateRef.current) return;
        setHasUserInteractedState(true)
        hasUserInteractedStateRef.current = true
    }


    const getJob = async (eventData: any) => {
        try {
            const job = eventData.job
            const job_id = eventData.data
            if (job) return job
            if (eventData.job_id) {
                let company = StorageController.getAppState().companies?.find((company) => company._id == job.company_id) as Company
                let job = company.jobs.find((job: Job) => job._id == job_id) as Job
                return new Job(job)
            }
            return null
        } catch (error) {
            console.log(error);
        }
    }

    // get user from id
    const getMember = async (member_id: string, company_id: string) => {
        try {
            let company = StorageController.getAppState().companies?.find((company) => company._id == company_id)
            let member = company?.members?.find((member) => member._id == member_id)
            // let member = StorageController.getAppState().members.find((member) => member._id == id)
            return member
        } catch (error) {
            console.log(error);
        }
    }

    // get client from id
    const getClient = async (client_id: string, company_id: string) => {
        try {
            let company = StorageController.getAppState().companies?.find((company) => company._id == company_id)
            let client = company?.clients?.find((client) => client._id == client_id)
            if (!client) {
                //@ts-ignore - making it a company just for the name
                client = await CompanyController.getCompanyById(client_id)
                if (!client) {
                    return {
                        name: "Client Job:"
                    }
                }
            }
            return client
        } catch (error) {
            console.log(error);
        }
    }

    const getCompanyById = async (id: string) => {
        try {
            let company = StorageController.getAppState().companies?.find((company) => company._id == id)
            return company
        } catch (error) {
            console.log(error);
        }
    }

    const shouldNotifyJob = (job: Job) => {
        try {
            let result = false
            // console.log("🚀 ~ file: notifications.component.js:122 ~ shouldNotifyJob ~ job:", job)
            // get user memberships
            const memberships = StorageController.getAppState().memberships;
            const companies = StorageController.getAppState().companies;
            // find company job belongs to
            const company = companies?.find(c => c._id == job.company_id || c._id == job.client_company_id || c._id == job.vendor_company_id);
            // get membership for company
            const member = memberships?.find(m => m.company_id == company?._id);
            if (!member || !job) result = false
            if (member?.is_admin) result = true
            if (member?.is_operator) result = true
            if (member?.is_client) {
                if (job.client_id == member?.client_id) {
                    result = true
                }
            }
            // // console.log("🚀 ~ file: notifications.component.js:140 ~ shouldNotifyJob ~ result", result, member)
            return result
        }
        catch (error) {
            console.log(error);
        }
    }

    const playAlertAudio = useCallback(() => {
        try {
            if (!hasUserInteractedStateRef.current) return;
            if (var_playAudio === true) {
                alertAudio.playAsync();
            }
        } catch (error) {
            console.log(error);
        }
    }, [playAudio, hasUserInteractedState])


    const playNewJobAudio = useCallback(() => {
        try {
            if (!hasUserInteractedStateRef.current) return;
            if (var_playAudio === true) {
                generalJobAudio.playAsync();
            }
        } catch (error) {
            console.log(error);
        }
    }, [playAudio, hasUserInteractedState])

    const playJobRequestAudio = useCallback(async () => {
        try {
            if (!hasUserInteractedStateRef.current) return;
            if (var_playAudio === true) {
                await newRequestAudio.setVolumeAsync(0.5)
                newRequestAudio.playAsync();
            }
        } catch (error) {
            console.log(error);
        }
    }, [playAudio, hasUserInteractedState])

    // play audio for pending job
    const playPendingJobAudio = useCallback(() => {
        try {
            if (!hasUserInteractedStateRef.current) return;
            if (var_playAudio === true) {
                pendingAudio.playAsync();
            }
        } catch (error) {
            console.log(error);
        }
    }, [playAudio, hasUserInteractedState])

    // play audio for arrived job
    const playArrivedJobAudio = useCallback(() => {
        try {
            if (!hasUserInteractedStateRef.current) return;
            if (var_playAudio === true) {
                arrivedAudio.playAsync();
            }
        } catch (error) {
            console.log(error);
        }
    }, [playAudio, hasUserInteractedState])

    // play audio for complete job
    const playCompleteJobAudio = useCallback(() => {
        try {
            if (!hasUserInteractedStateRef.current) return;
            if (var_playAudio === true) {
                completeAudio.playAsync();
            }
        } catch (error) {
            console.log(error);
        }
    }, [playAudio, hasUserInteractedState])

    // play audio for cancel job
    const playCancelJobAudio = useCallback(() => {
        try {
            if (!hasUserInteractedStateRef.current) return;
            if (var_playAudio === true) {
                cancelAudio.playAsync();
            }
        } catch (error) {
            console.log(error);
        }
    }, [playAudio, hasUserInteractedState])


    const checkJobNotificationAgainstEtaAlerts = useCallback((job: Job) => {

        let etaAlerts = [...etaNotificationAlertsRef.current]
        let matchingEtaAlerts = etaAlerts.filter((alert) => alert.job._id == job._id)

        if (matchingEtaAlerts.length > 0) {
            // check if the new status is marked as pending, complete, unassigned, cancelled
            // if it is, remove the alerts from the list
            let newStatus = job.status
            if (newStatus != JOB_STATUS.REQUEST && newStatus != JOB_STATUS.ASSIGNED && newStatus != JOB_STATUS.UNASSIGNED) {
                let newEtaAlerts = etaAlerts.filter((alert) => alert.job._id != job._id)
                onSetEtaNotifications(newEtaAlerts)
            }
        }
    }, [etaNotificationAlerts])


    // updated jobs event
    const handleUpdatedJobsEvent = useCallback((async (action: IAction) => {
        try {

            if (action.hasOwnProperty('data') && action.data.hasOwnProperty('data')) {
                if (action.data.hasOwnProperty('notify')) {
                    if (action.data.notify == false) {
                        return;
                    }
                }
                const job = await getJob(action.data)

                // Clear the notification from the list if the job is no longer valid to track
                checkJobNotificationAgainstEtaAlerts(job)

                if (!shouldNotifyJob(job)) return;
                //Check if document has been interacted with by user to prevent error





                // play audio
                try {
                    if (job.status == JOB_STATUS.REQUEST) {
                        playJobRequestAudio()
                    }
                    else if (job.status == JOB_STATUS.UNASSIGNED) {
                        playJobRequestAudio()
                    }
                    else if (job.status == JOB_STATUS.ASSIGNED) {
                        playNewJobAudio();
                    }
                    else if (job.status == JOB_STATUS.PENDING) {
                        playPendingJobAudio();
                    }
                    else if (job.status == JOB_STATUS.COMPLETE) {
                        playCompleteJobAudio();
                    }
                    else if (job.status == JOB_STATUS.CANCELLED) {
                        playCancelJobAudio();
                    }
                    else if (job.status == JOB_STATUS.QUOTE) {
                        //
                    }
                    else {
                        // do nothing
                    }
                } catch (e) {
                    console.log(e);
                    playNewJobAudio();
                }
                // newJobAudio.playAsync();

                const member = await getMember(job?.member_id, job?.company_id)
                const client = await getClient(job?.client_id, job?.company_id)
                const company = await getCompanyById(job?.company_id)
                // console.log("🚀 ~ file: notifications.component.js:274 ~ getClient ~ client:", client)
                let title = "Job Updated"
                let message = ""
                if (client) {
                    let j = new Job(job)
                    let services = j.getServicesNames()
                    if (client.name && client.name.toLowerCase().includes('error')) {
                        client.name = ""
                    }
                    title = `${company?.name} - ${client?.name + ":" || ""} ${member?.name || ""}`
                    message = `${services} ${job?.status}`

                }
                showtoastNotification(title, message, 'success')
                // console.log("🚀 ~ file: notifications.component.js:155 ~ getClient ~ title, message:", title, message)
                addNotification(title, message, 'checkmark-circle-2-outline', 'success', job, "JOB_UPDATE")
            }

        } catch (error) {
            console.log(error);
        }
    }), [notificationsList, notificationsCount, playAudio, hasUserInteractedState])


    // register new message event
    const handleMessageUpdateEvent = async (action: IAction) => {
        try {
            // console.log('===new message====', action.data);
            let data = action.data.data
            // play audio
            try {
                messageAudio.playAsync();
            } catch (e) {
                console.log(e);
            }
            let name = data?.user?.name || ""
            let message = data?.text || ""
            let title = `${name} sent you a message`
            // only show the first 20 characters of the message
            if (message.length > 20) {
                message = message.substring(0, 20) + '...'
            }
            showtoastNotification(title, message, 'success')
            addNotification(title, message, 'message-circle-outline', 'success', data, "MESSAGE_UPDATE")
        } catch (error) {
            console.log(error);
        }
    }



    const handleUpdatedOtherCompanyEvent = (action: IAction) => {
        // console.log('other company event fired', action.data);
        try {
        } catch (e) {
            console.log(e);
        }
    }


    const handleUpdatedSelectedCompanyEvent = async (action: IAction) => {
        try {
            // console.log('===company changed====');
            // addNotification('Company Changed', 'You have changed your company', 'checkmark-circle-2-outline', 'success')
            // messageAudio.playAsync();
            // showtoastNotification('Company Changed', 'You have changed your company', 'success')
        } catch (error) {
            console.log(error);
        }
    }

    const handleLoggedOutEvent = async (action: IAction) => {
        try {
            // console.log('===logged out====');
            showtoastNotification('Logged Out', 'You have logged out', 'success')
        } catch (error) {
            console.log(error);
        }
    }

    const handleSetLoggedInEvent = async (action: IAction) => {
        try {
            // console.log('===logged in====');
            showtoastNotification('Logged In', 'You have logged in', 'success')

        } catch (error) {
            console.log(error);
        }
    }

    const { dispatchEventStateChange } = useAppStateChange({
        onUpdatedJobs: handleUpdatedJobsEvent,
        onMessageUpdate: handleMessageUpdateEvent,
        onUpdatedOtherCompany: handleUpdatedOtherCompanyEvent,
        onUpdatedSelectedCompany: handleUpdatedSelectedCompanyEvent,
        onSetLoggedOut: handleLoggedOutEvent,
        onSetLoggedIn: handleSetLoggedInEvent
    });



    const showtoastNotification = (title: string, message: string, type: ToastType) => {
        try {
            // console.log("🚀 ~ file: notifications.component.js:266 ~ showtoastNotification ~ title, message, type:", "title:", title, "msg:",message, "type",type)
            const _title = (
                <Text>{title}</Text>
            )
            const _message = (
                <Text>{message}</Text>
            )
            Toast.show({
                text1: title,
                text2: message,
                type: type,
                //@ts-ignore - does exist
                duration: 1000,
            })
        } catch (e) {
            console.log(e);
        }
    }






    const addNotification = (title: string, message: string, icon: any, color: any, data = {}, type = "DEFAULT") => {
        let time = moment().format('hh:mm a')
        // make random uuid for notification
        let newNotification = new DashboardNotification(title, message, icon, color, time, data, type)
        // ensure notificaiton does not already exist in list
        // based on title, message and data
        let exists = notificationsListRef.current.find((notification: DashboardNotification) => {
            const dat = JSON.stringify(notification.data)
            const newData = JSON.stringify(newNotification.data)
            return notification.title == title && notification.message == message && dat == newData
        })
        if (exists) return;
        let newList = [...notificationsList, newNotification]

        notificationsListRef.current.unshift(newNotification)
        setNotificationsList(newList)
        setNotificationsCount(notificationsListRef.current.length)
        forceUpdate()
        // console.log("==== added notification====", newNotification, notificationsList.length, notificationsList, "====")
    }

    const clearNotifications = () => {
        setNotificationsList([])
        notificationsListRef.current = []
        setNotificationsCount(0)
        setShowList(false)
        forceUpdate()
    }

    const readNotifications = () => {
        setNotificationsCount(0)
        forceUpdate()
    }


    const [showList, setShowList] = useState(false)

    const toggleList = () => {
        setShowList(!showList)
    }

    const RenderNotificationIcon = () => {
        const isSmall = viewMode == DEVICE_MODE.MOBILE
        const buttonStyle = isSmall ? { width: 25, height: 30 } : { width: 50, height: 60 }
        const iconStyle = isSmall ? { width: 25, height: 25 } : { width: 50, height: 50 }
        if (notificationsCount > 0) {
            return (
                <Button
                    appearance="ghost"
                    style={buttonStyle}
                    // size="tiny"
                    status="warning"
                    onPress={() => { toggleList(); readNotifications() }}
                >
                    <View style={{ flexDirection: 'column', justifyContent: "center" }}>
                        <Icon name='bell' fill='red' width={iconStyle.width} height={iconStyle.height} />
                        <Icon name='alert-circle' fill='red' style={{ position: 'absolute', bottom: 0, right: 0, width: iconStyle.width * 0.5, height: iconStyle.height * 0.5 }} />
                        {notificationsCount && notificationsCount > 0 &&
                            <Text
                                style={{ textAlign: "center" }}
                                status="warning"
                                category="s2"
                            >
                                {isSmall ? "" : notificationsCount}
                            </Text>
                        }
                    </View>
                </Button>
            )
        }
        else {
            return (
                <Button
                    // size="tiny"
                    appearance="ghost"
                    status="basic"
                    style={buttonStyle}
                    onPress={toggleList}>
                    <Icon name='bell-outline' fill='white' width={iconStyle.width} height={iconStyle.height} />
                </Button>
            )
        }
    }


    const RenderEtaNotificationButton = React.useCallback(() => {
        const isSmall = viewMode == DEVICE_MODE.MOBILE
        const buttonStyle = isSmall ? { width: 25, height: 30 } : { width: 50, height: 60 }
        const iconStyle = isSmall ? { width: 25, height: 25 } : { width: 50, height: 50 }
        const shownAlerts = etaNotificationAlerts.filter((alert) => !alert.dismissed)
        return (
            <Button
                style={buttonStyle}
                onPress={() => setShowEtaNotifications(!showEtaNotifications)}
                appearance='ghost'
                status='warning'
            >
                <View style={{ flexDirection: 'column', alignContent: "center" }}>
                    <Icon name='alert-triangle-outline' fill='red' style={iconStyle} />
                    <Text
                        style={{ textAlign: "center" }}
                        status="warning"
                        category="s2"
                    >
                        {isSmall ? "" : shownAlerts.length}
                    </Text>
                </View>
            </Button>
        )
    }, [etaNotificationAlerts, showEtaNotifications])

    const onPressNotification = (action: DashboardNotification) => { // comes from the event listener
        // console.log("====onPressNotification====", action, "====")
        if (!action) return;
        if (!action.hasOwnProperty('type')) return;
        if (action.type == 'MESSAGE_UPDATE') {
            // maybe open chat or something
        }
        if (action.type == 'JOB_UPDATE') {

            // maybe open job list or something
        }
    }


    const onTogglePlayAudio = (value: boolean) => {
        setPlayAudio(value)
        var_playAudio = value
        forceUpdate()
    }

    const RenderAudioToggle = () => {
        return (
            <View style={{ flexDirection: 'column', alignItems: 'center' }}>
                <Text category='label' style={{ fontWeight: 'bold' }}>Alert</Text>
                <Toggle
                    size='small'
                    checked={playAudio}
                    onChange={(checked: boolean) => {
                        onTogglePlayAudio(checked)
                    }}
                />
            </View>
        )
    }

    const [selectedJob, setSelectedJob] = useState<Job | null>(null)
    const [selectedCompany, setSelectedCompany] = useState<Company | null>(null)
    const [jobDetailsModalOpen, setJobDetailsModalOpen] = useState(false)

    const openJobDetailsModal = () => {
        setJobDetailsModalOpen(true)
    }

    const closeJobDetailsModal = () => {
        setJobDetailsModalOpen(false)
    }

    const onOpenJobDetails = async (job: Job) => {
        const fetchedJob = await JobController.getJobById(job._id)
        if (!fetchedJob) return
        // if job is not the selected company, switch to that company
        const currentCompany = StorageController.getCurrentCompany()
        if (fetchedJob.company_id != currentCompany._id) {
            let company = StorageController.getAppState().companies?.find((company) => company._id == job.company_id)
            console.log("🚀============== ~ file: notifications.component.tsx:888 ~ onOpenJobDetails ~ company🚀==============", company)
            if (!company) return
            await CompanyController.switchCompany(company)
        }
        setSelectedJob(fetchedJob)
        openJobDetailsModal()
    }



    const RenderMemoizedJobDetails = React.useMemo(() => {
        if (!selectedJob) return null
        return (
            <Modal
                visible={jobDetailsModalOpen}
                backdropStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
                onBackdropPress={() => closeJobDetailsModal()}
                style={{ width: windowWidth * 0.9, height: windowHeight }}
            >
                <JobDetailContainer
                    selectedJob={selectedJob}
                    fn_closeJobDetailsModal={() => { closeJobDetailsModal() }}
                />
            </Modal>
        )
    }, [selectedJob, selectedCompany, jobDetailsModalOpen])



    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //////// ETA ALERTS
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */
    const checkEtaAlertTimeoutRef = useRef(null as any)
    const checkJobsForAlertTriggers = useCallback(() => {
        if (checkEtaAlertTimeoutRef.current) {
            clearTimeout(checkEtaAlertTimeoutRef.current)
        }
        let alerts = getAllCompanyAlerts()
        let isNewAlert = false
        let newAlerts = [] as ETANotification[]
        alerts.forEach((alert) => {
            if (!alertExists(alert)) {
                isNewAlert = true
                newAlerts.push(alert)
            }
        })
        onSetEtaNotifications([...newAlerts, ...etaNotificationAlertsRef.current])
        if (isNewAlert) {
            playAlertAudio()
            setShowEtaNotifications(true)
        }

        checkEtaAlertTimeoutRef.current = setTimeout(() => {
            checkJobsForAlertTriggers()
        }, 10000)
    }, [etaNotificationAlerts])

    const onDismissAlert = (alert: ETANotification) => {
        // find alert in list where alert.job._id and alert.type match
        const alertJobId = alert.job._id
        const alertType = alert.type

        let alerts = etaNotificationAlerts.map((a) => {
            if (a.job._id == alertJobId && a.type == alertType) {
                a.dismissed = true
            }
            return a
        })
        onSetEtaNotifications(alerts)
    }

    const loadInitialAlerts = () => {
        let alerts = getAllCompanyAlerts()
        if (alerts.length > 0) setShowEtaNotifications(true)
        onSetEtaNotifications(alerts)
    }

    const alertExists = useCallback((newAlert: ETANotification) => {
        let exists = false
        etaNotificationAlertsRef.current.map((currentAlert: ETANotification) => {
            const alertJobId = newAlert.job._id
            const alertType = newAlert.type
            const currentAlertJobId = currentAlert.job._id
            const currentAlertType = currentAlert.type
            if (alertJobId == currentAlertJobId && alertType == currentAlertType) {
                exists = true
            }
        })
        return exists
    }, [etaNotificationAlerts])


    const addEtaAlert = (alert: ETANotification) => {
        const exists = alertExists(alert)
        if (exists) return false;
        else {
            let newList = [alert, ...etaNotificationAlertsRef.current]
            onSetEtaNotifications(newList)
            return true
        }
    }



    const notificationsWidth = viewMode == DEVICE_MODE.DESKTOP ? (windowWidth * 0.2) : (windowWidth * 0.9)
    const nonDismissedEtaAlertsCount = useMemo(() => {
        const count = etaNotificationAlertsRef.current.filter((alert) => !alert.dismissed).length
        return count
    }, [etaNotificationAlerts])




    return (
        <View
            style={{}}
        >
            {RenderMemoizedJobDetails}
            {/* notification icon */}
            <View
                style={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    zIndex: 10,
                    flexDirection: 'row',
                    maxWidth: notificationsWidth,
                }}
            >
                {viewMode == DEVICE_MODE.DESKTOP &&
                    <RenderAudioToggle />
                }
                {viewMode == DEVICE_MODE.DESKTOP &&
                    <>
                        <RenderNotificationIcon />
                        {etaNotificationAlerts && nonDismissedEtaAlertsCount > 0 &&
                            <RenderEtaNotificationButton />
                        }
                    </>
                }
                {viewMode == DEVICE_MODE.MOBILE &&
                    <View style={{ flexDirection: 'column' }}>
                        <RenderNotificationIcon />
                        {etaNotificationAlerts && nonDismissedEtaAlertsCount > 0 &&
                            <RenderEtaNotificationButton />
                        }
                    </View>

                }
            </View>
            {showEtaNotifications && etaNotificationAlerts && nonDismissedEtaAlertsCount > 0 &&
                <Layout
                    style={{
                        position: 'absolute',
                        maxHeight: windowHeight * 0.5,
                        // minHeight: 0,
                        width: notificationsWidth,
                        top: 70,
                        right: 0,
                        zIndex: 10,
                        paddingLeft: 5,
                        paddingBottom: 5,
                        borderWidth: 1,
                        borderTopColor: 'red',
                        // borderColor: 'red',
                        boxShadow: '5px 5px 5px 5px rgba(255,0,0,0.5)',
                    }}>
                    <Button status="warning" appearance="ghost" style={{ marginBottom: 6 }} onPress={() => setShowEtaNotifications(false)}>Close</Button>
                    <ScrollView style={{ flex: 1 }}>
                        {etaNotificationAlerts.map((alert, index) => {
                            if (alert.dismissed) {
                                return null
                            }
                            return (
                                <Card
                                    status="warning"
                                    key={index}
                                    onPress={() => {
                                        onOpenJobDetails(alert.job)
                                    }}
                                    header={() => {
                                        return (
                                            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                                <Text category="s1">{alert.company.name}: {alertTypeToTitle(alert.type)} </Text>
                                                <Button
                                                    onPress={() => {
                                                        onDismissAlert(alert)
                                                    }}
                                                    size='tiny'
                                                    style={{ width: 20, height: 20 }}
                                                    appearance='ghost'
                                                    status='danger'
                                                >
                                                    <Icon name='close-outline' fill='red' width={20} height={20} />
                                                </Button>
                                            </View>
                                        )
                                    }}
                                >
                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                        <Button
                                            onPress={() => {
                                                onOpenJobDetails(alert.job)
                                            }}
                                            size='tiny'
                                            status='warning'
                                        >
                                            Open
                                        </Button>
                                        <Text>{alert.message}</Text>

                                    </View>
                                </Card>
                            )
                        })
                        }
                    </ScrollView>
                </Layout>
            }
            {showList &&
                <Layout
                    style={{
                        borderTopColor: 'yellow',
                        borderTopWidth: 1,
                        maxHeight: windowHeight * 0.8,
                        width: notificationsWidth,
                        boxShadow: '0px 0px 5px rgba(125,125,0,0.5)',
                        position: 'absolute',
                        top: 70,
                        right: 0,
                    }}
                >
                    <Button
                        onPress={clearNotifications}
                        appearance="ghost"
                        status="danger"
                        // style={{ backgroundColor: 'transparent', borderColor: 'transparent' }}
                        accessoryLeft={() => {
                            return (
                                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                    <Icon name='trash-2-outline' fill='red' style={{ width: 20, height: 20 }} />
                                    <Text style={{ marginLeft: 10 }}>Clear Notifications</Text>
                                </View>
                            )
                        }}
                    />
                    {viewMode == DEVICE_MODE.MOBILE &&
                        <RenderAudioToggle />
                    }
                    {/* notifications list */}
                    <List
                        style={{
                            with: '100%',
                            // flex: 1,
                            // maxHeight: "50%"
                        }}
                        data={notificationsListRef.current}
                        renderItem={({ item, index }: { item: DashboardNotification, index: number }) => {
                            return (
                                <ListItem
                                    key={index}
                                    title={item.title}
                                    description={item.message}
                                    onPress={
                                        () => {
                                            onPressNotification(item)
                                        }
                                    }
                                    accessoryLeft={() => {
                                        return (
                                            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                                {item.type == "JOB_UPDATE" &&
                                                    <Button
                                                        onPress={() => {
                                                            onOpenJobDetails(item.data as Job)
                                                        }}
                                                        status="primary"
                                                        style={{ width: 20, height: 20 }}
                                                    >
                                                        Open
                                                    </Button>
                                                }
                                                {/* <Icon name={item.icon} fill={item.color} style={{ width: 20, height: 20 }} /> */}
                                                <Text style={{ marginLeft: 10 }}>{item.time}</Text>
                                            </View>
                                        )
                                    }}
                                    accessoryRight={() => {
                                        return (
                                            <TouchableOpacity
                                                onPress={() => {
                                                    console.log("====delete notification====", item, "====")
                                                    let newList = notificationsListRef.current.filter((notification) => notification.id != item.id)
                                                    notificationsListRef.current = newList
                                                    setNotificationsList(newList)
                                                    setNotificationsCount(notificationsListRef.current.length)
                                                    forceUpdate()
                                                }}
                                                style={{ flexDirection: 'row', alignItems: 'center' }}>
                                                {/* Clear notification */}
                                                <Icon name='trash-2-outline' fill='red' style={{ width: 20, height: 20 }} />
                                            </TouchableOpacity>
                                        )
                                    }}
                                />
                            )
                        }}
                    />
                </Layout>
            }
        </View >

    )
}