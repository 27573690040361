import React, { useState } from 'react';
//@ts-ignore
import { View } from 'react-native';
//@ts-ignore
import { Modal, Text, Button, Icon, useTheme } from '@ui-kitten/components';
import { Layout } from '@ui-kitten/components';
import { CopyJobComponent } from '../copyJob/copyJob.component';




interface IJobOptionsMenu {
    showJobOptionsMemnu: boolean,
    setShowJobOptionsMenu: any,
    allowEdit: any,
    JobState: any,
    TransferJobComponent: any,
    setShowCancelJobModal: any
}


export const JobOptionsMenu = ({
    showJobOptionsMemnu,
    allowEdit,
    setShowJobOptionsMenu,
    JobState,
    TransferJobComponent,
    setShowCancelJobModal
}: IJobOptionsMenu) => {
    //@ts-ignore
    const theme = useTheme() as any
    return (
        <Modal
            visible={showJobOptionsMemnu}
            backdropStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.7)' }}
            style={{
                width: "80%",
                height: "80%",
                justifyContent: "center",
                alignItems: "center"
            }}
            onBackdropPress={() => setShowJobOptionsMenu(false)}>
            <Layout style={{ height: "100%", borderRadius: 20 }}>
                <View style={{ flex: 1, margin: 50 }}>
                    <Layout style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
                        <Text category='h2' status='success'>
                            Job Options
                        </Text>
                    </Layout>
                    <Layout style={{ flex: 8, borderRadius: 10 }}>
                        <TransferJobComponent
                            job={JobState}
                            onTransferJob={(r: any) => {
                            }}
                            canEdit={allowEdit()}
                        />
                        <Button
                            status="danger"
                            appearance="outline"
                            size='tiny'
                            onPress={() => { setShowCancelJobModal(true) }}
                            accessoryLeft={() => (<Icon name="alert-triangle-outline" fill={theme['text-basic-color'] as any} width={20} height={20} />)}
                        >
                            Cancel Job
                        </Button>
                        <CopyJobComponent
                            job={JobState}
                            onCopy={(r: any) => {
                            }}
                            canEdit={allowEdit()}
                        />
                    </Layout>
                    <Layout style={{ flex: 1 }}>
                        <Button onPress={() => setShowJobOptionsMenu(false)}>
                            DISMISS
                        </Button>
                    </Layout>
                </View>
            </Layout>
        </Modal>
    )
}
