import React from 'react';
//@ts-ignore
import { View } from 'react-native';
//@ts-ignore
import { Select, SelectItem, Text, IndexPath } from '@ui-kitten/components';
import { ROUTE_START_END_OPTION } from '../../models/Route.model';

interface RouteStartEndSelectorProps {
  startEndOption: ROUTE_START_END_OPTION;
  onSelectOption: (option: ROUTE_START_END_OPTION) => void;
  onSelectStartLocation: (index: number) => void;
  onSelectEndLocation: (index: number) => void;
  locations: { index: number; name: string }[];
  startLocationIndex: number | null;
  endLocationIndex: number | null;
}

export const RouteStartEndSelector: React.FC<RouteStartEndSelectorProps> = ({
  startEndOption,
  onSelectOption,
  onSelectStartLocation,
  onSelectEndLocation,
  locations,
  startLocationIndex,
  endLocationIndex,
}) => {
  return (
    <View>
      <Select
        label="Route Start/End Option"
        selectedIndex={new IndexPath(Object.values(ROUTE_START_END_OPTION).indexOf(startEndOption))}
        //@ts-ignore
        onSelect={(index: IndexPath | IndexPath[]) => onSelectOption(Object.values(ROUTE_START_END_OPTION)[index.row as number])}
        value={startEndOption.toUpperCase().replace(/[^a-zA-Z0-9]/g, ' ')}
      >
        <SelectItem title="Default" />
        <SelectItem title="Round Trip" />
        <SelectItem title="Start First, End Last" />
        <SelectItem title="Custom" />
      </Select>

      {startEndOption === ROUTE_START_END_OPTION.CUSTOM && (
        <>
          <Select
            label="Start Location"
            selectedIndex={startLocationIndex !== null ? new IndexPath(startLocationIndex) : undefined}
            //@ts-ignore
            onSelect={(index: IndexPath | IndexPath[]) => onSelectStartLocation(index.row as number)}
            value={startLocationIndex !== null ? locations[startLocationIndex].name : 'Select Start Location'}
          >
            {locations.map((location) => (
              <SelectItem key={location.index} title={location.name} />
            ))}
          </Select>

          <Select
            label="End Location"
            selectedIndex={endLocationIndex !== null ? new IndexPath(endLocationIndex) : undefined}
            //@ts-ignore
            onSelect={(index: IndexPath | IndexPath[]) => onSelectEndLocation(index.row as number)}
            value={endLocationIndex !== null ? locations[endLocationIndex].name : 'Select End Location'}
          >
            {locations.map((location) => (
              <SelectItem key={location.index} title={location.name} />
            ))}
          </Select>
        </>
      )}
    </View>
  );
};