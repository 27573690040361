import React, { useState, useEffect, useReducer, useRef } from 'react';
//@ts-ignore
import { StyleSheet, Text as RNText, Platform, useWindowDimensions, Grid, Alert, View } from "react-native";
//@ts-ignore
import { Button, Icon, Layout, Card, Input, Modal, Text, CheckBox, Toggle, Select, SelectItem, IndexPath, Divider } from "@ui-kitten/components";

import * as MemberController from "../../functions/membership.controller";
import * as StorageController from "../../functions/storageController";
import * as UserController from "../../functions/user.controller";
import * as ClientController from "../../functions/client.controller";
import { Member, MemberPermissions } from '../../models/Member.model';
import { MemberDeviceInfoCard } from './memberDeviceInfoCard.component';
import { Client } from '../../models/Client.model';

interface MemberEditScreenProps {
    var_selectedMemberToEdit: Member;
    fn_onMemberEdit: any;
    fn_onDeleteMember: any;
    fn_onLockedMemeber: any;
    fn_onUnlockedMemeber: any;
}

export const MemberEditScreen = ({
    var_selectedMemberToEdit = new Member({}),
    fn_onMemberEdit,
    fn_onDeleteMember,
    fn_onLockedMemeber,
    fn_onUnlockedMemeber
}: MemberEditScreenProps) => {
    const [ignored, forceUpdate] = useReducer(x => x + 1, 0);
    const [member, setMember] = useState(null as Member | null)


    const [checkboxIsAdmin, setCheckboxIsAdmin] = useState(false);
    const [checkboxIsDriver, setCheckboxIsDriver] = useState(false);
    const [checkboxIsOperator, setCheckboxIsOperator] = useState(false);
    const [checkboxIsClient, setCheckboxIsClient] = useState(false);
    const [checkboxIsClientAdmin, setCheckboxIsClientAdmin] = useState(false);
    // const [permissions, setPermissions] = useState({});
    // const tempPermissions = useRef(new MemberPermissions({}))
    const [clients, setClients] = useState([] as Client[])
    const [selectedClientIndex, setSelectedClientIndex] = useState(new IndexPath(0) as any);
    const memberRef = useRef(member)

    const [unsavedChanges, setUnsavedChanges] = useState(false)
    const [resetModalVisible, setResetModalVisible] = useState(false);



    // let _permissionsArray = Object.keys(tempPermissions.current).map((key) => ({
    //     key,
    //     value: tempPermissions.current[key],
    // }));

    useEffect(
        () => {
            load()
        },
        [var_selectedMemberToEdit]
    )

    const load = async () => {
        let _member = var_selectedMemberToEdit
        if (!_member) return
        // console.log("Setting member", var_selectedMemberToEdit)
        onSetMember(_member, false)
        loadClients()
        // tempPermissions.current = new MemberPermissions(_member.permissions)
        setEditMemberParams()
    }

    const onSetMember = (member: Member, isChanges = true) => {
        const _member = new Member(member)
        memberRef.current = _member
        setUnsavedChanges(isChanges)
        // console.log("🚀============== ~ file: memberEdit.component.js:96 ~ onSetMember ~ _member🚀==============", _member)
        setMember(_member)
    }

    const onSetPermissions = (field: any, value: any) => {
        if (!memberRef.current) return
        let _permissions = memberRef.current?.permissions || new MemberPermissions({})
        //@ts-ignore
        _permissions[field] = value
        const p = new MemberPermissions(_permissions)
        memberRef.current.permissions = p
        onSetMember(memberRef.current)
    }

    const loadClients = async () => {
        const company = StorageController.getCurrentCompany()
        if (!company) return
        let _clients = await ClientController.getClientsByCompanyId(company._id)
        setClients(_clients)
        return _clients
    }


    const checkCanEditFields = () => {
        let canEdit = false

        // if admin
        if (StorageController.getAppState().selectedMembership?.is_admin) {
            canEdit = true
            if (member?.deleted) {
                canEdit = false
            }
        }
        return canEdit
    }


    //set edit member params
    const setEditMemberParams = async () => {
        try {
            setCheckboxIsAdmin(var_selectedMemberToEdit?.is_admin)
            setCheckboxIsOperator(var_selectedMemberToEdit?.is_operator)
            setCheckboxIsDriver(var_selectedMemberToEdit?.is_driver)
            setCheckboxIsClient(var_selectedMemberToEdit?.is_client)
            setCheckboxIsClientAdmin(var_selectedMemberToEdit?.is_client_admin)


            let _selectedClientIndex = new IndexPath(0)
            if (var_selectedMemberToEdit?.client_id) {
                let _client = clients.find((client) => client._id === var_selectedMemberToEdit?.client_id)
                // console.log("🚀 ~ file: memberEdit.component.js:131 ~ setEditMemberParams ~ _client:", _client)
                _selectedClientIndex = new IndexPath(clients.indexOf(_client as any))
                setSelectedClientIndex(_selectedClientIndex)
            } else {
                setSelectedClientIndex(null)
            }
        } catch (e) {
            console.log(e)
            setCheckboxIsAdmin(false)
            setCheckboxIsOperator(false)
            setCheckboxIsDriver(false)
        }
    }

    // update member 
    const updateMember = () => {
        let _member = new Member(member)
        fn_onMemberEdit(_member)
        Alert.alert(
            "Member Updated",
            "Member has been updated",
            [
                { text: "OK", onPress: () => console.log("OK Pressed") }
            ]
        );
    }

    const resetUserPassword = () => {
        // console.log("resetUserPassword", member)
        if (!member || !member.user) return
        UserController.resetPassword(member.user)
    }


    const MemberResetPasswordModal = () => {
        return (
            <Modal
                visible={resetModalVisible}
                backdropStyle={styles.backdrop}
                onBackdropPress={() => setResetModalVisible(false)}
            >
                <Card disabled={true}>
                    <Text category='h6'>Reset Password</Text>
                    <Text category='s1'>Are you sure you want to reset the password for {member?.name}?</Text>
                    <Layout style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Button

                            onPress={() => {
                                setResetModalVisible(false)
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            onPress={() => {
                                resetUserPassword()
                                setResetModalVisible(false)
                            }}
                        >
                            Reset Password
                        </Button>
                    </Layout>
                </Card>
            </Modal>

        )
    }

    const [deleteMemberModalVisible, setDeleteMemberModalVisible] = useState(false);

    const onDeleteMember = async () => {
        // member delete funcitonality
        if (!member) return
        await MemberController.tagMemeberDeleted(member._id)
        fn_onDeleteMember()
    }

    const DeleteMemberModal = () => {
        return (
            <Modal
                visible={deleteMemberModalVisible}
                backdropStyle={styles.backdrop}
                onBackdropPress={() => setDeleteMemberModalVisible(false)}
            >
                <Card disabled={true}>
                    <Text category='h6'>Delete Member</Text>
                    <Text category='s1'>Are you sure you want to delete {member?.name}?</Text>
                    <Layout style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Button
                            onPress={() => {
                                setDeleteMemberModalVisible(false)
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            onPress={() => {
                                onDeleteMember()
                                setDeleteMemberModalVisible(false)
                            }}
                        >
                            Delete
                        </Button>
                    </Layout>
                </Card>
            </Modal>
        )
    }

    const [showLockMemberModal, setShowLockMemberModal] = useState(false);
    const [showUnlockMemberModal, setShowUnlockMemberModal] = useState(false);

    const onLockedMember = async () => {
        if (!member) return
        // member lock funcitonality
        await MemberController.tagMemberLocked(member._id)
        fn_onLockedMemeber()
        forceUpdate()
    }

    const onUnlockedMember = async () => {
        if (!member) return
        await MemberController.tagMemberUnlocked(member._id)
        fn_onUnlockedMemeber()
        forceUpdate()
    }



    const LockMemberModal = () => {
        return (
            <Modal
                visible={showLockMemberModal}
                backdropStyle={styles.backdrop}
                onBackdropPress={() => setShowLockMemberModal(false)}
            >
                <Card disabled={true}>
                    <Text category='h6'>Lock Member</Text>
                    <Text category='s1'>Are you sure you want to lock {member?.name}?</Text>
                    <Layout style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Button
                            onPress={() => {
                                setShowLockMemberModal(false)
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            onPress={() => {
                                onLockedMember()
                                setShowLockMemberModal(false)
                            }}
                        >
                            Lock
                        </Button>
                    </Layout>
                </Card>
            </Modal>
        )
    }

    const UnlockMemberModal = () => {
        return (
            <Modal
                visible={showUnlockMemberModal}
                backdropStyle={styles.backdrop}
                onBackdropPress={() => setShowUnlockMemberModal(false)}
            >
                <Card disabled={true}>
                    <Text category='h6'>Unlock Member</Text>
                    <Text category='s1'>Are you sure you want to unlock {member?.name}?</Text>
                    <Layout style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Button

                            onPress={() => {
                                setShowUnlockMemberModal(false)
                            }}
                        >
                            Cancel
                        </Button>
                        <Button onPress={() => {
                            onUnlockedMember()
                            setShowUnlockMemberModal(false)
                        }}>
                            Unlock
                        </Button>
                    </Layout>
                </Card>
            </Modal>
        )
    }


    const renderClientsOption = (item: Client) => (
        <SelectItem key={item._id} title={item.name} />
    );

    const onSetMemberClientId = async (index: any) => {
        let _client = clients[index.row]
        let m = member as Member
        m.client_id = _client._id as string
        onSetMember(m)
        console.log(m)
        // if (member.client_id == undefined){
        // }
        // setMember({ ...member, client_id: _client._id })
        // console.log("onSetMemberClientId", member);
        setSelectedClientIndex(index)
    }


    if (!member) return (<></>)

    const onSave = () => {
        updateMember()
    }

    const onCancel = () => {
        load()
    }

    return (
        <Layout style={{ flex: 1, flexDirection: 'column', overflowY: 'auto' }}>
            <LockMemberModal />
            <UnlockMemberModal />
            <DeleteMemberModal />
            <MemberResetPasswordModal />
            <Layout style={{ flex: 10 }}>
                {member &&
                    <Layout>
                        <Layout style={{ flexDirection: 'row' }}>
                            <Layout style={{ flex: 1 }}>
                                {
                                    unsavedChanges ? <Text style={{ color: 'red' }}>Unsaved Changes</Text> : <Text></Text>
                                }
                            </Layout>
                            <Layout style={{ flex: 3 }}>
                            </Layout>
                            <Layout style={{ flex: 1 }}>
                                <Button
                                    disabled={!checkCanEditFields()}
                                    onPress={onSave}
                                    accessoryLeft={(props: any) => <Icon {...props} name="save-outline" />}
                                    status="success"
                                >
                                    Save
                                </Button>
                            </Layout>
                            <Layout style={{ flex: 1 }}>
                                <Button
                                    onPress={onCancel}
                                    accessoryLeft={(props: any) => <Icon {...props} name="close-outline" />}
                                    status="warning"
                                >
                                    Cancel
                                </Button>
                            </Layout>
                        </Layout>
                        <Layout style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between' }}>
                            <Card
                                disabled
                                style={{ flex: 1 }}
                                status='success'
                                header={() => <Text category='s1'>Member Details <Text category='p1' selectable> - ID: {member._id}</Text></Text>}
                            >
                                {/* input fields to edit member */}
                                <Text category='s1'>Name: {member.name}</Text>
                                <Text category='s1'>Email: {member.user?.email}</Text>
                                <Text category='s1'>Phone: {member.user?.phone}</Text>
                                <Text category='s1'>Username: {member.user?.username}</Text>
                                <Input
                                    label='Name'
                                    name='name'
                                    value={member.name}
                                    disabled={!checkCanEditFields()}
                                    onChange={(e: any) => onSetMember({ ...member, name: e.target.value } as Member)}
                                />
                                <Input
                                    label='Phone'
                                    name='phone'
                                    value={member.user?.phone}
                                    disabled={!checkCanEditFields()}
                                    onChange={(e: any) => onSetMember({ ...member, user: { ...member.user, phone: e.target.value } } as Member)}
                                />
                            </Card>

                            <Card style={{
                                flex: 1,
                            }}
                                disabled
                                header={() => <Text category='s1'>Device Info</Text>}
                                status='info'
                            >
                                <Layout style={{
                                    flex: 1,
                                    flexDirection: 'column',
                                    border: '1px solid',
                                    display: member.is_driver ? "block" : "none"
                                    // display: checkCanEditFields() ? "block" : "none"
                                }}>
                                    {/* If driver then a member.rate field is here */}
                                    {member.is_driver &&
                                        <View style={{ flexDirection: 'column' }}>
                                            <MemberDeviceInfoCard member={member} />
                                        </View>
                                    }

                                </Layout>
                            </Card>
                            {/*
                            //////////////////////////////////////////////////////////////////
                            //////////////////////////////////////////////////////////////////
                            //////// MEMBER ACTIONS
                            //////////////////////////////////////////////////////////////////
                            //////////////////////////////////////////////////////////////////
                        */}
                            <View style={{ flexDirection: 'column', flex: 1 }}>
                                <Card
                                    disabled
                                    style={{
                                        // flex: 1,
                                        display: checkCanEditFields() ? "block" : "none"
                                    }}
                                    header={() => <Text category='h6'>Member Admin</Text>}
                                    status='warning'
                                >
                                    {/* Reset password button */}
                                    <Button
                                        disabled={!checkCanEditFields()}
                                        onPress={() => {
                                            setDeleteMemberModalVisible(true)
                                        }}
                                        appearance='outline'
                                        style={{ marginTop: 10, marginBottom: 10 }}
                                        status='danger'
                                    >
                                        Delete Member
                                    </Button>
                                    <Button
                                        disabled={!checkCanEditFields()}
                                        appearance='outline'
                                        status='info'
                                        onPress={() => {
                                            setResetModalVisible(true)
                                            console.log("reset password button pressed")
                                        }}
                                        style={{ marginTop: 10, marginBottom: 10 }}
                                    >
                                        Reset Password
                                    </Button>
                                    {/* Lock member button */}
                                    <>
                                        {!member.locked ?
                                            <Button
                                                status='warning'
                                                appearance='outline'
                                                disabled={!checkCanEditFields()}
                                                onPress={() => {
                                                    console.log("lock member button pressed")
                                                    setShowLockMemberModal(true)
                                                }}
                                                style={{ marginTop: 10, marginBottom: 10 }}
                                            >
                                                Lock Member
                                            </Button>
                                            :
                                            <Button
                                                appearance='outline'
                                                status='warning'
                                                disabled={!checkCanEditFields()}
                                                onPress={() => {
                                                    setShowUnlockMemberModal(true)
                                                    console.log("unlock member button pressed")
                                                }}
                                                style={{ marginTop: 10, marginBottom: 10 }}
                                            >
                                                Unlock Member
                                            </Button>
                                        }
                                    </>
                                </Card>
                                <Card style={{
                                    // flex: 1,
                                }}
                                    disabled
                                    header={() => <Text category='s1'>Member Info</Text>}
                                    status='info'
                                >
                                    <Layout style={{ flex: 1, flexDirection: 'column', justifyContent: 'space-between' }}>
                                        {member.is_driver && checkCanEditFields() &&
                                            <View style={{ flexDirection: 'column' }}>
                                                <Input
                                                    label='Rate $'
                                                    name='rate'
                                                    // make value prepended with $ sign
                                                    value={member.rate}
                                                    disabled={!checkCanEditFields()}
                                                    onChange={(e: any) => {
                                                        // check is a number    
                                                        if (isNaN(e.target.value)) {
                                                            return
                                                        }
                                                        onSetMember({ ...member, rate: e.target.value } as Member)
                                                    }}
                                                />
                                            </View>
                                        }
                                    </Layout>
                                </Card>
                            </View>
                        </Layout>
                        <Card
                            header={() => <Text category='h6'>Member Permissions</Text>}
                            status='primary'
                            disabled
                            style={{ display: checkCanEditFields() ? "block" : "none" }}>
                            <Layout style={{ flexDirection: 'row' }}>
                                <Layout style={{ flex: 1, flexDirection: 'column' }}>

                                    <CheckBox
                                        disabled={!checkCanEditFields()}
                                        checked={checkboxIsAdmin}
                                        onChange={(state: boolean) => {
                                            setCheckboxIsAdmin(state)
                                            onSetMember({ ...member, is_admin: state } as Member)
                                        }}>
                                        Admin
                                    </CheckBox>
                                    <CheckBox
                                        disabled={!checkCanEditFields()}
                                        checked={checkboxIsDriver}
                                        onChange={(state: boolean) => {
                                            setCheckboxIsDriver(state)
                                            onSetMember({ ...member, is_driver: state } as Member)
                                        }}>
                                        Driver
                                    </CheckBox>
                                    <CheckBox
                                        disabled={!checkCanEditFields()}
                                        checked={checkboxIsOperator}
                                        onChange={(state: boolean) => {
                                            setCheckboxIsOperator(state)
                                            onSetMember({ ...member, is_operator: state } as Member)

                                        }}>
                                        Operator
                                    </CheckBox>
                                    <CheckBox
                                        disabled={!checkCanEditFields()}
                                        checked={checkboxIsClient}
                                        onChange={(state: boolean) => {
                                            setCheckboxIsClient(state)
                                            onSetMember({ ...member, is_client: state } as Member)
                                        }}>
                                        Client
                                    </CheckBox>
                                </Layout>
                                <Layout style={{ flex: 1, flexDirection: 'column' }}>
                                    {member.is_client &&
                                        <>
                                            {/* Is client_admin */}
                                            <CheckBox
                                                disabled={!checkCanEditFields()}
                                                checked={checkboxIsClientAdmin}
                                                onChange={(state: boolean) => {
                                                    setCheckboxIsClientAdmin(state)
                                                    onSetMember({ ...member, is_client_admin: state } as Member)
                                                }}>
                                                Client Admin
                                            </CheckBox>
                                            {/* Client select */}
                                            <Select
                                                selectedIndex={selectedClientIndex}
                                                label={clients[selectedClientIndex?.row]?.name || "Select Client"}
                                                placeholder='Select Client'
                                                value={clients[selectedClientIndex?.row]?.name || "Select Client"}
                                                disabled={!checkCanEditFields()}
                                                onSelect={(index: any) => {
                                                    onSetMemberClientId(index)
                                                    onSetMember({ ...member, client_id: clients[index.row]._id } as Member)
                                                }}
                                            >
                                                {clients ? clients.map(renderClientsOption) : [].map(renderClientsOption)}
                                            </Select>
                                        </>
                                    }
                                </Layout>
                            </Layout>
                        </Card>
                        <Card
                            header={() => <Text category='h6'>Permissions</Text>}
                            status='info'
                            disabled
                            style={{}}>
                            <View style={{ margin: -15, flexDirection: 'column', gap: 10 }}>
                                <View style={{ flexDirection: 'row', gap: 5 }}>
                                    <View style={{ flex: 1 }}>
                                        <PermissionsCheckbox
                                            status='success'
                                            member={member}
                                            title='App Permissions'
                                            checkCanEditFields={checkCanEditFields}
                                            onSetPermissions={onSetPermissions}
                                            fieldsToShow={[
                                                // "driver_app_view_callout",
                                                "driver_app_view_bill_back_callout",
                                                "driver_app_view_bill_back_items_cost",
                                                "driver_app_create_job",
                                            ] as any}
                                        />
                                    </View>
                                    <View style={{ flex: 1 }}>
                                        <PermissionsCheckbox
                                            status='info'
                                            member={member}
                                            title='App Payment Permissions'
                                            checkCanEditFields={checkCanEditFields}
                                            onSetPermissions={onSetPermissions}
                                            fieldsToShow={[
                                                "driver_app_payment_enable_square"
                                            ] as any}
                                        />
                                    </View>
                                </View>
                                <View style={{ flexDirection: 'row', gap: 5 }}>
                                    <View style={{ flex: 1 }}>
                                        {/* <PermissionsCheckbox
                                            status='warning'
                                            member={member}
                                            title='Job Permissions'
                                            checkCanEditFields={checkCanEditFields}
                                            onSetPermissions={onSetPermissions}
                                            fieldsToShow={[
                                                "job_assign",
                                                "job_reassign",
                                                "job_create",
                                                "job_edit",
                                                "job_delete",
                                                "job_set_pending",
                                                "job_set_complete",
                                                "job_view_own",
                                                "job_view_all",
                                                "job_view_assigned",
                                                "job_view_pending",
                                            ]}
                                        /> */}
                                    </View>
                                    <View style={{ flex: 1 }}>
                                        {/* <PermissionsCheckbox
                                            status='success'
                                            member={member}
                                            title='Member Permissions'
                                            checkCanEditFields={checkCanEditFields}
                                            onSetPermissions={onSetPermissions}
                                            fieldsToShow={[
                                                "member_create",
                                                "member_edit",
                                                "member_delete",
                                                "member_view",
                                                "member_view_other",
                                                "member_view_self",
                                                "member_view_all",
                                            ]}
                                        /> */}
                                    </View>
                                </View>
                                <View style={{ flexDirection: 'row', gap: 5 }}>
                                    <View style={{ flex: 1 }}>
                                        {/* <PermissionsCheckbox
                                            status='primary'
                                            member={member}
                                            title='Invoice Permissions'
                                            checkCanEditFields={checkCanEditFields}
                                            onSetPermissions={onSetPermissions}
                                            fieldsToShow={[
                                                "invoice_view",
                                                "invoice_edit",
                                                "invoice_create",
                                                "invoice_delete",
                                                "invoice_view_all",
                                                "invoice_view",
                                                "invoice_edit",
                                                "invoice_create",
                                                "invoice_delete",
                                            ]}
                                        /> */}
                                    </View>
                                    <View style={{ flex: 1 }}>
                                        {/* <PermissionsCheckbox
                                            status='warning'
                                            member={member}
                                            title='Service Permissions'
                                            checkCanEditFields={checkCanEditFields}
                                            onSetPermissions={onSetPermissions}
                                            fieldsToShow={[
                                                "service_create",
                                                "service_edit",
                                                "service_delete",
                                                "service_field_create",
                                                "service_field_edit",
                                                "service_field_delete",
                                            ]}
                                        /> */}
                                    </View>
                                </View>

                                {/* <PermissionsCheckbox
                                    member={member}
                                    title='Admin Permissions'
                                    checkCanEditFields={checkCanEditFields}
                                    onSetPermissions={onSetPermissions}
                                    fieldsToShow={[
                                        "company_edit",
                                    ]}
                                /> */}

                            </View>
                        </Card>




                        {/* submit button */}
                        {/* <Layout style={{ display: checkCanEditFields() ? "block" : "none" }}>
                            <Button
                                onPress={() => { updateMember() }}
                                disabled={!checkCanEditFields()}
                                status='success'
                                style={{ marginTop: 10 }}>
                                Update
                            </Button>
                        </Layout> */}
                    </Layout>
                }
            </Layout>
        </Layout >
    )

}


interface PermissionsCheckboxProps {
    member: Member;
    checkCanEditFields: any;
    onSetPermissions: any;
    fieldsToShow?: any[];
    title?: string;
    status?: string;
}

const PermissionsCheckbox = ({
    member,
    checkCanEditFields,
    onSetPermissions,
    fieldsToShow = [],
    title = "Permissions",
    status = 'info'
}: PermissionsCheckboxProps) => {

    // for each field to show, if the member.permissions does not have the field then add it to the member permission
    fieldsToShow.forEach((field: any) => {
        //@ts-ignore
        if (!member.permissions[field]) {
            //@ts-ignore
            member.permissions[field] = false
        }
    })

    return (
        <Card
            status={status}
            disabled={true}
            // style={{ flex: 1 }}
            header={() => <Text category='s1'>{title}</Text>}
        >
            {/* <ScrollView> */}
            <Layout style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
                {Object.keys(member.permissions).map((key, index) => {
                    // if fields to show is not in the members permissions then add the field
                    // if (!member.permissions[key]) {
                    //     member.permissions[key] = false
                    // }
                    return (
                        <React.Fragment key={`${key}_${index}`}>
                            {
                                fieldsToShow.includes(key) &&
                                <Layout key={index} style={{ width: '50%' }}>
                                    <CheckBox
                                        disabled={!checkCanEditFields()}
                                        //@ts-ignore
                                        checked={member.permissions[key] || false}
                                        onChange={(checked: boolean) => {
                                            onSetPermissions(key, checked);
                                        }}
                                    >
                                        {key.replace(/_/g, ' ').split(' ').map((word) => word[0].toUpperCase() + word.slice(1)).join(' ')}
                                    </CheckBox>
                                </Layout>
                            }
                        </React.Fragment>
                    )
                })}
            </Layout>
            {/* </ScrollView> */}
        </Card>
    )
}


const styles = StyleSheet.create({
    backdrop: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },

});
