import React, { useState, useEffect, useRef, useReducer, useCallback, useMemo, createContext, useContext } from 'react';
//@ts-ignore
import { useWindowDimensions, StyleSheet, View, TouchableOpacity, ScrollView } from "react-native";
import { SafeAreaView } from 'react-native-safe-area-context';
import * as XLSX from 'xlsx';
import DataGrid from 'react-data-grid';
//@ts-ignore
import { Divider, Icon, Layout, Text, TopNavigation, TopNavigationAction, Card, Button, Input, ButtonGroup, CheckBox, Modal, RangeDatepicker, Datepicker, useTheme, IndexPath, Select, SelectItem, List, ListItem } from '@ui-kitten/components';
import ErrorBoundary from '../ErrorBoundary.component';
import * as JobsController from '../../functions/job.controller'
import * as MembershipsController from '../../functions/membership.controller'
import * as UpdateController from '../../functions/update.controller'
import * as ImageController from '../../functions/image.controller'
import * as ClientController from '../../functions/client.controller'
import * as StorageController from '../../functions/storageController'
import * as DocumentController from '../../functions/document.controller'
import { Document, DocumentCategory, SharePermissions, SharedWith } from '../../models/Document.model';
import { formatIsoDateTime, getDocumentTypeFromString } from '../../functions/utils';
import { Company } from '../../models/Company.model';

import RenderCreateNewCategoryModal from './NewCategoryModal.component';
import RenderCreateNewDocumentModal from './CreateNewDocumentModal.component';
import EditCategoryModal from './EditCategoryModal.component';
import RenderDocumentViewer from './DocumentViewer.component';
import { useAppStateChange, IAction } from '../../hooks/appStateChange.hook';



export const DocumentsContainerScreen = ({ navigation, route }: any) => {

    const [showCreateNewCategoryModal, setShowCreateNewCategoryModal] = useState(false)
    const [showCreateNewDocumentModal, setShowCreateNewDocumentModal] = useState(false)
    const [showEditDocumntCategoryModal, setShowEditDocumntCategoryModal] = useState(false)
    const [showEditDocumentModal, setShowEditDocumentModal] = useState(false)

    const [categories, setCategories] = useState<DocumentCategory[]>([])
    const [documents, setDocuments] = useState<Document[]>([])

    const [selectedCategory, setSelectedCategory] = useState<DocumentCategory | null>(null)
    const [selectedDocument, setSelectedDocument] = useState<Document | null>(null)

    const [showConfirmDeleteDocumentModal, setShowConfirmDeleteDocumentModal] = useState(false)
    const [showConfirmDeleteCategoryModal, setShowConfirmDeleteCategoryModal] = useState(false)

    const [selectedCompany, setSelectedCompany] = useState<Company | null>(null)

    //@ts-ignore
    const theme = useTheme()


    useEffect(() => {
        loadDocumentCategories()
    }, [])

    const handleCompanyChangeEvent = useCallback((action: IAction) => {
            setSelectedCompany(new Company(action.data))
            setCategories([])
            setDocuments([])
            setSelectedCategory(null)
            setSelectedDocument(null)
            setTimeout(async () => {
                loadDocumentCategories()
            }, 100);
    }, [selectedCompany]);

    const { dispatchEventStateChange } = useAppStateChange({
        onUpdatedSelectedCompany: handleCompanyChangeEvent
    })

    const loadDocumentCategories = async () => {
        try {
            const result = await DocumentController.getDocumentCategoriesByCompanyId(StorageController.getCurrentCompany()._id);
            setCategories(result)
        } catch (e) {
            console.log("🚀============== ~ file: documentsContainerScreen.tsx:87 ~ loadDocumentCategories ~ e", e)
        }
    }


    const onCreateNewDocumentCategory = async (category: DocumentCategory) => {
        try {
            const result = await DocumentController.createDocumentCategory(category);
            setCategories([...categories, result])
            setShowCreateNewCategoryModal(false)
        } catch (e) {
            console.log("🚀============== ~ file: documentsContainerScreen.tsx:87 ~ onCreateNewDocumentCategory ~ e", e)
        }
    }

    const onCreateNewDocument = async (document: Document) => {
        try {
            // console.log("🚀============== ~ file: documentsContainerScreen.tsx:59 ~ onCreateNewDocument ~ document🚀==============", document)
            onSetSelectedCategory(selectedCategory as DocumentCategory)
            // setDocuments([...documents, result])
            setShowCreateNewDocumentModal(false)
        } catch (e) {
            console.log("🚀============== ~ file: documentsContainerScreen.tsx:87 ~ onCreateNewDocumentCategory ~ e", e)
        }
    }


    const onSetSelectedCategory = async (category: DocumentCategory) => {
        setSelectedCategory(category)
        try {
            // console.log("🚀============== ~ file: documentsContainerScreen.tsx:78 ~ onSetSelectedCategory ~ category🚀==============", category)
            const company = StorageController.getCurrentCompany()
            const company_id = category.company_id
            const result = await DocumentController.getDocumentsByCategoryId(company_id, category._id);
            // console.log("🚀============== ~ file: documentsContainerScreen.tsx:74 ~ onSetSelectedCategory ~ result🚀==============", result)
            setDocuments(result)
            setSelectedDocument(null)
        } catch (e) {
            console.log("🚀============== ~ file: documentsContainerScreen.tsx:87 ~ onCreateNewDocumentCategory ~ e", e)
        }
    }

    const handleDeleteDocument = async (document: Document) => {
        setShowConfirmDeleteDocumentModal(true)
    }

    const deleteDocument = async (document: Document) => {
        try {
            const result = await DocumentController.deleteDocument(document._id);
            setDocuments(documents.filter(d => d._id !== document._id))
            setSelectedDocument(null)
            onSetSelectedCategory(selectedCategory as DocumentCategory)
        } catch (e) {
            console.log("🚀============== ~ file: documentsContainerScreen.tsx:87 ~ onCreateNewDocumentCategory ~ e", e)
        }
    }



    const RenderConfirmDeleteDocumentModal = ({ document, handleDeleteDocument }: { document: Document, handleDeleteDocument: any }) => {
        return (
            <Modal
                visible={showConfirmDeleteDocumentModal}
                backdropStyle={styles.backdrop}
                onBackdropPress={() => setShowConfirmDeleteDocumentModal(false)}>
                <Card disabled={true}
                    status='danger'
                    header={() => <Text>Delete Document</Text>}
                >
                    <View style={{ flexDirection: 'column', gap: 40 }}>

                        <Text>Are you sure you want to delete this document?</Text>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                            <Button
                                status='danger'
                                onPress={() => deleteDocument(document)}>Yes</Button>
                            <Button
                                status='warning'
                                appearance='ghost'
                                onPress={() => setShowConfirmDeleteDocumentModal(false)}>Cancel</Button>
                        </View>
                    </View>
                </Card>
            </Modal>
        )
    }

    const RenderConfirmDeleteCategoryModal = ({ category, handleDeleteCategory }: { category: DocumentCategory, handleDeleteCategory: any }) => {
        return (
            <Modal
                visible={showConfirmDeleteCategoryModal}
                backdropStyle={styles.backdrop}
                onBackdropPress={() => setShowConfirmDeleteCategoryModal(false)}>
                <Card disabled={true}
                    status='danger'
                    header={() => <Text>Delete Category</Text>}
                >
                    <View style={{ flexDirection: 'column', gap: 40 }}>

                        <Text>Are you sure you want to delete this category?</Text>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                            <Button
                                status='danger'
                                onPress={() => handleDeleteCategory(category)}>Yes</Button>
                            <Button
                                status='warning'
                                appearance='ghost'
                                onPress={() => setShowConfirmDeleteCategoryModal(false)}>Cancel</Button>
                        </View>
                    </View>
                </Card>
            </Modal>
        )
    }


    const onSetselectedDocument = async (document: Document) => {
        const updatedDoc = await DocumentController.getDocumentById(document._id);
        // console.log("🚀============== ~ file: documentsContainerScreen.tsx:101 ~ onSetselectedDocument ~ document🚀==============", document)
        setSelectedDocument(updatedDoc)
    }

    const handleOpenDocument = async (documentObj: Document) => {
        const url = await DocumentController.getSignedUrl(documentObj, {
            Expires: 60,
        });
        // console.log("🚀============== ~ file: documentsContainerScreen.tsx:107 ~ handleOpenDocument ~ url🚀==============", url)
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('target', '_blank');
        link.setAttribute('download', `${documentObj.title} - ${documentObj.file}`);
        document.body.appendChild(link);
        link.click();
        link.remove();
    }


    const renderDocumentTypeIcon = (document: Document) => {
        const type = getDocumentTypeFromString(document.content_type || '.' + document.file.split('.').pop() || '');
        switch (type) {
            case 'image':
                return <Icon name='image' fill='white' width={30} height={30} />;
            case 'pdf':
                return <Icon name='file-text-outline' fill='red' width={30} height={30} />;
            case 'video':
                return <Icon name='film-outline' fill='blue' width={30} height={30} />;
            case 'audio':
                return <Icon name='headphones-outline' fill='green' width={30} height={30} />;
            case 'excel':
                return <Icon name='file-text-outline' fill='green' width={30} height={30} />;
            case 'csv':
                return <Icon name='file-text-outline' fill='green' width={30} height={30} />;
            case 'word':
                return <Icon name='file-text-outline' fill='blue' width={30} height={30} />;
            default:
                return <Icon name='file-text' fill='black' width={30} height={30} />;
        }
    }

    const onShowEditCategoryModal = (category: DocumentCategory) => {
        setSelectedCategory(category)
        setShowEditDocumntCategoryModal(true)
    }

    const onShowDeleteCategoryModal = (category: DocumentCategory) => {
        setSelectedCategory(category)
        setShowConfirmDeleteCategoryModal(true)
    }

    const handleDeleteCategory = async (category: DocumentCategory) => {
        try {
            const result = await DocumentController.deleteDocumentCategory(category._id);
            console.log("🚀============== ~ file: documentsContainerScreen.tsx:227 ~ handleDeleteCategory ~ result🚀==============", result)
            loadDocumentCategories()
        } catch (err) {
            console.log("🚀============== ~ file: documentsContainerScreen.tsx:229 ~ handleDeleteCategory ~ err🚀==============", err)
        }
    }

    const onSaveEditedCategory = async (category: DocumentCategory) => {
        console.log("🚀============== ~ file: documentsContainerScreen.tsx:191 ~ onSaveEditedCategory ~ category🚀==============", category)
        try {
            loadDocumentCategories()
            setShowEditDocumntCategoryModal(false)
        } catch (e) {
            console.log("🚀============== ~ file: documentsContainerScreen.tsx:87 ~ onCreateNewDocumentCategory ~ e", e)
        }
    }

    const canEditCategory = (category: DocumentCategory) => {
        let allowEdit = false
        const isAdmin = StorageController.getAppState().selectedMembership?.is_admin
        const isCompanyOwner = StorageController.getCurrentCompany()._id == category.company_id

        const isClient = StorageController.getAppState().selectedMembership?.is_client
        const isClientAdmin = StorageController.getAppState().selectedMembership?.is_client_admin
        const client_id = StorageController.getAppState().selectedMembership?.client_id

        if (isAdmin && isCompanyOwner) {
            allowEdit = true
        }
        if (isClientAdmin && client_id === category.permissions.client_id && isCompanyOwner) {
            allowEdit = true
        }
        return allowEdit
    }

    const canCreateCategories = () => {
        const is_admin = StorageController.getAppState().selectedMembership?.is_admin
        const is_client = StorageController.getAppState().selectedMembership?.is_client
        const is_client_admin = StorageController.getAppState().selectedMembership?.is_client_admin
        if (is_admin || is_client_admin) {
            return true
        }
        return false
    }


    const createCategoryDescription = (category: DocumentCategory) => {
        let description = category.description
        const isClientCreated = category.permissions.is_client_created
        const isCompanyOwner = StorageController.getCurrentCompany()._id == category.company_id
        if (isCompanyOwner) {
            description += "\n - Owner"
        }
        const isShared = category.shared_with.length > 0
        if (isShared) {
            description += "\n - Shared"
        }
        if (isCompanyOwner && isShared) {
            // list all companies shared with
            description += " with "
            description += category.shared_with.map(s => {
                const company = StorageController.getAppState().companies?.find(c => c._id === s.company_id)
                return company?.name || ''
            }).join(', ')
        }
        if (!isCompanyOwner) {
            const company = StorageController.getAppState().companies?.find(c => c._id === category.company_id)
            description += "\n - Shared by " + company?.name || ''
        }
        if (isClientCreated && isCompanyOwner) {
            const company = StorageController.getCurrentCompany()
            const client = company.clients?.find(c => c._id === category.permissions.client_id)
            description += "\n - Client Created by " + client?.name || ''
        }

        return description
    }




    return (
        <ErrorBoundary>
            <RenderConfirmDeleteDocumentModal
                document={selectedDocument as Document}
                handleDeleteDocument={handleDeleteDocument}
            />
            <RenderConfirmDeleteCategoryModal
                category={selectedCategory as DocumentCategory}
                handleDeleteCategory={handleDeleteCategory}
            />
            <RenderCreateNewCategoryModal
                showCreateNewCategoryModal={showCreateNewCategoryModal}
                setShowCreateNewCategoryModal={setShowCreateNewCategoryModal}
                onCreateNewDocumentCategory={onCreateNewDocumentCategory}
            />
            <RenderCreateNewDocumentModal
                showCreateNewDocumentModal={showCreateNewDocumentModal}
                setShowCreateNewDocumentModal={setShowCreateNewDocumentModal}
                onCreateNewDocument={onCreateNewDocument}
                selectedCategory={selectedCategory}
            />
            <EditCategoryModal
                showModal={showEditDocumntCategoryModal}
                setShowModal={setShowEditDocumntCategoryModal}
                selectedCategory={selectedCategory}
                onSave={onSaveEditedCategory}
            />
            <Layout style={{ flexDirection: 'row', flex: 1 }}>
                <Layout style={{ flex: 1 }}>
                    {/* sidebar */}

                    <Button
                        status='basic'
                        appearance='outline'
                        disabled={!canCreateCategories()}
                        accessoryLeft={() => <Icon name='plus-outline' fill='white' width={20} height={20} />}
                        onPress={() => setShowCreateNewCategoryModal(true)}>
                        Create New Category
                    </Button>
                    <List
                        data={categories}
                        ItemSeparatorComponent={Divider}
                        renderItem={({ item }: { item: DocumentCategory }) => (
                            <Card
                                key={item._id}
                                //@ts-ignore
                                style={{ backgroundColor: selectedCategory?._id === item._id ? theme['color-success-900'] : theme['color-basic-800'] }}
                                status='primary'
                                header={() =>
                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                        <Text category='s1'>{item.title}</Text>
                                        {canEditCategory(item) &&
                                            <View style={{ flexDirection: 'row', gap: 30 }}>
                                                <TouchableOpacity
                                                    appearance='ghost'
                                                    status='info'
                                                    onPress={() => onShowEditCategoryModal(item)}
                                                >
                                                    <Icon name='edit-2-outline' fill='white' width={20} height={20} />
                                                </TouchableOpacity>
                                                <TouchableOpacity
                                                    size='small'
                                                    appearance='ghost'
                                                    status='danger'
                                                    onPress={() => onShowDeleteCategoryModal(item)}
                                                >
                                                    <Icon name='trash-2-outline' fill='red' width={20} height={20} />
                                                </TouchableOpacity>
                                            </View>
                                        }
                                    </View>
                                }
                                onPress={() => onSetSelectedCategory(item)}
                            >
                                <View style={{ flexDirection: 'column' }}>
                                    <Text category='p2'>{createCategoryDescription(item)}</Text>
                                </View>
                            </Card>
                        )}
                    />
                </Layout>
                <Layout style={{ flex: 5, borderLeftWidth: 1, borderWidthColor: 'black' }}>
                    {/* main content */}
                    <View style={{ flexDirection: 'row', flex: 1 }}>
                        <View style={{ flexDirection: 'column', flex: 1, gap: 3, borderRightWidth: 1, borderRightColor: 'black' }}>
                            {categories && categories.length && categories.length > 0 && selectedCategory ?
                                <Button
                                    appearance='outline'
                                    disabled={canEditCategory(selectedCategory as DocumentCategory) ? false : true}
                                    status='success'
                                    accessoryLeft={() => <Icon name='plus-outline' fill='white' width={20} height={20} />}
                                    onPress={() => setShowCreateNewDocumentModal(true)}>
                                    Create New Document
                                </Button>
                                : null
                            }
                            <List
                                data={documents}
                                ItemSeparatorComponent={Divider}
                                renderItem={({ item }: { item: Document }) => {
                                    let description = item.description
                                    let title = `${item.title} - ${item.file}`
                                    description += " - " + formatIsoDateTime(item.createdAt)
                                    return (
                                        <ListItem
                                            //@ts-ignore
                                            style={{ backgroundColor: selectedDocument?._id === item._id ? theme['color-success-900'] : theme['color-basic-800'] }}
                                            title={title}
                                            description={description}
                                            onPress={() => onSetselectedDocument(item)}
                                            accessoryLeft={() => renderDocumentTypeIcon(item)}
                                        />
                                    )
                                }}
                            />
                        </View>
                        <View style={{ flex: 3 }}>
                            {selectedDocument &&
                                <Card
                                    disabled
                                    status="primary"
                                    style={{ flex: 1 }}
                                    header={() =>
                                        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                            <Text category='s1'>{selectedDocument.title}</Text>
                                            <Text>{selectedDocument.file}</Text>
                                            <Text>Created: {formatIsoDateTime(selectedDocument.createdAt)}</Text>
                                        </View>
                                    }
                                >
                                    <RenderDocumentViewer
                                        canEditCategory={canEditCategory(selectedCategory as DocumentCategory)}
                                        handleDeleteDocument={handleDeleteDocument}
                                        document={selectedDocument}
                                        handleOpenDocument={handleOpenDocument}
                                    />
                                </Card>
                            }
                        </View>
                    </View>
                </Layout>
            </Layout>
        </ErrorBoundary >
    )
}








const styles = StyleSheet.create({
    backdrop: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
});

