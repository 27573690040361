const _log = (...args) => {
    const timestamp = new Date().toLocaleTimeString();
    const error = new Error();
    const stackTrace = error.stack;
    const stackLines = stackTrace.split('\n').slice(1, 5); // Limit to lines 1 through 4
    const lineNumber = stackLines[2]?.match(/:(\d+):\d+\)?$/)?.[1] || '';
    const functionName = stackLines[1]?.match(/at\s+(\S+)/)?.[1] || '';

    console.log.apply(
        [
        timestamp,
        ...args,
        functionName,
        stackLines]
        );
};

export default _log;