import React, { useState, useRef, useCallback, useEffect, useReducer, useMemo } from 'react';
//@ts-ignore
import { StyleSheet, View, useWindowDimensions } from 'react-native';
import {
    ApplicationProvider,
    Button,
    Icon,
    IconRegistry,
    Divider,
    Input,
    Select,
    SelectItem,
    useTheme,
    Layout,
    Text,
    List,
    ListItem,
    OverflowMenu,
    MenuItem,
    Card,
    Toggle,
    //@ts-ignore
    IndexPath,
} from "@ui-kitten/components";
import { SafeAreaView } from 'react-native-safe-area-context';
import { EvaIconsPack } from "@ui-kitten/eva-icons";
import { mapping, light, dark } from "@eva-design/eva";
import * as StorageController from '../../functions/storageController';
import * as GoogleController from '../../functions/google.controller';
import * as ClientController from '../../functions/client.controller';
import * as VendorProfileController from '../../functions/vendorProfile.controller';
import * as UpdateController from '../../functions/update.controller';
import * as CompanyController from '../../functions/company.controller';
import { GoogleMap, useJsApiLoader, Marker, InfoWindow, TransitLayer, DirectionsService, DirectionsRenderer, TrafficLayer, MarkerClusterer, Polygon, Polyline } from '@react-google-maps/api';
import "react-datepicker/dist/react-datepicker.css";
import { Job, JobTowingDetails, LineItem, TowingLocation, JobDirections, TowingLeg, JobLocation, Polyline as JobPolyLine, JobAddress } from '../../models/Job.model';
import { ScrollView } from 'react-native-gesture-handler';
import { Route, RouteDetails, ROUTE_STATUS, RouteLeg, RouteLocation, IGoogleRoute, IGoogleRouteLeg } from '../../models/Route.model';
import polyline from '@mapbox/polyline';
import { polylineStrokeColourSet } from '../../utils/routeUtils';

const mapLibs = ["places", "visualization"]
const mapPanelWidth = 200
const mapHeight = 500

enum ROUTE_POLYLINE_OPTIONS {
    OVERVIEW = 'overview',
    LEGS = 'legs'
}


interface RoutePlanningMapProps {
    route: Route,
    hoveredMarkerIndex: number | null,
    highlightedLegIndex: number | null,
    selectedMarkerIndex: number | null,
    setSelectedMarkerIndex: (index: number | null) => void,
    onMapClick: (event: any) => void,
    onDragMarker: (location: any, index: number) => void,
    setHoveredMarkerIndex: (index: number | null) => void,
    setHighlightedLegIndex: (index: number | null) => void,
    onClickMarker: (location: RouteLocation) => void,
}


export const RoutePlanningMap = ({
    route,
    highlightedLegIndex,
    hoveredMarkerIndex,
    onMapClick,
    onDragMarker,
    setHoveredMarkerIndex,
    setHighlightedLegIndex,
    onClickMarker,
}: RoutePlanningMapProps) => {

    const [map, setMap] = useState<any>(null);
    const { width, height } = useWindowDimensions();
    const [routePolylineOptions, setRoutePolylineOptions] = useState<ROUTE_POLYLINE_OPTIONS>(ROUTE_POLYLINE_OPTIONS.LEGS);

    useEffect(() => {
    }, [route])

    const mapContainerStyle = {
        width: "100%",
        height: `${height}px`
    };

    const mapRef = React.useRef(null);
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyDySIh3VZu-dtIFF9lzZC2RLJa_bLKtsvM",
        //@ts-ignore
        libraries: mapLibs
    });

    const companyLocation = StorageController.getCurrentCompany().settings?.location?.coords || { lat: 0, lng: 0, lon: 0 };
    const [center, setCenter] = useState(companyLocation);

    const memoizedRouteLegsPolylines = useMemo(() => {
        if (!route.details.legs) {
            return [];
        }

        return route.details.legs.map((leg: RouteLeg, index: number) => {
            let isHighlighted = false;
            if (hoveredMarkerIndex == null && highlightedLegIndex == null) {
                isHighlighted = true
            } else {
                if (hoveredMarkerIndex !== null) {
                    // Highlight leg before and after the hovered marker
                    if (hoveredMarkerIndex === 0) {
                        isHighlighted = index === 0;
                    } else {
                        isHighlighted = index === hoveredMarkerIndex - 1 || index === hoveredMarkerIndex;
                    }
                } else if (highlightedLegIndex !== null) {
                    // Highlight only the selected leg
                    isHighlighted = index === highlightedLegIndex;
                }
            }

            const strokeOpacity = isHighlighted ? 0.8 : 0.3;
            const strokeWeight = isHighlighted ? 6 : 4;
            if (!leg.polyline) {
                return null;
            }
            try {
                // check if ployline is a string or an array
                let decodedPolyline;
                if (typeof leg.polyline === 'string') {
                    decodedPolyline = polyline.decode(leg.polyline, 6).map((point: any) => ({ lat: point[0], lng: point[1] }));
                } else {
                    decodedPolyline = leg.polyline;
                }
                return {
                    path: decodedPolyline,
                    options: {
                        strokeColor: polylineStrokeColourSet(index),
                        strokeOpacity: strokeOpacity,
                        strokeWeight: strokeWeight,
                    }
                };
            } catch (error) {
                console.error("Error decoding polyline for leg", index, error);
                return null;
            }
        });
    }, [route, hoveredMarkerIndex, highlightedLegIndex, routePolylineOptions]);

    const memoizedRouteOverviewPolyline = useMemo(() => {
        // return null
        if (!route.details.overview_polyline) {
            return null;
        }
        let decodedPolyline;
        if (typeof route.details.overview_polyline === 'string') {
            decodedPolyline = polyline.decode(route.details.overview_polyline as string, 6).map((point: any) => ({ lat: point[0], lng: point[1] }));
        } else {
            decodedPolyline = route.details.overview_polyline;
        }
        const options = {
            path: decodedPolyline,
            options: {
                strokeColor: polylineStrokeColourSet(0),
                strokeOpacity: 0.8,
                strokeWeight: 6,
            }
        };
        return (
            <Polyline
                path={route.details.overview_polyline as any}
                options={options.options}
            />
        )
    }, [route, routePolylineOptions]);


    // all steps polyline
    const MemoizedAllStepsPolyline = useMemo(() => {

        if (!route?.details?.all_steps_polyline) {
            return [];
        }
        const line = route?.details?.all_steps_polyline?.map((p: any, index: number) => {
            const pLine = polyline.decode(p, 6).map((point: any) => ({ lat: point[0], lng: point[1] }));
            return {
                path: pLine,
                options: {
                    strokeColor: polylineStrokeColourSet(index),
                    strokeOpacity: 0.8,
                    strokeWeight: 4,
                }
            };
        });
        return line.map((p: any, index: number) => (
            <Polyline
                key={index}
                path={p.path}
                options={p.options}
            />
        ));
    }, [route]);





    const onHoverMarker = (index: number) => {
        setHoveredMarkerIndex(index);
    }


    const RenderPolyLineButtonOptions = () => {
        return (
            <View style={{ flexDirection: 'column', gap: 3 }}>
                <Button
                    onPress={() => {
                        setRoutePolylineOptions(ROUTE_POLYLINE_OPTIONS.OVERVIEW)
                    }}
                    status='success'>
                    <Text>Overview</Text>
                </Button>
                <Button
                    onPress={() => setRoutePolylineOptions(ROUTE_POLYLINE_OPTIONS.LEGS)}
                    status='success'>
                    <Text>Steps</Text>
                </Button>
            </View>
        )
    }

    const onHoverMarkerEvent = (routeLocation: RouteLocation | null) => {
        if (!routeLocation) {
            setShowMarkerInfoWindow(false);
            setHoveredMarkerIndex(null);
            return;
        }
        // get the leg that the marker is in
        const leg = route.details.legs.find((leg: RouteLeg) => leg.origin?.lat === routeLocation.location.lat && leg.origin.lng === routeLocation.location.lng);
        // get next leg if its not the last marker
        const nextLeg = route.details.legs[routeLocation.index + 1];
        setHoveredMarkerIndex(routeLocation.index);
        setSelectedMarkerRouteLocation(routeLocation);
        setShowMarkerInfoWindow(true);
    }

    const [showMarkerInfoWindow, setShowMarkerInfoWindow] = useState(false);
    const [selectedMarkerRouteLocation, setSelectedMarkerRouteLocation] = useState<RouteLocation | null>(null);

    const onClickMarkerEvent = (routeLocation: RouteLocation) => {
        if (!routeLocation) return;
        onClickMarker(routeLocation);
        setSelectedMarkerRouteLocation(routeLocation);
        setShowMarkerInfoWindow(true);
    }

    const RenderMarkerInfoWindow = ({ routeLocation }: { routeLocation: RouteLocation }) => {
        // const leg = route.details.legs.find((leg: RouteLeg) => leg.origin?.lat === routeLocation.location.lat && leg.origin.lng === routeLocation.location.lng);
        const leg = route.details.legs[routeLocation.index - 1];
        const nextLeg = route.details.legs[routeLocation.index];

        return (
            <InfoWindow
                position={routeLocation.location}
                options={{
                    pixelOffset: new google.maps.Size(0, -30),
                }}
                onCloseClick={() => setShowMarkerInfoWindow(false)}
            >
                <Layout>
                    <View style={{ flexDirection: 'column', padding: 10 }}>
                        <Text category="h6">Location {routeLocation.index + 1}: {routeLocation.name}</Text>
                        <Text category="s1">{routeLocation.address.formatted_address}</Text>
                        <Divider style={{ marginVertical: 5 }} />

                        <Text category="s1">Coordinates:</Text>
                        <Text>Lat: {routeLocation.location.lat.toFixed(6)}</Text>
                        <Text>Lng: {routeLocation.location.lng.toFixed(6)}</Text>

                        {leg && (
                            <>
                                <Divider style={{ marginVertical: 5 }} />
                                <Text category="s1">Previous Leg:</Text>
                                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                    <Text>Distance:</Text>
                                    <Text>{(leg.actual_distance_kms).toFixed(2)} km</Text>
                                </View>
                                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                    <Text>Duration:</Text>
                                    <Text>{Math.round(leg.actual_duration_seconds / 60)} min</Text>
                                </View>
                            </>
                        )}

                        {nextLeg && (
                            <>
                                <Divider style={{ marginVertical: 5 }} />
                                <Text category="s1">Next Leg:</Text>
                                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                    <Text>Distance:</Text>
                                    <Text>{(nextLeg.actual_distance_kms).toFixed(2)} km</Text>
                                </View>
                                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                    <Text>Duration:</Text>
                                    <Text>{Math.round(nextLeg.actual_duration_seconds / 60)} min</Text>
                                </View>
                            </>
                        )}
                    </View>
                </Layout>
            </InfoWindow>
        );
    };

    return (
        <View style={{ flex: 1 }}>
            {isLoaded && (
                <GoogleMap
                    options={{
                        disableDefaultUI: true,
                        zoomControl: true,
                        fullscreenControl: false,
                        streetViewControl: false,
                        mapTypeControl: true,
                        rotateControl: false,
                    }}
                    mapContainerStyle={mapContainerStyle}
                    center={center}
                    zoom={10}
                    onLoad={(map: any) => { mapRef.current = map; }}
                    onUnmount={() => { mapRef.current = null; }}
                    onClick={onMapClick}
                >
                    {showMarkerInfoWindow && (
                        <RenderMarkerInfoWindow routeLocation={selectedMarkerRouteLocation as RouteLocation} />
                    )}
                    {route.details.locations.map((routeLocation, index) => (
                        <Marker
                            key={index}
                            position={routeLocation.location}
                            draggable={true}
                            onDragEnd={(event: any) => {
                                const location = event?.latLng.toJSON();
                                onDragMarker(location, index);
                            }}
                            label={(index + 1).toString()}
                            onMouseOver={() => onHoverMarkerEvent(routeLocation)}
                            onMouseOut={() => onHoverMarkerEvent(null)}
                            onClick={() => onClickMarkerEvent(routeLocation)}
                        />
                    ))}
                    {/* {routePolylineOptions === ROUTE_POLYLINE_OPTIONS.OVERVIEW && memoizedRouteOverviewPolyline} */}
                    {routePolylineOptions === ROUTE_POLYLINE_OPTIONS.OVERVIEW && MemoizedAllStepsPolyline}
                    {routePolylineOptions === ROUTE_POLYLINE_OPTIONS.LEGS && memoizedRouteLegsPolylines.map((polyline: any, index: number) => (
                        <Polyline
                            key={index}
                            path={polyline.path}
                            options={polyline.options}
                        />
                    ))}
                    {/* {route.details.google_route && (
                            key={index}
                            path={polyline}
                            options={polyline.options}
                        />
                    ))}
                    {/* {route.details.google_route && (
                        <Polyline
                            path={GoogleController.decodePolyline(route.details.google_route?.polyline.encodedPolyline || '')}
                            options={{
                                strokeColor: "#0000FF",
                                strokeOpacity: 0.8,
                                strokeWeight: 4,
                            }}
                        />
                    )} */}
                </GoogleMap>
            )}
            {/* <RenderPolyLineButtonOptions /> */}
        </View>
    );
};