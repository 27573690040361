import React, { useState, useEffect, useRef, useReducer, useCallback, Profiler, useMemo, memo, useContext } from 'react';
//@ts-ignore
import { View, } from "react-native";
import {
    ApplicationProvider,
    Button,
    Text,
    Card,
    Input,
    //@ts-ignore
    IndexPath,
    Select,
    SelectItem,
} from "@ui-kitten/components";
import * as ImagePicker from 'expo-image-picker';
import { SafeAreaView } from 'react-native-safe-area-context';
import AssignJobList from '../assignJobList.component';
import AssignJobModal from '../modals/assignJobModal.component';
import { ServicesContainer } from '../services/servicesContainer.component';
import * as JobsController from '../../../functions/job.controller'
import * as MemberController from '../../../functions/membership.controller'
import * as ClientController from '../../../functions/client.controller'
import * as UpdateController from '../../../functions/update.controller'
import * as CompanyController from '../../../functions/company.controller'
import * as StorageController from '../../../functions/storageController'
import * as GoogleController from '../../../functions/google.controller'
import * as VendorProfileController from '../../../functions/vendorProfile.controller'
import "react-datepicker/dist/react-datepicker.css";
import ErrorBoundary from '../../ErrorBoundary.component';
import NotificationContext from '../../../context/notifications.context';
import { JobClientRate } from '../../../models/Job.model';


export const VendorDetailsCard = ({
    style = { flex: 1 },
    clientReferenceId = "",
    client_id = null as string | null,
    client_rate = null as JobClientRate | null,
    client_company_id = null as string | null,
    vendor_company_id = null as string | null,
    vendor_profile_id = null as string | null,
    is_client_created = false,
    is_vendor_created = false,
    jobCreatedBy = "",
    onSetBilling = (e: any) => { },
    onClientRateSelected = (e: any) => { console.log("onclient rate selected fn") },
    onClientRateCostSelected = (e: any) => { },
    onSetClientRateName = (e: any) => { },
    onSetClientRefrerenceId = (e: any) => { },
    onSetCustomerPaid = (e: any) => { },
    allowEdit = true,
}) => {

    const [vendorProfile, setVendorProfile] = useState(null as any)
    const [x, forceUpdate] = useReducer(x => x + 1, 0);
    const [vendorRateOptions, setVendorRateOptions] = useState([] as any)
    const [chosenVendorRate, setChosenVendorRate] = useState(false)
    const [existingClientRate, setExistingClientRate] = useState(client_rate) // is object with name, cost, billed_to
    const [vendorRateIndex, setVendorRateIndex] = useState(new IndexPath(0))
    const vendorClientProfileRef = useRef(null as any)
    const [vendorRatesDisplayValue, setVendorRatesDisplayValue] = useState("== Select Rate ===")
    // const vendorRatesDisplayValue = vendorRateOptions ? `${vendorRateOptions[vendorRateIndex?.row]?.name} : $${vendorRateOptions[vendorRateIndex?.row]?.cost}` : null;




    useEffect(() => {
        try {
            load()
        } catch (e) {
            // console.log("🚀 ~ file: ClientDetailsCard.component.js:94 ~ useEffect ~ e:", e)
        }
    }, [])

    const load = async () => {
        // get vendor profile from id
        if (!vendor_profile_id) return
        // const _vendorProfile = await VendorProfileController.getVendorProfile(vendor_profile_id)
        const _vendorProfile = getVendorFromStorageController()
        // console.log("🚀 ~ file: vendorDetailsCard.component.js:102 ~ load ~ _vendorProfile:", _vendorProfile)
        if (!_vendorProfile) return
        setVendorProfile(_vendorProfile)
        getClientProfileFromVendor(_vendorProfile)
        loadVendorRates(_vendorProfile)

    }

    const getVendorFromStorageController = () => {
        try {

            if (vendor_profile_id) {
                // get the vendor profiles from storage controller
                if (!StorageController.getAppState().vendorProfiles ||
                    StorageController.getAppState().vendorProfiles?.length == 0
                ) {
                    return
                }
                let vendorProfiles = StorageController.getAppState().vendorProfiles
                // find the vendor profile, matching the vendor_profile_id or linkedVendorAccountId to the job.vendor_company_id
                let vendorProfile = vendorProfiles.find((vendorProfile: any) => {
                    return vendorProfile?._id == vendor_profile_id || vendorProfile.linkedVendorAccountId == vendor_company_id
                })
                if (vendorProfile) {
                    return vendorProfile
                }
            }
            return null;
        } catch (e) {
            // console.log("🚀 ~ file: vendorDetailsCard.component.js:127 ~ getVendorFromStorageController ~ e:", e)
            return null
        }
    }


    const getClientProfileFromVendor = async (vProfile: any) => {
        if (!vProfile.linkedVendorAccountId) return
        const clientCompanyId = StorageController.getCurrentCompany()._id
        const _vendor_company_id = vProfile.linkedVendorAccountId
        const clientProfile = await ClientController.getClientByCompanyAndClientLinkingId(vProfile._id, clientCompanyId)
        // console.log("🚀 ~ file: createJob.component.js:272 ~ getClientProfileFromVendor ~ clientProfile:", clientProfile)
        if (!clientProfile) return
        vendorClientProfileRef.current = clientProfile
        return clientProfile
    }


    const loadVendorRates = async (vProfile: any) => {
        // console.log("🚀 ~ file: vendorDetailsCard.component.js:121 ~ loadVendorRates ~ vProfile:", vProfile)
        let rates = [] as any
        if (client_rate) {
            rates.push(client_rate)
        }
        if (!vProfile?.settings || !vProfile?.settings?.rates) {
            return
        }
        if (vendorClientProfileRef.current) {
            rates = [...rates, ...vendorClientProfileRef.current?.settings?.rates]
        }
        if (vProfile.settings.rates) {
            rates = [...rates, ...vProfile.settings.rates]
        }
        // console.log("🚀 ~ file: vendorDetailsCard.component.js:128 ~ loadVendorRates ~ existingClientRate:", existingClientRate)
        // console.log("🚀 ~ file: vendorDetailsCard.component.js:134 ~ loadVendorRates ~ vendorClientProfileRef.current?.settings?.rates:", vendorClientProfileRef.current?.settings?.rates)
        setVendorRateOptions(rates)
        if (client_rate) {
            try {
                const index = rates.findIndex((rate: any) => (rate.name == client_rate.name && rate.cost == client_rate.cost))
                setVendorRateIndex(new IndexPath(index))
                setChosenVendorRate(true)
                setVendorRatesDisplayValue(`${rates[index]?.name} : $${rates[index]?.cost}`)
            } catch (e) {
                // console.log("🚀 ~ file: vendorDetailsCard.component.js:141 ~ loadVendorRates ~ e:", e)
            }
        }
    }


    const onVendorRateSelected = (index: any) => {
        try {

            setChosenVendorRate(true)
            // set client rate
            let selectedRate = vendorRateOptions[index.row]
            selectedRate.billing = "bill_all_back"
            selectedRate.billed_to = "bill_all_back"
            onClientRateSelected(selectedRate)
            setVendorRateIndex(index)
            setVendorRatesDisplayValue(`${selectedRate.name} : $${selectedRate.cost}`)
            // console.log("🚀 ~ file: vendorDetailsCard.component.js:162 ~ onVendorRateSelected ~ selectedRate:", selectedRate)
        } catch (e) {
            // console.log("🚀 ~ file: vendorDetailsCard.component.js:164 ~ onVendorRateSelected ~ e:", e)
        }
    }

    // const getJobCreatedBy = () => {
    //     if (is_client_created && company_id == StorageController.getCurrentCompany()._id) {
    //         return "local_client"
    //     }
    //     else if (is_vendor_created) {
    //         return "vendor"
    //     }
    //     else {
    //         return "client"
    //     }
    // }







    // if (isOwnVendorJob) {
    //     return null
    // }
    // if (isLocalClientJob) {
    //     return null
    // }

    // if (isClientCreatedJob) {
    //     return null
    // }
    const renderClientRatesOption = (item: any) => (
        <SelectItem key={item.name} title={`${item.name} : $${item.cost}`} />
    );


    return (
        <View style={style}>
            <Card
                disabled
                header={() => <Text style={{ alignSelf: 'center' }}>Vendor Details</Text>}
                style={{ flex: 1 }}
                status='primary'
            >
                <Text category='h6'>Vendor Name: {vendorProfile?.name}</Text>
                {vendorProfile?.settings?.rates &&
                    <View style={{ flexDirection: 'row' }}>
                        {/* <Text category='h6'>Vendor Name: {vendorProfile.name}</Text> */}
                        <Select
                            size='small'
                            selectedIndex={vendorRateIndex}
                            label='Vendor Rate'
                            value={vendorRatesDisplayValue}
                            // value={chosenVendorRate ? existingClientRate ? `${existingClientRate.name} : $${existingClientRate.cost}` : vendorRatesDisplayValue : '--Select Rate --'}
                            placeholder='Select Vendor Rate'
                            onSelect={(index: any) => {
                                onVendorRateSelected(index)
                            }}
                        >
                            {vendorRateOptions ? vendorRateOptions.map(renderClientRatesOption) : [].map(renderClientRatesOption)}
                        </Select>
                    </View>
                }
                {/* Show custom rate if none exists */}
                {!vendorProfile?.settings?.rates &&
                    <View>

                        {/* <Text category='h6'>Vendor Name: {vendorProfile?.name}</Text> */}
                        <View style={{ flexDirection: 'row' }}>
                            {/* name and cost */}
                            <Input
                                size='small'
                                placeholder='Description'
                                label='Rate Description'
                                value={client_rate?.name}
                                onChangeText={(value: any) => {
                                    onSetClientRateName(value)
                                }}
                            />
                            <Input
                                size='small'
                                placeholder='Cost'
                                label='Rate Cost'
                                value={client_rate?.cost}
                                onChangeText={(value: any) => {
                                    onClientRateCostSelected(value)
                                }}
                            />
                        </View>
                    </View>
                }
                <Input
                    size='small'
                    placeholder='Client Ref'
                    label='Client Reference ID'
                    value={clientReferenceId}
                    onChangeText={(value: any) => {
                        onSetClientRefrerenceId(value)
                    }}
                />
                <View style={{ flexDirection: 'row' }}>
                    <Button
                        size='tiny'
                        status='success'
                        appearance={client_rate?.billed_to == "customer_cost" ? 'filled' : 'outline'}
                        onPress={() => {
                            onSetBilling("customer_cost")
                        }}>
                        Customer Cost
                    </Button>
                    <Button
                        size='tiny'
                        status='success'
                        appearance={client_rate?.billed_to == "bill_all_back" ? 'filled' : 'outline'}
                        onPress={() => {
                            onSetBilling("bill_all_back")
                        }}>
                        Bill All Back
                    </Button>
                </View>
                {/* if customer cost toggle buttons for if paid */}
                {client_rate?.billed_to == "customer_cost" &&
                    <View style={{ flexDirection: 'row', gap: 5 }}>
                        <Button
                            size='small'
                            status='success'
                            appearance={client_rate?.customer_paid ? 'filled' : 'outline'}
                            onPress={() => {
                                onSetCustomerPaid(true)
                            }}>
                            Paid
                        </Button>
                        <Button
                            size='small'
                            status='warning'
                            appearance={!client_rate?.customer_paid ? 'filled' : 'outline'}
                            onPress={() => {
                                onSetCustomerPaid(false)
                            }}>
                            Unpaid
                        </Button>
                    </View>
                }
            </Card>
        </View>
    )

}
