import React, { useState, useEffect, useRef, useReducer, useMemo, useCallback } from 'react';
//@ts-ignore
import { StyleSheet, View, ScrollView } from 'react-native';
// @ts-ignore
import {
    ApplicationProvider,
    Button,
    Icon,
    IconRegistry,
    Divider,
    Input,
    Select,
    SelectItem,
    useTheme,
    Layout,
    Text,
    List,
    ListItem,
    OverflowMenu,
    MenuItem,
    Card,
    Toggle,
    Radio,
    RadioGroup,
    Modal
} from '@ui-kitten/components';
import { GoogleMap, useJsApiLoader, Marker, InfoWindow, TransitLayer, DirectionsService, DirectionsRenderer, TrafficLayer, MarkerClusterer, Polygon, Polyline, OverlayView } from '@react-google-maps/api';
import * as UpdateController from '../../functions/update.controller';
import * as UserController from '../../functions/user.controller';
import * as StorageController from "../../functions/storageController"
import * as MessageController from "../../functions/message.controller"
import * as GoogleController from "../../functions/google.controller"
import * as JobsController from '../../functions/job.controller'
import ErrorBoundary from '../ErrorBoundary.component';
//@ts-ignore
import _ from 'lodash'
//@ts-ignore
import debounce from 'lodash.debounce';
import Toast from 'react-native-toast-message';
import { AddressSearch } from './searchAddress.component';
import DistanceTool from './distanceTool.component';
import { RoadsideJobInfo } from './RoadsideJobInfo.component';
import { TowingJobInfo } from './TowingJobInfo.component';
import { MemberDeviceInfoCard } from '../members/memberDeviceInfoCard.component';
import { Job, JobTowingDetails, TowingLeg, JOB_STATUS } from '../../models/Job.model';
import { Member } from '../../models/Member.model';
import { Company } from '../../models/Company.model'
import { darkMapStyles, lightMapStyles } from './mapStyles';

import { RenderDriverMarkers } from './map-components/DriverMarkers.component';
import { RenderRoadsideMarker, RenderTowingMarkers, JobClusterComponent } from './map-components/JobCluster.component';
import { RenderJobIcon } from './map-components/JobIcon.component';
import { RenderServiceAreaPolygons } from './map-components/ServiceAreaPolygon.component';
import { RenderDirectionsDriverToJobDirectionsPanel } from './map-components/DrivingDirectionsInfo.component';
import { RenderAddressSearch } from './map-components/MapAddressSearch.component';
import { RenderToggles } from './map-components/mapToggles.component';
import { useAppStateChange, IAction, STATE_ACTIONS } from '../../hooks/appStateChange.hook';
import { Route, ROUTE_LOCATION_TYPE, ROUTE_STATUS, RouteDetails, RouteLeg, RouteLocation } from '../../models/Route.model';
import polyline from '@mapbox/polyline';
import { polylineStrokeColourSet } from '../../utils/routeUtils';


const mapLibs = ["places", "visualization"]
const containerStyle = {
    width: '100%',
    height: '100%'
};

interface MapProps {
    jobs: Job[];
    routes: Route[];
    selectedRoute: Route | null;
    drivers: Member[];
    company: Company;
    zoomToJob: Job | null;
    zoomToDriver: Member | null;
    showDirections: boolean;
    driverToJobDirections: { driver: Member, job: Job } | null;
    onSelectJob: (job: Job) => void;
    onSelectRoute: (route: Route) => void;
    onClearDirections: () => void;
}



export default function Map({
    jobs = [] as Job[],
    routes = [] as Route[],
    selectedRoute = null as Route | null,
    drivers = [] as Member[],
    company = {} as Company,
    zoomToJob = null as Job | null,
    zoomToDriver = null as Member | null,
    showDirections = false,
    driverToJobDirections = null as { driver: Member, job: Job } | null,
    onSelectJob = (job: Job) => { },
    onSelectRoute = (route: Route) => { },
    onClearDirections = () => { },
}: MapProps) {


    const theme = useTheme() as any

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyDySIh3VZu-dtIFF9lzZC2RLJa_bLKtsvM",
        //@ts-ignore
        libraries: mapLibs  // Required for heatmap
    })

    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //////// STATES
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */

    //Map
    const [showDarkMap, setShowDarkMap] = useState(true)
    const [showTrafficFlow, setShowTrafficFlow] = useState(false)
    const [showServiceAreaPolygons, setShowServiceAreaPolygons] = React.useState(false as boolean);
    const [towingPolylines, setTowingPolylines] = useState([] as any[])
    const [showTowingPolyline, setShowTowingPolyline] = useState(false as boolean)
    const [showJobInfoWindow, setShowJobInfoWindow] = useState(false as boolean)
    const [showDriverInfoWindow, setShowDriverInfoWindow] = useState(false as boolean)
    const [showDirectionsPanel, setShowDirectionsPanel] = useState(false)
    const [showSearchMarker, setShowSearchMarker] = React.useState(false)
    const [searchMarkerPosition, setSearchMarkerPosition] = React.useState(null as any)
    const [showSearchMarkerInfoWindow, setShowSearchMarkerInfoWindow] = React.useState(false)
    const [mapDirections, setMapDirections] = React.useState(null as any)
    const [enableSelectPoint, setEnableSelectPoint] = React.useState(false)
    const [selectedPoint, setSelectedPoint] = React.useState(null as any)


    const [center, setCenter] = React.useState({ lat: -27.4705, lng: 153.0260 })
    const [showDitanceTool, setShowDistanceTool] = useState(false)
    const [distanceOriginMarker, setDistanceOriginMarker] = React.useState(null)
    const [distanceDestinationMarker, setDistanceDestinationMarker] = React.useState(null)
    const [showDistanceDirections, setShowDistanceDirections] = React.useState(false)
    const [distanceDirections, setDistanceDirections] = React.useState(null)

    //Drivers
    const [driversState, setDriversState] = useState(drivers)

    //Jobs





    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //////// REFS & MEMOs
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */

    //Map
    const mapRef = useRef(null)

    //Jobs
    const selectedJobMarkerRef = useRef(null as Job | null)

    //Drivers
    const driversRef = useRef(drivers)
    const assignedDriversIdListRef = useRef([] as string[]);
    const onRouteDriversIdListRef = useRef([] as string[]);
    const selectedDriverMarker = useRef(null as Member | null)
    const lastDirectionsDriver = useRef(null as any)
    const lastDirectionsJob = useRef(null as any)

    //Events
    const eventTokensRef = useRef([] as any[]);


    const memoizedJobs = useMemo(() => {
        const filteredJobs = jobs.filter((job: Job) => job.status != JOB_STATUS.COMPLETE && job.status != JOB_STATUS.PENDING && job.status != JOB_STATUS.CANCELLED)
        // return filteredJobs.map((job: Job) => { return new Job(job) })
        return jobs.map((job: Job) => { return new Job(job) })
    }, [jobs])

    const memoizedDrivers = useMemo(() => {
        // console.log("🚀============== ~ file: new-map.component.tsx:143 ~ memoizedDrivers ~ memoizedDrivers🚀==============")
        return driversState.filter((driver: Member) => driver.online)
    }, [driversState])


    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //////// USE EFFECTS
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */
    useEffect(() => {
        setCenter(StorageController.getCurrentCompany()?.settings?.location?.coords || { lat: -27.4705, lng: 153.0260 })
        onSetServiceAreasShown(!!StorageController.getAppState().showServiceAreas || false)
        return () => {
        }
    }, [])


    useEffect(() => {
        // console.log("🚀============== ~ file: new-map.component.tsx:149 ~ useEffect ~ drivers🚀==============", drivers)
        onSetDriversState(drivers, true)
        const { assignedDriverIds, onRouteDriverIds } = checkDriversJobStatus(jobs) as any;
        assignedDriversIdListRef.current = assignedDriverIds;
        onRouteDriversIdListRef.current = onRouteDriverIds;
    }, [drivers])

    useEffect(() => {
        // updateDriversStatus()
    }, [jobs])



    const updateDriversStatus = useCallback(() => {
        const jobs = StorageController.getCurrentCompany().jobs as Job[] // need to show all jobs in system
        const { assignedDriverIds, onRouteDriverIds } = checkDriversJobStatus(jobs) as any;
        assignedDriversIdListRef.current = assignedDriverIds;
        onRouteDriversIdListRef.current = onRouteDriverIds;
    }, [jobs, driversState])


    useEffect(() => {
        if (zoomToJob) {
            setCenter({ lat: zoomToJob.details.location.lat, lng: zoomToJob.details.location.lng })
        }
    }, [zoomToJob])

    useEffect(() => {
        if (zoomToDriver) {
            setCenter({ lat: zoomToDriver.details.location.lat, lng: zoomToDriver.details.location.lng })
        }
    }, [zoomToDriver])

    useEffect(() => {
        if (showDirections && driverToJobDirections?.driver && driverToJobDirections?.job) {
            setShowDirectionsPanel(true)
            getDirectionsForMap(driverToJobDirections?.driver, driverToJobDirections?.job)
        }
    }, [showDirections])



    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //////// EVENTS
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */




    const handleCompanyChangeEvent = (action: IAction) => {
        try {
            setShowDirectionsPanel(false);
            setMapDirections(null);
            setDistanceDirections(null)
            setShowDistanceTool(false)
            setShowDistanceDirections(false)

            //@ts-ignore
            updateDriversStatus()

            setShowSearchMarker(false)
            setSearchMarkerPosition(null);
            setShowJobInfoWindow(false);

            setTowingPolylines([])
            setShowTowingPolyline(false)

            setDistanceOriginMarker(null)
            setDistanceDestinationMarker(null)



            setShowSearchMarkerInfoWindow(false);
            // get company locaiton and set map center if exists
            if (StorageController.getCurrentCompany()?.settings?.location != null) {
                if (StorageController.getCurrentCompany()?.settings?.location.coords != null) {
                    const coords = StorageController.getCurrentCompany()?.settings?.location?.coords
                    setCenter({ lat: coords.lat, lng: coords.lng })
                }
            }
            // onSetServiceAreasShown(false)

        }
        catch (e) {
            ////console.log(e)
        }
    };



    const handleDriverLocationUpdateEvent = (action: IAction) => {
        // console.log("🚀============== ~ file: map.component.tsx:304 ~ handleDriverLocationUpdateEvent ~ action🚀==============", action)
        try {
            if (!StorageController.getAppState().companies) return;
            if (StorageController.getAppState().companies?.length == 0) return;
            const company = StorageController.getCurrentCompany();
            if (company?.members) {
                const update = {
                    name: action.data?.member?.name,
                    location: action.data?.location?.coords,
                    timestamp: action.data?.location?.timestamp,
                }
                onSetDriversState(company.members.filter((m) => m.is_driver === true), false)
            }
        } catch (e) {
            console.log("🚀============== ~ file: map.component.tsx:369 ~ handleDriverLocationUpdateEvent ~ e🚀==============", e)
        }
    }

    const { dispatchEventStateChange } = useAppStateChange({
        onUpdatedSelectedCompany: handleCompanyChangeEvent,
        onUpdatedDriverLocation: handleDriverLocationUpdateEvent
    })


    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //////// DRIVER STATE UPDATES
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */

    const debounceDriverDelay = 300;
    const lastCalledRef = useRef(Date.now());
    const debounceOptions = { leading: true, trailing: true };

    const debouncedDriverUpdate = useMemo(() => debounce((drivers: Member[]) => {
        const selectedMembership = StorageController.getAppState().selectedMembership;
        if (!selectedMembership) return;

        const filteredDrivers = drivers.filter(driver => canSeeDriverAndIsActive(selectedMembership, driver));
        driversRef.current = filteredDrivers;
        setDriversState(filteredDrivers);
        lastCalledRef.current = Date.now();
    }, debounceDriverDelay, debounceOptions), []);

    const onSetDriversState = useCallback((drivers: Member[], force = false) => {
        const now = Date.now();
        const selectedMembership = StorageController.getAppState().selectedMembership;
        if (!selectedMembership) return;

        if (force) {
            const filteredDrivers = drivers.filter(driver => canSeeDriverAndIsActive(selectedMembership, driver));
            driversRef.current = filteredDrivers;
            setDriversState(filteredDrivers);
        } else {
            debouncedDriverUpdate(drivers);
        }
    }, [driversState, jobs, company]);

    const canSeeDriverAndIsActive = (selectedMembership: Member, driver: Member) => {
        const canSeeDriver = checkCanSeeDriver(selectedMembership, driver);
        if (canSeeDriver) {
            const isDriverOffline = hideDriverIconTimeout(driver);
            const hasLocation = driver.details?.hasOwnProperty('location');
            return !isDriverOffline && hasLocation && driver.is_driver;
        }
        return false;
    };


    const hideDriverIconTimeout = (driver: Member) => {
        const now = new Date();
        const lastUpdated = new Date(driver.details?.location?.timestamp);
        const day = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
        const h = 3600 * 1000; // 1 hour in milliseconds
        const m = 60 * 1000; // 1 minute in milliseconds
        const s = 1000; // 1 second in milliseconds

        //@ts-ignore
        const diff = now - lastUpdated;

        if (diff > h * 12) {
            return true;
        } else {
            return false;
        }
    }



    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //////// Driver Functions
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */

    const checkCanSeeDriver = useCallback((member: Member, driver: Member, caller = null) => {
        try {

            if (!member || !driver) {
                return false;
            }
            // Admins, drivers, and operators can always see the driver
            if (member.is_admin || member.is_driver || member.is_operator) {
                return true;
            }
            // Clients can see the driver if the driver is assigned to a job for that client
            if (member.is_client) {
                return memoizedJobs?.some(job =>
                    job.member_id === driver._id &&
                    job.client_id === member.client_id &&
                    job.status === JOB_STATUS.ASSIGNED
                ) || false;
            }
        }
        catch (err) {
            return false
        }
    }, [memoizedJobs])


    const checkDriversJobStatus = (jobs: Job[]) => {
        try {
            let assignedDriverIds: string[] = [];
            let onRouteDriverIds: string[] = [];

            if (!jobs) return { assignedDriverIds, onRouteDriverIds };

            jobs.forEach(job => {
                const isAssigned = job.member_id && job.status === JOB_STATUS.ASSIGNED;
                const isOnRoute = job.driver_on_route && !job.driver_arrived;

                if (isAssigned && job.member_id) {
                    // Add to assigned drivers list if not already included
                    if (!assignedDriverIds.includes(job.member_id)) {
                        assignedDriverIds.push(job.member_id);
                    }

                    // Add to on-route drivers list if not already included
                    if (isOnRoute && !onRouteDriverIds.includes(job.member_id)) {
                        onRouteDriverIds.push(job.member_id);
                    }
                }
            });
            const result = { assignedDriverIds, onRouteDriverIds };
            return result;
        } catch (e) {
            console.log("Error in checkAssignedDrivers", e);
            return { assignedDriverIds: [], onRouteDriverIds: [] }; // Return empty arrays on error
        }
    };

    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //////// MAP FUCNTIONS
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */
    const onLoad = (map: any) => {

    }

    const onUnmount = (map: any) => {

    }

    const onMapZoomLevelChanged = () => {
    }

    const onPointSelected = (latLng: any) => {
        const lat = latLng.lat();
        const lng = latLng.lng();
        setSelectedPoint({ lat, lng } as any)
    }
    const onMapClicked = (e: any) => {
        if (enableSelectPoint) {
            onPointSelected(e.latLng)
        }
    }


    const onSetServiceAreasShown = (show: boolean) => {
        StorageController.appState.showServiceAreas = show
        setShowServiceAreaPolygons(show)
    }



    const getDirectionsForMap = (_selectedDriver: Member, _selectedJob: Job) => {
        if (_selectedJob == null || _selectedDriver == null) {
            return null
        }
        let _origin = null as any
        if (_selectedDriver.details?.location?.coords) {
            _origin = _selectedDriver.details?.location?.coords
        }
        //@ts-ignore
        else if (_selectedDriver.location?.coords) {
            //@ts-ignore
            _origin = _selectedDriver.location.coords
        }
        const _destination = _selectedJob.details?.location as any

        let origin = new google.maps.LatLng(_origin?.latitude, _origin?.longitude)
        let destination = new google.maps.LatLng(_destination?.lat, _destination?.lng)

        let directionService = new google.maps.DirectionsService()
        if (_.isEqual(lastDirectionsJob.current, _destination) && _.isEqual(lastDirectionsDriver.current, _origin)) {
            return null
        }
        let directionsRequest = {
            origin: origin,
            destination: destination,
            travelMode: google.maps.TravelMode.DRIVING
        }
        directionService.route(directionsRequest, (result, status) => {
            if (status == google.maps.DirectionsStatus.OK) {
                lastDirectionsDriver.current = _origin
                lastDirectionsJob.current = _destination
                setShowDirectionsPanel(true)
                setMapDirections(result as any)
            }
            else {
            }
        })
    }



    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //////// MEMOIZED RENDERS
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */

    const onJobMarkerClick = (job: Job) => {
        selectedJobMarkerRef.current = new Job(job) as Job | null
        setShowJobInfoWindow(true)
    }

    const onClickTowingMapMarker = (towing_details: JobTowingDetails) => {
        try {
            const polylines = towing_details.towing_legs.map((towingLeg: TowingLeg) => {
                return GoogleController.decodePolyline(towingLeg.directions.polyline)
            })
            setShowTowingPolyline(true)
            setTowingPolylines(polylines)
        } catch (e) {
        }
    }

    const onDriverMarkerClick = (driver: Member) => {
        if (!driver.user) {
            // find the driver in the company members
            const company = StorageController.getCurrentCompany() as Company | null
            const member = company?.members?.find(m => m?._id == driver._id) as Member
            if (member.user) {
                driver.user = member.user
            }
        }
        selectedDriverMarker.current = driver as Member | null
        setShowDriverInfoWindow(true)
    }

    const RenderMemoizedUnassignedJobClusterComponent = useMemo(() => {
        // console.log("🚀============== ~ file: new-map.component.tsx:447 ~ RenderMemoizedUnassugnedJobClusterComponent🚀==============", memoizedJobs)
        return (
            <JobClusterComponent
                status={JOB_STATUS.UNASSIGNED}
                jobs={memoizedJobs}
                onClick={onJobMarkerClick}
                onClickTowingMapMarker={onClickTowingMapMarker}
                renderJobIcon={RenderJobIcon}
            />
        )
    }, [memoizedJobs])

    const RenderMemoizedAssignedJobClusterComponent = useMemo(() => {
        // console.log("🚀============== ~ file: new-map.component.tsx:457 ~ RenderMemoizedAssignedJobClusterComponent ~ RenderMemoizedAssignedJobClusterComponent🚀==============")
        return (
            <JobClusterComponent
                status={JOB_STATUS.ASSIGNED}
                jobs={memoizedJobs}
                onClick={onJobMarkerClick}
                onClickTowingMapMarker={onClickTowingMapMarker}
                renderJobIcon={RenderJobIcon}
            />
        )
    }, [memoizedJobs])

    const RenderMemoizedRequestJobClusterComponent = useMemo(() => {
        // console.log("🚀============== ~ file: new-map.component.tsx:467 ~ RenderMemoizedRequestJobClusterComponent🚀==============")
        return (
            <JobClusterComponent
                status={JOB_STATUS.REQUEST}
                jobs={memoizedJobs}
                onClick={onJobMarkerClick}
                onClickTowingMapMarker={onClickTowingMapMarker}
                renderJobIcon={RenderJobIcon}
            />
        )
    }, [memoizedJobs])

    const RenderMemoizedCompleteJobClusterComponent = useMemo(() => {
        // console.log("🚀============== ~ file: new-map.component.tsx:477 ~ RenderMemoizedCompleteJobClusterComponent🚀==============")
        return (
            <JobClusterComponent
                status={JOB_STATUS.COMPLETE}
                jobs={memoizedJobs}
                onClick={onJobMarkerClick}
                onClickTowingMapMarker={onClickTowingMapMarker}
                renderJobIcon={RenderJobIcon}
            />
        )
    }, [memoizedJobs])


    const RenderMemoizedPendingJobClusterComponent = useMemo(() => {
        // console.log("🚀============== ~ file: new-map.component.tsx:477 ~ RenderMemoizedCompleteJobClusterComponent🚀==============")
        return (
            <JobClusterComponent
                status={JOB_STATUS.PENDING}
                jobs={memoizedJobs}
                onClick={onJobMarkerClick}
                onClickTowingMapMarker={onClickTowingMapMarker}
                renderJobIcon={RenderJobIcon}
            />
        )
    }, [memoizedJobs])

    const RenderMemoizedCancelledJobClusterComponent = useMemo(() => {
        // console.log("🚀============== ~ file: new-map.component.tsx:477 ~ RenderMemoizedCompleteJobClusterComponent🚀==============")
        return (
            <JobClusterComponent
                status={JOB_STATUS.CANCELLED}
                jobs={memoizedJobs}
                onClick={onJobMarkerClick}
                onClickTowingMapMarker={onClickTowingMapMarker}
                renderJobIcon={RenderJobIcon}
            />
        )
    }, [memoizedJobs])

    const RenderMemoizedTransferredOutJobClusterComponent = useMemo(() => {
        // console.log("🚀============== ~ file: new-map.component.tsx:477 ~ RenderMemoizedCompleteJobClusterComponent🚀==============")
        return (
            <JobClusterComponent
                status={JOB_STATUS.TRANSFERRED_OUT}
                jobs={memoizedJobs}
                onClick={onJobMarkerClick}
                onClickTowingMapMarker={onClickTowingMapMarker}
                renderJobIcon={RenderJobIcon}
            />
        )
    }, [memoizedJobs])

    const RenderMemoizedDriverMarkers = useMemo(() => {
        updateDriversStatus()
        return (
            <RenderDriverMarkers
                drivers={memoizedDrivers}
                onDriverMarkerClick={onDriverMarkerClick}
                assignedDriverIds={assignedDriversIdListRef.current}
                onRouteDriverIds={onRouteDriversIdListRef.current}
            />
        )
    }, [memoizedJobs, memoizedDrivers])

    const onEnableSelectPoint = () => {
        setEnableSelectPoint(true)
    }

    const onDisableSelectPoint = () => {
        setEnableSelectPoint(false)
    }
    const RenderMemoizedDistanceTool = useMemo(() => {
        return (
            <DistanceTool
                onEnableSelectPoint={onEnableSelectPoint}
                onDisableSelectPoint={onDisableSelectPoint}
                selectedPoint={selectedPoint}
                onOriginChange={setDistanceOriginMarker}
                onDestinationChange={setDistanceDestinationMarker}
                onSetMapDirections={(directions: any) => {
                    setDistanceDirections(directions);
                    setShowDistanceDirections(true);
                }}
                onClose={() => {
                    setShowDistanceTool(false);
                    setDistanceOriginMarker(null);
                    setDistanceDestinationMarker(null);
                    setDistanceDirections(null);
                    setShowDistanceDirections(false);
                }}
                onClear={() => {
                    ////console.log("Distance tool cleared")
                    setDistanceOriginMarker(null);
                    setDistanceDestinationMarker(null);
                    setDistanceDirections(null);
                    setShowDistanceDirections(false);
                }}
                isLoaded={isLoaded} />
        )
    }, [showDitanceTool, selectedPoint, distanceOriginMarker, distanceDestinationMarker, distanceDirections, showDistanceDirections])


    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //////// RENDER
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */

    const onRouteMarkerClick = (route: Route, job_id: string) => {
        console.log("🚀============== ~ file: map.component.tsx:714 ~ onRouteMarkerClick ~ route🚀==============", route)
        onSelectRoute(route)
        // get job from StorageController
        const company = StorageController.getCurrentCompany()
        const job = company.jobs.find((job: Job) => job._id == job_id)
        // select job id
        // onSelectJob(job as Job)
        // open job info window
        onJobMarkerClick(job as Job)
    }

    const RenderRouteIcon = (route: Route) => {
        return (
            <Icon name="route" fill={theme['color-primary-500']} style={{ width: 16, height: 16 }} />
        )
    }

    const RenderMemoizedRoutes = useMemo(() => {
        console.log("🚀============== ~ file: map.component.tsx:728 ~ RenderMemoizedRoutes ~ routes🚀==============", routes)
        return (
            <RenderRoutes
                routes={routes}
                onRouteMarkerClick={onRouteMarkerClick}
                selectedRoute={selectedRoute}
            />
        )
    }, [routes, selectedRoute])




    const RenderTowingPolylines = () => {
        return (
            <>
                {towingPolylines.map((polyline: any, index: number) => (
                    <Polyline
                        key={index}
                        path={polyline}
                        options={{
                            strokeColor: polylineStrokeColourSet(index),
                            strokeOpacity: 1,
                            strokeWeight: 4,
                        }}
                    />
                ))}
            </>
        );
    };





    if (!isLoaded) { return (<View><Text>Loading...</Text></View>) }

    return (
        <View style={{ flex: 1 }}>
            {showDitanceTool ?
                <View style={{ zIndex: 1000 }} >{RenderMemoizedDistanceTool}</View>
                : null}
            {showDirectionsPanel ?
                <View
                    style={{
                        position: "absolute",
                        top: "10px",
                        left: "10px",
                        zIndex: 1000,
                    }}
                >
                    <RenderDirectionsDriverToJobDirectionsPanel
                        mapDirections={mapDirections}
                        onClearDirections={onClearDirections}
                        setShowDirectionsPanel={setShowDirectionsPanel}
                    />
                </View>
                : null}
            <View
                style={{
                    justifyContent: "space-between",
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    zIndex: 1000,
                    flexDirection: 'row',
                }}
            >

                <RenderAddressSearch
                    setShowSearchMarker={setShowSearchMarker}
                    setCenter={setCenter}
                    setSearchMarkerPosition={setSearchMarkerPosition}
                />
                {!showDitanceTool &&
                    <Layout style={{ alignSelf: 'flex-end', margin: 3 }}>
                        <Button
                            appearance="outline"
                            status="success"
                            onPress={() => {
                                setShowDistanceTool(true)
                            }}
                        >
                            Distance Tool
                        </Button>
                    </Layout>
                }
            </View>
            {/*
            //////////////////////////////////////////////////////////////////
            //////////////////////////////////////////////////////////////////
            //////// GOOGLE MAPS
            //////////////////////////////////////////////////////////////////
            //////////////////////////////////////////////////////////////////
            */}
            <GoogleMap
                ref={mapRef}
                mapContainerStyle={containerStyle}
                center={center}
                zoom={10}
                onLoad={onLoad}
                onUnmount={onUnmount}
                onZoomChanged={onMapZoomLevelChanged}
                options={{
                    styles: showDarkMap ? darkMapStyles : lightMapStyles,
                    disableDefaultUI: true,
                    zoomControl: false
                }}
                onClick={onMapClicked}
            >
                <RenderToggles
                    onSetServiceAreasShown={onSetServiceAreasShown}
                    setShowDarkMap={setShowDarkMap}
                    setShowTrafficFlow={setShowTrafficFlow}
                    showDarkMap={showDarkMap}
                    showTrafficFlow={showTrafficFlow}
                    showServiceAreaPolygons={showServiceAreaPolygons}
                />
                {RenderMemoizedUnassignedJobClusterComponent}
                {RenderMemoizedAssignedJobClusterComponent}
                {RenderMemoizedRequestJobClusterComponent}
                {RenderMemoizedCompleteJobClusterComponent}
                {RenderMemoizedCancelledJobClusterComponent}
                {RenderMemoizedTransferredOutJobClusterComponent}
                {RenderMemoizedPendingJobClusterComponent}
                {RenderMemoizedDriverMarkers}
                {RenderMemoizedRoutes}
                {/* <RenderDriverMarkers
                    drivers={memoizedDrivers}
                    onDriverMarkerClick={onDriverMarkerClick}
                    renderDriverIcon={RenderDriverMarkers}
                    assignedDriverIds={assignedDriversIdListRef.current}
                    onRouteDriverIds={onRouteDriversIdListRef.current}
                /> */}
                {showJobInfoWindow &&
                    <InfoWindow
                        //@ts-ignore
                        visible={showJobInfoWindow}
                        position={
                            {
                                lat: selectedJobMarkerRef.current?.details?.location?.lat,
                                lng: selectedJobMarkerRef.current?.details?.location?.lon // for some reason locations come through as lon
                            } as google.maps.LatLngLiteral}
                        style={{ display: showJobInfoWindow ? "flex" : "none", justifyContent: 'center', alignItems: 'center' }}
                        onCloseClick={() => setShowJobInfoWindow(false)}
                    >
                        {selectedJobMarkerRef.current?.details?.towing_details ?
                            <TowingJobInfo
                                job={selectedJobMarkerRef.current}
                                setShowJobInfoWindow={setShowJobInfoWindow}
                                setShowTowingPolyline={setShowTowingPolyline}
                                setTowingPolylines={setTowingPolylines}
                                fn_selectJob={onSelectJob}
                            />
                            :
                            <RoadsideJobInfo
                                job={selectedJobMarkerRef.current ? selectedJobMarkerRef.current : new Job({})}
                                setShowJobInfoWindow={setShowJobInfoWindow}
                                setShowTowingPolyline={setShowTowingPolyline}
                                setTowingPolylines={setTowingPolylines}
                                fn_selectJob={onSelectJob}
                            />
                        }

                    </InfoWindow>
                }
                {showDriverInfoWindow && selectedDriverMarker.current && (
                    // <RenderDriverInfoWindow />
                    <InfoWindow
                        position={{
                            lat: selectedDriverMarker.current?.details?.location?.coords.latitude,
                            lng: selectedDriverMarker.current?.details?.location?.coords.longitude,
                        }}
                        onCloseClick={() => {
                            setShowDriverInfoWindow(false);
                        }}
                        // @ts-ignore
                        style={{
                            background: "#121721",
                        }}
                    >
                        <MemberDeviceInfoCard member={selectedDriverMarker.current} />
                    </InfoWindow>
                )}
                {showSearchMarker && searchMarkerPosition &&
                    <Marker
                        position={searchMarkerPosition.coords}
                        onClick={() => {
                            setShowSearchMarkerInfoWindow(true)
                        }}
                    />
                }
                {showSearchMarkerInfoWindow &&
                    <InfoWindow
                        position={searchMarkerPosition?.coords}
                        onCloseClick={() => {
                            setShowSearchMarkerInfoWindow(false)
                        }}
                    >
                        <Layout style={{
                            border: "1px solid black",
                            borderRadius: 5,
                            padding: 5,
                            backgroundColor: "#121721",
                        }}>
                            <Text category='label'>Address:</Text>
                            <Text>
                                {searchMarkerPosition?.address?.description}
                            </Text>
                            <Button
                                appearance="ghost"
                                onPress={() => {
                                    setShowSearchMarker(false)
                                    setShowSearchMarkerInfoWindow(false)
                                }}
                            >Dismiss</Button>
                        </Layout>
                    </InfoWindow>
                }
                {distanceOriginMarker &&
                    <Marker
                        position={distanceOriginMarker}
                        icon={{
                            path: google.maps.SymbolPath.BACKWARD_CLOSED_ARROW,
                            scale: 6,
                            fillColor: "blue",
                            fillOpacity: 0.5,
                            strokeColor: "black",
                            strokeWeight: 5,
                            strokeOpacity: 1,
                        }}
                    />
                }
                {distanceDestinationMarker &&

                    <Marker
                        position={distanceDestinationMarker}
                        icon={{
                            path: google.maps.SymbolPath.BACKWARD_CLOSED_ARROW,
                            scale: 6,
                            fillColor: "red",
                            fillOpacity: 0.5,
                            strokeColor: "black",
                            strokeWeight: 5,
                            strokeOpacity: 1,
                        }}
                    />
                }
                {showServiceAreaPolygons ?
                    <RenderServiceAreaPolygons
                        serviceAreas={StorageController.getCurrentCompany().settings?.service_areas || []}
                    /> : null
                }
                {(showTowingPolyline && towingPolylines?.length > 0) &&
                    <RenderTowingPolylines />
                }
                {showDirections ?
                    <ErrorBoundary>
                        <DirectionsRenderer
                            options={{
                                directions: mapDirections,
                                suppressMarkers: true,
                                polylineOptions: {
                                    strokeColor: "blue",
                                    strokeOpacity: 0.5,
                                    strokeWeight: 5,
                                },
                                preserveViewport: true,
                            }}
                        />
                    </ErrorBoundary>
                    : null
                }
                {showDistanceDirections &&
                    <ErrorBoundary>
                        <DirectionsRenderer
                            options={{
                                directions: distanceDirections,
                                suppressMarkers: true,
                                polylineOptions: {
                                    strokeColor: "blue",
                                    strokeOpacity: 0.5,
                                    strokeWeight: 5,
                                },
                                preserveViewport: true,
                            }}
                        />
                    </ErrorBoundary>
                }
                {showTrafficFlow ? <TrafficLayer
                    onLoad={onLoad}
                    weight={2}
                    options={{
                        //@ts-ignore
                        showTrafficFlow: true,
                        showTrafficIncidents: true,
                        autoRefresh: true
                    }}
                /> : null}
            </GoogleMap>

        </View>
    )

}

interface RenderRoutesProps {
    routes: Route[];
    onRouteMarkerClick: (route: Route, job_id: string) => void;
    selectedRoute: Route | null;
}

const RenderRoutes = ({ routes, onRouteMarkerClick, selectedRoute }: RenderRoutesProps) => {
    if (routes.length === 0) return <></>

    const RenderRoute = ({ route, index }: { route: Route, index: number }) => {
        const isSelected = selectedRoute && selectedRoute._id === route._id;

        const RenderPolylines = () => {
            if (isSelected) {
                // Render individual leg polylines for selected route
                return route.details.legs.map((leg: RouteLeg, legIndex: number) => {
                    let decodedPolyline;
                    if (typeof leg.polyline === 'string') {
                        decodedPolyline = polyline.decode(leg.polyline, 6).map((point: any) => ({ lat: point[0], lng: point[1] }));
                    } else {
                        decodedPolyline = leg.polyline;
                    }
                    const options = {
                        strokeColor: polylineStrokeColourSet(legIndex),
                        strokeOpacity: 0.8,
                        strokeWeight: 4,
                    };
                    return (
                        <Polyline
                            key={legIndex}
                            path={decodedPolyline}
                            options={options}
                        />
                    );
                });
            } else {
                // Render overview polyline for non-selected routes
                let overviewPolyline: any = null;
                if (typeof route.details.all_steps_polyline[0] == 'string') {
                    overviewPolyline = polyline.decode(route.details.all_steps_polyline[0] as string, 6)
                        .map((point: any) => ({ lat: point[0], lng: point[1] }));
                }
                const options = {
                    strokeColor: polylineStrokeColourSet(index),
                    strokeOpacity: 0.8,
                    strokeWeight: 4,
                };
                return (
                    <Polyline
                        path={overviewPolyline}
                        options={options}
                    />
                );
            }
        };

        return (
            <React.Fragment key={route._id}>
                {route.details.locations.map((location: RouteLocation, locationIndex: number) => (
                    <Marker
                        key={locationIndex}
                        label={(locationIndex + 1).toString()}
                        position={{ lat: location.location?.latitude, lng: location.location?.longitude }}
                        onClick={() => onRouteMarkerClick(route, location.job_id)}
                    />
                ))}
                {RenderPolylines()}
            </React.Fragment>
        );
    };

    // If no route is selected, show all routes
    if (!selectedRoute) {
        return (
            <React.Fragment>
                {routes.map((route: Route, index: number) => (
                    <RenderRoute key={route._id} route={route} index={index} />
                ))}
            </React.Fragment>
        );
    }

    // If a route is selected, show only that route
    const selectedRouteIndex = routes.findIndex((route: Route) => route._id === selectedRoute._id);
    if (selectedRouteIndex !== -1) {
        return <RenderRoute route={selectedRoute} index={selectedRouteIndex} />;
    }

    // If the selected route is not found in the routes array, show nothing
    return <></>;
};