import { Job, LINE_ITEM_BILLED_TO, LineItem } from './Job.model';
import { Holding } from './Holding.model';
import { Case } from './Case.model';
/**
 * Represents an Invoice.
 * @class
 */
export class Invoice {

    _id: string | null;
    case_id: string | null;
    company_id: string | null;
    items: LineItem[] = [];
    details: any = "";
    gst_type: any = "";
    total_amount_before_gst: number = 0;
    total_gst_amount: number = 0;
    total_amount: number = 0;
    status: any = "";
    invoice_date: number = 0;
    due_date: number = 0;
    paid_date: number = 0;
    createdAt: string = "";
    updatedAt: string = "";

    constructor(invoice: any = {}) {
        this._id = invoice._id || "";
        this.case_id = invoice.case_id || "";
        this.company_id = invoice.company_id || "";
        this.items = invoice.items || [];
        this.details = invoice.details || "";
        this.gst_type = invoice.gst_type || "";
        this.total_amount_before_gst = invoice.total_amount_before_gst || 0;
        this.total_gst_amount = invoice.total_gst_amount || 0;
        this.total_amount = invoice.total_amount || 0;
        this.status = invoice.status || "";
        this.invoice_date = invoice.invoice_date || 0;
        this.due_date = invoice.due_date || 0;
        this.paid_date = invoice.paid_date || 0;
        this.createdAt = invoice.createdAt || "";
        this.updatedAt = invoice.updatedAt || "";
    }

    // /**
    //  * Calculates the total amount before GST for all items in the invoice.
    //  * @returns {number} The total amount before GST.
    //  */
    // getTotalAmountBeforeGst() {
    //     return this.items.map(item => item.total_before_gst).reduce((acc, curr) => acc + curr, 0);
    // }

    // /**
    //  * Calculates the total GST amount for all items in the invoice.
    //  * @returns {number} The total GST amount.
    //  */
    // getTotalGstAmount() {
    //     return this.items.map(item => item.gst_amount).reduce((acc, curr) => acc + curr, 0);
    // }

    /**
     * Calculates the total amount including GST for all items in the invoice.
     * @returns {number} The total amount including GST.
     */
    getTotalAmount() {
        return this.items.map(item => (item.cost * item.quantity)).reduce((acc, curr) => acc + curr, 0);
    }

    // /**
    //  * Calculates the total amount before GST for items in the invoice filtered by billableTo.
    //  * @param {string} billableTo - The billableTo value to filter by.
    //  * @returns {number} The total amount before GST for the filtered items.
    //  */
    // getTotalAmountBeforeGstByBillableTo(billableTo: LINE_ITEM_BILLED_TO = LINE_ITEM_BILLED_TO.CUSTOMER_COST) {
    //     return this.items.filter(item => item.billing === billableTo).map()
    // }

    // /**
    //  * Calculates the total GST amount for items in the invoice filtered by billableTo.
    //  * @param {string} billableTo - The billableTo value to filter by.
    //  * @returns {number} The total GST amount for the filtered items.
    //  */
    // getTotalGstAmountByBillableTo(billableTo: LINE_ITEM_BILLED_TO = LINE_ITEM_BILLED_TO.CUSTOMER_COST) {
    //     return this.items.filter(item => item.billing === billableTo).map(item => item.gst_amount).reduce((acc, curr) => acc + curr, 0);
    // }

    /**
     * Calculates the total amount including GST for items in the invoice filtered by billableTo.
     * @param {string} billableTo - The billableTo value to filter by.
     * @returns {number} The total amount including GST for the filtered items.
     */
    getTotalAmountByBillableTo(billableTo: LINE_ITEM_BILLED_TO = LINE_ITEM_BILLED_TO.CUSTOMER_COST) {
        return this.items.filter(item => item.billing === billableTo).map(item => (item.cost * item.quantity)).reduce((acc, curr) => acc + curr, 0);
    }
}

/**
 * Represents an item in an invoice.
 * @class
 */
export class InvoiceItem {

    description = "";


    name = "";


    quantity = 0;

    unit_price = 0;


    total_before_gst = 0;


    gst_amount = 0;

    total = 0;


    gst_inclusive = false;


    paid = false;

    billable_to = LINE_ITEM_BILLED_TO;

    constructor(invoiceItem: any = {}) {
        this.description = invoiceItem.description || "";
        this.name = invoiceItem.name || "";
        this.quantity = invoiceItem.quantity || 0;
        this.unit_price = invoiceItem.unit_price || 0;
        this.total_before_gst = invoiceItem.total_before_gst || 0;
        this.gst_amount = invoiceItem.gst_amount || 0;
        this.total = invoiceItem.total || 0;
        this.gst_inclusive = invoiceItem.gst_inclusive || false;
        this.paid = invoiceItem.paid || false;
        this.billable_to = invoiceItem.billable_to || "";
    }
}



export class XeroInvoice {
    contactName: string;
    contactId: string;
    date: string;
    dueDate: string;
    invoiceDate: string;
    xeroLineItems: XeroInvoiceLineItem[] = [];
    reference: string;
    description: string;
    quantity = 1;
    unitAmount = 0;
    taxType: string;
    invoiceNumber: string;
    accountCode: string;


    constructor({
        accountCode,
        clientName,
        taxType, // GST on Income / GST on Expenses / No GST
        invoiceNumber, //Job Number, Invoice Number, Case Number
        invoiceDate, // Date created
        dueDate, // Due Date
        quantity = 1, // Quantity - probably 1 for the first item
        unitAmount = 0, // - for a job maybe the callout fee, for a case, 0?
        reference = "", // Client reference ID
        description, // Description
        lineItems, // LineItem array
    }: any) {
        this.contactName = clientName || "";
        this.contactId = "";
        this.date = invoiceDate || "";
        this.taxType = taxType || "";
        this.invoiceDate = invoiceDate || "";
        this.invoiceNumber = invoiceNumber || "";
        this.dueDate = dueDate || "";
        this.quantity = quantity || 1;
        this.unitAmount = unitAmount || 0;
        this.reference = reference || "";
        this.description = description || "";
        this.accountCode = accountCode || "";

        this.xeroLineItems = lineItems ?
            lineItems.map((item: LineItem) => {
                const l = new LineItem(item);
                const data = {
                    contactName: clientName,
                    accountCode: accountCode,
                    taxType: taxType,
                    invoiceNumber: invoiceNumber,
                    invoiceDate: invoiceDate,
                    dueDate: dueDate,
                    quantity: l.quantity,
                    unitAmount: l.cost,
                    reference: reference,
                    description: l.name || l.description,
                }
                return new XeroInvoiceLineItem(data)
            })
            : [] as XeroInvoiceLineItem[];
    }

    toXeroFormat() {
        return {
            ['*ContactName']: this.contactName,
            ['*AccountCode']: this.accountCode,
            ['*TaxType']: this.taxType,
            ['*InvoiceNumber']: this.invoiceNumber,
            ['*InvoiceDate']: this.invoiceDate,
            ['*DueDate']: this.dueDate,
            ['*Quantity']: this.quantity,
            ['*UnitAmount']: this.unitAmount,
            ['*Reference']: this.reference,
            ['*Description']: this.description,
        }
    }

    toXeroInvoiceArray() {
        const invoice0 = this.toXeroFormat()
        const result = [...[this.toXeroFormat()], ...this.xeroLineItems.map(item => item.toXeroInvoiceLineItem())]
        return result
    }

}


export class XeroInvoiceLineItem {
    contactName = "";
    accountCode = "";
    taxType = "";
    invoiceNumber = "";
    invoiceDate = "";
    dueDate = "";
    quantity = 0;
    unitAmount = 0;
    reference = "";
    description = "";

    constructor(lineItem: any = {}) {
        this.contactName = lineItem.contactName || "";
        this.accountCode = lineItem.accountCode || "";
        this.taxType = lineItem.taxType || "";
        this.invoiceNumber = lineItem.invoiceNumber || "";
        this.invoiceDate = lineItem.invoiceDate || "";
        this.dueDate = lineItem.dueDate || "";
        this.quantity = lineItem.quantity || 0;
        this.unitAmount = lineItem.unitAmount || 0;
        this.reference = lineItem.reference || "";
        this.description = lineItem.description || "";
    }

    toXeroInvoiceLineItem() {
        return {
            ['*ContactName']: this.contactName,
            ['*AccountCode']: this.accountCode,
            ['*TaxType']: this.taxType,
            ['*InvoiceNumber']: this.invoiceNumber,
            ['*InvoiceDate']: this.invoiceDate,
            ['*DueDate']: this.dueDate,
            ['*Quantity']: this.quantity,
            ['*UnitAmount']: this.unitAmount,
            ['*Reference']: this.reference,
            ['*Description']: this.description,
        }
    }

}

