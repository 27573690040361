import React from 'react';
import {
    Image,
    AccessibilityRole,
    ImageProps,
    ImageStyle,
    StyleSheet,
    TouchableOpacity,
    View,
    ScrollView,
    Text as RNText
    //@ts-ignore
} from "react-native";
import {
    ApplicationProvider,
    Button,
    Icon,
    IconRegistry,
    Layout,
    Text,
    List,
    ListItem,
    Card,
    Modal,
} from "@ui-kitten/components";
import { SafeAreaView } from 'react-native-safe-area-context';
import { EvaIconsPack } from "@ui-kitten/eva-icons";
import { mapping, light, dark } from "@eva-design/eva";
import * as StorageController from '../../functions/storageController';
import * as MembershipController from '../../functions/membership.controller';
import { MemberDeviceInfoCard } from '../members/memberDeviceInfoCard.component';
import { Company } from '../../models/Company.model';
import { Member } from '../../models/Member.model';
import { JOB_STATUS, Job } from '../../models/Job.model';
import Tooltip from '../modals/Tooltip';

interface IAssignJobListProps {
    member_id: any;
    fn_assignMember: (member: Member) => void;
    selectedJob: Job;
}

const AssignJobList = ({
    member_id,
    fn_assignMember,
    selectedJob
}: IAssignJobListProps) => {

    const [drivers, setDrivers] = React.useState([] as Member[])
    const memoizedOnlineDrivers = React.useMemo(() => drivers.filter((item: Member) => item.online), [drivers])
    const memoizedOfflineDrivers = React.useMemo(() => drivers.filter((item: Member) => !item.online), [drivers])
    const [selectedDriver, setSelectedDriver] = React.useState(null as Member | null)
    const [showMemberDeviceInfo, setShowMemberDeviceInfo] = React.useState(false)

    const [company, setCompany] = React.useState(null as Company | null)


    const companyRef = React.useRef(null as Company | null)
    const companyJobsRef = React.useRef(null as any)
    const [showMemberAlreadyAssignedModal, setShowMemberAlreadyAssignedModal] = React.useState(false)

    React.useEffect(() => {
        const company = StorageController.getCurrentCompany() as Company
        const companyJobs = company.jobs

        companyRef.current = company
        companyJobsRef.current = companyJobs
        setCompany(company)

        setTimeout(() => {
            getDrivers()
        }, 0)
    }, [])

    const getDrivers = async () => {
        const d = await MembershipController.getDriversByCompanyId(StorageController.getCurrentCompany()._id)
        const _drivers = d.map((item: Member) => new Member(item))
        // sort by online
        _drivers.sort((a: Member, b: Member) => {
            if (a.online && !b.online) return -1
            if (!a.online && b.online) return 1
            return 0
        })
        // filter removed or deleted drivers
        const drivers = _drivers.filter((item: Member) => {
            if (item.deleted || item.locked) return false
            return item.is_driver == true
        })
        setDrivers(drivers)
    }

    /**
     * Count number of jobs assigned to a member, ignoring non dispatchable jobs
     * @param member_id 
     * @returns 
     */
    const assignedToCount = (member_id: string) => {
        let count = 0
        companyJobsRef.current.forEach((job: Job) => {
            if (job.member_id == member_id && job.status == JOB_STATUS.ASSIGNED) {
                if (job.dispatchable) {
                    count++
                }
            }
        })
        return count
    }

    const assignLimit = () => {
        const hasLimit = company?.settings?.driver_settings?.enable_concurrent_jobs_per_driver_limit || false
        const limit = company?.settings?.driver_settings?.concurrent_jobs_per_driver || 1
        return hasLimit ? limit : 1
    }

    const canAssignJob = (member: Member) => {
        const hasLimit = company?.settings?.driver_settings?.enable_concurrent_jobs_per_driver_limit || false
        const limit = company?.settings?.driver_settings?.concurrent_jobs_per_driver || 1
        if (!hasLimit) return true
        if (selectedJob && !selectedJob.dispatchable) return true
        if (assignedToCount(member._id) >= limit) {
            return false
        }
        return true
    }

    const onAssignMember = (member: Member) => {
        //assign job
        fn_assignMember(member)
    }








    // online Icon with green color
    const OnlineIcon = (style: any) => (
        <Icon {...style} name='radio-button-on' fill='green' width={25} height={25} />
    );

    // offline Icon with red color
    const OfflineIcon = (style: any) => (
        <Icon {...style} name='radio-button-on' fill='red' width={25} height={25} />
    );

    const MemberStatusButton = ({
        item,
    }: any) => {

        //if already assigned to this job
        if (item._id == member_id) {
            return (<Button style={{ width: 100 }} disabled={true} status='warning'>Assigned</Button>)
        }
        //if member is already assigned to a job and limit is applied
        if (canAssignJob(item) == false) {
            return (<Button style={{ width: 100 }} disabled={true} status='warning'>Limit</Button>)
        }
        // if (item.status == "busy") {
        //     return (<Button style={{ width: 100 }} disabled={true} status='basic'>Busy</Button>)
        // }
        else {
            return (
                <Button style={{ width: 100 }}
                    onPress={() => onAssignMember(item)}
                    status='success'
                    disabled={StorageController.getAppState().selectedMembership?.is_client ? true : false}
                >
                    Assign
                </Button>
            )
        }
    }

    const onSelectMemberInfo = (item: Member) => {
        console.log("🚀============== ~ file: assignJobList.component.js:102 ~ onSelectMemberInfo ~ item🚀==============", item)
        setSelectedDriver(item)
        setShowMemberDeviceInfo(true)
    }


    // const renderItem = ({ item, index }) => (
    //     <ListItem title={`${item.title} ${index + 1}`}/>
    // );
    const RenderItem = ({ item, index }: any) => (
        <Layout
            style={styles.item}
            //@ts-ignore
            status='basic'>
            <Layout style={{ flex: 1, justifyContent: 'center', alignItems: 'center', paddingHorizontal: 0 }}>
                <Layout style={{ flex: 1, justifyContent: 'center', alignItems: 'center', width: "100%", flexDirection: "row" }}>
                    <Layout style={{ flex: 1, justifyContent: 'flex-start', alignItems: 'flex-start', width: "100%", flexDirection: 'row' }}>
                        <View style={{ flexDirection: 'column' }}>
                            {item.online ? <OnlineIcon /> : <OfflineIcon />}
                            <TouchableOpacity
                                onPress={() => {
                                    onSelectMemberInfo(item)
                                }}
                            >
                                <Icon name='info-outline' fill='white' width={25} height={25} />
                            </TouchableOpacity>
                        </View>
                        <Text category="s1" numberOfLines={2} style={{ marginLeft: 5 }}>
                            {item.name}
                        </Text>
                        {/* Render numbe of jobs this member is assigned to */}
                    </Layout>
                    <Layout style={{ flex: 1, justifyContent: 'flex-end', alignItems: 'flex-end', flexDirection: "row" }}>
                        <MemberStatusButton item={item} />
                    </Layout>
                </Layout>
                {company?.settings?.driver_settings?.enable_concurrent_jobs_per_driver_limit && (
                    <View style={{ width: "100%" }}>
                        <Tooltip text={`Assigned to ${assignedToCount(item._id)} jobs - Limit ${assignLimit()}`}>
                            <View style={{ flexDirection: 'row', gap: 5 }}>
                                <Icon name='briefcase-outline' fill='white' width={25} height={25} />
                                {canAssignJob(item) ? (
                                    null
                                ) : (
                                    <Icon name='alert-triangle-outline' fill='orange' width={25} height={25} />
                                )}
                                <Text category="s1" style={{ alignSelf: 'center' }}>{assignedToCount(item._id)}</Text>
                            </View>
                        </Tooltip>
                    </View>
                )}
            </Layout>
        </Layout>
    );

    const RenderDriversList = ({ drivers, status, title }: any) => {
        return (
            <ScrollView style={{ flex: 1 }}>
                <View style={[styles.driversGrid, { margin: 0 }]}>
                    {drivers.map((item: Member, index: number) => (
                        <View style={styles.driverItem} key={index}>
                            <RenderItem item={item} index={index} />
                        </View>
                    ))}
                </View>
            </ScrollView>
            // </Card>
        )
    }


    return (
        <View style={{ justifyContent: 'center', alignItems: 'center', height: "100%", overflow: "hidden" }}>
            <RenderMemberAlreadyAssignedModal
                showMemberAlreadyAssignedModal={showMemberAlreadyAssignedModal}
                setShowMemberAlreadyAssignedModal={setShowMemberAlreadyAssignedModal}
            />
            <Layout style={styles.listContainer}>
                <ScrollView>
                    <View style={styles.driversContainer}>
                        {drivers.length === 0 ? (
                            <Text category="h5">No Drivers Available</Text>
                        ) : (
                            <View style={{ flexDirection: 'row', flex: 1 }}>
                                <View style={{ flexDirection: 'column', width: '50%' }}>
                                    <Text style={{ alignSelf: 'center' }} category="h6">Online Drivers</Text>
                                    <View style={{ width: "100%", height: 3, backgroundColor: 'black' }}></View>
                                    <RenderDriversList drivers={memoizedOnlineDrivers} status={"success"} title={"OnlineDrivers"} />
                                </View>
                                <View style={{ width: 3, height: "100%", backgroundColor: 'black' }}></View>
                                <View style={{ flexDirection: 'column', width: '50%' }}>
                                    <Text style={{ alignSelf: 'center' }} category="h6">Offline Drivers</Text>
                                    <View style={{ width: "100%", height: 3, backgroundColor: 'black' }}></View>
                                    <RenderDriversList drivers={memoizedOfflineDrivers} status={"danger"} title={"OfflineDrivers"} />
                                </View>
                            </View>
                        )}
                        {selectedDriver && showMemberDeviceInfo && (
                            <View style={{ width: 300 }}>
                                <MemberDeviceInfoCard
                                    member={selectedDriver}
                                // onDismiss={() => {
                                //     setSelectedDriver(null)
                                //     setShowMemberDeviceInfo(false)
                                // }}
                                />
                                <Button
                                    appearance='outline'
                                    status='danger'
                                    onPress={() => {
                                        setSelectedDriver(null)
                                        setShowMemberDeviceInfo(false)
                                    }}>Close</Button>
                            </View>
                        )}
                    </View>
                </ScrollView>
            </Layout>
        </View>
    );

};


const RenderMemberAlreadyAssignedModal = ({
    showMemberAlreadyAssignedModal,
    setShowMemberAlreadyAssignedModal
}: {
    showMemberAlreadyAssignedModal: boolean, setShowMemberAlreadyAssignedModal: any
}) => {
    return (
        <Modal
            visible={showMemberAlreadyAssignedModal}
            backdropStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
            onBackdropPress={() => setShowMemberAlreadyAssignedModal(false)}>
            <Layout style={{ width: 300 }}>
                <Text>Member is already at limit of assigned jobs</Text>
                <Button
                    appearance='outline'
                    status='danger'
                    onPress={() => {
                        setShowMemberAlreadyAssignedModal(false)
                    }}>Close</Button>
            </Layout>
        </Modal>
    )
}

const styles = StyleSheet.create({
    driversContainer: {
        flexDirection: "row",
        height: "100%",
        width: "100%",
        // overflow: "hidden"
    },
    driversGrid: {
        flexDirection: "row",
        gap: 2,
        // flex: 1,
        flexWrap: "wrap",
        // width: "100%",
        overflowY: "auto",
    },
    driverItem: {
        flexBasis: "33%",
        minWidth: 230,
    },
    container: {
        maxHeight: "100%",
        height: "100%",
        overflow: "hidden"
    },
    contentContainer: {
        paddingHorizontal: 0,
        paddingVertical: 0,
        overflow: "hidden"
    },
    listContainer: {
        flexDirection: "row",
        height: "100%",
        width: "100%",
        overflow: "hidden"
    },
    tinyLogo: {
        width: "100%",
        height: 100,
    },
    item: {
        // marginVertical: 4,
        // backgroundColor: 'rgba(34, 43, 69,1)',
        // borderColor: 'rgba(16, 20, 38,1)',
        borderRadius: 4,
        borderWidth: 1,
        padding: 5,
    },
});






export default React.memo(AssignJobList);