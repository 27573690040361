import React, { useState } from 'react';
import { View, StyleSheet, ScrollView } from 'react-native';
import { Button, Card, Text, useTheme, Icon, Input } from '@ui-kitten/components';
import { Route } from '../../models/Route.model';

interface RoutesListProps {
    routes: Route[];
    selectedRouteIndex: number | null;
    onSelectRoute: (route: Route, index: number) => void;
    onCreateRoutes: () => void;
    isRouteCreated: boolean;
    onUpdateRouteName: (index: number, newName: string) => void;
}

export const RoutesList: React.FC<RoutesListProps> = ({
    routes,
    selectedRouteIndex,
    onSelectRoute,
    onCreateRoutes,
    isRouteCreated,
    onUpdateRouteName
}) => {
    const theme = useTheme();
    const [editingIndex, setEditingIndex] = useState<number | null>(null);
    const [editedName, setEditedName] = useState<string>('');

    const styles = StyleSheet.create({
        container: {
            gap: 20,
            padding: 10,
            flexDirection: 'row',
        },
        routeCard: {
            marginBottom: 10,
            borderWidth: 2,
            borderColor: 'transparent',
        },
        selectedRouteCard: {
            borderColor: theme['color-primary-500'],
        },
        routeInfo: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: 5,
            alignItems: 'center',
            gap: 5,
        },
        detailTextPill: {
            borderWidth: 1,
            borderRadius: 8,
            paddingHorizontal: 8,
            paddingVertical: 4,
        },
        statusBadge: {
            position: 'absolute',
            top: 0,
            right: 0,
            zIndex: 1,
        },
        routeNameContainer: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: 10,
        },
        routeNameInput: {
            flex: 1,
            borderBottomWidth: 1,
            borderBottomColor: theme['color-primary-500'],
            marginRight: 10,
        },
    });

    const getStops = (route: Route): string => {
        if (!route.details.locations) return 'N/A';
        return `${route.details.locations.length}`;
    };

    const getTotalDistance = (route: Route): string => {
        if (!route.details.legs) return 'N/A';
        return `${route.details.distance_kms?.toFixed(2)} km`;
        // return `${route.details.legs.reduce((sum, leg) => sum + leg.actual_distance_kms, 0).toFixed(2)} km`;
    };

    const getTotalDuration = (route: Route): string => {
        if (!route.details.duration_seconds) return 'N/A';
        return `${(route.details.duration_seconds / 60).toFixed(2)} minutes`;
        // return `${Math.round(route.details.legs.reduce((sum, leg) => sum + leg.actual_duration_seconds, 0) / 60)} min`;
    };

    const handleEditName = (index: number, currentName: string) => {
        setEditingIndex(index);
        setEditedName(currentName);
    };

    const handleSaveName = (index: number) => {
        onUpdateRouteName(index, editedName);
        setEditingIndex(null);
    };

    return (
        <ScrollView horizontal={true}>
            <View style={styles.container}>
                {routes.map((route, index) => (
                    <Card
                        header={
                            <View>
                                <View style={styles.routeNameContainer}>
                                    {editingIndex === index ? (
                                        <>
                                            <Input
                                                value={editedName}
                                                onChangeText={setEditedName}
                                                style={styles.routeNameInput}
                                                autoFocus
                                            />
                                            <Button
                                                size="small"
                                                appearance="ghost"
                                                accessoryLeft={<Icon name="checkmark-outline" />}
                                                onPress={() => handleSaveName(index)}
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <Text category="s1">{route.name || `Route ${index + 1}`}</Text>
                                            <Button
                                                size="small"
                                                appearance="ghost"
                                                accessoryLeft={<Icon name="edit-2-outline" />}
                                                onPress={() => handleEditName(index, route.name || `Route ${index + 1}`)}
                                            />
                                        </>
                                    )}
                                </View>
                                <View style={styles.statusBadge}>
                                    <Text 
                                        style={[
                                            styles.detailTextPill, 
                                            {
                                                backgroundColor: route._id ? theme['color-success-100'] : theme['color-warning-100'],
                                                fontSize: 12,
                                            }
                                        ]}
                                    >
                                        {route._id ? 'Created' : 'Not Created'}
                                    </Text>
                                </View>
                            </View>
                        }
                        status='basic'
                        key={index}
                        style={[
                            styles.routeCard,
                            selectedRouteIndex === index ? styles.selectedRouteCard : null
                        ]}
                        onPress={() => onSelectRoute(route, index)}
                    >
                        <View style={styles.routeInfo}>
                            <Text>Distance:</Text>
                            <Text style={[styles.detailTextPill, {backgroundColor: theme['color-success-100'] }]}>{getTotalDistance(route)}</Text>
                        </View>
                        <View style={styles.routeInfo}>
                            <Text>Duration:</Text>
                            <Text style={[styles.detailTextPill, {backgroundColor: theme['color-warning-100'] }]}>{getTotalDuration(route)}</Text>
                        </View>
                        <View style={styles.routeInfo}>
                            <Text>Stops:</Text>
                            <Text style={[styles.detailTextPill, {backgroundColor: theme['color-info-100'] }]}>{getStops(route)}</Text>
                        </View>
                    </Card>
                ))}
            </View>
        </ScrollView>
    );
};