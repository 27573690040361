import React, { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import { View, TouchableOpacity, Animated, StyleSheet, ScrollView, LayoutChangeEvent } from 'react-native';
import { Text, CheckBox, Icon, Button, useTheme, OverflowMenu, MenuItem, Layout } from '@ui-kitten/components';
import { Job, JOB_STATUS, JobCustomerDetails, JobEquipmentDetails, JobVehicleDetails, TowingLocation } from '../../models/Job.model';
import * as StorageController from '../../functions/storageController';
import { Member } from '../../models/Member.model';
import { Client } from '../../models/Client.model';
import { Badge } from '../job/components/Badge.component';
import { formatTimestamp } from '../../functions/utils';
import { CopyJobComponent } from './copyJob/copyJob.component';
import * as JobController from '../../functions/job.controller';
import { TransferJobComponent } from './transfer/transferJob.component';
import { VendorAcceptModal } from './VendorAcceptModal';
import { useAppStateChange } from '../../hooks/appStateChange.hook';

interface CompactJobListItemProps {
    job: Job;
    driver: Member;
    client: Client;
    onAssign: (job: Job) => void;
    onViewDetails: (job: Job) => void;
    isSelected?: boolean;
    onCheckboxChange: (checked: boolean) => void;
    containerWidth?: number;
    selectedJobId: string | null;
}

export const CompactJobListItem: React.FC<CompactJobListItemProps> = React.memo(({
    job,
    driver,
    client,
    onAssign,
    onViewDetails,
    isSelected,
    onCheckboxChange,
    containerWidth = 1000,
    selectedJobId
}) => {
    const [expanded, setExpanded] = useState(false);
    const [showCopyModal, setShowCopyModal] = useState(false);
    const [showTransferModal, setShowTransferModal] = useState(false);
    const animatedHeight = useRef(new Animated.Value(0)).current;


    const theme = useTheme();
    const [selectedState, setSelectedState] = useState(isSelected)
    const [showVendorAcceptModal, setShowVendorAcceptModal] = useState(false);
    const [requestButtonHeight] = useState(new Animated.Value(0));

    const [selectedJobIdState, setSelectedJobIdState] = useState(false)

    const [jobState, setJobState] = useState(job)

    useEffect(() => {
        setJobState(job)
    }, [job])

    useEffect(() => {
        if (selectedJobId && selectedJobId == job._id) {
            setSelectedJobIdState(true)
        } else {
            setSelectedJobIdState(false)
        }
    }, [selectedJobId])

    const USE_SMALL_WIDTH = containerWidth < 900;

    //This is a hack to update the job state when the job is updated in the background, 
    // as a memoized component updating the whole list is badd
    useAppStateChange({
        onUpdatedJobs(action) {
            if (action.data.job._id == job._id) {
                setJobState(new Job(action.data.job))
            }
        },
    })





    const handleCheckboxChange = useCallback((checked: boolean) => {
        setSelectedState(checked)
        onCheckboxChange(checked)
    }, [onCheckboxChange]);

    useEffect(() => {
        setSelectedState(isSelected)
    }, [isSelected])



    const toggleExpand = useCallback(() => {
        setExpanded(!expanded);
        Animated.timing(animatedHeight, {
            toValue: expanded ? 0 : 1,
            duration: 300,
            useNativeDriver: false,
        }).start();
    }, [expanded, animatedHeight]);


    const renderIcon = (name: string) => (
        <Icon
            style={styles.icon}
            fill={theme['text-basic-color']}
            name={name}
        />
    );

    const maxAnimatedHeight = animatedHeight.interpolate({
        inputRange: [0, 1],
        outputRange: [0, 1000], // Adjust 500 to an appropriate maximum height
    });

    const renderDriverStatus = () => {
        if (!jobState.member_id) {
            return (
                <View style={{ flexDirection: 'row', height: 14, alignItems: 'center' }}>
                    {renderIcon('person-add-outline')}
                    <Button
                        size='tiny'
                        appearance='ghost'
                        style={{ padding: 0, margin: 0, maxHeight: 14 }}
                        onPress={() => onAssign(jobState)}
                    >
                        <Text style={{ fontSize: USE_SMALL_WIDTH ? 8 : 12 }} category="s1">Assign Driver</Text>
                    </Button>
                </View>
            );
        }

        const isOnline = driver.online;
        const statusColor = isOnline ? theme['color-success-500'] : theme['color-danger-500'];
        let driverState = '';
        if (jobState.status == JOB_STATUS.ASSIGNED) {
            if (jobState.driver_accepted) driverState = 'Accepted';
            if (jobState.driver_on_route) driverState = 'On Route';
            if (jobState.driver_arrived) driverState = 'Arrived';
        }

        const fontSize = USE_SMALL_WIDTH ? 10 : 12;
        const dynamicStyles = {
            driverState: {
                marginLeft: 4,
                color: theme['text-basic-color'],
                fontSize: fontSize,
                borderRadius: 10,
                borderWidth: 1,
                borderColor: theme['border-basic-color-3'],
                padding: 1,
                paddingHorizontal: 2,
                backgroundColor: theme['color-success-500'],
            },
        } as any;

        return (
            <View style={{ flexDirection: 'column' }}>
                {driverState &&
                    USE_SMALL_WIDTH ?
                    <View style={{ position: 'absolute', bottom: 0, right: -50 }}>
                        <Text numberOfLines={1} category="s1" style={[dynamicStyles.driverState]}>{driverState}</Text>
                    </View>
                    :
                    <View style={{ position: 'absolute', bottom: -20, left: 0 }}>
                        <Text numberOfLines={1} category="s1" style={[dynamicStyles.driverState]}>{driverState}</Text>
                    </View>
                }
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <View style={[styles.onlineIndicator, { backgroundColor: statusColor }]} />
                    <Text style={{ fontSize: fontSize }} numberOfLines={1} category="s1">{driver.name || ""}</Text>
                </View>
            </View>
        );
    };

    const renderTimesSummary = () => {
        const times = [
            // { label: 'Start', time: job.start_time },
            { label: 'Accepted', time: jobState.accepted_time },
            { label: 'En route', time: jobState.driver_on_route_time },
            { label: 'Arrived', time: jobState.arrived_time },
            { label: 'Pending', time: jobState.pending_time },
        ];

        const formatTime = (time: number) => {
            if (time === 0) return 'N/A';
            let timeString = new Date(time).toLocaleTimeString('en-AU', { hour: '2-digit', minute: '2-digit' });
            return timeString;
        }

        const fontSize = USE_SMALL_WIDTH ? 10 : 12;
        const showLabel = !USE_SMALL_WIDTH;

        return (
            <View style={styles.timesContainer}>
                {times.map((item, index) => (
                    <View key={index} style={styles.timeItem}>
                        {showLabel && <Text numberOfLines={1} category="c2" style={[styles.timeLabel, { fontSize: fontSize }]}>{item.label}</Text>}
                        <View style={[
                            styles.timePill,
                            item.time && item.time !== 0 ? styles.greenPill : styles.redPill
                        ]}>
                            <Text numberOfLines={1} category="c1" style={[styles.timeValue, { fontSize: fontSize }]}>
                                {item.time && item.time !== 0
                                    ? formatTime(item.time)
                                    : 'N/A'}
                            </Text>
                        </View>
                    </View>
                ))}
            </View>
        );
    };

    const renderAddressInfo = () => {
        if (jobState.details.options.towing_job_options && jobState.details.towing_details) {
            return renderCollapsedTowingInfo();
        } else {
            return (
                <View style={[styles.basicInfo]}>
                    {renderIcon('pin-outline')}
                    <Text category='s1'>{jobState.details.address.suburb + "   "}</Text>
                    <Text category="p2" numberOfLines={1} style={styles.address}>
                        {jobState.getAddressToString()}
                    </Text>
                </View>
            );
        }
    };

    const renderCollapsedTowingInfo = () => {
        const towingDetails = jobState.details.towing_details;
        if (!towingDetails) return null;

        return (
            <View style={[styles.collapsedTowingContainer]}>
                {towingDetails.towing_locations.map((location, index) => (
                    <React.Fragment key={index}>
                        <View style={styles.collapedTowingLocationItem}>
                            <Icon
                                name={index === 0 ? "flag-outline" : index === towingDetails.towing_locations.length - 1 ? "checkmark-circle-2-outline" : "pin-outline"}
                                style={styles.locationIcon}
                                fill={location.time ? theme['color-success-500'] : theme['text-danger-color']}
                            />
                            <Text category='s1' numberOfLines={1} style={styles.locationText}>{location.address.suburb}</Text>
                        </View>
                        {index < towingDetails.towing_locations.length - 1 && (
                            <Icon
                                name="arrow-forward-outline"
                                style={styles.arrowIcon}
                                fill={location.time ? theme['color-success-500'] : theme['text-danger-color']}
                            />
                        )}
                    </React.Fragment>
                ))}
                <View style={styles.footerItem}>
                    <Icon name="navigation-2-outline" style={styles.footerIcon} fill={theme['text-basic-color']} />
                    <Text category="p2">{towingDetails.distance_kms} km</Text>
                </View>
                <View style={styles.footerItem}>
                    <Icon name="clock-outline" style={styles.footerIcon} fill={theme['text-basic-color']} />
                    <Text category="p2">{Math.round(towingDetails.duration_seconds / 60)} mins</Text>
                </View>
            </View>
        );
    };

    const RenderTimeDetails = () => {
        const times = [
            { label: 'Start', time: jobState.start_time },
            { label: 'Accepted', time: jobState.accepted_time },
            { label: 'En route', time: jobState.driver_on_route_time },
            { label: 'Arrived', time: jobState.arrived_time },
            { label: 'Pending', time: jobState.pending_time },
            { label: 'Last Updated', time: jobState.updatedAt },
        ];
        return (
            <View style={styles.detailsContainer}>
                <Text category="s1">Time Details</Text>
                {times.map((time, index) => (
                    <View key={index} style={styles.detailRow}>
                        {renderIcon('clock-outline')}
                        <Text category="s1" style={{ width: 150 }}>{time.label}:</Text>
                        <Text category="s2" style={[styles.startTimePill, time.time ? styles.greenPill : styles.redPill]}>{time.time ? formatTimestamp(new Date(time.time)) : 'N/A'}</Text>
                    </View>
                ))}
            </View>
        )
    }

    const RenderCustomerDetails = ({ customer_details }: { customer_details: JobCustomerDetails }) => {
        return (
            <View style={styles.detailsContainer}>
                <Text category="s1">Customer Details</Text>
                <View style={styles.detailRow}>
                    {renderIcon('person-outline')}
                    <Text category="s1" style={styles.detailLabel}>Name:</Text>
                    <Text category="s2" style={styles.detailValue}>{customer_details.name}</Text>
                </View>
                <View style={styles.detailRow}>
                    {renderIcon('phone-outline')}
                    <Text category="s1" style={styles.detailLabel}>Phone:</Text>
                    <Text category="s2" style={styles.detailValue}>{customer_details.phone}</Text>
                </View>
                <View style={styles.detailRow}>
                    {renderIcon('email-outline')}
                    <Text category="s1" style={styles.detailLabel}>Email:</Text>
                    <Text category="s2" style={styles.detailValue}>{customer_details.email}</Text>
                </View>
            </View>
        )
    }

    const RenderSmallVehicleOrEquipmentDetails = ({ job }: { job: Job }) => {
        const isTowingJob = job.details.options.towing_job_options;
        const towing_details = job.details.towing_details;
        const towing_type = towing_details?.towing_type?.toLowerCase();
        const vehicle_details = isTowingJob ? job.details?.towing_details?.vehicle_details || new JobVehicleDetails() : job.details?.vehicle_details;
        const equipment_details = isTowingJob ? job.details?.towing_details?.equipment_details : new JobEquipmentDetails()
        let icon = 'car-outline';
        let details = '';

        if (isTowingJob && towing_type === "equipment") {
            icon = 'cube-outline';
            details = `${equipment_details?.serial || 'N/A'} - ${equipment_details?.type || 'N/A'} - ${equipment_details?.size || 'N/A'}`;
        } else if (isTowingJob && towing_type === "vehicle") {
            details = `${vehicle_details?.rego || 'N/A'} - ${vehicle_details?.make || 'N/A'}/${vehicle_details?.model || 'N/A'} - ${vehicle_details?.year || 'N/A'} - ${vehicle_details?.colour || 'N/A'}`;
        } else {
            details = `${vehicle_details?.rego || 'N/A'} - ${vehicle_details?.make || 'N/A'}/${vehicle_details?.model || 'N/A'} - ${vehicle_details?.year || 'N/A'} - ${vehicle_details?.colour || 'N/A'}`;
        }

        return (
            <View style={styles.smallDetailsContainer}>
                <Icon name={icon} style={styles.smallDetailsIcon} fill={theme['text-basic-color']} />
                <Text style={styles.smallDetailsText} numberOfLines={1}>{details}</Text>
            </View>
        );
    };

    const RenderVehicleOrEquipmentDetails = ({ job }: { job: Job }) => {
        const isTowingJob = job.details.options.towing_job_options;
        const towing_details = job.details.towing_details;
        const towing_type = towing_details?.towing_type?.toLowerCase();
        if (isTowingJob && towing_type == "equipment") {
            return <RenderEquipmentDetails equipment_details={job.details.towing_details?.equipment_details || new JobEquipmentDetails()} />
        } else if (isTowingJob && towing_type == "vehicle") {
            return <RenderVehicleDetails job={job} />
        } else {
            return <RenderVehicleDetails job={job} />
        }
    }

    const RenderVehicleDetails = ({ job }: { job: Job }) => {
        const isTowingJob = job.details.options.towing_job_options;
        const vehicle_details = isTowingJob ? job.details.towing_details?.vehicle_details || new JobVehicleDetails() : job.details.vehicle_details;
        return (
            <View style={styles.detailsContainer}>
                <Text category="s1">Vehicle Details</Text>
                <View style={styles.detailRow}>
                    {renderIcon('car-outline')}
                    <Text category="s1" style={styles.detailLabel}>Rego:</Text>
                    <Text category="s2" style={styles.detailValue}>{vehicle_details.rego}</Text>
                </View>
                <View style={styles.detailRow}>
                    {renderIcon('bookmark-outline')}
                    <Text category="s1" style={styles.detailLabel}>Make:</Text>
                    <Text category="s2" style={styles.detailValue}>{vehicle_details.make}</Text>
                </View>
                <View style={styles.detailRow}>
                    {renderIcon('pricetags-outline')}
                    <Text category="s1" style={styles.detailLabel}>Model:</Text>
                    <Text category="s2" style={styles.detailValue}>{vehicle_details.model}</Text>
                </View>
                <View style={styles.detailRow}>
                    {renderIcon('calendar-outline')}
                    <Text category="s1" style={styles.detailLabel}>Year:</Text>
                    <Text category="s2" style={styles.detailValue}>{vehicle_details.year}</Text>
                </View>
                <View style={styles.detailRow}>
                    {renderIcon('color-palette-outline')}
                    <Text category="s1" style={styles.detailLabel}>Colour:</Text>
                    <Text category="s2" style={styles.detailValue}>{vehicle_details.colour}</Text>
                </View>
            </View>
        )
    }


    const RenderEquipmentDetails = ({ equipment_details }: { equipment_details: JobEquipmentDetails }) => {
        return (
            <View style={styles.detailsContainer}>
                <Text category="s1">Equipment Details</Text>
                <View style={styles.detailRow}>
                    <Icon name="hash-outline" style={styles.detailIcon} fill={theme['text-basic-color']} />
                    <Text category="s1" style={styles.detailLabel}>Serial:</Text>
                    <Text category="s2" style={styles.detailValue}>{equipment_details.serial}</Text>
                </View>
                <View style={styles.detailRow}>
                    <Icon name="cube-outline" style={styles.detailIcon} fill={theme['text-basic-color']} />
                    <Text category="s1" style={styles.detailLabel}>Size:</Text>
                    <Text category="s2" style={styles.detailValue}>{equipment_details.size}</Text>
                </View>
                <View style={styles.detailRow}>
                    <Icon name="cube-outline" style={styles.detailIcon} fill={theme['text-basic-color']} />
                    <Text category="s1" style={styles.detailLabel}>Type:</Text>
                    <Text category="s2" style={styles.detailValue}>{equipment_details.type}</Text>
                </View>
                <View style={styles.detailRow}>
                    <Icon name="trending-up-outline" style={styles.detailIcon} fill={theme['text-basic-color']} />
                    <Text category="s1" style={styles.detailLabel}>Weight:</Text>
                    <Text category="s2" style={styles.detailValue}>{equipment_details.weight}</Text>
                </View>
            </View>
        )
    }






    const renderTowingInfo = () => {
        if (!jobState.details.options.towing_job_options) return null;
        const towing_details = jobState.details.towing_details;
        if (!towing_details) return null;
        const vehicle_details = towing_details.vehicle_details || new JobVehicleDetails();
        const equipment_details = towing_details.equipment_details || new JobEquipmentDetails();
        const towing_type = towing_details.towing_type.toLowerCase(); // vehicle or equipment

        const towingDetails = jobState.details.towing_details;
        if (!towingDetails) return null;

        const formatTime = (time: number | null) => {
            if (!time) return 'N/A';
            return formatTimestamp(new Date(time));
        };

        // get towing reason
        const getLocationDetail = (towingLocation: TowingLocation) => {
            const location = new TowingLocation(towingLocation)
            let result = ""
            if (location?.location_type == "Address") {
                result += `Address: ${location?.address_type}`
            }
            else if (location?.location_type == "Holding") {
                result += `Holding: ${location?.holding_reason}`
            }
            return result
        }

        const buildDescription = (details: any, fields: any) => {
            if (!details) return "";
            return fields
                .map((field: any) => details[field] || "")
                .filter((value: any) => value !== "")
                .join(" | ");
        };

        let description = ""
        if (jobState.details.towing_details?.towing_type?.toLowerCase() === "equipment") {
            description = buildDescription(jobState.details.towing_details?.equipment_details, ["equipment", "serial", "size", "type", "weight"]);
        }

        if (jobState.details.towing_details?.towing_type?.toLowerCase() === "vehicle") {
            description = buildDescription(jobState.details.towing_details?.vehicle_details, ["make", "model", "rego", "type", "year", "colour"]);
        }



        return (
            <View style={styles.towingContainer}>
                <Text style={styles.towingTitle}>Towing Information</Text>
                <View style={{ flexDirection: 'row' }}>

                    <ScrollView style={styles.towingTableContainer}>
                        <View style={{ flexGrow: 1 }}>
                            <View style={styles.tableHeader}>
                                <Text style={styles.tableHeaderCell}>Location</Text>
                                <Text style={styles.tableHeaderCell}>Location Type</Text>
                                <Text style={[styles.tableHeaderCell, { flex: 3 }]}>Address</Text>
                                <Text style={styles.tableHeaderCell}>Time</Text>
                            </View>
                            {towingDetails.towing_locations.map((location, index) => (
                                <View key={index} style={{ flexDirection: "row", marginBottom: 10, alignItems: "center" }}>
                                    <Text style={styles.tableCell}>{location.name}</Text>
                                    <Text style={styles.tableCell}>{getLocationDetail(location)}</Text>
                                    <Text style={[styles.tableCell, styles.addressCell]}>{location.address.addressToString()}</Text>
                                    <View style={styles.tableCell}>
                                        <View style={[styles.timePill, location.time ? styles.greenPill : styles.grayPill]}>
                                            <Text style={styles.timeValue}>
                                                {formatTime(location.time)}
                                            </Text>
                                        </View>
                                    </View>
                                </View>
                            ))}
                        </View>
                    </ScrollView>
                </View>
                <View style={styles.towingFooter}>
                    <View style={styles.footerItem}>
                        <Icon name="navigation-2-outline" style={styles.footerIcon} fill="#FFF" />
                        <Text>{towingDetails.distance_kms} km</Text>
                    </View>
                    <View style={styles.footerItem}>
                        <Icon name="clock-outline" style={styles.footerIcon} fill="#fff" />
                        <Text>{Math.round(towingDetails.duration_seconds / 60)} mins</Text>
                    </View>
                </View>
            </View>
        );
    };



    const [menuVisible, setMenuVisible] = useState(false);

    const toggleMenu = () => {
        setMenuVisible(!menuVisible);
    };

    const handleUnassign = () => {
        console.log('Unassign action for job:', jobState.friendly_id);
        JobController.setJobUnassigned(jobState);
        toggleMenu();
    };

    const handleAssign = () => {
        console.log('Assign action for job:', jobState.friendly_id);
        //open assign modal
        onAssign(jobState);
        toggleMenu();
    };

    const handleSetPending = () => {
        console.log('Set Pending action for job:', jobState.friendly_id);
        // Placeholder: Implement set pending logic here
        JobController.setJobPending(jobState);
        toggleMenu();
    };

    const handleComplete = () => {
        console.log('Complete action for job:', jobState.friendly_id);
        JobController.setJobComplete(jobState);
        // Placeholder: Implement complete logic here
        toggleMenu();
    };

    const handleCancel = () => {
        console.log('Cancel action for job:', jobState.friendly_id);
        JobController.cancelJob(jobState._id);
        // Placeholder: Implement cancel logic here
        toggleMenu();
    };

    const handleCopy = () => {
        console.log('Copy action for job:', jobState.friendly_id);
        // Placeholder: Implement copy logic here
        setShowCopyModal(true);
        toggleMenu();
    };

    const handleTransfer = () => {
        console.log('Transfer action for job:', jobState.friendly_id);
        // Placeholder: Implement transfer logic here
        setShowTransferModal(true);
        toggleMenu();
    };

    const handleVendorAccept = (jobId: string, eta: number) => {
        console.log(`Vendor accepted job ${jobId} with ETA: ${eta} minutes`);
        setShowVendorAcceptModal(false);
    };

    const renderRequestButton = () => {
        let showBtn = true;
        if (StorageController.getAppState().selectedMembership?.is_client) {
            showBtn = false;
        }
        if (jobState.is_client_created && StorageController.getCurrentCompany()._id != jobState.company_id) {
            showBtn = false;
        }
        if (showBtn) {
            return (
                <Button
                    size='tiny'
                    status="warning"
                    appearance="outline"
                    onPress={() => setShowVendorAcceptModal(true)}>
                    Accept/Reject
                </Button>
            );
        } else {
            return (
                <Text category="c1">Awaiting Vendor Accept...</Text>
            );
        }
    };

    const renderMenuAnchor = () => (
        <Button
            size="tiny"
            appearance="ghost"
            accessoryLeft={renderIcon('more-vertical')}
            onPress={toggleMenu}
        />

    );

    const renderNonDispatchableLabel = () => {
        if (!jobState.dispatchable) {
            return (
                <View style={styles.nonDispatchableContainer}>
                    <Text style={styles.nonDispatchableText}>Non-Dispatchable</Text>
                </View>
            );
        }
        return null;
    };




    const RenderStartTime = () => {
        const shortTime = new Date(jobState.start_time).toLocaleTimeString('en-AU', { day: '2-digit', month: '2-digit', hour: '2-digit', minute: '2-digit', hour12: true });
        const longTime = new Date(jobState.start_time).toLocaleTimeString('en-AU', { day: '2-digit', month: 'short', hour: '2-digit', minute: '2-digit', hour12: true });
        const padding = USE_SMALL_WIDTH ? 1 : 3;
        const fontSize = USE_SMALL_WIDTH ? 10 : 12;
        const formattedTime = USE_SMALL_WIDTH ? shortTime : longTime;
        const isScheduled = jobState.is_scheduled
        return (
            <>
                {isScheduled &&
                    <View style={{ position: 'absolute', top: -10, left: 0, flexDirection: 'row', alignItems: 'center', gap: 5, backgroundColor: 'rgba(0,30,0,0.5)', padding: 1, borderRadius: 5 }}>
                        {renderIcon('calendar-outline')}
                        <Text style={{ fontSize: 8 }}>Scheduled</Text>
                    </View>
                }
                <Text style={[styles.startTimePill, { fontSize: fontSize, padding: padding }]} numberOfLines={1} category="s1">
                    {jobState.start_time ? formattedTime : "N/A"}
                </Text>
            </>
        )
    }
    const RenderClientName = () => {
        const fontSize = USE_SMALL_WIDTH ? 15 : 15
        return <Text category="s1" style={[{ fontSize: fontSize }]} numberOfLines={1}>{client.name || "N/A"}</Text>
    }

    const RenderServices = () => {
        const services = jobState.details.getServicesNames()
        const fontSize = USE_SMALL_WIDTH ? 10 : 15
        return <Text category="s1" style={{ fontSize: fontSize }} numberOfLines={1}>{services || "N/A"}</Text>
    }



    const renderExpandedContent = () => {
        return (
            <Layout style={[styles.expandedRow, { gap: 10 }]}>
                <RenderCustomerDetails customer_details={jobState.details.customer_details} />
                <RenderTimeDetails />
                <RenderVehicleOrEquipmentDetails job={jobState} />
                {jobState.details.options.towing_job_options &&
                    renderTowingInfo()
                }
            </Layout>
        );
    };

    const containerMaxHeight = expanded ? 1000 : 63
    // const paddingVertical = containerWidth < 900 ? 4 : 8
    const paddingTop = USE_SMALL_WIDTH ? 2 : 4
    const paddingBottom = USE_SMALL_WIDTH ? 2 : 4



    const RenderHeader = () => {
        const marginVertical = USE_SMALL_WIDTH ? 3 : 4
        if (!USE_SMALL_WIDTH) {
            return (
                <View style={styles.headerContainer}>
                    <View style={styles.headerItem}>
                        <Badge status={jobState.status as JOB_STATUS} style={{ width: 100 }} animated={false} />
                    </View>
                    <View style={styles.headerItem}>
                        <RenderClientName />
                    </View>
                    <View style={styles.headerItem}>
                        <RenderServices />
                    </View>
                    <View style={[styles.headerItem, { flexDirection: 'row', alignItems: 'center' }]}>
                        <RenderStartTime />
                    </View>
                    <View style={styles.headerItem}>
                        <RenderSmallVehicleOrEquipmentDetails job={jobState} />
                    </View>
                    <View style={styles.headerItem}>
                        {jobState.status == JOB_STATUS.REQUEST && renderRequestButton()}
                    </View>
                    <View style={[styles.headerItem, { flex: 2 }]}>
                        {renderTimesSummary()}
                    </View>
                    <View style={styles.headerItem}>
                        <View style={styles.driverHeader}>
                            {renderDriverStatus()}
                        </View>
                    </View>
                </View>
            );
        } else {
            return (
                <View>
                    <View style={[styles.headerContainer, { marginVertical: marginVertical }]}>
                        <View style={styles.headerItem}>
                            <Badge status={jobState.status as JOB_STATUS} style={{ width: 100 }} animated={false} />
                        </View>
                        <View style={[styles.headerItem, { paddingVertical: 0 }]}>
                            <RenderClientName />
                        </View>
                        <View style={[styles.headerItem, { paddingVertical: 0 }]}>
                            {jobState.status == JOB_STATUS.REQUEST && renderRequestButton()}
                        </View>
                        <View style={[styles.headerItem, { paddingVertical: 0 }]}>
                            <View style={[styles.driverHeader, { paddingVertical: 0 }]}>
                                {renderDriverStatus()}
                            </View>
                        </View>
                    </View>
                    <View style={styles.headerContainer}>
                        <View style={styles.headerItem}>
                            <RenderStartTime />
                        </View>
                        <View style={styles.headerItem}>
                            <RenderServices />
                        </View>
                        <View style={[styles.headerItem, { flex: 2 }]}>
                            {renderTimesSummary()}
                        </View>
                    </View>
                </View>
            );
        }
    };

    const RenderAddressRow = () => {
        const marginVertical = USE_SMALL_WIDTH ? 0 : 4
        return (
            <View style={[styles.headerContainer, { marginVertical: marginVertical }]}>
                <View style={{ flex: 8 }}>
                    {renderAddressInfo()}
                </View>
                <View style={{
                    flex: 1,
                    flexDirection: 'row',
                    height: 14,
                    alignItems: 'center',
                    backgroundColor: 'rgba(115,80,0,0.3)',
                    borderRadius: 5,
                    borderColor: 'rgba(115,80,0,0.8)',
                    borderWidth: 1,
                }}>
                    {/* open details button */}
                    <Button
                        style={{ width: "100%" }}
                        size="tiny" appearance="ghost" onPress={() => onViewDetails(jobState)}>
                        Details
                    </Button>
                </View>
            </View>
        );
    };


    const renderMenuItems = () => {
        const items = [];

        // View Details is always available
        // View Details is always available
        items.push(
            <MenuItem key="viewDetails" title='View Details' accessoryLeft={renderIcon('eye-outline')} onPress={() => { toggleMenu(); onViewDetails(jobState); }} />
        );

        // Copy is available for all statuses except REQUEST
        if (jobState.status !== JOB_STATUS.REQUEST) {
            items.push(
                <MenuItem key="copy" title='Copy' accessoryLeft={renderIcon('copy-outline')} onPress={handleCopy} />
            );
        }

        if (jobState.status === JOB_STATUS.REQUEST) {
            items.push(
                <MenuItem key="accept" title='Accept/Decline' accessoryLeft={renderIcon('checkmark-circle-2-outline')} onPress={() => setShowVendorAcceptModal(true)} />,
            );
        } else if (jobState.status === JOB_STATUS.UNASSIGNED) {
            items.push(
                <MenuItem key="assign" title='Assign' accessoryLeft={renderIcon('person-add-outline')} onPress={handleAssign} />,
                <MenuItem key="cancel" title='Cancel Job' accessoryLeft={renderIcon('close-circle-outline')} onPress={handleCancel} />,
                <MenuItem key="transfer" title='Transfer' accessoryLeft={renderIcon('swap-outline')} onPress={handleTransfer} />
            );
        } else if (jobState.status === JOB_STATUS.ASSIGNED) {
            items.push(
                <MenuItem key="unassign" title='Unassign' accessoryLeft={renderIcon('person-remove-outline')} onPress={handleUnassign} />,
                <MenuItem key="reassign" title='Reassign' accessoryLeft={renderIcon('repeat-outline')} onPress={handleAssign} />,
                <MenuItem key="setPending" title='Set Pending' accessoryLeft={renderIcon('clock-outline')} onPress={handleSetPending} />,
                <MenuItem key="cancel" title='Cancel Job' accessoryLeft={renderIcon('close-circle-outline')} onPress={handleCancel} />,
                <MenuItem key="transfer" title='Transfer' accessoryLeft={renderIcon('swap-outline')} onPress={handleTransfer} />
            );
        } else if (jobState.status === JOB_STATUS.PENDING) {
            items.push(
                <MenuItem key="reassign" title='Reassign' accessoryLeft={renderIcon('repeat-outline')} onPress={handleAssign} />,
                <MenuItem key="complete" title='Complete' accessoryLeft={renderIcon('checkmark-circle-2-outline')} onPress={handleComplete} />,
                <MenuItem key="cancel" title='Cancel Job' accessoryLeft={renderIcon('close-circle-outline')} onPress={handleCancel} />
            );
        }



        return items;
    };


    return (
        <View
            style={
                [styles.container,
                { backgroundColor: selectedState ? theme['color-success-800'] : 'transparent' },
                {
                    // maxHeight: containerMaxHeight,
                    // paddingVertical: paddingVertical,
                    paddingTop: paddingTop,
                    paddingBottom: paddingBottom
                }]}>
            {renderNonDispatchableLabel()}
            {showTransferModal &&
                <TransferJobComponent job={jobState} onTransfer={() => setShowTransferModal(false)} showModalDirect={true} onClose={() => setShowTransferModal(false)} />
            }
            {showCopyModal &&
                <CopyJobComponent job={jobState} onCopy={() => setShowCopyModal(false)} onClose={() => setShowCopyModal(false)} showModalDirect={true} />
            }
            <VendorAcceptModal
                showModal={showVendorAcceptModal}
                job={jobState}
                onAccept={handleVendorAccept}
                onClose={() => setShowVendorAcceptModal(false)}
            />
            <View style={{ flexDirection: 'row' }}>
                <CheckBox
                    checked={selectedState}
                    onChange={handleCheckboxChange}
                    style={styles.checkbox}
                />
                <TouchableOpacity style={{ flex: 1 }} onPress={toggleExpand}>
                    {/* <RenderHeaderRowTop />
                    <RenderHeaderRowMiddle />
                    <RenderHeaderRowBottom /> */}
                    <RenderHeader />
                    <RenderAddressRow />
                </TouchableOpacity>
                <OverflowMenu
                    anchor={renderMenuAnchor}
                    visible={menuVisible}
                    onBackdropPress={toggleMenu}
                >
                    {renderMenuItems()}
                </OverflowMenu>
                {selectedJobIdState &&
                    <View style={{ position: 'absolute', top: 0, right: 0, bottom: 0, backgroundColor: theme['color-primary-500'], width: 5 }} />
                }
            </View>
            <Animated.View style={[styles.expandedContent, { maxHeight: maxAnimatedHeight }]}>
                {expanded &&
                    <>
                        {renderExpandedContent()}
                        <View style={styles.actions}>
                            <Button size="small" onPress={() => handleAssign()}>
                                {jobState.member_id ? 'Reassign' : 'Assign'}
                            </Button>
                            <Button
                                size="small" appearance="outline" onPress={() => onViewDetails(jobState)}>
                                View Details
                            </Button>
                        </View>
                    </>
                }
            </Animated.View >
        </View >
    );
}, (prevProps, nextProps) => {
    // Custom comparison function if all are true it wont re render
    return prevProps.job._id === nextProps.job._id &&
        prevProps.isSelected === nextProps.isSelected &&
        prevProps.containerWidth === nextProps.containerWidth &&
        prevProps.job.updatedAt === nextProps.job.updatedAt &&
        prevProps.job.status === nextProps.job.status &&
        prevProps.job.member_id === nextProps.job.member_id &&
        prevProps.selectedJobId === nextProps.selectedJobId
});
const styles = StyleSheet.create({
    detailsContainer: {
        // flex: 1,
        backgroundColor: 'rgba(20, 0, 0, 0.2)',
        borderRadius: 8,
        padding: 8,
        flexBasis: 560,
        // minWidth: 250,
        // marginTop: 8,
    },
    towingContainer: {
        // marginTop: 12,
        flexGrow: 1,
        padding: 12,
        backgroundColor: 'rgba(0, 0, 30, 0.3)',
        borderRadius: 8,
        flexBasis: 550,
        // minWidth:500
    },
    headerContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 4,
    },
    headerItem: {
        flex: 1,
        paddingHorizontal: 4,
    },
    container: {
        borderBottomWidth: 1,
        borderBottomColor: '#E8E8E8',
        paddingVertical: 6,
        // width: 900,
        minHeight: 75
    },
    // headerContainer: {
    //     flexDirection: 'row',
    //     justifyContent: 'space-between',
    //     alignItems: 'flex-start',
    // },
    basicInfo: {
        flexDirection: 'row',
        alignItems: 'center',
        gap: 5,
    },
    startTimePill: {
        flex: 1,
        alignItems: 'center',
        backgroundColor: 'rgba(0, 155, 0, 0.4)',
        borderRadius: 12,
        padding: 1,
        alignSelf: 'center',
        textAlign: 'center',
    },
    row: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    checkbox: {
        marginRight: 8,
    },
    header: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    driverHeader: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 4,
        backgroundColor: 'rgba(10,0,55,0.6)',
        padding: 5,
        borderRadius: 5,
    },
    driverStatus: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    onlineIndicator: {
        width: 8,
        height: 8,
        borderRadius: 4,
        marginRight: 4,
    },
    jobId: {
        marginLeft: 8,
        fontWeight: 'bold',
        flex: 1
    },
    clientName: {
        marginLeft: 8,
        flex: 1,
    },

    address: {
        flex: 1,
        marginRight: 8,
    },
    icon: {
        width: 16,
        height: 16,
        marginRight: 4,
    },
    expandedContent: {
        overflow: 'hidden',
    },
    expandedRow: {
        flexWrap: 'wrap',
        flexDirection: 'row',
        // alignItems: 'center',
        marginTop: 4,
    },
    actions: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 8,
    },

    timesContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        // marginTop: 8,

        alignItems: 'center',
        paddingHorizontal: 4,
        flex: 1,
    },
    timeItem: {
        alignItems: 'center',
        flex: 1,
        marginHorizontal: 2,
    },
    timeLabel: {
        color: 'gray',
        marginBottom: 2,
    },
    timePill: {
        paddingHorizontal: 4,
        paddingVertical: 2,
        borderRadius: 12,
        width: '100%',
        alignItems: 'center',
    },
    greenPill: {
        backgroundColor: 'rgba(0, 255, 0, 0.2)',
    },
    redPill: {
        backgroundColor: 'rgba(255, 0, 0, 0.2)',
    },
    timeValue: {
        fontWeight: 'bold',
        color: 'white',
        fontSize: 10,
    },
    collapsedTowingContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: 4,
        gap: 4,
        // flexWrap: 'wrap', // Allow wrapping for small widths
    },
    locationItem: {
        flexDirection: 'row',
        alignItems: 'center',
        flex: 1,
    },
    locationIcon: {
        width: 16,
        height: 16,
        marginRight: 4,
        flexShrink: 0, // Prevent icon from shrinking
    },
    locationText: {
        flex: 1,
        minWidth: 0, // Allow text to shrink below its content size
    },
    ellipsis: {
        marginHorizontal: 8,
        fontWeight: 'bold',
    },

    towingTitle: {
        marginBottom: 8,
        fontWeight: 'bold',
        fontSize: 16,
    },
    towingTableContainer: {
        maxHeight: 200, // Adjust this value as needed
    },
    towingTable: {
        flexGrow: 1,
    },
    tableHeader: {
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderBottomColor: '#CCCCCC',
        paddingBottom: 4,
        marginBottom: 4,
    },
    tableHeaderCell: {
        flex: 1,
        fontWeight: 'bold',
        paddingHorizontal: 4,
        fontSize: 14,
    },
    tableRow: {
        flexDirection: 'row',
        marginBottom: 4,
        alignItems: 'center',
    },
    tableCell: {
        flex: 1,
        paddingHorizontal: 4,
        fontSize: 12,
    },
    addressCell: {
        flex: 3,
    },

    grayPill: {
        backgroundColor: 'rgba(128, 128, 128, 0.2)',
    },

    towingFooter: {
        flexDirection: 'row',
        justifyContent: 'space-around',
        marginTop: 8,
        paddingTop: 8,
        borderTopWidth: 1,
        borderTopColor: '#CCCCCC',
    },
    footerItem: {
        flexDirection: 'row',
        alignItems: 'center',
        flexShrink: 0, // Prevent footer items from shrinking
    },
    footerIcon: {
        width: 16,
        height: 16,
        marginRight: 4,
        flexShrink: 0, // Prevent icon from shrinking
    },
    nonDispatchableContainer: {
        position: 'absolute',
        top: 0,
        left: '0%',
        // transform: [{ translateX: -50 }],
        backgroundColor: 'red',
        paddingHorizontal: 8,
        paddingVertical: 2,
        borderBottomLeftRadius: 8,
        borderBottomRightRadius: 8,
        zIndex: 1,
    },
    nonDispatchableText: {
        color: 'white',
        fontWeight: 'bold',
        fontSize: 8,
    },
    arrowIcon: {
        width: 16,
        height: 16,
        marginHorizontal: 4,
        flexShrink: 0, // Prevent icon from shrinking
    },
    collapedTowingLocationItem: {
        flexDirection: 'row',
        alignItems: 'center',
        width: 150,
        gap: 4,
        flexShrink: 1, // Allow shrinking for small widths
    },
    timeIcon: {
        width: 12,
        height: 12,
        marginLeft: 4,
    },

    detailRow: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 4,
    },
    detailIcon: {
        width: 20,
        height: 20,
        marginRight: 8,
    },
    detailLabel: {
        // flex: 1,
        width: 60,
        fontWeight: 'bold',
    },
    detailValue: {
        flex: 2,
    },
    requestButtonContainer: {
        // position: 'absolute',
        // top: 0,
        // right: 0,
        // overflow: 'hidden',
        // justifyContent: 'center',
    },
    smallDetailsContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
        borderRadius: 4,
        paddingHorizontal: 4,
        paddingVertical: 2,
    },
    smallDetailsIcon: {
        width: 16,
        height: 16,
        marginRight: 4,
    },
    smallDetailsText: {
        fontSize: 10,
        flex: 1,
    },
});