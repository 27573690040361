/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
//@ts-ignore
import { Image } from 'react-native';
import { Icon } from '@ui-kitten/components';
import { InputToolbar, Actions, Composer, Send } from 'react-native-gifted-chat';
//@ts-ignore
import { pickImageAsync } from './mediaUtils';

export const renderInputToolbar = (props: any) => (
    <InputToolbar
        {...props}
        containerStyle={{
            backgroundColor: '#141A29',
            paddingTop: 3,
            paddingLeft: 4,
            paddingBottom: 3
        }}
        primaryStyle={{ alignItems: 'center' }}
    />
);

export const renderActions = (props: any) => (
    <>

        <Actions
            {...props}
            containerStyle={{
                width: 44,
                height: 44,
                alignItems: 'center',
                justifyContent: 'center',
                marginLeft: 4,
                marginRight: 4,
                marginBottom: 0,
            }}
            // icon={() => (

            // )}
            options={{
                'Choose From Library': () => {
                    console.log('Choose From Library');
                },
                "Take Picture": () => {
                    console.log('Take Picture');
                },
                Cancel: () => {
                    console.log('Cancel');
                },
            }}
            onPressActionButton={(action: any) => {
                console.log('onPressActionButton', action);
                pickImageAsync(props);
            }}
            optionTintColor="#222B45"
        />
        {/* <Icon name="camera-outline" fill="white" width={32}  height={32}  /> */}
    </>
);

export const RenderComposer = (props: any) => {
    const [inputText, setInputText] = React.useState('');
    const handleOnChangeText = (text: string) => {
        setInputText(text);
    };

    const handleOnSubmitEditing = () => {
        if (inputText.trim() !== '') {
            props.fn_onSend([{ text: inputText.trim() }]);
            setInputText('');
        }
    };
    return (

        <Composer
            {...props}
            // text={inputText}
            // onTextChanged={handleOnChangeText}
            textInputStyle={{
                color: '#222B45',
                backgroundColor: '#EDF1F7',
                borderWidth: 1,
                borderRadius: 5,
                borderColor: '#E4E9F2',
                paddingTop: 8.5,
                paddingHorizontal: 12,
                marginLeft: 4,
            }}
            textInputProps={{
                // on enter submit
                ...props.textInputProps,
                onKeyPress: (e: any) => {
                    try {
                        if (e.nativeEvent.key === 'Enter') {
                            if (!e.nativeEvent.shiftKey) {
                                e.preventDefault();
                                props.onSend({ text: props.text.trim() }, true)
                            }
                        }
                    } catch (err) {
                        console.log(err)
                        props.onSend({ text: props.text }, true)
                    }
                },
            }}

        />
    )
};

// textInputProps={{
//     // on enter submit
//     ...props.textInputProps,
//     onKeyPress: (e) => {
//         console.log("🚀 ~ file: messageInputToolbar.component.js:76 ~ e:", e, props)

//         if (e.nativeEvent.key === 'Enter') {
//             e.preventDefault();
//             console.log('Enter');
//             // handleOnSubmitEditing();
//             props.onSend({ text: props.text.trim() }, true)
//         }
//     },
// }}

export const renderSend = (props: any) => (
    <Send
        {...props}
        disabled={!props.text}
        containerStyle={{
            width: 44,
            height: 44,
            alignItems: 'center',
            justifyContent: 'center',
            marginHorizontal: 4,
        }}
    >
        {/* <Image
      style={{ width: 32, height: 32 }}
      source={{
        uri: 'https://placeimg.com/32/32/any',
      }}
    /> */}
        <Icon name="paper-plane-outline" fill="white" width={32} height={32} />
    </Send>
);
