import React, { useState, useEffect, useRef, useReducer, useMemo, useCallback } from 'react';
//@ts-ignore
import { useWindowDimensions, StyleSheet, View, TouchableOpacity, ScrollView } from "react-native";
import { SafeAreaView } from 'react-native-safe-area-context';
//@ts-ignore
import { Divider, Icon, Layout, Text, TopNavigation, TopNavigationAction, Card, Button, Input, ButtonGroup, CheckBox, Modal, RangeDatepicker, Datepicker, Toggle } from '@ui-kitten/components';
import moment from 'moment';
import Slider, { SliderProps } from '@react-native-community/slider';
import * as StorageController from '../../functions/storageController'
import * as JobController from '../../functions/job.controller'
import * as InvoiceController from '../../functions/invoice.controller'
import * as UpdateController from '../../functions/update.controller'
import * as ServiceController from '../../functions/service.controller'
import ErrorBoundary from '../ErrorBoundary.component';
import _, { debounce, min, set } from 'lodash';

import { Chart as ChartJS, ArcElement, Tooltip, Legend, BarElement, CategoryScale, LinearScale, Title, TimeScale, LineElement, PointElement } from "chart.js";
import { Doughnut, Bar, Line } from "react-chartjs-2";
// import 'chartjs-adapter-date-fns';
import 'chartjs-adapter-moment';
import { JOB_STATUS, Job, Service } from '../../models/Job.model';
import { ReportDetailsCard } from '../job/details/reportDetailsCard.component';
import { InvoicedTotalCard } from '../job/details/jobInvoicedTotalDetailsCard.component';
import { DashboardMap } from './dashboardMap.component';
import { RenderSearchJobsByDateRange } from './searchArea.component';
import { Client } from '../../models/Client.model';
import { Member } from '../../models/Member.model';
import { DailyBreakdown } from './dailyBreakdown.component';
import { useTheme } from '../../context/theme.context';
import { WeeklySummaries } from './weeklysummaries.component';
import {
    calculatePercentage,
    formatTime,
    formatDateTime,
    convertToTime,
    calculateTotalCalloutFees,
    countJobsArrivedWithinTime,
    calculateAverageCreatedToPendingTime,
    calculateAverageArrivalToCompleteTime,
    calculateTotalBillBackItemsCost,
    calculateTotalCustomerItemsCost,
    getLast10Weeks,
    calculateAverageJobTime,
    calculateAverageCreatedToArrivalTime,
    namedColor,
    getDaysBetweenDates,
    countNumberOfValidArrvalTimeJobs
} from "./dashboardUtils"
import { ImageDetailsCard } from '../job/details/imageDetailsCard.component';
import { LogDetailsCard } from '../job/details/logDetailsCard.component';
import { JobReportModal } from './jobReportModal.component';
import { TimeFilter } from './timeFilter.component';
import { DriversFilterList } from './driversFilterList.component';
import { ServicesFilterList } from './servicesFilterList.component';
import { Filters, FILTER_TYPE } from '../invoicing/Filters';
import { Company } from '../../models/Company.model';
import { useAppStateChange, IAction } from '../../hooks/appStateChange.hook';
import { VendorProfile } from '../../models/VendorProfile.model';
import * as VendorProfileController from '../../functions/vendorProfile.controller';



export interface DashboardWeeklySummary {
    startDate: Date;
    endDate: Date;
    averageJobTime: number;
    averageTimeOfArrival: number;
    totalCalloutFees: number;
    totalNumberOfJobs: number;
    jobsUnder45Mins: number;
    jobsUnder60Mins: number;
    jobsUnder90Mins: number;
}

export interface DashboardReport {
    dateRange: { start: Date, end: Date },
    averageJobTime: number,
    totalCalloutFees: number,
    totalNumberOfJobs: number,
    weeklySummaries: DashboardWeeklySummary[]
}

export interface DailySummary {
    date: Date;
    numberOfCancelledOrTransferredJobs: number;
    totalNumberOfCompletedJobs: number;
    averageJobTime: number;
    totalCalloutFees: number;
    totalNumberOfJobs: number;
    averageJobArrivalTime: number;
    averajeJobPendingTime: number;
    averageServiceTime: number;
    jobsUnder45Mins: number;
    jobsUnder60Mins: number;
    jobsUnder90Mins: number;
    jobs: Job[];
}


ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    TimeScale,
    LineElement,
    PointElement,
);

// let dashboardJobs = [];

// const setDashboardJobs = (jobs) => {
//     // dashboardJobs = jobs;
// }

// const getDashboardJobs = () => {
//     return dashboardJobs;
// }


const DashboardScreen = ({ navigation }: any) => {
    // const [jobs, setJobs] = useState([]);
    const [x, forceUpdate] = useReducer(x => x + 1, 0);
    const pivot = useRef(null);
    const { theme } = useTheme();

    const clientsRef = useRef([] as Client[]);
    // memebers list
    const driversRef = useRef([] as Member[]);
    const [drivers, setDrivers] = useState([] as Member[]);
    // services
    const servicesRef = useRef([] as Service[]);
    // summaries
    const [reports, setReports] = useState({} as any)
    const m_reports = useMemo(() => reports, [reports]);

    const [eventsRegistered, setEventsRegistered] = useState(false);

    // const [startTime, setStartTime] = useState(0); // mins in day
    // const [endTime, setEndTime] = useState(1440); // mins in day
    const startTimeRef = useRef(0 as any);
    const endTimeRef = useRef(1440 as any);
    const startTimeStringRef = useRef("00:00" as any);
    const endTimeStringRef = useRef("24:00" as any);
    const [todayReport, setTodayReport] = useState({});

    // memoize the plot data
    const [plotData, setPlotData] = useState([] as Job[]);
    const onSetPlotData = (data: Job[]) => {
        data = data.map(d => new Job(d))
        setPlotData(data);
        const cancelledJobs = data.filter(job => job.status === JOB_STATUS.CANCELLED);
        console.log("🚀============== ~ file: DashboardScreen.component.tsx:154 ~ onSetPlotData ~ cancelledJobs🚀==============", cancelledJobs)
        loadDailySummary();
    }
    const [fetchedData, setFetchedData] = useState([] as Job[]);
    const m_plotData = useMemo(() => plotData, [plotData]);

    const [showReportDetailsModal, setShowReportDetailsModal] = React.useState(false);
    const [selectedJob, setSelectedJob] = useState(null as Job | null);


    const clientsCheckedRef = useRef([] as Client[]);
    const driversCheckedRef = useRef([] as Member[]);
    const servicesCheckedRef = useRef([] as any[]);
    const statusesCheckedRef = useRef([] as any[]);
    const [statusesChecked, setStatusesChecked] = useState([] as any[]); // statuses checked in filter
    const [servicesChecked, setServicesChecked] = useState([] as any[]); // services checked in filter
    const [statuses, setStatuses] = useState([]); // statuses checked in filter
    const [clientsChecked, setClientsChecked] = useState([] as Client[]); // statuses checked in filter
    const [driversChecked, setDriversChecked] = useState([] as Member[]); // statuses checked in filter

    const [selectedCompany, setSelectedCompany] = useState(StorageController.getCurrentCompany() as Company);

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [dateRange, setDateRange] = React.useState({ startDate: new Date(), endDate: new Date() });
    const dateRangeRef = useRef(dateRange);

    const [vendorProfilesChecked, setVendorProfilesChecked] = useState([] as VendorProfile[]);
    const vendorProfilesCheckedRef = useRef([] as VendorProfile[]);
    const vendorProfilesRef = useRef([] as VendorProfile[]);

    useEffect(() => {
        dateRangeRef.current = dateRange;
    }, [dateRange]);


    const onShowReportDetailsModal = async (job: Job) => {
        setSelectedJob(job);
        setShowReportDetailsModal(true);
    }







    useEffect(() => {
        const unsubscribe = navigation.addListener('focus', () => {
            // console.log("DashboardScreen focus");
            if (!eventsRegistered) {
                setEventsRegistered(true);
            }
            load();
        });
        return () => {
            unsubscribe();
        }
    }, [navigation]);

    const load = async () => {
        handlePreviousWeek();
        loadLast10Weeks();
        await loadClients();
        await loadDrivers();
        await loadServices();
        await loadVendorProfiles();
        forceUpdate();
    }

    const reset = () => {
        clientsCheckedRef.current = [];
        driversCheckedRef.current = [];
        servicesCheckedRef.current = [];
        statusesCheckedRef.current = [];
        vendorProfilesCheckedRef.current = [];
        clientsRef.current = [];
        driversRef.current = [];
        servicesRef.current = [];
        setStatusesChecked([]);
        setServicesChecked([]);
        setVendorProfilesChecked([]);
        setPlotData([]);
        setFetchedData([]);
        setReports({});
        // forceUpdate();
    }




    const loadLast10Weeks = async () => {
        const last10Weeks = getLast10Weeks();
        const jobs = await InvoiceController.getJobsByDateRange(last10Weeks[last10Weeks.length - 1].end, last10Weeks[0].start);
        if (!jobs || jobs.length === 0) return;
        const averageJobTime = calculateAverageJobTime(jobs);
        const totalCalloutFees = calculateTotalCalloutFees(jobs);
        const totalNumberOfJobs = jobs.length;

        const weeklySummaries = [] as DashboardWeeklySummary[];
        for (const week of last10Weeks) {
            const weekStartDate = week.start;
            const weekEndDate = week.end;

            const weeklyJobs = jobs.filter((job) => {
                if (!job.pending_time) return false;
                return job.pending_time >= weekStartDate.getTime() && job.pending_time <= weekEndDate.getTime()
            }
            );

            const weeklyAverageJobTime = calculateAverageJobTime(weeklyJobs);
            const weeklyAverageTimeOfArrival = calculateAverageCreatedToArrivalTime(weeklyJobs);
            const weeklyTotalCalloutFees = calculateTotalCalloutFees(weeklyJobs);
            const jobsUnder45Mins = countJobsArrivedWithinTime(weeklyJobs, 45);
            const jobsUnder60Mins = countJobsArrivedWithinTime(weeklyJobs, 60);
            const jobsUnder90Mins = countJobsArrivedWithinTime(weeklyJobs, 90);

            const totalNumberOfValidArrvalTimeJobs = countNumberOfValidArrvalTimeJobs(weeklyJobs);
            const weeklyTotalNumberOfJobs = weeklyJobs.length;

            weeklySummaries.push({
                startDate: weekStartDate,
                endDate: weekEndDate,
                averageJobTime: weeklyAverageJobTime,
                averageTimeOfArrival: weeklyAverageTimeOfArrival,
                totalCalloutFees: weeklyTotalCalloutFees,
                totalNumberOfJobs: totalNumberOfValidArrvalTimeJobs,
                jobsUnder45Mins: jobsUnder45Mins,
                jobsUnder60Mins: jobsUnder60Mins,
                jobsUnder90Mins: jobsUnder90Mins,
            });
        }
        const report: DashboardReport = {
            dateRange: { start: startDate, end: endDate },
            averageJobTime: averageJobTime,
            totalCalloutFees: totalCalloutFees,
            totalNumberOfJobs: totalNumberOfJobs,
            weeklySummaries: weeklySummaries
        };
        // reports.current = report;
        setReports(report);
    }





    const loadDailySummary = () => {
        const dailySummary = [] as DailySummary[];
        const days = getDaysBetweenDates(startDate, endDate);

        for (let i = 0; i < days.length; i++) {
            const dayStart = new Date(days[i].setHours(0, 0, 0, 0)).getTime();
            const dayEnd = new Date(days[i].setHours(23, 59, 59, 999)).getTime();

            const dayJobs = m_plotData.filter(job => {
                // if (!job.pending_time) return false;
                // if (!job.dispatchable) return false;
                if(job.pending_time){
                    return job.pending_time >= dayStart && job.pending_time <= dayEnd;    
                }else{
                    return job.start_time >= dayStart && job.start_time <= dayEnd;    
                }
            });
            const totalNumberOfValidArrvalTimeJobs = countNumberOfValidArrvalTimeJobs(dayJobs);

            const numberOfCancelledOrTransferredJobs = dayJobs.filter(job => job.status === JOB_STATUS.CANCELLED || job.status === JOB_STATUS.TRANSFERRED_OUT).length;
            const totalNumberOfCompletedJobs = dayJobs.filter(job => job.status === JOB_STATUS.COMPLETE).length;

            const averageJobTime = calculateAverageJobTime(dayJobs);
            const totalCalloutFees = calculateTotalCalloutFees(dayJobs);
            const averageCreatedToArrivalTime = calculateAverageCreatedToArrivalTime(dayJobs)
            const calculateCreatedToPendingTime = calculateAverageCreatedToPendingTime(dayJobs)
            const averageArrivalToCompleteTime = calculateAverageArrivalToCompleteTime(dayJobs)
            const jobsUnder45Mins = countJobsArrivedWithinTime(dayJobs, 45);
            const jobsUnder60Mins = countJobsArrivedWithinTime(dayJobs, 60);
            const jobsUnder90Mins = countJobsArrivedWithinTime(dayJobs, 90);
            const totalNumberOfJobs = dayJobs.length;
            dailySummary.push({
                date: days[i],
                numberOfCancelledOrTransferredJobs: numberOfCancelledOrTransferredJobs,
                totalNumberOfCompletedJobs: totalNumberOfCompletedJobs,
                averageJobTime: averageJobTime,
                totalCalloutFees: totalCalloutFees,
                totalNumberOfJobs: totalNumberOfJobs,
                averageJobArrivalTime: averageCreatedToArrivalTime,
                averajeJobPendingTime: calculateCreatedToPendingTime,
                averageServiceTime: averageArrivalToCompleteTime,
                jobsUnder45Mins: jobsUnder45Mins,
                jobsUnder60Mins: jobsUnder60Mins,
                jobsUnder90Mins: jobsUnder90Mins,
                jobs: dayJobs
            });
        }
        console.log("🚀============== ~ file: DashboardScreen.component.tsx:346 ~ loadDailySummary ~ dailySummary🚀==============", dailySummary)
        // setDailySummary(dailySummary);
        return dailySummary;
    };



    // load jobs today
    useMemo(async () => {
        const today = new Date();
        const start = new Date().setHours(0, 0, 0, 0);
        const end = new Date().setHours(23, 59, 59, 999);
        const jobs = await InvoiceController.getJobsByDateRange(start, end)
        if (!jobs || jobs.length === 0) return;
        const nextJobsFiltered = jobs.filter((job) => {
            if (
                clientsCheckedRef.current.length > 0 &&
                !clientsCheckedRef.current.find((c) => c._id === job.client_id)
            ) {
                return false;
            }
            if (
                driversCheckedRef.current.length > 0 &&
                !driversCheckedRef.current.find((d) => d._id === job.member_id)
            ) {
                return false;
            }
            if (servicesCheckedRef.current.length > 0) {

                const hasSelectedService = job.details.selected_services?.some(
                    (service) =>
                        servicesCheckedRef.current.some(
                            (selectedService) => selectedService._id === service._id
                        )
                );

                if (!hasSelectedService) {
                    return false;
                }
            }
            if (
                statusesCheckedRef.current.length > 0 &&
                !statusesCheckedRef.current.includes(job.status)
            ) {
                return false;
            }
            if (vendorProfilesCheckedRef.current.length > 0) {
                const matchingVendorProfile = vendorProfilesCheckedRef.current.find(vp => {
                    if (job.vendor_company_id && vp.linkedVendorAccountId) {
                        return vp.linkedVendorAccountId === job.vendor_company_id;
                    }
                    if (job.vendor_profile_id) {
                        return vp._id === job.vendor_profile_id;
                    }
                    return false;
                });
                if (!matchingVendorProfile) {
                    return false;
                }
            }
            return true;
        });




        // create report object
        const averageJobTime = calculateAverageJobTime(nextJobsFiltered);
        const totalCalloutFees = calculateTotalCalloutFees(nextJobsFiltered);
        const totalNumberOfJobs = nextJobsFiltered.length;
        const report = {
            date: today,
            averageJobTime: averageJobTime,
            totalCalloutFees: totalCalloutFees,
            totalNumberOfJobs: totalNumberOfJobs,
        };

        setTodayReport(report);
        return report;
    }, [m_plotData])




    const RenderRangeSummary = useCallback(() => {

        const jobsCompleteWithin45Mins = countJobsArrivedWithinTime(m_plotData, 45);
        const jobsCompleteWithin60Mins = countJobsArrivedWithinTime(m_plotData, 60);
        const jobsCompleteWithin90Mins = countJobsArrivedWithinTime(m_plotData, 90);

        const totalNumberOfValidArrvalTimeJobs = countNumberOfValidArrvalTimeJobs(m_plotData);
        console.log("🚀============== ~ file: DashboardScreen.component.tsx:454 ~ RenderRangeSummary ~ m_totalJobs🚀==============", m_totalJobs, m_plotData)
        return (
            <Layout style={{ padding: 20, borderWidth: 1, borderColor: '#ccc', borderRadius: 4, marginBottom: 10 }}>
                <Layout style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: 10, borderBottomWidth: 1, borderBottomColor: '#ccc' }}>
                    {/* <Text category='s1' style={{ flex: 1 }}>Average TTC</Text> */}
                    <Text category='s1' style={{ flex: 1, textAlign: 'center' }}>Average Time Of Arrival</Text>
                    <Text category='s1' style={{ flex: 1, textAlign: 'center' }}>Total Jobs</Text>
                    <Text category='s1' style={{ flex: 1, textAlign: 'center' }}>Total Bill Back Items Cost</Text>
                    <Text category='s1' style={{ flex: 1, textAlign: 'center' }}>Total Customer Cost</Text>
                    <Text category='s1' style={{ flex: 1, textAlign: 'center' }}>Under 45 Mins</Text>
                    <Text category='s1' style={{ flex: 1, textAlign: 'center' }}>Under 60 Mins</Text>
                    <Text category='s1' style={{ flex: 1, textAlign: 'center' }}>Under 90 Mins</Text>
                    <Text category='s1' style={{ flex: 1, textAlign: 'center' }}>Callout Total</Text>
                </Layout>
                <Layout style={{ flexDirection: 'row', justifyContent: 'space-between', paddingVertical: 10 }}>
                    {/* <Text style={{ flex: 1 }}>{formatTime(calculateAverageJobTime(m_plotData))}</Text> */}
                    <Text style={{ flex: 1, textAlign: 'center' }}>{formatTime(calculateAverageCreatedToArrivalTime(m_plotData))}</Text>
                    <Text style={{ flex: 1, textAlign: 'center' }}>{m_totalJobs}</Text>
                    <Text style={{ flex: 1, textAlign: 'center' }}>${calculateTotalBillBackItemsCost(m_plotData)}</Text>
                    <Text style={{ flex: 1, textAlign: 'center' }}>${calculateTotalCustomerItemsCost(m_plotData)}</Text>
                    <Text style={{ flex: 1, textAlign: 'center' }}>{jobsCompleteWithin45Mins} ({calculatePercentage(jobsCompleteWithin45Mins, totalNumberOfValidArrvalTimeJobs)})</Text>
                    <Text style={{ flex: 1, textAlign: 'center' }}>{jobsCompleteWithin60Mins} ({calculatePercentage(jobsCompleteWithin60Mins, totalNumberOfValidArrvalTimeJobs)})</Text>
                    <Text style={{ flex: 1, textAlign: 'center' }}>{jobsCompleteWithin90Mins} ({calculatePercentage(jobsCompleteWithin90Mins, totalNumberOfValidArrvalTimeJobs)})</Text>
                    <Text style={{ flex: 1, textAlign: 'center' }}>${m_totalClientRates.toFixed(2)}</Text>
                </Layout>
            </Layout>
        )
    }, [m_plotData]);




    function calculatePercentage(partialValue: number, totalValue: number) {
        if (totalValue === 0) return "0%";
        return ((partialValue / totalValue) * 100).toFixed(2) + '%';
    };


    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //////// DAILY BREAKDOWN
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */
    const RenderDailyBreakdown = useCallback(() => {
        const renderJobTime = (jobTime: number) => {
            if (isNaN(Number(jobTime))) return "00:00:00";
            return formatTime(jobTime);
        }
        const dailySummary = loadDailySummary();
        const [detailsVisible, setDetailsVisible] = useState(dailySummary.map(() => false));

        const toggleDetails = useCallback((index: number) => {
            setDetailsVisible(currentDetailsVisible =>
                currentDetailsVisible.map((item, idx) => idx === index ? !item : item)
            );
        }, []);

        return (
            <DailyBreakdown
                dailySummary={dailySummary}
                clients={clientsRef.current}
                drivers={driversRef.current}
                toggleDetails={toggleDetails}
                detailsVisible={detailsVisible}
                onShowReportDetailsModal={onShowReportDetailsModal}
                calculatePercentage={calculatePercentage}
                renderJobTime={renderJobTime}
                formatDateTime={formatDateTime}
            />
        );
    }, [m_plotData]);





    const RenderWeeklySummaries = useCallback(() => {
        return (
            <WeeklySummaries
                weeklySummaries={m_reports.weeklySummaries}
            />
        )
    }, [m_reports]);






    // get services
    const loadServices = async () => {
        const s = StorageController.appState.services || StorageController.getAppState().selectedCompany?.services || [];
        servicesRef.current = s;
    }


    // get members
    const loadDrivers = async () => {
        const result = StorageController.appState.drivers;
        driversRef.current = result;
        setDrivers(result);
    }

    // get clients
    const loadClients = async () => {
        const result = StorageController.getCurrentCompany().clients
        // if member.is_client === true only show the client that matches member.client_id
        if (StorageController.getAppState().selectedMembership?.is_client) {
            const client = result.find(c => c._id === StorageController.getAppState().selectedMembership?.client_id);
            if (client) {
                1
                clientsRef.current = [client];
            }
        } else {
            clientsRef.current = result;
        }
    }

    // get vendor profiles
    const loadVendorProfiles = async () => {
        const result = await VendorProfileController.getVendorProfilesByCompanyId(StorageController.getCurrentCompany()._id)
        vendorProfilesRef.current = result;
    }


    // register company change listener
    const handleCompanyChangeEvent = useCallback((action: UpdateController.IAction) => {
        setSelectedCompany(new Company(action.data))
        reset()
        setTimeout(async () => {
            // load();
            loadLast10Weeks();
            await loadClients();
            await loadDrivers();
            await loadServices();
            await loadVendorProfiles();
            onDateRangeChange(dateRangeRef.current);
        }, 100);
    }, [dateRange]);

    const { dispatchEventStateChange } = useAppStateChange({
        onUpdatedSelectedCompany: handleCompanyChangeEvent
    })


    ////////////////////////////////
    ////////////////////////////////
    // FILTERS
    ////////////////////////////////
    ////////////////////////////////

    // clear filters
    const clearFilters = () => {
        clientsCheckedRef.current = [];
        driversCheckedRef.current = [];
        servicesCheckedRef.current = [];
        statusesCheckedRef.current = [];
        vendorProfilesCheckedRef.current = [];
        setStatusesChecked([]);
        setServicesChecked([]);
        setVendorProfilesChecked([]);
        // forceUpdate();
        // filterJobsByCriteria();
    }




    //////////////////////////////////////////////////////
    /////////////////////////////////////////////////////
    ///// FILTER JOBS BY CRITERIA
    ////////////////////////////////////////////////////
    /////////////////////////////////////////////////// 



    const filterJobsByCriteria = (nextJobs = fetchedData) => {
        try {
            const nextJobsFiltered = nextJobs.filter((job) => {
                if (
                    clientsCheckedRef.current.length > 0 &&
                    !clientsCheckedRef.current.find((c) => c._id === job.client_id)
                ) {
                    return false;
                }
                if (
                    driversCheckedRef.current.length > 0 &&
                    !driversCheckedRef.current.find((d) => d._id === job.member_id)
                ) {
                    return false;
                }
                if (servicesCheckedRef.current.length > 0) {

                    const hasSelectedService = job.details.selected_services?.some(
                        (service) =>
                            servicesCheckedRef.current.some(
                                (selectedService) => selectedService._id === service._id
                            )
                    );

                    if (!hasSelectedService) {
                        return false;
                    }
                }
                if (
                    statusesCheckedRef.current.length > 0 &&
                    !statusesCheckedRef.current.includes(job.status)
                ) {
                    return false;
                }
                if (vendorProfilesCheckedRef.current.length > 0) {
                    const matchingVendorProfile = vendorProfilesCheckedRef.current.find(vp => {
                        if (job.vendor_company_id && vp.linkedVendorAccountId) {
                            return vp.linkedVendorAccountId === job.vendor_company_id;
                        }
                        if (job.vendor_profile_id) {
                            return vp._id === job.vendor_profile_id;
                        }
                        return false;
                    });
                    if (!matchingVendorProfile) {
                        return false;
                    }
                }
                return true;
            });
            // const updatedJobs = modifiedData(nextJobsFiltered); TODO:

            // setRows(updatedJobs); TODO: 
            onSetPlotData(nextJobsFiltered)
            forceUpdate();
        } catch (e) {
            console.log('Error filterJobsByCriteria: ', e);
        }
    };


    const RenderFilterCards = () => {
        return (
            <Filters
                filterType={FILTER_TYPE.JOB}
                clients={clientsRef}
                drivers={driversRef}
                services={servicesRef}
                statuses={statuses}
                statusesChecked={statusesChecked}
                clientsChecked={clientsChecked}
                driversChecked={driversChecked}
                servicesChecked={servicesChecked}
                vendorProfilesChecked={vendorProfilesChecked}
                setClientsChecked={setClientsChecked}
                setDriversChecked={setDriversChecked}
                setServicesChecked={setServicesChecked}
                setStatusesChecked={setStatusesChecked}
                setVendorProfilesChecked={setVendorProfilesChecked}
                clientsCheckedRef={clientsCheckedRef}
                driversCheckedRef={driversCheckedRef}
                servicesCheckedRef={servicesCheckedRef}
                statusesCheckedRef={statusesCheckedRef}
                vendorProfilesCheckedRef={vendorProfilesCheckedRef}
                jobs={fetchedData}
                filteredJobs={m_plotData}
                holdings={[]}
                filteredHoldings={[]}
                forceUpdate={forceUpdate}
                filterJobsByCriteria={filterJobsByCriteria}
                filterHoldingsByCriteria={() => { }}
                vendorProfiles={vendorProfilesRef}
            />
        )
    }


    ////////////////////////////////
    ////////////////////////////////
    // DATE RANGE FILTER
    ////////////////////////////////
    ////////////////////////////////

    const onDateRangeChange = (nextRange: DateRange) => {
        setDateRange(nextRange);
        setStartDate(nextRange.startDate);
        setEndDate(nextRange.endDate);
        searchJobsByDateRange(nextRange.startDate, nextRange.endDate);
    };


    const validateStartDate = (date: Date) => {
        date = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 5, 5, 5);
        setStartDate(date)
    }
    const validateEndDate = (date: Date) => {
        setEndDate(date)
    }
    const validateDateRange = (range: DateRange) => {
        validateStartDate(range.startDate)
        validateEndDate(range.endDate)
        setDateRange(range)
    }

    const validateAndSearchJobsByDateRange = (range: DateRange) => {
        validateDateRange(range)
        searchJobsByDateRange(range.startDate, range.endDate)
    }

    // filter jobs by date range
    const searchJobsByDateRange = (start = startDate, end = endDate) => {
        start.setHours(0, 0, 0, 0);
        if (!end) { // for a single date
            end = new Date(start);
        }
        end.setHours(23, 59, 59, 999);
        InvoiceController.getJobsByDateRange(start, end)
            .then((res: Job[] | undefined) => {
                if (res) {
                    clearFilters();
                    onSetPlotData(res);
                    setFetchedData(res);
                }
            })
            .catch(err => {
                console.log(err);
            });
    }

    interface DateRange {
        startDate: Date;
        endDate: Date;
    }
    const [currentWeek, setCurrentWeek] = useState(new Date()); // Initial week

    const handlePreviousWeek = () => {
        const previousWeek = new Date(currentWeek);
        previousWeek.setDate(previousWeek.getDate() - 7);
        setCurrentWeek(previousWeek);
        updateDateRange(previousWeek);
    };

    const handleNextWeek = () => {
        const nextWeek = new Date(currentWeek);
        nextWeek.setDate(nextWeek.getDate() + 7);
        setCurrentWeek(nextWeek);
        updateDateRange(nextWeek);
    };



    const updateDateRange = (week: Date) => {
        const startDate = new Date(week);
        const endDate = new Date(week);
        startDate.setDate(startDate.getDate() - week.getDay() + 1);
        endDate.setDate(endDate.getDate() - week.getDay() + 7);
        const nextRange = { startDate, endDate };
        onDateRangeChange(nextRange);
    };


    const m_totalJobs = useMemo(() => m_plotData.length, [m_plotData]);

    // total client rates
    const m_totalClientRates = useMemo(() => {
        return m_plotData.reduce((total, job) => {
            let rate = job.details?.client_rate?.cost || 0;
            if (isNaN(Number(rate))) return total;
            return total + Number(rate);
        }, 0);
    }, [m_plotData]);



    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //////// TimeFilter
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */



    const onStartTimeChange = (time: string) => {
        const [hours, minutes] = time.split(':').map(Number);
        // setStartTime(hours * 60 + minutes);
        startTimeStringRef.current = time;
        startTimeRef.current = (hours * 60 + minutes);
    };
    const onEndTimeChange = (time: string) => {
        const [hours, minutes] = time.split(':').map(Number);
        // setEndTime(hours * 60 + minutes);
        endTimeStringRef.current = time
        endTimeRef.current = (hours * 60 + minutes);
    };

    const filterJobsByTime = useCallback((nextJobs = fetchedData) => {
        const nextJobsFiltered = nextJobs.filter((job:Job) => {
            if (!job.start_time) return false;
            const jobStartTime = new Date(job.start_time).getHours() * 60 + new Date(job.start_time).getMinutes();
            // const jobPendingTime = new Date(job.pending_time || 0).getHours() * 60 + new Date(job.pending_time || 0).getMinutes();
            return jobStartTime >= startTimeRef.current && jobStartTime <= endTimeRef.current;
        });
        onSetPlotData(nextJobsFiltered);
        // forceUpdate();
    }, [fetchedData]);



    // Make the time picker component





    ////////////////////////////////
    ////////////////////////////////
    // DATA TABLE - DOUGHNUT
    /////////////////////////////////
    /////////////////////////////////
    const [showIndividualServicesDoughnut, setShowIndividualServicesDoughnut] = useState(false);
    const [doughnutKeyUpdate, setDoughnutKeyUpdate] = useState(0);

    const m_serviceCounts = useMemo(() => {
        const serviceCounts: any = {};
        const keywords: any = {
            "Battery": ["battery", "jumpstart", "jump-start", "jump start", "jump", "start"],
            "Tyre": ["tyre", "tire"],
            "Tow": ["tow", "towing"],
            "Lockout": ["lockout", "locked", "keys", "key"],
            "Vehicle": ["vehicle", "car"],
            "Fuel": ["fuel", "gas"],
            "Other": ["other", "advice", "report", "inspect"]
        };

        m_plotData.forEach((job) => {
            const _job = new Job(job);
            const serviceNames = _job.getServicesNamesArray();
            serviceNames.forEach((service: any) => {
                const group = Object.keys(keywords).find(key =>
                    keywords[key].some((k: string) => service.toLowerCase().includes(k))
                );
                if (group) {
                    serviceCounts[group] = (serviceCounts[group] || 0) + 1;
                } else {
                    serviceCounts["Misc"] = (serviceCounts["Misc"] || 0) + 1;
                }
            });
        });

        return serviceCounts;
    }, [m_plotData]);


    const m_serviceCounts_individual = useMemo(() => {
        const serviceCounts: any = {};
        m_plotData.forEach((job) => {
            const _job = new Job(job);
            const serviceNames = _job.getServicesNamesArray();
            serviceNames.forEach((service: any) => {
                if (serviceCounts[service]) {
                    serviceCounts[service]++;
                } else {
                    serviceCounts[service] = 1;
                }
            })
        });
        return serviceCounts;
    }, [m_plotData]);

    // Create labels and background colors from the service types
    const m_serviceLabels = useMemo(() => {
        return Object.keys(m_serviceCounts);
    }, [m_serviceCounts]);

    const m_serviceData = useMemo(() => {
        return Object.values(m_serviceCounts);
    }, [m_serviceCounts]);

    // Create dataset with unique colors for each service
    const doughnutDataset = useMemo(() => {
        if (showIndividualServicesDoughnut) {
            return {
                data: Object.values(m_serviceCounts_individual),
                backgroundColor: Object.keys(m_serviceCounts_individual).map((_, index) => namedColor(index)),
                hoverBackgroundColor: Object.keys(m_serviceCounts_individual).map((_, index) => namedColor(index)),
            };
        }
        return {
            data: m_serviceData,
            backgroundColor: m_serviceLabels.map((_, index) => namedColor(index)),
            hoverBackgroundColor: m_serviceLabels.map((_, index) => namedColor(index)),
        };
    }, [m_serviceData, m_serviceLabels, showIndividualServicesDoughnut]);

    const memoizedDoughnutChart = useMemo(() => {
        setDoughnutKeyUpdate(doughnutKeyUpdate + 1);

        return (
            <Doughnut
                key={doughnutKeyUpdate}
                data={{
                    labels: showIndividualServicesDoughnut ? Object.keys(m_serviceCounts_individual) : m_serviceLabels,
                    datasets: [doughnutDataset],
                }}
                //@ts-ignore
                options={doughnutOptions}
            />
        );
    }, [m_serviceLabels, doughnutDataset, showIndividualServicesDoughnut]);

    const doughnutOptions = {
        responsive: true,
        plugins: {

            legend: {
                position: 'top',
                labels: {
                    color: "white"
                }
            },
            title: {
                display: true,
                text: 'Service Breakdown',
                color: "white"
            }
        }
    };

    // Line chart data for job frequency. job.start_time as the plot. y-axis is the number of jobs, x-axis is the time of day
    // Line chart data

    const m_processDatesCompleted = useMemo(() => {
        const jobCounts = {};
        const jobs = plotData.filter((job) => job.status === JOB_STATUS.COMPLETE);
        // Initialize jobCounts with zeros for all hours in the range
        for (let h = 0; h <= 23; h++) {
            const hourStr = h.toString().padStart(2, '0') + ':00';
            //@ts-ignore
            jobCounts[hourStr] = 0;
        }

        // Iterate through the jobs and count the number of jobs per hour
        for (let i = 0; i < jobs?.length; i++) {
            const job = jobs[i];
            const jobStartTime = moment.unix(job.start_time);
            const jobStartHour = jobStartTime.hour(); // Extract the hour (0-23)

            const hourLabel = jobStartHour.toString().padStart(2, '0') + ':00'; // Format hour as HH:00
            //@ts-ignore
            jobCounts[hourLabel]++;
        }

        return jobCounts;
    }, [plotData]);


    const m_processDatesCancelled = useMemo(() => {
        const jobCounts = {};
        const jobs = plotData.filter((job) => job.status === JOB_STATUS.CANCELLED);
        // Initialize jobCounts with zeros for all hours in the range
        for (let h = 0; h <= 23; h++) {
            const hourStr = h.toString().padStart(2, '0') + ':00';
            //@ts-ignore
            jobCounts[hourStr] = 0;
        }

        // Iterate through the jobs and count the number of jobs per hour
        for (let i = 0; i < jobs?.length; i++) {
            const job = jobs[i];
            const jobStartTime = moment.unix(job.start_time);
            const jobStartHour = jobStartTime.hour(); // Extract the hour (0-23)

            const hourLabel = jobStartHour.toString().padStart(2, '0') + ':00'; // Format hour as HH:00
            //@ts-ignore
            jobCounts[hourLabel]++;
        }

        return jobCounts;
    }, [plotData])



    const lineLabels = [
        "00:00", "01:00", "02:00", "03:00", "04:00", "05:00", "06:00", "07:00",
        "08:00", "09:00", "10:00", "11:00", "12:00", "13:00", "14:00", "15:00",
        "16:00", "17:00", "18:00", "19:00", "20:00", "21:00", "22:00", "23:00",
    ]

    const lineGraphDataSet = useMemo(() => {
        if (!plotData) return { labels: [], datasets: [] };
        const jobCountsComplete = m_processDatesCompleted;
        const jobCountsNotComplete = m_processDatesCancelled;

        return {
            // labels: Object.keys(jobCounts),
            labels: lineLabels,
            datasets: [
                {
                    label: 'Number of Jobs',
                    data: Object.values(jobCountsComplete) as number[],
                    borderColor: 'rgba(75,192,192,1)',
                    fill: false,
                    backgroundColor: 'rgba(75,192,192,0.2)',
                    tension: 0.1
                },
                {
                    label: 'Number of Jobs Cancelled',
                    data: Object.values(jobCountsNotComplete) as number[],
                    borderColor: 'rgba(255, 99, 132, 1)',
                    fill: false,
                    backgroundColor: 'rgba(255, 99, 132, 0.2)',
                    tension: 0.1
                }
            ],
        };
    }, [plotData, m_processDatesCompleted]);

    const lineOptions = {
        responsive: true,
        plugins: {
            legend: {
                display: true,
            },
            title: {
                display: true,
                text: 'Job Frequency',
            },
        },
        scales: {
            x: {
                title: {
                    display: true,
                    text: 'Time',
                },
                // show all labels
                ticks: {
                    autoSkip: false,
                },
            },
            y: {
                title: {
                    display: true,
                    text: 'Number of Jobs',
                },
                beginAtZero: true,
                min: 0,
                //@ts-ignore
                max: Math.max(...Object.values(lineGraphDataSet?.datasets?.[0]?.data as number ?? 0)) + 0,
            },
        },
    };










    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //////// MEMOIZED RENDERS
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */

    const MemoizedReportDetailsModal = useMemo(() => {
        return (
            <JobReportModal
                showReportDetailsModal={showReportDetailsModal}
                setShowReportDetailsModal={setShowReportDetailsModal}
                job={selectedJob as Job}
            />
        )
    }, [showReportDetailsModal]);




    return (
        <Layout style={{ flex: 1 }}>
            {MemoizedReportDetailsModal}
            <Layout style={{ flex: 1, flexDirection: 'row' }}>
                <Layout style={{ flex: 1, flexDirection: 'column' }}>
                    <ScrollView style={{ flex: 1, flexDirection: 'column' }}>
                        {/* <Layout style={{ flex: 1 }}> */}
                        <RenderSearchJobsByDateRange
                            searchJobsByDateRange={searchJobsByDateRange}
                            validateAndSearchJobsByDateRange={validateAndSearchJobsByDateRange}
                            dateRange={dateRange}
                            validateDateRange={validateDateRange}
                            handleNextWeek={handleNextWeek}
                            handlePreviousWeek={handlePreviousWeek}
                        />
                        <TimeFilter
                            startTime={startTimeRef.current}
                            endTime={endTimeRef.current}
                            onStartTimeChange={onStartTimeChange}
                            onEndTimeChange={onEndTimeChange}
                            filterJobsByTime={filterJobsByTime}
                        />
                        {/* </Layout> */}
                        {/* <Layout style={{ flex: 10 }}> */}
                        <ErrorBoundary >
                            {/* <RenderFilters /> */}
                            <RenderFilterCards />
                        </ErrorBoundary>
                        {/* </Layout> */}
                    </ScrollView>
                </Layout>
                <ScrollView style={{ flex: 6, flexDirection: 'column' }}>
                    {/* <RenderTodaysJobsSummary /> */}
                    <Layout style={{ flexDirection: 'row' }}>
                        {/* @ts-ignore */}
                        <Layout style={{ flex: 2, flexDirection: 'column', borderRadius: 20 }} level="2">
                            <View style={{ flexDirection: 'row' }}>
                                <Text category='h5'>Showing </Text>
                                <Text status="success" category='h5'>{m_plotData && m_plotData.length}</Text>
                                <Text category='h5'> Jobs from</Text>
                                <Text status="info" category='h5'> {startDate && startDate.toLocaleString(
                                    'en-GB',
                                    {
                                        day: 'numeric',
                                        month: 'short',
                                        year: 'numeric',
                                    },
                                )} - {endDate && endDate.toLocaleString(
                                    'en-GB',
                                    {
                                        day: 'numeric',
                                        month: 'short',
                                        year: 'numeric',
                                    },
                                )}</Text>
                                <Text category='h5'> - From </Text>
                                <Text status="warning" category='h5'>{startTimeStringRef.current} to {endTimeStringRef.current}</Text>
                            </View>


                            {/* <Bar data={barGraphDataSet} options={barOptions} /> */}
                            <DashboardMap company={selectedCompany} jobs={m_plotData} />
                        </Layout>
                        <View style={{ flex: 1, flexDirection: 'column' }}>
                            {/* @ts-ignore */}
                            <Toggle
                                checked={showIndividualServicesDoughnut}
                                onChange={() => setShowIndividualServicesDoughnut(!showIndividualServicesDoughnut)}
                                style={{ marginBottom: 10 }}
                            >
                                Show Individual Services
                            </Toggle>
                            {memoizedDoughnutChart}
                            {/* <Doughnut data={{ labels: m_serviceLabels, datasets: [doughnutDataset] }} options={doughnutOptions} /> */}
                            <Line data={lineGraphDataSet} options={lineOptions} />
                        </View>
                    </Layout>
                    <Layout style={{ flexDirection: 'row' }}>
                        <Layout style={{ flex: 1, flexDirection: 'column' }}>
                            {/* <RenderTodaysJobsSummary /> */}
                        </Layout>
                    </Layout>
                    <Layout style={{ flexDirection: 'row' }}>
                        <Layout style={{ flex: 1, flexDirection: 'column' }}>
                            <RenderRangeSummary />
                            <RenderDailyBreakdown />
                            {/* <RenderSummary /> */}
                            <RenderWeeklySummaries />
                        </Layout>
                    </Layout>
                </ScrollView>
            </Layout >
        </Layout >
    );
};

export default DashboardScreen

const styles = StyleSheet.create({
    backdrop: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
});




