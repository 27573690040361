import React, { useCallback, useState } from 'react';
import { List } from '@ui-kitten/components';
import { RouteLocation, ROUTE_LOCATION_TYPE } from '../../models/Route.model';
import { RouteListItem } from './routeListItem.component';

interface RouteListProps {
  locations: RouteLocation[];
  selectedRouteLocationIndex: number;
  onSelectLocation: (index: number) => void;
  onRemoveLocation: (index: number) => void;
  onReorderLocations: (fromIndex: number, toIndex: number) => void;
  onSetRouteLocationType: (index: number, routeLocationType: ROUTE_LOCATION_TYPE) => void;
}

export const RouteList: React.FC<RouteListProps> = ({
  locations,
  selectedRouteLocationIndex,
  onSelectLocation,
  onRemoveLocation,
  onReorderLocations,
  onSetRouteLocationType,
}) => {
  const renderItem = ({ item, index }: { item: RouteLocation; index: number }) => {
    return (
      <RouteListItem
        key={index}
        routeLocation={item}
        index={index}
        isSelected={selectedRouteLocationIndex === item.index}
        onPress={() => onSelectLocation(item.index)}
        onRemove={() => onRemoveLocation(item.index)}
        onMoveUp={() => onReorderLocations(index, index - 1)}
        onMoveDown={() => onReorderLocations(index, index + 1)}
        onSetRouteLocationType={onSetRouteLocationType}
        isFirst={index === 0}
        isLast={index === locations.length - 1}
      />
    );
  }

  return (
    <List
      data={locations}
      renderItem={renderItem}
    />
  );
};