import React, { useState, useEffect, useRef, useReducer, useCallback, Profiler, useMemo, memo, useContext } from 'react';
import {
    AccessibilityRole,
    ImageProps,
    ImageStyle,
    Image,
    StyleSheet,
    TouchableOpacity,
    View,
    Text as RNText,
    Platform,
    useWindowDimensions,
    ScrollView,
    Dimensions,
    Alert
    //@ts-ignore
} from "react-native";
import {
    ApplicationProvider,
    Button,
    Icon,
    IconRegistry,
    Layout,
    Text,
    List,
    ListItem,
    Card,
    Input,
} from "@ui-kitten/components";

interface OtherDetailsCardProps {
    dispatchComments: string;
    onSetDispatchComments: (value: string) => void;
    allowEdit: boolean;
    style?: any;
}

export const OtherDetailsCard = ({
    dispatchComments,
    onSetDispatchComments,
    allowEdit = true,
    style
}: OtherDetailsCardProps) => {

    // we want to make some states and efftects foir a change in dispatchComments top updatye the input value

    const commentsInputRef = useRef(null as any)
    useEffect(() => {
        if (commentsInputRef.current) {
            commentsInputRef.current.value = dispatchComments
        }
    }, [dispatchComments]);

    const onChangeCommentsValue = (value: string) => {
        onSetDispatchComments(value)
        console.log("onChangeCommentsValue", value, allowEdit)
    }

    useEffect(() => {
        console.log("dispatchComments", dispatchComments, allowEdit)
    }, [])




    return (
        <Card
            // disabled
            header={() => <Text style={{ alignSelf: 'center' }}>Comments</Text>}
            style={[style, {}]}
            status='primary'
        >

            <Input
                ref={commentsInputRef}
                style={{ flex: 1, padding: 10, margin: 5, borderBottomWidth: 1 }}
                label='Dispatch Comments'
                disabled={!allowEdit}
                multiline={true}
                textStyle={{ minHeight: 80 }}
                defaultValue={dispatchComments}
                onChange={(e: any) => onChangeCommentsValue(e.target.value)}
            />
        </Card>
    )

}
