import React from 'react';
//@ts-ignore
import { View, StyleSheet, TouchableOpacity, useWindowDimensions } from 'react-native';
import { RouteLeg, RouteLocation, Route } from '../../models/Route.model';
//@ts-ignore
import { useTheme, Text, Layout, Card, Icon } from '@ui-kitten/components';

interface RouteLegsCardProps {
    route: Route;
    selectedLegIndex: number | null;
    onLegClick: (leg: RouteLeg) => void;
    onClearSelectedLeg: () => void;
}

export const RouteLegsCard = ({route, selectedLegIndex, onLegClick, onClearSelectedLeg }: RouteLegsCardProps) => {

    //@ts-ignore
    const theme = useTheme() as any;

    const polylineStrokeColourSet = (index: number) => {
        const colours = [
            "#0000FF", // Blue
            "#4B0082", // Indigo
            "#9400D3", // Dark Violet
            "#FF7F00", // Dark Orange
            "#FF0000", // Red
            "#00FF00", // Lime
            "#00FFFF", // Cyan
            "#FF00FF", // Magenta
            "#FFD700", // Gold
            "#32CD32", // Lime Green
            "#8A2BE2", // Blue Violet
            "#FF1493", // Deep Pink
            "#1E90FF", // Dodger Blue
            "#FF4500", // Orange Red
            "#00CED1", // Dark Turquoise
        ]
        return colours[index % colours.length]
    }

    const legDistance = (leg: RouteLeg) => {
        if (leg.actual_distance_kms) {
            return `${leg.actual_distance_kms.toFixed(2)} km`;
        } else {
            return `${(1 / 1000).toFixed(2)} km`;
        }
    }

    const legDuration = (leg: RouteLeg) => {
        if (leg.actual_duration_seconds) {
            return `${(leg.actual_duration_seconds / 60).toFixed(0)} min`;
        } else {
            return `${(1 / 60).toFixed(0)} min`;
        }
    }

    const fromAddress = (leg: RouteLeg) => {
        return `${leg.origin?.lat.toFixed(4)}, ${leg.origin?.lon.toFixed(4)}`;
    }

    const toAddress = (leg: RouteLeg) => {
        return `${leg.destination?.lat.toFixed(4)}, ${leg.destination?.lon.toFixed(4)}`;
    }
    
    
    
    return (
        <View style={[styles.container, { borderColor: theme['color-basic-500'] }]}>
            {route.details.legs.map((leg, index) => (
                <TouchableOpacity key={index} style={styles.legCard} onPress={() => onLegClick(leg)}>
                    <View style={[styles.colorIndicator, { backgroundColor: polylineStrokeColourSet(index) }]} />
                    <Layout style={[styles.legInfo, { backgroundColor: selectedLegIndex === index ? theme['color-primary-100'] : theme['background-basic-color-1'] }]}>
                        <Text style={styles.legTitle}>Leg {index + 1}</Text>
                        <Text style={styles.legDetail}>From: {fromAddress(leg)}</Text>
                        <Text style={styles.legDetail}>To: {toAddress(leg)}</Text>
                        <Text style={styles.legDetail}>Distance: {legDistance(leg)}</Text>
                        <Text style={styles.legDetail}>Duration: {legDuration(leg)}</Text>
                    </Layout>
                </TouchableOpacity>
            ))}
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        padding: 3,
        borderWidth: 1,
        borderRadius: 8,
        margin: 1,
    },
    legCard: {
        flexDirection: 'row',
        // backgroundColor: '#f0f0f0',
        borderRadius: 8,
        marginBottom: 10,
        overflow: 'hidden',
    },
    colorIndicator: {
        width: 10,
    },
    legInfo: {
        flex: 1,
        padding: 10,
        borderWidth: 1,
        borderColor: 'black'
    },
    legTitle: {
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: 5,
    },
    legDetail: {
        fontSize: 14,
        marginBottom: 2,
    },
});