export class Image {
    _id!: string;
    job_id!: string;
    holding_id!: string;
    case_id!: string;
    company_id!: string;
    member_id!: string;
    details!: object;
    status!: string;
    name!: string;
    desc!: string;
    image_type!: string;
    fileType!: string;
    img!: object;
    data!: string;
    createdAt!: string;
    updatedAt!: string;

    constructor(details: any = {}) {
        this._id = details._id || '';
        this.job_id = details.job_id;
        this.holding_id = details.holding_id;
        this.case_id = details.case_id;
        this.company_id = details.company_id || '';
        this.member_id = details.member_id || '';
        this.details = details.details || {};
        this.status = details.status || '';
        this.name = details.name || '';
        this.desc = details.desc || '';
        this.image_type = details.image_type || '';
        this.fileType = details.fileType || '';
        this.img = details.img || {};
        this.data = details.data || '';
        this.createdAt = details.createdAt || '';
        this.updatedAt = details.updatedAt || '';

    }

    public get(): Image {
        return this
    }

    public set(image: any): Image {
        this._id = image._id;
        this.job_id = image.job_id;
        this.company_id = image.company_id;
        this.member_id = image.member_id;
        this.details = image.details;
        this.status = image.status;
        this.name = image.name;
        this.desc = image.desc;
        this.image_type = image.image_type;
        this.fileType = image.fileType;
        this.img = image.img;
        this.createdAt = image.createdAt;
        this.updatedAt = image.updatedAt;
        return this;
    }


}