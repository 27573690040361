/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { View } from 'react-native';
import { Avatar, Bubble, SystemMessage, Message, MessageText } from 'react-native-gifted-chat';
import * as StorageController from '../../functions/storageController';
import { Divider, Icon, Layout, Text, TopNavigation, TopNavigationAction, Card, Button, Input, ButtonGroup, CheckBox, Modal, RangeDatepicker, Datepicker, useTheme, IndexPath, Select, SelectItem, Spinner } from '@ui-kitten/components';

export const renderAvatar = (props) => (
    <Avatar
        {...props}
        containerStyle={{ left: { borderWidth: 3, borderColor: 'red' }, right: {} }}
        imageStyle={{ left: { borderWidth: 3, borderColor: 'blue' }, right: {} }}
    />
);

export const renderBubble = (props) => (
    <Bubble
        {...props}
        // renderTime={() => <Text>Time</Text>}
        // renderTicks={() => <Text>Ticks</Text>}
        containerStyle={{
            // left: { borderColor: 'teal', borderWidth: 8 },
            right: {},
        }}
        wrapperStyle={{
            left: { padding: 4 },
            right: { padding: 4 },
            // left: { borderColor: 'tomato', borderWidth: 4, padding:4 },
        }}
        bottomContainerStyle={{
            // left: { borderColor: 'purple', borderWidth: 4 },
            right: {},
        }}
        tickStyle={{}}
        // usernameStyle={{ color: 'tomato', fontWeight: '100' }}
        containerToNextStyle={{
            // left: { borderColor: 'navy', borderWidth: 4 },
            right: {},
        }}
        containerToPreviousStyle={{
            // left: { borderColor: 'mediumorchid', borderWidth: 4 },
            right: {},
        }}
    />
);

const RenderFooter = (props) => {
    const { currentMessage, nextMessage, side } = props;
    if (currentMessage.read) {
        return (
            <View style={{ paddingBottom: 20, flexDirection: 'row', justifyContent: side == 'left' ? 'flex-start' : 'flex-end' }}>
                <Text style={{ fontSize: 12, color: 'grey' }}>Read</Text>
                <Icon name="checkmark-circle-outline" width={15} height={15} fill="green" />
            </View>
        );
    }
    return (
        <View style={{ paddingBottom: 20, flexDirection: 'row', justifyContent: side == 'left' ? 'flex-start' : 'flex-end' }}>
            <Text style={{ fontSize: 12, color: 'grey' }}>Unread</Text>
            <Icon name="checkmark-circle-outline" width={15} height={15} fill='red' />
        </View>
    );
};

export const renderMessage = (props) => {
    try {
        const { currentMessage, nextMessage } = props;
        let currentUserId = StorageController.getAppState().selectedMembership?._id
        // last message is an empty object
        const isLastMessage = !nextMessage.hasOwnProperty('_id')
        return (
            <View>
                <Message {...props}>
                </Message>
                {isLastMessage &&
                    <>
                        {currentMessage.user?._id === currentUserId ?
                            <RenderFooter currentMessage={currentMessage} nextMessage={nextMessage} side="left" />
                            :
                            // <RenderFooter currentMessage={currentMessage} nextMessage={nextMessage} side="right" />
                            null
                        }
                    </>
                }
            </View>
        );
    }
    catch (e) {
        console.log("🚀 ~ file: messageComponents.component.js ~ line 144 ~ renderMessage ~ e", e)
        return null
    }
};


export const renderSystemMessage = (props) => (
    <SystemMessage
        {...props}
        containerStyle={{ backgroundColor: 'pink' }}
        wrapperStyle={{ borderWidth: 10, borderColor: 'white' }}
        textStyle={{ color: 'crimson', fontWeight: '900' }}
    />
);

// export const renderMessage = (props) => (
//     <Message
//         {...props}
//         // renderDay={() => <Text>Date</Text>}
//         containerStyle={{
//             left: { backgroundColor: 'lime' },
//             right: { backgroundColor: 'gold' },
//         }}
//     />
// );

export const renderMessageText = (props) => (
    <MessageText
        {...props}
        containerStyle={{
            // left: { backgroundColor: 'yellow' },
            // right: { backgroundColor: 'purple' },
        }}
        textStyle={{
            // left: { color: 'red' },
            // right: { color: 'green' },
            overflow: 'scroll', // Add this line to enable scrolling for long text
        }}
        linkStyle={{
            // left: { color: 'orange' },
            // right: { color: 'orange' },
        }}
        // customTextStyle={{ fontSize: 24, lineHeight: 24 }}
    />
);

export const renderCustomView = ({ user }) => (
    <View style={{ minHeight: 20, alignItems: 'center' }}>
        <Text>
            Current user:
            {user.name}
        </Text>
        <Text>From CustomView</Text>
    </View>
);
