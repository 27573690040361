import React, { useState, useEffect, useRef } from 'react';
//@ts-ignore
import { StyleSheet, View, Image, TouchableWithoutFeedback } from 'react-native';
import { Divider, Layout, Text, Card, Button, Input, Icon } from '@ui-kitten/components';
import { Feather } from '@expo/vector-icons';
import Toast from 'react-native-toast-message';

import * as StorageController from "../../functions/storageController"
import * as RootNavigation from '../../routes';
import * as UserController from '../../functions/user.controller'
import { AppStateModel } from '../../models/AppStateModel.model';
import { User } from '../../models/User.model';

// const AuthContext = React.createContext();

const useInputState = (initialValue = '') => {
    const [value, setValue] = React.useState(initialValue);
    return { value, onChangeText: setValue };
};

export const LoginScreen = ({ route, navigation, options }: any) => {
    const [errText, setErrText] = React.useState('');


    const [secureTextEntry, setSecureTextEntry] = React.useState(true);
    const [showMFAInput, setShowMFAInput] = React.useState(false);
    const inputUsername = useInputState();
    const inputPassword = useInputState();
    const inputMfa = useInputState();


    const usernameRef = useRef(null);
    const passwordRef = useRef(null);
    const mfaRef = useRef(null);

    const loginResponseRef = useRef(null as UserController.iLoginResponse | null);


    // FORGOT PASSWORD FUNCTIONALITY
    const [showForgotPassword, setShowForgotPassword] = React.useState(false);
    const [forgotPasswordEmail, setForgotPasswordEmail] = React.useState('');
    const [forgotPasswordErr, setForgotPasswordErr] = React.useState('');
    const [forgotPasswordSuccess, setForgotPasswordSuccess] = React.useState(false);



    //if logged in navigate to jobs screen
    useEffect(() => {
        StorageController.loadStateFromDisk(false)
            .then((state: any) => {
                if (state?.loggedIn && StorageController.getAppState().selectedMembership != null) {
                    RootNavigation.navigate('Jobs');
                }
                // else if (state.loggedIn && StorageController.getAppState().selectedMembership == null) {
                //     RootNavigation.navigate('CompanySwitch');
                // }
            })
    }, [])


    const FormHeader = (props: any) => (
        <View {...props}>
            <Text category='h6'>Login</Text>
            <Text category='s1'>Enter Details</Text>
            <Text category='s1'>{errText}</Text>
        </View>
    );

    const FormHeaderMFA = (props: any) => (
        <View {...props}>
            <Text category='h6'>MFA</Text>
            <Text category='s1'>Enter MFA Code</Text>
            <Text category='s1'>{errText}</Text>
        </View>
    );

    const FormFooter = (props: any) => (
        <View {...props} style={[props.style, styles.footerContainer]}>
            <Button
                style={styles.footerControl}
                title="Login"
                onPress={() => { login() }}>
                Login
            </Button>
        </View>
    );

    const FormFooterMFA = (props: any) => (
        <View {...props} style={[props.style, styles.footerContainer]}>
            <Button
                style={styles.footerControl}
                title="Verify MFA"
                onPress={() => { verifyMFA() }}>
                Verify MFA
            </Button>
        </View>
    );

    const renderIcon = () => (
        <TouchableWithoutFeedback onPress={toggleSecureEntry}>
            <Feather name={secureTextEntry ? 'eye-off' : 'eye'} size={16} color="white" />
        </TouchableWithoutFeedback>
    );

    const toggleSecureEntry = () => {
        setSecureTextEntry(!secureTextEntry);
    };


    const nav = () => {
        options.fn_update()
        RootNavigation.navigate('CompanySelect')
    }
    const login = async () => {
        try {
            const loginResponse: UserController.iLoginResponse = await UserController.login(inputUsername.value, inputPassword.value)
            // console.log("🚀============== ~ file: login.component.tsx:107 ~ login ~ loginResponse🚀==============", loginResponse)
            if (loginResponse.statusCode === 429) {
                let msg = "Too many login attempts, please try again later"
                setErrText(msg.toString())
                Toast.show({
                    type: 'error',
                    text1: msg,
                    text2: '👋'
                });
                return
            }
            loginResponseRef.current = loginResponse
            if (!loginResponse.success) {
                let msg = "Login Error"
                setErrText(msg.toString())
                Toast.show({
                    type: 'error',
                    text1: msg,
                    text2: '👋'
                });
                return
            }

            if (loginResponse.success && !loginResponse.mfa_enabled) {
                nav()
                Toast.show({
                    type: 'success',
                    text1: loginResponse.message,
                    text2: 'Logged in!'
                });
            }
            if (loginResponse.success && loginResponse.mfa_enabled) {
                setShowMFAInput(true)
                Toast.show({
                    type: 'info',
                    text1: loginResponse.message,
                    text2: '👋'
                });
            }
        } catch (error) {
            let msg = "Login Error"
            setErrText(msg.toString())
            Toast.show({
                type: 'error',
                text1: msg,
                text2: '👋'
            });
        }
    }

    const verifyMFA = async () => {
        try {
            if (!loginResponseRef.current || !loginResponseRef.current?.mfa_access_token) {
                let msg = "MFA Error"
                setErrText(msg.toString())
                Toast.show({
                    type: 'error',
                    text1: msg,
                    text2: '👋'
                });
                return
            }

            const mfaLoginResponse: UserController.iMfaLoginResponse = await UserController.verifyMFA(loginResponseRef.current?.mfa_access_token, inputMfa.value)
            // console.log("🚀============== ~ file: login.component.tsx:161 ~ verifyMFA ~ mfaLoginResponse🚀==============", mfaLoginResponse)
            if (mfaLoginResponse.statusCode === 429) {
                let msg = "Too many login attempts, please try again later"
                setErrText(msg.toString())
                Toast.show({
                    type: 'error',
                    text1: msg,
                    text2: '👋'
                });
                return
            }
            if (!mfaLoginResponse.success) {
                let msg = "MFA Error"
                setErrText(msg.toString())
                Toast.show({
                    type: 'error',
                    text1: msg,
                    text2: '👋'
                });
                return
            }
            if (mfaLoginResponse.success) {
                const user: User = new User(mfaLoginResponse.user)
                StorageController.appState.user = user
                Toast.show({
                    type: 'success',
                    text1: mfaLoginResponse.message,
                    text2: 'Logged in!'
                });
                setTimeout(() => {
                    const user = StorageController.getAppState().user
                    // console.log("🚀============== ~ file: login.component.tsx:181 ~ setTimeout ~ user🚀==============", user)
                    nav()
                }, 1000);
            }
        } catch (error) {
            let msg = "MFA Error"
            setErrText(msg.toString())
            Toast.show({
                type: 'error',
                text1: msg,
                text2: '👋'
            });
        }
    }


    const handleKeyPressArr = (int: number) => (e: any) => {
        try {
            if (e.nativeEvent.key === 'Tab') {
                e.preventDefault();
                //@ts-ignore
                passwordRef?.current?.focus();
            }
        } catch (err) {
            console.log(err, e);
        }
    };


    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //////// RESET PASSWORD
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */
    const resetPassword = async () => {
        try {
            if (forgotPasswordEmail === '') {
                setForgotPasswordErr('Please enter your email address')
                return
            }
            // const response = await UserController.resetPassword(forgotPasswordEmail)
            // if (response.success) {
            //     setForgotPasswordSuccess(true)
            //     setForgotPasswordErr('')
            //     setForgotPasswordEmail('')
            // } else {
            //     setForgotPasswordErr(response.message)
            // }
        } catch (error) {
            console.log("🚀============== ~ file: login.component.tsx:229 ~ resetPassword ~ error🚀==============", error)
        }
    }

    return (
        <Layout style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
            {showMFAInput ?
                <Card
                    disabled={true}
                    style={styles.card}
                    header={FormHeaderMFA}
                    footer={FormFooterMFA}>
                    <Layout style={{ flex: 1, flexDirection: 'column', justifyContent: 'space-between' }}>
                        <Input
                            style={styles.input}
                            size='small'
                            placeholder='MFA Code'
                            ref={mfaRef}
                            returnKeyType="next"
                            onSubmitEditing={() => verifyMFA()}
                            onKeyPress={handleKeyPressArr(0)}
                            {...inputMfa}
                        />
                    </Layout>
                </Card>
                :
                <Card
                    disabled={true}
                    style={styles.card}
                    header={FormHeader}
                    footer={FormFooter}>
                    <Layout style={{ flex: 1, flexDirection: 'column', justifyContent: 'space-between' }}>
                        <Input
                            style={styles.input}
                            size='small'
                            placeholder='Username'
                            ref={usernameRef}
                            returnKeyType="next"
                            //@ts-ignore
                            onSubmitEditing={() => passwordRef.current?.focus()}
                            onKeyPress={handleKeyPressArr(0)}
                            // onChangeText={value => descriptionOfWork(value)}
                            {...inputUsername}
                        />
                        <Layout style={{ flex: 1 }}>
                            <Text> </Text>
                        </Layout>
                        <Input
                            style={styles.input}
                            size='small'
                            placeholder='Password'
                            secureTextEntry={secureTextEntry}
                            accessoryRight={renderIcon}
                            ref={passwordRef}
                            // onChangeText={value => descriptionOfWork(value)}
                            onSubmitEditing={() => { login() }}
                            {...inputPassword}
                        />
                    </Layout>
                </Card>
            }
        </Layout>
    );
}


const styles = StyleSheet.create({
    topContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    card: {
        flex: 1,
        margin: 20,
        width: "30%",
        height: "30%",
    },
    footerContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    footerControl: {
        marginHorizontal: 2,
    }
});
