var config = require('../config/config.js')
const axios = require('axios');
import * as StorageController from './storageController'
import { Job, Service } from '../models/Job.model'

var api = config.api;
var testApi = config.test_api

function getApi() {
    if (StorageController.getAppState().use_test_api) {
        return testApi
    }
    return api
}

axios.interceptors.request.use(
    (config: any) => {
        let token = getToken();
        let member_id = null;
        if (StorageController.getAppState().selectedMembership != null) {
            member_id = StorageController.getAppState().selectedMembership?._id;
        }
        config.headers.authorization = `Bearer ${token}`;
        config.headers['member-id'] = member_id;
        return config;
    },
    (error: any) => {
        return Promise.reject(error);
    }
);

const getToken = async () => {
    return await StorageController.getAccessToken() || null;
}
// setTempServiceDefinitions(serviceDefinitions);



export async function tempGenerateServiceDefinition(name: any, company_id: string) {
    // console.log("Generating service definition", name);
    let serviceModel = new Service({
        // random _id
        // _id: Math.floor(Math.random() * 1000000000),
        company_id: company_id,
        description: '',
        name: name,
        fields: {
            description: 'Description',
        },
        types: {
            description: 'input'
        },
        values: {
            description: ''
        },
        options: {
            description: []
        },
        invoiced: {
            description: false
        },
        other: {
        },
        required: {
            description: false
        }
    });
    // delete derviceModel._id
    delete serviceModel._id // otherwise it will not generate one with the dummy id
    return serviceModel;
}


export async function tempGetServicesByCompanyId() {
    return StorageController.getAppState().services;
}

export async function tempCreateService(serviceObj: Service) {
    try {
        StorageController.appState.services.push(serviceObj);
        StorageController.saveStateToDisk();
    } catch (error) {
        console.error(error);
    }
    return serviceObj;
}

export async function tempUpdateService(serviceObj: Service) {
    try {
        var index = StorageController.appState.services.findIndex(x => x._id == serviceObj._id);
        StorageController.appState.services[index] = serviceObj;
        StorageController.saveStateToDisk();
    } catch (error) {
        console.error(error);
    }
    return serviceObj;
}

export async function tempDeleteService(serviceObj: Service) {
    try {
        var index = StorageController.appState.services.findIndex(x => x._id == serviceObj._id);
        StorageController.appState.services.splice(index, 1);
        StorageController.saveStateToDisk();
    } catch (error) {
        console.error(error);
    }
    return serviceObj;
}

// crud for services
export async function getServicesByCompanyId(companyId: string) {
    try {
        const response = await axios.get(getApi() + "/service/company/" + companyId);
        // console.log(response);
        if (response.data && response.data.length > 0) {
            const res = response.data.map((service: any) => new Service(service));
            return res
        }
    } catch (error) {
        console.error(error);
    }
}

export async function createService(serviceObj: Service) {
    try {
        const service = new Service(serviceObj);
        const response = await axios.post(getApi() + "/service", service);
        const res = new Service(response.data);
        return res
    } catch (error) {
        console.error(error);
    }
}


export async function updateService(serviceObj: Service) {
    try {
        const response = await axios.put(getApi() + `/service/${serviceObj._id}`, serviceObj);
        console.log(response);
        var index = StorageController.appState.services.findIndex(x => x._id == serviceObj._id);
        const updatedService = new Service(serviceObj);
        StorageController.appState.services[index] = updatedService;
        StorageController.saveStateToDisk();
        return response.data
    } catch (error) {
        console.error(error);
    }
}

export async function deleteService(serviceObj: Service) {
    try {
        const response = await axios.delete(getApi() + "/service/" + serviceObj._id);
        console.log(response);
        var index = StorageController.appState.services.findIndex(x => x._id == serviceObj._id);
        StorageController.appState.services.splice(index, 1);
        StorageController.saveStateToDisk();
        return response.data
    } catch (error) {
        console.error(error);
    }
}

export async function getAllServicesByAllCompanyMemberships() {
    try {
        const companies = StorageController.appState.companies || [];
        var services = [] as Service[];
        for (var i = 0; i < companies.length; i++) {
            var company = companies[i];
            var companyServices = await getServicesByCompanyId(company._id);
            services = services.concat(companyServices);
            company.services = companyServices;
        }
        return services;
    }
    catch (error) {
        console.error(error);
    }
}



