import React, { useState, useEffect } from 'react';
//@ts-ignore
import { View } from 'react-native';
import { Modal, Card, Button, Input, Layout, Text } from '@ui-kitten/components';
import * as JobsController from '../../../functions/job.controller';
import { Job, JobCustomerDetails } from '../../../models/Job.model';
import { Client } from '../../../models/Client.model';
import * as ClientController from '../../../functions/client.controller';

interface SendJobEmailsProps {
    job: Job;
    visible: boolean;
    onClose: () => void;
}

interface EmailCardProps {
    header: string;
    email: string | undefined;
    name?: string; // Add name prop
    onPress: () => void;
    sending: boolean;
    emailError?: string;
    setEmail?: (email: string) => void;
    customEmail?: string;
    disabled?: boolean;
    sent: boolean;
}

const EmailCard = ({ header, email, name, onPress, sending, emailError, setEmail, customEmail, disabled, sent }: EmailCardProps) => (
    <Card
        disabled={true}
        header={<Text category='s1'>{header}</Text>}
        footer={
            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                <Button
                    onPress={onPress}
                    disabled={sending || disabled || sent}
                    status={sent ? 'success' : 'primary'}
                    size='small'
                >
                    {sent ? 'Sent' : 'Send'}
                </Button>
            </View>
        }
        status='basic'
    >
        {name && <Text category='s2'>{name}</Text>} {/* Display name if provided */}
        {setEmail ? (
            <>
                <Input
                    placeholder='Custom Email Address'
                    value={customEmail}
                    onChangeText={setEmail}
                    disabled={sending}
                />
                {emailError ? <Text status='danger' category='label'>{emailError}</Text> : null}
            </>
        ) : (
            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                <View style={{ flexDirection: 'column' }}>
                    <Text>{email}</Text>
                    {emailError ? <Text status='danger' category='label'>{emailError}</Text> : null}
                </View>
            </View>
        )}
    </Card>
);

const SendJobEmails = ({ job, visible, onClose }: SendJobEmailsProps) => {
    const [email, setEmail] = useState('');
    const [sending, setSending] = useState(false);
    const [client, setClient] = useState<Client | null>(null);
    const [customer, setCustomer] = useState<JobCustomerDetails | null>(null);
    const [emailError, setEmailError] = useState('');
    const [clientEmailError, setClientEmailError] = useState('');
    const [customerEmailError, setCustomerEmailError] = useState('');
    const [clientNewEmailSent, setClientNewEmailSent] = useState(false);
    const [customerNewEmailSent, setCustomerNewEmailSent] = useState(false);
    const [customNewEmailSent, setCustomNewEmailSent] = useState(false);
    const [clientCompleteEmailSent, setClientCompleteEmailSent] = useState(false);
    const [customerCompleteEmailSent, setCustomerCompleteEmailSent] = useState(false);
    const [customCompleteEmailSent, setCustomCompleteEmailSent] = useState(false);

    // if the job is complete, pending, or cancelled, then we can send the complete email
    const canSendCompleteEmail = () => {
        return job.status === 'complete' || job.status === 'pending' || job.status === 'cancelled';
    }

    useEffect(() => {
        const fetchClient = async () => {
            if (!job.client_id) return;
            const client = await ClientController.getClientById(job.client_id);
            setClient(client);
            if (client?.contact_info?.email && !validateEmail(client.contact_info?.email)) {
                setClientEmailError('Invalid email address');
            }
        };
        fetchClient();
    }, [job]);

    useEffect(() => {
        const fetchCustomer = async () => {
            if (!job.details.customer_details) return;
            setCustomer(job.details.customer_details);
            if (job.details.customer_details.email && !validateEmail(job.details.customer_details.email)) {
                setCustomerEmailError('Invalid email address');
            }
        };
        fetchCustomer();
    }, [job]);

    useEffect(() => {
        setEmail('');
    }, []);

    const validateEmail = (email: string) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    };

    const sendEmail = async (recipientType: 'client' | 'customer' | 'custom', emailType: 'new' | 'complete') => {
        let emailToSend = '';
        if (recipientType === 'client') {
            emailToSend = client?.contact_info?.email || '';
        } else if (recipientType === 'customer') {
            emailToSend = customer?.email || '';
        } else if (recipientType === 'custom') {
            emailToSend = email;
        }

        if (!validateEmail(emailToSend)) {
            setEmailError('Invalid email address');
            return;
        }
        setEmailError('');
        setSending(true);
        try {
            let result;
            if (emailType === 'new') {
                result = await JobsController.sendNewJobEmailToCustomEmailAddress(job._id, emailToSend);
            } else if (emailType === 'complete') {
                result = await JobsController.sendCompleteEmailToCustomEmailAddress(job._id, emailToSend);
            }
            if (result && result.success) {
                if (emailType === 'new') {
                    if (recipientType === 'client') setClientNewEmailSent(true);
                    else if (recipientType === 'customer') setCustomerNewEmailSent(true);
                    else if (recipientType === 'custom') setCustomNewEmailSent(true);
                } else if (emailType === 'complete') {
                    if (recipientType === 'client') setClientCompleteEmailSent(true);
                    else if (recipientType === 'customer') setCustomerCompleteEmailSent(true);
                    else if (recipientType === 'custom') setCustomCompleteEmailSent(true);
                }
            }
        } catch (err) {
            console.log(err);
        } finally {
            setSending(false);
        }
    };

    return (
        <Modal
            visible={visible}
            backdropStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.7)' }}
            onBackdropPress={onClose}
        >
            {/* @ts-ignore */}
            <Layout style={{ padding: 10, flexDirection: 'row', gap: 10 }} level="2">
                {/* close button at the top right corner */}
                <Button
                    style={{ position: 'absolute', top: 0, right: 0, zIndex: 10 }}
                    onPress={onClose} disabled={sending} status='danger' size='small'>
                    <Text>X</Text>
                </Button>
                <Card
                    disabled={true}
                    status='basic'
                    header={<Text category='s1'>Send New Job Email</Text>}
                >
                    <View style={{ flexDirection: 'column', gap: 10 }}>
                        {client?.contact_info?.email && (
                            <EmailCard
                                header="Send to Client"
                                email={client?.contact_info?.email}
                                name={client?.name} // Pass client's name
                                onPress={() => sendEmail('client', 'new')}
                                sending={sending}
                                emailError={clientEmailError}
                                disabled={!!clientEmailError}
                                sent={clientNewEmailSent}
                            />
                        )}
                        {customer?.email && (
                            <EmailCard
                                header="Send to Customer"
                                email={customer?.email}
                                name={customer?.name} // Pass customer's name
                                onPress={() => sendEmail('customer', 'new')}
                                sending={sending}
                                emailError={customerEmailError}
                                disabled={!!customerEmailError}
                                sent={customerNewEmailSent}
                            />
                        )}
                        <EmailCard
                            header="Send to Custom Email Address"
                            email={email}
                            onPress={() => sendEmail('custom', 'new')}
                            sending={sending}
                            emailError={emailError}
                            setEmail={setEmail}
                            customEmail={email}
                            sent={customNewEmailSent}
                        />
                    </View>
                </Card>
                <Card
                    disabled={true}
                    status='success'
                    header={<Text category='s1'>Send Complete Email</Text>}
                >
                    <View style={{ flexDirection: 'column', gap: 10 }}>
                        {client?.contact_info?.email && (
                            <EmailCard
                                header="Send to Client"
                                email={client?.contact_info?.email}
                                name={client?.name} // Pass client's name
                                onPress={() => sendEmail('client', 'complete')}
                                sending={sending}
                                emailError={clientEmailError}
                                disabled={!!clientEmailError || !canSendCompleteEmail()}
                                sent={clientCompleteEmailSent}
                            />
                        )}
                        {customer?.email && (
                            <EmailCard
                                header="Send to Customer"
                                email={customer?.email}
                                name={customer?.name} // Pass customer's name
                                onPress={() => sendEmail('customer', 'complete')}
                                sending={sending}
                                emailError={customerEmailError}
                                disabled={!!customerEmailError || !canSendCompleteEmail()}
                                sent={customerCompleteEmailSent}
                            />
                        )}
                        <EmailCard
                            header="Send to Custom Email Address"
                            email={email}
                            onPress={() => sendEmail('custom', 'complete')}
                            sending={sending}
                            emailError={emailError}
                            disabled={!!emailError || !canSendCompleteEmail()}
                            setEmail={setEmail}
                            customEmail={email}
                            sent={customCompleteEmailSent}
                        />
                    </View>
                </Card>
            </Layout>
        </Modal>
    );
};

export default SendJobEmails;
