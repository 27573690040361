
import React, { useState, useEffect, useReducer, useRef, useCallback } from 'react';
import {
  StyleSheet,
  Text as RNText,
  Platform,
  useWindowDimensions,
  View,
  Animated,
  ScrollView,
  //@ts-ignore
} from "react-native";
import {
  Button,
  Icon,
  Layout,
  Card,
  Input,
  Modal,
  Text,
  useTheme,
} from "@ui-kitten/components";

import * as MembershipsController from '../../functions/membership.controller'
import * as UpdateController from '../../functions/update.controller'
import * as CompanyController from '../../functions/company.controller'
import * as ImageController from '../../functions/image.controller'
import * as ClientController from '../../functions/client.controller'
import * as StorageController from '../../functions/storageController'
import * as RootNavigation from '../../routes';
import * as UserController from '../../functions/user.controller';
import ErrorBoundary from '../ErrorBoundary.component';
import { Company } from '../../models/Company.model';
import { useAppStateChange, IAction, STATE_ACTIONS } from '../../hooks/appStateChange.hook';
var mqtt = require('../../services/mqtt.service');

var _companies = [] as Company[]
var _selectedCompany = null as Company | null
let messageUpdateCompanyIds = [] as any[]
let jobUpdateCompanyIds = [] as any[]



export const CompanySwitchComponent = () => {
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [companies, setCompanies] = useState([] as Company[]);
  const [loggedIn, setLoggedIn] = useState(false);
  const [eventsRegistered, setEventsRegistered] = useState(false)
  const [x, forceUpdate] = useReducer(x => x + 1, 0);
  const [companyCount, setCompanyCount] = useState(0)

  const [canLoad, setCanLoad] = useState(true);
  const [selectedCompany, setSelectedCompany] = useState(null as Company | null);
  const [selectedMembership, setSelectedMembership] = useState(StorageController.getAppState().selectedMembership)
  // const [messageUpdateCompanyIds, setMessageUpdateCompanyIds] = useState([])
  // const [jobUpdateCompanyIds, setJobUpdateCompanyIds] = useState([])
  const { width, height } = useWindowDimensions();



  useEffect(() => {
    // load companies from storageController
    if (!eventsRegistered) {

      setEventsRegistered(true)
    }
    init()
    setTimeout(() => {
    }, 200);
    return () => {

    }
  }, [])







  const init = useCallback(async () => {
    try {
      if (StorageController.getAppState().selectedMembership != null) {
        setCompanies(StorageController.getAppState().companies as Company[])
        setSelectedCompany(StorageController.getAppState().selectedCompany)
        updateCompanyNotifications()
        _companies = StorageController.getAppState().companies as Company[]
        if (_companies && _companies.length > 0) {
          setCompanyCount(_companies.length)
        }
        setCanLoad(true)
      }
    } catch (e) {
      console.log('error in init', e)
    }
  }, [companies, selectedCompany])

  const handleCompanyChangeEvent = (action: IAction) => {
    if (action.type === STATE_ACTIONS.UPDATED_SELECTED_COMPANY) {
      setSelectedCompany(new Company(action.data));
      setCompanies(StorageController.getAppState().companies as Company[]);
      updateCompanyNotifications();
      setCanLoad(true);
      init();
    }
  };

  const handleJobUpdateEvent = (action: IAction) => {
    if (action.type === STATE_ACTIONS.UPDATED_JOBS) {
      // console.log('Job update event fired', action.data);
      forceUpdate();
    }
  };

  const handleLogoutEvent = (action: IAction) => {
    if (action.type === STATE_ACTIONS.LOGGED_OUT) {
      // console.log('Logout event fired', action.data);
      resetComponent();
    }
  };

  const handleLoginEvent = (action: IAction) => {
    if (action.type === STATE_ACTIONS.LOGGED_IN) {
      // console.log('Login event fired', action.data);
      init();
    }
  };

  const handleOtherCompanyEvent = (action: IAction) => {
    if (action.type === STATE_ACTIONS.UPDATED_OTHER_COMPANY) {
      // console.log('Other company event fired', action.data);
      if (action.data.update === 'MESSAGES') {
        otherCompanyMessageUpdate(action.data.data.company_id);
      }
      if (action.data.update === 'JOBS') {
        otherCompanyJobUpdate(action.data.otherId);
      }
    }
  };

  const handleUpdatedAllDataEvent = (action: IAction) => {
    if (action.type == STATE_ACTIONS.UPDATED_ALL_DATA) {

      if (action.data.companies) {
        setCompanies(StorageController.getAppState().companies as Company[])
      }
      if (action.data.selectedCompany) {
        setSelectedCompany(StorageController.getCurrentCompany())
      }
      if (action.data.selectedMembership) {
        setSelectedMembership(StorageController.getAppState().selectedMembership)
      }
      forceUpdate()
    }
  }

  const { dispatchEventStateChange } = useAppStateChange({
    onUpdatedSelectedCompany: handleCompanyChangeEvent,
    onUpdatedJobs: handleJobUpdateEvent,
    onLoggedOut: handleLogoutEvent,
    onLoggedIn: handleLoginEvent,
    onUpdatedOtherCompany: handleOtherCompanyEvent,
    onUpdatedAllData: handleUpdatedAllDataEvent
  })


  const otherCompanyMessageUpdate = (id: string) => {
    // console.log('other company message update', id)
    if (!messageUpdateCompanyIds.includes(id)) {
      messageUpdateCompanyIds.push(id)
      // console.log("adding message update id", id)
      // setMessageUpdateCompanyIds([...messageUpdateCompanyIds, id])
      forceUpdate()
    }
    // console.log('messageUpdateCompanyIds', messageUpdateCompanyIds)
  }



  const otherCompanyJobUpdate = (id: string) => {
    // console.log('other company job update', id)
    if (!jobUpdateCompanyIds.includes(id)) {
      // console.log("adding job update id", id)
      // setJobUpdateCompanyIds([...jobUpdateCompanyIds, id])
      jobUpdateCompanyIds.push(id)
      forceUpdate()
    }
  }


  // after selecting company, if a job or message is in the lists, remove the ids and 
  // update the lists
  const updateCompanyNotifications = () => {
    // console.log('updating company notifications', StorageController.getAppState().selectedCompany._id, messageUpdateCompanyIds, jobUpdateCompanyIds)
    if (StorageController.getAppState().selectedCompany != null) {
      let c_id = StorageController.getAppState().selectedCompany?._id
      if (messageUpdateCompanyIds.includes(c_id)) {
        // console.log('removing message update id', c_id)
        messageUpdateCompanyIds = messageUpdateCompanyIds.filter((id) => id != c_id)
        forceUpdate()
      }
      if (jobUpdateCompanyIds.includes(c_id)) {
        // console.log('removing job update id', c_id)
        jobUpdateCompanyIds = jobUpdateCompanyIds.filter((id) => id != c_id)
        forceUpdate()
      }
    }
  }




  const resetComponent = () => {
    setCompanies([])
    setSelectedCompany(null)
    _companies = []
    _selectedCompany = null
    setLoggedIn(false)
    setCanLoad(false)
    setTimeout(() => {
      init()
    }, 200);
  }

  const check = async () => {
    if (StorageController.getAppState().selectedMembership == null) {
      console.log('no selected membership == RESETTING')
      resetComponent()
      return
    }
    // checkSelectedCompany();
    setTimeout(() => {
      check()
    }, 2000);
  }



  const onSelectCompany = async (company: Company) => {
    if (company.membership == null) {
      console.log('no membership found for company')
      return
    }
    const c = await CompanyController.switchCompany(company)
    if (!c) return
    setSelectedCompany(c);
    // RootNavigation.navigate('Jobs')
  }


  const pulseValue = useRef(new Animated.Value(1)).current;
  useEffect(() => {
    // Start the pulse animation when the component mounts
    Animated.loop(
      Animated.sequence([
        Animated.timing(pulseValue, {
          toValue: 1.5,
          duration: 500,
          useNativeDriver: true,
        }),
        Animated.timing(pulseValue, {
          toValue: 1,
          duration: 500,
          useNativeDriver: true,
        }),
      ])
    ).start();
  });

  // animated icon for a message bubble
  const MessageIcon = (props: any) => (
    <Animated.View style={{ transform: [{ scale: pulseValue }] }}>
      <Icon
        width={props.width}
        height={props.height}
        name='message-circle'
        fill='red'
      />
    </Animated.View>
  );

  // animated Icon for Job update
  const JobIcon = (props: any) => (
    <Animated.View style={{ transform: [{ scale: pulseValue }] }}>
      <Icon
        width={props.width}
        height={props.height}
        name='briefcase'
        fill='red'
      />
    </Animated.View>
  );

  const getBackgroundColourFromStatus = (status: string) => {
    if (status == "danger") return "red"
    if (status == "request") return "#ff34fb"
    if (status == "warning") return "orange"
    if (status == "success") return "green"
    if (status == "purple") return "purple"
    if (status == "primary") return "blue"
    return "grey"
  }


  const Badge = (props: any) => {
    return (
      <Layout style={{
        margin: 1,
        padding: 2,
        paddingBottom: 0,
        borderRadius: 5,
        backgroundColor: 'transparent'
        // backgroundColor: getBackgroundColourFromStatus(props.status),
      }}>
        <Text style={{
          backgroundColor: getBackgroundColourFromStatus(props.status),
          borderRadius: 15,
          color: "white",
          textShadowColor: "black",
          textShadowOffset: { width: 1, height: 1 },
          textShadowRadius: 2
        }}>
          {props.children}
        </Text>
      </Layout>
    )
  }


  const RenderStatusCountBadge = ({ status, jobs, company, scheduled = false }
    : { status: string, jobs: any[], company: Company, scheduled?: boolean }
  ) => {
    if (!jobs || jobs.length == 0) return <View></View>
    try {
      const companySettings = company?.settings || {}
      const companyScheduledTime = Number(companySettings?.scheduleSettings?.alertTime || 60)
      // if the job is scheduled and the job.start_time is less than the company scheduled time in mins from now
      // then the job is considered non scheduled
      const statusJobs = jobs.filter(job => job.status == status)
      if (!statusJobs?.length || statusJobs?.length == 0) return <View></View>



      const nonScheduledJobs = statusJobs.filter(
        job => job.is_scheduled == false ||
          (job.is_scheduled == true &&
            new Date(job.start_time) < new Date(new Date().getTime() + companyScheduledTime * 60000)
          )
      )

      const scheduledJobs = statusJobs.filter(
        job => job.is_scheduled == true &&
          new Date(job.start_time) > new Date(new Date().getTime() + companyScheduledTime * 60000)
      )

      const scheduledCount = scheduledJobs?.length || 0
      const nonScheduledCount = nonScheduledJobs?.length || 0




      return (
        <ErrorBoundary>
          <Badge
            status={
              status == "request" ? "request" :
                status == "unassigned" ? "danger" :
                  status == "pending" ? "primary" :
                    status == "complete" ? "success" :
                      status == "cancelled" ? "purple" :
                        status == "assigned" ? "warning" :
                          "basic"}
          >
            <View style={{ flexDirection: 'row', gap: 3 }}>
              {(!scheduled && nonScheduledCount > 0) &&
                <View>
                  <Text>
                    {nonScheduledCount}
                  </Text>
                </View>
              }

              {(scheduled && scheduledCount > 0) &&
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                  <Icon name='clock' fill='white' width={10} height={10} />
                  {scheduledCount}
                </View>
              }
            </View>
          </Badge>
        </ErrorBoundary>
      )
    } catch (e) {
      console.log("🚀============== ~ file: companySwitch.component.js:393 ~ RenderStatusCountBadge ~ e🚀==============", e)
      return null
    }
  }


  const scrollViewRef = useRef(null as any)
  const scrollPositionRef = useRef(0)
  const scrollForward = () => {
    const scrollIncrement = 100
    scrollViewRef.current.flashScrollIndicators();
    scrollPositionRef.current = scrollPositionRef.current + scrollIncrement
    scrollViewRef.current.scrollTo({ x: scrollPositionRef.current, y: 0, animated: true })
  }

  const scrollBackward = () => {
    const scrollIncrement = 100
    scrollViewRef.current.flashScrollIndicators();
    scrollPositionRef.current = scrollPositionRef.current - scrollIncrement
    scrollViewRef.current.scrollTo({ x: scrollPositionRef.current, y: 0, animated: true })
  }

  // if (!canLoad) return null
  // if (selectedMembership == null) return null
  // if (!selectedCompany) return null



  const RenderCompanySwitchModal = () => {
    return (
      <Modal
        visible={showModal}
        backdropStyle={styles.backdrop}
        onBackdropPress={() => setShowModal(false)}>
        <Card disabled={true} style={styles.modalCard}>
          <Text style={{ margin: 10, fontSize: 20, fontWeight: 'bold' }}>Select Company</Text>
          <ScrollView style={{ maxHeight: 300 }}>
            {companies && companies.map((company, index) => (
              <Button
                key={company._id}
                style={{ margin: 5 }}
                appearance='outline'
                status='basic'
                onPress={() => {
                  onSelectCompany(company)
                  setShowModal(false)
                }
                }>
                {company.name}
              </Button>
            ))}
          </ScrollView>
        </Card>
      </Modal>
    )
  }

  if (width < 500) {
    return (
      <View style={styles.container}>
        <RenderCompanySwitchModal />
        {companyCount > 1 &&
          <Button
            style={{ margin: 5 }}
            appearance='outline'
            status='basic'
            onPress={() => setShowModal(true)}>
            {selectedCompany?.name}
          </Button>
        }
      </View>
    )
  }

  if (!companies || companies.length == 0) return null

  return (
    <View style={{
      // position: 'absolute',
      // top: 0,
      // right: 100,
      // padding: 5,
      justifyContent: 'flex-end'
      // flex:1
    }}>
      <View style={{ flexDirection: 'row' }}>
        {companyCount > 5 &&
          <Button
            style={{ width: 40, height: "100%", borderRadius: 5 }}
            appearance='outline'
            status='info'
            onPress={() => {
              scrollBackward()
            }}
          >
            <Icon name='arrow-ios-back' fill='white' width={20} height={20} />
          </Button>
        }
        <View style={{ flex: 1, justifyContent: 'flex-end' }}>
          <ScrollView style={{ flexDirection: 'row' }} contentContainerStyle={{ justifyContent: 'flex-end', gap: 5, margin: 10 }} ref={scrollViewRef} horizontal={true} showsHorizontalScrollIndicator={false}>
            {/* <View style={{ flexDirection: 'row', gap: 5, margin: 5 }}> */}
            {/* <View style={{ flexDirection: 'row', gap: 5, margin: 5, justifyContent: 'flex-end' }}> */}
            {companies && companies?.map((company, index) => (
              <View key={company._id}>
                {company._id == selectedCompany?._id ? null : <>
                  {messageUpdateCompanyIds.includes(company._id) ? <>
                    <View style={{ position: 'absolute', top: -5, left: 0, zIndex: 1000 }}>
                      <MessageIcon
                        width={20} height={20} />
                    </View>
                  </> : null}
                  {jobUpdateCompanyIds.includes(company._id) ? <>
                    <View style={{ position: 'absolute', top: -5, left: 25, zIndex: 1000 }}>
                      <JobIcon
                        width={20} height={20} />
                    </View>
                  </> : null}
                </>}
                <Button
                  // size='small'
                  style={{ width: 100, numberOfLines: 1, maxHeight: 40 }}
                  appearance={company._id === selectedCompany?._id ? 'filled' : 'outline'}
                  status={company._id === selectedCompany?._id ? 'success' : 'basic'}
                  // style={{ marginRight: 8 }}
                  onPress={() => {
                    onSelectCompany(company)
                    // console.log("company set to: " + company.name)
                  }
                  }>
                  <View style={{ position: "absolute", top: -10, right: 0 }}>
                    <ErrorBoundary>
                      <View style={{ flexDirection: "row" }}>
                        <RenderStatusCountBadge company={company} status={"request"} jobs={company.jobs || []} />
                        <RenderStatusCountBadge company={company} status={"unassigned"} jobs={company.jobs || []} />
                        <RenderStatusCountBadge company={company} status={"assigned"} jobs={company.jobs || []} />
                        <RenderStatusCountBadge company={company} status={"pending"} jobs={company.jobs || []} />
                      </View>
                    </ErrorBoundary>
                  </View>
                  <View style={{ position: "absolute", bottom: -15, right: 0 }}>
                    <ErrorBoundary>
                      <View style={{ flexDirection: "row" }}>
                        <RenderStatusCountBadge company={company} status={"request"} jobs={company.jobs || []} scheduled={true} />
                        <RenderStatusCountBadge company={company} status={"unassigned"} jobs={company.jobs || []} scheduled={true} />
                        <RenderStatusCountBadge company={company} status={"assigned"} jobs={company.jobs || []} scheduled={true} />
                        <RenderStatusCountBadge company={company} status={"pending"} jobs={company.jobs || []} scheduled={true} />
                      </View>
                    </ErrorBoundary>
                  </View>
                  {company.name}
                </Button>
              </View>
            ))}
            {/* </View> */}
          </ScrollView>
        </View>
        {companyCount > 5 &&
          <Button
            style={{ width: 40, height: "100%", borderRadius: 5 }}
            appearance='outline'
            status='info'
            onPress={() => {
              scrollForward()
            }}
          >
            <Icon name='arrow-ios-forward' fill='white' width={20} height={20} />
          </Button>
        }
      </View>
    </View>
  )

}

const styles = StyleSheet.create({
  container: {
    // flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    // padding: 10,
    // backgroundColor: 'white'
  },
  modalCard: {
    width: 300,
    height: 400,
    // padding: 10,
    // backgroundColor: 'white'
  },
  backdrop: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  // container: {
  //   flex: 1,
  //   justifyContent: 'center',
  //   alignItems: 'center',
  // },
  // backdrop: {
  //   backgroundColor: 'rgba(0, 0, 0, 0.5)',
  // },
  // modalCard: {
  //   width: 256,
  // },
});
