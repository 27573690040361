import { cloneElement } from 'react';
import { Job } from '../../models/Job.model';
import { Holding } from '../../models/Holding.model';
import { HoldingInvoiceRow, JobInvoiceRow } from './invoicingTypes';

export async function exportToCsv(
    gridElement: any,
    fileName: string
) {
    const { head, body, foot } = await getGridContent(gridElement);
    const content = [...head, ...body, ...foot]
        .map((cells) => cells.map(serialiseCellValue).join(','))
        .join('\n');

    downloadFile(fileName, new Blob([content], { type: 'text/csv;charset=utf-8;' }));
}

export async function exportToXlsx(
    gridElement: any,
    fileName: string
) {
    const [{ utils, writeFile }, { head, body, foot }] = await Promise.all([
        //@ts-ignore
        import('xlsx'),
        getGridContent(gridElement)
    ]);
    const wb = utils.book_new();
    const ws = utils.aoa_to_sheet([...head, ...body, ...foot]);
    utils.book_append_sheet(wb, ws, 'Sheet 1');
    writeFile(wb, fileName);
}

export async function exportToPdf(
    gridElement: any,
    fileName: string
) {
    const [{ jsPDF }, autoTable, { head, body, foot }] = await Promise.all([
        //@ts-ignore
        import('jspdf'),
        //@ts-ignore
        (await import('jspdf-autotable')).default,
        await getGridContent(gridElement)
    ]);
    const doc = new jsPDF({
        orientation: 'l',
        unit: 'px'
    });

    autoTable(doc, {
        head,
        body,
        foot,
        horizontalPageBreak: true,
        styles: { cellPadding: 1.5, fontSize: 8, cellWidth: 'wrap' },
        tableWidth: 'wrap'
    });
    doc.save(fileName);
}

async function getGridContent(gridElement: any) {
    //@ts-ignore
    const { renderToStaticMarkup } = await import('react-dom/server');
    const grid = document.createElement('div');
    grid.innerHTML = renderToStaticMarkup(
        cloneElement(gridElement, {
            enableVirtualization: false
        })
    );

    return {
        head: getRows('.rdg-header-row'),
        body: getRows('.rdg-row:not(.rdg-summary-row)'),
        foot: getRows('.rdg-summary-row')
    };

    function getRows(selector: any) {
        return Array.from(grid.querySelectorAll<HTMLDivElement>(selector)).map((gridRow) => {
            return Array.from(gridRow.querySelectorAll<HTMLDivElement>('.rdg-cell')).map(
                (gridCell) => gridCell.innerText
            );
        });
    }
}

function serialiseCellValue(value: any) {
    if (typeof value === 'string') {
        const formattedValue = value.replace(/"/g, '""');
        return formattedValue.includes(',') ? `"${formattedValue}"` : formattedValue;
    }
    return value;
}

function downloadFile(fileName: string, data: any) {
    const downloadLink = document.createElement('a');
    downloadLink.download = fileName;
    const url = URL.createObjectURL(data);
    downloadLink.href = url;
    downloadLink.click();
    URL.revokeObjectURL(url);
}


export function formatTimestamp(timestamp: any): string {
    const date = new Date(timestamp);
    return Intl.DateTimeFormat('en-AU', {
        year: '2-digit',
        month: 'short',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
    }).format(date);
}


export const formatDate = (date: Date | number | string) => {
    if (!date) return "N/A";
    return new Date(date).toLocaleString('en-AU', {
        hour: '2-digit',
        minute: '2-digit',
        day: 'numeric',
        month: 'short',
        year: '2-digit',
        hour12: true
    })
}

/*
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
//////// UTILS
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
*/

export const generateInvoiceNumber = (job_id: string, createdAt: string, jobCount: number) => {

    // Get current year and month
    // Parse the date string
    const date = new Date(createdAt);
    let jobnumber = ""
    let jobNo = ""
    let jobNoDecimal = 0
    let invoiceNumber = ""
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    if (jobCount) {
        jobCount = jobCount % 100000;
        jobnumber += jobCount.toString().padStart(5, "0");
        invoiceNumber = `${year}-${month}-${jobnumber}`;
    } else {
        jobnumber = ""
        jobNo = job_id.substr(-4)
        jobNoDecimal = parseInt(jobNo, 16);
        invoiceNumber = `${year}_${month}_${jobNoDecimal}`;
    }
    return invoiceNumber;
}


export const getCustomerPaidCardAmount = (row: JobInvoiceRow) => {
    if (row.isSubRow) {
        return 0;
    }
    else {
        try {
            if (row.details?.report) {
                if (row.details.report.customerPaidCard) {
                    if (!row.details.report.customerPaidCardAmount) return 0;
                    const cardAmount = parseFloat(row.details.report.customerPaidCardAmount.toString() || "0");
                    if (isNaN(cardAmount)) return 0;
                    return cardAmount.toFixed(2);
                }

            }

        } catch (err) {
            console.log("err", err)
        }
        return 0;
    }
}

export const getCustomerPaidCashAmount = (row: JobInvoiceRow) => {
    if (row.isSubRow) {
        return 0;
    }
    else {
        try {
            if (row.details?.report) {
                if (row.details.report.customerPaidCash) {
                    if (!row.details.report?.customerPaidCashAmount || row.details.report?.customerPaidCashAmount?.toString() == "") return 0;
                    const cashAmount = parseFloat(row.details.report.customerPaidCashAmount.toString());
                    if (isNaN(cashAmount)) return 0;
                    return cashAmount.toFixed(2);
                }
            }
        } catch (err) {
            console.log("err", err)
        }
        return 0;
    }
}


export const getTotalCostItems = (row: JobInvoiceRow | HoldingInvoiceRow) => {
    if (row.isSubRow && row.isItemRow) {
        // console.log("row", row)
        const cost = parseFloat(row.cost);
        const quantity = parseFloat(row.quantity);
        if (isNaN(cost) || isNaN(quantity)) return 0;

        const total = cost * quantity;
        if (isNaN(total)) return 0;

        return total.toFixed(2);
    }
    else {
        return 0
    }
}

export const getTotalItemBillAllBack = (row: JobInvoiceRow | HoldingInvoiceRow) => {
    if (row.isSubRow && row.isItemRow) {
        const cost = parseFloat(row.cost);
        const quantity = parseFloat(row.quantity);
        if (isNaN(cost) || isNaN(quantity)) return 0;

        const total = cost * quantity;
        if (isNaN(total)) return 0;

        if (row.billAllBack) {
            return total.toFixed(2);
        } else {
            return 0;
        }
        return 0;
    }
    else {
        return 0
    }
}

export const getTotalItemCustomerCost = (row: JobInvoiceRow | HoldingInvoiceRow) => {
    if (row.isSubRow && row.isItemRow) {
        const cost = parseFloat(row.cost);
        const quantity = parseFloat(row.quantity);
        if (isNaN(cost) || isNaN(quantity)) return 0;

        const total = cost * quantity;
        if (isNaN(total)) return 0;

        if (row.customerCost) {
            return total.toFixed(2);
        } else {
            return 0;
        }
    }
    else {
        return 0
    }
}


export const getTotalClientRate = (row: JobInvoiceRow | HoldingInvoiceRow) => {
    if (row.isSubRow && row.isItemRow) {
        return 0
    }
    else {
        if (row.details?.hasOwnProperty("client_rate")) {
            return row.details.client_rate.cost;
        }
        else {
            return 0;
        }
    }
}


export const generateCsvData = (jobs: Job[]) => {
    // Flatten all job entries
    const flattenedJobs = jobs.map((job: Job) => {
        job = new Job(job)
        return job.toCsvArray()
    });
    return flattenedJobs;
};