import React, { useMemo, useState, useEffect } from 'react';
import {
    StyleSheet,
    View,
    TouchableOpacity,
    //@ts-ignore
} from "react-native";
import {
    Layout,
    Text,
    List,
    Card,
    useTheme,
    Icon,
} from "@ui-kitten/components";
import { Route, RouteLocation, RouteLeg, ROUTE_STATUS } from '../../models/Route.model';
import { RouteSummary } from './RouteSummary';
import { Member } from '../../models/Member.model';
import * as StorageController from '../../functions/storageController';

interface RoutesListProps {
    routes: Route[];
    selectedRoute: Route | null;
    onSelectRoute?: (route: Route | null) => void; 
    onSelectJobId?: (job_id: string) => void;
    onUpdateRouteStatus: (route_id: string, status: ROUTE_STATUS) => void;
    onEndReached?: () => void;
}

export const RoutesList = ({ routes, selectedRoute, onSelectRoute, onSelectJobId, onUpdateRouteStatus, onEndReached }: RoutesListProps) => {


    
    const [selectedRouteState, setSelectedRoute] = useState(selectedRoute as Route | null);

    useEffect(() => {
        setSelectedRoute(selectedRoute);
    }, [selectedRoute]);

    const handleSelectRoute = (route: Route) => {
        if (route._id == selectedRouteState?._id) {
            setSelectedRoute(null);
            onSelectRoute && onSelectRoute(null);
        } else {
            setSelectedRoute(route);
            onSelectRoute && onSelectRoute(route);
        }
    };

    const renderItem = ({ item }: { item: Route }) => (
        <RouteCard
            route={item}
            isSelected={selectedRouteState?._id === item._id}
            onSelectRoute={handleSelectRoute}
            onSelectJobId={onSelectJobId || (() => { })}
            onUpdateRouteStatus={onUpdateRouteStatus}  // Pass this prop
        />
    );



    return (
        <Layout style={styles.container}>
            <List
                data={routes}
                renderItem={renderItem}
                keyExtractor={(item: Route) => item._id}
                contentContainerStyle={styles.listContent}
                onEndReached={onEndReached ? onEndReached : () => { }}
            />
        </Layout>
    );
};

interface RouteCardProps {
    route: Route;
    isSelected: boolean;
    onSelectRoute: (route: Route) => void;
    onSelectJobId: (job_id: string) => void;
    onUpdateRouteStatus: (route_id: string, status: ROUTE_STATUS) => void;  // Add this prop
}

const RouteCard: React.FC<RouteCardProps> = ({ route, isSelected, onSelectRoute, onSelectJobId, onUpdateRouteStatus }) => {
    const theme = useTheme() as any
    const drivers = StorageController.getAppState().selectedCompany?.members.filter((member: Member) => member.is_driver) || [];
    return (
        <View style={[styles.card, isSelected ? {
            borderColor: theme['color-primary-500'],
            borderWidth: 2,
            borderRadius: 8
        } : {}]}>
            <RouteSummary
                route={route}
                routeReadyForCreation={true}
                onCreateRoute={() => { }}
                onAssignDriver={() => { }}
                isRouteCreated={true}
                onUpdatePlannedStartTime={() => { }}
                onSelectRoute={onSelectRoute}
                onSelectJobId={onSelectJobId}
                onUpdateRouteStatus={onUpdateRouteStatus}  // Pass this prop
            />
        </View>
    );
};

const LocationItem: React.FC<{ location: RouteLocation; index: number }> = ({ location, index }) => (
    <View style={styles.locationItem}>
        <Text category="s1">{index + 1}. {location.name}</Text>
        <Text category="c1">{location.address.addressToString()}</Text>
    </View>
);

const LegItem: React.FC<{ leg: RouteLeg; index: number }> = ({ leg, index }) => (
    <View style={styles.legItem}>
        <Text category="s1">Leg {index + 1}</Text>
        <Text category="c1">Distance: {leg.actual_distance_kms?.toFixed(2)} km</Text>
        <Text category="c1">Duration: {(leg.actual_duration_seconds || 0 / 60).toFixed(2)} min</Text>
    </View>
);

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    listContent: {
        padding: 16,
    },
    card: {
        marginBottom: 16,
    },
    sectionTitle: {
        marginTop: 16,
        marginBottom: 8,
    },
    locationItem: {
        marginBottom: 8,
    },
    legItem: {
        marginBottom: 8,
        paddingLeft: 16,
    },

});

export default RoutesList;
