import React from 'react';
//@ts-ignore
import { View } from 'react-native';
import { Modal, Card, Text, Button } from "@ui-kitten/components";

interface ConfirmRemoveRouteLocationModalProps {
    visible: boolean;
    onClose: () => void;
    onRemove: () => void;
}

export const ConfirmRemoveRouteLocationModal = ({
    visible,
    onClose,
    onRemove
}: ConfirmRemoveRouteLocationModalProps) => {
    return (
        <Modal
            visible={visible}
            backdropStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
            onBackdropPress={onClose}
        >
            <Card
                status='warning'
                disabled={true}>
                <Text>Are you sure you want to remove this location?</Text>
                <View style={{ height: 25 }}></View>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Button
                        status='danger'
                        onPress={() => {
                            onRemove();
                            onClose();
                        }}>Remove</Button>
                    <Button
                        appearance='ghost'
                        status='warning'
                        onPress={onClose}>Cancel</Button>
                </View>
            </Card>
        </Modal>
    );
};