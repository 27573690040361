var config = require('../config/config.js');
const axios = require('axios');
import { Route } from '../models/Route.model';
import { Job } from '../models/Job.model';
import * as StorageController from './storageController';
import * as JobController from './job.controller';


var api = config.api;
var testApi = config.test_api;

async function getApi() {
    if (await StorageController.getUseTestApi()) {
        return testApi
    }
    return api
}

//==============================================================
//==============================================================
// ROUTES
//==============================================================
//==============================================================
axios.interceptors.request.use(
    (config: any) => {
        let token = getToken();
        let member_id = null;
        if (StorageController.getAppState().selectedMembership != null) {
            member_id = StorageController.getAppState().selectedMembership?._id;
        }
        config.headers.authorization = `Bearer ${token}`;
        config.headers['member-id'] = member_id;
        return config;
    },
    (error: any) => {
        return Promise.reject(error);
    }
);
const getToken = async () => {
    return await StorageController.getAccessToken() || null;
}

// Create Route
export async function createRoute(routeObj: Route): Promise<Route | null> {
    try {
        const response = await axios.post(await getApi() + "/route", routeObj);
        return new Route(response.data);
    } catch (error) {
        console.error(error);
        return null
    }
}

// Update Route
export async function updateRoute(routeObj: Route) {
    try {
        const response = await axios.put(await getApi() + "/route/" + routeObj._id, routeObj);
        return response.data;
    } catch (error) {
        console.error(error);
    }
}

// Get Route by ID
export async function getRouteById(routeId: string) {
    try {
        const response = await axios.get(await getApi() + "/route/" + routeId);
        let routeObj = new Route(response.data);
        return routeObj;
    } catch (error) {
        console.error(error);
    }
}

// Delete Route
export async function deleteRoute(routeId: string) {
    try {
        const response = await axios.delete(await getApi() + "/route/" + routeId);
        return response.data;
    } catch (error) {
        console.error(error);
    }
}

// Find Routes by Job ID
export async function getRouteByJobId(jobId: string) {
    try {
        const response = await axios.get(await getApi() + "/route/job/" + jobId);
        return new Route(response.data);
    } catch (error) {
        console.error(error);
    }
}

// Add Job to Route
export async function addJobToRoute(routeId: string, jobId: string) {
    try {
        const response = await axios.put(await getApi() + "/route/" + routeId + "/job/" + jobId);
        return new Route(response.data);
    } catch (error) {
        console.error(error);
    }
}

// Remove Job from Route
export async function removeJobFromRoute(routeId: string, jobId: string) {
    try {
        const response = await axios.put(await getApi() + "/route/" + routeId + "/remove_job/" + jobId);
        return response.data;
    } catch (error) {
        console.error(error);
    }
}

// Find Routes by Company ID
export async function getRoutesByCompanyId(companyId: string) {
    try {
        const response = await axios.get(await getApi() + "/route/company/" + companyId);
        const routes = response.data?.map((routeObj: any) => {
            return new Route(routeObj);
        });
        return routes;
    } catch (error) {
        console.error(error);
    }
}

// Find Routes by Company ID and Date Range
export async function getRoutesByCompanyIdAndDateRange(companyId: string, startDate: any, endDate: any) {
    var body = {
        company_id: companyId,
        start_date: startDate,
        end_date: endDate,
    };
    try {
        const response = await axios.post(await getApi() + "/route/company/dates", body);
        const routes = response.data?.map((routeObj: any) => {
            return new Route(routeObj);
        });
        return routes;
    } catch (error) {
        console.error(error);
    }
}

// Find Routes by Company ID and Status
export async function getRoutesByCompanyIdAndStatus(companyId: string, status: string, limit = 1000, offset = 0) {
    const body = {
        company_id: companyId,
        status: status,
        limit: limit,
        offset: offset
    };
    try {
        const response = await axios.post(await getApi() + "/route/company/status", body);
        const routes = response.data.map((routeObj: any) => {
            return new Route(routeObj);
        });
        return routes;
    } catch (error) {
        console.error(error);
    }
}

// Set Route Status
export async function setRouteStatus(routeId: string, status: string) {
    const body = {
        route_id: routeId,
        status: status,
    };
    try {
        const response = await axios.put(await getApi() + `/route/status/${status}`, body);
        return new Route(response.data);
    } catch (error) {
        console.error(error);
    }
}

// Assign Driver to Route
export async function assignDriverToRoute(routeId: string, driverId: string) {
    try {
        // use update route function
        let route = await getRouteById(routeId) as Route;
        if (!route) {
            throw new Error("Route not found");
        }
        route.member_id = driverId;
        const updatedRoute = await updateRoute(route);
        return updatedRoute;
    } catch (error) {
        console.error("Error assigning driver to route:", error);
        throw error;
    }
}

export async function assignDriverToAllJobsInRoute(routeId: string, driverId: string) {
    try {
        let route = await getRouteById(routeId) as Route;
        if (!route) {
            throw new Error("Route not found");
        }
        await JobController.assignMultipleJobsToMember(route.job_ids, driverId, route.company_id)
    } catch (error) {
        console.error("Error assigning driver to all jobs in route:", error);
        throw error;
    }
}
