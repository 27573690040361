import React, { useState, useEffect, useRef, useReducer, useMemo, useCallback } from 'react';
//@ts-ignore
import { StyleSheet, View, ScrollView } from 'react-native';
// @ts-ignore
import {
    ApplicationProvider,
    Button,
    Icon,
    IconRegistry,
    Divider,
    Input,
    Select,
    SelectItem,
    useTheme,
    Layout,
    Text,
    List,
    ListItem,
    OverflowMenu,
    MenuItem,
    Card,
    Toggle,
    Radio,
    RadioGroup,
    Modal
} from '@ui-kitten/components';
import { GoogleMap, useJsApiLoader, Marker, InfoWindow, TransitLayer, DirectionsService, DirectionsRenderer, TrafficLayer, MarkerClusterer, Polygon, Polyline, OverlayView } from '@react-google-maps/api';
import * as UpdateController from '../../../functions/update.controller';
import * as UserController from '../../../functions/user.controller';
import * as StorageController from "../../../functions/storageController"
import * as MessageController from "../../../functions/message.controller"
import * as GoogleController from "../../../functions/google.controller"
import * as JobsController from '../../../functions/job.controller'
import ErrorBoundary from '../../ErrorBoundary.component';
//@ts-ignore
import _ from 'lodash'
//@ts-ignore
import debounce from 'lodash.debounce';
import Toast from 'react-native-toast-message';
import { AddressSearch } from '../searchAddress.component';
import DistanceTool from './../distanceTool.component';
import { RoadsideJobInfo } from './../RoadsideJobInfo.component';
import { TowingJobInfo } from './../TowingJobInfo.component';
import { MemberDeviceInfoCard } from '../../members/memberDeviceInfoCard.component';
import { Job, JobTowingDetails, TowingLeg } from '../../../models/Job.model';
import { Member } from '../../../models/Member.model';
import { Company } from '../../../models/Company.model'
import { darkMapStyles, lightMapStyles } from './../mapStyles';





export const RenderJobIcon = (status: any) => {
    if (status == "pending") {
        // return { url: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png" }
        return {
            path: google.maps.SymbolPath.CIRCLE,
            scale: 10,
            fillColor: "blue",
            fillOpacity: 0.5,
            strokeColor: "black",
            strokeWeight: 5,
            strokeOpacity: 1,
        }
    }
    else if (status == "assigned") {
        // return { url: "http://maps.google.com/mapfiles/ms/icons/yellow-dot.png" }
        // https://www.svgrepo.com/svg/56310/starfish?edit=true
        const googleMapsIconPath = "M10,0 L13.09,6.36 L20,7.27 L15.45,12.12 L16.36,19 L10,16.36 L3.64,19 L4.55,12.12 L0,7.27 L6.91,6.36 L10,0";
        const star2 = `<svg version="1.0" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 64.00 64.00" enable-background="new 0 0 64 64" xml:space="preserve" fill="#000000" stroke="#000000" stroke-width="1.216"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="#c8ff00" stroke-width="0.128"></g><g id="SVGRepo_iconCarrier"> <g> <path fill="#394240" d="M31.998,2.478c0.279,0,0.463,0.509,0.463,0.509l8.806,18.759l20.729,3.167l-14.999,15.38l3.541,21.701 l-18.54-10.254l-18.54,10.254l3.541-21.701L2,24.912l20.729-3.167l8.798-18.743C31.527,3.002,31.719,2.478,31.998,2.478 M31.998,0 c-0.775,0-1.48,0.448-1.811,1.15l-8.815,18.778L1.698,22.935c-0.741,0.113-1.356,0.632-1.595,1.343 c-0.238,0.71-0.059,1.494,0.465,2.031l14.294,14.657L11.484,61.67c-0.124,0.756,0.195,1.517,0.822,1.957 c0.344,0.243,0.747,0.366,1.151,0.366c0.332,0,0.666-0.084,0.968-0.25l17.572-9.719l17.572,9.719 c0.302,0.166,0.636,0.25,0.968,0.25c0.404,0,0.808-0.123,1.151-0.366c0.627-0.44,0.946-1.201,0.822-1.957l-3.378-20.704 l14.294-14.657c0.523-0.537,0.703-1.321,0.465-2.031c-0.238-0.711-0.854-1.229-1.595-1.343l-19.674-3.006L33.809,1.15 C33.479,0.448,32.773,0,31.998,0L31.998,0z"></path> <path fill="#ffcc00" d="M31.998,2.478c0.279,0,0.463,0.509,0.463,0.509l8.806,18.759l20.729,3.167l-14.999,15.38l3.541,21.701 l-18.54-10.254l-18.54,10.254l3.541-21.701L2,24.912l20.729-3.167l8.798-18.743C31.527,3.002,31.719,2.478,31.998,2.478"></path> </g> </g></svg>`
        const url = 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(star2);
        return {
            // path: google.maps.SymbolPath.CIRCLE,
            // scale: 10,
            fillColor: "yellow",
            fillOpacity: 0.5,
            strokeColor: "black",
            strokeWeight: 5,
            strokeOpacity: 1,
            url: url,
            // anchor: new google.maps.Point(10, 10), // Adjust the anchor point to center the icon on the marker
            scaledSize: new google.maps.Size(30, 30), // Adjust the size as needed
        }



    }
    else if (status == "complete") {
        // return { url: "http://maps.google.com/mapfiles/ms/icons/green-dot.png" }
        return {
            path: google.maps.SymbolPath.CIRCLE,
            scale: 10,
            fillColor: "green",
            fillOpacity: 1,
            strokeColor: "black",
            strokeWeight: 5,
            strokeOpacity: 1,
        }
    }
    else if (status == "unassigned") {
        // return {url: "http://maps.google.com/mapfiles/ms/icons/red-dot.png"}
        return {
            path: google.maps.SymbolPath.CIRCLE,
            scale: 10,
            fillColor: "red",
            fillOpacity: 0.5,
            strokeColor: "black",
            strokeWeight: 5,
            strokeOpacity: 1,
        }
    }
    else if (status == "cancelled") {
        return {
            path: google.maps.SymbolPath.CIRCLE,
            scale: 10,
            fillColor: "purple",
            fillOpacity: 0.5,
            strokeColor: "black",
            strokeWeight: 5,
            strokeOpacity: 1,
        }
    }
}