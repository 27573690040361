import React from 'react';
import { View } from 'react-native';
import { Button } from "@ui-kitten/components";

export enum CASE_STATUS_FILTER {
    ONGOING = "ongoing",
    PENDING = "pending",
    COMPLETE = "complete",
    CANCELLED = "cancelled"
}

interface CaseStatusFilterButtonsProps {
    caseFilterApplied: CASE_STATUS_FILTER;
    setCaseFilterApplied: (filter: CASE_STATUS_FILTER) => void;
}

export const CaseStatusFilterButtons = ({ caseFilterApplied, setCaseFilterApplied }: CaseStatusFilterButtonsProps) => {
    return (
        <View style={{ flexDirection: 'row', flex: 1, gap: 10 }}>
            <Button size='small' appearance={caseFilterApplied == CASE_STATUS_FILTER.ONGOING ? 'filled' : 'outline'} status='assigned' onPress={() => setCaseFilterApplied(CASE_STATUS_FILTER.ONGOING)}>Ongoing</Button>
            <Button size='small' appearance={caseFilterApplied == CASE_STATUS_FILTER.PENDING ? 'filled' : 'outline'} status='pending' onPress={() => setCaseFilterApplied(CASE_STATUS_FILTER.PENDING)}>Pending</Button>
            <Button size='small' appearance={caseFilterApplied == CASE_STATUS_FILTER.COMPLETE ? 'filled' : 'outline'} status='complete' onPress={() => setCaseFilterApplied(CASE_STATUS_FILTER.COMPLETE)}>Complete</Button>
            <Button size='small' appearance={caseFilterApplied == CASE_STATUS_FILTER.CANCELLED ? 'filled' : 'outline'} status='cancelled' onPress={() => setCaseFilterApplied(CASE_STATUS_FILTER.CANCELLED)}>Cancelled</Button>
        </View>
    );
};