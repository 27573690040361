import React, { useState, useEffect, useMemo } from 'react';
import {
    StyleSheet,
    View,
    //@ts-ignore
} from "react-native";
import {
    Card,
    Button,
    Icon,
    Input,
    List,
    ListItem,
    Divider,
    Text,
    Layout,
} from "@ui-kitten/components";
import * as StorageController from '../../../functions/storageController';
import * as CompanyController from '../../../functions/company.controller';
import ErrorBoundary from '../../ErrorBoundary.component';
import Toast from 'react-native-toast-message';
import { LineItem, Job, LINE_ITEM_BILLED_TO } from '../../../models/Job.model';
import { Company, InventoryItem } from '../../../models/Company.model';
import { VendorProfile } from '../../../models/VendorProfile.model';
//@ts-ignore
import { ListItemProps } from '@ui-kitten/components';


interface InventoryDetailsCardProps {
    style?: any;
    selectedItems: LineItem[];
    onChangeSelectedItems: (items: LineItem[]) => void;
    vendorProfile: VendorProfile | null | undefined;
    jobSource: string;
    allowEdit?: boolean;
}

export const InventoryDetailsCard = ({
    style,
    selectedItems = [],
    onChangeSelectedItems = (items) => { },
    vendorProfile,
    jobSource,
    allowEdit = true
}: InventoryDetailsCardProps) => {

    const [customLineItem, setCustomLineItem] = useState<LineItem>(new LineItem());
    const [inventoryCollapsed, setInventoryCollapsed] = useState(true);
    const [companyInventoryList, setCompanyInventoryList] = useState<InventoryItem[]>([]);
    const [searchInventoryTerm, setSearchInventoryTerm] = useState("");
    const [isFilteredInventoryList, setIsFilteredInventoryList] = useState(false);
    const [filteredInventoryList, setFilteredInventoryList] = useState<InventoryItem[]>([]);

    const [totalSelectedInventoryCost, setTotalSelectedInventoryCost] = useState("0.00");
    const [selectedLineItems, setSelectedLineItems] = useState<LineItem[]>(selectedItems);


    useEffect(() => {
        loadVendorInventory()
    }, [])



    useEffect(() => {
        // Added here because broke on holding item removing?
        setSelectedLineItems(selectedItems)
    }, [selectedItems])


    /**
    * Update the selected inventory list when the selected inventory items change
    */
    useEffect(() => {
        if (selectedItems != null && selectedItems.length > 0) {

            let total = 0;
            selectedItems.forEach(item => {
                if (!item.quantity) item.quantity = 1;
                if (!item.cost) item.cost = 0;
                total += item.cost * item.quantity;
            });
            setSelectedLineItems(selectedItems)
            setTotalSelectedInventoryCost(total.toFixed(2));
        }
    }, [selectedItems]);


    const onSetSelectedItems = (items: LineItem[]) => {
        onChangeSelectedItems(items);
    };


    const memoBillBackItems = useMemo(() => {
        return selectedLineItems.filter(item => item.billAllBack)
    }, [selectedLineItems])

    const memoCustomerCostItems = useMemo(() => {
        return selectedLineItems.filter(item => item.customerCost)
    }, [selectedLineItems])

    const memoBillBackItemsTotal = useMemo(() => {
        let total = 0;
        memoBillBackItems.forEach(item => {
            total += item.cost * item.quantity;
        });
        return total.toFixed(2);
    }, [memoBillBackItems])

    const memoCustomerCostItemsTotal = useMemo(() => {
        let total = 0;
        memoCustomerCostItems.forEach(item => {
            total += item.cost * item.quantity;
        });
        return total.toFixed(2);
    }, [memoCustomerCostItems])


    const onAddItemToSelectedItems = (item: LineItem) => {
        let newSelectedItems = [...selectedItems, item]
        onSetSelectedItems(newSelectedItems)
        Toast.show({
            type: 'success',
            position: 'bottom',
            text1: 'Item Added',
            text2: `${item.name} added to selected items`,
            visibilityTime: 1000,
            autoHide: true,
        });
    }

    const onUpdateItemInSelectedItems = (itemIndex: number, item: LineItem) => {
        const newSelectedItems = [...selectedItems];
        newSelectedItems[itemIndex] = item;
        onSetSelectedItems(newSelectedItems);
    };

    const onRemoveItemFromSelectedItems = (itemIndex: number) => {
        const newSelectedItems = [...selectedItems];
        newSelectedItems.splice(itemIndex, 1);
        onSetSelectedItems(newSelectedItems);
        Toast.show({
            type: 'success',
            position: 'bottom',
            text1: 'Item Removed',
            text2: `${selectedItems[itemIndex].name} removed from selected items`,
            visibilityTime: 1000,
            autoHide: true,
        });
    };


    const loadVendorInventory = async () => {
        if (jobSource === "client" && vendorProfile && vendorProfile.linkedVendorAccountId) {
            const linkedCompany = await CompanyController.getCompanyById(vendorProfile.linkedVendorAccountId) as Company;
            if (linkedCompany?.inventory?.length > 0) {
                const inv = linkedCompany.inventory.map(item => new InventoryItem(item));
                setCompanyInventoryList(inv);
            }
        } else if (jobSource === "vendor" || jobSource === "local_client") {
            setCompanyInventoryList(StorageController.getCurrentCompany().inventory || []);
        } else {
            setCompanyInventoryList([]);
        }
    };


    // /**
    //  * Loads the vendor inventory list
    //  */
    // const loadVendorInventory = async () => {
    //     //if linked get company inventory
    //     if (jobSource == "client") {
    //         if (vendorProfile.linkedVendorAccountId) {
    //             const linkedCompany = await CompanyController.getCompanyById(vendorProfile.linkedVendorAccountId)
    //             if (linkedCompany) {
    //                 if (linkedCompany.inventory && linkedCompany.inventory.length > 0) {
    //                     let inv = [...linkedCompany.inventory]
    //                     inv.forEach(item => {
    //                         item.quantity = 1
    //                         item.customerCost = false
    //                         item.billAllBack = true
    //                         item.billing = "Bill All Back"
    //                         item.description = item.name
    //                     })
    //                     inv.map(item => new LineItem(item))
    //                     setCompanyInventoryList(inv)
    //                 }
    //             }
    //         }
    //         // if no linked get vendor inventory
    //         if (!vendorProfile.linkedVendorAccountId) {
    //             setCompanyInventoryList(vendorProfile.inventory || [])
    //         }
    //     }
    //     if (jobSource == "vendor" || jobSource == "local_client") {
    //         setCompanyInventoryList(StorageController.getCurrentCompany().inventory || [])
    //     }
    // }



    const updateQuantity = (index: number, newQuantity: number) => {
        const updatedItems = [...companyInventoryList];
        updatedItems[index].quantity = newQuantity;
        setCompanyInventoryList(updatedItems);
    };


    const onSearchInventoryTermChanged = (value: string) => {
        setSearchInventoryTerm(value);
        if (value === "") {
            setIsFilteredInventoryList(false);
            setFilteredInventoryList([]);
        } else {
            setIsFilteredInventoryList(true);
            const filtered = companyInventoryList.filter(item => item.name.toLowerCase().includes(value.toLowerCase()));
            setFilteredInventoryList(filtered);
        }
    };





    return (
        <Card
            status="success"
            header={() => <Text>Add Items</Text>}
            disabled={true}
            style={style}
        >
            <View style={[
                styles.horizontalLayout,
                {
                    alignItems: 'flex-start',
                    margin: -10
                }
            ]}>
                {/* Make a list of inventory items that add to a list here */}
                <View style={{ flex: 1, minWidth: 150 }}>
                    <Card
                        status='warning'
                        style={styles.groupContainer}
                        header={() => <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                            <Text>Inventory</Text>
                            {
                                inventoryCollapsed ?
                                    <Button
                                        size='tiny'
                                        status='warning'
                                        appearance='outline'
                                        onPress={() => setInventoryCollapsed(false)}
                                        style={{ alignSelf: 'flex-end' }}
                                        accessoryRight={<Icon name='arrow-ios-downward-outline' width={24} height={24} fill="#fff" />}
                                    >
                                        Show Inventory
                                    </Button>
                                    :
                                    <Button
                                        size='tiny'
                                        status='warning'
                                        appearance='outline'
                                        onPress={() => setInventoryCollapsed(true)}
                                        style={{ alignSelf: 'flex-end' }}
                                        accessoryRight={<Icon name='arrow-ios-upward-outline' width={24} height={24} fill="#fff" />}
                                    >
                                        Hide Inventory
                                    </Button>
                            }
                        </View>}
                    >
                        {!inventoryCollapsed &&
                            <>
                                <View style={{ flex: 1, marginLeft: -25, marginRight: -25, flexDirection: 'row' }}>
                                    <Input
                                        size='small'
                                        placeholder='Search Inventory'
                                        label='Search Inventory'
                                        value={searchInventoryTerm}
                                        onChangeText={onSearchInventoryTermChanged}
                                        style={{ flex: 4 }}
                                    />
                                    <Button status="warning" appearance='outline' style={{ flex: 1, height: 30, alignSelf: 'flex-end', minHeight: 30 }} onPress={() => onSearchInventoryTermChanged("")}>Clear</Button>
                                </View>
                                {companyInventoryList && companyInventoryList.length > 0 &&
                                    <ErrorBoundary>
                                        <List
                                            style={{ flex: 1, marginLeft: -25, marginRight: -25, maxHeight: 500 }}
                                            ItemSeparatorComponent={Divider}
                                            data={isFilteredInventoryList ? filteredInventoryList : companyInventoryList}
                                            renderItem={({ item, index }: any) => (
                                                <RenderInventoryItem
                                                    item={item}
                                                    index={index}
                                                    onAddItemToSelectedItems={onAddItemToSelectedItems}
                                                    updateQuantity={updateQuantity}
                                                />
                                            )}
                                        />
                                    </ErrorBoundary>
                                }
                            </>
                        }
                        {!companyInventoryList &&
                            <Text>No inventory items found</Text>
                        }
                    </Card>
                </View>
                <View style={{ flexDirection: 'column', flex: inventoryCollapsed ? 3 : 1, minWidth: 350 }}>
                    {/* Card to input custom item, quantity, and cost */}
                    <AddCustomItemCard
                        onAddItemToSelectedItems={onAddItemToSelectedItems}
                    />

                    <Card
                        status='success'
                        header={() => <Text>Bill Back Items</Text>}
                        disabled={true}
                        style={styles.groupContainer}
                    >
                        <View
                            style={{ margin: -15 }}>
                            <List
                                style={{
                                    flex: 1,
                                    maxHeight: 500
                                }}
                                ItemSeparatorComponent={() => (
                                    <Layout style={{ width: '100%', height: 3 }}>
                                    </Layout>
                                )}
                                data={memoBillBackItems}
                                renderItem={({ item, index }: any) => (
                                    <RenderSelectedInventoryItem
                                        item={item}
                                        index={index}
                                        onRemoveItemFromSelectedItems={onRemoveItemFromSelectedItems}
                                        onUpdateItemInSelectedItems={onUpdateItemInSelectedItems}
                                        selectedLineItems={selectedLineItems}
                                    />
                                )}
                            />
                        </View>
                    </Card>
                    <Card
                        status='success'
                        header={() => <Text>Customer Cost Items</Text>}
                        disabled={true}
                        style={styles.groupContainer}
                    >
                        <View
                            style={{ margin: -15 }}>
                            <List
                                style={{
                                    flex: 1,
                                    maxHeight: 500
                                }}
                                ItemSeparatorComponent={() => (
                                    <Layout style={{ width: '100%', height: 3 }}>
                                    </Layout>
                                )}
                                data={memoCustomerCostItems}
                                renderItem={({ item, index }: any) => (
                                    <RenderSelectedInventoryItem
                                        item={item}
                                        index={index}
                                        onRemoveItemFromSelectedItems={onRemoveItemFromSelectedItems}
                                        onUpdateItemInSelectedItems={onUpdateItemInSelectedItems}
                                        selectedLineItems={selectedLineItems}
                                    />
                                )}
                            />
                        </View>
                    </Card>
                </View>
            </View>
            <View>
                {selectedLineItems.length > 0 &&
                    <Card
                        disabled
                        status='success'
                        header={() => <Text>Total</Text>}
                        style={styles.groupContainer}
                    >
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', borderColor: 'gray', borderWidth: 1 }}>
                            <View style={{ flex: 1, borderColor: 'gray', borderWidth: 1 }}>
                                <Text selectable>Bill Back Total:</Text>
                            </View>
                            <View style={{ flex: 3, borderColor: 'gray', borderWidth: 1 }}>
                                <Text selectable>${memoBillBackItemsTotal}</Text>
                            </View>
                        </View>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', borderColor: 'gray', borderWidth: 1 }}>
                            <View style={{ flex: 1, borderColor: 'gray', borderWidth: 1 }}>
                                <Text selectable>Customer Cost Total:</Text>
                            </View>
                            <View style={{ flex: 3, borderColor: 'gray', borderWidth: 1 }}>
                                <Text selectable>${memoCustomerCostItemsTotal}</Text>
                            </View>
                        </View>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', borderColor: 'gray', borderWidth: 1 }}>
                            <View style={{ flex: 1, borderColor: 'gray', borderWidth: 1 }}>
                                <Text selectable>Total:</Text>
                            </View>
                            <View style={{ flex: 3, borderColor: 'gray', borderWidth: 1 }}>
                                <Text selectable>${totalSelectedInventoryCost}</Text>
                            </View>
                        </View>
                    </Card>
                }
            </View>
        </Card>
    );

}

const AddCustomItemCard = ({
    onAddItemToSelectedItems,
}: {
    onAddItemToSelectedItems: (item: LineItem) => void,
}) => {

    const customLineItemRef = React.useRef<LineItem>(new LineItem())
    const nameRef = React.useRef(null as any)
    const quantityRef = React.useRef(null as any)
    const costRef = React.useRef(null as any)

    const handleAddLineItem = () => {
        if (!customLineItemRef.current.name) return
        // parse quantity and cost into numbers to check them
        if (isNaN(customLineItemRef.current.quantity)) customLineItemRef.current.quantity = 1
        if (isNaN(customLineItemRef.current.cost)) customLineItemRef.current.cost = 0
        customLineItemRef.current.billed_to = LINE_ITEM_BILLED_TO.CUSTOMER_COST

        const newLineItem = new LineItem(customLineItemRef.current)
        console.log("🚀============== ~ file: inventoryDetailsCard.component.tsx:434 ~ handleAddLineItem ~ newLineItem🚀==============", newLineItem)
        onAddItemToSelectedItems(newLineItem)
        customLineItemRef.current = new LineItem({})
        nameRef.current?.clear()
        quantityRef.current?.clear()
        costRef.current?.clear()
    }


    return (
        <Card
            disabled
            status='warning'
            header={() => <Text>Add Item</Text>}
            style={styles.groupContainer}
        >
            <View style={{ flexDirection: 'row', gap: 5, justifyContent: 'flex-start', alignItems: 'center' }}>
                <Input
                    ref={nameRef}
                    size='small'
                    placeholder='Name'
                    label='Name'
                    defaultValue={customLineItemRef.current.name || ""}
                    onChangeText={(value: string) => {
                        customLineItemRef.current.name = value
                        customLineItemRef.current.description = value
                    }}
                    style={{ flex: 1 }}
                />
                <Input
                    ref={quantityRef}
                    size='small'
                    placeholder='Quantity'
                    label='Quantity'
                    defaultValue={customLineItemRef.current.quantity?.toString() || "1"}
                    onChangeText={(value: any) => {
                        customLineItemRef.current.quantity = value
                    }}
                    style={{ flex: 1 }}
                />
                <Input
                    ref={costRef}
                    size='small'
                    placeholder='Cost'
                    label='Cost'
                    defaultValue={customLineItemRef.current.cost?.toString() || "0"}
                    onChangeText={(value: any) => {
                        customLineItemRef.current.cost = value
                    }}
                    style={{ flex: 1 }}
                />

                <Button
                    size='tiny'
                    status='success'
                    appearance='outline'
                    onPress={() => {
                        handleAddLineItem()
                    }}>
                    <Icon name='plus-outline' width={24} height={24} fill="#fff" />
                </Button>
            </View>
        </Card>
    )
}



const RenderInventoryItem = ({
    item,
    index,
    onAddItemToSelectedItems,
    updateQuantity,

}:
    {
        item: InventoryItem,
        index: number,
        onAddItemToSelectedItems: (item: LineItem) => void,
        updateQuantity: (index: number, newQuantity: number) => void,
    }
) => {
    return (
        <View key={index} style={{ padding: 3 }}>
            <View style={{ marginLeft: 4, flexDirection: 'row', justifyContent: 'flex-start', maxWidth: '100%' }}>
                <Text category='label' style={{ flex: 1 }}>{item.name}</Text>
            </View>
            <View style={{ marginLeft: 4, flexDirection: 'row', justifyContent: 'flex-end' }}>
                <Text style={{ flex: 3, alignSelf: 'center' }}>${item.cost}</Text>
                {/* Input field for the quantity */}
                <Input
                    placeholder='#'
                    style={{ flex: 1, maxWidth: 40 }}
                    textStyle={{ width: 40 }}
                    value={item.quantity?.toString() || "1"}
                    onChangeText={(text: string) => {
                        if (text === '') {
                            updateQuantity(index, 0);
                        } else {
                            const num = parseInt(text, 10);
                            if (!isNaN(num) && num >= 0) {
                                updateQuantity(index, num);
                                item.quantity = num;
                            }
                        }
                    }}
                />
                <Button
                    size='tiny'
                    appearance='outline'
                    status='success'
                    style={{ flex: 1, marginLeft: 2, width: 40 }}
                    onPress={() => {
                        const newLineItem = new LineItem({
                            ...item,
                            quantity: 1,
                            customerCost: false,
                            billAllBack: true,
                            billing: "Customer Cost",
                            description: item.name
                        })
                        onAddItemToSelectedItems(newLineItem)
                    }}>
                    <Icon name='plus-outline' width={24} height={24} fill="#fff" />
                </Button>
            </View>
        </View>
    )
};


const RenderSelectedInventoryItem = ({
    item,
    index,
    onRemoveItemFromSelectedItems,
    onUpdateItemInSelectedItems,
    selectedLineItems
}: {
    item: LineItem,
    index: number,
    onRemoveItemFromSelectedItems: (index: number) => void,
    onUpdateItemInSelectedItems: (index: number, item: LineItem) => void,
    selectedLineItems: LineItem[]
}) => {
    // const itemIndex = item.index
    const [isEditing, setIsEditing] = useState(false)
    const itemIndex = selectedLineItems.findIndex(selectedItem => selectedItem._id == item._id)

    const updatingItemRef = React.useRef<LineItem>(item)

    const updateName = (value: string) => {
        updatingItemRef.current.name = value
    }

    const updateQuantity = (value: number) => {
        if (isNaN(value)) value = 0
        updatingItemRef.current.quantity = value
    }

    const updateCost = (value: number) => {
        // ensure value is a number or make it 0
        if (isNaN(value)) value = 0
        updatingItemRef.current.cost = value
    }

    const saveEdits = () => {
        onUpdateItemInSelectedItems(itemIndex, updatingItemRef.current)
        setIsEditing(false)
    }


    if (!item) return (<></>)

    return (
        <View style={{ flexDirection: "column" }}>
            <View style={{ flex: 4, flexDirection: "row" }}>
                {isEditing ?
                    <View style={{flex:3, flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Input
                            size='small'
                            placeholder='Name'
                            label='Name'
                            defaultValue={item.name}
                            onChangeText={(value: string) => {
                                updateName(value)
                            }}
                            style={{ flex: 3 }} />
                        <Input
                            size='small'
                            placeholder='Quantity'
                            label='Quantity'
                            defaultValue={item.quantity.toString()}
                            onChangeText={(value: any) => {
                                updateQuantity(value)
                            }}
                            style={{ flex: 1 }} />
                        <Input
                            size='small'
                            placeholder='Cost'
                            label='Cost'
                            defaultValue={item.cost.toString()}
                            onChangeText={(value: any) => {
                                updateCost(value)
                            }}
                            style={{ flex: 1 }} />
                    </View>
                    :
                    <View style={{ flex: 3, flexDirection: "column" }}>
                        <Text selectable category='label' style={{}}>{item.name}</Text>
                        <View style={{ flexDirection: "row" }}>
                            <Text selectable style={{ flex: 1 }}>Quantity: {item.quantity}X</Text>
                            <Text selectable style={{ flex: 1 }}>Cost: ${item.cost}</Text>
                        </View>
                    </View>
                }
                <View style={{ flex: 1, flexDirection: "row", justifyContent: 'flex-end', alignItems: 'flex-end', gap: 3 }}>
                    {!isEditing ?
                        <Button
                            size='tiny'
                            status='info'
                            appearance='outline'
                            onPress={() => {
                                setIsEditing(true)
                            }}>
                            <Icon name='edit-2-outline' width={24} height={24} fill="#fff" />
                        </Button>
                        :
                        <Button
                            size='tiny'
                            status='success'
                            onPress={() => {
                                saveEdits()
                            }}>
                            <Icon name='checkmark-outline' width={24} height={24} fill="#fff" />
                        </Button>
                    }
                    <Button
                        size='tiny'
                        status='danger'
                        appearance='outline'
                        onPress={() => {
                            onRemoveItemFromSelectedItems(itemIndex)
                        }}>
                        <Icon name='trash-outline' width={24} height={24} fill="#fff" />
                    </Button>
                </View>
            </View>
            <View style={{ width: '100%', height: 1, backgroundColor: 'white' }}>
            </View>
            <View style={{ flex: 1, flexDirection: 'row' }}>
                <Text style={{ flex: 1 }}>Total = ${item.cost * item.quantity}</Text>
                <View style={{ flex: 1, flexDirection: 'row', alignItems: 'flex-end', zIndex: 10, gap: 3 }}>
                    <Button
                        size='tiny'
                        status='success'
                        appearance={selectedLineItems[itemIndex].customerCost ? 'filled' : 'outline'}
                        onPress={() => {
                            let updatedItem = { ...selectedLineItems[itemIndex] }
                            updatedItem.customerCost = true
                            updatedItem.billAllBack = false
                            updatedItem.billed_to = LINE_ITEM_BILLED_TO.CUSTOMER_COST
                            updatedItem.billing = "Customer Cost"
                            onUpdateItemInSelectedItems(itemIndex, updatedItem)
                        }}>
                        Customer Cost
                    </Button>
                    <Button
                        size='tiny'
                        status='success'
                        appearance={selectedLineItems[itemIndex].billAllBack ? 'filled' : 'outline'}
                        onPress={() => {
                            let updatedItem = { ...selectedLineItems[itemIndex] }
                            updatedItem.customerCost = false
                            updatedItem.billAllBack = true
                            updatedItem.billed_to = LINE_ITEM_BILLED_TO.BILL_ALL_BACK
                            updatedItem.billing = "Bill All Back"
                            onUpdateItemInSelectedItems(itemIndex, updatedItem)
                        }}>
                        Bill All Back
                    </Button>
                </View>
                <View style={{ flex: 1 }}></View>
                <View style={{ flex: 1 }}>
                    {/* Buttons to mark paid or not if customer cost */}
                    {selectedLineItems[itemIndex].customerCost &&
                        <Button
                            style={{ flex: 1 }}
                            size='tiny'
                            status={selectedLineItems[itemIndex].paid ? 'success' : 'warning'}
                            appearance={selectedLineItems[itemIndex].paid ? 'filled' : 'outline'}
                            onPress={() => {
                                let updatedItem = { ...selectedLineItems[itemIndex] }
                                updatedItem.paid = !updatedItem.paid
                                onUpdateItemInSelectedItems(itemIndex, updatedItem)
                            }}>
                            {selectedLineItems[itemIndex].paid ? "Paid" : "Not Paid"}
                        </Button>
                    }

                </View>
            </View>
        </View>
    )


};


const styles = StyleSheet.create({
    groupContainer: {
        margin: 10,
        flex: 1,
        // padding: 16,
        // borderRadius: 8,
        // backgroundColor: '#252e56',
    },

    groupTitle: {
        fontSize: 14,
        fontWeight: 'bold',
        // marginBottom: 8,
        // backgroundColor: '#090b15',
    },
    inputField: {
        paddingLeft: 24,
        paddingRight: 24,
    },
    horizontalLayout: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexWrap: 'wrap',
    },
    verticalLayout: {
        flexDirection: 'column',
    }
});