var config = require('../config/config.js')
const axios = require('axios');
import * as StorageController from './storageController'
import { Holding } from '../models/Holding.model';

var api = config.api;
var testApi = config.test_api

function getApi() {
    if (StorageController.getAppState().use_test_api) {
        return testApi
    }
    return api
}


axios.interceptors.request.use(
    (config: any) => {
        let token = getToken();
        let member_id = null;
        if (StorageController.getAppState().selectedMembership != null) {
            member_id = StorageController.getAppState().selectedMembership?._id;
        }
        config.headers.authorization = `Bearer ${token}`;
        config.headers['member-id'] = member_id;
        return config;
    },
    (error: any) => {
        return Promise.reject(error);
    }
);

const getToken = async () => {
    return await StorageController.getAccessToken() || null;
}

// Create and Save a new Holding
export async function create(holdingObj: Holding) {
    try {
        const response = await axios.post(getApi() + "/holding", holdingObj);
        return new Holding(response.data)
    } catch (error) {
        console.error(error);
    }
}

// Update a Holding by the id in the request
export async function update(id: string, holdingObj: Holding) {
    try {
        const response = await axios.put(getApi() + "/holding/" + id, holdingObj);
        return new Holding(response.data)
    } catch (error) {
        console.error(error);
    }
}

// Delete a Holding with the specified id in the request
export async function deleteHolding(id: string) {
    try {
        const response = await axios.delete(getApi() + "/holding/" + id);
        return new Holding(response.data)
    } catch (error) {
        console.error(error);
    }
}

// Find a single Holding with an id
export async function findByPk(id: string) {
    try {
        const response = await axios.get(getApi() + "/holding/" + id);
        return new Holding(response.data)
    } catch (error) {
        console.error(error);
    }

}

// Retrieve all Holdings for a given Company ID
export async function findByCompanyId(company_id: string) {
    try {
        const response = await axios.get(getApi() + "/holding/company/" + company_id);
        return response.data.map((holding: any) => new Holding(holding))
    } catch (error) {
        console.error(error);
    }

}

// Retrieve Holding by holding Job ID
export async function findByJobHoldingingJobId(job_id: string) {
    try {
        const response = await axios.get(getApi() + "/holding/job/holding/" + job_id);
        return new Holding(response.data)
    } catch (error) {
        // console.log("🚀 ~ file: holding.controller:106 ~ findByJobHoldingingJobId ~ error:", error)
    }

}

// Retrieve Holding by releasing Job ID
export async function findByJobReleasedJobId(job_id: string) {
    try {
        const response = await axios.get(getApi() + "/holding/job/release/" + job_id);
        return new Holding(response.data)
    } catch (error) {
        // console.log("🚀 ~ file: holding.controller:119 ~ findByJobReleasedJobId ~ error:", error)
    }

}

// Retrieve all Holdings by company_id and status
export async function findByCompanyIdAndStatus(company_id: string, status: string, limit = 1000, offset = 0) {
    try {
        const response = await axios.get(getApi() + `/holding/company/${company_id}/status/${status}/${limit}/${offset}`);
        if (response.data.length == 0) return [];
        const result = response.data.map((holding: any) => new Holding(holding))
        // console.log("🚀============== ~ file: holding.controller.ts:117 ~ findByCompanyIdAndStatus ~ result🚀==============", result)
        return result
    } catch (error) {
        console.log("🚀============== ~ file: holding.controller:113 ~ findByCompanyIdAndStatus ~ error🚀==============", error)
        return [];
    }
}

/**
 * Gets all logged in companies holding items by status, loads this into local storage and returns the companies with holdings populated.
 * @param {*} status 
 * @returns 
 */
export async function findByAllCompaniesAndStatus(status: string) {
    try {
        const companies = StorageController.getAppState().companies || [];
        const holdingPromises = companies.map(async (company) => {
            if (company.settings?.job_options.showTowingJobOptions) {
                const holdings = await findByCompanyIdAndStatus(company._id, status);
                company.holdingItems = holdings;
            } else {
                company.holdingItems = [];
            }
            return company; // This will be a company with holdings populated.
        });
        const companiesWithHoldings = (await Promise.allSettled(holdingPromises))
            .filter((result) => result.status === 'fulfilled')
            .map((result: any) => (result).value);
        return companiesWithHoldings;
    } catch (error) {
        // console.log("🚀 ~ file: holding.controller:131 ~ findByCompanyIdAndStatus ~ error:", error)
    }
}

// Retrieve all Holdings by case_id
export async function findByCaseId(case_id: string) {
    try {
        const response = await axios.get(getApi() + "/holding/case/" + case_id);
        return response.data.map((holding: any) => new Holding(holding))
    } catch (error) {
        // console.log("🚀 ~ file: holding.controller:143 ~ findByCaseId ~ error:", error)
    }

}

export async function updateHoldingSetReleased(holding_id: string, released_job_id: string) {
    try {
        // get the holding
        let holding = await findByPk(holding_id) as Holding;
        // update the holding
        holding.status = "released";
        holding.released_job_id = released_job_id;
        holding.released_time = Date.now()

        // update the holding
        return await update(holding_id, holding);
    } catch (error) {
        console.error(error);
    }

}


// set holding holding time
export async function setHoldingHoldingTime(id: string) {
    try {
        const response = await axios.put(getApi() + "/holding/time/hold/" + id);
        return new Holding(response.data)
    } catch (error) {
        console.error(error);
    }
}

// set holding released time
export async function setHoldingReleased(id: string, job_id = "none") {
    try {
        const response = await axios.put(getApi() + `/holding/time/release/${id}/${job_id}`);
        return new Holding(response.data)
    } catch (error) {
        console.error(error);
    }
}

// unrelease holding -- if a realeased job is cancelled, holding is reset
export async function unReleaseHolding(id: string) {
    try {
        const response = await axios.put(getApi() + "/holding/unrelease/" + id);
        return new Holding(response.data)
    } catch (error) {
        console.error(error);
    }
}

export async function searchHoldings(query: string, limit: number = 10) {
    try {
        const data = {
            limit: limit,
            offset: 0,
            term: query
        }
        const response = await axios.post(getApi() + "/holding/search/", data);
        return response.data.map((holding: any) => new Holding(holding))
    } catch (error) {
        console.error(error);
    }
}

// get holdings by date range
export async function getHoldingsByDateRange(companyId: string, startDate: any, endDate: any) {
    //body
    var body = {
        company_id: companyId,
        start: startDate,
        end: endDate,
    }
    try {
        // const response = await axios.post(getApi() + "/invoice/find/", body);
        const response = await axios.post(getApi() + "/holding/find/dates", body);
        const result = response.data.map((holding: any) => new Holding(holding))
        return result as Holding[]
    } catch (error) {
        console.error(error);
        // throw new Error(`HTTP error! status: ${response.status}`);
    }
}





/*
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
//////// LOCAL STORAGE FUNCTIONS //////////////////////////////////
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
*/
/**
 * Update Holding in company
 * @param {*} holding
 */
export async function updateHoldingInCompany(holding: Holding) {
    try {
        let companies = StorageController.getAppState().companies || []
        let company = companies.find(c => c._id == holding.company_id)
        if (company && company.holdings && company.holdings.length > 0) {
            let holdingIndex = company.holdings.findIndex(h => h._id == holding._id)
            if (holdingIndex == -1) {
                company.holdings.unshift(holding)
            } else {
                company.holdings[holdingIndex] = holding
            }
            console.log("🚀============== ~ file: holding.controller.ts:267 ~ updateHoldingInCompany ~ company.holdingItems🚀==============", company.holdings)
        }
        return companies
    } catch (err) {
        console.log("🚀 ~ file: holding.controller:225 ~ updateHoldingInCompany ~ err", err)
        return StorageController.getAppState().companies || []
    }
}
