var config = require('../config/config.js')
const axios = require('axios');
import { VendorProfile } from '../models/VendorProfile.model';
import * as StorageController from './storageController'

var api = config.api;
var testApi = config.test_api

function getApi() {
    if (StorageController.getAppState().use_test_api) {
        return testApi
    }
    return api
}


/**
 * 
 *
*/
export const VendorProfileSchema = {
    company_id: "",
    linkedVendorAccountId: "",
    name: "String",
    details: "",
    operatingHours: {},
    serviceAreas: [], // You could also use GeoJSON if you need to represent areas on a map
    servicesProvided: [], // These are the tags you mentioned
    other: {},
}


axios.interceptors.request.use(
    (config: any) => {
        let token = getToken();
        let member_id = null;
        if (StorageController.getAppState().selectedMembership != null) {
            member_id = StorageController.getAppState().selectedMembership?._id;
        }
        config.headers.authorization = `Bearer ${token}`;
        config.headers['member-id'] = member_id;
        return config;
    },
    (error: any) => {
        return Promise.reject(error);
    }
);

const getToken = async () => {
    return await StorageController.getAccessToken() || null;
}

/**
 * Get vendor profile by id
 * @param {string} vendorProfileId Company id
 */
export const getVendorProfile = async (vendorProfileId: string) => {
    try {
        const response = await axios.get(getApi() + "/vendor_profile/" + vendorProfileId)
        // console.log(response);
        return response.data
    }
    catch (error) {
        console.warn(error);
        // throw new Error(`HTTP error! status: ${response.status}`);
    }
}

/**
 * Get Vendor Profile by Company id
 * @param {string} companyId Company id
 */
export const getVendorProfilesByCompanyId = async (companyId: string) => {
    try {
        const response = await axios.get(getApi() + "/vendor_profile/company/" + companyId)
        // console.log("🚀 ~ file: vendorProfile.controller:63 ~ getVendorProfileByCompanyId ~ response:", response)
        return response.data
    } catch (e) {
        // console.log("🚀 ~ file: vendorProfile.controller:66 ~ getVendorProfileByCompanyId ~ e:", e)
        return []
    }

}

/**
 * CreateVendor using a link id
 */
export const createVendorProfileByLinkingId = async (vendorCompanyId: string) => {
    try {
        let company_id = StorageController.getCurrentCompany()?._id
        let data = {
            clientCompanyId: company_id,
            vendorCompanyId: vendorCompanyId
        }
        const response = await axios.post(getApi() + "/clientvendor/linkvendor", data)
        // console.log("🚀 ~ file: vendorProfile.controller:63 ~ getVendorProfileByCompanyId ~ response:", response)
        return response.data
    } catch (e) {
        // console.log("🚀 ~ file: vendorProfile.controller:66 ~ getVendorProfileByCompanyId ~ e:", e)
        return []
    }
}

/**
 * Link existing vendor with linkingid
 */

export const linkExistingVendorAccount = async (vendorCompanyId: string, vendor_id: string) => {
    const clientCompanyId = StorageController.getCurrentCompany()._id
    try {
        let data = {
            clientCompanyId: clientCompanyId,
            vendorCompanyId: vendorCompanyId,
            vendor_id: vendor_id
        }
        const response = await axios.post(getApi() + "/clientvendor/linkexistingvendor", data)
        // console.log("🚀 ~ file: vendorProfile.controller:63 ~ getVendorProfileByCompanyId ~ response:", response)
        return response.data
    } catch (e) {
        // console.log("🚀 ~ file: vendorProfile.controller:66 ~ getVendorProfileByCompanyId ~ e:", e)
        return []
    }
}

/**
 * Get Vendor Profile by client companyId and linkedVendorAccountId
 */
export const getVendorProfileByCompanyIdAndLinkedVendorAccountId = async (companyId: string, linkedVendorAccountId: string) => {
    try {
        const response = await axios.get(getApi() + "/vendor_profile/company/" + companyId + "/linkedVendorAccountId/" + linkedVendorAccountId)
        // console.log("🚀 ~ file: vendorProfile.controller:63 ~ getVendorProfileByCompanyId ~ response:", response)
        return response.data
    } catch (e) {
        // console.log("🚀 ~ file: vendorProfile.controller:66 ~ getVendorProfileByCompanyId ~ e:", e)
        return []
    }

}


/**
 * Create new vendor profile
 * @param {object} vendorProfile Vendor Profile object
 */
export const createVendorProfile = async (vendorProfile: VendorProfile) => {
    try {

        const response = await axios.post(getApi() + "/vendor_profile", vendorProfile)
        return response.data
    } catch (e) {
        // console.log("🚀 ~ file: vendorProfile.controller:82 ~ createVendorProfile ~ e:", e)
    }
}

/**
 * Update vendor profile
 * @param {string} vendorProfileId Vendor Profile id
 * @param {object} vendorProfileData Vendor Profile object data
 */
export const updateVendorProfile = async (vendorProfileId: string, vendorProfileData: VendorProfile) => {
    try {
        const response = await axios.put(`${api}/vendor_profile/${vendorProfileId}`, vendorProfileData);
        // console.log(response);
        return response.data;
    } catch (error) {
        console.error(error);
        // Handle the error as needed
    }
}

/**
 * Delete vendor profile
 * @param {string} vendorProfileId Vendor Profile id
 */
export const deleteVendorProfile = async (vendorProfileId: string) => {
    try {
        const response = await axios.delete(`${api}/vendor_profile/${vendorProfileId}`);
        // console.log(response);
        return response.data;
    } catch (error) {
        console.error(error);
        // Handle the error as needed
    }
}