import React, { useState, useEffect, useCallback } from 'react';
import { View, StyleSheet, Alert } from "react-native";
import * as StorageController from '../../functions/storageController';
import { Layout, Text, List, ListItem, Divider, Spinner, Button, Modal, Input, useTheme, Icon, CheckBox } from '@ui-kitten/components';
import * as InventoryController from '../../functions/Inventory.controller';
import { InventoryCategory, InventoryItem } from '../../models/Inventory.model';
import InventoryItemDetail from './InventoryItemDetail.component';
import { IAction, useAppStateChange } from '../../hooks/appStateChange.hook';
import Toast from 'react-native-toast-message';

export const InventoryCategoryScreen = ({ navigation }: any) => {
    const [categories, setCategories] = useState<InventoryCategory[]>([]);
    const [selectedCategory, setSelectedCategory] = useState<InventoryCategory | null>(null);
    const [items, setItems] = useState<InventoryItem[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    // State for Add Category Modal
    const [addCategoryModalVisible, setAddCategoryModalVisable] = useState<boolean>(false);
    const [newCategory, setNewCategory] = useState<{
        name: string;
        description: string;
    }>({
        name: '',
        description: '',
    });
    const [adding, setAdding] = useState<boolean>(false);
    // State for Add Item Modal
    const [newItem, setNewItem] = useState<{
        name: string;
        price: number;
        description: string;
        sku: string | null;
    }>({
        name: '',
        price: 0,
        description: '',
        sku: null,
    });
    const [addItemModalVisible, setAddItemModalVisible] = useState<boolean>(false);
    const [editingItem, setEditingItem] = useState<InventoryItem | null>(null); // State for the item being edited
    const [confirmRemoveModalVisible, setConfirmRemoveModalVisible] = useState<boolean>(false);
    const [itemToRemove, setItemToRemove] = useState<InventoryItem | null>(null); // State for the item to remove
    const [editingCategory, setEditingCategory] = useState<InventoryCategory | null>(null); // State for the category being edited
    const [searchQuery, setSearchQuery] = useState<string>(''); // Add state for search query
    const [selectedItems, setSelectedItems] = useState<string[]>([]); // State for selected items
    const [moveModalVisible, setMoveModalVisible] = useState<boolean>(false); // State for move modal
    const [targetCategory, setTargetCategory] = useState<InventoryCategory | null>(null); // State for target category

    const theme = useTheme();
    useEffect(() => {
        fetchCategories();
    }, []);

    const handleCompanyChangeEvent = useCallback((action: IAction) => {
        setCategories([]);
        setItems([]);
        setSelectedCategory(null);
        setSelectedItems([]);
        fetchCategories();
    }, []);


    const { dispatchEventStateChange } = useAppStateChange({
        onUpdatedSelectedCompany: handleCompanyChangeEvent
    })


    const fetchCategories = async () => {
        setLoading(true);
        const companyId = StorageController.getCurrentCompany()._id;
        const fetchedCategories = await InventoryController.getInventoryCategoriesByCompanyId(companyId);
        console.log("🚀============== ~ file: inventoryCategoryScreen.component.tsx:21 ~ fetchCategories ~ fetchedCategories🚀==============", fetchedCategories)
        if (fetchedCategories) {
            setCategories(fetchedCategories);
        }
        setLoading(false);
    }
    const fetchItems = async (categoryId: string) => {
        try {
            setLoading(true);
            const fetchedItems = await InventoryController.getInventoryItemsByCategory(categoryId);
            console.log("🚀============== ~ file: inventoryCategoryScreen.component.tsx:31 ~ fetchItems ~ fetchedItems🚀==============", fetchedItems)
            if (fetchedItems) {
                setItems(fetchedItems);
            }
            setLoading(false);
        } catch (error) {
            console.log("🚀============== ~ file: inventoryCategoryScreen.component.tsx:78 ~ fetchItems ~ error🚀==============", error)
            setLoading(false);
        }
    }
    const renderCategory = ({ item }: { item: InventoryCategory }) => (
        <ListItem
            title={item.name}
            description={item.description ?? ''}
            style={{ backgroundColor: item._id == selectedCategory?._id ? theme['color-success-100'] : theme['color-background-basic'] }}
            onPress={() => {
                setSelectedCategory(item);
                setItems([]);
                fetchItems(item._id!);
            }}
            accessoryLeft={<Icon name='folder-outline' fill={theme['color-primary-500']} />}
            accessoryRight={
                <View style={{ flexDirection: 'row', gap: 10 }}>
                    <Button
                        appearance='ghost'
                        status='primary'
                        size='small'
                        accessoryLeft={<Icon name='edit-outline' />}
                        onPress={() => {
                            openEditCategoryModal(item);
                        }}
                    >
                    </Button>
                </View>
            }
            onLongPress={() => openEditCategoryModal(item)} // Open edit modal on long press
        />
    );
    const renderItem = ({ item }: { item: InventoryItem }) => (
        <View style={{ flexDirection: 'row' }}>
            {/* <View style={{ flexDirection: 'row' }}>
                 <CheckBox
                    checked={selectedItems.includes(item._id!)}
                    onChange={() => toggleSelectItem(item._id!)}
                    // style={{ position: 'absolute', top: 10, left: 10 }}
                />
            </View> */}
            <View style={{ flex: 1 }}>
                <InventoryItemDetail
                    item={item}
                    onEdit={() => {
                        setEditingItem(item);
                        setNewItem({ name: item.name, price: item.default_price, description: item.description ?? '', sku: item.sku ?? '' });
                        setAddItemModalVisible(true); // Open modal for editing
                    }}
                    onRemove={() => {
                        setItemToRemove(item); // Set the item to remove
                        setConfirmRemoveModalVisible(true); // Show confirmation modal
                    }}
                    isSelected={selectedItems.includes(item._id!)}
                    onSelect={() => toggleSelectItem(item._id!)}
                />
            </View>
        </View>
    );

    const toggleSelectItem = (itemId: string) => {
        setSelectedItems(prev =>
            prev.includes(itemId) ? prev.filter(id => id !== itemId) : [...prev, itemId]
        );
    };

    const handleMoveItems = async () => {
        if (!targetCategory) return;
        await Promise.all(selectedItems.map(async (itemId) => {
            const item = items.find(i => i._id === itemId);
            if (item) {
                const updatedItem = new InventoryItem({ ...item, inventory_category_id: targetCategory._id });
                await InventoryController.updateInventoryItem(updatedItem);
            }
        }));
        setSelectedItems([]);
        setMoveModalVisible(false);
        await fetchItems(selectedCategory!._id!);
        Toast.show({ text1: 'Items moved successfully', type: 'success' });
    };




    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //////// CATEGORIES
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */
    // Function to handle adding a new category
    const handleAddCategory = async () => {
        if (newCategory.name.trim() === '') {
            return;
        }
        setAdding(true);
        const companyId = StorageController.getCurrentCompany()._id;
        const categoryToAdd: InventoryCategory = {
            name: newCategory.name,
            description: newCategory.description,
            company_id: companyId,
            details: {},
        };
        const addedCategory = await InventoryController.createInventoryCategory(categoryToAdd);
        console.log("🚀============== ~ file: inventoryCategoryScreen.component.tsx:72 ~ handleAddCategory ~ addedCategory🚀==============", addedCategory)
        if (addedCategory) {
            setCategories([...categories, addedCategory]);
            onCloseAddCategoryModal();
            await fetchCategories();
        }
        setAdding(false);
    }
    // Function to handle editing a category
    const handleEditCategory = async () => {
        if (!editingCategory) return; // Ensure there's a category to edit
        const updatedCategory: InventoryCategory = {
            ...editingCategory,
            name: newCategory.name,
            description: newCategory.description ?? '',
        };
        const result = await InventoryController.updateInventoryCategory(updatedCategory); // Assume this function exists
        console.log("🚀============== ~ file: inventoryCategoryScreen.component.tsx:106 ~ handleEditCategory ~ result🚀==============", result)
        if (result) {
            setCategories(categories.map(category => (category._id === updatedCategory._id ? updatedCategory : category))); // Update the category in the list
            onCloseAddCategoryModal(); // Close modal after editing
        }
    };



    // Modify the modal to set the editing category when opening
    const openEditCategoryModal = (category: InventoryCategory) => {
        setEditingCategory(category);
        setNewCategory({ name: category.name, description: category.description ?? '' });
        setAddCategoryModalVisable(true);
    }

    const onCloseAddCategoryModal = () => {
        setAddCategoryModalVisable(false);
        setNewCategory({ name: '', description: '' });
        setEditingCategory(null); // Reset editing state
    }



    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //////// ITEMS
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */
    // Function to handle adding a new item
    const handleAddItem = async () => {
        if (newItem.name.trim() === '' || newItem.price <= 0) {
            return; // Add validation as needed
        }
        const itemToAdd: InventoryItem = {
            name: newItem.name,
            default_price: newItem.price,
            inventory_category_id: selectedCategory?._id!,
            company_id: StorageController.getCurrentCompany()._id,
            description: newItem.description,
            sku: newItem.sku ?? '',
            details: {},
            // Add other necessary fields
        };
        const addedItem = await InventoryController.createInventoryItem(itemToAdd);
        console.log("🚀============== ~ file: inventoryCategoryScreen.component.tsx:106 ~ handleAddItem ~ addedItem🚀==============", addedItem)
        if (addedItem) {
            setItems([...items, addedItem]);
            setAddItemModalVisible(false);
            setNewItem({ name: '', price: 0, description: '', sku: null }); // Reset new item state
            await fetchItems(selectedCategory?._id!);
        }
    };
    const handleEditItem = async () => {
        if (!editingItem) return; // Ensure there's an item to edit
        const updatedItem: InventoryItem = {
            ...editingItem,
            name: newItem.name,
            default_price: newItem.price,
            description: newItem.description,
            sku: newItem.sku ?? '',
        };
        const result = await InventoryController.updateInventoryItem(updatedItem); // Assume this function exists
        console.log("🚀============== ~ file: inventoryCategoryScreen.component.tsx:106 ~ handleEditItem ~ result🚀==============", result)
        if (result) {
            setItems(items.map(item => (item._id === updatedItem._id ? updatedItem : item))); // Update the item in the list
            setAddItemModalVisible(false);
            resetItemForm(); // Reset the form after editing
            setEditingItem(null); // Reset editing state
        }
    };
    const resetItemForm = () => {
        setNewItem({ name: '', price: 0, description: '', sku: null });
        setEditingItem(null); // Reset editing state
    };

    const handleRemoveItem = async () => {
        if (!itemToRemove || !itemToRemove._id) return; // Ensure there's an item to remove
        const result = await InventoryController.deleteInventoryItem(itemToRemove._id); // Assume this function exists
        console.log("🚀============== ~ file: inventoryCategoryScreen.component.tsx:106 ~ handleRemoveItem ~ result🚀==============", result)
        if (result) {
            setItems(items.filter(item => item._id !== itemToRemove._id)); // Remove the item from the list
            setConfirmRemoveModalVisible(false); // Close the confirmation modal
            setItemToRemove(null); // Reset the item to remove
        }
    };

    const onCloseAddItemModal = () => {
        setAddItemModalVisible(false);
        setNewItem({ name: '', price: 0, description: '', sku: null });
    }


    return (
        <View style={{ flexDirection: 'row', flex: 1 }}>
            <View style={{ flex: 1, padding: 10 }}>
                <Text category='h6' style={styles.header}>Categories</Text>
                <Button style={styles.addButton} onPress={() => setAddCategoryModalVisable(true)}>
                    Add Category
                </Button>
                <List
                    data={categories}
                    renderItem={renderCategory}
                    ItemSeparatorComponent={Divider}
                    style={styles.list}
                />
            </View>
            <View style={{ flex: 4, padding: 10 }}>
                {selectedCategory && (
                    <>
                        <Text category='h6' style={styles.header}>Items in {selectedCategory.name}</Text>
                        <View style={{ marginBottom: 10, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', gap: 10 }}>
                            <Button style={styles.addButton} onPress={() => {
                                resetItemForm(); // Reset the form before opening the modal
                                setAddItemModalVisible(true);
                            }}
                            size='small'
                            appearance='filled'
                            status='success'
                            disabled={!selectedCategory}
                            accessoryLeft={<Icon name='plus-outline' />}
                            >
                                Add Item
                            </Button>
                            <View style={{ flexDirection: 'row', flex: 1 }}>

                                <Input
                                    placeholder='Search items...'
                                    value={searchQuery}
                                    onChangeText={setSearchQuery} // Update search query
                                    style={{ flex: 1 }}
                                />
                                <Button
                                    appearance='ghost'
                                    status='basic'
                                    onPress={() => setSearchQuery('')} // Clear search query
                                >
                                    Clear
                                </Button>
                            </View>
                            <Button
                                size='small'
                                disabled={selectedItems.length === 0}
                                onPress={() => setMoveModalVisible(true)}>
                                Move to Another Category
                            </Button>
                        </View>
                        {loading ? (
                            <Spinner size='large' />
                        ) : (
                            <List
                                data={items.filter(item =>
                                    searchQuery === '' || item.name.toLowerCase().includes(searchQuery.toLowerCase()) // Show all if searchQuery is empty
                                )}
                                renderItem={renderItem}
                                ItemSeparatorComponent={Divider}
                                style={styles.list}
                            />
                        )}

                    </>
                )}
            </View>
            <Modal
                visible={addCategoryModalVisible}
                backdropStyle={styles.backdrop}
                onBackdropPress={() => {
                    onCloseAddCategoryModal();
                    setEditingCategory(null); // Reset editing state
                }}
            >
                <Layout style={styles.modalContainer}>
                    <Text category='h6' style={styles.modalHeader}>{editingCategory ? 'Edit Category' : 'Add New Category'}</Text>
                    <View style={{ position: 'absolute', top: 0, right: 0 }}>
                        <Button
                            appearance='filled'
                            size='small'
                            status='danger'
                            accessoryLeft={<Icon name='close-outline' />}
                            onPress={onCloseAddCategoryModal} style={styles.submitButton}>
                        </Button>
                    </View>
                    <Input
                        label='Category Name'
                        placeholder='Enter category name'
                        value={newCategory.name}
                        onChangeText={(text) => setNewCategory({ ...newCategory, name: text })} // Update name
                        style={styles.input}
                    />
                    <Input
                        label='Description'
                        placeholder='Enter description (optional)'
                        value={newCategory.description}
                        onChangeText={(text) => setNewCategory({ ...newCategory, description: text })} // Update description
                        style={styles.input}
                        multiline={true}
                    />
                    <Button
                        onPress={editingCategory ? handleEditCategory : handleAddCategory}
                        disabled={adding}
                        style={styles.submitButton}
                    >
                        {editingCategory ? 'Save Changes' : 'Add Category'}
                    </Button>
                </Layout>
            </Modal>
            <Modal
                visible={addItemModalVisible}
                backdropStyle={styles.backdrop}
                onBackdropPress={() => onCloseAddItemModal()}
            >
                <Layout style={styles.modalContainer}>
                    <Text category='s1'>
                        {editingItem ? 'Edit Item' : 'Add New Item'}
                    </Text>
                    <View style={{ position: 'absolute', top: 0, right: 0 }}>
                        <Button
                            appearance='filled'
                            size='small'
                            status='danger'
                            accessoryLeft={<Icon name='close-outline' />}
                            onPress={onCloseAddItemModal} style={styles.submitButton}>
                        </Button>
                    </View>
                    <Input
                        label='Item Name'
                        placeholder='Enter item name'
                        value={newItem.name}
                        onChangeText={(text) => setNewItem({ ...newItem, name: text })} // Update name
                        style={styles.input}
                    />
                    <Input
                        label='Price'
                        placeholder='Enter item price'
                        value={newItem.price?.toString() ?? ''}
                        onChangeText={(text) => setNewItem({ ...newItem, price: parseFloat(text) })} // Update price
                        style={styles.input}
                        keyboardType='numeric'
                    />
                    <Input
                        label='Description'
                        placeholder='Enter item description'
                        value={newItem.description}
                        onChangeText={(text) => setNewItem({ ...newItem, description: text })} // Update description
                        style={styles.input}
                        multiline={true}
                    />
                    <Input
                        label='SKU'
                        placeholder='Enter item SKU (optional)'
                        value={newItem.sku ?? ''}
                        onChangeText={(text) => setNewItem({ ...newItem, sku: text })} // Update SKU
                        style={styles.input}
                    />
                    <Button
                        onPress={editingItem ? handleEditItem : handleAddItem}
                        style={styles.submitButton}
                    >
                        {editingItem ? 'Save Changes' : 'Add Item'}
                    </Button>
                </Layout>
            </Modal>
            <Modal
                visible={confirmRemoveModalVisible}
                backdropStyle={styles.backdrop}
                onBackdropPress={() => setConfirmRemoveModalVisible(false)}
            >
                <Layout style={styles.modalContainer}>
                    <Text category='h6' style={styles.modalHeader}>Confirm Removal</Text>
                    <Text>Are you sure you want to remove this item?</Text>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>

                        <Button onPress={handleRemoveItem} style={styles.submitButton}>
                            Yes, Remove
                        </Button>
                        <Button appearance='ghost'
                            status='danger'
                            onPress={() => setConfirmRemoveModalVisible(false)} style={styles.submitButton}>
                            Cancel
                        </Button>
                    </View>
                </Layout>
            </Modal>
            <Modal
                visible={moveModalVisible}
                backdropStyle={styles.backdrop}
                onBackdropPress={() => setMoveModalVisible(false)}
            >
                <Layout style={[styles.modalContainer, { gap: 20 }]}>
                    <Text category='h6'>Move Items to Category</Text>
                    <List
                        style={{ backgroundColor: 'transparent' }}
                        data={categories.filter(cat => cat._id !== selectedCategory?._id)}
                        renderItem={({ item }) => (
                            <ListItem
                                style={{ backgroundColor: targetCategory?._id === item._id ? theme['color-success-300'] : 'transparent' }}
                                title={item.name}
                                onPress={() => setTargetCategory(item)}
                                accessoryLeft={<Icon name='folder-outline' />}
                            />
                        )}
                    />
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Button onPress={handleMoveItems} disabled={!targetCategory}>
                            Move
                        </Button>
                        <Button
                            appearance='ghost'
                            status='danger'
                            onPress={() => {
                                setTargetCategory(null);
                                setMoveModalVisible(false);
                            }}>
                            Cancel
                        </Button>
                    </View>
                </Layout>
            </Modal>
        </View>
    );
}
const styles = StyleSheet.create({
    container: {
        flex: 1,
        padding: 10,
        flexDirection: 'row',
    },
    header: {
        marginVertical: 10,
    },
    list: {
        flex: 1,
    },
    addButton: {
        marginBottom: 10,
    },
    backdrop: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    modalContainer: {
        width: 300,
        padding: 20,
        borderRadius: 8,
    },
    modalHeader: {
        marginBottom: 15,
    },
    input: {
        marginBottom: 10,
    },
    submitButton: {
        marginTop: 10,
    }
});