import * as StorageController from './storageController'
import * as UpdateController from './update.controller'
var config = require('../config/config.js')
const axios = require('axios');
import * as mqtt from '../services/mqtt.service'
import { User } from '../models/User.model';
import { Member } from '../models/Member.model';
// import root navigation


export interface iLoginResponse {
    user: User | null;
    user_id: string;
    access_token: string | null;
    refresh_token?: string | null;
    success: boolean;
    mfa_enabled: boolean;
    mfa_access_token: string | null;
    message?: string;
    statusCode?: number;
    error?: any
}

export interface iMfaLoginResponse {
    user?: User;
    access_token?: string;
    refresh_token?: string;
    success?: boolean;
    message?: string | any;
    statusCode?: number;
    error?: any
}

var api = config.api;
var testApi = config.test_api
const AUTH_API = config.auth_api
// const AUTH_API = config.api

function getApi() {
    if (StorageController.getAppState().use_test_api) {
        return testApi
    }
    return api
}


/*
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
//////// Run this to refresh the token on app start if there is one
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
*/
setTimeout(async () => {
    console.log("🚀============== ~ file: user.controller.ts:138 ~ setTimeout ~ debounceRefreshToken()🚀==============")
    await debounceRefreshToken()
}, 3000)

axios.interceptors.request.use(
    async (config: any) => {
        let token = await getToken();
        let member_id = null;
        if (StorageController.getAppState().selectedMembership != null) {
            member_id = StorageController.getAppState().selectedMembership?._id;
        }
        config.headers.authorization = `Bearer ${token}`;
        config.headers['member-id'] = member_id;
        return config;
    },
    (error: any) => {
        return Promise.reject(error);
    }
);

const getToken = async () => {
    return await StorageController.getAccessToken() || null;
}

const getRefreshAccessToken = async () => {
    return await StorageController.getRefreshAccessToken() || null;
}

const setRefreshToken = async (token: string) => {
    await StorageController.setRefreshAccessToken(token)
    StorageController.saveStateToDisk()
}

const setToken = async (token: string) => {
    await StorageController.setAccessToken(token)
    StorageController.saveStateToDisk()
}

axios.interceptors.response.use(
    (response: any) => {
        // check if authorization header is present
        if (!response.headers.authorization) return response;
        const newToken = response.headers.authorization.split(' ')[1]; // Bearer <token>
        if (newToken) {
            setToken(newToken);
        }
        return response;
    },
    async (error: any) => {
        if (error.response && error.response.status === 401) {
            try {
                console.log("🚀============== ~ file: user.controller.ts:59 ~ error🚀==============", error);
                const newToken = await debounceRefreshToken();
                console.log("🚀============== ~ file: user.controller.ts:61 ~ newToken🚀==============", newToken);
                if (!newToken) {
                    logout();
                } else {
                    // Retry the original request with the new token
                    const config = error.config;
                    config.headers.Authorization = `Bearer ${newToken}`;
                    return axios.request(config);
                }
            } catch (err) {
                console.log("🚀============== ~ file: user.controller.ts:70 ~ err🚀==============", err)
                logout();
                return Promise.reject(error);
            }
        }
        return Promise.reject(error);
    }
);

const REFRESH_DEBOUNCE_TIME = 1000;
let refreshTimeout: any = null;
let refreshingPromise: Promise<string | null> | null = null;


export const debounceRefreshToken = (): Promise<string | null> => {
    if (refreshingPromise) return refreshingPromise;

    refreshingPromise = new Promise((resolve) => {
        if (refreshTimeout) {
            clearTimeout(refreshTimeout);
        }

        refreshTimeout = setTimeout(async () => {
            const newToken = await refreshToken();
            refreshingPromise = null;
            resolve(newToken);
        }, REFRESH_DEBOUNCE_TIME);
    });

    return refreshingPromise;
};




export async function refreshToken(): Promise<string | null | any> {
    try {
        const refresh_token = await getRefreshAccessToken();
        console.log("🚀============== ~ file: user.controller.ts:140 ~ refreshToken ~ refresh_token🚀==============", refresh_token)
        // const access_token = await StorageController.getAccessToken();
        if (StorageController.getAppState().loggedIn && refresh_token) {
            // const response = await axios.get(getApi() + '/refresh-token');
            // add the refresh token to the request as cookies
            const response = await axios.get(AUTH_API + '/refresh-token', {
                headers: {
                    'refresh-token': `${refresh_token}`
                }
            });
            console.log("🚀============== ~ file: user.controller.ts:156 ~ refreshToken ~ response🚀==============", response)
            const newToken = response.data.access_token;
            const newRefreshToken = response.data.refresh_token;
            if (newToken) {
                await setToken(newToken);
                await setRefreshToken(newRefreshToken);
                return newToken;
            }
            else {
                logout();
                return null;
            }
        }
    } catch (error) {
        console.log(error);
        return null;
    }
}


// Setup MFA for user
export async function setupMfa(user_id: string, username: string) {
    try {
        const response = await axios.post(AUTH_API + "/setup-mfa", { user_id: user_id, username: username });
        return response.data
    } catch (error) {
        console.error(error);
        return null
    }
}

/**
 * Disable MFA
 * @param user_id  
 * @returns 
 */
export async function disableMfa(user_id: string) {
    try {
        const response = await axios.post(AUTH_API + "/disable-mfa", { user_id: user_id });
        return response.data
    } catch (error) {
        console.error(error);
        return null
    }
}




export async function login(username: string, password: string) {
    var data = {
        username: username,
        password: password
    }
    // console.log('loggin in')
    try {
        // const response = await axios.post(getApi() + "/login", data);
        const response = await axios.post(AUTH_API + "/login", data);
        const loginResponse: iLoginResponse = response.data
        if (!loginResponse.success) {
            return { error: loginResponse.message }
        }
        if (loginResponse.mfa_enabled && loginResponse.mfa_access_token) {
            // begin mfa flow with temp auth token
            await StorageController.setAccessToken(loginResponse.mfa_access_token)
            return loginResponse
        }
        // console.log(response);
        // console.log("TOKEN============================", response.data.access_token)

        // NO MFA ENABLED - LOGIN SUCCESS
        if (!loginResponse.access_token) return { error: "Error Logging in, no valid token" }

        StorageController.appState.loggedIn = true
        StorageController.appState.user = new User(loginResponse.user)
        await StorageController.setAccessToken(loginResponse.access_token)
        await StorageController.setRefreshAccessToken(loginResponse.refresh_token as string)
        UpdateController.dispatchEventStateChange({ type: UpdateController.STATE_ACTIONS.LOGGED_IN, data: response.data })
        StorageController.saveStateToDisk()
        response.data.status = response.status


        // console.log("🚀============== ~ file: user.controller.ts:217 ~ login ~ response.data🚀==============", response.data)
        // if status code is 429
        return response.data
    } catch (error: any) {
        console.log("🚀============== ~ file: user.controller.ts:147 ~ login ~ error🚀==============", error)
        // console.log("🚀 ~ file: user.controller:101 ~ login ~ error:", error)
        if (error.response && error.response.status) {
            // console.log("🚀 ~ file: user.controller:103 ~ login ~ error.response:", error.response)
            // Check if the status code is 429
            if (error.response.status === 429) {
                console.error("Too many requests. Please try again later.");
                // Handle the 429 status code specifically
                // You can return or throw an error depending on your error handling strategy
                return { error: "Too many requests", statusCode: 429 };
            } else {
                // Handle other status codes
                return { error: error.message, statusCode: error.response.status };
            }
        } else {
            // If the error does not have a response
            return { error: error.message, statusCode: 0 };
        }
    }
}

/**
 * Verify the MFA totp token - The auth token is the temp token from the login response sent in the header 
 * @param mfa_jwt_token 
 * @param totp 
 * @returns 
 */
export async function verifyMFA(mfa_jwt_token: string, totp: string) {
    try {
        const response = await axios.post(AUTH_API + "/verify-mfa", { mfa_access_token: mfa_jwt_token, totp: totp });
        const loginResponse: iMfaLoginResponse = response.data
        if (!loginResponse.success) {
            return { error: loginResponse.message }
        }
        console.log("🚀============== ~ file: user.controller.ts:263 ~ verifyMFA ~ loginResponse🚀==============", loginResponse)
        if (!loginResponse.access_token) return { error: "Error Logging in, no valid token" }
        StorageController.appState.user = new User(loginResponse.user)
        StorageController.appState.loggedIn = true
        await StorageController.setAccessToken(loginResponse.access_token)
        await StorageController.setRefreshAccessToken(loginResponse.refresh_token as string)
        UpdateController.dispatchEventStateChange({ type: UpdateController.STATE_ACTIONS.LOGGED_IN, data: response.data })
        StorageController.saveStateToDisk()
        return loginResponse
    } catch (error) {
        console.error(error);
        return {
            success: false,
            message: error as string
        }
    }
}

// logout
export async function logout() {
    try {
        if (StorageController.getAppState().selectedMembership) {
            const membership = StorageController.getAppState().selectedMembership as Member
            await setMembershipOnlineStatus(membership._id, false)
        }
        mqtt.mqttUnSubAll()
        await StorageController.setDefaultAppState();
        UpdateController.dispatchEventStateChange({ type: UpdateController.STATE_ACTIONS.LOGGED_OUT, data: null })
        return
    } catch (error) {
        console.error(error);
    }
}


export async function getUser(id: string) {
    try {
        const response = await axios.get(getApi() + `/user/${id}`);
        // console.log(response);
        return response.data
    } catch (error) {
        console.error(error);
        // throw new Error(`HTTP error! status: ${response.status}`);
    }
}

export async function getMemberships(userId: string) {
    try {
        const response = await axios.get(getApi() + `/member/user/${userId}`);
        // console.log(response);
        return response.data
    } catch (error) {
        console.error(error);
        // throw new Error(`HTTP error! status: ${response.status}`);
    }
}

export async function setSelectedMembership(membershipObj: Member) {
    StorageController.appState.selectedMembership = membershipObj
    StorageController.saveStateToDisk()
}

export async function getCompaniesFromMemberships(memberships: Member[]) {
    var companies = []
    for (var i = 0; i < memberships.length; i++) {
        var membership = memberships[i]
        if (membership.company_id == null) continue
        var company = await getCompanyById(membership.company_id)
        company.membership = membership
        companies.push(company)
    }
    // console.log(companies)
    return companies
}

export async function getCompanyById(id: string) {
    try {
        const response = await axios.get(getApi() + `/company/${id}`);
        // console.log(response);
        return response.data
    } catch (error) {
        console.error(error);
        // throw new Error(`HTTP error! status: ${response.status}`);
    }
}


// set membership online status
export async function setMembershipOnlineStatus(membership_id: string, online_status: boolean) {
    try {
        if (online_status) {
            return await setMemberOnline(membership_id)
        } else {
            return await setMemberOffline(membership_id)
        }
        // const response = await axios.put(getApi() + `/member/online/${membership_id}`, { online_status: online_status });
        // console.log(response);
        // return response.data
    } catch (error) {
        console.error(error);
        // throw new Error(`HTTP error! status: ${response.status}`);
    }
}

export async function setMemberOffline(membership_id: string) {
    try {
        const response = await axios.put(getApi() + `/member/offline/${membership_id}`,);
        // console.log(response);
        return response.data
    } catch (error) {
        console.error(error);
        // throw new Error(`HTTP error! status: ${response.status}`);
    }
}

export async function setMemberOnline(membership_id: string) {
    try {
        const response = await axios.put(getApi() + `/member/online/${membership_id}`,);
        // console.log(response);
        return response.data
    } catch (error) {
        console.error(error);
        // throw new Error(`HTTP error! status: ${response.status}`);
    }
}


// set membership available/busy/unavailable status
export async function setMembershipStatus(membership_id: string, status: string) {
    try {
        const response = await axios.put(getApi() + `/member/status/${membership_id}`, { status: status });
        // console.log(response);
        return response.data
    } catch (error) {
        console.error(error);
        // throw new Error(`HTTP error! status: ${response.status}`);
    }
}

// get membership by id
export async function getMembershipById(id: string) {
    try {
        const response = await axios.get(getApi() + `/member/${id}`);
        // console.log(response);
        return response.data
    } catch (error) {
        console.error(error);
        // throw new Error(`HTTP error! status: ${response.status}`);
    }
}

// is loggedin
export async function isLoggedIn() {
    if (StorageController.getAppState().loggedIn) {
        return true
    } else {
        return false
    }
}


// change password
export async function changePassword(id: string, oldPassword: string, password: string) {
    try {
        const response = await axios.post(getApi() + `/user/password/change`, {
            old_password: oldPassword,
            password: password,
            user_id: id
        });
        return response.data
    } catch (error) {
        console.error(error);
        // throw new Error(`HTTP error! status: ${response.status}`);
    }
}

export async function resetPassword(user: User) {
    try {
        const response = await axios.get(getApi() + `/user/password/reset/${user._id}`);
        console.log("🚀============== ~ file: user.controller.ts:298 ~ resetPassword ~ response🚀==============", response)
        return response.data
    } catch (error) {
        console.error(error);
        // throw new Error(`HTTP error! status: ${response.status}`);
    }
}

//search user by email or phone number
export async function searchUser(value: string) {
    try {
        let data = {
            value: value
        }
        // console.log(data)
        const response = await axios.post(getApi() + `/user/search`, data);
        // console.log(response);
        return response.data
    } catch (error) {
        console.error(error);
        // throw new Error(`HTTP error! status: ${response.status}`);
    }
}

// save user - updating details (name, email, phone)
export async function saveUser(id: string, name: string, email: string, phone: string) {
    try {
        const response = await axios.put(getApi() + `/user/${id}`, {
            name: name,
            email: email,
            phone: phone
        });
        // console.log(response);
        return response.data
    } catch (error) {
        console.error(error);
        // throw new Error(`HTTP error! status: ${response.status}`);
    }
}
