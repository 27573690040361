import React, { useState, useEffect, useRef, useReducer, useMemo, useCallback } from 'react';
//@ts-ignore
import { useWindowDimensions, StyleSheet, View, TouchableOpacity, ScrollView } from "react-native";
import { SafeAreaView } from 'react-native-safe-area-context';
//@ts-ignore
import { Divider, Icon, Layout, Text, TopNavigation, TopNavigationAction, Card, Button, Input, ButtonGroup, CheckBox, Modal, RangeDatepicker, Datepicker } from '@ui-kitten/components';
import moment from 'moment';
import * as StorageController from '../../functions/storageController'
import * as JobController from '../../functions/job.controller'
import * as InvoiceController from '../../functions/invoice.controller'
import * as UpdateController from '../../functions/update.controller'
import * as ServiceController from '../../functions/service.controller'
import ErrorBoundary from '../ErrorBoundary.component';

import { Chart as ChartJS, ArcElement, Tooltip, Legend, BarElement, CategoryScale, LinearScale, Title } from "chart.js";
import { Doughnut, Bar } from "react-chartjs-2";
import { JOB_STATUS, Job, Service } from '../../models/Job.model';
import { ReportDetailsCard } from '../job/details/reportDetailsCard.component';
import { InvoicedTotalCard } from '../job/details/jobInvoicedTotalDetailsCard.component';
import { DashboardMap } from './dashboardMap.component';
import { RenderSearchJobsByDateRange } from './searchArea.component';
import { Client } from '../../models/Client.model';
import { Member } from '../../models/Member.model';
import { DailyBreakdown } from './dailyBreakdown.component';
import { WeeklySummaries } from './weeklysummaries.component';
import {
    calculatePercentage,
    formatTime,
    formatDateTime,
    convertToTime,
    calculateTotalCalloutFees,
    countJobsArrivedWithinTime,
    calculateAverageCreatedToPendingTime,
    calculateAverageArrivalToCompleteTime,
    calculateTotalBillBackItemsCost,
    calculateTotalCustomerItemsCost,
    getLast10Weeks,
    calculateAverageJobTime,
    calculateAverageCreatedToArrivalTime,
    namedColor
} from "./dashboardUtils"
import { ImageDetailsCard } from '../job/details/imageDetailsCard.component';
import { LogDetailsCard } from '../job/details/logDetailsCard.component';
import { JobDetailContainer } from '../job/details/jobDetailContainer.component';
import { JobDocuments } from '../job/details/jobDocuments.component';

export const JobReportModal = ({
    showReportDetailsModal,
    setShowReportDetailsModal,
    job,
    showImages,
    showLogs
}: any) => {
    const [windowHeight, setWindowHeight] = React.useState(useWindowDimensions().height * 0.9)
    const [windowWidth, setWindowWidth] = React.useState(useWindowDimensions().width * 0.9);
    const width = useWindowDimensions().width;
    const isSmallDevice = width < 800;

    const modalWidth = isSmallDevice ? windowWidth : windowWidth * 0.8;
    return (
        <Modal
            visible={showReportDetailsModal}
            backdropStyle={styles.backdrop}
            onBackdropPress={() => setShowReportDetailsModal(false)}>
            <View style={{ height: windowHeight, width: modalWidth }}>
                <FullJobReport job={job} onClose={() => setShowReportDetailsModal(false)} />
            </View>
        </Modal>
    )
}

interface JobDetailContainerProps {
    job: Job;
    onClose: () => void;
    showImages?: boolean;
    showLogs?: boolean;
}

export const FullJobReport = ({ job, onClose, showImages = true, showLogs = true }: JobDetailContainerProps) => {
    const width = useWindowDimensions().width;
    const isSmallDevice = width < 800;

    const [windowHeight, setWindowHeight] = React.useState(useWindowDimensions().height * 0.9)
    const [windowWidth, setWindowWidth] = React.useState(useWindowDimensions().width * 0.9);
    const [selectedJobImages, setSelectedJobImages] = useState([] as any[]);
    const [selectedJobLogs, setSelectedJobLogs] = useState([] as any[]);
    const [isAdmin, setIsAdmin] = useState(false);
    const [jobDetailsModalOpen, setJobDetailsModalOpen] = useState(false);

    const [showImagesState, setShowImagesState] = useState(!isSmallDevice && showImages);
    const [showLogsState, setShowLogsState] = useState(!isSmallDevice && showLogs);


    useEffect(() => {
        if (job) {
            const load = async () => {
                const images = await JobController.getImagesByJobId(job._id)
                const logs = await JobController.getJobLogsByJobId(job._id)
                setIsAdmin(StorageController.getAppState().selectedMembership?.is_admin ?? false)
                setSelectedJobLogs(logs);
                setSelectedJobImages(images);
            }
            load();
        }
        return () => {
            setSelectedJobImages([]);
            setSelectedJobLogs([]);
        }
    }, [job]);

    const closeJobDetailsModal = () => {
        setJobDetailsModalOpen(false);
    }



    const RenderMemoizedJobDetails = React.useMemo(() => {
        if (!job) return null
        return (
            <Modal
                visible={jobDetailsModalOpen}
                backdropStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
                onBackdropPress={() => closeJobDetailsModal()}
                style={{ width: windowWidth * 0.9, height: windowHeight }}
            >
                <JobDetailContainer
                    selectedJob={job}
                    fn_closeJobDetailsModal={() => { closeJobDetailsModal() }}
                />
            </Modal>
        )
    }, [job, jobDetailsModalOpen])




    return (
        <View style={{ flex: 1 }}>
            {RenderMemoizedJobDetails}
            <Button
                status='danger'
                style={{ position: 'absolute', top: 0, right: 0, zIndex: 9 }}
                onPress={() => onClose()}>
                <Icon name="close-outline" width={25} height={25} fill={"white"} />
            </Button>
            <View style={{ flexDirection: 'row', height: windowHeight }}>
                {showImagesState && !isSmallDevice &&
                    <View style={{ flex: 20 }}>
                        <ImageDetailsCard
                            images={selectedJobImages}
                            onSetImages={() => { }}
                            onSetLocalImages={() => { }}
                            propStyle={{ flex: 1 }}
                            showUploadButton={false}
                        />
                        <JobDocuments
                            job={job}
                            onSelectDocument={() => { }}
                        />
                    </View>
                }
                {showImagesState && isSmallDevice &&
                    <Modal
                        visible={showImagesState}
                        style={{ height: windowHeight, width: windowWidth }}>
                        <Button
                            status='danger'
                            style={{ position: 'absolute', top: 0, right: 0, zIndex: 9 }}
                            size="small"
                            onPress={() => setShowImagesState(false)}>
                            <Icon name="close-outline" width={20} height={20} fill={"white"} />
                        </Button>
                        <ImageDetailsCard
                            images={selectedJobImages}
                            onSetImages={() => { }}
                            onSetLocalImages={() => { }}
                            propStyle={{ flex: 1 }}
                            showUploadButton={false}
                        />
                    </Modal>
                }
                <View style={{ height: "100%", flex: 60 }}>
                    <View style={{ position: 'absolute', top: 0, width: "100%", zIndex: 9, height: 30, flexDirection: 'row', justifyContent: 'space-between' }}>
                        <View style={{ flexDirection: "row", gap: 10, paddingTop: 25 }}>
                            {/* @ts-ignore */}
                            <Button
                                // appearance='outline'
                                status='basic'
                                style={{}}
                                onPress={() => setShowImagesState(!showImagesState)}>

                                <Icon name="image-outline" width={25} height={25} fill={"white"} />
                                {showImagesState ? "Hide Images" : "Show Images"}
                            </Button>
                            {/* @ts-ignore */}
                            <Button
                                // appearance='outline'
                                status='info'
                                style={{}}
                                onPress={() => setShowLogsState(!showLogsState)}>
                                <Icon name="file-text-outline" width={25} height={25} fill={"white"} />
                                {showLogsState ? "Hide Logs" : "Show Logs"}
                            </Button>
                        </View>
                        <Button
                            status='info'
                            style={{}}
                            onPress={() => setJobDetailsModalOpen(true)}>
                            <Icon name="edit-2-outline" width={25} height={25} fill={"white"} />
                        </Button>

                    </View>
                    <ScrollView style={{ flex: 1 }}>
                        <ReportDetailsCard
                            job={job as Job}
                        />
                        <InvoicedTotalCard
                            job={job as Job}
                        />
                    </ScrollView>
                </View>
                {showLogsState && !isSmallDevice &&
                    <Layout style={{ height: "100%", flex: 20 }}>
                        <ScrollView style={{ flex: 1 }}>
                            <LogDetailsCard
                                logs={selectedJobLogs}
                            />
                        </ScrollView>
                    </Layout>
                }
                {showLogsState && isSmallDevice &&
                    <Modal
                        visible={showLogsState}
                        style={{ height: windowHeight, width: windowWidth }}>
                        <Button
                            status='danger'
                            style={{ position: 'absolute', top: 0, right: 0, zIndex: 9 }}
                            size="small"
                            onPress={() => setShowLogsState(false)}>
                            <Icon name="close-outline" width={20} height={20} fill={"white"} />
                        </Button>
                        <ScrollView style={{ flex: 1, height: windowHeight }}>
                            <LogDetailsCard
                                logs={selectedJobLogs}
                            />
                        </ScrollView>
                    </Modal>
                }
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    backdrop: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
});