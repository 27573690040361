import React, { useEffect, useState, useReducer } from 'react';
import {
    AccessibilityRole,
    ImageProps,
    ImageStyle,
    StyleSheet,
    TouchableOpacity,
    View,
    Text as RNText,
    Platform
    //@ts-ignore
} from "react-native";
import { ApplicationProvider, Modal, Button, Icon, IconRegistry, Layout, Text, List, ListItem, Card, Input, Divider } from "@ui-kitten/components";
import { NewClientScreen } from "./newClient.component";
import { ClientsList } from "./clientsList.component";
import { ClientEditScreen } from './clientEdit.component';
import * as ClientController from "../../functions/client.controller";
import * as StorageController from '../../functions/storageController';
import * as UpdateController from '../../functions/update.controller';
import { Client } from '../../models/Client.model';
import { Company } from '../../models/Company.model';
import { useAppStateChange, IAction } from '../../hooks/appStateChange.hook';

export const ClientsScreen = ({ navigation }: any) => {

    const [showNewClientModal, setShowNewClientModal] = useState(false);
    const [ignored, forceUpdate] = useReducer(x => x + 1, 0);
    const [clients, setClients] = useState([] as Client[]);
    const [selectedClientToEdit, setSelectedClientToEdit] = useState(null as Client | null);
    const [selectedCompany, setSelectedCompany] = useState(null as Company | null);




    const handleCompanyChangeEvent = React.useCallback((action: IAction) => {
        setSelectedCompany(new Company(action.data))
        setClients([])
        setSelectedClientToEdit(null)
        setTimeout(async () => {
            loadClients();
        }, 100);
    }, [selectedCompany]);

    const { dispatchEventStateChange } = useAppStateChange({
        onUpdatedSelectedCompany: handleCompanyChangeEvent
    })


    const PlusIcon = (props: any) => (
        <Icon {...props} fill='#000000' name="plus-outline" />
    );

    const loadClients = async () => {
        const company = StorageController.getAppState().selectedCompany;
        const membership = StorageController.getAppState().selectedMembership;
        if (!company || !membership) return;
        let c = await ClientController.getClientsByCompanyId(company._id)
        // console.log("in load", c)
        if (membership.is_client) {
            const client = c.filter((client: Client) => {
                return client._id == membership.client_id
            })
            c = client
        }
        setClients(c)

    }

    useEffect(() => {
        const unsubscribe = navigation.addListener('focus', () => {
            // The screen is focused
            // updatecontroller update clients
            loadClients();
        });
        return unsubscribe;
    }, [navigation]);

    const checkCanEditFields = () => {
        let canEdit = false;
        if (StorageController.getAppState().selectedMembership?.is_admin) {
            canEdit = true;
        }
        return canEdit;
    }






    const onSelectClient = (client: Client) => {
        // console.log("in onSelectClient", client)
        // forceUpdate();
        setSelectedClientToEdit(client)
    }

    const onAddClient = () => {
        // console.log("in onAddClient")
        loadClients();
    }

    const onCloseNewClientModal = () => {
        // console.log("in onCloseNewClientModal")
        setShowNewClientModal(false)
    }

    const onEditClient = (client: Client) => {
        // console.log("in onEditClient", client)
        setSelectedClientToEdit(null)
        loadClients();
    }

    const onDeleteClient = (client: Client) => {
        // console.log("in onDeleteClient", client)
        loadClients();
    }





    const NewClientModal = () => {
        return (
            <Modal
                visible={showNewClientModal}
                backdropStyle={styles.backdrop}
                style={styles.newClientModal}
                onBackdropPress={() => setShowNewClientModal(false)}>
                <NewClientScreen
                    onClose={onCloseNewClientModal}
                    onAddClient={onAddClient}
                />
            </Modal>
        )
    }


    return (
        <Layout style={{ flex: 1, flexDirection: 'column' }}>
            <NewClientModal />
            <Layout style={{ flex: 1, justifyContent: "center", alignItems: "start" }}>
                <Button
                    disabled={!checkCanEditFields()}
                    onPress={() => { setShowNewClientModal(true) }}
                    style={{ borderRadius: "100%", width: 20, height: 20, color: "white", marginLeft: 24 }}
                    accessoryLeft={PlusIcon}
                >
                </Button>
                <Divider />
            </Layout>
            <Layout style={{ flex: 10, flexDirection: 'row' }}>
                <Layout style={{ flex: 1 }}>
                    <ClientsList
                        fn_onSelectClient={onSelectClient}
                        var_selectedClientToEdit={selectedClientToEdit}
                        var_clients={clients}
                    />
                </Layout>
                <Layout style={{ flex: 5, height: '100%' }}>
                    {selectedClientToEdit &&
                        <ClientEditScreen
                            var_selectedClientToEdit={selectedClientToEdit}
                            fn_onEditClient={onEditClient}
                            fn_onDeleteClient={onDeleteClient}
                        />
                    }
                </Layout>
            </Layout>
        </Layout>
    );

}


const styles = StyleSheet.create(
    {
        container: {
            maxHeight: "100%",
        },
        contentContainer: {
            paddingHorizontal: 0,
            paddingVertical: 0,
        },
        listContainer: {
            flexDirection: "column",
            height: "100%",
            width: "100%",
            padding: 0,
            margin: 0
        },
        item: {
            marginVertical: 4,
        },
        side_section: {
            flex: 1,
            justifyContent: 'center',
            display: 'flex'
        },
        backdrop: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
        newClientModal: {
            width: "50%",
            // height: "50%",
            borderRadius: 10,
            padding: 10,
            margin: 20
        },
    }
);