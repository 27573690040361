import React, { useState, useEffect, useRef, useReducer, useCallback } from 'react';
import {
    AccessibilityRole,
    ImageProps,
    ImageStyle,
    Image,
    StyleSheet,
    TouchableOpacity,
    View,
    Text as RNText,
    Platform,
    useWindowDimensions
    //@ts-ignore
} from "react-native";
import {
    ApplicationProvider,
    Button,
    Icon,
    IconRegistry,
    Layout,
    Text,
    List,
    ListItem,
    Select,
    SelectItem,
    Toggle,
    OverflowMenu,
    MenuItem,
    Modal,
    Radio,
    RadioGroup
} from "@ui-kitten/components";
import * as ImagePicker from 'expo-image-picker';
import * as JobsController from '../../functions/job.controller'
import * as ClientController from '../../functions/client.controller'
import * as UpdateController from '../../functions/update.controller'
import * as StorageController from '../../functions/storageController'
import * as ImageController from '../../functions/image.controller'
import ErrorBoundary from '../ErrorBoundary.component';
import { Job } from '../../models/Job.model';
import { Image as ImageModel } from '../../models/Image.model'

var config = require('../../config/config.js')
var image_api = config.image_api
var image_api2 = config.image_api2


export const JobDetailImages = ({
    var_images,
    var_localImages
}: {
    var_images: any[],
    var_localImages: any[]
}) => {

    // const [job, setJob] = useState(var_job as Job)
    const [images, setImages] = useState(var_images as any[])
    const [image, setImage] = useState(null as any)
    const [isLocal, setIsLocal] = useState(false)
    const [localImages, setLocalImages] = useState([] as any[])
    const [imageLoading, setImageLoading] = useState(false)
    const [imageModalVisible, setImageModalVisible] = useState(false)
    const [windowHeight, setWindowHeight] = React.useState(useWindowDimensions().height * 0.9)
    const [windowWidth, setWindowWidth] = React.useState(useWindowDimensions().width * 0.9);

    const [useAlternativeImageApi, setUseAlternativeImageApi] = useState(false)

    const [imagesFailedToLoad, setImagesFailedToLoad] = useState([] as any[])

    useEffect(() => {
        setImages(var_images)
        setLocalImages(var_localImages)
        // setJob(var_job)
    }, [var_images, var_localImages])


    const generateImageUrl = (image: ImageModel) => {
        let id = ""
        if (image?.job_id) id = image.job_id
        if (image?.holding_id) id = image.holding_id
        if (image?.case_id) id = image.case_id

        return `${image_api}/${image?.company_id}/${id}/${image?._id}.${image?.fileType}`
    }

    const onImageFailedToLoad = (image: ImageModel) => {
        // Check if the image is already in the list of failed images
        if (!imagesFailedToLoad.some((img) => img._id === image._id)) {
            setImagesFailedToLoad((prev) => [...prev, image]);
        }
    }


    const ImageBox = ({ image, index, local }: { image: Image, index: number, local: boolean }) => {
        // Check if image is a signature by checking if desc has the word signature in it
        let isSignature = image.desc?.toLowerCase().includes("signature")
        const [loadError, setLoadError] = useState(false); // Track if there's an error loading the image
        if (imagesFailedToLoad.some((img) => img._id === image._id)) return null;
        if (loadError) return null

        return (
            <TouchableOpacity
                onPress={() => {
                    setIsLocal(local)
                    setImage(image)
                    setImageModalVisible(true)
                }}
            >
                <Image
                    style={{
                        width: "90%",
                        height: isSignature ? 100 : 200,
                        margin: 5,
                        resizeMode: "contain",
                        // backgroundColor: isSignature ? "white" : "transparent"
                    }}
                    // source={{ uri: local ? image.uri : `${image_api}/${image?._id}/${image?.fileType}` }}
                    source={{ uri: local ? image.uri : generateImageUrl(image) }}
                    onError={(e: any) => {
                        // setUseAlternativeImageApi(true)
                        onImageFailedToLoad(image)
                        // setLoadError(true);
                    }}
                />
            </TouchableOpacity>
        )
    }


    // Modal that allows for viewing of images, scales the image to the viewport, or allows the zooming of the image
    const RenderImageModal = () => {
        const [imageSize, setImageSize] = useState({ width: "100%", height: "100%" });
        const [showDeleteConfirm, setShowDeleteConfirm] = useState(false)

        const RenderDeleteConfirm = () => {
            return (
                <View style={{ flexDirection: 'row', gap: 3 }}>
                    <Button
                        appearance='outline'
                        status='warning'
                        style={{ flex: 1 }}
                        onPress={() => { setShowDeleteConfirm(false) }}
                    >
                        Cancel
                    </Button>
                    <Button
                        appearance='outline'
                        status='danger'
                        style={{ flex: 4 }}
                        onPress={() => {
                            console.log("Deleting Image", image)
                            handleDelete(image)
                            setShowDeleteConfirm(false)
                        }}
                    >
                        Delete

                    </Button>

                </View>

            )
        }


        if (!image) return null
        return (
            <Modal
                visible={imageModalVisible}
                backdropStyle={styles.backdrop}
                onBackdropPress={() => setImageModalVisible(false)}
            >
                <ErrorBoundary>
                    <Button
                        appearance='outline'
                        status='danger'
                        style={{ flex: 1, width: "10%", alignSelf: "flex-end" }}
                        onPress={() => setImageModalVisible(false)}
                    >
                        X
                    </Button>
                    <Layout
                        style={{ width: windowWidth * 0.5, height: windowHeight * 0.8 }}
                    >
                        <View
                            style={{
                                flex: 1,
                                width: "100%",
                                height: "100%",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center"
                            }}
                        >
                            <View style={{ flex: 1, flexDirection: "row", justifyContent: "space-between" }}>
                            </View>

                            <View
                                style={{ flex: 9, backgroundColor: image.desc?.toLowerCase().includes("signature") ? "white" : "transparent", flexDirection: "row" }}
                            >
                                <View style={{ flex: 1 }}></View>
                                <View
                                    style={{
                                        flex: 20,
                                        width: windowWidth * 0.4,
                                        height: windowHeight * 0.7,
                                    }}
                                >
                                    <Image
                                        style={{
                                            flex: 1,
                                            resizeMode: "contain",
                                        }}
                                        // source={{ uri: isLocal ? image.uri : `${image_api}/${image?._id}/${image?.fileType}` }}
                                        source={{ uri: isLocal ? image.uri : generateImageUrl(image) }}
                                        onError={(e: any) => {
                                            console.log("🚀============== ~ file: jobDetailsImages.component.tsx:204 ~ RenderImageModal ~ e🚀==============", e)
                                            // setUseAlternativeImageApi(true)
                                        }}
                                    />
                                </View>
                                <View style={{ flex: 1 }}>
                                </View>
                            </View>
                        </View>
                    </Layout>
                    <View style={{ flexDirection: 'row', gap: 10 }}>
                        <View style={{ flex: 1 }}>
                            {
                                showDeleteConfirm ?
                                    <RenderDeleteConfirm />
                                    :
                                    <Button
                                        appearance='outline'
                                        status='danger'
                                        style={{ flex: 1 }}
                                        onPress={() => setShowDeleteConfirm(true)}
                                    >
                                        Delete
                                    </Button>
                            }
                        </View>
                        <Button
                            appearance='outline'
                            status='info'
                            style={{ flex: 1, width: "100%" }}
                            onPress={() => { handleDownload(image) }}

                        >
                            Open Image
                        </Button>
                    </View>
                </ErrorBoundary>
            </Modal>

        )
    }





    const handleDelete = async (image: ImageModel) => {
        try {
            const result = await ImageController.deleteImageById(image._id)
            // console.log("Deleted Image", result)
            const newImages = images.filter((img) => img._id !== image._id)
            setImages(newImages)
        }
        catch (err) {
            console.log("Error deleting image", err)
        }
    }






    const handleDownload = (image: Image) => {
        const link = document.createElement('a');
        link.target = '_blank';
        link.rel = 'noopener noreferrer';
        if (isLocal) {
            // Download local image
            const byteString = atob(image.uri.split(',')[1]);
            const mimeString = image.uri.split(',')[0].split(':')[1].split(';')[0];
            const ab = new ArrayBuffer(byteString.length);
            const ia = new Uint8Array(ab);
            for (let i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
            }
            const blob = new Blob([ab], { type: mimeString });
            // get file type
            const fileType = image.uri.split(',')[0].split(':')[1].split(';')[0].split('/')[1];

            const objectUrl = URL.createObjectURL(blob);

            link.href = objectUrl;
            link.download = `image.${fileType}`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
        else {
            // Download image from server
            // link.href = `${image_api}/${image?._id}/${image?.fileType}`;
            link.href = generateImageUrl(image)
            link.download = `${image?._id}.${image?.fileType}`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }



    const getSignatures = () => {
        if (!images) return []
        let signatures = [] as ImageModel[]
        try {

            images?.forEach((image) => {
                if (image?.desc?.toLowerCase().includes("signature")) {
                    signatures.push(image)
                }
            })
            return signatures
        } catch (error) {
            console.log("🚀============== ~ file: jobDetailsImages.component.tsx:331 ~ getSignatures ~ error🚀==============", error)
            return []
        }
    }

    const getNonSignatures = () => {
        if (!images) return []
        let nonSignatures = [] as ImageModel[]
        try {
            images?.forEach((image) => {
                try {
                    if (!image?.desc?.toLowerCase().includes("signature")) {
                        nonSignatures.push(image)
                    }
                }
                catch (err) {
                    nonSignatures.push(image)
                }
            })
            return nonSignatures
        } catch (error) {
            console.log("🚀============== ~ file: jobDetailsImages.component.tsx:352 ~ getNonSignatures ~ error🚀==============", error)
            return []
        }
    }

    // capitalise and remove _ from string
    const formatString = (str: string) => {
        try {

            let s = str.replace(/_/g, " ").replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())));
            return s
        } catch (err) {
            return ""
        }
    }

    //format iso date string to local hh:mm dd/mmm/yy
    const formatDate = (date: any) => {
        try {
            const dateObj = new Date(date)
            return `${dateObj.toLocaleTimeString('en-AU',
                { hour: '2-digit', minute: '2-digit' }
            )} ${dateObj.toLocaleDateString(
                'en-AU',
                { day: '2-digit', month: 'short', year: '2-digit' }
            )}`
        }
        catch (err) {
            return ""
        }
    }


    return (
        <ErrorBoundary>
            <RenderImageModal />
            {/* Create an array of images in a gallery */}
            {
                getNonSignatures().length > 0 ?
                    <Text style={{ textAlign: "center", fontSize: 20, fontWeight: "bold", backgroundColor: "#000" }}>Images</Text>
                    :
                    <Text style={{ textAlign: "center", fontSize: 20, fontWeight: "bold", backgroundColor: "#000" }}>No Uploaded Images</Text>
            }
            <View style={{ flexDirection: "row", flexWrap: "wrap", justifyContent: "center" }}>
                {getNonSignatures()?.map((image, index) => {
                    if (!image) return null
                    if (imagesFailedToLoad.some((img) => img._id === image._id)) return null;
                    return (
                        <View key={image._id} style={{ backgroundColor: "green", width: "33%", borderWidth: 1, borderColor: "#000" }}>
                            <ImageBox key={index} image={image} index={index} local={false} />
                            <View style={{ width: "100%" }}>

                                <Text style={{ fontSize: 12 }}>{formatString(image?.desc)}</Text>
                                <Text style={{ fontSize: 12 }}>{formatDate(image?.createdAt)}</Text>
                            </View>
                            <View style={{ width: "100%", backgroundColor: "green" }}>
                                <Icon name="checkmark-circle-outline" style={{ width: 20, height: 20 }} />
                            </View>
                        </View>
                    )
                })}
            </View>
            {
                getSignatures().length > 0 ? <Text style={{ textAlign: "center", fontSize: 20, fontWeight: "bold", backgroundColor: "#000" }}>Signatures</Text> : null
            }
            {/* Gallery for signatures */}
            <View style={{ flexDirection: "row", flexWrap: "wrap", justifyContent: "center" }}>
                {getSignatures().map((image, index) => {
                    if (imagesFailedToLoad.some((img) => img._id === image._id)) return null;
                    return (
                        <View key={image._id} style={{ backgroundColor: "white", width: "25%", borderWidth: 1, borderColor: "#000" }}>
                            <ImageBox key={index} image={image} index={index} local={false} />
                            <View style={{ width: "100%", backgroundColor: "green" }}>
                                <Icon name="checkmark-circle-outline" style={{ width: 20, height: 20 }} />
                            </View>
                        </View>
                    )
                })}
            </View>
            {/* Gallery for localImages */}
            {localImages.length > 0 ? <Text style={{ textAlign: "center", fontSize: 20, fontWeight: "bold", backgroundColor: "#000" }}>Not Uploaded</Text> : null}
            <View style={{ flexDirection: "row", flexWrap: "wrap", justifyContent: "center" }}>
                {localImages.map((image, index) => {
                    // if image is in the failed to load list, don't display it
                    if (imagesFailedToLoad.some((img) => img._id === image._id)) return null;
                    return (
                        <View key={image._id} style={{ backgroundColor: "red", width: "33%", borderWidth: 1, borderColor: "#000" }}>

                            <ImageBox image={image} index={index} local={true} />
                            <Icon name="cloud-upload-outline" style={{ width: 20, height: 20 }} />
                        </View>
                    )
                })}
            </View>
        </ErrorBoundary>
    )
}

const styles = StyleSheet.create({
    backdrop: {
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
    },
});