import React, { useState, useMemo } from 'react';
import { View, StyleSheet, Animated } from 'react-native';
import { Button, Input, Select, SelectItem, Text, IndexPath, Icon, Layout } from '@ui-kitten/components';
import { RouteGroup } from '../../types/route.types';
import { CompanySavedAddress } from '../../models/Company.model';
import { ROUTE_START_END_OPTION } from '../../models/Route.model';

interface RouteGroupConfigProps {
    routeGroups: RouteGroup[];
    onUpdateRouteGroups: (groups: RouteGroup[]) => void;
    companySavedAddresses: CompanySavedAddress[];
}

export const RouteGroupConfig: React.FC<RouteGroupConfigProps> = ({
    routeGroups,
    onUpdateRouteGroups,
    companySavedAddresses,
}) => {
    const [expandedGroups, setExpandedGroups] = useState<boolean[]>(routeGroups.map(() => false));

    const animations = useMemo(() => routeGroups.map(() => new Animated.Value(0)), [routeGroups.length]);

    const toggleGroup = (index: number) => {
        const newExpandedGroups = [...expandedGroups];
        newExpandedGroups[index] = !newExpandedGroups[index];
        setExpandedGroups(newExpandedGroups);

        Animated.timing(animations[index], {
            toValue: newExpandedGroups[index] ? 1 : 0,
            duration: 300,
            useNativeDriver: false,
        }).start();
    };

    const addRouteGroup = () => {
        onUpdateRouteGroups([...routeGroups, {
            numberOfVehicles: 1,
            startEndOption: ROUTE_START_END_OPTION.DEFAULT,
        }]);
        setExpandedGroups([...expandedGroups, false]);
    };

    const updateRouteGroup = (index: number, updates: Partial<RouteGroup>) => {
        const newGroups = routeGroups.map((group, i) =>
            i === index ? { ...group, ...updates } : group
        );
        onUpdateRouteGroups(newGroups);
    };

    const removeRouteGroup = (index: number) => {
        onUpdateRouteGroups(routeGroups.filter((_, i) => i !== index));
    };

    const incrementVehicles = (index: number) => {
        updateRouteGroup(index, { numberOfVehicles: routeGroups[index].numberOfVehicles + 1 });
    };

    const decrementVehicles = (index: number) => {
        if (routeGroups[index].numberOfVehicles > 1) {
            updateRouteGroup(index, { numberOfVehicles: routeGroups[index].numberOfVehicles - 1 });
        }
    };

    const startEndOptions = [
        { title: 'Default', value: ROUTE_START_END_OPTION.DEFAULT },
        { title: 'Round Trip', value: ROUTE_START_END_OPTION.ROUND_TRIP },
        { title: 'Start First, End Last', value: ROUTE_START_END_OPTION.START_FIRST_END_LAST },
        { title: 'Custom', value: ROUTE_START_END_OPTION.CUSTOM },
    ];

    return (
        <Layout style={styles.container}>
            {routeGroups.map((group, index) => (
                <View key={index} style={styles.groupContainer}>
                    <View style={styles.groupHeader}>
                        <Text category="s1">Route Group {index + 1}</Text>
                        <View style={{flexDirection: 'row', alignItems: 'center'}}>
                            <Button
                                size="small"
                                appearance="ghost"
                                accessoryLeft={<Icon name={expandedGroups[index] ? "chevron-up" : "chevron-down"} />}
                                onPress={() => toggleGroup(index)}
                            />
                            <Button
                                size="small"
                                status="danger"
                                appearance="ghost"
                                accessoryLeft={<Icon name="trash-2-outline" />}
                                onPress={() => removeRouteGroup(index)}
                            />
                        </View>
                    </View>
                    <Text style={styles.summary}>
                        {group.numberOfVehicles} vehicle(s), {group.startEndOption.replace(/_/g, ' ').toLowerCase()}
                    </Text>
                    <Animated.View style={[
                        styles.expandableContent,
                        {
                            maxHeight: animations[index].interpolate({
                                inputRange: [0, 1],
                                outputRange: [0, 1000]
                            }),
                            opacity: animations[index],
                            overflow: 'hidden',
                        }
                    ]}>
                        <View style={styles.groupContent}>
                            <View style={styles.vehicleInputContainer}>
                                <Text style={styles.label}>Number of Vehicles</Text>
                                <View style={styles.vehicleControls}>
                                    <Button
                                        size='tiny'
                                        onPress={() => decrementVehicles(index)}
                                        accessoryLeft={<Icon name='minus-outline' />}
                                    />
                                    <Input
                                        label='Number of Vehicles'
                                        size='small'
                                        textStyle={{ maxWidth: 20 }}
                                        value={group.numberOfVehicles.toString()}
                                        onChangeText={(value) => updateRouteGroup(index, { numberOfVehicles: parseInt(value) || 1 })}
                                        keyboardType="numeric"
                                        style={styles.vehicleInput}
                                    />
                                    <Button
                                        size='tiny'
                                        onPress={() => incrementVehicles(index)}
                                        accessoryLeft={<Icon name='plus-outline' />}
                                    />
                                </View>
                            </View>
                            <Select
                                size='small'
                                label="Start/End Option"
                                value={group.startEndOption.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase())}
                                selectedIndex={new IndexPath(startEndOptions.findIndex(option => option.value === group.startEndOption))}
                                onSelect={(indexPath) => {
                                    if (indexPath instanceof IndexPath) {
                                        updateRouteGroup(index, { startEndOption: startEndOptions[indexPath.row].value });
                                    }
                                }}
                                style={styles.input}
                            >
                                {startEndOptions.map((option, i) => (
                                    <SelectItem key={i} title={option.title} />
                                ))}
                            </Select>
                            {group.startEndOption === ROUTE_START_END_OPTION.CUSTOM && (
                                <>
                                    <Select
                                        size='small'
                                        label="Start Address"
                                        value={group.startAddress ? group.startAddress.name : 'Select Start Address'}
                                        selectedIndex={new IndexPath(companySavedAddresses.findIndex(addr => addr._id === group.startAddress?._id))}
                                        onSelect={(indexPath) => {
                                            if (indexPath instanceof IndexPath) {
                                                updateRouteGroup(index, { startAddress: companySavedAddresses[indexPath.row] });
                                            }
                                        }}
                                        style={styles.input}
                                    >
                                        {companySavedAddresses.map((addr, i) => (
                                            <SelectItem key={i} title={addr.name} />
                                        ))}
                                    </Select>
                                    <Select
                                        size='small'
                                        label="End Address"
                                        value={group.endAddress ? group.endAddress.name : 'Select End Address'}
                                        selectedIndex={new IndexPath(companySavedAddresses.findIndex(addr => addr._id === group.endAddress?._id))}
                                        onSelect={(indexPath) => {
                                            if (indexPath instanceof IndexPath) {
                                                updateRouteGroup(index, { endAddress: companySavedAddresses[indexPath.row] });
                                            }
                                        }}
                                        style={styles.input}
                                    >
                                        {companySavedAddresses.map((addr, i) => (
                                            <SelectItem key={i} title={addr.name} />
                                        ))}
                                    </Select>
                                </>
                            )}
                            {group.startEndOption === ROUTE_START_END_OPTION.ROUND_TRIP && (
                                <Select
                                    size='small'
                                    label="Round Trip Address"
                                    value={group.roundTripAddress ? group.roundTripAddress.name : 'Select Round Trip Address'}
                                    selectedIndex={new IndexPath(companySavedAddresses.findIndex(addr => addr._id === group.roundTripAddress?._id))}
                                    onSelect={(indexPath) => {
                                        if (indexPath instanceof IndexPath) {
                                            updateRouteGroup(index, { roundTripAddress: companySavedAddresses[indexPath.row] });
                                        }
                                    }}
                                    style={styles.input}
                                >
                                    {companySavedAddresses.map((addr, i) => (
                                        <SelectItem key={i} title={addr.name} />
                                    ))}
                                </Select>
                            )}
                            {group.startEndOption === ROUTE_START_END_OPTION.START_FIRST_END_LAST && (
                                <>
                                    <Select
                                        size='small'
                                        label="Start Address"
                                        value={group.startAddress ? group.startAddress.name : 'Select Start Address'}
                                        selectedIndex={new IndexPath(companySavedAddresses.findIndex(addr => addr._id === group.startAddress?._id))}
                                        onSelect={(indexPath) => {
                                            if (indexPath instanceof IndexPath) {
                                                updateRouteGroup(index, { startAddress: companySavedAddresses[indexPath.row] });
                                            }
                                        }}
                                        style={styles.input}
                                    >
                                        {companySavedAddresses.map((addr, i) => (
                                            <SelectItem key={i} title={addr.name} />
                                        ))}
                                    </Select>
                                    <Select
                                        size='small'
                                        label="End Address"
                                        value={group.endAddress ? group.endAddress.name : 'Select End Address'}
                                        selectedIndex={new IndexPath(companySavedAddresses.findIndex(addr => addr._id === group.endAddress?._id))}
                                        onSelect={(indexPath) => {
                                            if (indexPath instanceof IndexPath) {
                                                updateRouteGroup(index, { endAddress: companySavedAddresses[indexPath.row] });
                                            }
                                        }}
                                        style={styles.input}
                                    >
                                        {companySavedAddresses.map((addr, i) => (
                                            <SelectItem key={i} title={addr.name} />
                                        ))}
                                    </Select>
                                </>
                            )}
                        </View>
                    </Animated.View>
                </View>
            ))}
            <Button onPress={addRouteGroup} style={styles.addButton}>Add Route Group</Button>
        </Layout>
    );
};

const styles = StyleSheet.create({
    container: {
        padding: 10,
        zIndex: 1000, // Ensure it's above other elements
    },
    groupContainer: {
        marginBottom: 10,
        borderWidth: 1,
        borderColor: '#ccc',
        borderRadius: 5,
        overflow: 'hidden',
    },
    groupHeader: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        // padding: 10,
        borderBottomWidth: 1,
        borderBottomColor: '#ccc',
    },
    summary: {
        padding: 10,
        // backgroundColor: '#f0f0f0',
    },
    expandableContent: {
        // backgroundColor: '#ffffff',
    },
    groupContent: {
        padding: 10,
    },
    vehicleInputContainer: {
        marginBottom: 10,
    },
    label: {
        marginBottom: 5,
    },
    vehicleControls: {
        flexDirection: 'row',
        alignItems: 'flex-end',
    },
    vehicleInput: {
        flex: 1,
        marginHorizontal: 10,
    },
    input: {
        marginBottom: 10,
    },
    button: {
        marginBottom: 10,
    },
    addButton: {
        marginTop: 10,
    },
});
