import * as MemberController from '../functions/membership.controller';
import { User } from './User.model';

export class Member {
    _id!: string
    name = '' as string
    details = {} as any
    company_id = '' as string | null
    user_id = '' as string
    online = false as boolean
    status = '' as string
    is_admin = false as boolean
    is_driver = false as boolean
    is_operator = false as boolean
    is_client = false as boolean
    is_client_admin = false as boolean
    client_id!: string
    permissions = new MemberPermissions()
    message_channels = [] as any[]
    settings = {} as any
    deleted = false as boolean
    locked = false as boolean
    log = [] as any[]
    rate = 0 as number
    acl = [] as any[]
    createdAt = '' as string
    updatedAt = '' as string

    //Local User
    user?= null as User | null


    constructor(details = {} as any) {
        this._id = details?._id;
        this.name = details?.name ?? '';
        this.details = details?.details ?? {};
        this.company_id = details?.company_id ?? '';
        this.user_id = details?.user_id ?? '';
        this.online = details?.online ?? false;
        this.status = details?.status ?? '';
        this.is_admin = details?.is_admin ?? false;
        this.is_driver = details?.is_driver ?? false;
        this.is_operator = details?.is_operator ?? false;
        this.is_client = details?.is_client ?? false;
        this.is_client_admin = details?.is_client_admin ?? false;
        this.client_id = details?.client_id;
        this.permissions = details?.permissions ?? new MemberPermissions(details.permissions);
        this.message_channels = details?.message_channels ?? [];
        this.settings = details?.settings ?? {};
        this.deleted = details?.deleted ?? false;
        this.locked = details?.locked ?? false;
        this.log = details?.log ?? [];
        this.rate = details?.rate ?? 0;
        this.acl = details?.acl ?? [];
        this.createdAt = details?.createdAt ?? '';
        this.updatedAt = details?.updatedAt ?? '';

        //Local User
        this.user = details.user ?? new User(details.user);
    }

    save() {
        return MemberController.updateMembership(this);
    }
}



export class MemberPermissions {
    job_assign = false
    job_reassign = false
    job_create = false
    job_edit = false
    job_delete = false
    job_set_pending = false
    job_set_complete = false
    job_view_own = false
    job_view_all = false
    member_create = false
    member_edit = false
    member_delete = false
    member_view = false
    company_edit = false
    invoice_view = false
    invoice_edit = false
    job_view_complete = false
    message_send = false
    message_view = false
    invoice_create = false
    member_view_other = false
    member_view_self = false
    member_view_all = false
    invoice_delete = false
    invoice_view_all = false
    job_view_assigned = false
    job_view_pending = false
    service_create = false
    service_edit = false
    service_delete = false
    service_field_create = false
    service_field_edit = false
    service_field_delete = false
    driver_app_view_callout = false
    driver_app_view_bill_back_callout = false
    driver_app_view_bill_back_items_cost = false
    driver_app_create_job = false
    driver_app_payment_enable_square = false

    constructor(details = {} as any) {
        this.job_assign = details?.job_assign ?? false;
        this.job_reassign = details?.job_reassign ?? false;
        this.job_create = details?.job_create ?? false;
        this.job_edit = details?.job_edit ?? false;
        this.job_delete = details?.job_delete ?? false;
        this.job_set_pending = details?.job_set_pending ?? false;
        this.job_set_complete = details?.job_set_complete ?? false;
        this.job_view_own = details?.job_view_own ?? false;
        this.job_view_all = details?.job_view_all ?? false;
        this.member_create = details?.member_create ?? false;
        this.member_edit = details?.member_edit ?? false;
        this.member_delete = details?.member_delete ?? false;
        this.member_view = details?.member_view ?? false;
        this.company_edit = details?.company_edit ?? false;
        this.invoice_view = details?.invoice_view ?? false;
        this.invoice_edit = details?.invoice_edit ?? false;
        this.job_view_complete = details?.job_view_complete ?? false;
        this.message_send = details?.message_send ?? false;
        this.message_view = details?.message_view ?? false;
        this.invoice_create = details?.invoice_create ?? false;
        this.member_view_other = details?.member_view_other ?? false;
        this.member_view_self = details?.member_view_self ?? false;
        this.member_view_all = details?.member_view_all ?? false;
        this.invoice_delete = details?.invoice_delete ?? false;
        this.invoice_view_all = details?.invoice_view_all ?? false;
        this.job_view_assigned = details?.job_view_assigned ?? false;
        this.job_view_pending = details?.job_view_pending ?? false;
        this.service_create = details?.service_create ?? false;
        this.service_edit = details?.service_edit ?? false;
        this.service_delete = details?.service_delete ?? false;
        this.service_field_create = details?.service_field_create ?? false;
        this.service_field_edit = details?.service_field_edit ?? false;
        this.service_field_delete = details?.service_field_delete ?? false;
        this.driver_app_view_callout = details?.driver_app_view_callout ?? false;
        this.driver_app_view_bill_back_callout = details?.driver_app_view_bill_back_callout ?? false;
        this.driver_app_view_bill_back_items_cost = details?.driver_app_view_bill_back_items_cost ?? false;
        this.driver_app_create_job = details?.driver_app_create_job ?? false;
        this.driver_app_payment_enable_square = details?.driver_app_payment_enable_square ?? false;
    }
}