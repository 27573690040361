
import React, { useState, useEffect, useRef, useReducer, useCallback, useMemo, createContext, useContext } from 'react';
//@ts-ignore
import { useWindowDimensions, StyleSheet, View, TouchableOpacity, ScrollView } from "react-native";
import { SafeAreaView } from 'react-native-safe-area-context';
import * as XLSX from 'xlsx';
import DataGrid from 'react-data-grid';
//@ts-ignore
import { Divider, Icon, Layout, Text, TopNavigation, TopNavigationAction, Card, Button, Input, ButtonGroup, CheckBox, Modal, RangeDatepicker, Datepicker, useTheme, IndexPath, Select, SelectItem, List, ListItem } from '@ui-kitten/components';
import ErrorBoundary from '../ErrorBoundary.component';
import * as JobsController from '../../functions/job.controller'
import * as MembershipsController from '../../functions/membership.controller'
import * as UpdateController from '../../functions/update.controller'
import * as ImageController from '../../functions/image.controller'
import * as ClientController from '../../functions/client.controller'
import * as StorageController from '../../functions/storageController'
import * as DocumentController from '../../functions/document.controller'
import { Document, DocumentCategory, SharePermissions, SharedWith } from '../../models/Document.model';
import { formatIsoDateTime, getDocumentTypeFromString } from '../../functions/utils';
import { Company } from '../../models/Company.model';


interface EditSharedWithModalProps {
    visible: boolean;
    onClose: () => void;
    companies: Company[];
    sharedWith: SharedWith | null;
    onSave: (sharedWith: SharedWith) => void;
}

const EditSharedWithModal: React.FC<EditSharedWithModalProps> = ({ visible, onClose, companies, sharedWith, onSave }) => {
    const [selectedCompanyIndex, setSelectedCompanyIndex] = useState<IndexPath | null>(null);
    const [permissions, setPermissions] = useState<SharePermissions>({
        admin: { read: false, write: false },
        operator: { read: false, write: false },
        driver: { read: false, write: false },
        client: { read: false, write: false }
    });

    useEffect(() => {
        if (sharedWith) {
            const index = companies.findIndex(c => c._id === sharedWith.company_id);
            setSelectedCompanyIndex(new IndexPath(index >= 0 ? index : 0));
            setPermissions(sharedWith.permissions || {
                admin: { read: false, write: false },
                operator: { read: false, write: false },
                driver: { read: false, write: false },
                client: { read: false, write: false }
            });
        }
    }, [sharedWith, companies]);

    const handleSave = () => {
        if (sharedWith && selectedCompanyIndex !== null) {
            sharedWith.company_id = companies[selectedCompanyIndex.row]._id;
            sharedWith.permissions = permissions;
            onSave(sharedWith);
        }
    };

    const handlePermissionChange = (key: keyof SharePermissions, type: 'read' | 'write', value: boolean) => {
        setPermissions({
            ...permissions,
            [key]: {
                ...permissions[key],
                [type]: value
            }
        });
    };

    return (
        <Modal
            visible={visible}
            backdropStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
            onBackdropPress={onClose}
        >
            <Card
                disabled
                header={() => <Text category='h6'>Edit Share</Text>}
                status='primary'
            >
                <View style={{ flexDirection: 'column', gap: 10 }}>
                    <Select
                        label="Company"
                        placeholder='Select a company'
                        value={companies[selectedCompanyIndex?.row]?.name}
                        selectedIndex={selectedCompanyIndex}
                        onSelect={(index: any) => setSelectedCompanyIndex(index)}
                    >
                        {companies.map((company) => (
                            <SelectItem key={company._id} title={company.name} />
                        ))}
                    </Select>
                    <View style={{ flexDirection: 'column', gap: 3 }}>
                        <View style={{ flexDirection: 'column' }}>
                            <Text category='h6'>Operator</Text>
                            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                <CheckBox
                                    checked={permissions.operator.read}
                                    onChange={(checked: boolean) => handlePermissionChange('operator', 'read', checked)}
                                >
                                    Read
                                </CheckBox>
                                <CheckBox
                                    checked={permissions.operator.write}
                                    onChange={(checked: boolean) => handlePermissionChange('operator', 'write', checked)}
                                >
                                    Write
                                </CheckBox>
                            </View>
                        </View>
                        <View style={{ flexDirection: 'column' }}>
                            <Text category='h6'>Driver</Text>
                            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                <CheckBox
                                    checked={permissions.driver.read}
                                    onChange={(checked: boolean) => handlePermissionChange('driver', 'read', checked)}
                                >
                                    Read
                                </CheckBox>
                                <CheckBox
                                    checked={permissions.driver.write}
                                    onChange={(checked: boolean) => handlePermissionChange('driver', 'write', checked)}
                                >
                                    Write
                                </CheckBox>
                            </View>
                        </View>
                        <View style={{ flexDirection: 'column' }}>
                            <Text category='h6'>Clients</Text>
                            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                <CheckBox
                                    checked={permissions.client.read}
                                    onChange={(checked: boolean) => handlePermissionChange('client', 'read', checked)}
                                >
                                    Read
                                </CheckBox>
                                <CheckBox
                                    checked={permissions.client.write}
                                    onChange={(checked: boolean) => handlePermissionChange('client', 'write', checked)}
                                >
                                    Write
                                </CheckBox>
                            </View>
                        </View>
                        {/* {Object.keys(permissions).map((key) => (
                        <View key={key} style={{ flexDirection: 'column' }}>
                            <Text>{`${key.charAt(0).toUpperCase() + key.slice(1)} Permissions`}</Text>
                            <CheckBox
                                checked={permissions[key as keyof SharePermissions].read}
                                onChange={(checked: boolean) => handlePermissionChange(key as keyof SharePermissions, 'read', checked)}
                            >
                                {`${key.charAt(0).toUpperCase() + key.slice(1)} Read`}
                            </CheckBox>
                            <CheckBox
                                checked={permissions[key as keyof SharePermissions].write}
                                onChange={(checked: boolean) => handlePermissionChange(key as keyof SharePermissions, 'write', checked)}
                            >
                                {`${key.charAt(0).toUpperCase() + key.slice(1)} Write`}
                            </CheckBox>
                        </View>
                    ))} */}
                    </View>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Button
                            status="success"
                            onPress={handleSave}>Save</Button>
                        <Button
                            status='danger'
                            appearance='ghost'
                            onPress={onClose}
                        >
                            Cancel
                        </Button>
                    </View>
                </View>
            </Card>
        </Modal>
    );
};

export default EditSharedWithModal;