var config = require('../config/config.js')
const axios = require('axios');
import { Member, MemberPermissions } from '../models/Member.model';
import { User } from '../models/User.model';
import * as StorageController from './storageController'

var api = config.api;
var testApi = config.test_api

function getApi() {
    if (StorageController.getAppState().use_test_api) {
        return testApi
    }
    return api
}

axios.interceptors.request.use(
    (config: any) => {
        let token = getToken();
        let member_id = null;
        if (StorageController.getAppState().selectedMembership != null) {
            member_id = StorageController.getAppState().selectedMembership?._id;
        }
        config.headers.authorization = `Bearer ${token}`;
        config.headers['member-id'] = member_id;
        return config;
    },
    (error: any) => {
        return Promise.reject(error);
    }
);

const getToken = async () => {
    return await StorageController.getAccessToken() || null;
}

//set selected driver in storage storagecontroller
export async function setSelectedMemberToEdit(memberObj: Member) {
    StorageController.appState.selectedMemberToEdit = memberObj
    StorageController.saveStateToDisk()
}

//get selected member
export async function getSelectedMemberToEdit() {
    return StorageController.appState.selectedMemberToEdit
}

//Read drivers from server from company TODO: make this where driver == true
export async function getDriversByCompanyId(companyId: string) {
    try {
        const response = await axios.get(getApi() + "/member/drivers/company/" + companyId);
        // console.log(response);
        var members = response.data
        for (var i = 0; i < members.length; i++) {
            var member = members[i]
            var user = member.user_id
            member.user = user
        }
        // members = members.map(member => new Member(member))
        return members
        // return response.data
    } catch (error) {
        console.log(error);
    }
}

//getMembersByCompanyId
export async function getMembersByCompanyId(companyId: string) {
    try {
        const response = await axios.get(getApi() + "/member/company/" + companyId);
        // console.log(response);
        //for each member get the user
        var members = response.data
        members = members.map((member: Member) => {
            var user = new User(member.user_id)
            //TODO: idk remove this business
            member.user = user
            // console.log("🚀============== ~ file: membership.controller.ts:78 ~ members=members.map ~ member🚀==============", member)
            return new Member(member)
        })
        return members
    } catch (error) {
        console.log(error);
    }
}

export async function getMemberById(memberId: string) {
    try {
        const response = await axios.get(getApi() + "/member/" + memberId);
        // console.log(response);
        var member = response.data
        var user = member.user_id
        member.user = user
        // member = new Member(member)
        return member
    } catch (error) {
        console.log(error);
    }
}


// get all members by all companies
export async function getAllMembersByAllCompanies() {
    try {
        let companies = StorageController.getAppState().companies
        let members = []
        if (!companies) {
            throw new Error("No companies found")
        }
        for (var i = 0; i < companies.length; i++) {
            var company = companies[i]
            var company_id = company._id
            var company_members = await getMembersByCompanyId(company_id)
            // company_members = company_members.map(member => new Member(member))
            company.members = company_members
        }
        return companies
    } catch (error) {
        console.log(error);
    }
}

//create user
export async function createUser(userObj: User) {
    try {
        const response = await axios.post(getApi() + "/user", userObj);
        // console.log(response);
        return response.data
    } catch (error: any) {
        console.log(error);
        if (error.response.status === 409) {
            // throw new Error("Username or Email already exists");
        } else {
            // throw new Error("Error creating user");
        }
    }
}


//create member
export async function createMember(memberObj: Member, userObj: User) {
    const company = StorageController.getAppState().selectedCompany
    if (!company) {
        throw new Error("No company selected")
    }
    memberObj.name = userObj?.name
    memberObj.user_id = userObj?._id
    memberObj.company_id = company._id

    try {
        const response = await axios.post(getApi() + "/member", memberObj);
        console.log("🚀============== ~ file: membership.controller.ts:145 ~ createMember ~ response🚀==============", response.data)
        // console.log(response);
        return response.data
    } catch (error) {
        console.error(error);
        // throw new Error(`HTTP error! status: ${response.status}`);
    }
}

// get user by id
export async function getUserById(id: string) {
    try {
        const response = await axios.get(getApi() + `/user/${id}`);
        // console.log(response);
        return response.data
    } catch (error) {
        console.error(error);
        // throw new Error(`HTTP error! status: ${response.status}`);
    }
}


// update membership
//update userobj
export async function updateUser(userObj: User) {
    try {
        const response = await axios.put(getApi() + "/user/" + userObj._id, userObj);
        // console.log(response);
        return response.data
    } catch (error) {
        console.error(error);
        // throw new Error(`HTTP error! status: ${response.status}`);
    }
}
export async function updateMembership(memberObj: Member) {
    //deep copy memberObj
    var member = JSON.parse(JSON.stringify(memberObj))
    const usr = await updateUser(memberObj.user as User)
    delete member.user
    // console.log(`Updating membership`, memberObj)
    // delete memberObj.user
    try {
        const response = await axios.put(getApi() + "/member/" + member._id, member);
        // console.log(response);
        return response.data
    } catch (error) {
        console.error(error);
        // throw new Error(`HTTP error! status: ${response.status}`);
    }
}

// get member permissions
export async function getMemberPermissions() {
    let permissions = await StorageController.getAppState().selectedMembership?.permissions
    return permissions
}

export async function tagMemeberDeleted(memberId: string) {
    try {
        const response = await axios.put(getApi() + "/member/tag/delete/" + memberId);
        // console.log(response);
        return response.data
    } catch (error) {
        console.log(error);
    }
}

export async function tagMemberLocked(memberId: string) {
    try {
        const response = await axios.put(getApi() + "/member/tag/lock/" + memberId);
        // console.log(response);
        return response.data
    } catch (error) {
        console.log(error);
    }
}

export async function tagMemberUnlocked(memberId: string) {
    try {
        const response = await axios.put(getApi() + "/member/tag/unlock/" + memberId);
        // console.log(response);
        return response.data
    } catch (error) {
        console.log(error);
    }
}






// 622a12af6925f7e172ebfff5