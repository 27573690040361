import React, { useState, useRef, useCallback, useEffect, useReducer } from 'react';
import {
    Image,
    AccessibilityRole,
    ImageProps,
    ImageStyle,
    StyleSheet,
    TouchableOpacity,
    View,
    ScrollView,
    FlatList,
    Alert,
    Clipboard,
    Text as RNText
    //@ts-ignore
} from "react-native";
import {
    ApplicationProvider,
    Button,
    Icon,
    IconRegistry,
    Divider,
    Input,
    Select,
    SelectItem,
    useTheme,
    Layout,
    Text,
    List,
    ListItem,
    OverflowMenu,
    MenuItem,
    Card,
} from "@ui-kitten/components";
import "react-datepicker/dist/react-datepicker.css";
import { JobCustomerDetails } from '../../../models/Job.model';



interface CustomerDetailsCardProps {
    style?: any;
    customerDetails: JobCustomerDetails;
    onChangeCustomerDetails: (details: JobCustomerDetails) => void;
    allowEdit?: boolean;
    handleKeyPress?: (e: any) => void;
    setRefs?: (refs: React.RefObject<any>[]) => void;
    onInputFocus?: (ref: React.RefObject<any>) => void;
}
export const CustomerDetailsCard = ({
    style,
    customerDetails,
    onChangeCustomerDetails,
    allowEdit = true,
    handleKeyPress = () => { },
    setRefs = () => { },
    onInputFocus = () => { }
}: CustomerDetailsCardProps) => {

    const nameRef = useRef<any>(null);
    const phoneRef = useRef<any>(null);
    const emailRef = useRef<any>(null);

    useEffect(() => {
        // Pass the refs back to the parent component
        if (setRefs) setRefs([nameRef, phoneRef, emailRef]);
    }, []);


    useEffect(() => {
        if (nameRef.current) nameRef.current.value = customerDetails.name;
    }, [customerDetails.name]);

    useEffect(() => {
        if (phoneRef.current) phoneRef.current.value = customerDetails.phone;
    }, [customerDetails.phone]);

    useEffect(() => {
        if (emailRef.current) emailRef.current.value = customerDetails.email;
    }, [customerDetails.email]);

    const handleInputChange = (field: keyof JobCustomerDetails, value: string) => {
        onChangeCustomerDetails({ ...customerDetails, [field]: value });
    };

    return (
        <Card
            disabled={true}
            status="info"
            header={() => <Text>Customer Details</Text>}
            style={style}
        >
            <View style={{ flexDirection: 'column', gap: 5 }}>
                <Input
                    key={`name-${customerDetails.name}`} // changing key will force this component to update
                    style={{ flex: 1, minWidth: 150 }}
                    ref={nameRef}
                    size='small'
                    placeholder='Customer Name'
                    label='Customer Name'
                    defaultValue={customerDetails.name}
                    onBlur={(e: any) => handleInputChange('name', e.nativeEvent.text)}
                    onKeyPress={handleKeyPress}
                    onFocus={() => onInputFocus(nameRef)}
                />
                <Input
                    key={`phone-${customerDetails.phone}`}
                    style={{ flex: 1, minWidth: 150 }}
                    ref={phoneRef}
                    size='small'
                    placeholder='Customer Phone'
                    label='Customer Phone'
                    defaultValue={customerDetails.phone}
                    onBlur={(e: any) => handleInputChange('phone', e.nativeEvent.text)}
                    onKeyPress={handleKeyPress}
                    onFocus={() => onInputFocus(phoneRef)}
                />
                <Input
                    key={`email-${customerDetails.email}`}
                    style={{ flex: 1, minWidth: 150 }}
                    ref={emailRef}
                    size='small'
                    placeholder='Customer Email'
                    label='Customer Email'
                    defaultValue={customerDetails.email}
                    onBlur={(e: any) => handleInputChange('email', e.nativeEvent.text)}
                    onKeyPress={handleKeyPress}
                    onFocus={() => onInputFocus(emailRef)}
                />
            </View>
        </Card>
    );

}