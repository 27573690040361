import React, { useState, useEffect, useRef, useReducer, useMemo, useCallback } from 'react';
//@ts-ignore
import { StyleSheet, View, ScrollView } from 'react-native';
import {
    ApplicationProvider,
    Button,
    Icon,
    IconRegistry,
    Divider,
    Input,
    Select,
    SelectItem,
    useTheme,
    Layout,
    Text,
    List,
    ListItem,
    OverflowMenu,
    MenuItem,
    Card,
    Toggle,
    Radio,
    RadioGroup,
    Modal
} from "@ui-kitten/components";
import { GoogleMap, useJsApiLoader, Marker, InfoWindow, TransitLayer, DirectionsService, DirectionsRenderer, OverlayView, TrafficLayer, MarkerClusterer, Polygon } from '@react-google-maps/api';
import * as UpdateController from '../../functions/update.controller';
import * as UserController from '../../functions/user.controller';
import * as StorageController from "../../functions/storageController"
import * as CompanyController from "../../functions/company.controller"
import * as MessageController from "../../functions/message.controller"
import * as GoogleController from "../../functions/google.controller"
import * as JobsController from '../../functions/job.controller'
import ErrorBoundary from '../ErrorBoundary.component';
import _ from 'lodash'
import Toast from 'react-native-toast-message';
import { AddressSearch } from './searchAddress.component';
import DistanceTool from './distanceTool.component';
import { set } from 'react-native-reanimated';
import { User } from '../../models/User.model';

interface DriverInfoWindowProps {
    onClose: () => void;
    driver: any;
    selectDriverChat?: (driver: any) => void;
    enableChat?: boolean;
}


export const DriverInfoWindow = ({
    onClose,
    driver,
    selectDriverChat = () => { },
    enableChat = false,
}: DriverInfoWindowProps) => {

    const [location, setLocation] = useState({ lat: 0, lng: 0 });
    const [timestamp, setTimestamp] = useState(new Date().getTime());
    const [heading, setHeading] = useState(0);
    const [user, setUser] = useState(null as User | null);
    const [speed, setSpeed] = useState(0);
    const [userLoaded, setUserLoaded] = useState(false);

    useEffect(() => {
        if (driver?.location?.coords) {
            setLocation({
                lat: driver?.location?.coords.latitude,
                lng: driver?.location?.coords.longitude,
            });
            setTimestamp(driver?.location?.timestamp)
            setHeading(driver?.location?.coords?.heading)
            setSpeed(driver?.location?.coords?.speed)
        } else if (driver?.details?.location?.coords) {
            setLocation({
                lat: driver?.details?.location?.coords.latitude,
                lng: driver?.details?.location?.coords.longitude,
            });
            setTimestamp(driver?.details?.location?.timestamp)
            setHeading(driver?.details?.location?.coords?.heading)
            setSpeed(driver?.details?.location?.coords?.speed)
        }
        if (!userLoaded) {
            loadUser();
            setUserLoaded(true);
        }
    }, [driver])

    const loadUser = async () => {
        const user = await UserController.getUser(driver.user_id) as User;
        if (user) {
            setUser(user as User);
        }
    }
    const CorrectIcon = (props: any) => <Icon {...props} name="checkmark-circle-2-outline" fill="green" width={24} height={24} />
    const IncorrectIcon = (props: any) => <Icon {...props} name="close-circle-outline" fill="red" width={24} height={24} />

    return (
        <InfoWindow
            position={{
                lat: location.lat,
                lng: location.lng,
            }}
            onCloseClick={() => {
                onClose();
            }}
            //@ts-ignore
            style={{
                background: "#121721",
            }}
        >
            <ErrorBoundary>
                <Card
                    disabled={true}
                    header={() =>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                            <View style={{ flexDirection: "row", justifyContent: 'space-between' }}>
                                <Text category="s1">{driver.name}</Text>
                            </View>
                        </View>
                    } status="primary">
                    <View style={{ margin: -10 }}>

                        <View style={{ flexDirection: "row", justifyContent: 'space-between' }}>
                            <Text category="s1">Updated:</Text>
                            <Text category="s1">
                                {new Date(timestamp).toLocaleString()}
                            </Text>
                        </View>
                        <Divider />
                        <View style={{ flexDirection: "row", justifyContent: 'space-between' }}>
                            <Text category="s1">Driver ID:</Text>
                            <Text>
                                {driver._id}
                            </Text>
                        </View>
                        <View style={{ flexDirection: "row", justifyContent: 'space-between' }}>
                            <Text category="s1">Driver Status:</Text>
                            <Text>{driver.status}</Text>
                        </View>
                        <View style={{ flexDirection: "row", justifyContent: 'space-between' }}>
                            <Text category="s1">Driver Location:</Text>
                            <Text style={{ fontSize: 10 }}>
                                {location.lat},{" "}
                                {location.lng}
                            </Text>
                        </View>
                        <View style={{ flexDirection: "row", justifyContent: 'space-between' }}>
                            <Text category="s1">Driver Heading:</Text>
                            <Text>
                                {heading?.toFixed(2)}°
                            </Text>
                        </View>
                        {user &&
                            <>
                                <View style={{ flexDirection: "row", justifyContent: 'space-between' }}>
                                    <Text category="s1">Location Permissions:</Text>
                                    <Text>
                                        {user?.details?.device?.location_enabled ? <CorrectIcon /> : <IncorrectIcon />}
                                    </Text>
                                </View>
                                <View style={{ flexDirection: "row", justifyContent: 'space-between' }}>
                                    <Text category="s1">Location Background:</Text>
                                    <Text>
                                        {user?.details?.device?.location_background_permission?.granted ? <CorrectIcon /> : <IncorrectIcon />}
                                    </Text>
                                </View>
                                <View style={{ flexDirection: "row", justifyContent: 'space-between' }}>
                                    <Text category="s1">Location Foreground:</Text>
                                    <Text>
                                        {user?.details?.device?.location_permission?.granted ? <CorrectIcon /> : <IncorrectIcon />}
                                    </Text>
                                </View>
                                <Divider />
                                <View style={{ flexDirection: "row", justifyContent: 'space-between' }}>
                                    <Text category="s1">Battery Permissions:</Text>
                                    <Text>
                                        {user?.details?.device?.battery_enabled ? <CorrectIcon /> : <IncorrectIcon />}
                                    </Text>
                                </View>
                                <View style={{ flexDirection: "row", justifyContent: 'space-between' }}>
                                    <Text category="s1">Battery Level:</Text>
                                    <Text>
                                        {(user?.details?.device?.battery_level * 100).toFixed(2)}%
                                    </Text>
                                </View>
                                <View style={{ flexDirection: "row", justifyContent: 'space-between' }}>
                                    <Text category="s1">Battery Optimisation:</Text>
                                    <Text>
                                        {(user?.details?.device?.battery_optimization_enabled == false) ? <CorrectIcon /> : <IncorrectIcon />}
                                    </Text>
                                </View>
                                <View style={{ flexDirection: "row", justifyContent: 'space-between' }}>
                                    <Text category="s1">Battery On Low Power:</Text>
                                    <Text>
                                        {user?.details?.device?.battery_low_power_mode ? "Yes" : "No"}
                                    </Text>
                                </View>
                                <Divider />
                                <View style={{ flexDirection: "row", justifyContent: 'space-between' }}>
                                    <Text category="s1">App Version:</Text>
                                    <Text>
                                        {user?.details?.device?.app_version}
                                    </Text>
                                </View>
                                <View style={{ flexDirection: "row", justifyContent: 'space-between' }}>
                                    <Text category="s1">Device Type:</Text>
                                    <Text>
                                        {user?.details?.device?.name}
                                    </Text>
                                </View>
                                <View style={{ flexDirection: "row", justifyContent: 'space-between' }}>
                                    <Text category="s1">Device OS:</Text>
                                    <Text>
                                        {user?.details?.device?.system_version}
                                    </Text>
                                </View>
                            </>
                        }
                        {enableChat &&
                            <button
                                onClick={() => {
                                    onClose();
                                }}
                            >
                                Chat
                            </button>

                        }
                    </View>
                </Card>
            </ErrorBoundary>
        </InfoWindow>
    )
}