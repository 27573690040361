import React from 'react';
import { Modal, Card, Text, Button } from '@ui-kitten/components';
import { View, StyleSheet } from 'react-native';

interface ConfirmationModalProps {
    visible: boolean;
    message: string;
    onConfirm: () => void;
    onCancel: () => void;
}

const BulkActionConfirmationModal: React.FC<ConfirmationModalProps> = ({ visible, message, onConfirm, onCancel }) => {
    return (
        <Modal
            visible={visible}
            backdropStyle={styles.backdrop}
            onBackdropPress={onCancel}
        >
            <Card disabled={true}>
                <Text>{message}</Text>
                <View style={styles.buttonContainer}>
                    <Button appearance="filled" status="success" onPress={onConfirm} style={styles.button}>Confirm</Button>
                    <Button appearance="ghost" status="danger" onPress={onCancel} style={styles.button}>Cancel</Button>
                </View>
            </Card>
        </Modal>
    );
};

const styles = StyleSheet.create({
    backdrop: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    buttonContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
        marginTop: 20,
    },
    button: {
        marginLeft: 10,
    },
});

export default BulkActionConfirmationModal;