import React from 'react';
import {
    Image,
    AccessibilityRole,
    ImageProps,
    ImageStyle,
    StyleSheet,
    TouchableOpacity,
    View,
    ScrollView,
    Text as RNText
    //@ts-ignore
} from "react-native";
import {
    ApplicationProvider,
    Button,
    Icon,
    IconRegistry,
    Layout,
    Text,
    List,
    ListItem,
    Card,
} from "@ui-kitten/components";

interface TooltipProps {
    text: string;
    children: React.ReactNode;
  }

const Tooltip = ({ text, children }: TooltipProps) => {
    const [isHovered, setIsHovered] = React.useState(false);

    return (
        <View style={ttStyles.container}>
            <TouchableOpacity
            //@ts-ignore
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                style={ttStyles.touchable}
            >
                {children}
                {isHovered && (
                    <View style={ttStyles.tooltip}>
                        <Text style={ttStyles.tooltipText}>{text}</Text>
                        {/* <View style={ttStyles.tooltipArrow} /> */}
                    </View>
                )}
            </TouchableOpacity>
        </View>
    );
};

const ttStyles = StyleSheet.create({
    container: {
        position: "relative",
    },
    touchable: {
        position: "relative",
    },
    tooltip: {
        position: "absolute",
        backgroundColor: "black",
        color: "white",
        padding: 5,
        borderRadius: 5,
        top: -50,
        left: 0,
        zIndex: 1000,
    },
    tooltipText: {
        color: "white",
    },
    tooltipArrow: {
        position: "absolute",
        width: 0,
        height: 0,
        borderTopWidth: 0,
        borderRightWidth: 5,
        borderBottomWidth: 5,
        borderLeftWidth: 5,
        borderTopColor: "transparent",
        borderRightColor: "transparent",
        borderBottomColor: "black",
        borderLeftColor: "transparent",
        top: "100%",
        left: "50%",
        marginLeft: -5,
    },
});

export default Tooltip;