import React, { useState, useEffect, useRef, useReducer, useMemo, useCallback } from 'react';
//@ts-ignore
import { StyleSheet, View, ScrollView } from 'react-native';
// @ts-ignore
import {
    ApplicationProvider,
    Button,
    Icon,
    IconRegistry,
    Divider,
    Input,
    Select,
    SelectItem,
    useTheme,
    Layout,
    Text,
    List,
    ListItem,
    OverflowMenu,
    MenuItem,
    Card,
    Toggle,
    Radio,
    RadioGroup,
    Modal
} from '@ui-kitten/components';



export const RenderToggles = ({
    showTrafficFlow = false,
    showDarkMap = false,
    showServiceAreaPolygons = false,
    onSetServiceAreasShown = (e: boolean) => { },
    setShowDarkMap = (e: boolean) => { },
    setShowTrafficFlow = (e: boolean) => { },
}) => {

    const RenderToggleShowTrafficFlow = () => {
        return (
            <Layout style={{ paddingLeft: 5 }}>
                <Toggle
                    checked={showTrafficFlow}
                    onChange={(e: any) => {
                        ////console.log("Show traffic flow", e)
                        setShowTrafficFlow(e)
                    }}
                >
                    Show Traffic Flow
                </Toggle>
            </Layout>
        )
    }

    const RenderToggleShowDarkMap = () => {
        return (
            <Layout style={{}}>

                <Toggle
                    checked={showDarkMap}
                    onChange={(e: any) => {
                        ////console.log("Show dark map", e)
                        setShowDarkMap(e)
                    }}
                >
                    Show Dark Map
                </Toggle>
            </Layout>
        )
    }

    const RenderToggleShowServiceAreas = () => {
        return (
            <Layout style={{ paddingLeft: 5 }}>
                <Toggle
                    style={{ paddingLeft: 10 }}
                    checked={showServiceAreaPolygons}
                    onChange={(e: any) => {
                        onSetServiceAreasShown(e)
                    }}
                >
                    Show Service Areas
                </Toggle>
            </Layout>
        )
    }
    return (
        <Layout style={{ position: "absolute", top: 0, right: 0, zIndex: 1000, borderLeft: "1px solid", borderBottom: "1px solid", boxShadow: '0 0 5px rgba(0,0,0,0.5)' }}>
            <Layout style={{ flex: 1, alignContent: 'flex-start' }}>
                <RenderToggleShowTrafficFlow />
                <RenderToggleShowDarkMap />
                <RenderToggleShowServiceAreas />
            </Layout>
        </Layout>
    )
}
