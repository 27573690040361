import React, { useState, useRef, useCallback, useEffect, useReducer } from 'react';
//@ts-ignore
import { View } from "react-native";
import {
    ApplicationProvider,
    Button,
    Icon,
    IconRegistry,
    Divider,
    Input,
    Select,
    SelectItem,
    useTheme,
    Layout,
    Text,
    List,
    ListItem,
    OverflowMenu,
    MenuItem,
    Card,
    Toggle,
    //@ts-ignore
    IndexPath,
    Radio,
    RadioGroup,
    Modal,
} from "@ui-kitten/components";
import { Job, JobTowingDetails, JobVehicleDetails } from '../../../models/Job.model';

export const TowingDetailsCard = ({
    style = {},
    allowEdit = true,
    setRefs = (e: any) => { },
    onInputFocus = (e: any) => { },
    onKeypress = (e: any) => { },
    onChangeTowingDetails = (f: any, v: any) => { },
    defaultTowingObj = {} as JobTowingDetails | null,
    // towingType
}) => {

    const towingTypeRef = useRef();

    const TOWING_TYPE_OPTIONS = ["Vehicle", "Equipment"];
    //@ts-ignore
    const [towingTypeIndex, setTowingTypeIndex] = useState(TOWING_TYPE_OPTIONS.indexOf(defaultTowingObj?.towing_type) || new IndexPath(0));
    const [towingType, setTowingType] = useState(defaultTowingObj?.towing_type || TOWING_TYPE_OPTIONS[0]);


    // State for vehicle details
    const vehRefs = useRef(new Array(5).fill(null)).current;
    const equipmentRefs = useRef(new Array(5).fill(null)).current;

    useEffect(() => {
        if (setRefs) {
            setRefs(setKeyRefs());
        }
    }, [towingType, setRefs]);

    const setKeyRefs = useCallback(() => {
        return towingType === "Vehicle" ? vehRefs : equipmentRefs;
    }, [towingType]);

    // const setKeyRefs = () => {
    //     if (towingType === "Vehicle") {
    //         return [
    //             vehRefs[0],
    //             vehRefs[1],
    //             vehRefs[2],
    //             vehRefs[3],
    //             vehRefs[4],
    //         ]
    //     } else {
    //         return [
    //             equipmentRefs[0],
    //             equipmentRefs[1],
    //             equipmentRefs[2],
    //             equipmentRefs[3],
    //             equipmentRefs[4],
    //         ]
    //     }
    // }

    const [vehicleDetails, setVehicleDetails] = useState(
        defaultTowingObj?.vehicle_details || {
            rego: '',
            make: '',
            model: '',
            year: '',
            colour: '',
        }
    );

    const [equipmentDetails, setEquipmentDetails] = useState(
        defaultTowingObj?.equipment_details || {
            equipment: '',
            type: '',
            weight: '',
            size: '',
            serial: ''
        }
    );


    const onChangeVehicleDetail = (field: keyof JobVehicleDetails, value: any) => {
        const updatedDetails = { ...vehicleDetails, [field]: value };
        setVehicleDetails(updatedDetails);
        onChangeTowingDetails('vehicle_details', updatedDetails);
    };

    const onChangeEquipmentDetail = (field: any, value: any) => {
        const updatedDetails = { ...equipmentDetails, [field]: value };
        setEquipmentDetails(updatedDetails);
        onChangeTowingDetails('equipment_details', updatedDetails);
    };


    // useEffect(() => {
    //     let refs = [
    //         // towingTypeRef,
    //         ...setKeyRefs()
    //     ]
    //     if (setRefs) setRefs(setKeyRefs());
    // }, []);

    useEffect(() => {
        setVehicleDetails(defaultTowingObj?.vehicle_details || {
            rego: '',
            make: '',
            model: '',
            year: '',
            colour: '',
        });
        setEquipmentDetails(defaultTowingObj?.equipment_details || {
            equipment: '',
            type: '',
            weight: '',
            size: '',
            serial: ''
        });
        setTowingType(defaultTowingObj?.towing_type || TOWING_TYPE_OPTIONS[0]);
    }, [defaultTowingObj]);


    // useEffect(() => {
    //     setEquipmentDetails({ ...equipmentDetails, ...defaultTowingObj?.equipment_details });
    //     setVehicleDetails({ ...vehicleDetails, ...defaultTowingObj?.vehicle_details });
    //     setTowingType(defaultTowingObj?.towing_type);
    //     console.log("🚀============== ~ file: towingDetailsCard.component.tsx:147 ~ useEffect ~ defaultTowingObj🚀==============", defaultTowingObj)
    // }, [defaultTowingObj]);


    const onChangeTowingType = (index: any) => {
        const type = TOWING_TYPE_OPTIONS[index.row];
        // remove the other type from the object
        onChangeTowingDetails('towing_type', type);

        //@ts-ignore
        setTowingTypeIndex(new IndexPath(index.row));
        console.log("🚀============== ~ file: towingDetailsCard.component.tsx:161 ~ onChangeTowingType ~ type🚀==============", type)
        setTowingType(type);
    }


    return (
        <Card
            status="warning"
            header={() => <Text>Towing Details</Text>}
            disabled={true}
            style={style}
        >
            <View style={{ flexDirection: 'row', gap: 5, flexWrap: 'wrap', justifyContent: 'flex-start' }}>
                <Select
                    ref={towingTypeRef}
                    size='small'
                    label='Towing Type'
                    value={towingType}
                    onSelect={onChangeTowingType}
                    style={{ flex: 1 }}
                >
                    {
                        TOWING_TYPE_OPTIONS.map((type, index) => (
                            <SelectItem key={index} title={type} />
                        ))
                    }
                </Select>
            </View>
            <View style={{ flexDirection: 'row', gap: 5, flexWrap: 'wrap', justifyContent: 'flex-start' }}>

                {towingType === "Vehicle" && (
                    <View style={{ flex: 1, flexDirection: 'row', gap: 5, justifyContent: 'flex-start', flexWrap: 'wrap' }}>
                        <Input
                            ref={vehRefs[0]}
                            size='small'
                            placeholder='Rego'
                            label='Registration (Rego)'
                            defaultValue={vehicleDetails.rego}
                            // onChangeText={(value) => onChangeVehicleDetail('rego', value)}
                            onBlur={(e: any) => onChangeVehicleDetail('rego', e.nativeEvent.text)}
                            onKeyPress={onKeypress}
                            onFocus={() => onInputFocus(vehRefs[0])}
                        />
                        <Input
                            ref={vehRefs[1]}
                            size='small'
                            placeholder='Make'
                            label='Make'
                            defaultValue={vehicleDetails.make}
                            // onChangeText={(value) => onChangeVehicleDetail('make', value)}
                            onBlur={(e: any) => onChangeVehicleDetail('make', e.nativeEvent.text)}
                            onKeyPress={onKeypress}
                            onFocus={() => onInputFocus(vehRefs[1])}
                        />
                        <Input
                            ref={vehRefs[2]}
                            size='small'
                            placeholder='Model'
                            label='Model'
                            defaultValue={vehicleDetails.model}
                            // onChangeText={(value) => onChangeVehicleDetail('model', value)}
                            onBlur={(e: any) => onChangeVehicleDetail('model', e.nativeEvent.text)}
                            onKeyPress={onKeypress}
                            onFocus={() => onInputFocus(vehRefs[2])}
                        />
                        <Input
                            ref={vehRefs[3]}
                            size='small'
                            placeholder='Year'
                            label='Year'
                            defaultValue={vehicleDetails.year}
                            // onChangeText={(value) => onChangeVehicleDetail('year', value)}
                            onBlur={(e: any) => onChangeVehicleDetail('year', e.nativeEvent.text)}
                            keyboardType='numeric'
                            onKeyPress={onKeypress}
                            onFocus={() => onInputFocus(vehRefs[3])}
                        />
                        <Input
                            ref={vehRefs[4]}
                            size='small'
                            placeholder='Colour'
                            label='Colour'
                            defaultValue={vehicleDetails.colour}
                            // onChangeText={(value) => onChangeVehicleDetail('colour', value)}
                            onBlur={(e: any) => onChangeVehicleDetail('colour', e.nativeEvent.text)}
                            onKeyPress={onKeypress}
                            onFocus={() => onInputFocus(vehRefs[4])}
                        />
                    </View>
                )}

                {towingType === "Equipment" && (
                    <View style={{ flex: 1, flexDirection: 'row', gap: 5, justifyContent: 'flex-start', flexWrap: 'wrap' }}>
                        <Input
                            ref={equipmentRefs[0]}
                            size='small'
                            placeholder='Equipment'
                            label='Equipment'
                            defaultValue={equipmentDetails.equipment}
                            // onChangeText={(value) => onChangeEquipmentDetail('equipment', value)}
                            onBlur={(e: any) => onChangeEquipmentDetail('equipment', e.nativeEvent.text)}
                            onKeyPress={onKeypress}
                            onFocus={() => onInputFocus(equipmentRefs[0])}
                        />
                        <Input
                            ref={equipmentRefs[1]}
                            size='small'
                            placeholder='Type'
                            label='Type'
                            defaultValue={equipmentDetails.type}
                            // onChangeText={(value) => onChangeEquipmentDetail('type', value)}
                            onBlur={(e: any) => onChangeEquipmentDetail('type', e.nativeEvent.text)}
                            onKeyPress={onKeypress}
                            onFocus={() => onInputFocus(equipmentRefs[1])}
                        />
                        <Input
                            ref={equipmentRefs[2]}
                            size='small'
                            placeholder='Weight'
                            label='Weight'
                            defaultValue={equipmentDetails.weight}
                            // onChangeText={(value) => onChangeEquipmentDetail('weight', value)}
                            onBlur={(e: any) => onChangeEquipmentDetail('weight', e.nativeEvent.text)}
                            keyboardType='numeric'
                            onKeyPress={onKeypress}
                            onFocus={() => onInputFocus(equipmentRefs[2])}
                        />
                        <Input
                            ref={equipmentRefs[3]}
                            size='small'
                            placeholder='Size'
                            label='Size'
                            defaultValue={equipmentDetails.size}
                            // onChangeText={(value) => onChangeEquipmentDetail('size', value)}
                            onBlur={(e: any) => onChangeEquipmentDetail('size', e.nativeEvent.text)}
                            onKeyPress={onKeypress}
                            onFocus={() => onInputFocus(equipmentRefs[3])}
                        />
                        <Input
                            ref={equipmentRefs[4]}
                            size='small'
                            placeholder='Serial'
                            label='Serial'
                            defaultValue={equipmentDetails.serial}
                            // onChangeText={(value) => onChangeEquipmentDetail('serial', value)}
                            onBlur={(e: any) => onChangeEquipmentDetail('serial', e.nativeEvent.text)}
                            onKeyPress={onKeypress}
                            onFocus={() => onInputFocus(equipmentRefs[4])}
                        />
                    </View>
                )}
                {/* <Input
                    key={`rego-${rego}`} //changing key will force this component to update
                    ref={regoRef}
                    size='small'
                    placeholder='Rego'
                    label='Rego'
                    defaultValue={rego}
                    onBlur={(e) => onChangeRego(e.nativeEvent.text)}
                    onKeyPress={onKeypress}
                    onFocus={() => onInputFocus(regoRef)}
                /> */}

            </View>
        </Card>
    );

}