import React, { useEffect, useCallback } from 'react';
//@ts-ignore
import { View } from 'react-native';
//@ts-ignore
import { Card, Select, Input, Button, Text, SelectItem, Icon, IndexPath } from '@ui-kitten/components';
import { BaseClientDetailsCardProps } from './ClientDetailsCardTypes';
import * as StorageController from '../../../../functions/storageController';
import { JobClientRate } from '../../../../models/Job.model';

export function BaseClientDetailsCard({
    style = {},
    cardStatus = 'info',
    headerText = 'Client Details',
    showClientSelect = false,
    showRateSelect = true,
    allowEdit = true,
    clients = [],
    clientsSelectIndexPath,
    clientDisplayValue = '',
    clientRateOptions = [],
    clientRateIndex,
    clientRatesDisplayValue = '',
    clientReferenceId,
    JobClientRateState,
    linkedClientCompany,
    showCustomClientRate = false,
    hasChosenClient = false,
    hasChosenClientRate = false,
    onClientSelected,
    onClientRateSelectedIndex,
    onSetClientRefrerenceValue,
    onSetClientBilling,
    onSetCustomerPaid,
    onSetClientRate,
    setShowCustomClientRate,
    onInputFocus,
}: BaseClientDetailsCardProps) {
    const clientReferenceIdRef = React.useRef<any>(null);

    const renderClientRatesOption = (item: any) => (
        <SelectItem key={item.name} title={`${item.name} : $${item.cost}`} />
    );

    const renderClientsOption = (item: any) => (
        <SelectItem key={item._id} title={item.name} />
    );

    const RenderCustomClientRateCard = useCallback(() => {
        return (
            <Card
                status="info"
                header={() => <Text>Custom Rate</Text>}
                disabled={true}
                style={style}
            >
                <View style={{ flexDirection: 'row', flexWrap: "wrap", justifyContent: 'flex-start' }}>
                    <Input
                        size='small'
                        style={{ flex: 1 }}
                        label='Description'
                        placeholder='Description'
                        defaultValue={JobClientRateState?.name}
                        onBlur={(e: any) => {
                            JobClientRateState.name = e.nativeEvent.text;
                            onSetClientRate(JobClientRateState);
                        }}
                    />
                    <Input
                        size='small'
                        style={{ flex: 1 }}
                        label='Client Rate $'
                        placeholder='Client Rate'
                        defaultValue={JobClientRateState?.cost?.toString()}
                        onBlur={(e: any) => {
                            const cost = e.nativeEvent.text;
                            if (isNaN(cost)) return;
                            const numCost = parseFloat(cost);
                            JobClientRateState.cost = numCost;
                            onSetClientRate(JobClientRateState);
                        }}
                    />
                </View>
            </Card>
        );
    }, [JobClientRateState]);

    const RenderToggleCustomClientRateButton = ({ style }: any) => {
        return (
            <Button
                size='small'
                status='warning'
                appearance='outline'
                style={style}
                disabled={StorageController.getAppState().selectedMembership?.is_client}
                accessoryLeft={showCustomClientRate ? (props: any) => <Icon {...props} name='close-outline' /> : (props: any) => <Icon {...props} name='edit-outline' />}
                onPress={() => setShowCustomClientRate(!showCustomClientRate)}
            >
                {showCustomClientRate ? "Hide Edit" : "Edit Rate"}
            </Button>
        );
    };

    const showCustomRateInputs = clientRateOptions.length === 0 || showCustomClientRate;

    // useEffect(() => {
    //     if (clientRateOptions.length === 0) {
    //         // Clear inputs when there are no client rates
    //         onSetClientRate(new JobClientRate({}));
    //     } else if (clientRateOptions.length > 0) {
    //         // Select the first rate option as default
    //         if(!clientRateIndex){
    //             onClientRateSelectedIndex && onClientRateSelectedIndex(new IndexPath(0));
    //         }
    //     }
    // }, [clientRateOptions]);

    return (
        <Card
            disabled
            header={() => <Text style={{ alignSelf: 'center' }}>{headerText}</Text>}
            status={cardStatus}
            style={style}
        >
            <View style={{ flexDirection: "column", justifyContent: 'flex-start', gap: 5 }}>
                {linkedClientCompany && (
                    <Text category="h6">{linkedClientCompany.name || ""}</Text>
                )}
                <View style={{ flexDirection: 'row', gap: 10 }}>
                    {showClientSelect ? (
                        <View style={{ flexDirection: 'row', flex: 3 }}>
                            <Select
                                size='small'
                                style={{ flex: 1 }}
                                label='Client'
                                placeholder="---- Select Client ----"
                                disabled={!allowEdit}
                                selectedIndex={hasChosenClient ? clientsSelectIndexPath : new IndexPath(0)}
                                value={hasChosenClient ? clientDisplayValue : "---- Select Client ----"}
                                onSelect={(index: IndexPath | IndexPath[]) => {
                                    onClientSelected && onClientSelected(index);
                                }}
                            >
                                {clients.map(renderClientsOption)}
                            </Select>
                        </View>
                    ) :
                        <Text>{clientDisplayValue}</Text>
                    }
                    {clientRateOptions.length > 0 && (
                        <View style={{ flex: 1, height: 25 }}>
                            <RenderToggleCustomClientRateButton />
                        </View>
                    )}
                </View>


                {showRateSelect ? (
                    showCustomRateInputs ? (
                        <RenderCustomClientRateCard />
                    ) : (
                        clientRateOptions.length > 0 && (
                            <Select
                                size='small'
                                selectedIndex={clientRateIndex}
                                label='Client Rate'
                                value={clientRatesDisplayValue}
                                placeholder='Select Client Rate'
                                disabled={!allowEdit}
                                onSelect={(index: IndexPath | IndexPath[]) => {
                                    onClientRateSelectedIndex && onClientRateSelectedIndex(index);
                                }}
                            >
                                {clientRateOptions.map(renderClientRatesOption)}
                            </Select>
                        )
                    )
                ) : null}

                <Input
                    ref={clientReferenceIdRef}
                    size='small'
                    style={{ margin: 5 }}
                    label='Client Reference'
                    placeholder='Client Reference'
                    defaultValue={clientReferenceId || ""}
                    disabled={!allowEdit}
                    onChangeText={onSetClientRefrerenceValue}
                    onFocus={() => onInputFocus && onInputFocus(clientReferenceIdRef)}
                />

                <View style={{ flexDirection: 'row', gap: 5 }}>
                    <Button
                        size='small'
                        status={JobClientRateState?.billed_to == "customer_cost" ? 'success' : 'basic'}
                        appearance={JobClientRateState?.billed_to == "customer_cost" ? 'filled' : 'outline'}
                        onPress={() => onSetClientBilling("customer_cost")}
                    >
                        Customer Cost
                    </Button>
                    <Button
                        size='small'
                        disabled={!allowEdit}
                        status={JobClientRateState?.billed_to == "bill_all_back" ? 'success' : 'basic'}
                        appearance={JobClientRateState?.billed_to == "bill_all_back" ? 'filled' : 'outline'}
                        onPress={() => onSetClientBilling("bill_all_back")}
                    >
                        Bill All Back
                    </Button>
                </View>

                {JobClientRateState?.billed_to == "customer_cost" && (
                    <View style={{ flexDirection: 'row', gap: 5 }}>
                        <Button
                            size='small'
                            disabled={!allowEdit}
                            status={JobClientRateState?.customer_paid ? 'success' : 'basic'}
                            appearance={JobClientRateState?.customer_paid ? 'filled' : 'outline'}
                            onPress={() => onSetCustomerPaid(true)}
                        >
                            Paid
                        </Button>
                        <Button
                            size='small'
                            disabled={!allowEdit}
                            status={!JobClientRateState?.customer_paid ? 'warning' : 'basic'}
                            appearance={!JobClientRateState?.customer_paid ? 'filled' : 'outline'}
                            onPress={() => onSetCustomerPaid(false)}
                        >
                            Unpaid
                        </Button>
                    </View>
                )}
            </View>
        </Card>
    );
}