import React, { useState, useEffect, useRef, useReducer, useCallback } from "react";
import {
    StyleSheet,
    View,
    Image,
    ScrollView,
    TouchableOpacity,
    useWindowDimensions,
    KeyboardAvoidingView,
    Keyboard,
    LayoutAnimation,
    BackHandler,
    Animated,
    PanResponder,
    Alert,
    Dimensions,
    Platform,
    //@ts-ignore
} from "react-native";
import { PinchGestureHandler, State } from "react-native-gesture-handler";
import {
    Button,
    Card,
    Layout,
    Text,
    Input,
    Modal,
    List,
    ListItem,
    Divider,
    Icon,
    Toggle,
    RadioGroup,
    Radio,
    useTheme,
} from "@ui-kitten/components";
import { Job, JobClientRate, JobReport, LineItem } from "../../models/Job.model";
import { Member } from "../../models/Member.model"
import * as StorageController from "../../functions/storageController";
import * as PaymentController from "../../functions/payment.controller";
import { SquareOrder, Payment, PaymentStates, SquarePayment, SquareLineItem } from "../../models/Payment.model";
import { formatIsoDateTime } from "../../functions/utils";
// import QRCode from 'react-qr-code';

const width = Dimensions.get("window").width;
const height = Dimensions.get("window").height;

interface SquarePaymentLinkProps {
    job: Job;
    // onPaymentSuccess: () => void;
    onPaymentFailure: () => void;
}


export const SquarePaymentLink = ({
    job,
    // onPaymentSuccess,
    onPaymentFailure,
}: SquarePaymentLinkProps) => {


    const [payments, setPayments] = useState<Payment[]>([]);
    const [isCheckingPaymentStatus, setIsCheckingPaymentStatus] = useState(false);
    const [createButtonDisabled, setCreateButtonDisabled] = useState(false);
    const [showConfirmCreatePaymentLink, setShowConfirmCreatePaymentLink] = useState(false);
    const checkPaymentStateInterval = useRef<any>(null);
    const [jobState, setJobState] = useState(new Job(job));


    useEffect(() => {
        setJobState(new Job(job));
    }, [job])

    // Check if an order has already been completed for this job
    useEffect(() => {
        // get payments from server
        loadPayments()
        return () => {
            try {
                if (checkPaymentStateInterval.current) clearTimeout(checkPaymentStateInterval.current);
            }
            catch (e) {
                // console.log("🚀============== ~ file: SquarePaymentLink.component.tsx:75 ~ useEffect ~ e🚀==============", e)
            }
        }
    }, []);

    const loadPayments = async () => {
        showIsCheckingPaymentStatus()
        const payments = await PaymentController.getPaymentsByJobId(jobState._id);
        // console.log("🚀============== ~ file: SquarePaymentLink.component.tsx:75 ~ loadPayments ~ payments🚀==============", payments)
        if (payments) {
            setPayments(payments);
            checkNonCompletePaymentsStatus(payments);
        }
    }

    const showIsCheckingPaymentStatus = () => {
        setIsCheckingPaymentStatus(true);
        setTimeout(() => {
            setIsCheckingPaymentStatus(false);
        }, 1000)
    }


    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //////// Check Payment Link Status
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */

    const checkNonCompletePaymentsStatus = async (payments: Payment[]) => {
        try {
            // get all payments that are not completed
            const nonCompletePayments = payments.filter(payment => payment.state !== PaymentStates.COMPLETED);
            if (nonCompletePayments.length == 0) {
                if (checkPaymentStateInterval.current) clearTimeout(checkPaymentStateInterval.current);
                return;
            }
            // otherwise keep chekcing load payments on interval
            checkPaymentStateInterval.current = setTimeout(() => {
                // console.log("🚀============== ~ file: SquarePaymentLink.component.tsx:111 ~ checkPaymentStateInterval.current=setInterval ~ loadPayments🚀==============")
                loadPayments();
            }, 5000);

        } catch (e) {
            console.log("🚀============== ~ file: SquarePaymentLink.component.tsx:94 ~ checkPaymentsStatus ~ e🚀==============", e)
        }
    }





    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //////// Create Payment Link 
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */


    // }) => {
    //     return (
    //         <View style={{ flexDirection: 'column' }}>
    //             <Text>Are you sure you want to create a payment link for this job?</Text>
    //             <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
    //                 <Button
    //                     status="success"
    //                     onPress={() => {
    //                         setShowConfirmCreatePaymentLink(false);
    //                         onCreatePaymentLinkButton();
    //                     }}
    //                 >
    //                     Confirm
    //                 </Button>
    //                 <Button
    //                     status="info"
    //                     appearance="ghost"
    //                     onPress={() => setShowConfirmCreatePaymentLink(false)}
    //                 >
    //                     Cancel
    //                 </Button>
    //             </View>
    //         </View>
    //     )
    // }

    const onCreatePaymentLinkButton = async () => {
        setCreateButtonDisabled(true);
        const outstandingItems = jobState.getCustomerCostItemsUnpaidIncludingClientRate()
        if (outstandingItems.length == 0) {
            alert("There are no outstanding items to bill for this job.");
            setCreateButtonDisabled(false);
            return;
        }
        else {
            await createPaymentLink();
            setCreateButtonDisabled(false);
        }
    }

    const createPaymentLink = async () => {
        try {
            const savedJob = await jobState.save(true)
            if (!savedJob) return;
            const paymentResponse = await PaymentController.createPaymentLink(savedJob._id);
            // console.log("🚀============== ~ file: SquarePaymentLink.component.tsx:137 ~ createPaymentLink ~ paymentResponse🚀==============", paymentResponse)
            if (!paymentResponse) {
                onPaymentFailure();
                return;
            }
            onPaymentLinkCreated(paymentResponse);
            return paymentResponse;
        } catch (e) {
            console.log("🚀============== ~ file: squarePaymentLink.component.tsx:208 ~ createPaymentLink ~ e================", e)
            // onPaymentFailure();
        }
    }


    const onPaymentLinkCreated = (paymentResponse: any) => {
        // console.log("🚀============== ~ file: SquarePaymentLink.component.tsx:146 ~ onPaymentLinkCreated ~ paymentResponse🚀==============", paymentResponse)
        // get payments from server again. or just append this to the list
        loadPayments()
    }






    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //////// RENDER
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */

    const onDeletePayment = (payment: Payment) => {
        loadPayments()
    }




    return (
        <View style={{}}>
            <RenderConfirmCreatePaymentLinkButtonsModal
                showConfirmCreatePaymentLink={showConfirmCreatePaymentLink}
                setShowConfirmCreatePaymentLink={setShowConfirmCreatePaymentLink}
                onCreatePaymentLinkButton={onCreatePaymentLinkButton}
                lineItems={jobState.getCustomerCostItemsUnpaidIncludingClientRate()}
            />
            <Button
                status="info"
                disabled={createButtonDisabled}
                appearance="outline"
                accessoryLeft={() => <Icon name='credit-card' width={15} height={15} fill="white" />}
                onPress={() => setShowConfirmCreatePaymentLink(true)}>Save Job & Create Square Payment</Button>
            {/* {!showConfirmCreatePaymentLink ?
                :
            } */}
            {payments && payments.length > 0 ?
                <Card
                    disabled
                    style={{ marginTop: 10 }}
                    header={() => <Text>Payment History {isCheckingPaymentStatus ? <Text> - Checking....</Text> : null}</Text>}
                    status="info"
                >
                    <View style={{ flexDirection: 'column', gap: 10 }}>
                        {payments.map((payment, index) => {
                            return (
                                <View key={index}>
                                    <RenderSubmittedPaymentDetails squarePayment={payment} onDeletePayment={onDeletePayment} />
                                </View>
                            )
                        }
                        )}
                    </View>
                </Card>
                : null}
        </View>
    );
};



/*
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
//////// ORDER DETAILS
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
*/

const RenderSubmittedPaymentDetails = ({ squarePayment, onDeletePayment }: { squarePayment: Payment, onDeletePayment: any }) => {
    // if (!squarePayment.details) return null;
    const squareOrder = squarePayment.details?.squareOrder;
    const paymentState = squarePayment.state;

    const netAmountTotal = squareOrder?.netAmounts?.totalMoney?.amount || 0;
    const taxAmount = squareOrder?.netAmounts?.taxMoney?.amount || 0;
    const discountAmount = squareOrder?.netAmounts?.discountMoney?.amount || 0;

    const netAmountDue = squareOrder?.netAmountDueMoney?.amount || 0;

    const netAmountTotalStr = (netAmountTotal / 100).toFixed(2);
    const taxAmountStr = (taxAmount / 100).toFixed(2);
    const discountAmountStr = (discountAmount / 100).toFixed(2);
    const netAmountDueStr = (netAmountDue / 100).toFixed(2);

    const [showConfirmDeletePaymentModal, setShowConfirmDeletePaymentModal] = useState(false);

    const RenderPaymentState = () => {
        // red, green, yellow, blue
        let color = 'black';
        let status = 'basic';
        switch (paymentState) {
            case PaymentStates.COMPLETED:
                color = 'green';
                status = 'success';
                break;
            case PaymentStates.FAILED:
                color = 'red';
                status = 'danger';
                break;
            case PaymentStates.PENDING:
                color = 'yellow';
                status = 'warning';
                break;
            case PaymentStates.PROCESSING:
                color = 'blue';
                status = 'info';
                break;
            default:
                color = 'yellow';
                status = 'warning';
                break;
        }
        return { color, status };
    }

    const handleLinkClick = (url: string | null) => {
        if (!url) return;
        // console.log("🚀============== ~ file: SquarePaymentLink.component.tsx:421 ~ handleLinkClick ~ url🚀==============", url)
        window.open(url, '_blank');
    }

    const handleDeletePayment = async (id: string) => {
        const deletedPayment = await PaymentController.deletePayment(id);
        if (deletedPayment) {
            onDeletePayment(deletedPayment)
            // console.log("🚀============== ~ file: SquarePaymentLink.component.tsx:421 ~ handleLinkClick ~ url🚀==============", deletedPayment)
            // get payments from server again. or just append this to the list
        }
    }

    return (
        <Card
            disabled
            status={RenderPaymentState().status}
            header={() => <Text style={{ color: RenderPaymentState().color }}>{paymentState}</Text>}
            style={{ marginBottom: 10 }}
        >
            <View style={{ flexDirection: 'column', borderWidth: 1, borderColor: 'grey' }}>
                <RenderConfirmDeletePaymentModal
                    squarePayment={squarePayment}
                    handleDeletePayment={handleDeletePayment}
                    setShowConfirmDeletePaymentModal={setShowConfirmDeletePaymentModal}
                    showConfirmDeletePaymentModal={showConfirmDeletePaymentModal}
                />
                {/* If status is not complete, button to open payment link here */}
                {paymentState !== PaymentStates.COMPLETED ?
                    <View style={{ flex: 1, flexDirection: 'row' }}>
                        <Button
                            style={{ flex: 1 }}
                            appearance="outline"
                            status="info"
                            onPress={() => {
                                handleLinkClick(squarePayment?.details?.payment_link?.url || null)
                            }}
                        >
                            Open Payment Link
                        </Button>
                    </View>
                    : null}
                {/* Order Details */}
                <View style={styles.table}>
                    <View style={styles.tableRow}>
                        <Text selectable style={styles.tableLabel}>Order ID</Text>
                        <Text selectable style={styles.tableValue}>{squareOrder?.id}</Text>
                    </View>
                    <View style={styles.tableRow}>
                        <Text selectable style={styles.tableLabel}>Created</Text>
                        <Text selectable style={styles.tableValue}>{formatIsoDateTime(squareOrder?.createdAt)}</Text>
                    </View>
                    <View style={styles.tableRow}>
                        <Text selectable style={styles.tableLabel}>Updated</Text>
                        <Text selectable style={styles.tableValue}>{formatIsoDateTime(squareOrder?.updatedAt)}</Text>
                    </View>
                    <View style={styles.tableRow}>
                        <Text selectable style={styles.tableLabel}>Payment State</Text>
                        <Text selectable style={styles.tableValue}>{paymentState}</Text>
                    </View>
                </View>
                {/* LineItems */}
                <View style={styles.table}>
                    <View style={styles.tableRow}>
                        <Text selectable style={styles.tableLabel}>Name</Text>
                        <Text selectable style={styles.tableLabel}>Quantity</Text>
                        <Text selectable style={styles.tableLabel}>Note</Text>
                        <Text selectable style={styles.tableLabel}>Type</Text>
                        <Text selectable style={styles.tableLabel}>Total</Text>
                    </View>
                    {squareOrder?.lineItems && squareOrder?.lineItems.map((item, index) => {
                        const totalMoneyAmount = item.totalMoney?.amount || 0;
                        const itemTotal = (totalMoneyAmount / 100).toFixed(2);

                        return (
                            <React.Fragment key={index}>
                                <View style={styles.tableRow}>
                                    <Text selectable style={styles.itemName}>{item.name}</Text>
                                    <Text selectable style={styles.tableValue}>{item.quantity}</Text>
                                    <Text selectable style={styles.tableValue}>{item.note}</Text>
                                    <Text selectable style={styles.tableValue}>{item.itemType}</Text>
                                    <Text selectable style={styles.tableValue}>${itemTotal}</Text>
                                </View>
                            </React.Fragment>
                        )
                    })}
                </View>
            </View>

            {/* List service charges */}
            <View style={styles.table}>
                <View style={styles.tableRow}>
                    <Text selectable style={styles.tableLabel}>Service Charges</Text>
                </View>
                {squareOrder?.serviceCharges && squareOrder?.serviceCharges.map((serviceCharge, index) => {
                    return (
                        <React.Fragment key={index}>
                            <View style={styles.tableRow}>
                                <Text selectable style={[styles.itemName, { flex: 4 }]}>{serviceCharge.name}</Text>
                                <Text selectable style={styles.tableValue}>${(serviceCharge.amountMoney.amount / 100).toFixed(2)}</Text>
                            </View>
                        </React.Fragment>
                    )
                }
                )}
            </View>

            {/* Order Details */}
            <View style={styles.table}>
                <View style={styles.tableRow}>
                    <Text selectable style={styles.tableLabel}>Net Amounts</Text>
                    <Text selectable style={styles.tableValue}></Text>
                </View>
                <View style={styles.tableRow}>
                    <Text selectable style={styles.tableLabel}>Total</Text>
                    <Text selectable style={styles.tableValue}>${netAmountTotalStr}</Text>

                </View>
                <View style={styles.tableRow}>
                    <Text selectable style={styles.tableLabel}>Tax</Text>
                    <Text selectable style={styles.tableValue}>${taxAmountStr}</Text>
                </View>
                <View style={styles.tableRow}>
                    <Text selectable style={styles.tableLabel}>Discount</Text>
                    <Text selectable style={styles.tableValue}>${discountAmountStr}</Text>
                </View>
                <View style={styles.tableRow}>
                    <Text selectable style={styles.tableLabel}>Net Amount Due</Text>
                    <Text selectable style={styles.tableValue}>${netAmountDueStr}</Text>
                </View>
                {paymentState == PaymentStates.COMPLETED ?
                    <View style={styles.tableRow}>
                        <Button
                            status="info"
                            appearance="outline"
                            onPress={() => {
                                console.log(squarePayment)
                                handleLinkClick(squarePayment?.details?.squarePayment?.receiptUrl || null)
                            }}
                        >
                            Open Receipt
                        </Button>
                    </View>
                    :
                    null
                }
                {paymentState == PaymentStates.OPEN ?
                    <View style={styles.tableRow}>
                        <Button
                            status="danger"
                            appearance="outline"
                            onPress={() => {
                                console.log(squarePayment)
                                setShowConfirmDeletePaymentModal(true)
                            }}
                        >
                            Delete Payment Request
                        </Button>
                    </View>
                    :
                    null
                }
            </View>
        </Card>
    );
}


const RenderConfirmCreatePaymentLinkButtonsModal = ({
    showConfirmCreatePaymentLink,
    setShowConfirmCreatePaymentLink,
    onCreatePaymentLinkButton,
    lineItems
}: {
    showConfirmCreatePaymentLink: any;
    setShowConfirmCreatePaymentLink: any;
    onCreatePaymentLinkButton: any;
    lineItems: LineItem[];
}) => {
    return (
        <Modal
            visible={showConfirmCreatePaymentLink}
            backdropStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
            onBackdropPress={() => setShowConfirmCreatePaymentLink(false)}>
            <Card
                disabled
                status="info"
                header={() => <Text>Confirm Payment Link</Text>}
            >
                <View style={{ flexDirection: 'column', gap: 15 }}>
                    <Text>Are you sure you want to create a payment link for this job?</Text>
                    {/* Render the line items */}
                    <View style={styles.table}>
                        <View style={styles.tableRow}>
                            <Text selectable style={styles.tableLabel}>Name</Text>
                            <Text selectable style={styles.tableLabel}>Quantity</Text>
                            <Text selectable style={styles.tableLabel}>Cost</Text>
                            <Text selectable style={styles.tableLabel}>Total</Text>
                        </View>
                        {lineItems && lineItems.map((item, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <View style={styles.tableRow}>
                                        <Text selectable style={styles.itemName}>{item.name}</Text>
                                        <Text selectable style={styles.tableValue}>{item.quantity}</Text>
                                        <Text selectable style={styles.tableValue}>{item.cost}</Text>
                                        <Text selectable style={styles.tableValue}>${Number(Number(item.quantity || 0) * Number(item.cost || 0)).toFixed(2)}</Text>
                                    </View>
                                </React.Fragment>
                            )
                        })}
                    </View>
                    <View style={styles.tableRow}>
                        <Text selectable style={[styles.tableLabel, { flex: 3 }]}>Total</Text>
                        <Text selectable style={styles.tableValue}>${lineItems.reduce((acc, item) => acc + Number(item.quantity || 0) * Number(item.cost || 0), 0).toFixed(2)}</Text>
                    </View>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Button
                            status="success"
                            onPress={() => {
                                setShowConfirmCreatePaymentLink(false);
                                onCreatePaymentLinkButton();
                            }}
                        >
                            Confirm
                        </Button>
                        <Button
                            status="info"
                            appearance="ghost"
                            onPress={() => setShowConfirmCreatePaymentLink(false)}
                        >
                            Cancel
                        </Button>
                    </View>
                </View>
            </Card>
        </Modal>
    )
}


const RenderConfirmDeletePaymentModal = ({
    squarePayment,
    handleDeletePayment,
    setShowConfirmDeletePaymentModal,
    showConfirmDeletePaymentModal
}: any) => {
    return (
        <Modal
            visible={showConfirmDeletePaymentModal}
            backdropStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
            onBackdropPress={() => setShowConfirmDeletePaymentModal(false)}>
            <Card
                disabled
                status="danger"
                header={() => <Text>Delete Payment</Text>}
            >
                <Text>Are you sure you want to delete this payment?</Text>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between', gap: 5 }}>

                    <Button
                        status="danger"
                        onPress={() => {
                            setShowConfirmDeletePaymentModal(false);
                            handleDeletePayment(squarePayment._id)
                        }}
                    >
                        Delete Payment
                    </Button>
                    <Button
                        status="info"
                        appearance="ghost"
                        onPress={() => setShowConfirmDeletePaymentModal(false)}
                    >
                        Cancel
                    </Button>
                </View>
            </Card>
        </Modal>
    )
}


const styles = StyleSheet.create({
    table: {
        borderWidth: 1,
        borderColor: 'grey',
        marginTop: 10,
    },
    tableRow: {
        flexDirection: 'row',
        borderWidth: 1,
        borderColor: 'grey',
        justifyContent: 'space-between',
    },
    tableLabel: {
        fontWeight: 'bold',
        flex: 1,
        // textAlign:'center'
    },
    tableValue: {
        flex: 1,
        borderLeftWidth: 1,
        borderColor: 'grey',
        textAlign: 'end',
        paddingRight: 5,
    },
    itemName: {
        flex: 1,
        // borderWidth: 1,
        borderColor: 'grey',
    }
});

