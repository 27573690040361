import React, { createContext, useState, useContext } from 'react';
import { mapping, light, dark } from '@eva-design/eva';
import { default as newmapping } from '../mapping.json';
import { Company, CompanyTheme } from '../models/Company.model'

const ThemeContext = createContext(null);

const mergeThemes = (base: any, custom: any) => {
    return { ...base, ...custom };
};

export const ThemeProvider = ({ children }: any) => {
    const [theme, setTheme] = useState(light);

    const toggleTheme = (companyTheme: CompanyTheme, lightOrDark: string = "dark") => {
        // console.log("🚀============== ~ file: theme.context.tsx:16 ~ toggleTheme ~ companyTheme🚀==============", companyTheme)
        if (lightOrDark == "light") {
            const newTheme = mergeThemes(light, companyTheme);
            // console.log("🚀============== ~ file: theme.context.tsx:18 ~ toggleTheme ~ newTheme🚀==============", newTheme)
            setTheme(newTheme);
        }
        else {
            const newTheme = mergeThemes(dark, companyTheme);
            // console.log("🚀============== ~ file: theme.context.tsx:22 ~ toggleTheme ~ newTheme🚀==============", newTheme)
            setTheme(newTheme);
        }
    };

    return (
        // @ts-ignore
        <ThemeContext.Provider value={{ theme, toggleTheme }}>
            {children}
        </ThemeContext.Provider>
    );
};

export const useTheme = () => {
    const context = useContext(ThemeContext);
    if (!context) {
        throw new Error("useTheme must be used within a ThemeProvider");
    }
    return context;
};