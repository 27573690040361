import React from 'react';
import { BaseClientDetailsCard } from './BaseClientDetailsCard';
import { VendorJobClientDetailsCardProps } from './ClientDetailsCardTypes';

export function VendorJobClientDetailsCard(props: VendorJobClientDetailsCardProps) {
  return (
    <BaseClientDetailsCard
      {...props}
      cardStatus="info"
      showClientSelect={true}
      showRateSelect={true}
    />
  );
}