import React, { useState, useEffect, useRef, useReducer } from 'react';
//@ts-ignore
import { StyleSheet, View, ScrollView, TouchableOpacity, useWindowDimensions, Animated } from 'react-native';
import { SafeAreaProvider, SafeAreaView } from 'react-native-safe-area-context';
import { Layout, Text, Button, Divider, List, Card, Modal, Input, Icon, ListItem } from '@ui-kitten/components';

import * as UpdateController from '../../functions/update.controller';
import * as MembershipController from '../../functions/membership.controller';
import * as StorageController from "../../functions/storageController"
import * as MessageController from "../../functions/message.controller"

import { MembersListSmallContainer } from './membersMessage/membersListSmallContainer.component'
import { MessagesScreen } from './messagingScreen.component'
import ErrorBoundary from '../ErrorBoundary.component';
import { debounce } from 'lodash';
import { Member } from '../../models/Member.model';
import { useAppStateChange, IAction, STATE_ACTIONS } from '../../hooks/appStateChange.hook';


export const MessageOverlay = ({

}: any) => {
    const [loading, setLoading] = useState(false);
    const [showChatBubble, setShowChatBubble] = useState(false);
    const [showChatSection, setShowChatSection] = useState(false);
    const [members, setMembers] = useState([] as Member[])
    const [selectedMember, setSelectedMember] = useState(null as Member | null)
    const [x, forceUpdate] = useReducer(x => x + 1, 0);
    const [isUnreadMessages, setIsUnreadMessages] = useState(false)

    const [registeredEvents, setRegisteredEvents] = useState(false)
    const [windowHeight, setWindowHeight] = React.useState(useWindowDimensions().height * 0.9)
    const [windowWidth, setWindowWidth] = React.useState(useWindowDimensions().width * 0.9);



    const m_showChatBubble = React.useMemo(() => showChatBubble, [showChatBubble]);
    const m_showChatSection = React.useMemo(() => showChatSection, [showChatSection]);
    const m_members = React.useMemo(() => members, [members]);
    const m_selectedMember = React.useMemo(() => selectedMember, [selectedMember]);



    useEffect(() => {
        if (!registeredEvents) {

            setRegisteredEvents(true)
        }
        loadMembers()
        getUnreadMessages()
        return () => {
         
        }

    }, [])




    const companySwitchedEventRegister = async (action: UpdateController.IAction) => {
        if (action.type === UpdateController.STATE_ACTIONS.UPDATED_SELECTED_COMPANY) {
            loadMembers();
            setSelectedMember(null);
            StorageController.appState.selectedChatRecipient = null;
            getUnreadMessages();
        }
    };

    const messageUpdateEventRegister = async (action: UpdateController.IAction) => {
        if (action.type === UpdateController.STATE_ACTIONS.MESSAGE_UPDATE) {
            getUnreadMessages();
        }
    };

    const registerSelectedChat = (action: UpdateController.IAction) => {
        if (action.type === UpdateController.STATE_ACTIONS.SET_SELECTED_CHAT) {
            // console.log('Selected chat channel', action.data);
            setShowChatBubble(true);
            setShowChatSection(true);
            setTimeout(() => {
                setSelectedMember(action.data.member);
            }, 10);
        }
    };

    const registerMembersUpdate = (action: UpdateController.IAction) => {
        if (action.type === UpdateController.STATE_ACTIONS.MEMBERS_UPDATE) {
            // console.log('Members update', action.data);
            // loadMembers();
            loadMembersDebounced();
        }
    };

    const { dispatchEventStateChange } = useAppStateChange({
        onUpdatedSelectedCompany: companySwitchedEventRegister,
        onMessageUpdate: messageUpdateEventRegister,
        onSetSelectedChat: registerSelectedChat,
        onMembersUpdate: registerMembersUpdate,
    });

    const loadMembersTimeoutRef = useRef(null as any);
    const loadMembersDebounced = () => {
        // if theres no timeout, execute immediatly, otherwise wait for the timeout to finish
        // if its been called less than 1 second ago then cancel the timeout and start a new one
        if (!loadMembersTimeoutRef.current) {
            loadMembers();
            loadMembersTimeoutRef.current = setTimeout(() => {
                loadMembersTimeoutRef.current = null;
            }, 1000);
        } else {
            clearTimeout(loadMembersTimeoutRef.current);
            loadMembersTimeoutRef.current = setTimeout(() => {
                loadMembersTimeoutRef.current = null;
                loadMembers();
            }, 1000);
        }
    };



    const loadMembers = async () => {
        try {
            const company = StorageController.getCurrentCompany();
            const selectedMembership = StorageController.getAppState().selectedMembership;
            if (company && company._id && selectedMembership) {
                // let _members = await MembershipController.getMembersByCompanyId(company._id)
                let _members = company.members
                // remove member by id
                StorageController.appState.members = _members;
                if (!_members) return;
                _members = _members.filter((member: Member) => member._id !== selectedMembership._id);
                // remove locked and deleted members
                _members = _members.filter((member: Member) => {
                    return !member.deleted && !member.locked
                })
                setMembers(_members);
            }
        } catch (e) {
        }
    }


    const getUnreadMessages = async () => {
        if (StorageController.getAppState().selectedMembership) {
            try {
                let _messages = await MessageController.getUnreadMessages()
                // console.log("\n \n unread messages \n \n", _messages);
                if (_messages.length > 0) {
                    // ensure that the member in the unread message is in the members list
                    let _members = StorageController.getCurrentCompany().members
                    _messages = _messages.filter((message: any) => {
                        return _members.find((member: Member) => {
                            if (member._id === message.sender._id) {
                                if (!member.locked && !member.deleted) return true
                            }
                        })
                    })
                    if (_messages.length > 0) {
                        setIsUnreadMessages(true)
                    } else {
                        setIsUnreadMessages(false)
                    }

                } else {
                    setIsUnreadMessages(false)
                    forceUpdate()
                }
                MessageController.setUnreadMessagesToStorageController(_messages)
            }
            catch (error) {
                console.log(error);
            }
        }
    }



    const onSelectMember = async (member: Member) => {
        setShowChatSection(true)
        setSelectedMember(member)

    }

    const onCloseChat = async () => {
        setSelectedMember(null);
        StorageController.appState.selectedChatRecipient = null;
        setShowChatSection(false)
    }

    const onClostMemberList = async () => {
        if (StorageController.getAppState().unreadMessageSenderIds.length > 0) {
            setIsUnreadMessages(true)
        } else {
            setIsUnreadMessages(false)
        }
        setShowChatSection(false)
        setShowChatBubble(false)
    }

    const onSendMessage = async () => {
        // console.log("message", text);

    }


    const pulseValue = useRef(new Animated.Value(1)).current;
    useEffect(() => {
        // Start the pulse animation when the component mounts
        Animated.loop(
            Animated.sequence([
                Animated.timing(pulseValue, {
                    toValue: 1.2,
                    duration: 500,
                    useNativeDriver: true,
                }),
                Animated.timing(pulseValue, {
                    toValue: 1,
                    duration: 500,
                    useNativeDriver: true,
                }),
            ])
        ).start();
    }, []);

    const RenderHideChatButton = (props: any) => {
        return (
            <TouchableOpacity
                style={[props.style, { backgroundColor: "#570000", borderRadius: 50 }]}
                onPress={() => onClostMemberList()}>
                <Icon
                    name='close-circle-outline'
                    width={50}
                    height={50}
                    fill='red'
                />
            </TouchableOpacity>
        )
    }

    const RenderShowChatButton = ({ fn_setShowChatBubble }: any) => {
        return (
            <TouchableOpacity
                style={[{ backgroundColor: "#003300", borderRadius: 50 }]}
                onPress={() => fn_setShowChatBubble(true)}>
                <Icon
                    name='arrowhead-up-outline'
                    width={50}
                    height={50}
                    fill='green'
                />
                {isUnreadMessages &&
                    <View style={{ position: 'absolute', top: 0, left: 0 }}>
                        <Animated.View style={{ transform: [{ scale: pulseValue }], backgroundColor: "red", borderRadius: 50 }}>
                            <Icon name='message-circle-outline' width={30} height={30} fill='white' />
                        </Animated.View>
                    </View>
                }
            </TouchableOpacity>
        )
    }


    return (
        <>
            {m_showChatBubble && m_showChatSection && m_members && m_selectedMember &&
                <View style={{
                    position: 'absolute',
                    right: 100,
                    bottom: 0,
                    width: "20%",
                    height: windowHeight * 0.5,
                    zIndex: 100,
                }}>

                    {loading ? <Text>Loading...</Text> :
                        <View style={{ flex: 1, flexDirection: "row" }}>
                            {/* <RenderChatSection /> */}
                            <View style={{
                                flex: 1,
                                zIndex: 100,
                                height: "100%",
                                boxShadow: '0px 0px 5px rgba(0,0,0,0.5)',
                                boxShadowRadius: 10,
                                elevation: 5,
                                alignSelf: 'flex-end', // This will anchor the view to the bottom of its parent container
                            }}>
                                <>
                                    <Layout style={{
                                        flexDirection: 'row',
                                        borderTopRightRadius: 10,
                                        borderTopLeftRadius: 10,
                                        border: '1px solid black',
                                        zIndex: 100
                                    }}>
                                        <Layout style={{ flex: 2, marginLeft: 10 }}>
                                            {selectedMember &&
                                                <Text category='label' >{selectedMember.name?.split(' ').map((s) => s.charAt(0).toUpperCase() + s.substring(1)).join(' ')}</Text>
                                            }
                                        </Layout>
                                        <Layout style={{ flex: 1, alignItems: "End" }}>
                                            {/* Make this with a red circle as the icon */}
                                            <TouchableOpacity
                                                style={{ backgroundColor: "red", borderRadius: 50, width: 20, height: 20, alignItems: "center", justifyContent: "center", zIndex: 100 }}
                                                onPress={() => onCloseChat()}>
                                                <Text>X</Text>
                                            </TouchableOpacity>
                                        </Layout>
                                    </Layout>
                                    <ErrorBoundary>
                                        <MessagesScreen style={{ flex: 1, border: "1px solid black" }}
                                            // var_messages={messages}
                                            var_selectedMember={selectedMember}
                                            fn_onSendMessage={onSendMessage}
                                            var_showChatSection={m_showChatSection}
                                        />
                                    </ErrorBoundary>
                                    {/* </Layout> */}
                                </>
                            </View>
                        </View>

                    }
                </View>
            }
            <View style={{
                position: 'absolute',
                // backgroundColor: 'red',
                right: 0,
                bottom: 0,
                width: 50,
                height: showChatBubble ? "77%" : 'auto',
            }}>
                {loading ? <Text>Loading...</Text> :
                    <View style={{ flex: 1, flexDirection: "row" }}>
                        <View style={{ flex: 1, flexDirection: "column" }}>
                            <View style={{ flex: 10, alignItems: "flex-end", justifyContent: "flex-start" }}>
                                {m_showChatBubble && m_members &&
                                    // <RenderChatListSection />
                                    <MembersListSmallContainer
                                        style={{
                                            // height: "100%",
                                            flex: 1,
                                            alignItems: "flex-end",
                                            justifyContent: "flex-end"
                                        }}
                                        var_members={m_members}
                                        var_selectedMember={m_selectedMember}
                                        fn_onSelectMember={onSelectMember}
                                    // var_showChatBubble={m_showChatBubble}
                                    // var_showChatSection={m_showChatSection}
                                    />
                                }
                            </View>
                            <View style={{ alignItems: "flex-end" }}>
                                {m_showChatBubble ?
                                    <RenderHideChatButton
                                    // style={{ position: 'absolute', bottom: 0, right: 0, zIndex: 1000, background: "white" }}
                                    />
                                    :
                                    <RenderShowChatButton
                                        fn_setShowChatBubble={setShowChatBubble}
                                    // style={{ position: 'absolute', bottom: 0, right: 0, zIndex: 1000, background: "white" }}
                                    />
                                }
                            </View>
                        </View>
                    </View>
                }
            </View>
        </>
    )

}