/**
 * @file CaseInvoicingScreen.component.js
 * @description This file contains the implementation of the CaseInvoicingScreen component.
 * The CaseInvoicingScreen component is responsible for displaying and managing the invoicing functionality for cases.
 * It includes features such as selecting cases, selecting invoices, date range selection, and displaying case and invoice details.
 * The component uses various UI components from the @ui-kitten/components library and interacts with several controller functions.
 * It also includes utility functions from the Utils module.
 * The component is exported as the default export.
 */


import React, { useState, useEffect, useRef, useReducer, useCallback, useMemo, createContext, useContext } from 'react';
import * as Print from 'expo-print';
//@ts-ignore
import { useWindowDimensions, StyleSheet, View, TouchableOpacity } from "react-native";
//@ts-ignore
import { Divider, Icon, Layout, Text, TopNavigation, TopNavigationAction, Card, Button, Input, ButtonGroup, CheckBox, Modal, RangeDatepicker, Datepicker, useTheme, IndexPath, Select, SelectItem, Spinner, List } from '@ui-kitten/components';
import * as StorageController from '../../functions/storageController'
import * as JobController from '../../functions/job.controller'
import * as InvoiceController from '../../functions/invoice.controller'
import * as UpdateController from '../../functions/update.controller'
import * as ServiceController from '../../functions/service.controller'
import * as GoogleController from '../../functions/google.controller'
import * as CaseController from '../../functions/case.controller'
import * as ClientController from '../../functions/client.controller'
import ErrorBoundary from '../ErrorBoundary.component';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable'
import { ScrollView } from 'react-native-gesture-handler';
//@ts-ignore
import { CSVLink } from "react-csv";
import { ReportDetailsCard } from '../job/details/reportDetailsCard.component';
import { LogDetailsCard } from '../job/details/logDetailsCard.component';
import { Job, JobTowingDetails, LineItem, Service } from '../../models/Job.model'
import { Case } from '../../models/Case.model';
import { Holding } from '../../models/Holding.model';
import { Invoice, InvoiceItem, XeroInvoice } from '../../models/Invoice.model';
import { InvoicedTotalCard } from '../job/details/jobInvoicedTotalDetailsCard.component';
import { HoldingDetailsContainer } from '../job/holding/holdingDetailsContainer.component';
import { HoldingReport } from '../job/holding/holdingReport.component';
import * as Utils from '../../functions/utils';
import { Client } from '../../models/Client.model';
import { Member } from '../../models/Member.model';
import { Company } from '../../models/Company.model';
import { useAppStateChange, IAction } from '../../hooks/appStateChange.hook';



/**
 * @component CaseInvoicingScreen
 * @description The CaseInvoicingScreen component is responsible for displaying and managing the invoicing functionality for cases.
 * @param {Object} navigation - The navigation object provided by React Navigation.
 * @returns {JSX.Element} The JSX element representing the CaseInvoicingScreen component.
 */
export const CaseInvoicingScreen = ({ navigation }: any) => {

    const { width, height } = useWindowDimensions()
    const [Cases, setCases] = useState([] as Case[]);
    const [selectedCase, setSelectedCase] = useState(null as Case | null);
    const [selectedInvoice, setSelectedInvoice] = useState(null);
    const [selectedCompany, setSelectedCompany] = useState(null as Company | null);

    const shouldUpdateCaseRef = useRef(false)


    const [Loading, setLoading] = useState(false)

    const [dateRange, setDateRange] = React.useState({});
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    const [currentWeek, setCurrentWeek] = useState(new Date()); // Initial week

    const dateRangeRef = useRef()
    const startDateRef = useRef(new Date())
    const endDateRef = useRef(new Date())
    const [DateRangeVisible, setDateRangeVisible] = useState(false)


    const [showCaseDetailsPanel, setShowCaseDetailsPanel] = useState(false)
    const [showInvoiceDetailsPanel, setShowInvoiceDetailsPanel] = useState(false)

    const servicesRef = useRef([] as Service[])

    const m_services = useMemo(() => {
        return servicesRef.current
    }, [servicesRef.current])

    const clientsRef = useRef([] as Client[])
    const m_clients = useMemo(() => {
        return clientsRef.current
    }, [clientsRef.current])


    // register company change listener
    const handleCompanyChangeEvent = useCallback((action: IAction) => {
        setSelectedCompany(new Company(action.data))
        setTimeout(async () => {
            loadServices()
            loadClients()
            initDateRange()
            handlePreviousWeek()
        }, 100);
    }, [dateRange]);

    const { dispatchEventStateChange } = useAppStateChange({
        onUpdatedSelectedCompany: handleCompanyChangeEvent
    })


    useEffect(() => {
        loadServices()
        loadClients()
        initDateRange()
        handlePreviousWeek()
    }, [])

    const loadServices = async () => {
        const services = await ServiceController.getServicesByCompanyId(StorageController.getCurrentCompany()._id)
        servicesRef.current = services
    }

    const loadClients = async () => {
        const clients = await ClientController.getClientsByCompanyId(StorageController.getCurrentCompany()._id)
        if (StorageController.getAppState().selectedMembership?.is_client) {
            const client = clients.find((c: Client) => c._id === StorageController.getAppState().selectedMembership?.client_id);
            clientsRef.current = [client];
        } else {
            clientsRef.current = clients;
        }
        // clientsRef.current = clients
    }

    const onSetSelectedCase = async (caseObj: Case) => {
        if (shouldUpdateCaseRef.current) {
            shouldUpdateCaseRef.current = false
            let c = await CaseController.getCaseById(caseObj._id) as Case
            if (!c) return
            // update case in cases
            const index = Cases.findIndex(c => c._id === caseObj._id)
            if (index === -1) {
                return
            }
            Cases[index] = c
            setCases(Cases)
            setSelectedCase(c)
            return
        } else {
            const c = new Case(caseObj)
            setSelectedCase(c)
        }
    }

    const onSetCases = (cases: Case[]) => {
        try {
            const c = cases.map(c => new Case(c))
            setCases(cases)
        } catch (error) {
            console.log(error)
        }
    }

    const [filteredClients, setFilteredClients] = useState([] as any)
    const [filteredServices, setFilteredServices] = useState<string[]>([])
    const [filteredStatuses, setFilteredStatuses] = useState([] as any)

    useEffect(() => {
        const selectedMembership = StorageController.getAppState().selectedMembership
        if (selectedMembership?.is_client) {
            if (selectedMembership.client_id) {
                setFilteredClients([selectedMembership.client_id || ""])
            }
        }
    }, [])

    ////////////////////////////////////////////////
    ////////////////////////////////////////////////
    ////////////////////////////////////////////////
    //////////////// FILTERS         ///////////////
    ////////////////////////////////////////////////
    ////////////////////////////////////////////////
    ////////////////////////////////////////////////
    const m_filteredCases = useMemo(() => {
        setLoading(true)
        const result = Cases.filter(caseItem => {
            // Filter by clients if filteredClients is not empty
            const clientCheck = filteredClients.length === 0 || caseItem.job_ids.some(jobId => {
                const job = new Job(jobId);
                return filteredClients.includes(job.client_id);
            });

            // Filter by services if filteredServices is not empty
            const serviceCheck = filteredServices.length === 0 || caseItem.job_ids.some(jobId => {
                const job = new Job(jobId);
                // Assuming each job has a details.selected_services array
                return job.details.selected_services.some(service => filteredServices.includes(service._id as string));
            });

            // Filter by status if filteredStatuses is not empty
            const statusCheck = filteredStatuses.length === 0 || filteredStatuses.includes(caseItem.status);

            return clientCheck && serviceCheck && statusCheck;
        });
        setLoading(false)
        return result
    }, [Cases, filteredClients, filteredServices, filteredStatuses]);


    const CASE_STATUSES = [
        'ongoing',
        'pending',
        'complete',
        'cancelled',
    ]

    const RenderFilters = () => {
        const isClient = StorageController.getAppState().selectedMembership?.is_client
        return (
            <ScrollView style={{ flex: 1 }}>
                <Card
                    // style={{ flex: 1 }}
                    status='basic'
                    disabled={true}
                    header={() =>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                            <Text category='h6'>Filters</Text>
                        </View>
                    }>
                    <View style={{ flexDirection: 'column', gap: 35 }}>
                        {!isClient ?
                            <Card
                                style={{ padding: 5, margin: -15 }}
                                status='success'
                                disabled={true}
                                header={() =>
                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                        <Text category='s1'>Clients</Text>
                                    </View>
                                }
                            >
                                <View style={{ flexDirection: 'column' }}>
                                    {m_clients.map((client, index) => {
                                        return (
                                            <CheckBox
                                                key={index}
                                                checked={filteredClients.includes(client._id)}
                                                onChange={(nextChecked: boolean) => {
                                                    if (nextChecked) {
                                                        setFilteredClients([...filteredClients, client._id])
                                                    } else {
                                                        setFilteredClients(filteredClients.filter((c: string) => c !== client._id))
                                                    }
                                                }}>
                                                {client.name}
                                            </CheckBox>
                                        )
                                    })}
                                </View>
                            </Card>
                            : <></>
                        }
                        <Card
                            style={{ padding: 5, margin: -15 }}
                            status='warning'
                            disabled={true}
                            header={() =>
                                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                    <Text category='s1'>Services</Text>
                                </View>
                            }
                        >
                            <View style={{ flexDirection: 'column' }}>
                                {m_services?.map((service, index) => {
                                    return (
                                        <CheckBox
                                            key={index}
                                            checked={filteredServices.includes(service._id as string)}
                                            onChange={(nextChecked: boolean) => {
                                                if (nextChecked) {
                                                    setFilteredServices([...filteredServices, service._id as string])
                                                } else {
                                                    setFilteredServices(filteredServices.filter(s => s !== service._id))
                                                }
                                            }}>
                                            {service.name}
                                        </CheckBox>
                                    )
                                }
                                )}
                            </View>
                        </Card>
                        <Card
                            style={{ padding: 5, margin: -15 }}
                            status='danger'
                            disabled={true}
                            header={() =>
                                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                    <Text category='s1'>Statuses</Text>
                                </View>
                            }
                        >
                            <View style={{ flexDirection: 'column' }}>
                                {CASE_STATUSES.map((status, index) => {
                                    return (
                                        <CheckBox
                                            key={index}
                                            checked={filteredStatuses.includes(status)}
                                            onChange={(nextChecked: boolean) => {
                                                if (nextChecked) {
                                                    setFilteredStatuses([...filteredStatuses, status])
                                                } else {
                                                    setFilteredStatuses(filteredStatuses.filter((s: any) => s !== status))
                                                }
                                            }}>
                                            {status}
                                        </CheckBox>
                                    )
                                }
                                )}
                            </View>
                        </Card>
                    </View>
                </Card>
            </ScrollView>
        )
    }




    ////////////////////////////////////////////////
    ////////////////////////////////////////////////
    ////////////////////////////////////////////////
    //////////////// CSV EXPORT      ///////////////
    ////////////////////////////////////////////////
    ////////////////////////////////////////////////
    ////////////////////////////////////////////////

    const generateXeroInvoiceData = (selectedCase: Case, billingType = "bill_all_back") => {
        if (!selectedCase) return []
        const c = new Case(selectedCase)
        let lineItemsArray = [] as LineItem[]
        const jobHoldingsList = c.getJobsAndHoldings()
        let client: Client | undefined = undefined;
        const firstJob = jobHoldingsList.find(item => item instanceof Job)
        let caseDescription = ""
        if (firstJob) {
            try {
                client = StorageController.getAppState().clients.find(c => c._id === firstJob?.client_id)
                // Exists on both job and holding 
                //@ts-ignore
                caseDescription = firstJob.buildDescriptionString()
            } catch (e) {
                console.log(e)
            }
        }
        if (billingType === "bill_all_back") {
            lineItemsArray = [...lineItemsArray, ...c.getTotalBillBackItems()]
        }
        else if (billingType === "customer_cost") {
            lineItemsArray = [...lineItemsArray, ...c.getTotalCustomerCostItems()]
        }
        else {
            lineItemsArray = [...lineItemsArray, ...c.getTotalBillBackItems(), ...c.getTotalCustomerCostItems()]
        }

        const XeroInvoiceData = new XeroInvoice({
            accountCode: client?.account_code,
            clientName: client?.name,
            taxType: "GST On Income",
            invoiceNumber: c.friendly_id,
            invoiceDate: Utils.formatTimestamp(Date.now()),
            dueDate: Utils.formatTimestamp(Date.now() + 30 * 24 * 60 * 60 * 1000),
            lineItems: lineItemsArray,
            description: `CASE - ${c.name}` + caseDescription,
            reference: c.friendly_id,
            quantity: 1,
            unitAmount: 0,
        })
        const result = XeroInvoiceData.toXeroInvoiceArray()
        return result
    }

    // Usage example for each useMemo
    const m_selectedCaseInvoiceExportData = useMemo(() => {
        if (!selectedCase) return []
        return generateXeroInvoiceData(selectedCase, "all")
    }, [selectedCase]);

    const m_selectedCaseCustomerCostItems = useMemo(() => {
        if (!selectedCase) return []
        return generateXeroInvoiceData(selectedCase, "customer_cost")
    }, [selectedCase])

    const m_selectedCaseBillBackItems = useMemo(() => {
        if (!selectedCase) return []
        return generateXeroInvoiceData(selectedCase, "bill_all_back")
    }, [selectedCase])


    ////////////////////////////////////////////////
    ////////////////////////////////////////////////
    ////////////////////////////////////////////////
    //////////////// PDF EXPORT      ///////////////
    ////////////////////////////////////////////////
    ////////////////////////////////////////////////
    ////////////////////////////////////////////////
    const m_selectedCaseInvoiceExportDataPdf = useMemo(() => {
        if (!selectedCase) return null
        const customerCostItems = selectedCase?.getTotalCustomerCostItems()
        const billBackItems = selectedCase?.getTotalBillBackItems()
        const company = StorageController.getCurrentCompany()
        const companyDetails = {
            name: company?.name,
            address: company?.settings?.location?.address,
            phone: company?.settings?.contact_info.phone
        }
        const total = selectedCase?.getTotalCost()
        const customerPaidAmount = selectedCase?.getTotalCustomerPaid()
        const customerOutstandingAmount = total - customerPaidAmount
        return InvoiceController.generateInvoiceHTML(
            selectedCase.name,
            companyDetails,
            customerCostItems,
            billBackItems,
            [],
            total,
            customerPaidAmount,
            customerOutstandingAmount,
            true
        )
    }, [selectedCase])

    const m_selectedCaseCustomerCostItemsPdf = useMemo(() => {
        if (!selectedCase) return null
        const _selectedCase = new Case(selectedCase)

        const customerCostItems = selectedCase?.getTotalCustomerCostItems()
        const company = StorageController.getCurrentCompany()
        const companyDetails = {
            name: company?.name,
            address: company?.settings?.location?.address,
            phone: company?.settings?.contact_info?.phone,
        }
        const total = _selectedCase?.getTotalCustomerCost()
        const customerPaidAmount = _selectedCase?.getTotalCustomerPaid()
        const customerOutstandingAmount = total - customerPaidAmount
        return InvoiceController.generateInvoiceHTML(
            selectedCase.name,
            companyDetails,
            customerCostItems,
            [],
            [],
            total,
            customerPaidAmount,
            customerOutstandingAmount,
            true
        )
    }, [selectedCase])

    const m_selectedCaseBillBackItemsPdf = useMemo(() => {
        if (!selectedCase) return null
        const billBackItems = selectedCase?.getTotalBillBackItems()
        const company = StorageController.getCurrentCompany()
        const companyDetails = {
            name: company?.name,
            address: company?.settings?.location?.address,
            phone: company?.settings?.contact_info?.phone,
        }
        const total = selectedCase?.getTotalBillBack()
        return InvoiceController.generateInvoiceHTML(
            selectedCase.name,
            companyDetails,
            [],
            billBackItems,
            [],
            total,
            0,
            0,
            false
        )
    }, [selectedCase])


    const handleExportToPDF = async (htmlData: any, filename: string) => {
        const doc = new jsPDF({
            orientation: 'portrait',
            unit: 'px',
            format: 'a4'
        })
        doc.html(htmlData, {
            html2canvas: { scale: 1 },
            filename: filename,
            margin: 10,
            autoPaging: true,
            callback: function (pdf) {
                try {
                    pdf.output('dataurlnewwindow', { filename: filename })
                } catch (e) {
                    console.log(e)
                    pdf.save(filename)
                }
            }
        })
    };




    ////////////////////////////////////////////////
    ////////////////////////////////////////////////
    ////////////////////////////////////////////////
    //////////////// DATE VALIDATION ///////////////
    ////////////////////////////////////////////////
    ////////////////////////////////////////////////
    ////////////////////////////////////////////////

    const onDateRangeChange = (nextRange: any) => {
        if (validateDateRange(nextRange)) {
            setDateRange(nextRange);
            searchCasesByDateRange(nextRange.startDate, nextRange.endDate);
        }
    };

    const updateDateRange = (week: Date) => {
        const startDate = new Date(week);
        const endDate = new Date(week);
        startDate.setDate(startDate.getDate() - week.getDay() + 1);
        endDate.setDate(endDate.getDate() - week.getDay() + 7);
        startDateRef.current = startDate;
        endDateRef.current = endDate;
        const nextRange = { startDate, endDate };
        onDateRangeChange(nextRange);
    };

    // validate start date is before end date
    const validateDateRange = (nextRange: any) => {
        const { startDate, endDate } = nextRange; // are Date objects
        return startDate <= endDate;
    };

    const validateStartDate = (date: Date) => {
        date = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 5, 5, 5);
        setStartDate(date)
    }
    const validateEndDate = (date: Date) => {
        setEndDate(date)
    }
    const validateRange = (range: any) => {
        validateStartDate(range.startDate)
        validateEndDate(range.endDate)
        setDateRange(range)
    }

    const initDateRange = async () => {
        const week = new Date();
        week.setDate(week.getDate() - 7);
        const _startDate = new Date(week);
        const _endDate = new Date(week);
        _startDate.setDate(_startDate.getDate() - week.getDay() + 1);
        _endDate.setDate(_endDate.getDate() - week.getDay() + 7);
        startDateRef.current = _startDate;
        endDateRef.current = _endDate;
        setStartDate(_startDate);
        setEndDate(_endDate);
    }

    const handlePreviousWeek = () => {
        const previousWeek = new Date(currentWeek);
        previousWeek.setDate(previousWeek.getDate() - 7);
        setCurrentWeek(previousWeek);
        updateDateRange(previousWeek);
    };

    const handleNextWeek = () => {
        const nextWeek = new Date(currentWeek);
        nextWeek.setDate(nextWeek.getDate() + 7);
        setCurrentWeek(nextWeek);
        updateDateRange(nextWeek);
    };

    const searchCasesByDateRange = async (start = startDateRef.current, end = endDateRef.current) => {
        setLoading(true)
        try {
            if (!start || !end) return
            start.setHours(0, 0, 0, 0)
            end.setHours(23, 59, 59, 999)
            const company_id = StorageController?.getCurrentCompany()?._id
            const cases = await CaseController.getCasesByCompanyIdAndDateRange(company_id, start.getTime(), end.getTime())
            onSetCases(cases)
        } catch (error) {
            console.error(error)
        }
        setLoading(false)
    }

    ////////////////////////////////////////////////
    ////////////////////////////////////////////////
    ////////////////////////////////////////////////
    //////////////// MODALS         ////////////////
    ////////////////////////////////////////////////
    ////////////////////////////////////////////////
    ////////////////////////////////////////////////
    const [showJobReportDetailsModal, setShowJobReportDetailsModal] = useState(false)
    const [showHoldingDetailsModal, setShowHoldingDetailsModal] = useState(false)
    const [showConfirmSetAllCustomerItemsPaidModal, setShowConfirmSetAllCustomerItemsPaidModal] = useState(false)

    const [selectedJob, setSelectedJob] = useState(null as Job | null)
    const [selectedHolding, setSelectedHolding] = useState(null as Holding | null)
    const [selectedClient, setSelectedClient] = useState(null as Client | null)

    const onOpenJobDetailsPanel = async (job: Job) => {
        if (!job.client_id) return
        console.log("🚀============== ~ file: CaseInvoicingScreen.component.js:217 ~ onOpenJobDetailsPanel ~ job🚀==============", job)
        const client = await ClientController.getClientById(job.client_id)
        const logs = await JobController.getJobLogsByJobId(job._id)
        job.job_logs = logs || []
        setSelectedClient(client)
        setSelectedJob(job)
        setShowJobReportDetailsModal(true)
    }

    const onOpenHoldingDetailsPanel = async (holding: Holding) => {
        let client = await ClientController.getClientById(holding.client_id as string)
        setSelectedClient(client)
        setSelectedHolding(holding)
        setShowHoldingDetailsModal(true)
    }



    const onSetJobDetailsModal = (value: boolean) => {
        if (!value) setSelectedJob(null)
        shouldUpdateCaseRef.current = true
        if (!selectedCase) return
        onSetSelectedCase(selectedCase)
        setShowJobReportDetailsModal(value)
    }

    const onSetHoldingDetailsModal = (value: boolean) => {
        if (!value) setSelectedHolding(null)
        shouldUpdateCaseRef.current = true
        if (!selectedCase) return
        onSetSelectedCase(selectedCase)
        setShowHoldingDetailsModal(value)
    }

    const onSetAllCaseCustomerItemsPaid = async () => {
        setLoading(true)
        try {
            if (!selectedCase) return
            const result = await CaseController.setCaseCustomerCostPaid(selectedCase, true)
            console.log("🚀============== ~ file: CaseInvoicingScreen.component.js:429 ~ onSetAllCaseCustomerItemsPaid ~ result🚀==============", result)
            shouldUpdateCaseRef.current = true
            onSetSelectedCase(selectedCase)
        } catch (error) {
            console.error(error)
        }
        setLoading(false)
    }

    const RenderConfirmSetAllCustomerItemsPaidModal = () => {
        return (
            <Modal
                key={`confirm-set-all-customer-items-paid-modal-${Date.now()}`}
                visible={showConfirmSetAllCustomerItemsPaidModal}
                backdropStyle={styles.backdrop}
                style={{ width: width * 0.9, height: height * 0.9 }}
                onBackdropPress={() => setShowConfirmSetAllCustomerItemsPaidModal(false)}>
                <Card
                    style={{ flex: 1 }}
                    status='basic'
                    disabled={true}
                    header={() =>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                            <Text category='h6'>Confirm Set All Customer Items Paid</Text>
                            <Button
                                status='danger'
                                appearance='outline'
                                onPress={() => {
                                    setShowConfirmSetAllCustomerItemsPaidModal(false)
                                }}>
                                <Icon name='close-outline' width={24} height={24} fill="white" />
                            </Button>
                        </View>
                    }>
                    <View style={{ flexDirection: 'column' }}>
                        <Text category='s1'>Are you sure you want to set all customer items as paid?</Text>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                            <Button
                                status='danger'
                                onPress={() => {
                                    setShowConfirmSetAllCustomerItemsPaidModal(false)
                                }}>
                                Cancel
                            </Button>
                            <Button
                                status='success'
                                onPress={() => {
                                    setShowConfirmSetAllCustomerItemsPaidModal(false)
                                    onSetAllCaseCustomerItemsPaid()
                                }}>
                                Confirm
                            </Button>
                        </View>
                    </View>
                </Card>
            </Modal>
        )
    }



    const JobReportDetailsModal = () => {
        if (!selectedJob) return null
        return (

            <Modal
                key={`job-report-modal-${Date.now()}`}
                visible={showJobReportDetailsModal}
                backdropStyle={styles.backdrop}
                style={{ width: width * 0.9, height: height * 0.9 }}
                onBackdropPress={() => onSetJobDetailsModal(false)}>
                <Card
                    style={{ flex: 1 }}
                    status='basic'
                    disabled={true}
                    header={() =>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                            <Text category='h6'>{selectedJob.friendly_id}</Text>
                            <Text category='s1'>{selectedJob.status}</Text>
                            <Button
                                status='danger'
                                onPress={() => {
                                    onSetJobDetailsModal(false)
                                }}>
                                <Icon name='close-outline' width={24} height={24} fill="white" />
                            </Button>
                        </View>
                    }>
                    <View style={{ flexDirection: 'row' }}>
                        <View style={{ flex: 1, flexDirection: 'column', height: "100%" }}>
                            <ScrollView style={{ height: height * 0.8 }}>
                                <RenderClientDetailsCard client={selectedClient || new Client({})} />
                                <ReportDetailsCard
                                    job={selectedJob}
                                // caseItem={selectedCase as Case}
                                // fn_closeModal={() => onSetJobDetailsModal(false)}
                                />
                                <LogDetailsCard
                                    logs={selectedJob.job_logs}
                                />
                            </ScrollView>
                        </View>
                        <View style={{ flex: 1, flexDirection: 'column' }}>
                            <ScrollView style={{ height: height * 0.8 }}>
                                <InvoicedTotalCard
                                    style={{ flex: 1 }}
                                    job={selectedJob}
                                    canEdit={true}
                                // caseItem={selectedCase}
                                // fn_closeModal={() => onSetJobDetailsModal(false)}
                                />
                            </ScrollView>
                        </View>
                    </View>
                </Card>
            </Modal>
        )
    }

    const HoldingDetailsModal = () => {
        if (!selectedHolding) return null
        return (
            <Modal
                key={`holding-details-modal-${Date.now()}`}
                visible={showHoldingDetailsModal}
                backdropStyle={styles.backdrop}
                style={{ width: width * 0.9, height: height * 0.9 }}
                onBackdropPress={() => onSetHoldingDetailsModal(false)}>
                <HoldingReport
                    holding={selectedHolding}
                    onClose={() => onSetHoldingDetailsModal(false)}
                />
            </Modal>
        )
    }


    ////////////////////////////////////////////////
    ////////////////////////////////////////////////
    ////////////////////////////////////////////////
    //////////////// PANELS         ////////////////
    ////////////////////////////////////////////////
    ////////////////////////////////////////////////
    ////////////////////////////////////////////////

    const RenderInvoiceDetailsPanel = ({ }) => {
        return (
            <Card
                disabled={true}
                style={{ flex: 1 }}>
                {/* <Text category='h6'>{selectedInvoice._id}</Text>
                <Text category='s1'>{selectedInvoice.status}</Text>
                <Text category='s1'>{selectedInvoice.details}</Text> */}
            </Card>
        )
    }



    const RenderCaseDetailsPanel = ({ }) => {
        let totalCost = 0
        let totalBillBack = 0
        let totalCustomerCost = 0

        let created = ""
        let pending = ""
        let completed = ""
        if (!selectedCase) return null
        try {
            totalCost = Number(Number(selectedCase.getTotalCost()).toFixed(2))
            totalBillBack = Number(Number(selectedCase.getTotalBillBack()).toFixed(2))
            totalCustomerCost = Number(Number(selectedCase.getTotalCustomerCost()).toFixed(2))
            created = Utils.formatTimestamp(selectedCase.createdAt) as string
            pending = Utils.formatTimestamp(selectedCase.pending_at || selectedCase.updatedAt) as string
            completed = Utils.formatTimestamp(selectedCase.completed_at || selectedCase.updatedAt) as string
        }
        catch (e) {
            console.log(e)
        }
        return (
            <Card
                disabled
                accent={<RenderCardStatusAccent status={selectedCase.status} />}
                style={{ flex: 1, borderLeftWidth: 3, borderLeftColor: 'grey' }}
                header={() => {
                    return (
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                            <Text category='h6'>{selectedCase.name}</Text>
                            <Text category='s1'>{selectedCase.status}</Text>
                            <Button
                                status='danger'
                                onPress={() => {
                                    setShowCaseDetailsPanel(false)
                                }}>
                                <Icon name='close-outline' width={24} height={24} fill="white" />
                            </Button>
                        </View>
                    )
                }}
            >
                <View style={{ flex: 1, flexDirection: 'column', margin: -15 }}>
                    <ScrollView style={{ height: height * 0.85, flexDirection: 'column' }}>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                            <Text category='s1'>Export Invoice Options: </Text>
                            <View style={{ flexDirection: 'row', gap: 5 }}>

                                <CSVLink
                                    data={m_selectedCaseBillBackItems}
                                    filename={`${selectedCase.name}_invoice.csv`}
                                    target="_blank"
                                >
                                    <Button
                                        status='primary'
                                        size='tiny'
                                        appearance='outline'
                                        accessoryLeft={() => <Icon name='download-outline' width={24} height={24} fill="white" />}
                                    >
                                        Bill Back Items CSV
                                    </Button>
                                </CSVLink>

                                <CSVLink
                                    data={m_selectedCaseCustomerCostItems}
                                    filename={`${selectedCase.name}_invoice.csv`}
                                    target="_blank"
                                >
                                    <Button
                                        status='primary'
                                        size='tiny'
                                        appearance='outline'
                                        accessoryLeft={() => <Icon name='download-outline' width={24} height={24} fill="white" />}
                                    >
                                        Customer Cost Items CSV
                                    </Button>
                                </CSVLink>
                                <CSVLink
                                    data={m_selectedCaseInvoiceExportData}
                                    filename={`${selectedCase.name}_invoice.csv`}
                                    target="_blank"
                                >
                                    <Button
                                        status='primary'
                                        size='tiny'
                                        appearance='outline'
                                        accessoryLeft={() => <Icon name='download-outline' width={24} height={24} fill="white" />}
                                    >
                                        All Invoice Items CSV
                                    </Button>
                                </CSVLink>
                            </View>
                        </View>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                            <Text category='s1'>Export PDF Options: </Text>
                            <View style={{ flexDirection: 'row', gap: 5 }}>

                                {/* PDF export options */}
                                <Button
                                    status='primary'
                                    size='tiny'
                                    appearance='outline'
                                    accessoryLeft={() => <Icon name='download-outline' width={24} height={24} fill="white" />}
                                    onPress={async () => {
                                        handleExportToPDF(m_selectedCaseBillBackItemsPdf, `${selectedCase.name}_invoice.pdf`)
                                    }}
                                >
                                    Bill Back Items PDF
                                </Button>
                                <Button
                                    status='primary'
                                    size='tiny'
                                    appearance='outline'
                                    accessoryLeft={() => <Icon name='download-outline' width={24} height={24} fill="white" />}
                                    onPress={async () => {
                                        const ccitems = selectedCase.getTotalCustomerCostItems()
                                        console.log("🚀============== ~ file: CaseInvoicingScreen.component.js:599 ~ onPress={ ~ ccitems🚀==============", ccitems)
                                        handleExportToPDF(m_selectedCaseCustomerCostItemsPdf, `${selectedCase.name}_invoice.pdf`)
                                    }}
                                >
                                    Customer Cost Items PDF
                                </Button>
                                <Button
                                    status='primary'
                                    size='tiny'
                                    appearance='outline'
                                    accessoryLeft={() => <Icon name='download-outline' width={24} height={24} fill="white" />}
                                    onPress={async () => {
                                        handleExportToPDF(m_selectedCaseInvoiceExportDataPdf, `${selectedCase.name}_invoice.pdf`)
                                    }}
                                >
                                    All Invoice Items PDF
                                </Button>
                            </View>
                        </View>
                        <View style={{ flexDirection: 'row', gap: 10 }}>

                            <View style={{ flex: 1, flexDirection: 'column', borderLeftWidth: 1, borderLeftColor: 'grey', paddingLeft: 3 }}>
                                <Text style={{ borderBottomColor: 'grey', borderBottomWidth: 1 }} category='s1'>Times:</Text>
                                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                    <Text category='s1'>Created: </Text>
                                    <Text category='s1'>{created}</Text>
                                </View>
                                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                    <Text category='s1'>Pending: </Text>
                                    <Text category='s1'>{pending}</Text>
                                </View>
                                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                    <Text category='s1'>Completed: </Text>
                                    <Text category='s1'>{completed}</Text>
                                </View>
                            </View>
                            <View style={{ flex: 1, flexDirection: 'column', borderLeftWidth: 1, borderLeftColor: 'grey', paddingLeft: 3 }}>
                                <Text style={{ borderBottomColor: 'grey', borderBottomWidth: 1 }} category='s1'>Cost:</Text>
                                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                    <Text category='s1'>Total Bill Back Cost: </Text>
                                    <Text category='s1'>${totalBillBack}</Text>
                                </View>
                                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                    <Text category='s1'>Total Customer Cost: </Text>
                                    <Text category='s1'>${totalCustomerCost}</Text>
                                </View>
                                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                    <Text category='s1'>Total Case Cost: </Text>
                                    <Text category='s1'>${totalCost}</Text>
                                </View>
                            </View>
                        </View>
                        <View style={{ flexDirection: 'column', borderTopColor: 'white', borderTopWidth: 1, marginTop: 10 }}>
                            <View style={{ flexDirection: 'row', justifyContent: 'space-between', gap: 5 }}>
                                <View style={{ flexDirection: 'row', flex: 1 }}>
                                    {selectedCase.job_ids.length > 0 &&
                                        <View style={{ flexDirection: 'column', flex: 1 }}>
                                            <Text category='s1'>Jobs</Text>
                                            <ScrollView style={{ flex: 1 }}>
                                                <View style={{ flexDirection: 'column', gap: 5 }}>
                                                    {selectedCase.job_ids.map((item, index) => {
                                                        const j = new Job(item)
                                                        return <RenderJobCard
                                                            style={{ width: '100%' }}
                                                            job={j}
                                                            caseItem={selectedCase}
                                                            key={index}
                                                            disabled={false}
                                                            onPress={() => {
                                                                onOpenJobDetailsPanel(j)
                                                            }} />
                                                    })}
                                                </View>
                                            </ScrollView>
                                        </View>
                                    }
                                </View>
                                <View style={{ flexDirection: 'row', flex: 1 }}>
                                    {selectedCase.holding_ids.length > 0 &&
                                        <View style={{ flexDirection: 'column', flex: 1 }}>
                                            <Text category='s1'>Holdings</Text>
                                            <View style={{ flexDirection: 'column', flex: 1 }}>
                                                <ScrollView style={{ flex: 1, flexDirection: 'column' }}>
                                                    <View style={{ flexDirection: 'column', gap: 5 }}>
                                                        {selectedCase.holding_ids.map((item, index) => {
                                                            const h = new Holding(item)
                                                            return <RenderHoldingCard
                                                                style={{ width: '100%' }}
                                                                holding={h}
                                                                caseItem={selectedCase}
                                                                key={index}
                                                                disabled={false}
                                                                onPress={() => {
                                                                    onOpenHoldingDetailsPanel(h)
                                                                }} />
                                                        })}
                                                    </View>
                                                </ScrollView>
                                            </View>
                                        </View>
                                    }
                                </View>
                            </View>
                            <View style={{ flexDirection: 'row', margin: 5 }}></View>
                            <Divider />
                            <View style={{ flexDirection: 'row', margin: 5 }}></View>
                            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                <Text category='s1'>Actions:</Text>
                                <View style={{ flexDirection: 'row', gap: 5 }}>
                                    <Button
                                        status='success'
                                        size='tiny'
                                        appearance='outline'
                                        onPress={() => {
                                            setShowConfirmSetAllCustomerItemsPaidModal(true)
                                        }}>
                                        Set All Customer Items Paid
                                    </Button>
                                </View>
                            </View>
                            <InvoicedTotalCard
                                //@ts-ignore
                                caseItem={selectedCase as Case || new Case({})}
                            // fn_closeModal={() => setShowCaseDetailsPanel(false)}
                            />
                        </View>
                    </ScrollView>
                </View>
            </Card >
        )
    }

    const onExportCurrentCasesToXero = (type = "bill_all_back") => {
        setLoading(true)
        try {
            const company_id = StorageController?.getCurrentCompany()?._id
            const cases = m_filteredCases
            let xeroData = [] as any[]
            cases.forEach(c => {
                const _xeroData = generateXeroInvoiceData(c, type)
                xeroData = [...xeroData, ..._xeroData]
            })
            setLoading(false)
            return xeroData
        }
        catch (e) {
            console.log(e)
            setLoading(false)
        }

    }


    interface RenderSearchPanelProps {
        onSearch: (start: Date, end: Date) => void;
        validateRange: (range: any) => void;
        dateRangeRef: any;
        dateRange: any;
        handleNextWeek: () => void;
        handlePreviousWeek: () => void;
    }

    const RenderSearchPanel = ({
        onSearch,
        validateRange,
        dateRangeRef,
        dateRange,
        handleNextWeek,
        handlePreviousWeek
    }: RenderSearchPanelProps) => {

        // const [dateRange, setDateRange] = React.useState({});
        // const [startDate, setStartDate] = useState(new Date());
        // const [endDate, setEndDate] = useState(new Date());

        const RenderNextWeekButton = () => (
            <Button
                // style={styles.button}
                appearance="outline"
                status="basic"
                onPress={() => {
                    // start date is last sunday aned date is this saturday
                    handleNextWeek();
                }}
            >
                <Icon name="arrow-ios-forward-outline" width={30} height={30} />
            </Button>
        );

        const RenderLastWeekButton = () => (
            <Button
                // style={styles.button}
                appearance="outline"
                status="basic"
                onPress={() => {
                    // start date is last sunday aned date is this saturday
                    handlePreviousWeek();
                }}
            >
                <Icon name="arrow-ios-back-outline" width={30} height={30} />
            </Button>
        );
        return (
            <Layout style={{ flex: 1, flexDirection: 'column' }}>
                <View style={{ flexDirection: 'row' }}>
                    <CSVLink
                        data={onExportCurrentCasesToXero("bill_all_back")}
                        filename={`${startDate?.toLocaleDateString()}-${endDate?.toLocaleDateString()}_invoice.csv`}
                        target="_blank"
                    >
                        <Button
                            status='primary'
                            size='tiny'
                            appearance='outline'
                            accessoryLeft={() => <Icon name='download-outline' width={24} height={24} fill="white" />}
                        >
                            Export Bill Back Cases To Xero CSV
                        </Button>
                    </CSVLink>
                    <CSVLink
                        data={onExportCurrentCasesToXero("customer_cost")}
                        filename={`${startDate?.toLocaleDateString()}-${endDate?.toLocaleDateString()}_invoice.csv`}
                        target="_blank"
                    >
                        <Button

                            status='primary'
                            size='tiny'
                            appearance='outline'
                            accessoryLeft={() => <Icon name='download-outline' width={24} height={24} fill="white" />}
                        >
                            Export Customer Cost Cases To Xero CSV
                        </Button>
                    </CSVLink>
                </View>

                <Layout style={{ flexDirection: "row" }}>
                    <Layout style={{ flex: 1 }}>
                        <RenderLastWeekButton />
                    </Layout>
                    <Layout style={{ flex: 1 }}>
                        <RenderNextWeekButton />
                    </Layout>
                </Layout>
                <RangeDatepicker
                    ref={dateRangeRef}
                    range={dateRange}
                    min={new Date(1900, 0, 0)}
                    max={new Date(2099, 0, 0)}
                    accessoryRight={CalendarIcon}
                    onSelect={(nextRange: any) => validateRange(nextRange)}
                />
                <Button
                    onPress={() => onSearch(dateRange.startDate, dateRange.endDate)}
                    style={{ width: '100%', marginBottom: 10 }}>
                    Search
                </Button>
                <RenderFilters />
            </Layout>
        )
    }

    const RenderLoading = () => {
        if (Loading) {

            return (
                <View style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0,0,0,0.5)',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 100
                }}>
                    <Spinner size='giant' />
                </View>
            )
        } else {
            return null
        }
    }


    return (
        <Layout style={{ flex: 1, flexDirection: 'row' }}>
            <RenderLoading />
            <RenderConfirmSetAllCustomerItemsPaidModal />
            <JobReportDetailsModal />
            <HoldingDetailsModal />
            <View style={{ flex: 10, flexDirection: 'row' }}>
                <View style={{ flex: 2, flexDirection: 'column', borderRightColor: 'black', borderRightWidth: 1 }}>
                    <RenderSearchPanel
                        dateRange={dateRange}
                        dateRangeRef={dateRangeRef}
                        validateRange={validateRange}
                        onSearch={searchCasesByDateRange}
                        handleNextWeek={handleNextWeek}
                        handlePreviousWeek={handlePreviousWeek}
                    />
                </View>
                <View style={{ flex: 10, flexDirection: 'column' }}>
                    <RenderCaseList
                        cases={m_filteredCases}
                        selectedCase={selectedCase}
                        onSetSelectedCase={onSetSelectedCase}
                        showCaseDetailsPanel={showCaseDetailsPanel}
                        setShowCaseDetailsPanel={setShowCaseDetailsPanel}
                    />
                </View>
            </View>
            {selectedCase && showCaseDetailsPanel &&
                <View
                    style={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        width: '50%',
                        height: '100%',
                        zIndex: 100,
                        // flex: 8,
                        flexDirection: 'column'
                    }}>
                    <RenderCaseDetailsPanel />
                </View>
            }
            {selectedInvoice && showInvoiceDetailsPanel &&
                <View style={{ flex: 8, flexDirection: 'column' }}>
                    <RenderInvoiceDetailsPanel />
                </View>
            }
        </Layout>
    )
}




////////////////////////////////////////////////
////////////////////////////////////////////////
///////////////////////////////////////////////////
//////////////// LISTS            ////////////////
/////////////////////////////////////////////////
////////////////////////////////////////////////
////////////////////////////////////////////////

const STATUS_CARD_ACCENT: { [key: string]: string } = {
    "unassigned": "red",
    "assigned": 'yellow',
    "ongoing": "yellow",
    "pending": "blue",
    "complete": "green",
    "cancelled": "purple",
    "held": "yellow",
    "released": "green",
}
const RenderCardStatusAccent = ({ status }: { status: string }) => {
    const style = {
        height: 5,
        borderTopLeftRadius: 5,
        borderTopRightRadius: 5,
        zIndex: 10,
        backgroundColor: STATUS_CARD_ACCENT[status]
    }
    return (
        <View style={style} />
    )
};

interface JobCardProps {
    job: Job
    caseItem: Case
    style: Object
    onPress: Function
    disabled: boolean
}

const RenderJobCard = ({ job, caseItem, style, onPress, disabled = true }: JobCardProps) => {
    //@ts-ignore
    if (!job instanceof Job) return null
    let totalCost = 0
    let totalBillBack = 0
    let totalCustomerCost = 0

    let customerPaidAmount = 0
    let customerOutstandingAmount = 0
    try {
        totalCost = Number(Number(job.getTotalLineItemCostCostIncludingClientRate()).toFixed(2))
        totalBillBack = Number(Number(job.getTotalBillbackIncludingClientRate()).toFixed(2))
        totalCustomerCost = Number(Number(job.getTotalCustomerCostIncludingClientRate()).toFixed(2))
        customerPaidAmount = Number(Number(job.getTotalCustomerPaidAmountIncludingClientRate()).toFixed(2))
        customerOutstandingAmount = Number(Number(job.getTotalAmountOutstandingIncludingClientRate()).toFixed(2))

    } catch (e) {
        console.log(e)
        return null
    }

    const RenderCustomerTotals = () => {
        if (totalCustomerCost == 0) return null
        return (
            <View style={{ flex: 1, flexDirection: 'column', borderBottomWidth: 1, borderColor: 'grey' }}>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Text category='s1'>Customer Cost: </Text>
                    <Text category='s1'>${totalCustomerCost}</Text>
                </View>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Text category='s1'>Customer Paid: </Text>
                    <Text category='s1'>${customerPaidAmount}</Text>
                </View>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Text category='s1'>Outstanding: </Text>
                    <Text category='s1'
                        style={{ backgroundColor: customerOutstandingAmount > 0 ? 'red' : 'green' }}>
                        ${customerOutstandingAmount}
                    </Text>
                </View>
            </View>
        )
    }

    return (
        <Card
            style={style}
            accent={<RenderCardStatusAccent status={job.status} />}
            disabled={disabled}
            onPress={() => { onPress() }}
            header={() =>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Text category='s1'>{Utils.formatIsoDate(job.createdAt as string)}</Text>

                    <Text category='s1'>{job.status}</Text>
                </View>
            }
        >
            <View style={{ flexDirection: 'row', justifyContent: 'space-between', margin: -15, gap: 10 }}>
                {/* <View style={{ flexDirection: 'column' }}>
                </View> */}
                <View style={{ flexDirection: 'column' }}>
                    <View style={{ flexDirection: 'row' }}>
                        <Text category='s1'>{job.friendly_id}</Text>
                    </View>
                    <View style={{ flexDirection: 'row' }}>
                        <Text category='s1'>Services: </Text>
                        <Text category='p1'>{job.getServicesNames()}</Text>
                    </View>
                </View>
                <View style={{ flex: 1, flexDirection: 'column', borderLeftWidth: 1, borderLeftColor: 'grey', paddingLeft: 3 }}>
                    <Text style={{ borderBottomColor: 'grey', borderBottomWidth: 1 }} category='s1'>Invoice Totals:</Text>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Text category='s1'>Bill Back: </Text>
                        <Text category='s1'>${totalBillBack}</Text>
                    </View>
                    <RenderCustomerTotals />
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Text category='s1'>Total Cost: </Text>
                        <Text category='s1'>${totalCost}</Text>
                    </View>
                </View>
            </View>
        </Card>
    )
}


interface HoldingCardProps {
    holding: Holding
    caseItem: Case
    style: Object
    onPress: Function
    disabled: boolean
}
const RenderHoldingCard = ({ holding, caseItem, style, onPress, disabled = true }: HoldingCardProps) => {
    let totalCost = 0
    let totalBillBack = 0
    let totalCustomerCost = 0

    let customerPaidAmount = 0
    let customerOutstandingAmount = 0

    let heldTime = ""
    let releasedTime = ""
    try {
        totalCost = Number(Number(holding.getTotalCost()).toFixed(2))
        totalBillBack = Number(Number(holding.getTotalBillBackCost()).toFixed(2))
        totalCustomerCost = Number(Number(holding.getTotalCustomerCost()).toFixed(2))
        customerPaidAmount = Number(Number(holding.getTotalCustomerPaidAmount()).toFixed(2))
        customerOutstandingAmount = Number(Number(holding.getTotalAmountOutstanding()).toFixed(2))

        heldTime = Utils.formatTimestamp(holding.holding_time) as string
        releasedTime = Utils.formatTimestamp(holding.released_time) as string

    } catch (err) {
        console.log(err)
        return null
    }

    const RenderCustomerTotals = () => {
        if (totalCustomerCost == 0) return null
        return (
            <View style={{ flex: 1, flexDirection: 'column', borderBottomWidth: 1, borderColor: 'grey' }}>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Text category='s1'>Customer Cost: </Text>
                    <Text category='s1'>${totalCustomerCost}</Text>
                </View>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Text category='s1'>Customer Paid: </Text>
                    <Text category='s1'>${customerPaidAmount}</Text>
                </View>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Text category='s1'>Outstanding: </Text>
                    <Text category='s1'
                        style={{ backgroundColor: customerOutstandingAmount > 0 ? 'red' : 'green' }}>
                        ${customerOutstandingAmount}
                    </Text>
                </View>
            </View>
        )
    }

    return (
        <Card
            style={style}
            accent={<RenderCardStatusAccent status={holding.status} />}
            disabled={disabled}
            onPress={() => {
                onPress()
            }}
            header={() =>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Text category='s1'>{Utils.formatIsoDate(new Date(holding.createdAt))}</Text>
                    <Text category='s1'>{holding.status}</Text>
                </View>
            }
        >
            <View style={{ flexDirection: 'row', justifyContent: 'space-between', margin: -15, gap: 10 }}>
                <View style={{ flexDirection: 'column' }}>
                    <View style={{ flexDirection: 'row' }}>
                        <Text category='s1'>Type: </Text>
                        <Text category='p1'>{holding.holding_type}</Text>
                    </View>
                    <View style={{ flexDirection: 'row' }}>
                        <Text category='s1'>Reason: </Text>
                        <Text category='p1'>{holding.reason}</Text>
                    </View>
                    <View style={{ flexDirection: 'row' }}>
                        <Text category='s1'>Held: </Text>
                        <Text category='p1'>{heldTime}</Text>
                    </View>
                    <View style={{ flexDirection: 'row' }}>
                        <Text category='s1'>Released: </Text>
                        <Text category='p1'>{releasedTime}</Text>
                    </View>
                </View>
                <View style={{ flex: 1, flexDirection: 'column', borderLeftWidth: 1, borderLeftColor: 'grey', paddingLeft: 3 }}>
                    <Text style={{ borderBottomColor: 'grey', borderBottomWidth: 1 }} category='s1'>Invoice Totals:</Text>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Text category='s1'>Bill Back: </Text>
                        <Text category='s1'>${totalBillBack}</Text>
                    </View>
                    <RenderCustomerTotals />
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Text category='s1'>Total Cost: </Text>
                        <Text category='s1'>${totalCost}</Text>
                    </View>
                </View>
            </View>
        </Card>
    )
}

interface RenderClientDetailsCardProps {
    style?: Object
    client: Client
}

const RenderClientDetailsCard = ({ style, client }: RenderClientDetailsCardProps) => {
    return (
        <Card
            disabled={true}
            style={[style]}
            status='primary'
            header={() => <Text category='s1'>Client Details</Text>}
        >
            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                <Text category='s1'>{client?.name}</Text>
            </View>
            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                <Text category='s1'>Email: </Text>
                <Text>{client?.contact_info?.email}</Text>
            </View>
            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                <Text category='s1'>Phone: </Text>
                <Text>{client?.contact_info?.phone}</Text>
            </View>
            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                <Text category='s1'>Address</Text>
                <Text>{client?.contact_info?.address?.formatted_address || ""}</Text>
            </View>
        </Card>
    )
}


interface CasesListProps {
    cases: Case[]
    selectedCase: Case | null
    onSetSelectedCase: Function
    showCaseDetailsPanel: boolean
    setShowCaseDetailsPanel: Function
}


const RenderCaseList = ({
    cases,
    selectedCase,
    onSetSelectedCase,
    showCaseDetailsPanel,
    setShowCaseDetailsPanel
}: CasesListProps) => {

    const CaseListItem = ({ caseItem }: { caseItem: Case }) => {
        const totalCost = Number(caseItem.getTotalCost()).toFixed(2)
        const totalBillBack = Number(caseItem.getTotalBillBack()).toFixed(2)
        const totalCustomerCost = Number(caseItem.getTotalCustomerCost()).toFixed(2)
        const jobs = caseItem.job_ids.map(j => new Job(j))
        const holdings = caseItem.holding_ids.map(h => new Holding(h))
        const invoices = caseItem.invoice_ids.map(i => new Invoice(i))

        const jobHoldingsList = mergeAndSortCaseJobsAndHoldings(jobs, holdings);

        return (
            <Card
                style={{
                    width: '100%',
                    marginBottom: 10,
                    backgroundColor: selectedCase?._id === caseItem._id ? 'rgba(0,100,100,0.2)' : 'transparent',
                }}
                accent={<RenderCardStatusAccent status={caseItem.status} />}
                // status={STATUS_CARD_STATE[caseItem.status]}
                onPress={() => {
                    onSetSelectedCase(caseItem)
                    setShowCaseDetailsPanel(true)
                }}
                header={() =>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <View style={{ flexDirection: 'row', gap: 10 }}>
                            <Text category='s1'>{caseItem.name}</Text>
                            <Text category='s1'>{Utils.formatIsoDate(caseItem.createdAt)}</Text>
                        </View>
                        <Text category='s1'>{caseItem.status}</Text>
                    </View>
                }
            >
                <View style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    margin: -15,
                    backgroundColor: selectedCase?._id === caseItem._id ? 'rgba(0,100,100,0.2)' : 'transparent',
                }}>
                    <View style={{ flex: 4, flexDirection: 'row' }}>
                        <ScrollView horizontal={true}>
                            <View style={{ flexDirection: 'row', gap: 5 }}>
                                {jobHoldingsList.map((item: any, index: number) => {
                                    if (item instanceof Job) {
                                        return (
                                            <RenderJobCard
                                                key={index}
                                                job={item}
                                                caseItem={caseItem}
                                                onPress={() => {
                                                    onSetSelectedCase(caseItem);
                                                    setShowCaseDetailsPanel(true);
                                                }}
                                                style={{}}
                                                disabled={false}
                                            />
                                        )
                                    } else if (item instanceof Holding) {
                                        return (
                                            <RenderHoldingCard
                                                key={index}
                                                holding={item}
                                                caseItem={caseItem}
                                                onPress={() => {
                                                    onSetSelectedCase(caseItem);
                                                    setShowCaseDetailsPanel(true);
                                                }}
                                                style={{}}
                                                disabled={false} />
                                        )
                                    }
                                })}
                            </View>
                        </ScrollView>
                    </View>
                    <View style={{ flex: 1, flexDirection: 'column', borderLeftWidth: 1, borderLeftColor: 'grey', paddingLeft: 3 }}>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', borderBottomColor: 'grey', borderBottomWidth: 1 }}>
                            <Text style={{}} category='s1'>Invoice Total:</Text>
                            {/* <Text category='s1'>${totalCost}</Text> */}
                        </View>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                            <Text category='s1'>Bill Back: </Text>
                            <Text category='s1'>${totalBillBack}</Text>
                        </View>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                            <Text category='s1'>Customer Cost: </Text>
                            <Text category='s1'>${totalCustomerCost}</Text>
                        </View>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                            <Text category='s1'>Total: </Text>
                            <Text category='s1'>${totalCost}</Text>
                        </View>
                    </View>
                </View>
            </Card>
        )
    }

    const mergeAndSortCaseJobsAndHoldings = (caseJobs: Job[], caseHoldings: Holding[]) => {
        // Assuming both caseJobs and caseHoldings have a 'createdAt' field
        const combinedList = [...caseJobs, ...caseHoldings] as any;
        combinedList.sort((a: any, b: any) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
        return combinedList;
    };











    return (
        <List
            style={{ flex: 1 }}
            data={cases}
            renderItem={({ item }: { item: Case }) => <CaseListItem caseItem={item} />}
        />
    )
}

const styles = StyleSheet.create({
    backdrop: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    modal: {
        zIndex: 100,
        width: '80%',
        height: '90%'
    }
});

const CalendarIcon = (props: any) => (
    <Icon {...props} name='calendar' />
);

const SearchIcon = (props: any) => (
    <Icon {...props} name='search' />
);

const FilterIcon = (props: any) => (
    <Icon {...props} name='funnel' />
);

const RefreshIcon = (props: any) => (
    <Icon {...props} name='refresh' />
);

const AddIcon = (props: any) => (
    <Icon {...props} name='plus' />
);

const EditIcon = (props: any) => (
    <Icon {...props} name='edit' />
);

const DeleteIcon = (props: any) => (
    <Icon {...props} name='trash' />
);

const ExportIcon = (props: any) => (
    <Icon {...props} name='download' />
);

const ImportIcon = (props: any) => (
    <Icon {...props} name='upload' />
);

const SettingsIcon = (props: any) => (
    <Icon {...props} name='settings' />
);

