import React, { useState } from 'react';
import {
    View,
    ScrollView
    //@ts-ignore
} from "react-native";
import {
    Button,
    Icon,
    Input,
    Card,
    Text,
    List,
    ListItem,
    Divider,
    Layout
} from "@ui-kitten/components";
import { AddressDetailsCard } from '../job/details/addressDetailsCard.component';
import { RouteLocation } from "../../models/Route.model";
import { LineItem } from "../../models/Job.model";
import { InventoryItem } from '../../models/Company.model';
import { JobAddress, JobLocation } from "../../models/Job.model";


interface RouteLocationDetailsCardProps {
    location: RouteLocation;
    onChangeRouteLocation: Function;
    comapnyInventoryItems: InventoryItem[];
    onCloseDetailsPanel: Function;
    openJobReportModal: Function;
}


export const RouteLocationDetailsCard = ({ location, onChangeRouteLocation, comapnyInventoryItems, onCloseDetailsPanel, openJobReportModal }:
    RouteLocationDetailsCardProps
) => {

    const [locationState, setLocationState] = useState<RouteLocation>(location);
    const [showInventoryItems, setShowInventoryItems] = useState(false);
    const [showCompanyInventoryItems, setShowCompanyInventoryItems] = useState(false);
    const [selectedInventoryItem, setSelectedInventoryItem] = useState<InventoryItem | null>(null);

    const onSetRouteLocation = (newLocation: RouteLocation) => {
        setLocationState(newLocation);
        onChangeRouteLocation(newLocation);
    }

    const onSetAddress = (address: JobAddress) => {
        onSetRouteLocation({ ...locationState, address });
    }

    const onSetLocation = (newLocation: JobLocation) => {
        onSetRouteLocation({ ...locationState, location: newLocation });
    }

    const onSetInventoryItems = (lineItems: LineItem[]) => {
        onSetRouteLocation({ ...locationState, inventory_items: lineItems });
    }

    const inventoryItemToLineItem = (item: InventoryItem) => {
        return new LineItem({
            name: item.name,
            description: item.description,
            quantity: 1,
            cost: item.cost
        })
    }

    const itemDescriptionBuilder = (item: LineItem) => {
        return `${item.description || item.name} X ${item.quantity}`
    }

    return (
        //@ts-ignore
        <Layout style={{ flex: 1, padding: 3, borderWidth: 1 }} level="1">

            <View style={{ flexDirection: 'row', height: 3, backgroundColor: '#3366ff' }}></View>
            <View style={{ flexDirection: 'row', justifyContent: 'space-between', zIndex: 1001 }}>
                <Text>{location.index + 1} : {location.name}</Text>
                <Button
                    size='tiny'
                    style={{ zIndex: 1002 }}
                    // appearance='ghost'
                    status='warning'
                    onPress={() => openJobReportModal(location.job_id)}>
                    <>
                        <Icon name='file-text-outline' width={20} height={20} fill='white' />
                        <Text>Open Job Report</Text>
                    </>
                </Button>
                <Button
                    size='tiny'
                    style={{ zIndex: 1002 }}
                    // appearance='ghost'
                    status='danger'
                    onPress={() => onCloseDetailsPanel()}>
                    <Icon name='close-outline' width={20} height={20} fill='white' />
                </Button>

            </View>
            <Input
                label='Name'
                value={location.name}
                onChangeText={(name: string) => onSetRouteLocation({ ...location, name })}
            />
            <Input
                label='Note'
                multiline={true}
                textStyle={{ minHeight: 64 }}
                value={location.note}
                onChangeText={(note: string) => onSetRouteLocation({ ...location, note })}
            />
            <Text>{location.job_id}</Text>
            <Text>{location.address?.formatted_address}</Text>
            <Text>{location.location_type}</Text>
            <AddressDetailsCard
                address={location.address}
                addressDisplayValue={location.address?.formatted_address}
                onSetAddress={onSetAddress}
                onSetLocation={onSetLocation}
                onToggleCollapse={() => { }}
            />
            <Button onPress={() => setShowCompanyInventoryItems(!showCompanyInventoryItems)}>Company Inventory Items</Button>
            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                <Card
                    disabled={true}
                    style={{ flex: 1 }}
                    header={() => <Text>Inventory Items</Text>}
                    status='success'
                >
                    <List
                        data={location.inventory_items}
                        ItemSeparatorComponent={Divider}
                        renderItem={({ item, index }: { item: LineItem, index: number }) => (
                            <ListItem
                                title={item.name}
                                description={itemDescriptionBuilder(item)}
                                accessoryRight={() => (
                                    <Button
                                        appearance='ghost'
                                        onPress={() => {
                                            const newItems = location.inventory_items.filter((_, i) => i !== index);
                                            onSetInventoryItems(newItems);
                                        }}
                                        accessoryLeft={() => (
                                            <Input
                                                style={{ width: 50 }}
                                                value={item.quantity?.toString() || '1'}
                                                onChangeText={(quantity: string) => {
                                                    const newItems = location.inventory_items.map((i, iIndex) => {
                                                        if (iIndex === index) {
                                                            return { ...i, quantity: parseInt(quantity) }
                                                        }
                                                        return i;
                                                    }
                                                    );
                                                    onSetInventoryItems(newItems);
                                                }
                                                }
                                            />
                                        )}
                                    >
                                        <Icon name='trash-2-outline' width={20} height={20} fill='red' />
                                    </Button>
                                )}
                            />
                        )}
                    />
                </Card>

                {showCompanyInventoryItems ?
                    <Card
                        disabled={true}
                        style={{ flex: 1 }}
                        header={() => <Text>Company Inventory Items</Text>}
                        status='warning'
                    >
                        <List
                            ItemSeparatorComponent={Divider}
                            data={comapnyInventoryItems}
                            renderItem={({ item }: { item: InventoryItem }) => (
                                <ListItem
                                    title={item.name}
                                    description={item.description}
                                    onPress={() => {
                                        const lineItem = inventoryItemToLineItem(item);
                                        onSetInventoryItems([...location.inventory_items, lineItem]);
                                    }}
                                />
                            )}
                        />
                    </Card>
                    : null
                }
            </View>
        </Layout>
    )
}