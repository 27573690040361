import React, { useCallback, useEffect, useState, useRef } from 'react';
//@ts-ignore
import { View, StyleSheet } from 'react-native';
import { Button, Layout, Toggle, Input, Text, Card, Icon, Modal } from '@ui-kitten/components';
import * as StorageController from '../../functions/storageController';
import { CompanySecret } from '../../models/CompanySecret.model';
import * as CompanySecretController from '../../functions/companySecret.controller';
import { Company, CompanySavedAddress, CompanySettings, JobOptions, ScheduleSettings, SMSSettings, EmailSettings, Surcharge, ServiceArea, CompanyWebhookSettings, CompanyTheme, IServiceAreaCoord, CompanyAlerts } from '../../models/Company.model';
import _ from 'lodash';


interface PaymentSettingsProps {
    company: Company,
    onSetCompany: (company: Company) => void,
    onSetCompanySecret: (companySecret: CompanySecret) => void,
}


export const PaymentSettings = ({
    company,
    onSetCompany,
    onSetCompanySecret,
}: PaymentSettingsProps) => {

    const [companySecret, setCompanySecret] = useState(null as CompanySecret | null)
    const [showCreateSurchargeModal, setShowCreateSurchargeModal] = useState(false)
    const companySecretRef = useRef(companySecret)

    useEffect(() => {
        setCompanySecret(null)
        load()
    }, [company])

    const load = async () => {
        const companySecret = await CompanySecretController.findByCompany(company._id)
        console.log("🚀============== ~ file: paymentSettings.component.tsx:36 ~ load ~ companySecret🚀==============", companySecret)
        setCompanySecret(companySecret)
    }

    const _onSetCompanySecret = async (companySecret: CompanySecret) => {
        companySecretRef.current = companySecret
        setCompanySecret(companySecret)
        onSetCompanySecret(companySecret)
    }


    return (
        <View style={{ flex: 1, flexDirection: 'row' }}>
            <CreateSurchargeModal
                visible={showCreateSurchargeModal}
                setVisible={setShowCreateSurchargeModal}
                onSave={(newSurcharge: Surcharge) => {
                    let newSurcharges = companySecret?.square.surcharges || []
                    newSurcharges.push(newSurcharge as Surcharge)
                    _onSetCompanySecret({
                        ...companySecret, square: {
                            ...(companySecret?.square || {}),
                            surcharges: newSurcharges
                        }
                    } as unknown as CompanySecret)
                    setShowCreateSurchargeModal(false)
                }}
            />
            <Card
                disabled
                header={() => <Text category='h6' > Square Payment Options </Text>}
                status="basic"
            >
                <View style={{ flexDirection: 'column' }}>
                    {/* Square Enabled */}
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Text category='s1' >Square Enabled </Text>
                        <Toggle
                            checked={company.settings?.payment_settings.square?.enabled || false}
                            onChange={(nextChecked: boolean) =>
                                onSetCompany({
                                    ...company, settings: {
                                        ...company.settings, payment_settings: {
                                            ...company.settings?.payment_settings, square: {
                                                ...(company.settings?.payment_settings?.square || {}),
                                                enabled: nextChecked
                                            }
                                        }
                                    }
                                } as Company)
                            }
                        >
                        </Toggle>
                    </View>
                    {company.settings.payment_settings.square?.enabled ?
                        <>
                            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                <Text category='s1' >Application ID </Text>
                                <Input
                                    label="Square Application ID"
                                    placeholder="Square Application ID"
                                    defaultValue={companySecret?.square.application_id}
                                    // defaultValue={company.settings?.payment_settings.square?.application_id}
                                    onChangeText={(nextValue: string) =>
                                        _onSetCompanySecret({
                                            ...companySecret, square: {
                                                ...(companySecret?.square || {}),
                                                application_id: nextValue
                                            }
                                        } as CompanySecret)
                                    }
                                />
                            </View>
                            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                <Text category='s1' >Location ID </Text>
                                <Input
                                    label="Square Location ID"
                                    placeholder="Square Location ID"
                                    defaultValue={companySecret?.square?.location_id}
                                    onChangeText={(nextValue: string) =>
                                        _onSetCompanySecret({
                                            ...companySecret, square: {
                                                ...(companySecret?.square || {}),
                                                location_id: nextValue
                                            }
                                        } as CompanySecret)
                                    }
                                />
                            </View>
                            {/* Access Token */}
                            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                <Text category='s1' >Access Token </Text>
                                <Input
                                    label="Square Access Token"
                                    placeholder="Square Access Token"
                                    defaultValue={companySecret?.square?.access_token}
                                    onChangeText={(nextValue: string) =>
                                        _onSetCompanySecret({
                                            ...companySecret, square: {
                                                ...(companySecret?.square || {}),
                                                access_token: nextValue
                                            }
                                        } as CompanySecret)
                                    }
                                />
                            </View>
                            {/* Add list of surchages */}
                            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                <Text category='s1' >Surcharges </Text>
                                <Button onPress={() => setShowCreateSurchargeModal(true)} status="success" > Add Surcharge </Button>
                            </View>
                            <View style={{ flexDirection: 'column' }}>
                                {
                                    companySecret?.square?.surcharges?.map((surcharge: Surcharge, index) => (
                                        <Card key={index} disabled={true} >
                                            <Layout style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }} >
                                                <Text category='label' > {surcharge?.name} </Text>
                                                <Text category='label' >{surcharge.type == 'flat' ? '$' : ''}{surcharge?.amount}{surcharge.type == 'flat' ? '' : '%'} </Text>
                                                {/* Delete surcharge */}
                                                <Button onPress={() => {
                                                    let newSurcharges = companySecret?.square.surcharges || []
                                                    newSurcharges.splice(index, 1)
                                                    _onSetCompanySecret({
                                                        ...companySecret, square: {
                                                            ...(companySecret?.square || {}),
                                                            surcharges: newSurcharges
                                                        }
                                                    } as unknown as CompanySecret)
                                                }} size='tiny' status="danger" >
                                                    <Icon name='trash-2-outline' width={15} height={15} />
                                                </Button>
                                            </Layout>
                                        </Card>
                                    ))
                                }
                            </View>
                        </>
                        : <></>}
                </View>
            </Card>
            <View style={{ flex: 2 }}></View>
        </View>
    )
}

const CreateSurchargeModal = ({ visible, setVisible, onSave }: any) => {
    const [surcharge, setSurcharge] = useState(new Surcharge({ name: '', amount: '', type: 'flat' }))

    const onSetSurcharge = (surcharge: Surcharge) => {
        setSurcharge(surcharge)
    }


    const save = async (surcharge: Surcharge) => {
        // check if the surcharge has a name and amount
        if (surcharge.name && surcharge.amount) {
            // Check if amount is a number
            if (isNaN(surcharge.amount)) {
                alert('Surcharge amount must be a number')
                return
            }
            onSave(surcharge)
        }
        else {
            alert('Surcharge must have a name and amount')
        }
    }



    return (
        <Modal
            visible={visible}
            backdropStyle={styles.backdrop}
            onBackdropPress={() => setVisible(false)}>
            <Card disabled={true}>
                <Text>Surcharge Name</Text>
                <Input
                    placeholder='Surcharge Name'
                    value={surcharge.name}
                    onChangeText={(nextValue: any) => onSetSurcharge({ ...surcharge, name: nextValue })}
                />
                <Text>Surcharge Amount</Text>
                <Input
                    placeholder='Surcharge Amount'
                    value={surcharge.amount}
                    onChangeText={(nextValue: any) => onSetSurcharge({ ...surcharge, amount: nextValue })}
                />
                <Text>Surcharge Type</Text>
                {/* Toggle between flat or percentage */}
                <Toggle
                    checked={surcharge.type == 'flat'}
                    onChange={(nextChecked: boolean) => {
                        let type = nextChecked ? 'flat' : 'percentage'
                        onSetSurcharge({ ...surcharge, type: type })
                    }}
                >
                    {surcharge.type == 'flat' ? 'Flat' : 'Percentage'}
                </Toggle>
                <Button onPress={() => save(surcharge)} status="success" > Save </Button>
                <Button onPress={() => setVisible(false)} status="basic" > Cancel </Button>
            </Card>
        </Modal>
    )
}

const styles = StyleSheet.create({
    backdrop: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    newClientModal: {
        width: "90%",
        height: "90%"
    }
});