import React, { useState, useRef, useCallback, useEffect, useReducer } from 'react';
import {
    Image,
    AccessibilityRole,
    ImageProps,
    ImageStyle,
    StyleSheet,
    TouchableOpacity,
    useWindowDimensions,
    View,
    ScrollView,
    FlatList,
    Alert,
    Clipboard,
    Text as RNText
    //@ts-ignore
} from "react-native";
import {
    ApplicationProvider,
    Button,
    Icon,
    IconRegistry,
    Divider,
    Input,
    Select,
    SelectItem,
    useTheme,
    Layout,
    Text,
    List,
    ListItem,
    OverflowMenu,
    MenuItem,
    Card,
    Toggle,
    Radio,
    RadioGroup,
    Modal,
} from "@ui-kitten/components";
import { SafeAreaView } from 'react-native-safe-area-context';
import { EvaIconsPack } from "@ui-kitten/eva-icons";
import { mapping, light, dark } from "@eva-design/eva";
import * as StorageController from '../../../functions/storageController';
import * as GoogleController from '../../../functions/google.controller';
import * as ClientController from '../../../functions/client.controller';
import * as VendorProfileController from '../../../functions/vendorProfile.controller';
import * as UpdateController from '../../../functions/update.controller';
import * as CompanyController from '../../../functions/company.controller';
import ErrorBoundary from '../../ErrorBoundary.component';
import DistanceQuoteTool from '../jobDistanceQuoteTool.component';

import { CustomerDetailsCard } from '../details/customerDetailsCard.component';
import { VehicleDetailsCard } from '../details/vehicleDetailsCard.component';
import { TowingDetailsCard } from './towingDetailsCard.component';
// import { ClientDetailsCard } from './clientDetailsCard.component';
import { ClientDetailsCard } from '../details/ClientDetailsCard.component';
import { VendorDetailsCard } from './vendorDetailsCard.component';
import { AddressDetailsCard } from '../details/addressDetailsCard.component';
import { OptionsDetailsCard } from '../details/optionsDetailsCard.component';
import { OtherDetailsCard } from '../details/otherDetailsCard.component';
import { InventoryDetailsCard } from '../details/inventory/inventoryDetailsCard.component';
import { ReportDetailsCard } from '../details/reportDetailsCard.component';
import { LocationDetailsCard } from '../details/locationDetailsCard.component';
import { TowingLocationDetailsCard } from './towingLocationDetailsCard.component';
import { InvoicedTotalCard } from '../details/jobInvoicedTotalDetailsCard.component';

import { ServicesContainer } from '../services/servicesContainer.component';

import { Job, LineItem, JobTowingDetails, JobClientRate, JobAddress, Service, JOB_STATUS, JobVehicleDetails, JobCustomerDetails, JobEquipmentDetails, JobLocation, JobReport } from '../../../models/Job.model';
import { Client } from '../../../models/Client.model';
import { VendorProfile } from '../../../models/VendorProfile.model';

interface ICreateJobProps {
    case_id?: string | null,
    fn_createJob: any,
    vendorProfile?: VendorProfile | null,
    clientProfile?: Client | null,
    jobSource: string, // "vendor" or "client"
    selectedCompany: any,
    selectedMembership: any,
    scheduledStartTime?: number | null,
    contract_id?: string | null,
}


export default function CreateJob({
    case_id,
    fn_createJob,
    vendorProfile,
    clientProfile,
    jobSource, // "vendor" or "client"
    selectedCompany,
    selectedMembership,
    scheduledStartTime,
    contract_id,
}: ICreateJobProps) {

    //@ts-ignore
    const theme: any = useTheme();
    const [JobState, setJob] = useState(new Job({}))
    const [x, forceUpdate] = useReducer(x => x + 1, 0);
    const [address, setAddress] = useState(new JobAddress({}))

    // tools
    const [parsedData, setParsedData] = useState([] as any);
    const [showFieldMapping, setShowFieldMapping] = useState(false);
    const [showDistanceQuoteTool, setShowDistanceQuoteTool] = useState(false);

    // make these refs
    const companyHasRoadsideOptionsRef = React.useRef(true)
    const companyHasTowingOptionsRef = React.useRef(false)
    const companyHasTransportOptionsRef = React.useRef(false)

    const dispatchCommentsValueRef = useRef('')


    // Client or vendor selction
    const selectedClientRef = React.useRef(null as Client | null)
    const vendorClientProfileRef = React.useRef(null as Client | null)

    //Services
    const [selectedServices, setSelectedServices] = useState<Service[]>([])

    const { width, height } = useWindowDimensions()
    const windowCollapseWidth = 1200;




    const [loading, setLoading] = useState(true)
    const [createButtonDisabled, setCreateButtonDisabled] = useState(false)
    const submittingRef = useRef(false)

    //Unused now, but still good for a ref when using in future
    const formRefsArr = [
        useRef(), // name
        useRef(), // phone
        useRef(), // email
        useRef(), // clientRefId
        useRef(), // rego
        useRef(), // make
        useRef(), // model
        useRef(), // year
        useRef(), // colour
        useRef(), // searchAddress
        useRef(), // notes
        useRef(),
        useRef(),
        useRef(),
        useRef(),
        useRef(),
        useRef(),
        useRef(),
    ]

    useEffect(() => {
        load()
    }, [])

    const jobStateRef = useRef(JobState)


    const load = async () => {
        setLoading(true)
        // setup job object
        let job = new Job({
            start_time: new Date().getTime(),
            details: {
                client_rate: new JobClientRate({ name: "-- None Selected --", cost: 0, billed_to: "bill_all_back" }),
                address: new JobAddress({ street_number: "", street_name: "", suburb: "", city: "", post_code: "", state: "", country: "", formatted_address: "" }),
                report: { items: [] }
            },
            vendor_eta: 60,
            dispatchable: true,
        })

        if (scheduledStartTime) {
            job.is_scheduled = true
            job.start_time = scheduledStartTime
        }
        onSetJob(job, "load")

        if (jobSource == "vendor") {
            loadVendorCompanyOptions()
        }
        if (jobSource == "local_client") {
            loadVendorCompanyOptions()
        }
        if (jobSource == "client") {
            loadVendorCompanyOptions()
        }
        setTimeout(() => {
            setLoading(false)
        }, 20)
    }

    const onSetJob = (_job: any | Job, caller?: string) => {
        let j = new Job(_job)
        // Setting some refs here. otherwise causes large reloads on each key input.
        jobStateRef.current = j
        console.log("🚀============== ~ file: createJobContainer.component.js:184 ~ onSetJob ~ j🚀==============", j, caller)
        j.details.comments = dispatchCommentsValueRef.current
        // // // console.log("🚀 ~ file: createJobContainer.component.js:205 ~ onSetJob ~ value:", j)
        // setting state, and forcing a rerender... the mobile version resizes and loses all the data
        setJob(j)
    }


    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    // COMPANY OPTIONS
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////


    const enum_JOB_TYPE_OPTION = {
        ROADSIDE: 0,
        TOWING: 1,
        TRANSPORT: 2,
    }

    const [selectedJobType, setSelectedJobType] = React.useState(enum_JOB_TYPE_OPTION.ROADSIDE);



    const loadVendorCompanyOptions = async () => {
        let companyJobOptions = {
            showRoadsideJobOptions: true,
            showTowingJobOptions: false,
            showTransportJobOptions: false,
        }
        // if linked id, get linked company
        if (jobSource == "vendor" || jobSource == "local_client") {
            let selectedCompany = StorageController.getCurrentCompany()
            if (selectedCompany.settings?.job_options) {
                companyJobOptions = { ...companyJobOptions, ...selectedCompany.settings.job_options }
            }
        }
        if (jobSource == "client" && vendorProfile?.linkedVendorAccountId) {
            const linkedCompany = await CompanyController.getCompanyById(vendorProfile.linkedVendorAccountId)
            if (linkedCompany) {
                if (linkedCompany.settings?.job_options) {
                    companyJobOptions = { ...companyJobOptions, ...linkedCompany.settings.job_options }
                }
            }
        }
        // if no linked id, get from vendor profile?
        if (jobSource == "client" && !vendorProfile?.linkedVendorAccountId) {
            // get options from vendor profile
            if (vendorProfile?.settings?.job_options) {
                companyJobOptions = { ...companyJobOptions, ...vendorProfile.settings.job_options }
            }
        }


        companyHasRoadsideOptionsRef.current = companyJobOptions.showRoadsideJobOptions
        companyHasTowingOptionsRef.current = companyJobOptions.showTowingJobOptions
        companyHasTransportOptionsRef.current = companyJobOptions.showTransportJobOptions
        if (companyHasRoadsideOptionsRef.current) {
            setSelectedJobType(enum_JOB_TYPE_OPTION.ROADSIDE)
            return
        }
        if (companyHasTowingOptionsRef.current) {
            onSetSelectedJobType(enum_JOB_TYPE_OPTION.TOWING)
            // setSelectedJobType(enum_JOB_TYPE_OPTION.TOWING)

            return
        }
        if (companyHasTransportOptionsRef.current) {
            setSelectedJobType(enum_JOB_TYPE_OPTION.TRANSPORT)
            return
        }

        // otherwise default to roadside
        onSetSelectedJobType(enum_JOB_TYPE_OPTION.ROADSIDE)
    }


    const onSetSelectedJobType = (type: any) => {
        if (type == selectedJobType) return
        jobStateRef.current.details.options.roadside_job_options = false
        jobStateRef.current.details.options.towing_job_options = false
        jobStateRef.current.details.options.transport_job_options = false
        if (type == enum_JOB_TYPE_OPTION.ROADSIDE && companyHasRoadsideOptionsRef.current) {
            jobStateRef.current.details.options.roadside_job_options = true
            setSelectedJobType(type)
            return
        }
        if (type == enum_JOB_TYPE_OPTION.TOWING && companyHasTowingOptionsRef.current) {
            // create a JobTowingDetails object
            let _towing_details = new JobTowingDetails(JobState?.details?.towing_details)
            jobStateRef.current.details.towing_details = _towing_details
            jobStateRef.current.details.options.towing_job_options = true
            towing_details_ref.current = _towing_details
            onSetJob(jobStateRef.current, "onSetSelectedJobType")
            setSelectedJobType(type)
            return
        }
        if (type == enum_JOB_TYPE_OPTION.TRANSPORT && companyHasTransportOptionsRef.current) {
            jobStateRef.current.details.options.transport_job_options = true
            setSelectedJobType(type)
            return
        }
        setSelectedJobType(type)
    }



    const RenderJobTypeButtons = ({ style }: any) => {
        const optionsCount =
            (companyHasRoadsideOptionsRef.current ? 1 : 0) +
            (companyHasTowingOptionsRef.current ? 1 : 0) +
            (companyHasTransportOptionsRef.current ? 1 : 0)
        if (optionsCount < 2) return null
        return (
            <Card
                status="info"
                header={() => <RenderCardHeader value="Job Options" />}
                disabled={true}
                style={[{ margin: 0 }]}>
                <View style={{ flexDirection: 'row', justifyContent: 'space-evenly', margin: -15 }}>
                    {companyHasRoadsideOptionsRef.current &&
                        <Button
                            status='request'
                            appearance={selectedJobType == enum_JOB_TYPE_OPTION.ROADSIDE ? 'filled' : 'outline'}
                            onPress={() => { onSetSelectedJobType(enum_JOB_TYPE_OPTION.ROADSIDE) }}>
                            Roadside
                        </Button>
                    }
                    {companyHasTowingOptionsRef.current &&
                        <Button
                            status='success'
                            appearance={selectedJobType == enum_JOB_TYPE_OPTION.TOWING ? 'filled' : 'outline'}
                            onPress={() => { onSetSelectedJobType(enum_JOB_TYPE_OPTION.TOWING) }}>
                            Towing
                        </Button>
                    }
                    {companyHasTransportOptionsRef.current &&
                        <Button
                            status='warning'
                            appearance={selectedJobType == enum_JOB_TYPE_OPTION.TRANSPORT ? 'filled' : 'outline'}
                            onPress={() => { onSetSelectedJobType(enum_JOB_TYPE_OPTION.TRANSPORT) }}>
                            Transport
                        </Button>
                    }
                </View>

            </Card>
        )
    }


    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //////// SERVICES
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */
    // const [randUpdate, setRandUpdate] = useState(0)
    const updateJobServices = (services: Service[]) => {
        // // console.log("🚀============== ~ file: createJobContainer.component.tsx:342 ~ updateJobServices ~ services🚀==============", services)
        setSelectedServices(services)
        let _Job = { ...jobStateRef.current }
        _Job.details.selected_services = services
        onSetJob(_Job, "updateJobServices")
    }






    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    // Client created - Vendor Options
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////

    const onSetVendorClientProfile = (client: Client) => {
        // // console.log("🚀 ~ file: createJobContainer.component.js:272 ~ onSetVendorClientProfile ~ client:", client)
        vendorClientProfileRef.current = client
        jobStateRef.current.client_id = client._id
        onSetJob(jobStateRef.current, "onSetVendorClientProfile")
    }

    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    // Vendor created - Client Options
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    const onSetClient = (client: Client) => {
        selectedClientRef.current = client
        let _Job = new Job(jobStateRef.current)
        _Job.client_id = client._id
        _Job.client_company_id = client.linkedClientAccountId ?? null
        onSetJob(_Job, "onSetClient")
    }

    const onSetClientRate = (rate: JobClientRate) => {
        let _Job = jobStateRef.current
        _Job.setClientRate(rate)
        onSetJob(_Job, "onSetClientRate")
    }


    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    // KEYPRESS
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////

    const [focusedRef, setFocusedRef] = useState(null);
    const [customerRefs, setCustomerRefs] = useState([]);
    const [vehicleRefs, setVehicleRefs] = useState([]);
    const [towingRefs, setTowingRefs] = useState([]);

    const onInputFocus = (ref: any) => {
        setFocusedRef(ref);
    };

    const handleKeyPressArr = (field: any) => (e: any) => {
        try {
            const formRefsArr = [...customerRefs, ...vehicleRefs, ...towingRefs]
            //@ts-ignore
            const nextField = formRefsArr[field + 1]?.current;
            //@ts-ignore
            const prevField = formRefsArr[field - 1]?.current;
            if (e.nativeEvent.key === 'Tab' && e.nativeEvent.shiftKey) {
                // Shift + Tab was pressed.
                // You can now handle focus change here.
                e.preventDefault();
                prevField?.focus();
                return;
            }
            if (e.nativeEvent.key === 'Tab') {
                e.preventDefault();
                nextField?.focus();
            }
        } catch (err) {
            console.log(err, e);
        }
    };

    const handleKeyPress = useCallback((e: any) => {
        if (e.nativeEvent.key === 'Tab') {
            e.preventDefault();
            const formRefsArr = [...customerRefs, ...vehicleRefs, ...towingRefs]
            // Find the currently focused field's index
            const currentFieldIndex = formRefsArr.findIndex(ref => ref === focusedRef);
            if (currentFieldIndex !== -1) {
                if (e.nativeEvent.shiftKey) {
                    // Focus the previous field
                    //@ts-ignore
                    const prevField = formRefsArr[currentFieldIndex - 1]?.current;
                    prevField?.focus();
                } else {
                    // Focus the next field
                    //@ts-ignore
                    const nextField = formRefsArr[currentFieldIndex + 1]?.current;
                    nextField?.focus();
                }
            }
        }
    }, [focusedRef]);


    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    // SUBMIT
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    const [showValidationModal, setShowValidationModal] = React.useState(false)
    const [validationErrors, setValidationErrors] = React.useState([] as any)

    const validateNonEmptyFields = (field: any) => {
        if (field && field != "") {
            return true
        }
        return false
    }

    const verifyInputs = async () => {
        let mandatoryFields = [] as any
        if (jobSource == "client") {
            if (vendorProfile?.linkedVendorAccountId) {
                let linkedCompany = await CompanyController.getCompanyById(vendorProfile.linkedVendorAccountId)
                if (linkedCompany?.settings?.create_job_mandatory_fields) {
                    mandatoryFields = linkedCompany?.settings.create_job_mandatory_fields
                }
            }
            if (!vendorProfile?.linkedVendorAccountId) {
                if (vendorProfile?.settings?.create_job_mandatory_fields) {
                    mandatoryFields = vendorProfile?.settings.create_job_mandatory_fields
                }
            }
        }
        if (jobSource == "vendor" || jobSource == "local_client") {
            if (StorageController.getCurrentCompany().settings?.create_job_mandatory_fields) {
                mandatoryFields = StorageController.getCurrentCompany().settings.create_job_mandatory_fields
            }
        }
        let missingFields = []
        if (!JobState.details.client_rate) missingFields.push("Callout Rate Not Set")
        // TODO: broken for now. leads back to if the vendor is just a profile and not a company
        // if (jobSource == "client") {
        //     if (!vendorClientProfileRef.current) missingFields.push("Vendor Not Selected")
        // }

        if (jobSource == "vendor") {
            if (!selectedClientRef.current) missingFields.push("Client Not Selected")
        }
        if (jobSource == "local_client") {
            if (!selectedClientRef.current) missingFields.push("Client Not Selected")
        }

        if (!JobState.details.location) missingFields.push("Location Not Selected")

        if (mandatoryFields.customer_name && (!validateNonEmptyFields(JobState.details.customer_details?.name))) missingFields.push("Customer Name")
        if (mandatoryFields.customer_phone && (!validateNonEmptyFields(JobState.details.customer_details?.phone))) missingFields.push("Customer Phone")
        if (mandatoryFields.customer_email && (!validateNonEmptyFields(JobState.details.customer_details?.email))) missingFields.push("Customer Email")
        if (mandatoryFields.vehicle_rego && (!validateNonEmptyFields(JobState.details.vehicle_details?.rego))) missingFields.push("Rego")
        if (mandatoryFields.vehicle_make && (!validateNonEmptyFields(JobState.details.vehicle_details?.make))) missingFields.push("Make")
        if (mandatoryFields.vehicle_model && (!validateNonEmptyFields(JobState.details.vehicle_details?.model))) missingFields.push("Model")
        if (mandatoryFields.vehicle_year && (!validateNonEmptyFields(JobState.details.vehicle_details?.year))) missingFields.push("Year")
        if (mandatoryFields.vehicle_colour && (!validateNonEmptyFields(JobState.details.vehicle_details?.colour))) missingFields.push("Colour")

        if (mandatoryFields.address && !JobState.details.address) missingFields.push("Address")
        return missingFields
    }
    const RenderValidationModal = () => (
        <Modal
            visible={showValidationModal}
            backdropStyle={styles.backdrop}
            onBackdropPress={() => setShowValidationModal(false)}>
            <Card disabled={true} style={styles.modalCard}>
                <Text style={{}}>Please fill in the following fields:</Text>
                {validationErrors.map((error: any, index: number) => (
                    <Text key={index}>{error}</Text>
                ))}
                <Button appearance='outline' onPress={() => setShowValidationModal(false)}>OK</Button>
            </Card>
        </Modal>
    )

    //TODO: make the job report a bit better for a review
    const [showConfirmCreateJobModal, setShowConfirmCreateJobModal] = React.useState(false)
    const RenderConfirmCreateJobModal = () => (
        <Modal
            visible={showConfirmCreateJobModal}
            backdropStyle={styles.backdrop}
            onBackdropPress={() => setShowConfirmCreateJobModal(false)}>
            <Card
                disabled={true}
                status="success"
                header={() => <Text>Confirm Create Job?</Text>}
                footer={() =>
                    <View style={{ flexDirection: "row", justifyContent: 'space-between', gap: 50 }}>
                        <Button status='danger' appearance='outline' onPress={() => setShowConfirmCreateJobModal(false)}>Cancel</Button>
                        <Button status='success' appearance='outline' onPress={() => submitJob()}>Create Job</Button>
                    </View>
                }
            >
                <Layout
                // style={styles.modalCard}
                >
                    <ReportDetailsCard
                        job={JobState}
                    />
                </Layout>
            </Card>
        </Modal>
    )

    const createJobInitialStatusRef = React.useRef(JOB_STATUS.UNASSIGNED)
    const checkAndSubmit = async (status = JOB_STATUS.UNASSIGNED) => {
        // console.log("🚀============== ~ file: createJobContainer.component.tsx:577 ~ checkAndSubmit ~ status🚀==============", status)
        if (submittingRef.current) return
        submittingRef.current = true
        setCreateButtonDisabled(true)
        createJobInitialStatusRef.current = status
        // console.log("🚀============== ~ file: createJobContainer.component.tsx:581 ~ checkAndSubmit ~ createJobInitialStatusRef.current🚀==============", createJobInitialStatusRef.current)
        const errors = await verifyInputs()
        if (errors.length > 0) {
            setValidationErrors(errors)
            setShowValidationModal(true)
            setCreateButtonDisabled(false)
            submittingRef.current = false
            return
        }
        // setShowConfirmCreateJobModal(true)
        submitJob()
    }

    const submitJob = async () => {
        let _Job = new Job(JobState)
        _Job.status = createJobInitialStatusRef.current
        _Job.details.comments = dispatchCommentsValueRef.current
        const company = StorageController.getCurrentCompany()
        if (jobSource == "vendor") {
            // console.log("🚀============== ~ file: createJobContainer.component.tsx:600 ~ submitJob ~ jobSource🚀==============", jobSource)
            _Job.vendor_profile_id = selectedCompany._id
            _Job.company_id = selectedCompany._id
            _Job.vendor_company_id = selectedCompany._id
            _Job.is_vendor_created = true
            _Job.client_id = selectedClientRef.current?._id ?? null
            _Job.client_company_id = selectedClientRef.current?.linkedClientAccountId ?? null
        }
        if (jobSource == "local_client") {
            // console.log("🚀============== ~ file: createJobContainer.component.tsx:609 ~ submitJob ~ jobSource🚀==============", jobSource)
            _Job.vendor_profile_id = selectedCompany._id
            _Job.company_id = selectedCompany._id
            _Job.vendor_company_id = selectedCompany._id
            _Job.is_client_created = true
            _Job.client_id = StorageController.getAppState().selectedMembership?.client_id ?? null
            _Job.client_company_id = selectedClientRef.current?.linkedClientAccountId ?? null
        }
        // As the client, making a job for a vendor
        // if the vendor is a real existing vendor, we can link the job to them
        // if the vendor is not a real existing vendor, we can link the job to the client company
        // the option to link the vendor relationship later will be available
        if (jobSource == "client") {
            // console.log("🚀============== ~ file: createJobContainer.component.tsx:622 ~ submitJob ~ jobSource🚀==============", jobSource)
            _Job.vendor_profile_id = vendorProfile?._id ?? null // so if its linked in future we can still get the vendor id
            _Job.client_company_id = company._id
            _Job.is_client_created = true
            if (contract_id) {
                _Job.contract_id = contract_id
            }
            if (vendorProfile?.linkedVendorAccountId) {
                _Job.company_id = vendorProfile.linkedVendorAccountId
                _Job.client_id = vendorClientProfileRef.current?._id ?? null
                _Job.vendor_company_id = vendorProfile.linkedVendorAccountId
            } else {
                _Job.company_id = selectedCompany._id
            }
        }
        // Set Job options, this is to allow grandfathering of old jobs that will not have these options
        let options = {
            roadside_job_options: false,
            towing_job_options: false,
            transport_job_options: false,
        }
        if (selectedJobType == enum_JOB_TYPE_OPTION.ROADSIDE) options.roadside_job_options = true
        if (selectedJobType == enum_JOB_TYPE_OPTION.TOWING) options.towing_job_options = true
        if (selectedJobType == enum_JOB_TYPE_OPTION.TRANSPORT) options.transport_job_options = true
        _Job.details.options = options

        if (!_Job.is_scheduled) {
            _Job.start_time = new Date().getTime()
        }
        // // console.log("🚀============== ~ file: createJobContainer.component.js:583 ~ submitJob ~ _Job🚀==============", _Job)
        setCreateButtonDisabled(false)
        submittingRef.current = false
        // console.log("🚀============== ~ file: createJobContainer.component.tsx:653 ~ submitJob ~ _Job🚀==============", _Job)
        fn_createJob(_Job)
    }


    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    // CUSTOMER
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    const [customer_details, set_customer_details] = useState(new JobCustomerDetails({}))
    const onSetCustomerFieldsDetails = (field: any, value: any) => {
        let _customer_details = { ...customer_details }
        //@ts-ignore
        _customer_details[field] = value
        set_customer_details(_customer_details)
        let _Job = { ...jobStateRef.current }
        //@ts-ignore
        _Job.details[field] = value //TODO: this will be removed
        _Job.details.customer_details = _customer_details
        onSetJob(_Job, "onSetCustomerFieldsDetails")
        // // console.log("🚀 ~ file: createJobContainer.component.js:493 ~ onSetCustomerDetails ~ _customerDetails:", _customer_details)
    }

    const onSetCustomerDetails = (customerDetailsObj: any | JobCustomerDetails) => {
        let _customer_details = new JobCustomerDetails(jobStateRef.current?.details?.customer_details)
        _customer_details = { ..._customer_details, ...customerDetailsObj }
        let _Job = { ...jobStateRef.current }
        _Job.details.customer_details = _customer_details
        set_customer_details(_customer_details)
        onSetJob(_Job, "onSetCustomerDetails")
    }






    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    // VEHICLE
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////

    const [vehicle_details, set_vehicle_details] = useState(new JobVehicleDetails({}))
    const onSetVehicleFieldDetails = (field: any, value: any) => {
        let _vehicle_details = { ...vehicle_details }
        //@ts-ignore
        _vehicle_details[field] = value
        set_vehicle_details(_vehicle_details)
        let _Job = { ...jobStateRef.current }
        //@ts-ignore
        _Job.details[field] = value //TODO: this will be removed
        _Job.details.vehicle_details = _vehicle_details
        onSetJob(_Job, "onSetVehicleFieldDetails")
        // // console.log("🚀 ~ file: createJobContainer.component.js:493 ~ onSetVehicleDetails ~ _vehicle_details:", _vehicle_details)
    }

    const onSetVehicleDetails = (vehicleDetailsObj: any | JobVehicleDetails) => {
        let _vehicle_details = new JobVehicleDetails(JobState?.details?.vehicle_details)
        _vehicle_details = { ..._vehicle_details, ...vehicleDetailsObj }
        let _Job = { ...jobStateRef.current }
        _Job.details.vehicle_details = _vehicle_details
        set_vehicle_details(_vehicle_details)
        onSetJob(_Job, "onSetVehicleDetails")
    }


    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    // TOWING OPTIONS
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    const [towingPickupAddressDisplayValue, setTowingPickupAddressDisplayValue] = useState("")
    const [towingDropoffAddressDisplayValue, setTowingDropoffAddressDisplayValue] = useState("")

    const towing_details_ref = useRef(new JobTowingDetails(JobState?.details?.towing_details || {}));

    const onSetTowingDetails = (field: any, value: any) => {
        let _towing_details = new JobTowingDetails(jobStateRef.current?.details?.towing_details)
        //@ts-ignore
        _towing_details[field] = value
        jobStateRef.current.details.towing_details = _towing_details
        towing_details_ref.current = _towing_details
        // console.log("🚀============== ~ file: createJobContainer.component.js:692 ~ onSetTowingDetails ~ _towing_details🚀==============", _towing_details)
        onSetJob(jobStateRef.current, "onSetTowingDetails")
    }

    /**
         * 
         * @param {JobTowingDetails} locationDetailsObj 
         */
    const onSetTowingLocationDetails = (locationDetailsObj: any | JobTowingDetails) => {
        // console.log("🚀============== ~ file: jobDetailContainer.component.js:401 ~ onSetTowingLocationDetails ~ locationDetailsObj🚀==============", locationDetailsObj)
        // Merge location details with towing details
        let _towing_details = new JobTowingDetails(jobStateRef.current?.details?.towing_details) // get existing details
        _towing_details.towing_locations = locationDetailsObj.towing_locations
        _towing_details.towing_legs = locationDetailsObj.towing_legs
        _towing_details.distance_kms = locationDetailsObj.distance_kms
        _towing_details.duration_seconds = locationDetailsObj.duration_seconds
        _towing_details.cost_per_km = locationDetailsObj.cost_per_km
        _towing_details.covered_distance_kms = locationDetailsObj.covered_distance_kms
        _towing_details.total_cost = locationDetailsObj.total_cost
        _towing_details.original_dropoff_towing_location = locationDetailsObj.original_dropoff_towing_location
        _towing_details.direction_polylines = locationDetailsObj.direction_polylines
        _towing_details.directions_polyline = locationDetailsObj.directions_polyline

        jobStateRef.current.details.location = _towing_details.towing_locations[0]?.location
        jobStateRef.current.details.address = _towing_details.towing_locations[0]?.address
        // // console.log("🚀============== ~ file: createJobContainer.component.js:680 ~ onSetTowingLocationDetails ~ JobState.details.address🚀==============", _towing_details.towing_locations[0]?.address)
        jobStateRef.current.details.towing_details = _towing_details
        const newJobState = new Job(jobStateRef.current)
        towing_details_ref.current = _towing_details
        // // console.log("🚀============== ~ file: jobDetailContainer.component.js:431 ~ onSetTowingLocationDetails ~ _towing_details🚀==============", _towing_details)
        onSetJob(jobStateRef.current, "onSetTowingLocationDetails")
    }

    const addTowingDistanceItem = (towingItems: LineItem[]) => {
        try {
            if (!towingItems) return
            if (towingItems.length == 0) return
            let _Job = jobStateRef.current
            // // console.log("🚀============== ~ file: createJobContainer.component.js:732 ~ addTowingDistanceItem ~ _Job🚀==============", _Job)
            towingItems.forEach(item => {
                _Job.addLineItem(item)
                // // console.log("🚀============== ~ file: createJobContainer.component.js:734 ~ addTowingDistanceItem ~ item🚀==============", item)
            });
            onSetJob(_Job, "addTowingDistanceItem")
        } catch (err) {
            console.log(err)
        }
    }




    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    // EQUIPMENT
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////


    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    // SCHEDULED
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////



    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    // ADDRESS
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    const [addressDisplayValue, setAddressDisplayValue] = useState("")


    const onSetAddress = (addrObj: JobAddress) => {
        let _Job = { ...jobStateRef.current }
        _Job.details.address = addrObj
        onSetJob(_Job, "onSetAddress")
        setAddress(addrObj)
    }

    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    // FIELD MAPPING
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////

    const RenderFieldMapping = ({ style = { flex: 1 } }: any) => {

        const [parsedPastedInputString, setParsedPastedInputString] = useState("");


        const handlePaste = (pastedText: string) => {
            // Array of delimiters
            const delimiters = [
                ',',
                ':',
                '\r\n',
                '\r',
                '\n',
                '\t'
            ];
            // Create a regex from the delimiters
            // const regex = new RegExp(delimiters.join('|'), 'g');
            // Regular expression for splitting and ignoring text before ': '
            // const regex = new RegExp(delimiters.join('|') + '|(?<=: ).*', 'g');

            // Split the text and trim each part
            // const parts = pastedText.split(regex).map(s => s.trim());
            // const parts = (pastedText.match(regex) || [])
            //     .filter(part => part && part.trim() !== '')
            //     .map(s => s.trim());
            // Split the text, filter out undefined values, and trim each part
            // const parts = pastedText.match(regex).filter(Boolean).map(s => s.trim());

            // remove any empty strings
            // split by delimiters
            // const parts = pastedText.split(delimiters.join('|')).filter(Boolean).map(s => s.trim());
            const regex = new RegExp(delimiters.join('|'), 'g');
            const parts = pastedText.split(regex).map(s => s.trim());
            // const parts = pastedText.split(/,|\r?\n|\r/).map(s => s.trim());
            setParsedData(parts);
        };
        const mapField = (field: any, value: any) => {
            let towing_vehicle_details: JobVehicleDetails = JobState.details.towing_details?.vehicle_details || new JobVehicleDetails({})
            let towing_equipment_details: JobEquipmentDetails = JobState.details.towing_details?.equipment_details || new JobEquipmentDetails({})
            switch (field) {
                case 'name':
                    onSetCustomerFieldsDetails("name", value)
                    break;
                case 'phone':
                    onSetCustomerFieldsDetails("phone", value)
                    break;
                case 'email':
                    onSetCustomerFieldsDetails("email", value)
                    break;
                case 'make':
                    onSetVehicleFieldDetails("make", value)
                    break;
                case 'model':
                    onSetVehicleFieldDetails("model", value)
                    break;
                case 'year':
                    onSetVehicleFieldDetails("year", value)
                    break;
                case 'colour':
                    onSetVehicleFieldDetails("colour", value)
                    break;
                case 'rego':
                    onSetVehicleFieldDetails("rego", value)
                    break;
                case 'notes':
                    let notes = jobStateRef.current.details.comments || ""
                    notes += ` ${value}`
                    dispatchCommentsValueRef.current = notes
                    onSetJob({ ...jobStateRef.current, details: { ...jobStateRef.current.details, comments: notes } }, "mapField")
                    break;
                case 'client_reference_id':
                    onSetJob({ ...jobStateRef.current, client_reference_id: value }, "mapField")
                    break;


                case 'towing_pickup':
                    setTowingPickupAddressDisplayValue(towingPickupAddressDisplayValue + " " + value)
                    break;
                case 'towing_dropoff':
                    setTowingDropoffAddressDisplayValue(towingDropoffAddressDisplayValue + " " + value)
                    break;


                case 'towing_rego':
                    towing_vehicle_details.rego = value
                    onSetTowingDetails("vehicle_details", towing_vehicle_details)
                    break;
                case 'towing_make':
                    towing_vehicle_details.make = value
                    onSetTowingDetails("vehicle_details", towing_vehicle_details)
                    break;
                case 'towing_model':
                    towing_vehicle_details.model = value
                    onSetTowingDetails("vehicle_details", towing_vehicle_details)
                    break;
                case 'towing_year':
                    towing_vehicle_details.year = value
                    onSetTowingDetails("vehicle_details", towing_vehicle_details)
                    break;
                case 'towing_colour':
                    towing_vehicle_details.colour = value
                    onSetTowingDetails("vehicle_details", towing_vehicle_details)
                    break;

                case 'towing_equipment_equipment':
                    towing_equipment_details.equipment = value
                    onSetTowingDetails("equipment_details", towing_equipment_details)
                    break;
                case 'towing_equipment_type':
                    towing_equipment_details.type = value
                    onSetTowingDetails("equipment_details", towing_equipment_details)
                    break;
                case 'towing_equipment_weight':
                    towing_equipment_details.weight = value
                    onSetTowingDetails("equipment_details", towing_equipment_details)
                    break;
                case 'towing_equipment_size':
                    towing_equipment_details.size = value
                    onSetTowingDetails("equipment_details", towing_equipment_details)
                    break;
                case 'towing_equipment_serial':
                    towing_equipment_details.serial = value
                    onSetTowingDetails("equipment_details", towing_equipment_details)
                    break;





                case 'address':
                    // get term and append value
                    // const term = search.term
                    // setSearch({ term: term + " " + value, fetchPredictions: true })
                    setAddressDisplayValue(addressDisplayValue + " " + value)
                    // onChangeText(value)
                    // debouncedSave(search.term)
                    break;
                default:
                    break;
            }



            // Remove the mapped value from parsedData array
            setParsedData(parsedData.filter((item: any) => item !== value));
        };
        return (
            <Card
                status="info"
                header={() => <RenderCardHeader value="Field Mapping" />}
                disabled={true}
                style={style}>
                <Text category='h5'>Paste csv or plain text:</Text>
                {/* <Text category='h6'>{pastedString}</Text> */}
                <Input
                    multiline={true}
                    textStyle={{ minHeight: 64 }}
                    defaultValue={parsedPastedInputString}
                    onChangeText={(nextValue: string) => {
                        setParsedPastedInputString(nextValue);
                        // setPastedString(nextValue);
                        // handlePaste(nextValue);
                    }}
                />
                <View style={{ flexDirection: 'row' }}>
                    <View style={{ flex: 9 }}></View>
                    <Button
                        appearance='outline'
                        status="success"
                        onPress={() => {
                            // get cliboard data and parse it
                            handlePaste(parsedPastedInputString);
                        }} style={{ margin: 5, flex: 1 }}>
                        Process
                    </Button>
                </View>

                {parsedData.length > 0 && (
                    <Layout>
                        <Text category='s1'>Map the following fields:</Text>
                        {parsedData.map((item: any, index: number) => (
                            <View key={index} style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', gap: 3 }}>
                                <Text style={{ flex: 2 }}>{item}</Text>
                                <View style={{ flex: 10, flexDirection: "row" }}>
                                    {!JobState.details.customer_details?.name && (
                                        <Button appearance='outline' size="small" style={{ margin: 5 }} onPress={() => mapField('name', item)}>Name</Button>
                                    )}
                                    {!JobState.details.customer_details?.phone && (
                                        <Button appearance='outline' size="small" style={{ margin: 5 }} onPress={() => mapField('phone', item)}>Phone</Button>
                                    )}
                                    {!JobState.details.customer_details?.email && (
                                        <Button appearance='outline' size="small" style={{ margin: 5 }} onPress={() => mapField('email', item)}>Email</Button>
                                    )}

                                    <Button status='info' appearance='outline' size="small" style={{ margin: 5 }} onPress={() => mapField('notes', item)}>+ Notes</Button>
                                    {/* {!JobState.details.comments && (
                                    )} */}
                                    {!JobState.client_reference_id && (
                                        <Button status='purple' appearance='outline' size="small" style={{ margin: 5 }} onPress={() => mapField('client_reference_id', item)}>Client Ref ID</Button>
                                    )}
                                    {selectedJobType == enum_JOB_TYPE_OPTION.ROADSIDE &&
                                        <>
                                            {!JobState.details.vehicle_details?.make && (
                                                <Button status='warning' appearance='outline' size="small" style={{ margin: 5 }} onPress={() => mapField('make', item)}>Make</Button>
                                            )}
                                            {!JobState.details.vehicle_details?.model && (
                                                <Button status='warning' appearance='outline' size="small" style={{ margin: 5 }} onPress={() => mapField('model', item)}>Model</Button>
                                            )}
                                            {!JobState.details.vehicle_details?.year && (
                                                <Button status='warning' appearance='outline' size="small" style={{ margin: 5 }} onPress={() => mapField('year', item)}>Year</Button>
                                            )}
                                            {!JobState.details.vehicle_details?.colour && (
                                                <Button status='warning' appearance='outline' size="small" style={{ margin: 5 }} onPress={() => mapField('colour', item)}>Colour</Button>
                                            )}
                                            {!JobState.details.vehicle_details?.rego && (
                                                <Button status='warning' appearance='outline' size="small" style={{ margin: 5 }} onPress={() => mapField('rego', item)}>Rego</Button>
                                            )}
                                            <Button status="success" appearance='outline' size="small" style={{ margin: 5 }} onPress={() => mapField('address', item)}>+ Address</Button>
                                        </>
                                    }
                                    {selectedJobType == enum_JOB_TYPE_OPTION.TOWING &&
                                        <>
                                            {JobState.details.towing_details?.towing_type?.toLowerCase() === "vehicle" &&
                                                <>
                                                    {!JobState.details.towing_details?.vehicle_details?.rego &&
                                                        <Button status='warning' appearance='outline' size="small" style={{ margin: 5 }} onPress={() => mapField('towing_rego', item)}>Rego</Button>
                                                    }
                                                    {!JobState.details.towing_details?.vehicle_details?.make &&
                                                        <Button status='warning' appearance='outline' size="small" style={{ margin: 5 }} onPress={() => mapField('towing_make', item)}>Make</Button>
                                                    }
                                                    {!JobState.details.towing_details?.vehicle_details?.model &&
                                                        <Button status='warning' appearance='outline' size="small" style={{ margin: 5 }} onPress={() => mapField('towing_model', item)}>Model</Button>
                                                    }
                                                    {!JobState.details.towing_details?.vehicle_details?.year &&
                                                        <Button status='warning' appearance='outline' size="small" style={{ margin: 5 }} onPress={() => mapField('towing_year', item)}>Year</Button>
                                                    }
                                                    {!JobState.details.towing_details?.vehicle_details?.colour &&
                                                        <Button status='warning' appearance='outline' size="small" style={{ margin: 5 }} onPress={() => mapField('towing_colour', item)}>Colour</Button>
                                                    }
                                                </>
                                            }
                                            {JobState.details.towing_details?.towing_type?.toLowerCase() === "equipment" &&
                                                <>
                                                    {!JobState.details.towing_details?.equipment_details?.equipment &&
                                                        <Button status='warning' appearance='outline' size="small" style={{ margin: 5 }} onPress={() => mapField('towing_equipment_equipment', item)}>Equipment</Button>
                                                    }
                                                    {!JobState.details.towing_details?.equipment_details?.type &&
                                                        <Button status='warning' appearance='outline' size="small" style={{ margin: 5 }} onPress={() => mapField('towing_equipment_type', item)}>Type</Button>
                                                    }
                                                    {!JobState.details.towing_details?.equipment_details?.weight &&
                                                        <Button status='warning' appearance='outline' size="small" style={{ margin: 5 }} onPress={() => mapField('towing_equipment_weight', item)}>Weight</Button>
                                                    }
                                                    {!JobState.details.towing_details?.equipment_details?.size &&
                                                        <Button status='warning' appearance='outline' size="small" style={{ margin: 5 }} onPress={() => mapField('towing_equipment_size', item)}>Size</Button>
                                                    }
                                                    {!JobState.details.towing_details?.equipment_details?.serial &&
                                                        <Button status='warning' appearance='outline' size="small" style={{ margin: 5 }} onPress={() => mapField('towing_equipment_serial', item)}>Serial</Button>
                                                    }
                                                </>
                                            }
                                            <Button status='success' appearance='outline' size="small" style={{ margin: 5 }} onPress={() => mapField('towing_pickup', item)}>+ Pickup Address</Button>
                                            <Button status='success' appearance='outline' size="small" style={{ margin: 5 }} onPress={() => mapField('towing_dropoff', item)}>+ Dropoff Address</Button>
                                        </>
                                    }
                                </View>

                            </View>
                        ))}
                    </Layout>
                )}
            </Card>
        );
    }

    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    // RENDER
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////

    const RenderCardHeader = ({ value }: any) => {
        return (
            <Text
                style={styles.groupTitle}
            >{value}
            </Text>
        )
    }




    const RenderCreateJobButton = () => {
        const RequestBtn = (
            <Button
                style={{ margin: 5, flex: 1 }}
                appearance='outline'
                disabled={createButtonDisabled}
                status='success'
                onPress={() => checkAndSubmit(JOB_STATUS.REQUEST)}>
                <View style={{ flexDirection: 'row' }}>
                    <Icon name='edit-2-outline' width={24} height={24} fill={theme['text-basic-color']} />
                    <Text>Create Job Request</Text>
                </View>
            </Button>
        )
        const UnassignedBtn = (
            <Button
                style={{ margin: 5, flex: 1 }}
                appearance='filled'
                disabled={createButtonDisabled}
                status='success'
                onPress={() => checkAndSubmit(JOB_STATUS.UNASSIGNED)}>
                <View style={{ flexDirection: 'row' }}>
                    <View style={{ flexDirection: 'row' }}>
                        <Icon name='edit-2-outline' width={24} height={24} fill={theme['text-basic-color']} />
                        <Text>Create</Text>
                    </View>
                </View>
            </Button>
        )

        if (jobSource == "client") {
            if (vendorProfile?.linkedVendorAccountId) {
                return RequestBtn
            }
            else {
                return UnassignedBtn
            }
        }
        if (jobSource == "vendor") {
            return UnassignedBtn
        }
        if (jobSource == "local_client") {
            return RequestBtn
        }
        return null
    }



    if (loading) {
        return null
    }





    return (
        <Layout style={{ flex: 1 }}>
            <RenderValidationModal />
            <RenderConfirmCreateJobModal />
            <View style={width < windowCollapseWidth ? styles.smallWindowContainerStyle : styles.largeWindowContainerStyle}>

                {/*
                //////////////////////////////////////////////////////////////////
                //////////////////////////////////////////////////////////////////
                //////// Main Container //////////////////////////////////////////
                //////////////////////////////////////////////////////////////////
                //////////////////////////////////////////////////////////////////
                */}

                <View style={styles.mainAreaContainer}>
                    {/*
                            //////////////////////////////////////////////////////////////////
                            //////////////////////////////////////////////////////////////////
                            //////// Top Buttons /////////////////////////////////////////////
                            //////////////////////////////////////////////////////////////////
                            //////////////////////////////////////////////////////////////////
                        */}
                    <View style={styles.topButtonsContainer}>
                        <RenderJobTypeButtons style={styles.groupContainerItem} />
                        <View style={styles.topButtonsContainerItem}>
                            <Card
                                header={() => <RenderCardHeader value="Tools" />}
                                style={{ flex: 1 }}
                                status="basic"
                                disabled
                            >
                                <View style={{ height: 40, flexDirection: 'row', justifyContent: 'space-between', gap: 5 }}>
                                    {
                                        !showFieldMapping ?
                                            <Button
                                                appearance='outline'
                                                status="info"
                                                style={{ margin: 0, flex: 1 }}
                                                onPress={() => setShowFieldMapping(true)}
                                            >
                                                Open Field Mapping Tool
                                            </Button>
                                            : null
                                    }

                                    {!showDistanceQuoteTool ?
                                        <Button
                                            appearance='outline'
                                            status="info"
                                            style={{ margin: 0, flex: 1 }}
                                            onPress={() => setShowDistanceQuoteTool(true)}
                                        >
                                            Open Distance Quote Tool
                                        </Button>
                                        : null
                                    }
                                </View>
                            </Card>
                        </View>
                    </View>
                    {showFieldMapping || showDistanceQuoteTool ?
                        <View style={{ flexDirection: 'column', justifyContent: "space-between" }}>
                            {showFieldMapping ?
                                <View style={{}}>
                                    <RenderFieldMapping />
                                    <Button
                                        // appearance='outline'
                                        status="danger"
                                        style={{ position: 'absolute', margin: 5, flex: 1, top: 0, right: 0, width: 30 }}
                                        onPress={() => setShowFieldMapping(false)}
                                    >
                                        X
                                    </Button>

                                </View>
                                :
                                null
                            }
                            {
                                showDistanceQuoteTool ?
                                    <View>
                                        <Button
                                            status="danger"
                                            style={{
                                                position: 'absolute',
                                                margin: 5,
                                                flex: 1,
                                                top: 0,
                                                right: 0,
                                                zIndex: 100,
                                                width: 30
                                            }}
                                            onPress={() => setShowDistanceQuoteTool(false)}
                                        >x</Button>
                                        <DistanceQuoteTool />
                                    </View>
                                    :
                                    null
                            }
                        </View>
                        : <></>}

                    <View style={styles.groupContainer}>
                        {/* Customer Details */}
                        <View style={styles.groupContainerItem}>
                            <CustomerDetailsCard
                                style={[styles.groupContainerItem, {}]}
                                customerDetails={customer_details}
                                onChangeCustomerDetails={onSetCustomerDetails}
                                handleKeyPress={handleKeyPress}
                                setRefs={(refs: any) => setCustomerRefs(refs)}
                                onInputFocus={onInputFocus}
                                allowEdit={true}
                            />
                        </View>
                        <View style={styles.groupContainerItem}>
                            {/* Vehicle Details */}
                            {selectedJobType == enum_JOB_TYPE_OPTION.ROADSIDE &&
                                <VehicleDetailsCard
                                    style={styles.groupContainerItem}
                                    allowEdit={true}
                                    vehicleDetails={JobState.details.vehicle_details}
                                    onChangeVehicleDetails={onSetVehicleDetails}
                                    handleKeyPress={handleKeyPress}
                                    onInputFocus={onInputFocus}
                                    setRefs={(refs: any) => setVehicleRefs(refs)}
                                />
                            }
                            {
                                selectedJobType == enum_JOB_TYPE_OPTION.TOWING &&
                                <TowingDetailsCard
                                    setRefs={(refs) => setTowingRefs(refs)}
                                    onChangeTowingDetails={onSetTowingDetails}
                                    defaultTowingObj={JobState.details?.towing_details || new JobTowingDetails({})}
                                    onKeypress={handleKeyPress}
                                    onInputFocus={onInputFocus}
                                    style={styles.groupContainerItem}
                                />
                            }
                        </View>
                    </View>
                    <View style={styles.groupContainer}>


                        <View style={styles.groupContainerItem}>
                            {/* Toggles */}
                            <ErrorBoundary>
                                <OptionsDetailsCard
                                    style={styles.groupContainerItem}
                                    job={JobState}
                                    allowEdit={true}
                                    onSetDispatchable={(e: boolean) => onSetJob({ ...JobState, dispatchable: e }, "onSetDispatchable")}
                                    onSetIsScheduled={(e: boolean) => onSetJob({ ...JobState, is_scheduled: e }, "onSetIsScheduled")}
                                    onSetVendorEta={(e) => onSetJob({ ...JobState, vendor_eta: e }, "onSetVendorEta")}
                                    onSet_start_time={(e) => onSetJob({ ...JobState, start_time: e }, "onSet_start_time")}
                                    onSetPriority={(e) => onSetJob({ ...JobState, details: { ...JobState.details, priority: e } }, "onSetPriority")}
                                />
                            </ErrorBoundary>
                        </View>
                        <View style={styles.groupContainerItem}>

                            {
                                (jobSource == "vendor" || jobSource == "local_client") &&
                                <ClientDetailsCard
                                    job={JobState}
                                    clientReferenceId={JobState.client_reference_id}
                                    fn_onSetClient={onSetClient}
                                    fn_onSetClientRate={onSetClientRate}
                                    fn_onSetClientRefrerence={(value) => {
                                        const j = new Job(JobState);
                                        j.client_reference_id = value;
                                        onSetJob(j, "fn_onSetClientRefrerence");
                                    }}
                                    style={styles.groupContainerItem}
                                    isNewJob={true}
                                    jobCreateSource={jobSource}
                                    allowEdit={true}
                                    onInputFocus={onInputFocus}
                                    jobCreatedBy={''}
                                    setRefs={function (e: any): void {

                                    }} />

                            }
                            {
                                jobSource == "client" &&
                                <VendorDetailsCard
                                    style={styles.groupContainerItem}
                                    vendorProfile={vendorProfile}
                                    clientReferenceId={JobState.client_reference_id}
                                    Job={JobState}
                                    onSetJob={onSetJob}
                                    onSetVendorClientProfile={onSetVendorClientProfile}
                                    onSetClientRate={onSetClientRate}
                                    formRefsArr={formRefsArr}
                                    handleKeyPressArr={handleKeyPressArr}
                                    onChangeClientRefId={(value: any) => {
                                        onSetJob({ ...JobState, client_reference_id: value }, "onChangeClientRefId");
                                    }}
                                    onInputFocus={onInputFocus}
                                    allowEdit={true}
                                    setRefs={undefined} />
                            }
                        </View>
                    </View>
                    <View style={[styles.groupContainerItemLarge]}>
                        {selectedJobType == enum_JOB_TYPE_OPTION.ROADSIDE &&
                            <LocationDetailsCard
                                style={styles.groupContainerItemLarge}
                                onSetAddress={onSetAddress}
                                onSetLocation={(location) => {
                                    let _Job = { ...JobState }
                                    const locationObj = {
                                        lat: location.lat,
                                        lng: location.lng,
                                        lon: location.lng
                                    }
                                    _Job.details.location = new JobLocation(locationObj)
                                    onSetJob(_Job, "onSetLocation")
                                }}
                                address={address}
                                location={JobState.details?.location}
                                searchDisplayValue={addressDisplayValue}
                                // styles={styles}
                                cardTitle={"Job Location"}
                            />
                        }
                        {selectedJobType == enum_JOB_TYPE_OPTION.TOWING &&
                            <TowingLocationDetailsCard
                                style={styles.groupContainerItemLarge}
                                onChangeTowingLocations={onSetTowingLocationDetails}
                                onAddCostToItems={addTowingDistanceItem}
                                defaultTowingLocationObj={JobState.details.towing_details || new JobTowingDetails({})}
                                pickupAddressDisplayValue={towingPickupAddressDisplayValue}
                                dropoffAddressDisplayValue={towingDropoffAddressDisplayValue}
                            />
                        }
                    </View>
                    <View style={styles.groupContainerItemLarge}>
                        <InventoryDetailsCard
                            style={[styles.groupContainerItemLarge, { flex: 2 }]}
                            selectedItems={JobState.details.report?.items || []}
                            onChangeSelectedItems={(items) => {
                                let _Job = { ...jobStateRef.current }
                                if (!jobStateRef.current.details.report) _Job.details.report = new JobReport({})
                                _Job.details.report.items = items
                                onSetJob(_Job, "onChangeSelectedItems")
                            }}
                            vendorProfile={vendorProfile}
                            jobSource={jobSource}
                        />
                    </View>
                    <View style={styles.groupContainerItemLarge}>
                        <InvoicedTotalCard
                            job={JobState}
                            style={styles.groupContainerItemLarge}
                        />
                    </View>
                    <View style={styles.groupContainerItemLarge}>
                        <OtherDetailsCard
                            allowEdit={true}
                            dispatchComments={JobState.details?.comments}
                            onSetDispatchComments={(value: string) => {
                                dispatchCommentsValueRef.current = value
                                console.log("dispatchCommentsValueRef.current", dispatchCommentsValueRef.current)
                            }}
                            style={styles.groupContainerItemLarge}
                        />
                    </View>

                </View>
                {/*
                //////////////////////////////////////////////////////////////////
                //////////////////////////////////////////////////////////////////
                //////// SERVICES
                //////////////////////////////////////////////////////////////////
                //////////////////////////////////////////////////////////////////
                */}
                <View style={{}}>

                    <View style={styles.groupContainerLarge}>
                        <View style={styles.groupContainerItemLarge}>
                            <Card
                                disabled
                                status='warning'
                                style={{}}
                                header={() => <Text category="h6">Services</Text>}
                            >
                                <ServicesContainer
                                    fn_updateJobServices={updateJobServices}
                                    var_selectedServices={selectedServices}
                                    allowEdit={true}
                                    vendorCompanyId={vendorProfile?.linkedVendorAccountId}
                                    var_randUpdate={undefined}
                                    style={{}}
                                />
                            </Card>
                        </View>
                    </View>
                </View>
                {/*
                //////////////////////////////////////////////////////////////////
                //////////////////////////////////////////////////////////////////
                //////// COMPLETE
                //////////////////////////////////////////////////////////////////
                //////////////////////////////////////////////////////////////////
                */}
                <Layout style={{ flexDirection: "row", borderTop: "1px solid", width: "100%" }}>
                    <Layout style={{ flex: 1, flexDirection: 'row', paddingTop: 25, width: "100%" }}>
                        <View style={{ flexDirection: 'row', flex: 1 }}>
                            {/* save as quote */}
                            <Button
                                style={{ margin: 5, flex: 1 }}
                                appearance='outline'
                                status='quote'
                                onPress={() => checkAndSubmit(JOB_STATUS.QUOTE)}>
                                <View style={{ flexDirection: 'row' }}>
                                    <View style={{ flexDirection: 'row' }}>
                                        <Icon name='edit-2-outline' width={24} height={24} fill={theme['text-basic-color']} />
                                        <Text>
                                            Save as Quote
                                        </Text>
                                    </View>
                                </View>
                            </Button>
                            <RenderCreateJobButton />
                        </View>
                    </Layout>
                </Layout>
            </View>
        </Layout >
    )

}



const styles = StyleSheet.create({
    largeWindowContainerStyle: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: 10
    },
    mediumWindowContainerStyle: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: 10
    },
    smallWindowContainerStyle: {
        flexDirection: 'column',
        gap: 10
    },

    mainAreaContainer: {
        flexDirection: 'column',
        gap: 10,
        flex: 3,
        minWidth: 300,
    },
    topButtonsContainer: {
        flexDirection: 'row',
        justifyContent: "space-between",
        gap: 5,
        flexWrap: 'wrap'
    },
    topButtonsContainerItem: {
        flexDirection: 'row',
        justifyContent: "space-between",
        gap: 5,
        flex: 1,
        minWidth: 200,
    },
    groupContainer: {
        flexDirection: 'row',
        // flex: 1,
        // flexBasis: "100%",
        flexWrap: 'wrap',
        gap: 10
    },
    groupContainerLarge: {
        flex: 1,
        minWidth: 350,
        // backgroundColor: '#252e56',
    },
    groupContainerItem: {
        flex: 1,
        flexBasis: '49%', //that works ut eeehh
        minWidth: 300,
        // maxWidth: 600,
        // backgroundColor: '#252e56',
    },
    groupContainerItemLarge: {
        // flex: 1,
        // flexBasis: '100%',
        minWidth: 350,
        // backgroundColor: '#252e56',
    },

    groupTitle: {
        fontSize: 14,
        fontWeight: 'bold',
        // marginBottom: 8,
        // backgroundColor: '#090b15',
    },
    inputField: {
        paddingLeft: 24,
        paddingRight: 24,
    },
    horizontalLayout: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexWrap: 'wrap',
    },
    verticalLayout: {
        flexDirection: 'column',
    },
    title: {
        fontSize: 24,
        fontWeight: 'bold',
        textAlign: 'center',
        marginVertical: 8,
    },
    modalCard: {
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 10,
        padding: 10,
        margin: 10,
        maxHeight: "80%",
        minHeight: "50%",
        width: "90%",
        maxWidth: "90%",
        minWidth: "90%",
        alignSelf: "center"
    },
    container: {
        maxHeight: "100%",
        height: "100%",
        overflow: "hidden"
    },
    contentContainer: {
        paddingHorizontal: 0,
        paddingVertical: 0,
        overflow: "hidden"
    },
    listcontainer: {
        flexDirection: "column",
        height: "100%",
        width: "100%",
        overflow: "hidden"
    },
    tinyLogo: {
        width: "100%",
        height: 100,
    },
    item: {
        marginVertical: 4,
    },
    predictionsContainer: {
        backgroundColor: '#cfcfcf',
        padding: 10,
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 10
    },
    predictionRow: {
        paddingBottom: 15,
        marginBottom: 15,
        borderBottomColor: 'black',
        borderBottomWidth: 1,
    },
    calendar: {
        position: 'absolute',
        backgroundColor: 'black',
        zIndex: 100,
    },
    backdrop: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
});