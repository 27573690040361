import React, { useState, useEffect, useReducer, useCallback, useRef, useMemo } from 'react';
//@ts-ignore
import { StyleSheet, Text as RNText, Platform, useWindowDimensions, View } from "react-native";
import {
    Button,
    Icon,
    Layout,
    Card,
    Input,
    Modal,
    Text,
    List,
    ListItem,
    //@ts-ignore
    CheckBox,
    Divider
} from "@ui-kitten/components";
import * as JobsController from '../../functions/job.controller'
import * as MembershipsController from '../../functions/membership.controller'
import * as UpdateController from '../../functions/update.controller'
import * as ImageController from '../../functions/image.controller'
import * as ClientController from '../../functions/client.controller'
import * as StorageController from '../../functions/storageController'
import * as ServiceController from '../../functions/service.controller'
import * as VendorProfileController from '../../functions/vendorProfile.controller'
import * as CompanyController from '../../functions/company.controller'
import ServiceNetworkMap from '../map/serviceNetworkMap.component';
import ErrorBoundary from '../ErrorBoundary.component';
import NewJobForVendorContainerScreen from '../job/clientJobs/newJobForVendorContainerScreen.component';
import JobsList from '../job/jobsList.component';
import { JobDetailContainer } from '../job/details/jobDetailContainer.component';
import { Company, CompanyContract } from '../../models/Company.model';
import { VendorProfile } from '../../models/VendorProfile.model';
import { Service } from '../../models/Job.model';
import { Member } from '../../models/Member.model';
import { Job, JOB_STATUS } from '../../models/Job.model';
import { Case } from '../../models/Case.model';
import { CaseList } from '../job/casesList.component';
import { Client } from 'mqtt';
import { useAppStateChange, IAction, STATE_ACTIONS } from '../../hooks/appStateChange.hook';

enum LIST_TABS {
    JOBS = "JOBS",
    CREATE = "CREATE",
    CASES = "CASES",
}
enum CASE_STATUS_FILTER {
    ONGOING = "ongoing",
    PENDING = "pending",
    COMPLETE = "complete",
    CANCELLED = "cancelled"
}

/**
 * This component shows the service network screen
 * A list of all connected vendors and contracts
 * A job is created for a contract
 * The job is assigned to a vendor though filtering and sorting geofenced areas and services provided
 */
export const ServiceNetworkScreen = ({ navigation, route }: any) => {

    const { height, width } = useWindowDimensions();

    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //////// JOBS
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */

    const [Jobs, setJobs] = useState([] as Job[])
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null as any)
    const [showNewJobForVendorModal, setShowNewJobForVendorModal] = useState(false as boolean)
    const [selectedJob, setSelectedJob] = useState(null as Job | null)
    const [showJobDetailModal, setShowJobDetailModal] = useState(false as boolean)

    const [filteredJobs, setFilteredJobs] = useState([] as Job[]);
    const [selectedStatus, setSelectedStatus] = useState(null as JOB_STATUS | null);

    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //////// VENDORS
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */
    const [vendorProfiles, setVendorProfiles] = useState([] as VendorProfile[])
    const [selectedVendorProfile, setSelectedVendorProfile] = useState(null as VendorProfile | null)
    const [filteredVendorProfiles, setFilteredVendorProfiles] = useState([] as VendorProfile[])
    const [vendorsToDisplay, setVendorsToDisplay] = useState([] as VendorProfile[])
    const [filteredVendorProfile, setFilteredVendorProfile] = useState(null as VendorProfile | null);
    const [showSelectedVendorOptionsModal, setShowSelectedVendorOptionsModal] = useState(false)

    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //////// CONTRACTS
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */

    const [contracts, setContracts] = useState([] as CompanyContract[])
    const [selectedContract, setSelectedContract] = useState(null as CompanyContract | null)
    const [serviceTags, setServiceTags] = useState([] as string[])


    const [memberships, setMemberships] = useState([] as Member[])
    const [company, setCompany] = useState(null as Company | null)

    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //////// SERVICES
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */
    const [selectedService, setSelectedService] = useState(null as Service | null)
    const [filteredServices, setFilteredServices] = useState([] as string[])


    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //////// CASES
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */
    const [caseFilterApplied, setCaseFilterApplied] = useState(CASE_STATUS_FILTER.ONGOING)
    const [memoizedDrivers, setMemoizedDrivers] = useState([] as Member[])
    const [cases, setCases] = useState([] as Case[])
    const [showCaseDetailModal, setShowCaseDetailModal] = useState(false)



    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //////// EVENTS
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */
    const [registeredEvents, setRegisteredEvents] = useState(false)


    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //////// TABS
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */
    const [listPanelIndex, setListPanelIndex] = useState(LIST_TABS.JOBS)
    const [selectedTab, setSelectedTab] = useState(LIST_TABS.JOBS)


    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //////// INIT LOAD
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */

    useEffect(() => {
        setError(null)
        const unsubscribe = navigation.addListener('focus', () => {
            loadData()
            if (!registeredEvents) {

                setRegisteredEvents(true)
            }
        })
        return () => {

            unsubscribe()
        }
    }, [navigation])


    const loadData = async () => {
        try {
            setLoading(true)
            // const jobs = await JobsController.getJobs()
            const company = await CompanyController.getCompanyById(StorageController.getCurrentCompany()._id)
            if (!company) return
            const vendors = await UpdateController.updateVendorProfiles()
            // const vendors = await VendorProfileController.getVendorProfilesByCompanyId(StorageController.getAppState().selectedCompany._id)
            const jobs = await JobsController.getIncompleteJobsByClientCompanyId(company._id)
            onSetJobs(jobs)
            setContracts(company.settings?.contracts || [])
            setVendorProfiles(vendors)
            setCompany(company)
            setLoading(false)
        } catch (error) {
            setError(error)
            setLoading(false)
        }
        setLoading(false)
    }


    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //////// EVENTS
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */



    const handleCompanyChangeEvent = (action: UpdateController.IAction) => {
        if (action.type === "UPDATED_SELECTED_COMPANY") {
            loadData()
        }
    };

    const handleDriverLocationUpdatedEvent = (action: UpdateController.IAction) => {
        if (action.type === UpdateController.STATE_ACTIONS.UPDATED_DRIVER_LOCATION) {

        }
    };




    const jobsRef = useRef(Jobs);
    const onSetJobs = (jobs: Job[]) => {
        jobsRef.current = jobs;
        setJobs(jobs)
    }



    const handleJobUpdateEvent = useCallback((action: UpdateController.IAction) => {
        if (action.type === UpdateController.STATE_ACTIONS.UPDATED_JOBS) {
            if (!action.data.job) return
            const job = action.data.job
            if (job.client_company_id !== StorageController.getCurrentCompany()._id) return
            let _jobs = [...jobsRef.current]
            const index = _jobs.findIndex(j => j._id === job._id)
            if (index > -1) {
                _jobs[index] = job
                onSetJobs(_jobs)
            } else {
                onSetJobs([..._jobs, job])
            }
        }
    }, [jobsRef.current]);



    const onCreateJob = async (job: Job) => {
        try {
            setShowNewJobForVendorModal(false)
        }
        catch (e) {
        }
    }

    const { dispatchEventStateChange } = useAppStateChange({
        onUpdatedJobs: handleJobUpdateEvent,
        onUpdatedSelectedCompany: handleCompanyChangeEvent,
        onUpdatedDriverLocation: handleDriverLocationUpdatedEvent,
    })


    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //////// FILTERS
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */




    // Combined Filter Logic
    const filterJobs = useCallback((jobs: Job[]) => {
        let currentFilteredJobs = jobs;
        if (selectedContract) {
            currentFilteredJobs = filterJobsByContract(selectedContract, currentFilteredJobs);
        }
        if (filteredVendorProfile) {
            currentFilteredJobs = filterJobsByVendor(filteredVendorProfile, currentFilteredJobs);
        }
        if (selectedStatus) {
            currentFilteredJobs = filterJobsByStatus(selectedStatus, currentFilteredJobs);
        }
        return currentFilteredJobs;
    }, [selectedContract, filteredVendorProfile, selectedStatus]);

    // useEffect Hook for Filtering
    useEffect(() => {
        const updatedJobs = filterJobs(Jobs);
        setFilteredJobs(updatedJobs); // Update the state with the filtered jobs
    }, [Jobs, filterJobs]);


    // Filter Functions
    const filterJobsByVendor = (vendorProfile: VendorProfile, jobs: Job[]) => {
        return jobs?.filter((job: Job) =>
            vendorProfile.linkedVendorAccountId
                ? job.company_id === vendorProfile.linkedVendorAccountId && job.vendor_profile_id === vendorProfile._id
                : job.vendor_profile_id === vendorProfile._id
        );
    };

    const filterJobsByContract = (contract: CompanyContract, jobs: Job[]) => {
        return jobs.filter(job => job.contract_id === contract._id);
    };

    const filterJobsByStatus = (status: JOB_STATUS, jobs: Job[]) => {
        return jobs.filter(job => job.status === status);
    };

    const onFilterVendorProfile = (vendorProfile: VendorProfile | null) => {
        setFilteredVendorProfile(vendorProfile)
    }




    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //////// MODALS
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */



    const RenderSelectedVendorOptionsModal = () => {
        return (
            <Modal
                visible={showSelectedVendorOptionsModal}
                backdropStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
                onBackdropPress={() => setShowSelectedVendorOptionsModal(false)}
            >
                <Card disabled={true}>
                    <Text category="h6">{selectedVendorProfile && selectedVendorProfile.name} - Options</Text>
                    <Button
                        onPress={() => {
                            setShowSelectedVendorOptionsModal(false)
                            setShowNewJobForVendorModal(true)
                        }}
                    >
                        Create Job
                    </Button>
                </Card>
            </Modal>
        )
    }

    const onSelectedVendorProfile = (vendorProfile: VendorProfile) => {
        setSelectedVendorProfile(vendorProfile)
        setShowSelectedVendorOptionsModal(true)
    }



    const onSetFilteredVendorProfiles = (profiles: VendorProfile[]) => {
        setFilteredVendorProfiles(profiles)
    }

    const onSelectedJob = (job: Job) => {
        StorageController.appState.selectedJob = job
        JobsController.getJobById(job._id).then(res => {
            setSelectedJob(job)
            setShowJobDetailModal(true)
        })
    }


    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //////// SERVICES
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */

    // Filter Vendors based on selected contract and services tags
    const onSelectServices = (services: string[]) => {
        try {
            setFilteredServices(services)
            if (services.length == 0 && vendorProfiles.length > 0) {
                let vendors = vendorProfiles.filter(vendor => {
                    if (selectedContract && selectedContract.vendor_profiles) {
                        return selectedContract.vendor_profiles.includes(vendor._id)
                    }
                })
                setVendorsToDisplay(vendors)
                setFilteredVendorProfiles([])
            } else {
                let vendors = vendorProfiles.filter(vendorProfile => {
                    if (vendorProfile.services_provided) {
                        let result = false
                        vendorProfile.services_provided.forEach((service: string) => {
                            if (services.includes(service)) {
                                result = true
                            }
                        })
                        return result
                    } else {
                        return false
                    }
                })
                vendors.filter(vendor => {
                    if (selectedContract && selectedContract.vendor_profiles) {
                        return selectedContract.vendor_profiles.includes(vendor._id)
                    }
                })
                setVendorsToDisplay(vendors)
                setFilteredVendorProfiles(vendors)
            }
        } catch (e) {
        }
    }


    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //////// CONTRACTS
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */


    const onSelectedContract = (contract: CompanyContract) => {
        setSelectedContract(contract)
        // get the services for the contract
        setServiceTags(contract.service_tags)
        setFilteredServices([])
        if (vendorProfiles && vendorProfiles.length > 0) {
            let vendors = vendorProfiles.filter(vendor => {
                if (contract.vendor_profiles) {
                    return contract.vendor_profiles.includes(vendor._id)
                }
            })
            setVendorsToDisplay(vendors)
            setFilteredVendorProfiles([])
        }
    }

    const selectAllContracts = () => {
        setSelectedContract(null)
        setServiceTags([])
        setFilteredServices([])
        setVendorsToDisplay(vendorProfiles)
        setFilteredVendorProfiles([])
    }


    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //////// TABS
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */
    /*
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
//////// LIST PANEL TABS
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
*/


    const RenderListPanelTabs = () => {
        return (
            <View style={{ flexDirection: 'row', gap: 4, marginBottom: -3 }}>
                <Button
                    style={{ flex: 1 }}
                    onPress={() => { setListPanelIndex(LIST_TABS.JOBS) }}
                    appearance={listPanelIndex == LIST_TABS.JOBS ? "filled" : "outline"}
                    status={listPanelIndex == LIST_TABS.JOBS ? "success" : "basic"}
                >
                    JOBS
                </Button>
                <Button
                    style={{ flex: 1 }}
                    onPress={() => { setListPanelIndex(LIST_TABS.CREATE) }}
                    appearance={listPanelIndex == LIST_TABS.CREATE ? "filled" : "outline"}
                    status={listPanelIndex == LIST_TABS.CREATE ? "success" : "basic"}
                >
                    CREATE
                    <View style={{ position: 'absolute', top: 0, right: 0 }}>
                        {/* <RenderScheduledCountBadge /> */}
                    </View>
                </Button>
                <Button
                    style={{ flex: 1 }}
                    onPress={() => { setListPanelIndex(LIST_TABS.CASES) }}
                    appearance={listPanelIndex == LIST_TABS.CASES ? "filled" : "outline"}
                    status={listPanelIndex == LIST_TABS.CASES ? "success" : "basic"}
                >
                    CASES
                    <View style={{ position: 'absolute', top: 0, right: 0 }}>
                        {/* <RenderDriversOnlineCountBadge /> */}
                    </View>
                </Button>
            </View>
        )
    }

    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //////// Modals
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */


    const MemoizedJobDetailsModal = useMemo(() => {
        if (!selectedJob) return null
        return (<View
            style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 100 }}
        >
            <View
                style={{ position: 'absolute', top: '0%', right: '5%', left: '5%', bottom: '0%', zIndex: 100000 }}>
                <JobDetailContainer
                    jobViewedBy={"client"}
                    selectedJob={selectedJob}
                    fn_closeJobDetailsModal={() => { setShowJobDetailModal(false) }}
                />
            </View>
        </View>
        )
    }, [selectedJob])

    const MemoizedNewJobModal = useMemo(() => {
        if (!selectedVendorProfile) return null
        return (<View
            style={{ position: 'absolute', top: 5, left: 0, zIndex: 999, width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(0,0,0,0.5)', }}
        >
            <Layout
                style={{ width: width * 0.9, height: height * 0.9, borderRadius: 10, boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)', borderWidth: 1, borderColor: 'rgba(0, 0, 0, 0.5)', padding: 10, }}
            >
                <View style={{ position: 'absolute', right: 0, top: -20, zIndex: 999 }}>
                    <Button status="danger" onPress={() => setShowNewJobForVendorModal(false)}>
                        X
                    </Button>
                </View>
                <NewJobForVendorContainerScreen
                    vendorProfile={selectedVendorProfile}
                    contract={selectedContract}
                    onCreateJob={(job: Job) => { onCreateJob(job) }}
                />
            </Layout>
        </View>)
    }, [showNewJobForVendorModal, selectedVendorProfile])


    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //////// CASES
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */





    const CaseStatusFilterButtons = ({ setCaseFilterApplied, caseFilterApplied }: any) => {
        return (
            <View style={{ flexDirection: 'row', flex: 1, gap: 2, maxHeight: 40, paddingTop: 3 }}>
                <Button size='small' appearance={caseFilterApplied == CASE_STATUS_FILTER.ONGOING ? 'filled' : 'outline'} status='assigned' onPress={() => setCaseFilterApplied(CASE_STATUS_FILTER.ONGOING)}>Ongoing</Button>
                <Button size='small' appearance={caseFilterApplied == CASE_STATUS_FILTER.PENDING ? 'filled' : 'outline'} status='pending' onPress={() => setCaseFilterApplied(CASE_STATUS_FILTER.PENDING)}>Pending</Button>
                <Button size='small' appearance={caseFilterApplied == CASE_STATUS_FILTER.COMPLETE ? 'filled' : 'outline'} status='complete' onPress={() => setCaseFilterApplied(CASE_STATUS_FILTER.COMPLETE)}>Complete</Button>
                <Button size='small' appearance={caseFilterApplied == CASE_STATUS_FILTER.CANCELLED ? 'filled' : 'outline'} status='cancelled' onPress={() => setCaseFilterApplied(CASE_STATUS_FILTER.CANCELLED)}>Cancelled</Button>
            </View>
        )
    }

    const RenderMemoizedCasesList = useMemo(() => {
        if (!company) return null
        return (
            <CaseList
                var_viewedBy={StorageController.getAppState().selectedMembership?.is_client ? "local_client" : "vendor"}
                selectedCompany={company}
                caseFilter={caseFilterApplied}
                var_clients={[]}
                var_drivers={memoizedDrivers}
                fn_selectJob={onSelectedJob}
                fn_openAssign={() => { }}
                fn_zoomToMarker={() => { }}
                fn_setDirections={() => { }}
                fn_vendorAcceptJob={() => { }}
                fn_onCreateNewCaseJob={() => { }}
                fn_onCreateNewHolding={() => { }}
                var_vendorProfiles={vendorProfiles}
                fn_openJobReport={() => { }}
                fn_openCaseDetails={undefined} />
        )
    }, [company, caseFilterApplied, listPanelIndex])


    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //////// RENDER
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */

    return (
        <Layout style={{ flex: 1, flexDirection: 'column' }}>
            {selectedVendorProfile &&
                <RenderSelectedVendorOptionsModal />
            }
            {(selectedJob && showJobDetailModal) &&
                MemoizedJobDetailsModal
            }
            {showNewJobForVendorModal && (
                MemoizedNewJobModal
            )}
            {/* Contract selection buttons */}
            <View style={{ flexDirection: 'row' }}>

                <RenderContractFilterButtonList
                    contracts={contracts}
                    selectedContract={selectedContract}
                    onSelectedContract={onSelectedContract}
                    selectAllContracts={selectAllContracts}
                />
                <RenderVendorFilterButtonList
                    vendorsToDisplay={vendorsToDisplay}
                    filteredVendorProfile={filteredVendorProfile}
                    onFilterVendorProfile={onFilterVendorProfile}
                />
            </View>
            <Layout style={{ flex: 1, flexDirection: 'row' }}>
                <View style={{ flex: 4 }}>
                    <RenderListPanelTabs />
                    {listPanelIndex == LIST_TABS.JOBS &&
                        <View style={{ flex: 1 }}>
                            <View style={{ flex: 1 }}>
                                <RenderFilterButtons
                                    selectedStatus={selectedStatus}
                                    setSelectedStatus={setSelectedStatus}
                                />
                            </View>
                            <View style={{ flex: 15 }}>
                                {(Jobs && Jobs.length > 0) &&
                                    <JobsList
                                        // var_jobs={memoizedFilteredJobs}
                                        jobs={filteredJobs}
                                        var_viewedBy={"client"}
                                        var_vendorProfiles={vendorProfiles}
                                        clients={[]}
                                        drivers={[]}
                                        fn_selectJob={onSelectedJob}
                                        fn_openAssign={() => { }}
                                        fn_zoomToMarker={() => { }}
                                        fn_setDirections={() => { }}
                                        fn_onEndReached={() => { }}
                                        fn_vendorAcceptJob={() => { }}
                                        selectedJobId={null} />
                                }
                            </View>
                        </View>
                    }
                    {listPanelIndex == LIST_TABS.CREATE &&
                        <View style={{ flex: 1 }}>
                            <View style={{ flexDirection: 'row' }}>
                                <RenderServiceSelectionFilters
                                    serviceTags={serviceTags}
                                    filteredServices={filteredServices}
                                    onSelectServices={onSelectServices}
                                    setFilteredServices={setFilteredServices}
                                />
                                <RenderVendorSelectionFilters
                                    vendorsToDisplay={vendorsToDisplay}
                                    filteredVendorProfiles={filteredVendorProfiles}
                                    onSetFilteredVendorProfiles={onSetFilteredVendorProfiles}
                                />
                            </View>
                        </View>
                    }
                    {listPanelIndex == LIST_TABS.CASES &&
                        <View style={{ flex: 1 }}>
                            <CaseStatusFilterButtons
                                setCaseFilterApplied={setCaseFilterApplied}
                                caseFilterApplied={caseFilterApplied}
                            />
                            <View style={{ flex: 15 }}>
                                {RenderMemoizedCasesList}
                            </View>
                        </View>
                    }
                </View>
                <Layout style={{ flex: 6 }}>
                    {filteredVendorProfiles.length > 0 &&
                        <View style={{ position: "absolute", top: 0, left: 0, width: '20%', zIndex: 1000 }}>
                            <RenderListOfSelectedVendors
                                onSelectedVendorProfile={onSelectedVendorProfile}
                                vendors={filteredVendorProfiles}
                            />
                        </View>
                    }

                    <ErrorBoundary>
                        <ServiceNetworkMap
                            jobs={filteredJobs}
                            vendorProfiles={vendorProfiles}
                            filteredVendorProfiles={filteredVendorProfiles}
                            selectedVendorProfile={selectedVendorProfile}
                            contracts={contracts}
                            selectedContract={selectedContract}
                            onSelectedVendorProfile={onSelectedVendorProfile}
                        />
                    </ErrorBoundary>

                </Layout>
            </Layout>
        </Layout >
    )
}

interface IRenderFilterButtons {
    selectedStatus: JOB_STATUS | null
    setSelectedStatus: (status: JOB_STATUS | null) => void
}
const RenderFilterButtons = ({
    selectedStatus,
    setSelectedStatus
}: IRenderFilterButtons) => {
    return (
        <Layout style={{ flex: 1, flexDirection: 'row' }}>
            {/* Status filter buttons quote, request, unassigned, assigned, pending, complete, cancelled */}
            <View style={{ flexDirection: 'row', gap: 1 }}>
                <Button
                    style={{ flex: 1 }}
                    appearance={!selectedStatus ? 'filled' : 'outline'}
                    status='basic'
                    size="small"
                    onPress={() => { setSelectedStatus(null) }}
                >
                    All Status
                </Button>
                <Button
                    style={{ flex: 1 }}
                    appearance={selectedStatus && selectedStatus == 'quote' ? 'filled' : 'outline'}
                    status="quote"
                    size="small"
                    onPress={() => { setSelectedStatus(JOB_STATUS.QUOTE) }}
                >
                    Quote
                </Button>
                <Button
                    style={{ flex: 1 }}
                    appearance={selectedStatus && selectedStatus == 'request' ? 'filled' : 'outline'}
                    status="request"
                    size="small"
                    onPress={() => { setSelectedStatus(JOB_STATUS.REQUEST) }}
                >
                    Request
                </Button>
                <Button
                    style={{ flex: 1 }}
                    appearance={selectedStatus && selectedStatus == 'unassigned' ? 'filled' : 'outline'}
                    status="danger"
                    size="small"
                    onPress={() => { setSelectedStatus(JOB_STATUS.UNASSIGNED) }}
                >
                    Unassigned
                </Button>
                <Button
                    style={{ flex: 1 }}
                    appearance={selectedStatus && selectedStatus == 'assigned' ? 'filled' : 'outline'}
                    status="warning"
                    size="small"
                    onPress={() => { setSelectedStatus(JOB_STATUS.ASSIGNED) }}
                >
                    Assigned
                </Button>
                <Button
                    style={{ flex: 1 }}
                    appearance={selectedStatus && selectedStatus == 'pending' ? 'filled' : 'outline'}
                    status="info"
                    size="small"
                    onPress={() => { setSelectedStatus(JOB_STATUS.PENDING) }}
                >
                    Pending
                </Button>
                <Button
                    style={{ flex: 1 }}
                    appearance={selectedStatus && selectedStatus == 'complete' ? 'filled' : 'outline'}
                    status="success"
                    size="small"
                    onPress={() => { setSelectedStatus(JOB_STATUS.COMPLETE) }}
                >
                    Complete
                </Button>
                <Button
                    style={{ flex: 1 }}
                    appearance={selectedStatus && selectedStatus == 'cancelled' ? 'filled' : 'outline'}
                    status="purple"
                    size="small"
                    onPress={() => { setSelectedStatus(JOB_STATUS.COMPLETE) }}
                >
                    Cancelled
                </Button>
            </View>

        </Layout>)
}


interface IRenderVendorFilterButtonList {
    vendorsToDisplay: VendorProfile[]
    filteredVendorProfile: VendorProfile | null
    onFilterVendorProfile: (vendorProfile: VendorProfile | null) => void
}

const RenderVendorFilterButtonList = ({
    vendorsToDisplay,
    filteredVendorProfile,
    onFilterVendorProfile
}: IRenderVendorFilterButtonList) => {

    return (
        <Card
            style={{ flex: 1 }}
            status='primary'
            disabled
            header={() => { return (<Text category='label'>Vendors</Text>) }}
        >
            <Layout style={{ flexDirection: 'row', margin: -15 }}>
                {/* Vendor Filter buttons */}
                <Button
                    style={{ margin: 5 }}
                    appearance={!filteredVendorProfile ? 'filled' : 'outline'}
                    status={!filteredVendorProfile ? 'success' : 'primary'}
                    onPress={() => {
                        onFilterVendorProfile(null)
                    }}
                >
                    All Vendors
                </Button>
                {(vendorsToDisplay && vendorsToDisplay.length > 0) &&
                    <ErrorBoundary>
                        {
                            vendorsToDisplay.map((vendorProfile, index) => {
                                return (
                                    <Button
                                        key={index}
                                        size="small"
                                        style={{ margin: 5, maxWidth: 150, flex: 1 }}
                                        appearance={filteredVendorProfile && filteredVendorProfile._id == vendorProfile._id ? 'filled' : 'outline'}
                                        status={filteredVendorProfile && filteredVendorProfile._id == vendorProfile._id ? 'success' : 'basic'}
                                        onPress={() => {
                                            onFilterVendorProfile(vendorProfile)
                                        }}
                                    >
                                        <Text numberOfLines={1} ellipsizeMode='tail'>{vendorProfile.name}</Text>
                                    </Button>
                                )
                            }
                            )
                        }
                    </ErrorBoundary>
                }
            </Layout>
        </Card>
    )
}

interface IRenderContractFilterButtonList {
    contracts: CompanyContract[]
    selectedContract: CompanyContract | null
    onSelectedContract: (contract: CompanyContract) => void
    selectAllContracts: () => void
}

const RenderContractFilterButtonList = ({
    contracts,
    selectedContract,
    onSelectedContract,
    selectAllContracts
}: IRenderContractFilterButtonList) => {
    return (
        <Card
            style={{ flex: 1 }}
            status='success'
            disabled
            header={() => { return (<Text category='label'>Contracts</Text>) }}
        >
            <Layout style={{ flexDirection: 'row', margin: -15 }}>
                <Button
                    style={{ margin: 5 }}
                    appearance={!selectedContract ? 'filled' : 'outline'}
                    status={!selectedContract ? 'success' : 'primary'}
                    onPress={() => {
                        selectAllContracts()
                    }}
                >
                    All Contracts
                </Button>
                {(contracts && contracts.length > 0) &&
                    <ErrorBoundary>

                        {
                            contracts.map((contract, index) => {
                                return (
                                    <Button
                                        key={index}
                                        style={{ margin: 5, flex: 1, maxWidth: 200 }}
                                        size="small"
                                        appearance={selectedContract && selectedContract._id == contract._id ? 'filled' : 'outline'}
                                        status={selectedContract && selectedContract._id == contract._id ? 'success' : 'basic'}
                                        onPress={() => {
                                            onSelectedContract(contract)
                                        }}
                                    >
                                        {contract.name}
                                    </Button>
                                )
                            })
                        }
                    </ErrorBoundary>
                }
            </Layout>
        </Card>
    )
}


interface IRenderServiceVendorSelectionFilters {
    serviceTags: string[]
    filteredServices: string[]
    onSelectServices: (services: string[]) => void
    setFilteredServices: (services: string[]) => void
}

const RenderServiceSelectionFilters = ({
    serviceTags,
    filteredServices,
    onSelectServices,
    setFilteredServices
}: IRenderServiceVendorSelectionFilters) => {
    return (
        <Card
            style={{ flex: 1 }}
            status='basic'
            disabled
            header={() => { return (<Text category='label'>Services</Text>) }}
        >
            <View style={{ margin: -15 }}>

                {/* List services to display */}
                <View style={{ flexDirection: 'row' }}>
                    <Button size='tiny' onPress={() => { setFilteredServices([]) }}>Clear</Button>
                </View>
                <ErrorBoundary>
                    <List
                        data={serviceTags}
                        renderItem={({ item, index }: { item: string, index: number }) => {
                            return (
                                <ListItem
                                    title={item}
                                    onPress={() => {
                                        if (filteredServices.includes(item)) {
                                            onSelectServices(filteredServices.filter(service => service !== item))
                                        } else {
                                            onSelectServices([...filteredServices, item])
                                        }
                                    }}
                                    accessoryLeft={() => {
                                        return (
                                            <CheckBox
                                                checked={filteredServices.includes(item)}
                                                onChange={() => {
                                                    if (filteredServices.includes(item)) {
                                                        onSelectServices(filteredServices.filter(service => service !== item))
                                                    } else {
                                                        onSelectServices([...filteredServices, item])
                                                    }
                                                }}
                                            />
                                        )
                                    }}
                                />
                            )
                        }}
                    />
                </ErrorBoundary>
            </View>
        </Card>
    )
}

interface IRenderVendorSelectionFiltersProps {
    vendorsToDisplay: VendorProfile[]
    filteredVendorProfiles: VendorProfile[]
    onSetFilteredVendorProfiles: (profiles: VendorProfile[]) => void
}

const RenderVendorSelectionFilters = ({
    vendorsToDisplay,
    filteredVendorProfiles,
    onSetFilteredVendorProfiles
}: IRenderVendorSelectionFiltersProps) => {
    return (
        <Card
            style={{ flex: 1 }}
            status='basic'
            disabled
            header={() => { return (<Text category='label'>Vendors</Text>) }}
        >
            <View style={{ flex: 1, margin: -15 }}>
                <View style={{ flexDirection: 'row' }}>
                    <Button size='tiny' onPress={() => { onSetFilteredVendorProfiles([]) }}>Clear</Button>
                </View>
                <ErrorBoundary>
                    <List
                        data={vendorsToDisplay}
                        renderItem={({ item, index }: { item: VendorProfile, index: number }) => {
                            return (
                                <ListItem
                                    title={item.name}
                                    // description={item.details}
                                    onPress={() => {
                                        if (filteredVendorProfiles.includes(item)) {
                                            onSetFilteredVendorProfiles(filteredVendorProfiles.filter(vendorProfile => vendorProfile !== item))
                                        } else {
                                            onSetFilteredVendorProfiles([...filteredVendorProfiles, item])
                                        }
                                    }}
                                    accessoryLeft={() => {
                                        return (
                                            <CheckBox
                                                checked={filteredVendorProfiles.includes(item)}
                                                onChange={() => {
                                                    if (filteredVendorProfiles.includes(item)) {
                                                        onSetFilteredVendorProfiles(filteredVendorProfiles.filter(vendorProfile => vendorProfile !== item))
                                                    } else {
                                                        onSetFilteredVendorProfiles([...filteredVendorProfiles, item])
                                                    }
                                                }}
                                            />
                                        )
                                    }}
                                />
                            )
                        }}
                    />
                </ErrorBoundary>
            </View>
        </Card>
    )
}


interface IRenderListOfSelectedVendors {
    vendors: VendorProfile[]
    onSelectedVendorProfile?: (vendorProfile: VendorProfile) => void
}

const RenderListOfSelectedVendors = ({ vendors, onSelectedVendorProfile }: IRenderListOfSelectedVendors) => {
    return (
        <Card
            style={{ flex: 1 }}
            status='warning'
            disabled
            header={() => { return (<Text category='label'>Selected Vendors</Text>) }}
        >
            <View style={{ flexDirection: 'column', gap: 4, margin: -15 }}>
                {vendors.map((vendor, index) => {
                    return (
                        <Card
                            key={index}
                            style={{ flex: 1 }}
                            status='basic'
                            disabled
                            header={() => { return (<Text category='label'>{vendor.name}</Text>) }}
                        >
                            <Text>{vendor.details}</Text>
                            <Button
                                onPress={() => {
                                    if (onSelectedVendorProfile) {
                                        onSelectedVendorProfile(vendor)
                                        console.log("🚀============== ~ file: serviceNetworkScreen.component.tsx:1038 ~ {vendors.map ~ vendor🚀==============", vendor)
                                    }
                                }}
                            >
                                Options
                            </Button>
                        </Card>
                    )
                })}
            </View>
        </Card>
    )
}