import React, { useState, useEffect, useReducer, useRef } from 'react';
//@ts-ignore
import { StyleSheet, Text as RNText, Platform, useWindowDimensions, Grid, Alert, View } from "react-native";
//@ts-ignore
import { Button, Icon, Layout, Card, Input, Modal, Text, CheckBox, Toggle, Select, SelectItem, IndexPath, Divider } from "@ui-kitten/components";


import { Member, MemberPermissions } from '../../models/Member.model';
import * as StorageController from '../../functions/storageController';
import * as UpdateController from '../../functions/update.controller';
import * as MemberController from '../../functions/membership.controller';
import * as Utils from '../../functions/utils';
import { DeviceDetails } from '../../models/DeviceDetails.model';

export const MemberDeviceInfoCard = ({ member }: { member: Member }) => {
    const systemInfo = StorageController.getAppState().systemInfo
    const androidInfo = systemInfo?.android_info || {}
    const iosInfo = systemInfo?.ios_info || {}
    const webInfo = systemInfo?.web_info || {}
    const otherInfo = systemInfo?.other || {}

    const usersAppVersion = member.user?.details?.device?.app_version || 0
    const systemAndroidAppVersion = androidInfo.version_code
    const versionsMatch = usersAppVersion >= systemAndroidAppVersion
    const [device, setDevice] = useState<DeviceDetails | null>(member.user?.details?.device || null)


    const getDeviceType = (device: DeviceDetails) => {
        if (!device) return "web"
        if (device.name == "iPhone") {
            return "ios"
        }
        else if (device?.system_version && device?.system_version != "undefined") {
            return "android"
        }
        else {
            return "web"
        }
    }

    useEffect(() => {
        // console.log("MemberDeviceInfoCard: ", member)
        let _device = member.user?.details?.device
        if (member?.details?.device_details) {
            _device = member.details.device_details
            setDevice(_device as DeviceDetails)
        }
        else {
            setDevice(_device as DeviceDetails)
        }
    }, [member])

    const CorrectIcon = (props: any) => <Icon {...props} name="checkmark-circle-2-outline" fill="green" width={24} height={24} />
    const IncorrectIcon = (props: any) => <Icon {...props} name="close-circle-outline" fill="red" width={24} height={24} />
    const RenderAppVersion = () => {
        if (androidInfo?.version_code) {
            return (
                <View style={{ flexDirection: "row", justifyContent: 'space-between' }}>
                    <Text category="s1">App Version:</Text>
                    <View style={{ flexDirection: 'row', backgroundColor: versionsMatch ? 'green' : "red" }}>
                        <Text>
                            {device?.app_version}
                        </Text>
                        {androidInfo?.version_code &&
                            <Text>
                                /{androidInfo?.version_code}
                            </Text>
                        }
                        {!versionsMatch &&
                            <View style={{ flexDirection: "row" }}>
                                <Icon name="alert-circle-outline" fill="white" width={24} height={24} />
                                <Text style={{ fontSize: 10 }}>Update Required</Text>
                            </View>
                        }
                    </View>
                </View>
            )
        }
        else {
            return null
        }
    }

    const RenderNativeDeviceInfo = () => {
        return (
            <View style={{ flexDirection: "column" }}>
                <View style={{ flexDirection: "row", justifyContent: 'space-between' }}>
                    <Text category="s1">Location Permissions:</Text>
                    <Text style={{ color: 'white' }}>
                        {device?.location_enabled ? <CorrectIcon /> : <IncorrectIcon />}
                        {device?.location_enabled}
                    </Text>
                </View>
                <View style={{ flexDirection: "row", justifyContent: 'space-between' }}>
                    <Text category="s1">Location Background:</Text>
                    <Text>
                        {device?.location_background_permission?.granted ? <CorrectIcon /> : <IncorrectIcon />}
                    </Text>
                </View>
                <View style={{ flexDirection: "row", justifyContent: 'space-between' }}>
                    <Text category="s1">Location Foreground:</Text>
                    <Text>
                        {device?.location_permission?.granted ? <CorrectIcon /> : <IncorrectIcon />}
                    </Text>
                </View>
                <Divider />
                <View style={{ flexDirection: "row", justifyContent: 'space-between' }}>
                    <Text category="s1">Battery Permissions:</Text>
                    <Text>
                        {device?.battery_enabled ? <CorrectIcon /> : <IncorrectIcon />}
                    </Text>
                </View>
                <View style={{ flexDirection: "row", justifyContent: 'space-between' }}>
                    <Text category="s1">Battery Level:</Text>
                    <Text>
                        {/* @ts-ignore */}
                        {(device?.battery_level * 100).toFixed(2)}%
                    </Text>
                </View>
                <View style={{ flexDirection: "row", justifyContent: 'space-between' }}>
                    <Text category="s1">Battery Optimisation:</Text>
                    <Text>
                        {(device?.battery_optimization_enabled == false) ? <CorrectIcon /> : <IncorrectIcon />}
                    </Text>
                </View>
                <View style={{ flexDirection: "row", justifyContent: 'space-between' }}>
                    <Text category="s1">Battery On Low Power:</Text>
                    <Text>
                        {device?.battery_low_power_mode ? "Yes" : "No"}
                    </Text>
                </View>
                <Divider />
                {usersAppVersion && usersAppVersion >= 72 &&
                    <View style={{ flexDirection: 'column' }}>
                        <View style={{ marginTop: 3, flexDirection: "row", justifyContent: 'space-between' }}>
                            <Text category="s1">Camera Permissions:</Text>
                            <Text>
                                {device?.camera_permission?.granted ? <CorrectIcon /> : <IncorrectIcon />}
                            </Text>
                        </View>
                        <View style={{ flexDirection: "row", justifyContent: 'space-between' }}>
                            <Text category="s1">Media Library:</Text>
                            <Text>
                                {device?.media_library_permission?.granted ? <CorrectIcon /> : <IncorrectIcon />}
                            </Text>
                        </View>
                    </View>
                }
                <Divider />
                <RenderAppVersion />
                <View style={{ flexDirection: "row", justifyContent: 'space-between' }}>
                    <Text category="s1">Device Type:</Text>
                    <Text>
                        {device?.name}
                    </Text>
                </View>
                {getDeviceType(device as DeviceDetails) == "android" &&
                    <View style={{ flexDirection: "row", justifyContent: 'space-between' }}>
                        <Text category="s1">Device OS:</Text>
                        <Text>
                            {device?.system_version}
                        </Text>
                    </View>
                }
                {getDeviceType(device as DeviceDetails) == "ios" &&
                    <View style={{ flexDirection: "row", justifyContent: 'space-between' }}>
                        <Text category="s1">Device OS:</Text>
                        <Text>
                            {device?.device_id}
                        </Text>
                    </View>
                }
            </View>
        )
    }

    const RenderCoords = () => {
        const lat = member.details?.location?.coords.latitude || 0
        const lon = member.details?.location?.coords.longitude || 0
        return (
            <View style={{ flexDirection: "row", justifyContent: 'space-between' }}>
                <Text style={{ fontSize: 10 }}>
                    {lat.toFixed(5)},{" "}
                    {lon.toFixed(5)}
                </Text>
            </View>
        )
    }




    return (
        <Card
            disabled={true}
            header={() =>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <View style={{ flexDirection: "row", justifyContent: 'space-between' }}>
                        <Text category="s1">{member.name}</Text>
                    </View>
                </View>
            } status="primary">
            <View style={{ margin: -10 }}>
                <View style={{ flexDirection: "row", justifyContent: 'space-between' }}>
                    <Text category="s1">Updated:</Text>
                    <Text category="s1">
                        {Utils.formatTimestampWithSeconds(member.details?.location?.timestamp || 0)}
                        {/* {new Date(member.details?.location?.timestamp).toLocaleString()} */}
                    </Text>
                </View>
                <Divider />
                <View style={{ flexDirection: "row", justifyContent: 'space-between' }}>
                    <Text category="s1">Driver ID:</Text>
                    <Text>
                        {member._id}
                    </Text>
                </View>
                <View style={{ flexDirection: "row", justifyContent: 'space-between' }}>
                    <Text category="s1">Driver Status:</Text>
                    <Text>{member.status}</Text>
                </View>
                <View style={{ flexDirection: "row", justifyContent: 'space-between' }}>
                    <Text category="s1">Driver Location:</Text>
                    <RenderCoords />
                </View>
                <View style={{ flexDirection: "row", justifyContent: 'space-between' }}>
                    <Text category="s1">Driver Heading:</Text>
                    <Text>
                        {member.details?.location?.coords?.heading?.toFixed(2)}°
                    </Text>
                </View>
                {getDeviceType(device as DeviceDetails) == "web" ?
                    <View>
                        <View style={{ flexDirection: "row", justifyContent: 'space-between' }}>
                            <Text category="s1">Device Type:</Text>
                            <Text>
                                {device?.name} | WEB
                            </Text>
                        </View>
                    </View>
                    :
                    <RenderNativeDeviceInfo />
                }

            </View>
        </Card>
    )
}