import React, { useState, useEffect, useRef, useReducer, useMemo, useCallback } from 'react';
//@ts-ignore
import { StyleSheet, View, ScrollView } from 'react-native';
import {
    ApplicationProvider,
    Button,
    Icon,
    IconRegistry,
    Input,
    Select,
    SelectItem,
    useTheme,
    Layout,
    Text,
    List,
    ListItem,
    OverflowMenu,
    MenuItem,
    Card,
    Toggle,
    Radio,
    RadioGroup,
    Modal
} from "@ui-kitten/components";
import { GoogleMap, useJsApiLoader, Marker, InfoWindow, TransitLayer, DirectionsService, DirectionsRenderer, TrafficLayer, MarkerClusterer, Polygon } from '@react-google-maps/api';
import * as UpdateController from '../../functions/update.controller';
import * as UserController from '../../functions/user.controller';
import * as StorageController from "../../functions/storageController"
import * as MessageController from "../../functions/message.controller"
import * as GoogleController from "../../functions/google.controller"
import * as JobsController from '../../functions/job.controller'
import ErrorBoundary from '../ErrorBoundary.component';
import _ from 'lodash'
//@ts-ignore
import debounce from 'lodash.debounce';
import Toast from 'react-native-toast-message';


function useDebounce(callback: any, delay: number) {
    const debouncedFn = useCallback(
        debounce((...args: any) => callback(...args), delay),
        [delay] // will recreate if delay changes
    );
    return debouncedFn;
}

interface DistanceToolProps {
    isLoaded: boolean,
    onOriginChange: Function,
    onDestinationChange: Function,
    onSetMapDirections: Function,
    onClear: Function,
    onClose: Function,
    onEnableSelectPoint: Function,
    onDisableSelectPoint: Function,
    selectedPoint: any
}


export default function DistanceTool({
    isLoaded,
    onOriginChange,
    onDestinationChange,
    onSetMapDirections,
    onClear,
    onClose,
    onEnableSelectPoint,
    onDisableSelectPoint,
    selectedPoint
}: DistanceToolProps) {


    //================================================
    // Search states
    //=====================================================
    //=====================================================
    const [distance, setDistance] = useState(null as any);
    const [duration, setDuration] = useState(null as any);
    const [originOptions, setOriginOptions] = useState([] as any);
    const [destinationOptions, setDestinationOptions] = useState([] as any);
    const [originMenuVisible, setOriginMenuVisible] = useState(false);
    const [destinationMenuVisible, setDestinationMenuVisible] = useState(false);
    const [originSearch, setOriginSearch] = useState({ term: '', fetchPredictions: false } as any);
    const [destinationSearch, setDestinationSearch] = useState({ term: '', fetchPredictions: false } as any);
    const [originLocation, setOriginLocation] = useState(null as any);
    const [destinationLocation, setDestinationLocation] = useState(null as any);
    const [coveredDistance, setCoveredDistance] = useState(0 as any);
    const [costPerKm, setCostPerKm] = useState(0 as any);
    const [costResult, setCostResult] = useState(0 as any);

    const [selectingPoint, setSelectingPoint] = useState("none") // origin, destination, none

    const enableSelectOrigin = () => {
        setSelectingPoint("origin")
        onEnableSelectPoint()
    }

    const enableSelectDestination = () => {
        setSelectingPoint("destination")
        onEnableSelectPoint()
    }

    useEffect(() => {
        if (selectedPoint) {
            if (selectingPoint === "origin") {
                onSetOriginLocation(selectedPoint)
                setOriginSearch({ term: selectedPoint.lat + "," + selectedPoint.lng })
            }
            if (selectingPoint === "destination") {
                onSetDestinationLocation(selectedPoint)
                setDestinationSearch({ term: selectedPoint.lat + "," + selectedPoint.lng })
            }
            setSelectingPoint("none")
            onDisableSelectPoint()
        }
    }, [selectedPoint])

    const getPredictions = async (search: string) => {
        const res = await GoogleController.getpredictionsAutocomplete(search);
        if (!res) {
            return []
        }
        return res.predictions;
    }

    const originDebounce = useDebounce((text: string) => {
        getPredictions(text).then(res => {
            setOriginOptions(res);
            console.log("🚀 ~ file: newJob.component.js:850 ~ getPredictions ~ res:", res)
            setOriginMenuVisible(true);
        });
    }, 500);

    const destinationDebounce = useDebounce((text: string) => {
        getPredictions(text).then(res => {
            setDestinationOptions(res);
            setDestinationMenuVisible(true);
        });
    }, 500);

    const clearAll = () => {
        setOriginSearch({ term: '', fetchPredictions: false })
        setDestinationSearch({ term: '', fetchPredictions: false })
        setOriginOptions([])
        setDestinationOptions([])
        setOriginMenuVisible(false)
        setDestinationMenuVisible(false)
        onSetOriginLocation(null)
        onSetDestinationLocation(null)
        setDistance(null)
        setDuration(null)
        setCostResult(null)
        onClear()
    }

    const onSetOriginLocation = (location: any) => {
        setOriginLocation(location)
        onOriginChange(location)
    }

    const onSetDestinationLocation = (location: any) => {
        setDestinationLocation(location)
        onDestinationChange(location)
    }



    const originInput = () => {
        return (
            <Input
                placeholder='Search Address'
                size='small'
                label='Search Address'
                value={originSearch.term}
                onChangeText={(text: string) => {
                    setOriginSearch({ term: text, fetchPredictions: true })
                    originDebounce(text);
                }}
            />
        )
    }

    const destinationInput = () => {
        return (
            <Input
                placeholder='Search Address'
                size='small'
                label='Search Address'
                value={destinationSearch.term}
                onChangeText={(text: string) => {
                    setDestinationSearch({ term: text, fetchPredictions: true })
                    destinationDebounce(text);
                }}
            />
        )
    }

    const clickedPrediction = (event: any, type: any) => {
        console.log("clicked")
        console.log(event)
        if (type === 'origin') {
            setOriginSearch({ term: event.description })
            setOriginMenuVisible(false);
            GoogleController.getPredictionsPlace(event.place_id).then(res => {
                console.log(res)
                res.result.address_components
                onSetOriginLocation({ lat: res.result.geometry.location.lat, lng: res.result.geometry.location.lng })
            })
        } else {
            setDestinationSearch({ term: event.description })
            setDestinationMenuVisible(false);
            GoogleController.getPredictionsPlace(event.place_id).then(res => {
                console.log(res)
                res.result.address_components
                onSetDestinationLocation({ lat: res.result.geometry.location.lat, lng: res.result.geometry.location.lng })
                GoogleController.sortAddress(res.result.address_components).then((res: any) => {
                    console.log(res)
                })
            })
        }
    }

    const getDirections = async () => {
        console.log("get directions", originLocation, destinationLocation)
        if (!originLocation || !destinationLocation) {
            return
        }
        let origin = new google.maps.LatLng(originLocation.lat, originLocation.lng)
        let destination = new google.maps.LatLng(destinationLocation.lat, destinationLocation.lng)
        let directionService = new google.maps.DirectionsService()
        let directionsRequest = {
            origin: origin,
            destination: destination,
            travelMode: google.maps.TravelMode.DRIVING,
            unitSystem: google.maps.UnitSystem.METRIC
        }
        // make that async
        const directions = await directionService.route(directionsRequest, (result, status) => {
            console.log(result)
            console.log(status)
            if (result && status == google.maps.DirectionsStatus.OK) {
                let distVal = result.routes[0].legs[0].distance?.value
                let distTxt = result.routes[0].legs[0].distance?.text
                const distStr = `${distTxt} (${distVal} m)`
                console.log(result.routes[0].legs[0].distance?.text)
                console.log(result.routes[0].legs[0].duration?.text)
                setDistance(distStr)
                setDuration(result.routes[0].legs[0].duration?.text)
                onSetMapDirections(result)
                console.log("🚀 ~ file: distanceTool.component.js:215 ~ directions ~ result:", result)
                return result
            } else {
                console.log("error")
            }
        }
        )
        return directions
    }

    const calculateResult = async () => {
        // get directions
        try {

            const directions = await getDirections()
            if (!directions) return
            let dist = directions.routes[0].legs[0].distance?.value || 1
            dist = dist / 1000 // convert to km
            // offset distance by covered distance
            const offsetDistance = dist - Number(coveredDistance)

            // calculate cost
            let costStr = ""
            const cost = offsetDistance * Number(costPerKm)
            if (cost < 0) {
                costStr += "0.00"
            } else {
                costStr += cost.toFixed(2)
            }
            // set cost result
            setCostResult(`$${costStr}`)
        } catch (e) {
            console.log(e)
        }
    }
    const RenderCardHeader = ({ value }: any) => {
        return (
            <View
                style={{ flexDirection: 'row', justifyContent: 'space-between' }}>

                <Text
                    style={{
                        fontSize: 14,
                        fontWeight: 'bold',
                    }}
                >{value}
                </Text>
                {selectingPoint !== "none" &&
                    <Text
                        style={{
                            fontSize: 14,
                            fontWeight: 'bold',
                            color: 'yellow'
                        }}
                    >Selecting {selectingPoint} - Please Click On Map
                    </Text>

                }
                <Button
                    appearance='outline'
                    status='danger'
                    onPress={() => onClose()}
                >
                    X
                </Button>
            </View>
        )
    }

    return (
        <Layout style={{ flexDirection: 'row' }}>
            <Card
                style={{
                    margin: 10,
                    flex: 1,
                    // backgroundColor: '#252e56',
                }}
                status='info'
                header={() => <RenderCardHeader value='Distance Quote Tool' />}
            >
                <View style={{ flexDirection: 'row' }}>


                    {!isLoaded && <Text>Loading...</Text>}
                    <View style={{ flex: 2, flexDirection: 'column', margin: 5, alignContent: 'space-between' }}>
                        <View style={{ flexDirection: 'row', alignItems: 'flex-end' }}>
                            <Button
                                style={{ height: 30, width: 30 }}
                                appearance='outline'
                                status='info'
                                size='tiny'
                                onPress={() => enableSelectOrigin()}
                            >
                                <Icon name='pin-outline' width={24} height={24} fill="white" />
                            </Button>
                            <OverflowMenu
                                visible={originMenuVisible}
                                anchor={originInput}
                                onBackdropPress={() => setOriginMenuVisible(false)}
                            >
                                {originOptions && originOptions.map((option: google.maps.places.AutocompletePrediction, index: number) =>
                                    <MenuItem
                                        key={index}
                                        title={option.description}
                                        onPress={() => {
                                            clickedPrediction(option, 'origin');
                                        }}
                                    />
                                )}
                            </OverflowMenu>

                        </View>
                        <View style={{ flexDirection: 'row', alignItems: 'flex-end' }}>
                            <Button
                                style={{ height: 30, width: 30 }}
                                appearance='outline'
                                status='info'
                                size='tiny'
                                onPress={() => enableSelectDestination()}
                            >
                                <Icon name='pin-outline' width={24} height={24} fill="white" />
                            </Button>
                            <OverflowMenu
                                visible={destinationMenuVisible}
                                anchor={destinationInput}
                                onBackdropPress={() => setDestinationMenuVisible(false)}
                            >
                                {destinationOptions && destinationOptions.map((option: google.maps.places.AutocompletePrediction, index: number) =>
                                    <MenuItem
                                        key={index}
                                        title={option.description}
                                        onPress={() => {
                                            clickedPrediction(option, 'destination');
                                        }}
                                    />
                                )}
                            </OverflowMenu>

                        </View>

                    </View>
                    <View style={{ flex: 2, flexDirection: 'column', margin: 5, justifyContent: 'space-between' }}>
                        <Input
                            placeholder='Covered Distance (Km)'
                            size='small'
                            label='Covered Distance (Km)'
                            value={coveredDistance}
                            onChangeText={(text: string) => {
                                if (!isNaN(Number(text)) || text.includes('.')) {
                                    if (text.startsWith('.')) {
                                        text = '0' + text;
                                    }
                                    setCoveredDistance(text)
                                }
                            }}
                        />
                        <Input
                            placeholder='Cost Per Km'
                            size='small'
                            label='Extra Cost Per Km Over($)'
                            value={costPerKm}
                            onChangeText={(text: string) => {
                                if (!isNaN(Number(text)) || text.includes('.')) {
                                    if (text.startsWith('.')) {
                                        text = '0' + text;
                                    }
                                    setCostPerKm(text)
                                }
                            }}
                        />
                    </View>

                    <View style={{ flex: 1, flexDirection: 'column', margin: 5, justifyContent: 'flex-end' }}>
                        {/* <Text>Origin: {originSearch.term}</Text>
                        <Text>Destination: {destinationSearch.term}</Text> */}
                        <Button
                            appearance='outline'
                            status='success'
                            size='small'
                            style={{ flex: 1 }}
                            onPress={() => calculateResult()}
                        >
                            Calculate
                            <Icon name='cube-outline' width={24} height={24} fill="white" />
                        </Button>
                    </View>
                    <Card
                        header={() =>
                            <Text
                                style={{
                                    fontSize: 14,
                                    fontWeight: 'bold',
                                }}
                            >Result
                            </Text>
                        }
                        style={{
                            flex: 2,
                            flexDirection: 'column',
                            // backgroundColor: '#252e56',
                            margin: 5
                        }}>
                        {distance ? <Text>Distance: {distance}</Text> : null}
                        {duration ? <Text>Duration: {duration}</Text> : null}
                        {costResult ? <Text>Cost: {costResult}</Text> : null}
                        {distance &&
                            <Button
                                appearance='outline'
                                status='danger'
                                size='small'
                                onPress={() => clearAll()}
                            >
                                Clear
                                <Icon name='trash-2-outline' width={24} height={24} fill="white" />
                            </Button>
                        }
                    </Card>


                </View>
            </Card>
        </Layout>
    );



}