import React, { useState, useEffect, useReducer, useRef } from 'react';
//@ts-ignore
import { StyleSheet, Text as RNText, Platform, useWindowDimensions, Grid, View, Image } from "react-native";
//@ts-ignore
import { Button, Icon, Layout, Card, Input, Modal, Text, CheckBox, Toggle, Avatar } from "@ui-kitten/components";

import * as MemberController from "../../functions/membership.controller";
import * as StorageController from "../../functions/storageController";
import * as UserController from "../../functions/user.controller";
import { User } from '../../models/User.model';
import Toast from 'react-native-toast-message';
import { set } from 'lodash';
// import the qrcode image
import QRCode from "react-qr-code";




export const UserProfileScreen = ({ navigation }: any) => {

    const [ignored, forceUpdate] = useReducer(x => x + 1, 0);
    const [user, setUser] = useState(null as User | null)
    const [name, setName] = useState(null as string | null);
    const [email, setEmail] = useState(null as string | null);
    const [phone, setPhone] = useState(null as string | null);



    const [oldPassword, setOldPassword] = useState(''); // [password, setPassword
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const [userSaveError, setUserSaveError] = useState('');
    const [userSaveSuccess, setUserSaveSuccess] = useState('');

    const [showMFAQrCode, setShowMFAQrCode] = useState(false);
    const [mfaCode, setMfaCode] = useState('');
    const [mfaImage, setMfaImage] = useState('');
    const [hasMFA, setHasMFA] = useState(false);

    const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);


    useEffect(() => {
        return navigation.addListener('focus', () => {
            UserController.getUser(StorageController.appState.user._id).then((user) => {
                setUser(user);
                setName(user.name);
                setEmail(user.email);
                setPhone(user.phone);
            });
        });

    }, [])

    const saveUser = () => {
        if (!name || !email || !phone || !user) {
            setUserSaveError('Please fill out all fields');
            return;
        }
        UserController.saveUser(user._id, name, email, phone).then((response) => {
            if (response.success) {
                StorageController.getAppState().user.name = name as string;
                StorageController.getAppState().user.email = email as string;
                StorageController.getAppState().user.phone = phone as string;
                StorageController.saveStateToDisk();
                setUserSaveSuccess('User details saved successfully');
                setUserSaveError("");
                forceUpdate();
            } else {
                setUserSaveError(response.message);
                setError(response.message);
            }
        })
    }

    const handleChangePassword = () => {
        if (password !== confirmPassword) {
            setError('Passwords do not match');
        } else {
            UserController.changePassword(
                StorageController.appState.user._id,
                oldPassword,
                password
            ).then((response) => {
                if (response.success) {
                    setSuccess('Password changed successfully');
                    setError("")
                    setPassword('');
                    setOldPassword('');
                    setConfirmPassword('');
                    forceUpdate();
                } else {
                    setError(response.message);
                }
            })
        }
    }

    const generateMFAQRCode = () => {
        if (!user) return;
        UserController.setupMfa(user?._id, user?.username).then((response) => {
            if (response) {
                setUser({ ...user, mfa_enabled: true } as User);
                console.log("🚀============== ~ file: userProfileScreen.component.tsx:108 ~ UserController.setupMfa ~ response🚀==============", response)
                setShowMFAQrCode(true);
                setMfaImage(response.qrCodeUrl || "");
                setMfaCode(response.secret);
            }
        })
    }

    const toggleMFA = () => {
        if (!user) return;
        if (user.mfa_enabled) {
            UserController.disableMfa(user?._id).then((response) => {
                if (response) {
                    setUser({ ...user, mfa_enabled: false } as User);
                    setShowMFAQrCode(false);
                    setMfaImage('');
                    setMfaCode('');
                }
            })
        } else {
            generateMFAQRCode();
        }
    }




    return (
        <Layout style={{ flexDirection: 'column', flex: 1 }}>
            <>
                {user &&
                    <View style={{ flexDirection: 'column' }}>

                        <Layout style={{ flex: 1, flexDirection: 'row' }}>
                            <ChangePasswordModal
                                visible={showChangePasswordModal}
                                setVisible={setShowChangePasswordModal}
                                user={user}
                            />
                            <Layout style={{ flex: 1 }}>
                                {/* Spacer */}
                            </Layout>
                            <View style={{ flex: 3 }}>
                                <Card
                                    header={() => <Text>Edit User Details</Text>}
                                    style={{ flex: 1 }}
                                    status="basic"
                                >
                                    <Layout style={{ flex: 1 }}>
                                        <Layout>
                                            <Text category='label'>Name:</Text>
                                            <Input defaultValue={name} onChangeText={setName} />
                                        </Layout>
                                        <Layout>
                                            <Text category='label'>Email:</Text>
                                            <Input defaultValue={email} onChangeText={setEmail} />
                                        </Layout>
                                        <Layout>
                                            <Text category='label'>Phone:</Text>
                                            <Input defaultValue={phone} onChangeText={setPhone} />
                                        </Layout>
                                        <Text status="danger">{userSaveError}</Text>
                                        <Text>{userSaveSuccess}</Text>
                                        <Button onPress={() => { saveUser() }}>
                                            <Text>Save</Text>
                                        </Button>
                                    </Layout>
                                </Card>
                            </View>
                            <Layout style={{ flex: 1 }}>
                                {/* Spacer */}
                            </Layout>
                            <Layout style={{ flex: 3, justifyContent: 'left' }}>
                                <Card
                                    disabled
                                    header={() => <Text>Change Password</Text>}
                                    status="warning"
                                >
                                    <Button
                                        onPress={() => setShowChangePasswordModal(true)}
                                    >
                                        Change Password
                                    </Button>
                                </Card>
                                <Card
                                    disabled
                                    header={() => <Text>Two Factor Authentication</Text>}
                                    status="warning"
                                >
                                    <Text>Two Factor Authentication is {user?.mfa_enabled || showMFAQrCode ? 'enabled' : 'disabled'}</Text>
                                    <Button
                                        onPress={() => generateMFAQRCode()}
                                    >
                                        Generate QR Code
                                        {user.mfa_enabled || showMFAQrCode && <Text> (Regenerate)</Text>}
                                    </Button>
                                    {showMFAQrCode &&
                                        <View>
                                            <Text>Scan this QR Code with your Authenticator App</Text>
                                            <Image
                                                source={{
                                                    uri: mfaImage,
                                                }}
                                                style={{ width: 300, height: 300 }}
                                                resizeMode="contain"
                                            />
                                        </View>
                                    }
                                </Card>
                            </Layout>
                            <Layout style={{ flex: 1 }}>
                                {/* Spacer */}
                            </Layout>
                        </Layout>
                    </View>
                }

            </>
        </Layout>
    )
}

const ChangePasswordModal = ({ visible, setVisible, user }: { visible: boolean, setVisible: any, user: User }) => {
    const [oldPassword, setOldPassword] = useState(''); // [password, setPassword
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const handleChangePassword = () => {
        if (password !== confirmPassword) {
            setError('Passwords do not match');
        } else {
            UserController.changePassword(
                user._id,
                oldPassword,
                password
            ).then((response) => {
                if (response.success) {
                    setSuccess('Password changed successfully');
                    setError("")
                    setPassword('');
                    setOldPassword('');
                    setConfirmPassword('');
                    Toast.show({
                        type: 'success',
                        position: 'top',
                        text1: 'Password Changed',
                        text2: 'Password changed successfully'
                    });
                    setVisible(false);
                } else {
                    setError(response.message);
                    Toast.show({
                        type: 'error',
                        position: 'top',
                        text1: 'Error',
                        text2: response.message
                    });
                }
            })
        }
    }

    return (
        <Modal
            visible={visible}
            backdropStyle={styles.backdrop}
            onBackdropPress={() => setVisible(false)}>
            <Card disabled={true}>
                <Text category='h1'>Change Password</Text>
                <Text>Enter your old password to change your password</Text>
                <Text category='label'>Old Password:</Text>
                <Input
                    onChangeText={setOldPassword}
                    value={oldPassword}
                    secureTextEntry />
                <Text category='label'>New Password:</Text>
                <Input
                    onChangeText={setPassword}
                    value={password}
                    secureTextEntry />
                <Text category='label'>Confirm Password:</Text>
                <Input
                    onChangeText={setConfirmPassword}
                    value={confirmPassword}
                    secureTextEntry />
                <Text>{error}</Text>
                <Text>{success}</Text>
                <Button onPress={handleChangePassword}>
                    <Text>Change Password</Text>
                </Button>
            </Card>
        </Modal>
    )
}

const styles = StyleSheet.create({
    backdrop: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
})