import React, { useState, useEffect, useRef, useReducer, useCallback, useMemo, createContext, useContext } from 'react';
//@ts-ignore
import { useWindowDimensions, StyleSheet, View, TouchableOpacity, FlatList, Alert, ScrollView } from "react-native";
import { SafeAreaView } from 'react-native-safe-area-context';
//@ts-ignore
import { Divider, List, Icon, Layout, Text, TopNavigation, TopNavigationAction, Card, Button, Input, ButtonGroup, CheckBox, Modal, RangeDatepicker, Datepicker, useTheme, IndexPath, Select, SelectItem, Toggle, OverflowMenu, MenuItem, ListItem } from '@ui-kitten/components';
import ErrorBoundary from '../ErrorBoundary.component';
import * as JobsController from '../../functions/job.controller'
import * as MembershipsController from '../../functions/membership.controller'
import * as UpdateController from '../../functions/update.controller'
import * as ImageController from '../../functions/image.controller'
import * as ClientController from '../../functions/client.controller'
import * as StorageController from '../../functions/storageController'
import * as CompanyController from '../../functions/company.controller'
import * as GoogleController from '../../functions/google.controller'
import NotificationContext from '../../context/notifications.context';
import { Company, InventoryItem } from '../../models/Company.model';
import { useAppStateChange, IAction } from '../../hooks/appStateChange.hook';

export const InventoryScreen = ({ navigation }: any) => {

    //@ts-ignore
    const { showNotification } = React.useContext(NotificationContext);
    const [selectedInventoryItem, setSelectedInventoryItem] = useState<InventoryItem | null>(null)
    const [showAddInventoryItemArea, setShowAddInventoryItemArea] = useState(false)
    const [selectedInventoryIndex, setSelectedInventoryIndex] = useState<number | null>(null)
    const [showEditInventoryItemArea, setShowEditInventoryItemArea] = useState(false)
    const [unsavedChanges, setUnsavedChanges] = useState(false)

    const [company, setCompany] = useState<Company>(new Company({}))
    const [companyInventory, setCompanyInventory] = useState<InventoryItem[]>([])
    const [originalInventory, setOriginalInventory] = useState<InventoryItem[]>([])



    const newInventoryItemRef = useRef({} as InventoryItem | any)
    const editInventoryItemRef = useRef({} as InventoryItem | any)
    const companyRef = useRef({})


    useEffect(() => {
        const unsubscribe = navigation.addListener('focus', () => {
            load();
        });

        // Return the function to unsubscribe from the event so it gets removed on unmount
        return unsubscribe;
    }, [navigation]);




    const handleCompanyChangeEvent = useCallback((action: IAction) => {
            setSelectedInventoryIndex(null)
            setSelectedInventoryItem(null)
            setCompanyInventory([])
            setOriginalInventory([])
            setShowAddInventoryItemArea(false)
            setShowEditInventoryItemArea(false)
            newInventoryItemRef.current = {}
            editInventoryItemRef.current = {}
            companyRef.current = {}
            setTimeout(async () => {
                load()
            }, 100);
    }, [company]);

    const { dispatchEventStateChange } = useAppStateChange({
        onUpdatedSelectedCompany: handleCompanyChangeEvent
    })

    const load = async () => {
        let id = StorageController.getCurrentCompany()._id
        let company = await CompanyController.getCompanyById(id)
        if (!company) return
        let companyInventory = company.inventory || new Array<InventoryItem>()
        setCompanyInventory(companyInventory)
        setCompany(company)
        setOriginalInventory([...companyInventory])
        companyRef.current = company
    }




    //=============================================
    //=============================================
    //INVENTORY
    //=============================================
    //=============================================

    const onSelectedInventoryItem = async (item: InventoryItem, index: number) => {
        editInventoryItemRef.current = { ...item }
        setSelectedInventoryItem(item)
        setSelectedInventoryIndex(index)
        setShowEditInventoryItemArea(true)
    }

    const handleEditInventoryItem = (item: InventoryItem) => {
        editInventoryItemRef.current = { ...item }
        setSelectedInventoryItem(item)
        onEditInventoryItem()
    }

    const handleAddInventoryItem = (item: InventoryItem) => {
        newInventoryItemRef.current = { ...item }
        onAddInventoryItem()
    }


    const onEditInventoryItem = () => {
        if (isNaN(editInventoryItemRef.current.cost) || editInventoryItemRef.current.cost.length == 0) {
            showNotification('Notice', 'Cost must be a number');
            return
        }
        if (!editInventoryItemRef.current.name || editInventoryItemRef.current.name.length == 0) {
            showNotification('Notice', 'Name must not be empty');
            return
        }
        try {
            const floatCost = parseFloat(editInventoryItemRef.current.cost);
            const stringFloat = floatCost.toFixed(2);
            editInventoryItemRef.current.cost = Number(stringFloat);
        } catch (e) {
            showNotification('Notice', 'Cost must be a number');
            return
        }
        const updatedInventory = [...company?.inventory];  // Create a shallow copy of the existing inventory
        updatedInventory[selectedInventoryIndex || 0] = { ...editInventoryItemRef.current };  // Replace the item at the selected index
        // setCompany((prevCompany:Company) => ({ ...prevCompany, inventory: updatedInventory }));  // Update the company state
        setCompanyInventory(updatedInventory)
        editInventoryItemRef.current = {};  // Reset the edit item
        setSelectedInventoryItem(null);  // Reset the selected item
        setSelectedInventoryIndex(null);  // Reset the selected index
        setShowEditInventoryItemArea(false)
        setUnsavedChanges(true)
    }

    const onDelteInventoryItem = async (index: number) => {
        let inventory = company?.inventory
        if (inventory.length == 0) return
        inventory.splice(index, 1)
        // setCompany({ ...company, inventory: inventory })
        setCompanyInventory(inventory)
        setUnsavedChanges(true)
    }

    const onMakeNewInventoryItem = async () => {
        setShowAddInventoryItemArea(true)
    }
    const onAddInventoryItem = async () => {
        let _company = { ...company }
        if (!_company.inventory) {
            _company.inventory = []
        }
        if (isNaN(newInventoryItemRef.current.cost) || newInventoryItemRef.current.cost.length == 0) {
            showNotification('Notice', 'Cost must be a number');
            return
        }
        try {
            const floatCost = parseFloat(newInventoryItemRef.current.cost);
            const stringFloat = floatCost.toFixed(2);
            newInventoryItemRef.current.cost = Number(stringFloat);
        } catch (e) {
            showNotification('Notice', 'Cost must be a number');
            return
        }
        if (!newInventoryItemRef.current.name || newInventoryItemRef.current.name.length == 0) {

            showNotification('Notice', 'Name must not be empty');
            return
        }

        // _company.inventory.push({ ...newInventoryItemRef.current }); // needs shallow copy bacause pointing
        // setCompany(_company)
        setCompanyInventory([...company.inventory, { ...newInventoryItemRef.current }])
        newInventoryItemRef.current = {}
        setShowAddInventoryItemArea(false)
        setUnsavedChanges(true)
    }

    const onSave = async () => {
        // set the company that matches the id in storage controller
        // let result = await CompanyController.updateCompany(company)
        let coy = company
        coy.inventory = companyInventory
        const saveResult = await coy.save()
        setOriginalInventory([...companyInventory])
        console.log("result", saveResult)
        if (saveResult.status == 200) {
            setUnsavedChanges(false)
            showNotification('Notice', 'Inventory Settings Saved');
        }
        else {
            showNotification('Error', 'Company Settings Failed to Save, try again later or refresh..');
        }
    }

    const onCancel = async () => {
        setUnsavedChanges(false)
        // setCompany({ ...originalCompanyObj })
        setCompanyInventory([...originalInventory])
    }





    return (
        <View style={{ flex: 1 }}>
            <RenderAddNewInventoryItemModal
                visible={showAddInventoryItemArea}
                setVisible={setShowAddInventoryItemArea}
                handleAddInventoryItem={handleAddInventoryItem}
            />
            <RenderEditInventoryItemModal
                inventoyItem={selectedInventoryItem}
                visible={showEditInventoryItemArea}
                setVisible={setShowEditInventoryItemArea}
                handleEditInventoryItem={handleEditInventoryItem}
            />

            <Layout style={{ flexDirection: 'row' }}>
                <View style={{ flex: 1 }}>
                    {
                        unsavedChanges ? <Text style={{ color: 'red' }
                        } > Unsaved Changes </Text> : <Text></Text >
                    }
                </View>
                <Layout style={{ flex: 3 }}>
                </Layout>
                <Layout style={{ flex: 1 }}>
                    <Button
                        onPress={onSave}
                        accessoryLeft={(props: any) => <Icon {...props} name="save-outline" />}
                        status="success"
                    />
                </Layout>
                <Layout style={{ flex: 1 }}>
                    <Button
                        onPress={onCancel}
                        accessoryLeft={(props: any) => <Icon {...props} name="close-outline" />}
                        status="warning"
                    />
                </Layout>
            </Layout>
            <ScrollView style={{ flex: 1 }}>
                <Layout style={{ flex: 1, flexDirection: 'row' }}>
                    <Layout style={{ flex: 1, flexDirection: 'column' }}>
                        <Card disabled status="basic" header={() => <Text category='h6'>Inventory</Text>}>
                            <View style={{ flexDirection: 'row' }}>
                                <Button status='success' appearance='outline' onPress={() => { onMakeNewInventoryItem() }}>
                                    <Icon name='plus-outline' width={24} height={24} fill="white" />
                                </Button>
                                <Text style={{ justifyContent: 'center', alignSelf: 'center', padding: 10 }}>Add New Item</Text>
                            </View>
                            <View style={{ flexDirection: 'row' }}>
                            </View>
                            <View>
                                <List
                                    data={companyInventory || []}
                                    ItemSeparatorComponent={Divider}
                                    renderItem={({ item, index }: any) => (
                                        <ListItem
                                            title={`${item.name}`}
                                            description={`$${Number(item.cost).toFixed(2)}`}
                                            accessoryRight={(props: any) => {
                                                return (
                                                    <View style={{ flexDirection: 'row', gap: 3 }}>
                                                        <Button size='small' appearance='outline' status='success' onPress={() => {
                                                            onSelectedInventoryItem(item, index)
                                                        }}>
                                                            <Icon {...props} name='edit-outline' width={24} height={24} fill="white" />
                                                        </Button>
                                                        <Button size='small' appearance='outline' status='danger' onPress={() => {
                                                            onDelteInventoryItem(index)
                                                        }}>
                                                            <Icon {...props} name='trash-outline' width={24} height={24} fill="white" /></Button>
                                                    </View>
                                                )
                                            }}
                                        />
                                    )}
                                />
                            </View>
                        </Card>
                    </Layout>
                </Layout>
            </ScrollView>
        </View>
    )
}

const RenderAddNewInventoryItemModal = ({
    visible,
    setVisible,
    handleAddInventoryItem
}: any) => {
    const newInventoryItemRef = useRef(new InventoryItem({}))
    return (
        <Modal
            visible={visible}
            backdropStyle={styles.backdrop}
            onBackdropPress={() => setVisible(false)}>
            <Card
                disabled
                status='success'
                header={() =>
                    <View style={{ flexDirection: 'row' }}>
                        <View style={{ flex: 1 }}>
                            <Text category='s1'>Add New Item</Text>
                        </View>
                        <Button appearance='outline' size='tiny' style={{ position: 'absolute', top: -40, right: -20 }} status='danger' onPress={() => { setVisible(false) }}>X</Button>
                    </View>
                }>
                <View style={{ flexDirection: 'column', gap: 5 }}>

                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Text>Name</Text>
                        <Input
                            placeholder="Name"
                            defaultValue={""}
                            onChangeText={(nextValue: any) => newInventoryItemRef.current.name = nextValue}
                        />
                    </View>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Text>Cost</Text>
                        <Input
                            placeholder="Cost"
                            defaultValue={""}
                            onChangeText={(nextValue: any) => {
                                newInventoryItemRef.current.cost = nextValue
                            }}
                        />
                    </View>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Button status='success' onPress={() => { handleAddInventoryItem(newInventoryItemRef.current) }}>Save</Button>
                        <Button appearance='ghost' style={{}} status='danger' onPress={() => { setVisible(false) }}>cancel</Button>
                    </View>
                </View>
            </Card>
        </Modal>
    )
}

const RenderEditInventoryItemModal = ({
    inventoyItem,
    visible,
    setVisible,
    handleEditInventoryItem
}: any) => {
    if (!inventoyItem) return (<></>)
    return (
        <Modal
            visible={visible}
            backdropStyle={styles.backdrop}
            onBackdropPress={() => setVisible(false)}>
            <Card disabled header={() => <Text category='s1'>Edit Item</Text>} status='warning'>
                <View style={{ flexDirection: 'column', gap: 5 }}>

                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Text>Name</Text>
                        <Input
                            placeholder="Name"
                            defaultValue={inventoyItem?.name}
                            onChangeText={(nextValue: any) => inventoyItem.name = nextValue}
                        />
                    </View>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Text>Cost</Text>
                        <Input
                            placeholder="Cost"
                            defaultValue={Number(inventoyItem?.cost).toFixed(2)}
                            onChangeText={(nextValue: any) => {
                                inventoyItem.cost = nextValue
                            }}
                        />
                    </View>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Button status='success' onPress={() => { handleEditInventoryItem(inventoyItem) }}>Save</Button>
                        <Button appearance='ghost' style={{}} status='danger' onPress={() => { setVisible(false) }}>cancel</Button>
                    </View>
                </View>
            </Card>
        </Modal>
    )


}


const styles = StyleSheet.create({
    backdrop: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
});