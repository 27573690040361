import React, { useState, useEffect } from 'react';
import { View, ScrollView, TouchableOpacity, Animated, TouchableWithoutFeedback, StyleSheet } from 'react-native';
//@ts-ignore
import { Layout, Text, Icon, Select, SelectItem, useTheme, IndexPath, Button, Modal, Card, Input, OverflowMenu, MenuItem } from "@ui-kitten/components";
import { Route, ROUTE_STATUS } from '../../models/Route.model';
import { Member, MemberPermissions } from '../../models/Member.model';
import { JobReportModal } from '../dashboardComponents/jobReportModal.component';
import * as JobController from '../../functions/job.controller';
//@ts-ignore
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import * as MembershipController from '../../functions/membership.controller';
import * as StorageController from "../../functions/storageController";
import AssignJobModal from '../job/modals/assignJobModal.component';
import { Job, JOB_STATUS } from '../../models/Job.model';
import { Badge } from '../job/components/Badge.component';

interface RouteSummaryProps {
    route: Route;
    routeReadyForCreation: boolean;
    onCreateRoute: () => void;
    onAssignDriver: (driverId: string) => void;
    isRouteCreated: boolean;
    onUpdatePlannedStartTime: (time: Date) => void;
    onSelectRoute: (route: Route) => void;
    onSelectJobId: (job_id: string) => void;
    onUpdateRouteStatus: (routeId: string, newStatus: ROUTE_STATUS) => void;
}



export const RouteSummary = ({
    route,
    routeReadyForCreation,
    onCreateRoute,
    onAssignDriver,
    isRouteCreated,
    onUpdatePlannedStartTime,
    onSelectRoute,
    onSelectJobId,
    onUpdateRouteStatus
}: RouteSummaryProps) => {
    //@ts-ignore
    const theme = useTheme() as any;
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [routeCanBeCreated, setRouteCanBeCreated] = useState(false);
    const [routeCanBeAssigned, setRouteCanBeAssigned] = useState(false);
    const [showCreateRouteModal, setShowCreateRouteModal] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const [animation] = useState(new Animated.Value(0));
    const [selectedJob, setSelectedJob] = useState<Job | null>(null);
    const [showJobReportModal, setShowJobReportModal] = useState(false);
    const [drivers, setDrivers] = useState<Member[]>([]);
    const [selectedDriver, setSelectedDriver] = useState<string | null>(null);
    const [plannedStartTime, setPlannedStartTime] = useState(new Date());
    const [showAssignJobModal, setShowAssignJobModal] = useState(false);
    const [statusMenuVisible, setStatusMenuVisible] = useState(false);

    const totalDistance = route.details.legs.reduce((sum, leg) => sum + leg.actual_distance_kms, 0);
    const totalDuration = route.details.legs.reduce((sum, leg) => sum + leg.actual_duration_seconds, 0);

    const polylineStrokeColourSet = (index: number) => {
        const colours = [
            "#0000FF", "#4B0082", "#9400D3", "#FF7F00", "#FF0000",
            "#00FF00", "#00FFFF", "#FF00FF", "#FFD700", "#32CD32",
            "#8A2BE2", "#FF1493", "#1E90FF", "#FF4500", "#00CED1",
        ];
        return colours[index % colours.length];
    };

    const handleOpenAssignModal = () => {
        setShowAssignJobModal(true);
    };



    const handleCreateRoute = () => {
        setShowCreateRouteModal(true);
    };

    const confirmCreateRoute = () => {
        onCreateRoute();
        setShowCreateRouteModal(false);
    };

    useEffect(() => {
        if (route.status === ROUTE_STATUS.PLANNED) {
            setRouteCanBeAssigned(true);
        }

    }, [route.status]);

    useEffect(() => {
        if (routeReadyForCreation) {
            setRouteCanBeCreated(true);
        }
    }, [routeReadyForCreation]);

    const onSetSelectedJob = async (job_id: string) => {
        const job = await JobController.getJobById(job_id);
        if (job) {
            setSelectedJob(job);
            setShowJobReportModal(true);
        }
    }

    const toggleExpand = () => {
        console.log('route summary', route);
        const toValue = isExpanded ? 0 : 1;
        Animated.timing(animation, {
            toValue,
            duration: 300,
            useNativeDriver: false,
        }).start();
        setIsExpanded(!isExpanded);
    };

    const maxHeight = animation.interpolate({
        inputRange: [0, 1],
        outputRange: [0, 500], // Adjust this value based on your content
    });

    const assignedDriver = drivers.find(driver => driver._id === route.member_id);

    useEffect(() => {
        loadDrivers();
    }, []);

    const loadDrivers = async () => {
        const company = StorageController.getCurrentCompany();
        const fetchedDrivers = await MembershipController.getDriversByCompanyId(company._id);
        setDrivers(fetchedDrivers);
    };

    const handlePlannedStartTimeChange = (newTime: Date) => {
        try {
            setPlannedStartTime(newTime);
            onUpdatePlannedStartTime(newTime);
        } catch (error) {
        }
    };


    const handleAssignDriver = async (selectedDriver: Member) => {
        if (selectedDriver) {
            await route.assignDriver(selectedDriver._id);
            onAssignDriver(selectedDriver._id);
            setShowAssignJobModal(false);
        }
    };

    const handleDismissAssignJobModal = () => {
        setShowAssignJobModal(false);
    };

    const renderStatusToggle = () => (
        <Button
            size="tiny"
            appearance="ghost"
            onPress={() => setStatusMenuVisible(true)}
            accessoryLeft={<Icon name="more-vertical" fill={theme['color-basic-600']} style={{ width: 24, height: 24 }} />}
        />
    );

    const onStatusSelect = (index: IndexPath) => {
        setStatusMenuVisible(false);
        const newStatus = Object.values(ROUTE_STATUS)[index.row];
        onUpdateRouteStatus(route._id, newStatus);
    };

    return (
        <Layout style={{ padding: 16, borderRadius: 8, marginBottom: 4, width: '100%', borderWidth: 1, borderColor: theme['color-basic-500'] }}>
            <TouchableOpacity onPress={toggleExpand}>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: 4 }}>
                    <View style={{ flexDirection: 'row' }}>
                        <Text category="s1">{route.name}</Text>
                        <Icon
                            name={isExpanded ? 'chevron-up-outline' : 'chevron-down-outline'}
                            fill={theme['color-primary-500']}
                            style={{ width: 24, height: 24 }}
                        />
                    </View>
                    <View style={{ flexDirection: 'row', justifyContent: 'flex-end', gap: 40 }}>
                        {isRouteCreated &&
                            <View style={{ alignItems: 'flex-end', justifyContent: 'center' }}>
                                <Button
                                    size="tiny"
                                    status="warning"
                                    appearance="ghost"
                                    onPress={() => onSelectRoute(route)}
                                    accessoryLeft={<Icon name="map-outline" fill={theme['color-basic-100']} style={{ width: 24, height: 24 }} />}
                                />
                            </View>
                        }
                        <OverflowMenu
                            anchor={renderStatusToggle}
                            visible={statusMenuVisible}
                            onBackdropPress={() => setStatusMenuVisible(false)}
                            onSelect={onStatusSelect}
                        >
                            {Object.values(ROUTE_STATUS).map((status, index) => {
                                const statusName = status.replace('_', ' ').toUpperCase();
                                return (<MenuItem key={index} title={statusName} />)
                            })}
                        </OverflowMenu>
                    </View>
                </View>
            </TouchableOpacity>

            <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: 4 }}>
                <View>
                    <Text category="s1">Total Distance</Text>

                    <Text style={[styles.detailTextPill, { backgroundColor: theme['color-success-500'], borderColor: theme['color-success-500'] }]} category="s2">{totalDistance.toFixed(2)} km</Text>
                </View>
                <View>
                    <Text category="s1">Total Duration</Text>
                    <Text style={[styles.detailTextPill, { backgroundColor: theme['color-warning-400'], borderColor: theme['color-warning-400'] }]} category="s2">{Math.round(totalDuration / 60)} min</Text>
                </View>
                <View>
                    <Text category="s1">Locations</Text>
                    <Text style={[styles.detailTextPill, { backgroundColor: theme['color-info-800'], borderColor: theme['color-info-800'] }]} category="s2">{route.details.locations.length}</Text>
                </View>
                {!isRouteCreated && (
                    <Button onPress={handleCreateRoute} disabled={!routeCanBeCreated}>
                        Create Route
                    </Button>
                )}
            </View>

            <Animated.View style={{ maxHeight, overflow: 'hidden' }}>
                <ScrollView>
                    <Text category="s1" style={{ marginBottom: 4 }}>Locations and Legs</Text>
                    {route.details.locations.map((location, index) => (
                        <React.Fragment key={index}>
                            <View style={{ marginBottom: 4, flexDirection: 'row', alignItems: 'center', gap: 5 }}>
                                <TouchableOpacity onPress={() => {
                                    onSelectJobId(location.job_id);
                                }}>
                                    <Icon
                                        name={index === 0 ? 'pin-outline' : (index === route.details.locations.length - 1 ? 'flag' : 'flag-outline')}
                                        fill={theme['color-primary-500']}
                                        style={{ width: 24, height: 24, marginRight: 4 }}
                                    />
                                </TouchableOpacity>
                                <View style={{ flex: 1 }}>
                                    <Text category="s2">{index + 1}. {location.name}</Text>
                                    <Text category="c1">{location.address.addressToString()}</Text>
                                </View>
                                {location.job && (
                                    <Badge status={location.job.status} />
                                )}
                                {location.job_id && (
                                    <Button
                                        size="tiny"
                                        status="info"
                                        onPress={() => {
                                            onSetSelectedJob(location.job_id);
                                        }}
                                    >
                                        View Job
                                    </Button>
                                )}
                            </View>
                            {index < route.details.legs.length && (
                                <View style={{ marginLeft: 32, marginBottom: 4, flexDirection: 'row', alignItems: 'center' }}>
                                    <Icon
                                        name="arrow-forward-outline"
                                        fill={theme['color-info-500']}
                                        style={{ width: 24, height: 24, marginRight: 4 }}
                                    />
                                    <View style={styles.legInfoContainer}>
                                        {/* <Text category="s1" style={styles.legInfoLabel}>Leg {index + 1}</Text> */}
                                        <View style={[styles.legInfoValue, { backgroundColor: theme['color-success-200'] }]}>
                                            <Text category="s2">{route.details.legs[index].actual_distance_kms?.toFixed(2)} km</Text>
                                        </View>
                                    </View>
                                    <View style={styles.legInfoContainer}>
                                        {/* <Text category="s1" style={styles.legInfoLabel}>Duration</Text> */}
                                        <View style={[styles.legInfoValue, { backgroundColor: theme['color-warning-200'] }]}>
                                            <Text category="s2">{Math.round(route.details.legs[index].actual_duration_seconds / 60)} min</Text>
                                        </View>
                                    </View>
                                </View>
                            )}
                        </React.Fragment>
                    ))}
                </ScrollView>
            </Animated.View>

            <ScrollView horizontal showsHorizontalScrollIndicator={false}>
                {route.details.locations.map((location, index) => (
                    <React.Fragment key={index}>
                        <View style={{ alignItems: 'center', marginRight: 1 }}>
                            <Icon
                                name={index === 0 ? 'pin-outline' : (index === route.details.locations.length - 1 ? 'flag' : 'flag-outline')}
                                fill={theme['color-primary-500']}
                                style={{ width: 24, height: 24 }}
                            />
                            <Text category="c1">{location.name}</Text>
                            <Text category="c1">{location.route_location_type}</Text>
                        </View>
                        {index < route.details.locations.length - 1 && (
                            <View style={{ flexDirection: 'row', alignItems: 'center', marginRight: 8 }}>
                                <View style={{
                                    height: 2,
                                    width: 40,
                                    backgroundColor: polylineStrokeColourSet(index),
                                    marginHorizontal: 4
                                }} />
                                <Icon
                                    name="arrow-forward-outline"
                                    fill={theme['color-basic-600']}
                                    style={{ width: 16, height: 16 }}
                                />
                            </View>
                        )}
                    </React.Fragment>
                ))}
            </ScrollView>
            <View style={{ flexDirection: 'row', gap: 20 }}>
                <View style={styles.timePickerContainer}>
                    <Text category="s1">Planned Start Time</Text>
                    <TimePicker value={plannedStartTime} onChange={handlePlannedStartTimeChange} />
                </View>
                {isRouteCreated && (
                    <View style={{ marginTop: 4, flexDirection: 'row', justifyContent: 'space-between', gap: 10 }}>
                        <View style={{ flexDirection: 'row', gap: 10, alignItems: 'center' }}>
                            <Text category="s1">Assigned Driver:</Text>
                            {assignedDriver ?
                                <View style={[styles.detailTextPill, { backgroundColor: theme['color-success-900'], borderColor: theme['color-primary-500'] }]}>
                                    <Text category="s2">{assignedDriver?.name || 'No driver assigned'}</Text>
                                </View>
                                :
                                <Text category="s2">No driver assigned</Text>
                            }
                            <View style={{}}>

                                {assignedDriver ? (
                                    <View style={{ marginBottom: 4 }}>
                                        <Button
                                            size="tiny"
                                            appearance="outline" onPress={handleOpenAssignModal} style={{ marginTop: 8 }}>
                                            Reassign Driver
                                        </Button>
                                    </View>
                                ) : (
                                    <View>
                                        <Button
                                            size="tiny"
                                            onPress={handleOpenAssignModal} disabled={!routeCanBeAssigned} style={{ marginTop: 8 }}>
                                            Assign Driver
                                        </Button>
                                    </View>
                                )}
                            </View>
                        </View>
                    </View>
                )}
            </View>


            <Modal
                visible={showCreateRouteModal}
                backdropStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
                onBackdropPress={() => setShowCreateRouteModal(false)}
            >
                <Card disabled>
                    <Text category="h6" style={{ marginBottom: 16 }}>Confirm Route Creation</Text>
                    <Text>
                        Are you sure you want to create a route with the following locations?
                    </Text>
                    <ScrollView style={{ maxHeight: 200, marginVertical: 16 }}>
                        {route.details.locations.map((location, index) => (
                            <Text key={index}>{location.name}</Text>
                        ))}
                    </ScrollView>
                    <View style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
                        <Button appearance="ghost" onPress={() => setShowCreateRouteModal(false)} style={{ marginRight: 8 }}>
                            Cancel
                        </Button>
                        <Button onPress={confirmCreateRoute}>
                            Confirm
                        </Button>
                    </View>
                </Card>
            </Modal>

            <JobReportModal
                showReportDetailsModal={showJobReportModal}
                setShowReportDetailsModal={setShowJobReportModal}
                job={selectedJob}
                showImages={true}
                showLogs={true}
            />

            <AssignJobModal
                var_member_id={route.member_id}
                var_assignJobModalVisible={showAssignJobModal}
                selectedJob={null}
                fn_assignMember={handleAssignDriver}
                fn_dismiss={handleDismissAssignJobModal}
            />
        </Layout>
    );
};



interface TimePickerProps {
    value: Date;
    onChange: (date: Date) => void;
}

const TimePicker: React.FC<TimePickerProps> = ({ value, onChange }) => {
    const theme = useTheme();
    const [showPicker, setShowPicker] = useState(false);

    return (
        <View>
            <View style={{ flexDirection: 'row', alignItems: 'center', gap: 10 }}>
                <View style={[styles.detailTextPill, { backgroundColor: theme['color-basic-900'], borderColor: theme['color-basic-500'] }]}>
                    <Text style={{ color: theme['color-basic-100'] }}>{value.toLocaleTimeString([], { day: '2-digit', month: 'short', year: '2-digit', hour: '2-digit', minute: '2-digit' })}</Text>
                </View>

                <Button onPress={() => setShowPicker(true)} size="small">
                    Select
                </Button>
            </View>
            {showPicker && (
                <Layout style={{ flexDirection: 'row', gap: 10, alignItems: 'center' }}>
                    <DatePicker
                        selected={value}
                        onChange={onChange}
                        showTimeSelect
                        timeIntervals={15}
                        shouldCloseOnSelect={false}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                        minDate={new Date()}
                        inline
                    />
                    <Button onPress={() => setShowPicker(false)}>Close</Button>
                </Layout>
            )}
        </View>
    );
};

const styles = StyleSheet.create({
    timePickerContainer: {
        marginTop: 16,
    },
    legInfoContainer: {
        flexDirection: 'row',
        marginRight: 16,
        gap: 8,
    },
    legInfoLabel: {
        marginBottom: 4,
    },
    legInfoValue: {
        // backgroundColor: theme['color-basic-200'],
        borderRadius: 8,
        paddingHorizontal: 8,
        paddingVertical: 4,
    },
    detailTextPill: {
        borderWidth: 1,
        borderRadius: 8,
        paddingHorizontal: 8,
        paddingVertical: 4,
    }
});