import React, { useState, useEffect, useRef, useReducer, useCallback, Profiler, useMemo, memo, useContext } from 'react';
import {
    AccessibilityRole,
    ImageProps,
    ImageStyle,
    Image,
    StyleSheet,
    TouchableOpacity,
    View,
    Text as RNText,
    Platform,
    useWindowDimensions,
    ScrollView,
    Dimensions,
    Alert
    //@ts-ignore
} from "react-native";
import {
    ApplicationProvider,
    Button,
    Icon,
    IconRegistry,
    Layout,
    Text,
    List,
    ListItem,
    Card,
    Input,
    useTheme,
    Select,
    SelectItem,
    Toggle,
    OverflowMenu,
    Radio,
    RadioGroup,
    Divider
} from "@ui-kitten/components";
import * as ImagePicker from 'expo-image-picker';
import { SafeAreaView } from 'react-native-safe-area-context';
import AssignJobList from '../assignJobList.component';
import AssignJobModal from '../modals/assignJobModal.component';
import { ServicesContainer } from '../services/servicesContainer.component';
import * as JobsController from '../../../functions/job.controller'
import * as MemberController from '../../../functions/membership.controller'
import * as ClientController from '../../../functions/client.controller'
import * as UpdateController from '../../../functions/update.controller'
import * as CompanyController from '../../../functions/company.controller'
import * as StorageController from '../../../functions/storageController'
import * as GoogleController from '../../../functions/google.controller'
import "react-datepicker/dist/react-datepicker.css";
import ErrorBoundary from '../../ErrorBoundary.component';
import { JobDetailImages } from '../jobDetailsImages.component';
import { event } from 'react-native-reanimated';
import NotificationContext from '../../../context/notifications.context';
import { Toast } from 'react-native-toast-message/lib/src/Toast';
import AddressSearch from './addressSearch.component';
import { JobDetailMap } from './mapSelectLocation.component';
import { SquarePaymentLink } from '../../payments/SquarePaymentLink.component';
import { Company } from '../../../models/Company.model';

interface PaymentDetailsCardProps {
    jobState: any;
    customerPaidCard: boolean;
    customerPaidCardAmount: number;
    customerPaidCash: boolean;
    customerPaidCashAmount: number;
    onSetCustomerPaidCard: (value: boolean) => void;
    onSetCustomerPaidCardAmount: (value: number) => void;
    onSetCustomerPaidCash: (value: boolean) => void;
    onSetCustomerPaidCashAmount: (value: number) => void;
    allowEdit: boolean;
    style?: any;
}

export const PaymentDetailsCard = ({
    jobState,
    customerPaidCard,
    customerPaidCardAmount,
    customerPaidCash,
    customerPaidCashAmount,
    onSetCustomerPaidCard,
    onSetCustomerPaidCardAmount,
    onSetCustomerPaidCash,
    onSetCustomerPaidCashAmount,
    allowEdit,
    style
}: PaymentDetailsCardProps) => {

    const [squarePaymentsEnabled, setSquarePaymentsEnabled] = useState(false)
    useEffect(() => {
        const company = StorageController.getCurrentCompany()
        if (!company) return
        setSquarePaymentsEnabled(company.settings?.payment_settings?.square?.enabled || false)
    }, [])

    return (
        <Card
            disabled
            header={() => <Text style={{ alignSelf: 'center' }}>Payment Details</Text>}
            style={[style, {}]}
            status='primary'
        >
            {squarePaymentsEnabled ?
                <View style={{}}>
                    <SquarePaymentLink
                        job={jobState}
                        onPaymentFailure={() => {
                        }
                        } />
                </View>
                : null}
            <View style={{ flexDirection: "row" }}>
                <Toggle
                    disabled={!allowEdit}
                    checked={customerPaidCash}
                    onChange={(e: boolean) => onSetCustomerPaidCash(e)}
                >
                    Customer Paid Cash
                </Toggle>
                <Input
                    style={{ flex: 1 }}
                    label='Cash Amount'
                    name='cashAmount'
                    placeholder='Cash Amount'
                    disabled={!allowEdit || !customerPaidCash}
                    defaultValue={customerPaidCashAmount || 0}
                    onChangeText={(e: any) => onSetCustomerPaidCashAmount(e)}
                />
                <View style={{ flex: 2 }}></View>
            </View>
            <View style={{ flexDirection: "row" }}>
                <Toggle
                    checked={customerPaidCard}
                    disabled={!allowEdit}
                    onChange={(e: boolean) => {
                        // console.log(e)
                        onSetCustomerPaidCard(e)
                    }}
                >
                    Customer Paid Card
                </Toggle>
                <Input
                    style={{ flex: 1 }}
                    label='Card Amount'
                    name='cardAmount'
                    placeholder='Card Amount'
                    disabled={!allowEdit || !customerPaidCard}
                    defaultValue={customerPaidCardAmount || 0}
                    onChangeText={(e: any) => onSetCustomerPaidCardAmount(e)}
                />
                <View style={{ flex: 2 }}></View>
            </View>
        </Card>
    )

}
