import React from 'react';
import { View } from 'react-native';
import { Button } from "@ui-kitten/components";
import { HOLDING_STATUS } from '../../models/Holding.model';

interface HoldingStatusFilterButtonsProps {
    holdingFilterApplied: HOLDING_STATUS;
    setHoldingFilterApplied: (filter: HOLDING_STATUS) => void;
    onClearSearchResults: () => void;
    holdingSearchResults: any[];
}

export const HoldingStatusFilterButtons = ({
    holdingFilterApplied,
    setHoldingFilterApplied,
    onClearSearchResults,
    holdingSearchResults
}: HoldingStatusFilterButtonsProps) => {
    return (
        <View style={{ flexDirection: 'row', flex: 1, gap: 10 }}>
            <Button size='small' appearance={holdingFilterApplied == HOLDING_STATUS.HELD ? 'filled' : 'outline'} status='warning' onPress={() => setHoldingFilterApplied(HOLDING_STATUS.HELD)}>Held</Button>
            <Button size='small' appearance={holdingFilterApplied == HOLDING_STATUS.RELEASED ? 'filled' : 'outline'} status='success' onPress={() => setHoldingFilterApplied(HOLDING_STATUS.RELEASED)}>Released</Button>
            {/* if search results, add new button to clear and set back to held  */}
            {holdingSearchResults && holdingSearchResults.length > 0 &&
                <Button size='small' appearance='outline' status='danger' onPress={() => onClearSearchResults()}>Clear Search</Button>
            }
        </View>
    )
}