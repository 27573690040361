import React, { useEffect, useRef, useState } from 'react';
import {
    Image,
    AccessibilityRole,
    ImageProps,
    ImageStyle,
    StyleSheet,
    TouchableOpacity,
    View,
    Text as RNText,
    Animated
    //@ts-ignore
} from "react-native";
import {
    ApplicationProvider,
    Button,
    Icon,
    IconRegistry,
    Layout,
    Text,
    List,
    ListItem,
    Card
} from "@ui-kitten/components";
import { SafeAreaView } from 'react-native-safe-area-context';
import { EvaIconsPack } from "@ui-kitten/eva-icons";
import { mapping, light, dark } from "@eva-design/eva";
import * as UpdateController from '../../functions/update.controller';
import DriversListItem from "./DriversListItem.component";
import { Member } from '../../models/Member.model';
import { Job } from '../../models/Job.model';

interface DriversListProps {
    var_drivers: any;
    fn_selectDriver: (driver: any, jobs: any) => void;
    fn_selectJob: (job: any) => void;
    fn_zoomToMarker: (job: any) => void;
    var_clients: any;
    fn_setDirections: (driver: Member, job: Job) => void;
    var_viewedBy: string;
}


const DriversList = ({
    var_drivers,
    fn_selectDriver,
    fn_selectJob,
    fn_zoomToMarker,
    var_clients,
    fn_setDirections,
    var_viewedBy
}: DriversListProps) => {

    const [drivers, setDrivers] = useState([]);

    useEffect(() => {
        // order drivers by online status
        let _drivers = var_drivers.sort((a: Member, b: Member) => (a.online < b.online) ? 1 : -1)
        // now sort by last updated at
        _drivers = _drivers.sort((a: Member, b: Member) => (new Date(a.updatedAt) < new Date(b.updatedAt)) ? 1 : -1)
        // remove deleted and locked drivers
        _drivers = _drivers.filter((driver: Member) => {
            return !driver.deleted && !driver.locked
        })
        setDrivers(_drivers)
    }, [var_drivers])



    const renderItem = ({ item, index }: { item: Member, index: number }) => {
        return (
            <DriversListItem
                item={item}
                var_driver={item}
                index={index}
                fn_selectDriver={fn_selectDriver}
                fn_selectJob={fn_selectJob}
                fn_zoomToMarker={fn_zoomToMarker}
                var_clients={var_clients}
                var_drivers={var_drivers}
                fn_setDirections={fn_setDirections}
                var_viewedBy={var_viewedBy}
            ></DriversListItem>
        )
    };

    return (
        <Layout style={{ flex: 1, justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
            <List
                style={{ width: '100%' }}
                data={drivers}
                renderItem={renderItem}
            />
        </Layout>
    );
}

const styles = StyleSheet.create({

});


export default DriversList;
