import * as CompanyController from '../functions/company.controller';
import { Job, LineItem, Service, JobAddress } from "./Job.model";
import { Member } from "./Member.model";
import { Case } from "./Case.model";
import { Holding } from "./Holding.model";
import { Client } from "./Client.model";
import { CompanySubscription } from './CompanySubscription.model';
import { Route } from './Route.model';
const uuid = require('uuid');
const { v4: uuidv4 } = uuid;

export class Company {
    _id = '';  // Unique identifier for the company
    name = '';  // Name of the company
    details = '';  // Details of the company
    settings: CompanySettings = new CompanySettings();  // Settings related to the company
    inventory: InventoryItem[] = [];  // Inventory items of the company
    serviceAreas = [];  // Service areas of the company
    location = {};  // Location details of the company
    contactInfo = {};  // Contact information of the company
    createdAt = '';  // Date and time when the company was created
    updatedAt = '';  // Date and time when the company was last updated

    // This web app only
    drivers: Member[] = [];  // List of drivers in the company
    clients: Client[] = [];  // List of clients in the company
    jobs: Job[] = [];  // List of jobs in the company
    members: Member[] = [];  // List of members in the company
    services: Service[] = [];  // List of services in the company
    cases: Case[] = [];  // List of cases in the company
    holdingItems: Holding[] = [];  // List of holding items in the company
    membership: Member | null = null;  //Used on login selecting company
    vendorProfiles: any = [];  //Used on login selecting company
    holdings: Holding[] = [];  //Used on login selecting company
    company_subscription: CompanySubscription | null = null;  //Used on login selecting company
    routes: Route[] = [];  //Used on login selecting company

    constructor(details = {} as any) {
        this.name = details?.name ?? '';
        this._id = details?._id ?? '';
        this.details = details?.details ?? '';
        this.settings = new CompanySettings(details.settings || new CompanySettings({}));
        this.createdAt = details?.createdAt ?? '';
        this.updatedAt = details?.updatedAt ?? '';

        this.serviceAreas = details?.serviceAreas ?? [];
        this.location = details?.location ?? {};
        this.contactInfo = details?.contactInfo ?? {};
        this.drivers = details?.drivers ?? [];
        this.clients = details?.clients ?? [];
        this.jobs = details?.jobs ?? [];
        this.members = details?.members ?? [];
        this.services = details?.services ?? [];
        this.cases = details?.cases ?? [];
        this.holdingItems = details?.holdingItems ?? [];
        this.membership = details?.membership ?? null;
        this.holdings = details?.holdings ?? [];
        this.company_subscription = details?.company_subscription ? new CompanySubscription(details.company_subscription) : null;

        if (Array.isArray(details?.inventory)) {
            this.inventory = details.inventory.map((item: any) => new InventoryItem(item));
        } else {
            this.inventory = [];
        }
    }

    async save() {
        // Save the company 
        try {
            const r = await CompanyController.updateCompany(this);
            return r;
        } catch (e) {
            console.log("🚀============== ~ file: Company.model.js:45 ~ Company ~ save ~ e", e)
            return e;
        }
    }


    // Method to update company settings
    updateSettings(newSettings = {} as any) {
        this.settings = { ...this.settings, ...newSettings };
    }

    updateScheduleSettings(newSettings = {} as any) {
        this.settings.scheduleSettings = { ...this.settings.scheduleSettings, ...newSettings };
    }

    updateJobOptions(newSettings = {} as any) {
        this.settings.job_options = { ...this.settings.job_options, ...newSettings };
    }

    updateSMSSettings(newSettings = {} as any) {
        this.settings.sms = { ...this.settings.sms, ...newSettings };
    }

    updateEmailSettings(newSettings = {} as any) {
        this.settings.email = { ...this.settings.email, ...newSettings };
    }

    updateContracts(newContracts = [] as any) {
        this.settings.contracts = newContracts;
    }

    updateCompanyRoles(newRoles = {} as any) {
        this.settings.company_roles = newRoles;
    }

    updateCreateJobMandatoryFields(newFields = {} as any) {
        this.settings.create_job_mandatory_fields = newFields;
    }

    updateContactInfo(newInfo = {} as any) {
        this.settings.contact_info = { ...this.settings.contact_info, ...newInfo };
    }

    updateDriverSettings(newSettings = {} as any) {
        this.settings.driver_settings = { ...this.settings.driver_settings, ...newSettings };
    }

    updateAppSettings(newSettings = {} as any) {
        const newAppOptions = { ...this.settings.app_options, ...newSettings };
        this.settings.app_options = new CompanyAppOptions(newAppOptions);
    }

    // Method to add an item to the inventory
    addToInventory(item = {} as any) {
        //@ts-ignore
        this.inventory.push(item as any);
    }
}

export class InventoryItem {
    _id = '';
    name = '';
    description = '';
    quantity = 0;
    cost = 0;
    category = '';
    createdAt = '';
    updatedAt = '';
    constructor(details = {} as any) {
        // generate uuid
        this._id = details?._id ?? uuidv4();
        this.name = details?.name ?? '';
        this.description = details?.description ?? '';
        this.quantity = details?.quantity ?? 0;
        this.cost = details?.cost ?? 0;
        this.category = details?.category ?? '';
        this.createdAt = details?.createdAt ?? new Date().toISOString();
        this.updatedAt = details?.updatedAt ?? new Date().toISOString();
    }
}


export class ScheduleSettings {
    alertEnabled = false;
    alertTime = 0;
    constructor(details = {} as any) {
        this.alertEnabled = details?.alertEnabled ?? false;
        this.alertTime = details?.alertTime ?? 0;
    }
}

export class JobOptions {
    showRoadsideJobOptions = false;
    showTransportJobOptions = false;
    showTowingJobOptions = false;
    constructor(details = {} as any) {
        this.showRoadsideJobOptions = details?.showRoadsideJobOptions ?? false;
        this.showTransportJobOptions = details?.showTransportJobOptions ?? false;
        this.showTowingJobOptions = details?.showTowingJobOptions ?? false;
    }
}

export class SMSSettings {
    sendCreatedSMS = false;
    sendAssignedSMS = false;
    sendEnRouteSMS = false;
    sendCompletedSMS = false;
    sendCancelledSMS = false;

    include_job_status = false;

    constructor(details = {} as any) {
        this.sendCreatedSMS = details?.sendCreatedSMS ?? false;
        this.sendAssignedSMS = details?.sendAssignedSMS ?? false;
        this.sendEnRouteSMS = details?.sendEnRouteSMS ?? false;
        this.sendCompletedSMS = details?.sendCompletedSMS ?? false;
        this.sendCancelledSMS = details?.sendCancelledSMS ?? false;
        this.include_job_status = details?.include_job_status ?? false;
    }
}

export class EmailSettings {
    customer_sendCreatedEmail = false;
    customer_sendDriverAssignedEmail = false;
    customer_sendDriverEnRouteEmail = false;
    customer_sendDriverArrivedEmail = false;
    customer_sendDriverCompleteEmail = false;
    customer_sendCompleteEmail = false;
    customer_sendCancelledEmail = false;

    client_sendCreatedEmail = false;
    client_sendAssignedEmail = false;
    client_sendEnRouteEmail = false;
    client_sendCompletedEmail = false;
    client_sendCancelledEmail = false;

    /**
     * Include job status link in email
     */
    include_job_status = false;

    /**
     * Admin notify job request
     */
    enable_admin_notify_job_request = false;
    admin_notify_job_request_email = '';



    constructor(details = {} as any) {
        this.customer_sendCreatedEmail = details?.customer_sendCreatedEmail ?? false;
        this.customer_sendDriverAssignedEmail = details?.customer_sendDriverAssignedEmail ?? false;
        this.customer_sendDriverEnRouteEmail = details?.customer_sendDriverEnRouteEmail ?? false;
        this.customer_sendDriverArrivedEmail = details?.customer_sendDriverArrivedEmail ?? false;
        this.customer_sendDriverCompleteEmail = details?.customer_sendDriverCompleteEmail ?? false;
        this.customer_sendCompleteEmail = details?.customer_sendCompleteEmail ?? false;
        this.customer_sendCancelledEmail = details?.customer_sendCancelledEmail ?? false;

        this.client_sendCreatedEmail = details?.client_sendCreatedEmail ?? false;
        this.client_sendAssignedEmail = details?.client_sendAssignedEmail ?? false;
        this.client_sendEnRouteEmail = details?.client_sendEnRouteEmail ?? false;
        this.client_sendCompletedEmail = details?.client_sendCompletedEmail ?? false;
        this.client_sendCancelledEmail = details?.client_sendCancelledEmail ?? false;

        this.include_job_status = details?.include_job_status ?? false;

        this.enable_admin_notify_job_request = details?.enable_admin_notify_job_request ?? false;
        this.admin_notify_job_request_email = details?.admin_notify_job_request_email ?? '';
    }
}



export class CompanySettings {
    scheduleSettings = new ScheduleSettings({});
    job_options = new JobOptions({});
    sms = new SMSSettings({});
    email = new EmailSettings({});
    contracts = [] as CompanyContract[];
    company_roles = new CompanyRoles({});
    create_job_mandatory_fields = {};
    contact_info: CompanyContactInfo = new CompanyContactInfo({});
    invoice = {}
    driver_settings: DriverSettings = new DriverSettings({});
    location = {} as CompanyLocation;
    service_areas = [] as ServiceArea[];
    app_options = new CompanyAppOptions({});
    payment_settings = new CompanyPaymentSettings({});
    address_book = [] as CompanySavedAddress[];
    webhook_settings = new CompanyWebhookSettings({});
    theme = new CompanyTheme({});
    other: any = {};
    alerts: CompanyAlerts = new CompanyAlerts({});

    constructor(details = {} as any) {
        this.scheduleSettings = new ScheduleSettings(details.scheduleSettings || {});
        this.job_options = new JobOptions(details.job_options || {});
        this.sms = new SMSSettings(details.sms || {});
        this.email = new EmailSettings(details.email || {});
        this.contracts = details?.contracts ? details.contracts.map((item: any) => new CompanyContract(item)) : [];
        this.company_roles = details?.company_roles ? new CompanyRoles(details.company_roles) : new CompanyRoles({});
        this.create_job_mandatory_fields = details?.create_job_mandatory_fields ?? {};
        this.contact_info = new CompanyContactInfo(details.contact_info || {});
        this.invoice = details?.invoice ?? {};
        this.driver_settings = details?.driver_settings ? new DriverSettings(details.driver_settings) : new DriverSettings({});
        this.location = details?.location ?? {};
        this.service_areas = details?.service_areas ?? [];
        this.app_options = new CompanyAppOptions(details.app_options || new CompanyAppOptions({}));
        this.payment_settings = new CompanyPaymentSettings(details.payment_settings || {});
        this.address_book = details?.address_book ? details.address_book.map((item: any) => new CompanySavedAddress(item)) : [];
        this.webhook_settings = new CompanyWebhookSettings(details.webhook_settings || {});
        this.theme = new CompanyTheme(details.theme || {});
        this.other = details?.other ?? {};
        this.alerts = new CompanyAlerts(details.alerts || {});
    }
}

export class CompanyContract {
    _id: string
    name: string
    details: string
    vendor_profiles: string[]
    service_tags: string[]
    service_areas: any[]
    other: any

    constructor(details: any = {}) {
        this._id = details._id || Math.random().toString(36).substring(7)
        this.name = details.name || ""
        this.details = details.details || ""
        this.vendor_profiles = details.vendor_profiles || []
        this.service_tags = details.service_tags || []
        this.service_areas = details.service_areas || []
        this.other = details.other || {}
    }
}

export class CompanyTheme {
    primary?;
    success?;
    warning?;
    info?;
    danger?;
    basic?;

    light_dark?= 'dark';

    primaryTransparent = this.primary + '33';
    successTransparent = this.success + '33';
    warningTransparent = this.warning + '33';
    infoTransparent = this.info + '33';
    dangerTransparent = this.danger + '33';
    basicTransparent = this.basic + '33';


    brand_logo = '';

    constructor(details = {} as any) {
        this.primary = details?.primary ?? '#3366FF';
        this.success = details?.success ?? '#00e096';
        this.warning = details?.warning ?? '#ffaa00';
        this.info = details?.info ?? '#34E3ED';
        this.danger = details?.danger ?? '#FF382D';
        this.basic = details?.basic ?? '#748FDF';
        this.light_dark = details?.light_dark ?? 'dark';

        this.primaryTransparent = this.primary + '33';
        this.successTransparent = this.success + '33';
        this.warningTransparent = this.warning + '33';
        this.infoTransparent = this.info + '33';
        this.dangerTransparent = this.danger + '33';
        this.basicTransparent = this.basic + '33';
        this.brand_logo = details?.brand_logo ?? '';
    }

    reset() {
        this.primary = '#3366FF';
        this.success = '#00e096';
        this.warning = '#ffaa00';
        this.info = '#34E3ED';
        this.danger = '#FF382D';
        this.brand_logo = '';
        this.light_dark = 'dark';

        this.primaryTransparent = this.primary + '33';
        this.successTransparent = this.success + '33';
        this.warningTransparent = this.warning + '33';
        this.infoTransparent = this.info + '33';
        this.dangerTransparent = this.danger + '33';

        return this;
    }


    createEvaJson() {
        const primaryScale = this.generateColorScale(this.primary);
        const successScale = this.generateColorScale(this.success);
        const warningScale = this.generateColorScale(this.warning);
        const infoScale = this.generateColorScale(this.info);
        const dangerScale = this.generateColorScale(this.danger);
        const basicScale = this.generateBasicColorScale(this.basic);



        return {
            "logo-url": this.brand_logo,
            "color-primary-100": primaryScale[100],
            "color-primary-200": primaryScale[200],
            "color-primary-300": primaryScale[300],
            "color-primary-400": primaryScale[400],
            "color-primary-500": primaryScale[500],
            "color-primary-600": primaryScale[600],
            "color-primary-700": primaryScale[700],
            "color-primary-800": primaryScale[800],
            "color-primary-900": primaryScale[900],
            "color-primary-transparent-100": primaryScale[100] + `15`,
            "color-primary-transparent-200": primaryScale[200] + `15`,
            "color-primary-transparent-300": primaryScale[300] + `15`,
            "color-primary-transparent-400": primaryScale[400] + `15`,
            "color-primary-transparent-500": primaryScale[500] + `15`,
            "color-primary-transparent-600": primaryScale[600] + `15`,
            "color-success-100": successScale[100],
            "color-success-200": successScale[200],
            "color-success-300": successScale[300],
            "color-success-400": successScale[400],
            "color-success-500": successScale[500],
            "color-success-600": successScale[600],
            "color-success-700": successScale[700],
            "color-success-800": successScale[800],
            "color-success-900": successScale[900],
            "color-success-transparent-100": successScale[100] + `15`,
            "color-success-transparent-200": successScale[200] + `15`,
            "color-success-transparent-300": successScale[300] + `15`,
            "color-success-transparent-400": successScale[400] + `15`,
            "color-success-transparent-500": successScale[500] + `15`,
            "color-success-transparent-600": successScale[600] + `15`,
            "color-warning-100": warningScale[100],
            "color-warning-200": warningScale[200],
            "color-warning-300": warningScale[300],
            "color-warning-400": warningScale[400],
            "color-warning-500": warningScale[500],
            "color-warning-600": warningScale[600],
            "color-warning-700": warningScale[700],
            "color-warning-800": warningScale[800],
            "color-warning-900": warningScale[900],
            "color-warning-transparent-100": warningScale[100] + `15`,
            "color-warning-transparent-200": warningScale[200] + `15`,
            "color-warning-transparent-300": warningScale[300] + `15`,
            "color-warning-transparent-400": warningScale[400] + `15`,
            "color-warning-transparent-500": warningScale[500] + `15`,
            "color-warning-transparent-600": warningScale[600] + `15`,
            "color-danger-100": dangerScale[100],
            "color-danger-200": dangerScale[200],
            "color-danger-300": dangerScale[300],
            "color-danger-400": dangerScale[400],
            "color-danger-500": dangerScale[500],
            "color-danger-600": dangerScale[600],
            "color-danger-700": dangerScale[700],
            "color-danger-800": dangerScale[800],
            "color-danger-900": dangerScale[900],
            "color-danger-transparent-100": dangerScale[100] + `15`,
            "color-danger-transparent-200": dangerScale[200] + `15`,
            "color-danger-transparent-300": dangerScale[300] + `15`,
            "color-danger-transparent-400": dangerScale[400] + `15`,
            "color-danger-transparent-500": dangerScale[500] + `15`,
            "color-danger-transparent-600": dangerScale[600] + `15`,
            "color-info-100": infoScale[100],
            "color-info-200": infoScale[200],
            "color-info-300": infoScale[300],
            "color-info-400": infoScale[400],
            "color-info-500": infoScale[500],
            "color-info-600": infoScale[600],
            "color-info-700": infoScale[700],
            "color-info-800": infoScale[800],
            "color-info-900": infoScale[900],
            "color-info-transparent-100": infoScale[100] + '15',
            "color-info-transparent-200": infoScale[200] + '15',
            "color-info-transparent-300": infoScale[300] + '15',
            "color-info-transparent-400": infoScale[400] + '15',
            "color-info-transparent-500": infoScale[500] + '15',
            "color-info-transparent-600": infoScale[600] + '15',
            "color-basic-100": "#FFF",
            "color-basic-200": basicScale[200],
            "color-basic-300": basicScale[300],
            "color-basic-400": basicScale[400],
            "color-basic-500": basicScale[500],
            "color-basic-600": basicScale[600],
            "color-basic-700": basicScale[700],
            "color-basic-800": basicScale[800],
            "color-basic-900": basicScale[900],
            "color-basic-1000": basicScale[1000],
            "color-basic-1100": basicScale[1100],
            "color-basic-transparent-100": basicScale[100] + `15`,
            "color-basic-transparent-200": basicScale[200] + `15`,
            "color-basic-transparent-300": basicScale[300] + `15`,
            "color-basic-transparent-400": basicScale[400] + `15`,
            "color-basic-transparent-500": basicScale[500] + `15`,
            "color-basic-transparent-600": basicScale[600] + `15`,
            "color-basic-transparent-700": basicScale[700] + `15`,
            "color-basic-transparent-800": basicScale[800] + `15`,
            "color-basic-transparent-900": basicScale[900] + `15`,
            "color-basic-transparent-1000": basicScale[1000] + `15`,
            "color-basic-transparent-1100": basicScale[1100] + `15`,
            "text-basic-color": this.light_dark == 'dark' ? "#FFFFFF" : "#000000",
            "text-disabled-color": this.light_dark == 'dark' ? "#c4cad7" : "#c4cad7",
        }
    }

    private hexToRgb(hex: string) {
        let r = 0, g = 0, b = 0;
        if (hex.length == 4) {
            r = parseInt(hex[1] + hex[1], 16);
            g = parseInt(hex[2] + hex[2], 16);
            b = parseInt(hex[3] + hex[3], 16);
        } else if (hex.length == 7) {
            r = parseInt(hex[1] + hex[2], 16);
            g = parseInt(hex[3] + hex[4], 16);
            b = parseInt(hex[5] + hex[6], 16);
        }
        return { r, g, b };
    }

    private rgbToHex(r: any, g: any, b: any) {
        r = r.toString(16).padStart(2, '0');
        g = g.toString(16).padStart(2, '0');
        b = b.toString(16).padStart(2, '0');
        return `#${r}${g}${b}`;
    }

    private lighten(hex: string, percent: number) {
        const { r, g, b } = this.hexToRgb(hex);
        const newR = Math.min(255, Math.floor(r + (255 - r) * percent / 100));
        const newG = Math.min(255, Math.floor(g + (255 - g) * percent / 100));
        const newB = Math.min(255, Math.floor(b + (255 - b) * percent / 100));
        return this.rgbToHex(newR, newG, newB);
    }

    private darken(hex: string, percent: number) {
        const { r, g, b } = this.hexToRgb(hex);
        const newR = Math.max(0, Math.floor(r * (100 - percent) / 100));
        const newG = Math.max(0, Math.floor(g * (100 - percent) / 100));
        const newB = Math.max(0, Math.floor(b * (100 - percent) / 100));
        return this.rgbToHex(newR, newG, newB);
    }

    private generateColorScale(baseColor: string) {
        return {
            100: this.lighten(baseColor, 30),
            200: this.lighten(baseColor, 25),
            300: this.lighten(baseColor, 20),
            400: this.lighten(baseColor, 10),
            500: baseColor,
            600: this.darken(baseColor, 10),
            700: this.darken(baseColor, 20),
            800: this.darken(baseColor, 30),
            900: this.darken(baseColor, 40),
            1000: this.darken(baseColor, 50),
            1100: this.darken(baseColor, 60),
        }
    }


    private generateBasicColorScale(baseColor: string) {
        const basicLightScale = {
            100: '#FFFFFF',
            200: this.lighten(baseColor, 60),
            300: this.lighten(baseColor, 40),
            400: this.lighten(baseColor, 20),
            500: this.lighten(baseColor, 10),
            600: baseColor,
            700: this.darken(baseColor, 10),
            800: this.darken(baseColor, 20),
            900: this.darken(baseColor, 30),
            1000: this.darken(baseColor, 40),
            1100: this.darken(baseColor, 50),
        };

        const basicDarkScale = {
            100: baseColor,
            200: this.darken(baseColor, 10),
            300: this.darken(baseColor, 20),
            400: this.darken(baseColor, 30),
            500: this.darken(baseColor, 40),
            600: this.darken(baseColor, 50),
            700: this.darken(baseColor, 60),
            800: this.darken(baseColor, 70),
            900: this.darken(baseColor, 80),
            1000: this.darken(baseColor, 90),
            1100: this.darken(baseColor, 100),
        };

        return this.light_dark === 'dark' ? basicDarkScale : basicLightScale;
    }
}

// Addressbook to add to settings
export class CompanySavedAddress {
    _id = '';
    name = '';
    address: JobAddress = new JobAddress({});
    phone = '';
    location = {
        lat: 0,
        lng: 0
    }
    email = '';
    category = '';
    constructor(details = {} as any) {
        this._id = details?._id ?? uuidv4();
        this.name = details?.name ?? '';
        this.address = details?.address ? new JobAddress(details.address) : new JobAddress({});
        this.location = details?.location ?? { lat: 0, lng: 0 };
        this.phone = details?.phone ?? '';
        this.email = details?.email ?? '';
        this.category = details?.category ?? '';
    }
}

export class ServiceArea {
    _id = ''
    name = ''
    type = ''
    color = '#0000'
    coords = [] as IServiceAreaCoord[]
    constructor(details: any = {}) {
        this._id = details?.id || uuidv4();
        this.name = details?.name || 'Service Area'
        this.type = details?.type || 'serviceArea'
        this.color = details?.color || "#00FF00"
        this.coords = details?.coords || []
    }
}

export interface IServiceAreaCoord {
    lat: number;
    lng: number;
}



class DriverSettings {
    allow_driver_create_job = false;
    concurrent_jobs_per_driver = 1;
    enable_concurrent_jobs_per_driver_limit = false;

    constructor(details = {} as any) {
        this.allow_driver_create_job = details?.allow_driver_create_job ?? false;
        this.concurrent_jobs_per_driver = details?.concurrent_jobs_per_driver ?? 1;
        this.enable_concurrent_jobs_per_driver_limit = details?.enable_concurrent_jobs_per_driver_limit ?? false;
    }
}

export class CompanyContactInfo {
    name = '';
    phone = '';
    email = '';
    primary_contact_name = "";
    primary_contact_phone = "";
    primary_contact_email = "";
    secondary_contact_name = "";
    secondary_contact_phone = "";
    secondary_contact_email = "";

    constructor(details = {} as any) {
        this.name = details?.name ?? '';
        this.phone = details?.phone ?? '';
        this.email = details?.email ?? '';
        this.primary_contact_name = details?.primary_contact_name ?? '';
        this.primary_contact_phone = details?.primary_contact_phone ?? '';
        this.primary_contact_email = details?.primary_contact_email ?? '';
        this.secondary_contact_name = details?.secondary_contact_name ?? '';
        this.secondary_contact_phone = details?.secondary_contact_phone ?? '';
        this.secondary_contact_email = details?.secondary_contact_email ?? '';
    }
}


class CompanyRoles {
    showCases = false;
    showContracts = false;

    constructor(details = {} as any) {
        this.showCases = details?.showCases ?? false;
        this.showContracts = details?.showContracts ?? false;
    }
}

class CompanyLocation {
    address = '';
    coords = {
        lat: 0,
        lng: 0
    }

    constructor(details = {} as any) {
        this.address = details?.address ?? '';
        this.coords = details?.coords ?? { lat: 0, lng: 0 };
    }
}


class CompanyAppOptions {
    show_pre_inspection = true
    show_post_inspection = true
    show_vehicle_indemnity = true
    show_services = true
    show_inventory = true
    show_payment = true
    show_images = true

    show_signatures = true // replacing with the 2 below
    show_pre_inspection_signatures = true
    show_post_inspection_signatures = true

    require_driver_comments = true
    require_pre_inspection_images = true
    require_post_inspection_images = true

    require_pre_inspection_signatures = true
    require_post_inspection_signatures = true

    allow_edit_customer_details = true
    allow_edit_vehicle_details = true
    allow_edit_services = true
    allow_edit_inventory = true
    allow_edit_payment = true


    constructor(options = {} as any) {
        this.show_pre_inspection = options?.show_pre_inspection ?? true;
        this.show_post_inspection = options?.show_post_inspection ?? true;
        this.show_vehicle_indemnity = options?.show_vehicle_indemnity ?? true;
        this.show_services = options?.show_services ?? true;
        this.show_inventory = options?.show_inventory ?? true;
        this.show_payment = options?.show_payment ?? true;
        this.show_images = options?.show_images ?? true;

        this.show_signatures = options?.show_signatures ?? true;
        this.show_pre_inspection_signatures = options?.show_pre_inspection_signatures ?? true;
        this.show_post_inspection_signatures = options?.show_post_inspection_signatures ?? true;

        this.require_driver_comments = options?.require_driver_comments ?? true;
        this.require_pre_inspection_images = options?.require_pre_inspection_images ?? true;
        this.require_post_inspection_images = options?.require_post_inspection_images ?? true;

        this.require_pre_inspection_signatures = options?.require_pre_inspection_signatures ?? true;
        this.require_post_inspection_signatures = options?.require_post_inspection_signatures ?? true;

        this.allow_edit_customer_details = options?.allow_edit_customer_details ?? true;
        this.allow_edit_vehicle_details = options?.allow_edit_vehicle_details ?? true;
        this.allow_edit_services = options?.allow_edit_services ?? true;
        this.allow_edit_inventory = options?.allow_edit_inventory ?? true;
        this.allow_edit_payment = options?.allow_edit_payment ?? true;
    }
}


class CompanyPaymentSettings {
    stripe = {
        enabled: false,
        // secret_key: '',
        // publishable_key: '',
        customer_id: ''
    }

    square = {
        enabled: false,
        // access_token: '',
        // location_id: '',
        // application_id: '',
        surcharges: [] as Surcharge[]
    }

    constructor(details = {} as any) {
        this.stripe = details?.stripe ?? {
            enabled: false,
            // secret_key: '',
            // publishable_key: '',
            customer_id: ''
        }
        this.square = details?.square ?? {
            enabled: false,
            // access_token: '',
            // location_id: '',
            // application_id: '',
            // surcharges: [] as Surcharge[]
        }
    }
}


//Define surcharges
export class Surcharge {
    name!: string;
    amount!: number;
    description!: string;
    type: string = 'flat'; // flat, percentage
    constructor(surcharge: any) {
        this.name = surcharge.name;
        this.amount = surcharge.amount;
        this.description = surcharge.description;
        this.type = surcharge.type || 'flat';
    }
}

export class CompanyWebhookSettings {
    webhook_enabled = false;
    webhook_url = '';
    webhook_auth_bearer_token = '';

    constructor(details = {} as any) {
        this.webhook_enabled = details?.webhook_enabled ?? false;
        this.webhook_url = details?.webhook_url ?? '';
        this.webhook_auth_bearer_token = details?.webhook_auth_bearer_token ?? '';
    }
}

export class CompanyAlerts {

    enable_over_eta_alerts: boolean = false // time after eta that an alert is sent
    enable_close_to_eta_alerts: boolean = false // time before eta that an alert is sent
    enable_unassigned_job_time_alerts: boolean = false // time that a job has sat unassigned (eg sits unassigned for 30 minutes)
    enable_assigned_driver_not_accepted_job_time_alerts: boolean = false // time that a job has sat assigned to a driver but not accepted

    close_to_eta_alert_time_theshold: number = 0 // minutes
    unassigned_job_time_threshold: number = 0 // minutes
    assigned_driver_not_accepted_job_time_threshold: number = 0 // minutes

    constructor(alerts: any = {}) {
        this.enable_over_eta_alerts = alerts.enable_over_eta_alerts ?? false
        this.enable_close_to_eta_alerts = alerts.enable_close_to_eta_alerts ?? false
        this.enable_unassigned_job_time_alerts = alerts.enable_unassigned_job_time_alerts ?? false
        this.enable_assigned_driver_not_accepted_job_time_alerts = alerts.enable_assigned_driver_not_accepted_job_time_alerts ?? false

        this.close_to_eta_alert_time_theshold = alerts.close_to_eta_alert_time ?? 10
        this.unassigned_job_time_threshold = alerts.unassigned_job_time ?? 30
        this.assigned_driver_not_accepted_job_time_threshold = alerts.assigned_driver_not_accepted_job_time ?? 30
    }

}
