import NotificationContext from '../../context/notifications.context';
// components/NotificationModal.js
import React, { useContext } from 'react';
import { Modal, View, Text, Button } from 'react-native';
import {Layout, Card} from '@ui-kitten/components'

const NotificationModal = ({onOk}) => {
  const { notificationVisible, notificationTitle, notificationMessage, hideNotification } = useContext(NotificationContext);

  const handleOk = () => {
    if(onOk){
        onOk();
    }
    console.log("Notification Modal OK")
    hideNotification();
};
  return (
    <Modal visible={notificationVisible} animationType="fade" transparent>
      <Card style={styles.modalContainer}>
        <Layout style={styles.modalContent}>
          <Text style={styles.title}>{notificationTitle}</Text>
          <Text style={styles.message}>{notificationMessage}</Text>
          <Button title="OK" onPress={()=>handleOk()} />
        </Layout>
      </Card>
    </Modal>
  );
};

const styles = {
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalContent: {
    backgroundColor: 'white',
    padding: 20,
    borderRadius: 8,
    alignItems: 'center',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  message: {
    fontSize: 16,
    marginBottom: 20,
  },
};

export default NotificationModal;