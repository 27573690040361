import axios from 'axios';
import polyline from '@mapbox/polyline';
import { Route, RouteLocation, RouteLeg, ROUTE_START_END_OPTION, ROUTE_LOCATION_TYPE, IGoogleRouteLeg } from '../models/Route.model';
import config from '../config/config.js';
import * as StorageController from './storageController';


const api = config.api;
const testApi = config.test_api;

function getApi() {
    if (StorageController.getAppState().use_test_api) {
        return testApi;
    }
    return api;
}


/*
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
//////// OSRM SOLVE FUNCTIONS
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
*/

export async function getRouteDirections(route: Route): Promise<{legs: RouteLeg[], routePolyline: any}> {
    const waypoints = getWaypoints(route);
    if (waypoints.length < 2) return { legs: [], routePolyline: null };

    const osrmDirections = await getStaticRouteRequest(waypoints.map(waypoint => ({
        lat: waypoint.location.latLng.latitude,
        lon: waypoint.location.latLng.longitude
    })));

    if (!osrmDirections?.routes) return { legs: [], routePolyline: null };

    const routeResult = osrmDirections.routes[0];
    const legs = convertStepsToRouteLegs(routeResult.steps);
    const encodedPolyline = routeResult.overview_polyline;
    const routePolyline = polyline.decode(encodedPolyline as string, 6).map((point: any) => ({
        lat: point[0],
        lng: point[1]
    }));

    return { legs, routePolyline };
}


export async function getOptimizeRoute(route: Route): Promise<{legs: RouteLeg[], overviewPolyline: any, allStepsPolyline: any}> {
    const waypoints = getWaypoints(route);
    if (waypoints.length < 2) return { legs: [], overviewPolyline: null, allStepsPolyline: null };

    let startLocation, endLocation;

    switch (route.details.startEndOption) {
        case ROUTE_START_END_OPTION.DEFAULT:
        case ROUTE_START_END_OPTION.START_FIRST_END_LAST:
            startLocation = waypoints[0].location.latLng;
            endLocation = waypoints[waypoints.length - 1].location.latLng;
            break;
        case ROUTE_START_END_OPTION.ROUND_TRIP:
            startLocation = waypoints[0].location.latLng;
            endLocation = waypoints[0].location.latLng;
            break;
        case ROUTE_START_END_OPTION.CUSTOM:
            startLocation = waypoints[route.details.startLocationIndex || 0].location.latLng;
            endLocation = waypoints[route.details.endLocationIndex || waypoints.length - 1].location.latLng;
            break;
    }

    const vehicle = {
        id: 1,
        start: [startLocation.longitude, startLocation.latitude] as [number, number],
        end: [endLocation.longitude, endLocation.latitude] as [number, number],
        profile: 'car',
        capacity: [10000]
    };

    const vroomRequest: VroomRequest = {
        jobs: waypoints.map((waypoint, index) => {
            let delivery = [0];
            let pickup = [0];
            let priority = 0;
            let description = 'delivery';
            let type = 'delivery';

            if (waypoint.location.route_location_type === ROUTE_LOCATION_TYPE.PICKUP) {
                delivery = [0];
                pickup = [1];
                priority = 1;
                description = 'pickup';
                type = 'pickup';
            }

            if (waypoint.location.route_location_type === ROUTE_LOCATION_TYPE.PICKUP && index === 0) {
                priority = 100;
            }

            return {
                id: index + 1,
                description: description,
                location: [waypoint.location.latLng.longitude, waypoint.location.latLng.latitude] as [number, number],
                type: type,
                delivery: delivery,
                pickup: pickup,
                priority: priority,
            }
        }),
        vehicles: [vehicle],
        options: {
            g: true
        }
    };

    const response = await solveVroomProblemWithRoutesRequest(vroomRequest);
    if (!response.success || !response.routes) {
        console.error("Error in getOptimizeRoute:", response.message || "No routes found");
        return { legs: [], overviewPolyline: null, allStepsPolyline: null };
    }

    const allLegs: RouteLeg[] = [];
    let lastOverviewPolyline: any = null;
    let allStepsPolyline: any = response.all_overview_polyline;

    response.routes.forEach((route: CombinedRoute) => {
        const legs = route.steps.map((step: CombinedStep, index: number) => {
            const geometry = step.polyline as any;
            const combinedPolyline = convertOSRMPolylineToArray(geometry);
            const combinedEncodedPolyline = polyline.encode(combinedPolyline, 6);
            if (step.type == "end") return null;
            const newLeg = new RouteLeg({
                index: index,
                origin: step.startLocation,
                destination: step.endLocation,
                polyline: combinedEncodedPolyline,
                actual_distance_kms: step.distance / 1000,
                actual_duration_seconds: step.duration,
            });
            return newLeg;
        }).filter((leg: RouteLeg | null) => leg !== null);

        allLegs.push(...legs);
        lastOverviewPolyline = route.overview_polyline;
    });

    return { legs: allLegs, overviewPolyline: lastOverviewPolyline, allStepsPolyline: allStepsPolyline };
}

function getWaypoints(route: Route) {
    return route.details.locations.map((location) => ({
        location: {
            latLng: {
                latitude: location.location.lat,
                longitude: location.location.lng,
            },
            route_location_type: location.route_location_type
        }
    }));
}



/*
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
//////// OSRM ROUTES - VROOM
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
*/
// router.post("/osrm/vroom/full-routes", OsrmService.solveVroomProblemWithRoutesRequest);

export async function solveVroomProblemWithRoutesRequest(request: VroomRequest): Promise<VroomResponseWithRoutes> {
    try {
        const response = await axios.post(getApi() + "/osrm/vroom/full-routes", request);
        return response.data;
    } catch (error) {
        console.log(error);
        return {
            success: false,
            message: "Error solving VROOM problem",
            code: 500
        }
    }
}


// router.post("/osrm/static-route", OsrmService.getStaticRouteRequest);

export async function getStaticRouteRequest(locations: OsrmLocation[]): Promise<VroomResponseWithRoutes> {
    try {
        const data = {
            locations: locations
        }
        const response = await axios.post(getApi() + "/osrm/static-route", data);
        return response.data;
    } catch (error) {
        console.log(error);
        return {
            success: false,
            message: "Error solving VROOM problem",
            code: 500
        }
    }
}




/*
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
//////// UTILS
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
*/



export const convertLegsToRouteLegs = (legs: IGoogleRouteLeg[]) => {
    return legs.map((leg, index) => {
        const newLeg = new RouteLeg({
            index: index,
            origin: leg.startLocation.latLng,
            destination: leg.endLocation.latLng,
            polyline: leg.polyline.encodedPolyline,
            actual_distance_kms: leg.distanceMeters / 1000,
            actual_duration_seconds: parseInt(leg.duration.replace('s', '')),
        });
        return newLeg;
    });
};


export const convertStepsToRouteLegs = (steps: CombinedStep[]) => {
    return steps.map((step, index) => {
        const googlePolyline = convertOSRMPolylineToGooglePolylineObject(step.polyline as string[]);
        const newLeg = new RouteLeg({
            index: index,
            origin: step.startLocation,
            destination: step.endLocation,
            polyline: googlePolyline,
            actual_distance_kms: step.distance / 1000,
            actual_duration_seconds: step.duration,
        });
        return newLeg;
    });
};
/**
 * Util function to convert OSRM polylines to Google polyline objects
 * @param geometry Array of lat/lng objects
 * @returns Google Polyline encoded object {lat: number, lng: number}[]
 */
export const convertOSRMPolylineToGooglePolylineObject = (geometry: string[]) => {
    if (!geometry) return [];
    const decodedPolyline = geometry?.map((polylineString: string) => {
        if (polylineString.length > 0) {
            const decoded = polyline.decode(polylineString, 6)
            const combinedDecoded = decoded.map((point: any) => {
                return { lat: point[0], lng: point[1] }
            })
            return combinedDecoded
        }
        return null
    }) || []
    const combinedPolyline = decodedPolyline.filter((point: any) => point !== null).flat();
    return combinedPolyline
};

/**
 * Util function to convert OSRM polylines to an array of lat/lng objects
 * @param geometry Array of encoded polyline strings
 * @returns Array of {lat: number, lng: number} objects
 */
export const convertOSRMPolylineToArray = (geometry: string[]): [number, number][] => {
    if (!geometry) return [];
    const decodedPolyline = geometry?.map((polylineString: string) => {
        if (polylineString.length > 0) {
            const decoded = polyline.decode(polylineString, 6)
            const combinedDecoded = decoded.map((point: any) => {
                return [point[0], point[1]]
            })
            return combinedDecoded
        }
        return null
    }) || []
    const combinedPolyline = decodedPolyline.filter((point: any) => point !== null).flat();
    return combinedPolyline as [number, number][]
};




/*
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
//////// VROOM REQUEST TYPES
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
*/


interface VroomResponseWithRoutes extends VroomResponse {
    routes?: CombinedRoute[];
    all_overview_polyline?: string[];
    success?: boolean;
    message?: string;
}

export interface CombinedRoute extends VroomRoute {
    // legs?: CombinedStep[];
    steps: CombinedStep[];
    overview_polyline?: string;
}

export interface CombinedStep extends Step {
    startLocation: OsrmLocation;
    endLocation: OsrmLocation;
    distance: number;
    duration: number;
    polyline: string | string[] | {lat: number, lng: number}[];
    success: boolean;
    message: string;
}


interface OsrmLocation {
    lon: number;
    lat: number;
}

export interface VroomRequest {
    jobs?: Job[];                     // Array of job objects
    shipments?: Shipment[];           // Array of shipment objects
    vehicles: Vehicle[];               // Array of vehicle objects
    matrix?: number[][];               // Custom distance matrix
    options?: Options;                 // Options for the problem
}

interface Job {
    id: number;                     // Unique identifier for the job
    description?: string;           // Optional description of the job
    location?: [number, number];    // Coordinates array for job location
    location_index?: number;        // Index of relevant row and column in custom matrices
    setup?: number;                 // Job setup duration (defaults to 0)
    service?: number;               // Job service duration (defaults to 0)
    amount?: number[];              // Array of integers describing multidimensional quantities
    delivery?: number[];            // Array of integers describing multidimensional quantities for delivery
    pickup?: number[];              // Array of integers describing multidimensional quantities for pickup
    skills?: number[];              // Array of integers defining mandatory skills
    priority?: number;              // Integer in the [0, 100] range describing priority level (defaults to 0)
    time_windows?: TimeWindow[];    // Array of time_window objects describing valid slots for job service start
}

interface TimeWindow {
    start: number;                  // Start time of the time window
    end: number;                    // End time of the time window
}

interface Shipment {
    id: number;                     // Unique identifier for the shipment
    pickup: ShipmentStep;           // Shipment_step object describing pickup
    delivery: ShipmentStep;         // Shipment_step object describing delivery
    amount?: number[];              // Array of integers describing multidimensional quantities
    skills?: number[];              // Array of integers defining mandatory skills
    priority?: number;              // Integer in the [0, 100] range describing priority level (defaults to 0)
}

interface ShipmentStep {
    id: number;                     // Unique identifier for the shipment step
    location: [number, number];     // Coordinates array for step location
    setup_duration?: number;        // Task setup duration (defaults to 0)
    service_duration?: number;      // Task service duration (defaults to 0)
    time_windows?: [number, number][]; // Array of time_window objects describing valid slots for task service start
    description?: string;           // Optional description of this step
}

interface Vehicle {
    id: number;                     // Unique identifier for the vehicle
    profile?: string;                // Routing profile (defaults to car)
    start?: [number, number];       // Coordinates array for start location
    end?: [number, number];         // Coordinates array for end location
    capacity?: number[];            // Array of integers describing multidimensional quantities
    skills?: number[];              // Array of integers defining skills
    time_window?: [number, number]; // Time_window object describing working hours
    breaks?: Break[];               // Array of break objects
    speed_factor?: number;          // Double value in the range (0, 5] used to scale all vehicle travel times (defaults to 1)
    max_tasks?: number;             // Integer defining the maximum number of tasks in a route for this vehicle
    steps?: VehicleStep[];          // Array of vehicle_step objects describing a custom route for this vehicle
    startDescription?: string;      // Optional description of the start location
    endDescription?: string;        // Optional description of the end location
}

interface Break {
    id: number;                     // Unique identifier for the break
    time_windows: [number, number][]; // Array of time_window objects describing valid slots for break start
    service_duration: number;       // Break duration (defaults to 0)
    description?: string;           // Optional description of this break
    max_load?: number[];            // Array of integers describing the maximum vehicle load for which this break can happen
}

interface VehicleStep {
    type: 'start' | 'job' | 'pickup' | 'delivery' | 'break' | 'end'; // Type of the step
    id: number;                     // ID of the task to be performed at this step (for job, pickup, delivery, or break)
    location: [number, number];     // Coordinates array for step location
    service_at?: number;            // Hard constraint on service time
    service_after?: number;         // Hard constraint on service time lower bound
    service_before?: number;         // Hard constraint on service time upper bound
}

interface Options {
    g?: boolean;                    // Enable geometry in output
    geometry?: boolean;             // Enable geometry in output (alternative to 'g')
    router?: string;                // Specify the routing engine
    alternate_route?: number;       // Number of alternative routes to compute
    avoid_polygons?: any[]; // Array of polygons to avoid in routing
}
/*
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
//////// VROOM RESPONSE TYPES
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
*/

interface VroomResponse {
    code: number;                   // Status code (0: no error, 1: internal error, 2: input error, 3: routing error)
    error?: string;                 // Error message (present if code is different from 0)
    summary?: Summary;               // Object summarizing solution indicators
    unassigned?: UnassignedTask[];   // Array of objects describing unassigned tasks
    routes?: VroomRoute[];                // Array of route objects
}

interface Summary {
    cost: number;                   // Total cost for all routes
    routes: number;                 // Number of routes in the solution
    unassigned: number;             // Number of tasks that could not be served
    setup: number;                  // Total setup time for all routes
    service: number;                // Total service time for all routes
    duration: number;               // Total travel time for all routes
    waiting_time: number;           // Total waiting time for all routes
    priority: number;               // Total priority sum for all assigned tasks
    violations: Violation[];        // Array of violation objects for all routes
    amount?: number[];              // Total amount for all routes
    delivery?: number[];            // Total delivery for all routes
    pickup?: number[];              // Total pickup for all routes
    distance?: number;              // Total distance for all routes (provided with -g flag or distance matrices)
    computing_times: ComputingTimes;
}

interface ComputingTimes {
    loading: number;
    solving: number;
    routing: number;
}

interface UnassignedTask {
    id: number;                     // ID of the unassigned task
    type: string;                   // Type of the unassigned task
    description?: string;           // Description of the task, if provided
    location?: [number, number];    // Coordinates of the task, if provided
    location_index?: number;        // Index in custom matrices, if provided
}

export interface VroomRoute {
    vehicle: number;                // ID of the vehicle assigned to this route
    cost: number;                   // Cost for this route
    setup: number;                  // Total setup time for this route
    service: number;                // Total service time for this route
    duration: number;               // Total travel time for this route
    waiting_time: number;           // Total waiting time for this route
    priority: number;               // Total priority sum for tasks in this route
    steps: Step[];                  // Array of step objects
    violations: Violation[];        // Array of violation objects for this route
    amount?: number[];              // Total amount for jobs in this route
    delivery?: number[];            // Total delivery for tasks in this route
    pickup?: number[];              // Total pickup for tasks in this route
    description?: string;           // Vehicle description, if provided in input
    geometry?: string;              // Polyline encoded route geometry (provided with -g flag)
    distance?: number;              // Total route distance (provided with -g flag or distance matrices)
}

interface Step {
    type: 'start' | 'job' | 'pickup' | 'delivery' | 'break' | 'end'; // Type of the step
    location: [number, number];     // Coordinates array for this step (if provided in input)
    setup: number;                  // Setup time at this step
    service: number;                // Service time at this step
    waiting_time: number;           // Waiting time upon arrival at this step
    arrival: number;                // Estimated time of arrival at this step
    duration: number;               // Cumulated travel time upon arrival at this step
    violations: Violation[];        // Array of violation objects for this step
    distance: number;               // Traveled distance upon arrival at this step (provided with -g flag)
    description?: string;           // Step description, if provided in input
    id?: number;                    // ID of the task performed at this step (for job, pickup, delivery, or break)
    job?: number;                   // ID of the job performed at this step (only if type is job)
    load?: number[];                // Vehicle load after step completion (with capacity constraints)
    location_index?: number;        // Index of relevant row and column in custom matrices (if provided in input)
}

interface Violation {
    cause: string;                  // String describing the cause of violation
    duration?: number;              // Earliness (for "lead_time") or lateness (for "delay")
}


/*
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
//////// OSRM ROUTE TYPES
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
*/

interface OSRMRouteResults {
    waypoints: Waypoint[];
    routes: OSRMRoute[];
}


/**
    * Represents a route through (potentially multiple) waypoints.
    *
    * https://github.com/Project-OSRM/osrm-backend/blob/master/docs/http.md#route-object
    */
interface OSRMRoute {
    /**
     * The distance traveled by the route, in float meters.
     */
    distance: number;
    /**
     * The estimated travel time, in float number of seconds.
     */
    duration: number;
    /**
     * The whole geometry of the route value depending on overview parameter, format depending on the geometries parameter. See RouteStep's geometry property for a parameter documentation.
     */
    geometry?: any;
    /**
     * The calculated weight of the route.
     */
    weight: number;
    /**
     * The name of the weight profile used during extraction phase.
     */
    weight_name: string;
    /**
     * The legs between the given waypoints, an array of RouteLeg objects.
     */
    legs: OSRMRouteLeg[];
}


interface Polyline {
    encodedPolyline: string;
}

interface LineString {
    coordinates: Coordinate[];
}

interface Coordinate {
    latitude: number;
    longitude: number;
}

interface Indication {
    modifier: string;
}



/**
 * Represents a route between two waypoints.
 *
 * https://github.com/Project-OSRM/osrm-backend/blob/master/docs/http.md#routeleg-object
 */
interface OSRMRouteLeg {
    /**
     * The distance traveled by this route leg, in float meters.
     */
    distance: number;
    /**
     * The estimated travel time, in float number of seconds.
     */
    duration: number;
    /**
     * The calculated weight of the route leg.
     */
    weight: number;
    /**
     * Summary of the route taken as string. Depends on the summary parameter:
     * - true: Names of the two major roads used. Can be empty if route is too short.
     * - false: empty string
     */
    summary: string;
    /**
     * Depends on the steps parameter.
     * - true: array of RouteStep objects describing the turn-by-turn instructions
     * - false: empty array
     */
    steps: RouteStep[];
    /**
     * Additional details about each coordinate along the route geometry:
     * - true: An Annotation object containing node ids, durations distances and
     * - false: weights undefined
     */
    annotation: any;
}

/**
     * A step consists of a maneuver such as a turn or merge, followed by a distance of travel along a single way to the subsequent step.
     *
     * https://github.com/Project-OSRM/osrm-backend/blob/master/docs/http.md#routestep-object
     */
interface RouteStep {
    /**
     * The distance of travel from the maneuver to the subsequent step, in float meters.
     */
    distance: number;
    /**
     * The estimated travel time, in float number of seconds.
     */
    duration: number;
    /**
     * The unsimplified geometry of the route segment, depending on the geometries parameter.
     */
    geometry: Polyline | LineString;
    /**
     * The calculated weight of the step.
     */
    weight: number;
    /**
     * The name of the way along which travel proceeds.
     */
    name: string;
    /**
     * A reference number or code for the way. Optionally included, if ref data is available for the given way.
     */
    ref: string;
    /**
     * The pronunciation hint of the way name. Will be undefined if there is no pronunciation hit.
     */
    pronunciation: string;
    /**
     * The destinations of the way. Will be undefined if there are no destinations.
     */
    destinations: string;
    /**
     * The exit numbers or names of the way. Will be undefined if there are no exit numbers or names.
     */
    exits: string;
    /**
     * A string signifying the mode of transportation.
     */
    mode: string;
    /**
     * A StepManeuver object representing the maneuver.
     */
    maneuver: StepManeuver;
    /**
     * A list of Intersection objects that are passed along the segment, the very first belonging to the StepManeuver
     */
    intersections: Intersection[];
    /**
     * The name for the rotary. Optionally included, if the step is a rotary and a rotary name is available.
     */
    rotary_name: string;
    /**
     * The pronunciation hint of the rotary name. Optionally included, if the step is a rotary and a rotary pronunciation is available.
     */
    rotary_pronunciation: string;
}

/**
 * https://github.com/Project-OSRM/osrm-backend/blob/master/docs/http.md#stepmaneuver-object
 */
interface StepManeuver {
    /**
     * A [longitude, latitude] pair describing the location of the turn.
     */
    location: Coordinate;
    /**
     * The clockwise angle from true north to the direction of travel immediately before the maneuver. Range 0-359.
     */
    bearing_before: number;
    /**
     * The clockwise angle from true north to the direction of travel immediately after the maneuver. Range 0-359.
     */
    bearing_after: number;
    /**
     * A string indicating the type of maneuver.
     * new identifiers might be introduced without API change Types unknown to the client should be handled like the turn type,
     * the existence of correct modifier values is guranteed.
     */
    type: any;
    /**
     * An optional string indicating the direction change of the maneuver.
     */
    modifier: Indication;
}


/**
 * A Lane represents a turn lane at the corresponding turn location.
 *
 * https://github.com/Project-OSRM/osrm-backend/blob/master/docs/http.md#lane-object
 */
interface Lane {
    indications: Indication[];
    valid: boolean;
}

/**
* An intersection gives a full representation of any cross-way the path passes bay.
* For every step, the very first intersection (intersections[0]) corresponds to the location of the StepManeuver.
* Further intersections are listed for every cross-way until the next turn instruction.
*
* https://github.com/Project-OSRM/osrm-backend/blob/master/docs/http.md#intersection-object
*/
interface Intersection {
    /**
     * A [longitude, latitude] pair describing the location of the turn.
     */
    location: Coordinate;
    /**
     * A list of bearing values (e.g. [0,90,180,270]) that are available at the intersection.
     * The bearings describe all available roads at the intersection. Values are between 0-359 (0=true north)
     */
    bearings: number[];
    /**
     * An array of strings signifying the classes (as specified in the profile) of the road exiting the intersection.
     */
    classes: string[];
    /**
     * A list of entry flags, corresponding in a 1:1 relationship to the bearings.
     * A value of true indicates that the respective road could be entered on a valid route.
     * false indicates that the turn onto the respective road would violate a restriction.
     */
    entry: string[];
    /**
     * index into bearings/entry array. Used to calculate the bearing just before the turn.
     * Namely, the clockwise angle from true north to the direction of travel immediately before the maneuver/passing the intersection.
     * Bearings are given relative to the intersection. To get the bearing in the direction of driving, the bearing has to be rotated by a value of 180.
     * The value is not supplied for depart maneuvers.
     */
    in: number;
    /**
     * index into the bearings/entry array. Used to extract the bearing just after the turn.
     * Namely, The clockwise angle from true north to the direction of travel immediately after the maneuver/passing the intersection.
     * The value is not supplied for arrive maneuvers.
     */
    out: number;
    /**
     * Array of Lane objects that denote the available turn lanes at the intersection.
     * If no lane information is available for an intersection, the lanes property will not be present.
     */
    lanes: Lane;
}

/**
 * Object used to describe waypoint on a route.
 *
 * https://github.com/Project-OSRM/osrm-backend/blob/master/docs/http.md#waypoint-object
 */
interface Waypoint {
    distance: number;
    /**
     * Name of the street the coordinate snapped to
     */
    name: string;
    /**
     * Array that contains the [longitude, latitude] pair of the snapped coordinate
     */
    location: Coordinate;
    /**
     * Unique internal identifier of the segment (ephemeral, not constant over data updates)
     * This can be used on subsequent request to significantly speed up the query and to connect multiple services.
     * E.g. you can use the hint value obtained by the nearest query as hint values for route inputs.
     */
    hint: string;
}
