import React, { useState, useEffect, useRef, useReducer, useCallback, Profiler, useMemo, memo, useContext } from 'react';
import {
    AccessibilityRole,
    ImageProps,
    ImageStyle,
    Image,
    StyleSheet,
    TouchableOpacity,
    View,
    Text as RNText,
    Platform,
    useWindowDimensions,
    ScrollView,
    Dimensions,
    Alert
    //@ts-ignore
} from "react-native";
import {
    Layout,
    Text,
} from "@ui-kitten/components";

import * as JobsController from '../../../functions/job.controller'
import * as MemberController from '../../../functions/membership.controller'
import * as ClientController from '../../../functions/client.controller'
import * as UpdateController from '../../../functions/update.controller'
import * as CompanyController from '../../../functions/company.controller'
import * as StorageController from '../../../functions/storageController'
import * as GoogleController from '../../../functions/google.controller'
import ErrorBoundary from '../../ErrorBoundary.component';
import { Job } from '../../../models/Job.model'
import { JobLog } from '../../../models/JobLog.model';


interface LogDetailsCardProps {
    logs: JobLog[];
}

export const LogDetailsCard = ({
    logs
}: LogDetailsCardProps) => {

    const RenderSmsDetails = ({ log }: { log: JobLog }) => {
        return (
            <>
                <Layout style={{ flexDirection: 'row', borderBottomWidth: 1, borderColor: 'gray', minHeight: 40 }}>
                    <Text style={{ flex: labelFlex, fontSize: 12, fontWeight: 'bold' }}>Message: </Text>
                    <Text style={{ flex: valueFlex, fontSize: 10, flexWrap: 'wrap' }}>{log?.details?.data?.message}</Text>
                </Layout>
                <Layout
                    style={{ flex: 1, flexDirection: 'row', borderBottomWidth: 1, borderColor: 'gray', }}>
                    <Text style={{ flex: labelFlex, fontSize: 12, fontWeight: 'bold' }}>Recipient: </Text>
                    <Text style={{ flex: valueFlex, fontSize: 10 }}>{log?.details?.data?.to}</Text>
                </Layout>
            </>
        )
    }

    const RenderEmailDetails = ({ log }: { log: JobLog }) => {
        return (<Layout style={{ flexDirection: 'row', borderBottomWidth: 1, borderColor: 'gray', }}>
            <Text style={{ flex: labelFlex, fontSize: 12, fontWeight: 'bold' }}>Recipient: </Text>
            <Text style={{ flex: valueFlex, fontSize: 10 }}>{log?.details?.data?.email}</Text>
        </Layout>)
    }

    const RenderAssignedDetails = ({ log }: { log: JobLog }) => {
        return (
            <Layout style={{ flexDirection: 'row', borderBottomWidth: 1, borderColor: 'gray', }}>
                <Text style={{ flex: labelFlex, fontSize: 12, fontWeight: 'bold' }}>Assigned To: </Text>
                <Text style={{ flex: valueFlex, fontSize: 10 }}>{log?.details?.data.assigned_member_name}</Text>
            </Layout>
        )
    }

    const labelFlex = 2;
    const valueFlex = 5;



    return (
        <Layout style={{ flex: 1, height: "100%" }}>
            <View
                style={{ flex: 1, height: '100%' }}
                header={(props: any) => <Text {...props}>Logs</Text>}
                status='primary'
                disabled={true}
            >
                <View style={{ height: 15, borderTopLeftRadius: 10, borderTopRightRadius: 10, backgroundColor: 'blue' }}>
                    <Text style={{ fontSize: 12, fontWeight: 'bold', color: 'white', textAlign: 'center' }}>Logs</Text>
                </View>

                {/* <ScrollView style={{ flexDirection: 'column', marginLeft: -20, marginRight: -20 }}> */}
                <ScrollView style={{ flex: 1, padding: 2 }}>
                    {logs && logs.length && logs?.map((log: JobLog, index) => {
                        let date = new Date(log.createdAt).toLocaleString(
                            'en-AU',
                            {
                                day: '2-digit',
                                month: 'short',
                                year: '2-digit',
                                hour: 'numeric',
                                minute: 'numeric',
                                hour12: true
                            }
                        )
                        // capitalise each word
                        const words = log.details?.function?.split(' ');
                        if (!words) return <></>;
                        for (let i = 0; i < words?.length; i++) {
                            words[i] = words[i][0]?.toUpperCase() + words[i].substr(1);
                        }
                        let fn = words.join(' ');
                        const isSms = log.details?.type == "sms";
                        const isEmail = log.details?.type == "email";
                        const isAssign = log.details?.type == "assign";

                        return (
                            <Layout key={index} style={{ flexDirection: 'column', margin: 1, padding: 1, borderWidth: 1, borderColor: 'gray', borderRadius: 5 }}>
                                <Layout style={{ flex: 1, flexDirection: 'row', borderBottomWidth: 1, borderColor: 'gray', }}>
                                    <Text style={{ flex: labelFlex, fontSize: 12, fontWeight: 'bold' }}>Date: </Text>
                                    <Text style={{ flex: valueFlex, fontSize: 10 }}>{date}</Text>
                                </Layout>
                                <Layout style={{ flex: 1, flexDirection: 'row', borderBottomWidth: 1, borderColor: 'gray', }}>
                                    <Text style={{ flex: labelFlex, fontSize: 12, fontWeight: 'bold' }}>Event: </Text>
                                    <Text style={{ flex: valueFlex, fontSize: 10 }}>{fn}</Text>
                                </Layout>
                                {
                                    isSms ? <RenderSmsDetails log={log} /> : <></>
                                }
                                {
                                    isEmail ? <RenderEmailDetails log={log} /> : <></>
                                }
                                {
                                    isAssign ? <RenderAssignedDetails log={log} /> : <></>
                                }
                                <Layout style={{ flex: 1, flexDirection: 'row', borderBottomWidth: 1, borderColor: 'gray', }}>
                                    <Text style={{ flex: labelFlex, fontSize: 12, fontWeight: 'bold' }}>Name: </Text>
                                    <Text style={{ flex: valueFlex, fontSize: 10 }}>{log.details?.member_name}</Text>
                                </Layout>
                            </Layout>
                        )
                    })}
                </ScrollView>
            </View>
        </Layout>
    )

}
