import React, { useEffect, useRef, useState } from 'react';
import {
    Image,
    AccessibilityRole,
    ImageProps,
    ImageStyle,
    StyleSheet,
    TouchableOpacity,
    View,
    Text as RNText,
    Animated
    //@ts-ignore
} from "react-native";
import {
    ApplicationProvider,
    Button,
    Icon,
    IconRegistry,
    Layout,
    Text,
    List,
    ListItem,
    Card
} from "@ui-kitten/components";
import { SafeAreaView } from 'react-native-safe-area-context';
import { EvaIconsPack } from "@ui-kitten/eva-icons";
import { mapping, light, dark } from "@eva-design/eva";
import { useScrollToTop } from '@react-navigation/native';
import * as JobController from '../../functions/job.controller';
import * as UpdateController from '../../functions/update.controller';
import * as MessageController from '../../functions/message.controller';
import * as StorageController from '../../functions/storageController';
import DriversJobList from './DriversJobList.component';
import { Member } from '../../models/Member.model';
import { Job } from '../../models/Job.model';

interface DriversListItemProps {
    var_driver: Member;
    var_drivers: Member[];
    var_clients: any;
    item: Member;
    fn_selectDriver: (driver: Member, jobs: Job[]) => void;
    fn_selectJob: (job: Job) => void;
    fn_zoomToMarker: (job: Job) => void;
    fn_setDirections: (driver: Member, job: Job) => void;
    index: number;
    var_viewedBy: string;
}


const DriversListItem = ({
    var_driver,
    var_drivers,
    var_clients,
    item,
    index,
    fn_selectDriver,
    fn_selectJob,
    fn_zoomToMarker,
    fn_setDirections,
    var_viewedBy
}: DriversListItemProps) => {

    // console.log(props)
    // var Jobs = null
    // var Show = false
    const [Jobs, setJobs] = useState([]);
    const [Show, setShow] = useState(false);
    const [Status, setStatus] = useState("");
    const [registeredEvents, setRegisteredEvents] = useState(false);

    // JobController.getIncompleteJobsByMemberIdAndCompanyId(item._id, item.company_id).then((jobs) => {
    //     Jobs = jobs
    //     Show = true
    //     // setJobs(jobs)
    //     // setShow(true)
    // })
    useEffect(
        () => {
            if (!registeredEvents) {
                setRegisteredEvents(true);
            }
            if (!item.company_id) return;
            JobController.getIncompleteJobsByMemberIdAndCompanyId(item._id, item.company_id).then((jobs) => {
                // Jobs = jobs
                // Show = true
                setJobs(jobs)
                setShow(true)
                checkArray(jobs)
            })
        },
        []
    )

    const selectDriverChat = () => {
        let member = var_driver
        const selectedMembership = StorageController.getAppState().selectedMembership
        if (!member || !selectedMembership) return;
        if (member._id != StorageController.appState.selectedChatRecipient?._id) {
        }
        MessageController.getMessagesBetweenMembers(selectedMembership._id, member._id).then(_messages => {
            // StorageController.appState.selectedChatMessages = _messages
            StorageController.setSelectedMessages(_messages)
            UpdateController.dispatchEventStateChange({ type: UpdateController.STATE_ACTIONS.SET_SELECTED_CHAT, data: { member: member, messages: _messages } })
            StorageController.appState.selectedChatRecipient = member
            StorageController.saveStateToDisk()
        })
    }

    const checkArray = (jobs: Job[]) => {
        try {
            var driver_arrived = jobs.find(element => {
                if (element.driver_accepted == true && element.driver_arrived == true) {
                    return element
                }
            })
            var driver_on_route = jobs.find(element => {
                if (element.driver_accepted == true && element.driver_on_route == true) {
                    return element
                }
            })
            if (driver_arrived) {
                setStatus("Working")
                // console.log("Driver has arrived")
            }
            else if (driver_on_route) {
                setStatus("On Route")
                // console.log("Driver is on route")
            }
            else {
                setStatus("Waiting")
            }
        }
        catch (err) {
            console.log(err)
        }
    }

    const driverStatusColour = (status: string) => {
        if (status == "Working") {
            return "#00FF00"
        }
        if (status == "On Route") {
            return "#FFA500"
        }
        if (status == "Waiting") {
            return "#FF0000"
        }
    }
    // console.log(jobs)
    return (
        <Layout style={{ flex: 1, width: '100%', margin: 5, border: "1px solid" }} key={index}>
            <Layout style={{ flexDirection: 'row', alignItems: 'center', borderBottomWidth: 1, borderBottomColor: '#000000' }}>
                <Layout style={{ flex: 2, backgroundColor: item.online ? "#00FF00" : "#FF0000", border: '1px solid #000000', borderRadius: 50, width: 15, height: 15, top: 0, left: 40, position: "absolute" }}>
                </Layout>
                {Show ?
                    <Layout style={{ flex: 2, backgroundColor: driverStatusColour(Status), border: '1px solid #000000', borderRadius: 50 }}>
                        <Text style={{ flex: 1, textAlign: 'center' }} category="label">{Status}</Text>
                    </Layout>
                    : null}
                <Layout style={{ flex: 1, flexDirection: 'row', justifyContent: 'flex-end' }}>
                </Layout>
                <Layout style={{ flex: 12 }}>
                    <Text category='h6'>{item.name}</Text>
                </Layout>
                <Layout style={{ flex: 3 }}>
                    {/* if the driver id is not current selected member */}
                    {item._id != StorageController.getAppState().selectedMembership?._id &&
                        <Button onPress={selectDriverChat} style={{ flex: 1, backgroundColor: '#000000' }} size='small'>Chat</Button>
                    }
                </Layout>
            </Layout>
            {item.user &&
                <Layout style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', padding: 10 }}>
                    <Layout style={{ flex: 1, flexDirection: 'column' }}>
                        <Text category='label'>Phone: </Text>
                        <Text category='s1'>{item.user.phone}</Text>
                    </Layout>
                    <Layout style={{ flex: 1, flexDirection: 'column' }}>
                        <Text category='label'>Email: </Text>
                        <Text category='s1'>{item.user.phone}</Text>
                    </Layout>
                </Layout>
            }
            <Layout style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', padding: 10 }}>
                {Show ?
                    <DriversJobList
                        var_driversJobs={Jobs}
                        var_clients={var_clients}
                        var_drivers={var_drivers}
                        fn_selectJob={fn_selectJob}
                        fn_zoomToMarker={fn_zoomToMarker}
                        fn_setDirections={fn_setDirections}
                        var_viewedBy={var_viewedBy}
                    ></DriversJobList>
                    : null}
            </Layout>
            <Layout style={{ flex: 1, flexDirection: 'row', alignItems: 'center' }}>
                <Layout style={{ flex: 9, flexDirection: 'row', alignItems: 'center' }}>
                    {/* Spacer */}
                </Layout>
                <Layout style={{ flex: 1, flexDirection: 'row', alignItems: 'center' }}>
                    <Button onPress={() => { fn_selectDriver(item, Jobs) }}>View</Button>
                </Layout>
                <Layout style={{ flex: 1, flexDirection: 'row', alignItems: 'center' }}>
                    {/* spacer */}
                </Layout>
            </Layout>
        </Layout>
    );
}

const styles = StyleSheet.create({

});


export default DriversListItem;
